import {
  Button,
  Calendar,
  CalendarCell,
  CalendarGrid,
  DatePicker,
  Dialog,
  Group,
  Heading,
  I18nProvider,
  Popover,
} from 'react-aria-components';
import arrow from '../../../assets/images/datePicker/arrow.png';
import { Box, Divider, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import calendarImg from '../../../assets/images/datePicker/calendar.png';
import './style.scss';
import dayjs from 'dayjs';
import { today } from '@internationalized/date';
const CustomDatePicker = (props) => {
  const { watch } = useFormContext();
  const {
    name,
    title,
    onChange,
    rules,
    withoutInput,
    withoutLogo,
    InputProps,
    inputSx,
    placeholder,
    style,
    disabled,
    refButton,
    minDate,
    maxDate,
  } = props;
  const watchDate = watch(name);
  return (
    // <div className={'container'}>
    <Controller
      name={name}
      rules={rules}
      render={({ field }) => (
        <I18nProvider locale="he">
          <DatePicker
            minValue={minDate ? minDate : today()}
            maxValue={maxDate && maxDate}
            isDisabled={disabled}
            // {...field}
            value={field.value}
            aria-label="datePicker"
            onChange={(value) => {
              field.onChange(value);
              onChange && onChange();
            }}
            style={{ width: !!withoutInput ? 'unset' : '100%', height: '100%', ...style }}
          >
            <Group>
              <Button
                ref={refButton && refButton}
                id="date-picker-button"
                className={`react-aria-Button ${!withoutInput && 'input-button'}`}
                // onHoverStart={(e) => {
                //   e.target.click();
                // }}
              >
                {!withoutLogo && (
                  <Box
                    sx={{ opacity: disabled ? '0.5' : 'unset' }}
                    src={calendarImg}
                    component={'img'}
                    alt="datePicker-calendar-img"
                  ></Box>
                )}
              </Button>
              {!withoutInput && (
                <TextField
                  disabled={disabled}
                  placeholder={
                    placeholder ? placeholder : `${dayjs(new Date()).format('DD/MM/YYYY')}`
                  }
                  onClick={() => {
                    document.getElementById('date-picker-button')?.click();
                  }}
                  InputProps={{ readOnly: true, ...InputProps }}
                  sx={{
                    height: '100%',
                    '&.MuiTextField-root .MuiOutlinedInput-input': {
                      padding: '0px 44px 0px 0px',
                      cursor: 'pointer',
                      height: '100%',
                      boxSizing: 'border-box',
                    },
                    ...inputSx,
                  }}
                  value={
                    watchDate !== '' && !!watchDate ? dayjs(watchDate).format('DD/MM/YYYY') : ''
                  }
                />
              )}
            </Group>
            <Popover>
              <Dialog>
                {title && (
                  <>
                    <div className="datePicker_title">{title}</div>
                    <Divider sx={{ marginTop: '9.5px' }} />
                  </>
                )}
                <Calendar>
                  <header>
                    <Button slot="next">
                      <img src={arrow} alt="arrow-left" />
                    </Button>
                    <Heading />
                    <Button slot="previous">
                      <img style={{ transform: 'scale(-1,1)' }} src={arrow} alt="arrow-right" />
                    </Button>
                  </header>
                  <CalendarGrid>{(date) => <CalendarCell date={date} />}</CalendarGrid>
                </Calendar>
              </Dialog>
            </Popover>
          </DatePicker>
        </I18nProvider>
      )}
    />
    // </div>
  );
};
export default CustomDatePicker;
