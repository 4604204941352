import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const checklistCreate = createAsyncThunk(
  "checklist/create",
  async ({ axiosPrivate, ...payload }, thunkAPI) => {
    try {
      const { headers,config,request, ...response } = await axiosPrivate.post(`checklist`, payload);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const chatSlice = createSlice({
  name: "checklist",
  initialState: {
    currentEvent: { checkListId: 0, eventTypeName: "" },
    isNeedPlace: false,
    allEvents: [],
    error: null,
    loadingStatus: null,
    allRecommended: [],
    suppliersList: [{ id: 0, categoryName: "", supplierName: "", price: 0, error: false }],
  },
  reducers: {
    setCurrentEventChecklist(state, action) {
      state.currentEvent = { ...action.payload.currentEvent };
    },
    setCurrentCategory(state, action) {
      state.currentCategory = action.payload.currentCategory;
    },
    setSuppliers(state, action) {
      state.suppliersList = action.payload;
    },
  },
  extraReducers: {},
});

export const { setCurrentEventChecklist, setCurrentCategory, setSuppliers } = chatSlice.actions;

export default chatSlice.reducer;
