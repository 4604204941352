import { useMediaQuery } from '@mui/material';
import React from 'react';
import mainImg1 from '../../../../assets/images/homePage/form/hero_image_dt1.jpg'
import mainImg2 from '../../../../assets/images/homePage/form/hero_image_dt2.jpg'
import mainImg3 from '../../../../assets/images/homePage/form/hero_image_dt3.jpg'
// import mobileImg from '../../../../assets/images/homePage/form/image_mobile.webp';
import styles from './styleBasicForm.module.scss';
import classNames from 'classnames';

const Photo = () => {
  const isMobile = useMediaQuery('(max-width:900px)');
  return (
    <div className={styles.photo_container}>
      {isMobile ? 
      <div className={styles.photo_wrapper}>
         <img className={styles.photo} src={mainImg3} alt="homeImg" />
       </div>
       :<>
          {/* <img src={mainImg1} className={classNames(styles.photo, styles.photo1)} alt="homeImg1"/>
          <img src={mainImg2} className={classNames(styles.photo, styles.photo2)} alt="homeImg2"/>
          <img src={mainImg3} className={classNames(styles.photo, styles.photo3)} alt="homeImg3"/> */}
            <div className={classNames(styles.photo_wrapper, styles.photo1)}>
            <img src={mainImg1} className={styles.photo} alt="homeImg1"/>
          </div>
          <div className={classNames(styles.photo_wrapper, styles.photo2)}>
            <img src={mainImg2} className={styles.photo} alt="homeImg2"/>
          </div>
          <div className={classNames(styles.photo_wrapper, styles.photo3)}>
            <img src={mainImg3} className={styles.photo} alt="homeImg3"/>
          </div>
       </>
    }
   
     
    </div>
  );
};

export default Photo;
