import { useState,useEffect } from "react"
import { Helmet } from "react-helmet-async";
import img from "../../../assets/images/for-pages/icon_tag.jpeg";

const MetaBlogDetails = (props) => {
    const [ icon,setIcon ]=useState(sessionStorage.getItem('url'))
    const {blog} = props;
    useEffect(()=>{
        setIcon(sessionStorage.getItem('url'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[sessionStorage.getItem('url')])
console.log(blog);
    return(
        <Helmet>
        <meta property="og:image"  content={img}></meta>
        <meta charSet="utf-8" />
        <title>{blog}</title>
        <link rel="canonical" href="http://mysite.com/example" />
        <meta property={blog}></meta>
        <meta property="og:site_name" content="להפיק בקליק! - EventDO"></meta>
        <meta property="og:url" content={window.location.href}></meta>
        <link rel="shortcut icon" href={icon} />
        </Helmet>
    )
}
export default MetaBlogDetails;
