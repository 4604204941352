import { Box } from '@mui/material';
import React, { useRef, useEffect } from 'react';
import styles from './CustomCodeInputStyle.module.scss';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';
const CustomCodeInput = (props) => {
  const { setValue, watch } = useFormContext();
  const { numCells = 4, sx, name, isInvalidCode } = props;
  const values = watch(name);
  const inputRefs = useRef([]);

  useEffect(() => {
    setValue(name, '');
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleInput = (event, idx) => {
    const { value } = event.target;
    const newValues = [...values];
    newValues[idx] = value.substring(value.length - 1);
    setValue(name, newValues.join(''));
    if (value && idx < numCells - 1) {
      inputRefs.current[idx + 1].focus();
    }
  };
  const handleClick = (i) => {
    inputRefs.current[i].type = 'text';
    setTimeout(() => {
      inputRefs.current[i].setSelectionRange(1, 1);
      inputRefs.current[i].type = 'number';
    }, 1);
  };
  const onKeyDown = (e, i) => {
    if ((e.keyCode === 8 || e.keyCode === 46) && !values[i] && i > 0 && inputRefs.current[i - 1]) {
      inputRefs.current[i - 1].focus();
      return;
    }
    if (e.keyCode === 37 && inputRefs.current[i - 1]) {
      inputRefs.current[i - 1].focus();
      handleClick(i - 1);
      return;
    }
    if (e.keyCode === 39 && inputRefs.current[i + 1]) {
      inputRefs.current[i + 1].focus();
      handleClick(i + 1);
      return;
    }
  };
  const handlePaste = (event) => {
    const pastedValue = event.clipboardData.getData('text');
    const newValues = pastedValue
      .slice(0, numCells)
      .split('')
      .map((char) => char || '');
    setValue(name, newValues);
  };

  return (
    <Box className={styles.inputs_container} sx={{ ...sx }}>
      {Array(numCells)
        .fill('')
        .map((value, i) => (
          <input
            key={i}
            id={`input-${i}`}
            type="number"
            value={values[i] || value}
            ref={(el) => (inputRefs.current[i] = el)}
            onChange={(e) => {
              handleInput(e, i);
            }}
            onClick={() => handleClick(i)}
            onKeyDown={(e) => onKeyDown(e, i)}
            onPaste={handlePaste}
            className={classNames(styles.one_input, isInvalidCode && styles.one_input_error)}
          />
        ))}
    </Box>
  );
};
export default CustomCodeInput;
