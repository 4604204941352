import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import Select from "../../../../components/selectComponent/Select";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import {setErr} from "../../../../store/forAll/errSlice";
import {
  changeSupPremission,
  getLabelSuppliers,
} from "../../../../store/personalSupplier/changeSupplierSlice";
import classNames from "classnames";
import styles from "./LabelPremissions.module.scss";

const LabelPremissions = (props) => {
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();

  const [curentSupplier, setCurentSupplier] = useState({});
  const [allSuppliers, setAllSuppliers] = useState([]);

  const getAllSuppliers = () => {
    // console.log("getAll suppliers1");
    dispatch(getLabelSuppliers({axiosPrivate})).then((res) => {
      if (res?.error) {
        dispatch(
          setErr({
            message: res?.payload?.response?.data?.message,
            code: res?.payload?.response?.data?.code,
          })
        );
      } else {
        setAllSuppliers(res.payload);
        // dispatch(
        //   setErr({
        //     message: "השינויים נשמרו",
        //     type: 2,
        //   })
        // );
      }
    });
  };

  const changeOption = (optionName) => {
    let newSupplier = {...curentSupplier};
    newSupplier[optionName] = !!!curentSupplier[optionName]
    setCurentSupplier(newSupplier)
  }

  const saveOptions = (optionName) => {
    //optionName : "isOptionChat", "isOptionCalendar", "isOptionPrice"
    let newSupplier = {...curentSupplier};
    let newAllSuppliers = allSuppliers.map((item) => {
      if(item.id === curentSupplier.id){
        return curentSupplier
      }else{
        return item
      }
    })
    // console.log(newSupplier)
    
    dispatch(changeSupPremission({data1: newSupplier, axiosPrivate})).then(
      (res) => {
        if (res?.error) {
          dispatch(
            setErr({
              message: res?.payload?.response?.data?.message,
              code: res?.payload?.response?.data?.code,
            })
          );
        } else {
          setAllSuppliers(newAllSuppliers)
          dispatch(
            setErr({
              message: "השינויים נשמרו",
              type: 2,
            })
          );
        }
      }
    );
  };

  useEffect(() => {
    getAllSuppliers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={styles.wrapper}>
      <div className={styles.eventsContainer}>
        <Select
          fontSize={"1rem"}
          paddingInlineStart={"1.75rem"}
          paddingInlineEnd={"1.33rem"}
          placeholder={"בחר/י ספק"}
          width={"100%"}
          borderRadius={"0.31rem"}
          backgroundColor={"white"}
          color={"#242323"}
          options={
            allSuppliers
            // [
            //   { id: 1, name: "first" },
            //   { id: 2, name: "second" },
            //   { id: 3, name: "third" },
            //   { id: 4, name: "fourth" },
            // ]
          }
          onChangeOption={(option) => {
            setCurentSupplier(option);
          }}
          selectedOption={curentSupplier}
        />
      </div>
      {!!curentSupplier?.name && <div className={styles.permissionsContainer}>
          <div className={styles.rad}>
            <div className={classNames(styles.radio)}>
              <input
                onClick={() => changeOption("isOptionCalendar")}

                // {...register("isFlash")}
                id="cal1"
                type="radio"
                // name="contractRequired"
                checked={curentSupplier.isOptionCalendar}
                // value={""}
              />
              <label htmlFor="cal1">{"יומן בשליטת לייבל"}</label>
            </div>
          </div>
          <div className={styles.rad}>
            <div className={classNames(styles.radio)}>
              <input
                onClick={() => changeOption("isOptionChat")}

                // {...register("isFlash")}
                id="chat1"
                type="radio"
                // name="contractRequired"
                checked={curentSupplier.isOptionChat}
                // value={""}
              />
              <label htmlFor="chat1">{"מסמכים וצ'ט בשליטת לייבל"}</label>
            </div>
          </div>
          <div className={styles.rad}>
            <div className={classNames(styles.radio)}>
              <input
                onClick={() => changeOption("isOptionProfile")}

                // {...register("isFlash")}
                id="profile"
                type="radio"
                // name="contractRequired"
                checked={curentSupplier.isOptionProfile}
                // value={""}
              />
              <label htmlFor="profile">{"ערוך פרופיל ספק"}</label>
            </div>
          </div>  
          <div className={styles.rad}>
            <div className={classNames(styles.radio)}>
              <input
                onClick={() => changeOption("isOptionNotification")}

                // {...register("isFlash")}
                id="notification"
                type="radio"
                // name="contractRequired"
                checked={curentSupplier.isOptionNotification}
                // value={""}
              />
              <label htmlFor="notification">{"שליטה על התראות"}</label>
            </div>
          </div>
          {/* <div className={styles.rad}>
            <div className={classNames(styles.radio)}>
              <input
                onClick={() => changeOption("isOptionPrice")}
                // {...register("isFlash")}
                id="price1"
                type="radio"
                // name="contractRequired"
                checked={curentSupplier.isOptionPrice}
                // value={""}
              />
              <label htmlFor="price1">{"עריכת תוכן ומחירים בשליטת לייבל"}</label>
            </div>
          </div> */}
      </div>}
      {!!curentSupplier?.name && <div className={styles.buttons}>
        <div onClick={() => saveOptions()} className={styles.save}>
          {"שמור שינויים"}
        </div>
      </div>}
    </div>
  );
};

export default LabelPremissions;
