import React from 'react';
import styles from '../styleBasicForm.module.scss';
import { Typography } from '@mui/material';
import arrow from '../../../../../assets/images/homePage/form/arrow1.webp';
import ProgressBarForm from './ProgressBarForm';
import StepForm from './stepForm.js/StepForm';
import { DEFAULT_FORM_VALUES } from './stepForm.js/stepForm.config';
import { FormProvider, useForm } from 'react-hook-form';

const Form = () => {
  const defaultValues = DEFAULT_FORM_VALUES;

  const form = useForm({ defaultValues: defaultValues, mode: 'onChange' });
  return (
    <div className={styles.form_container}>
      <div className={styles.form_text}>
        <Typography className={styles.form_title}>איוונט-דו להפיק בקליק</Typography>
        <Typography className={styles.form_description}>
          {/* חפשו את הצוות המנצח שלכם מבין מאות ספקים מקצועיים עם חוות דעת, מחירים, זמינות ועוד. */}
        {"מזל טוב! אתם מתכננים אירוע."}<br></br>
        {"אנחנו מאמינים שתכנון אירוע צריך להיות נינוח, רגוע ואפילו מהנה!"}<br></br>
        {"אנחנו פה בשביל לעשות לכם סדר – ריכזנו את כל הספקים מכל התחומים עם מידע מלא, גלריות, המלצות, מחירים ובדיקת זמינות. "}<br></br>
        {"כל מה שתצטרכו לעשות זה להגיד לנו מה ומתי אתם צריכים, המערכת תציג לכם רק את מי שרלוונטי עבורכם. "}<br></br>
        {"את התוצאות תוכלו לסנן על פי המלצות, ביקורות ומחיר אמיתי."}<br></br>
        {"אוהבים ליווי אישי? לחצו על כפתור הוואטסאפ."}
        </Typography>
        <img className={styles.form_arrow} src={arrow} alt="arrow" />
      </div>
      <FormProvider {...form}>
        <form>
          <ProgressBarForm />
          <StepForm />
        </form>
      </FormProvider>
    </div>
  );
};

export default Form;
