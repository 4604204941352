import React from 'react';
import styles from '../styleBasicForm.module.scss';
import typeImg from '../../../../../assets/images/homePage/form/progress/type.webp';
import celebrateImg from '../../../../../assets/images/homePage/form/progress/celebrate.webp';
import locationImg from '../../../../../assets/images/homePage/form/progress/location.webp';
import locationNameImg from '../../../../../assets/images/homePage/form/progress/location_name.webp';
import guestsImg from '../../../../../assets/images/homePage/form/progress/guests.webp';
import calendarImg from '../../../../../assets/images/homePage/form/progress/calendar.webp';
import phoneImg from '../../../../../assets/images/homePage/form/progress/phone.webp';
import leftArrow from '../../../../../assets/images/homePage/form/progress/leftArrow.webp';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { CircularProgress, Typography, useMediaQuery } from '@mui/material';
import { EXISTS_PLACE_ENUM, STEPS_ENUM } from './stepForm.js/stepForm.config';
import useAuth from '../../../../hooks/useAuth';
import { useSubmigLogic } from './stepForm.js/hooks/useSubmitLogic';
const steps = [
  {
    id: 0,
    img: typeImg,
    needWhenExistsPlace: true,
  },
  {
    id: 1,
    img: celebrateImg,
    needWhenExistsPlace: true,
  },
  {
    id: 2,
    img: locationImg,
    needWhenExistsPlace: true,
  },
  {
    id: 3,
    img: calendarImg,
    needWhenExistsPlace: true,
  },
  {
    id: 4,
    img: guestsImg,
    needWhenExistsPlace: false,
  },
  {
    id: 5,
    img: locationNameImg,
    needWhenExistsPlace: false,
  },
  {
    id: 6,
    img: phoneImg,
    needWhenExistsPlace: true,
    onlyNoAuth: true,
  },
];

const ProgressBarForm = () => {
  const { currentStep } = useSelector((state) => state.main);
  const { watch } = useFormContext();
  const existsPlace = watch('existsPlace');
  const isExistsPlace = !!existsPlace ? existsPlace === EXISTS_PLACE_ENUM.YES : true;
  const isMobile = useMediaQuery('(max-width:600px)');
  const isLastStep = currentStep === STEPS_ENUM.SUBMIT;
  const { auth } = useAuth();
  const currentRole = Object.keys(auth).length !== 0 && auth?.roles?.length ? auth?.roles[0] : '';
  const { factoryHandleSubmit, isLoading } = useSubmigLogic({ currentRole });
  const getSteps = () => {
    const filteredByPlace = !isExistsPlace
      ? steps.filter((oneStep) => oneStep.needWhenExistsPlace)
      : steps;
    return currentRole
      ? filteredByPlace.filter((oneItem) => !oneItem?.onlyNoAuth)
      : filteredByPlace;
  };
  return (
    <div
      className={classNames(
        styles.progress_container,
        isLastStep && isMobile && styles.progress_container_last
      )}
    >
      {isLastStep && isMobile ? (
        <div onClick={factoryHandleSubmit} style={{ display: 'flex', alignItems: 'center' }}>
          {isLoading ? (
            <div
              style={{
                color: '#405F6A',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                padding: '12px 0px',
              }}
            >
              <CircularProgress color="inherit" size={40} />
            </div>
          ) : (
            <>
              <Typography
                sx={{
                  fontSize: '20px',
                  lineHeight: '29px',
                  fontFamily: 'Heebo-Bold',
                  marginLeft: '14px',
                }}
              >
                בואו נצא לדרך
              </Typography>
              <img src={leftArrow} alt="left-arrow"></img>
            </>
          )}
        </div>
      ) : (
        getSteps()?.map((oneStep) => (
          <div
            // onClick={() => {
            //   dispatch(setCurrentStep(oneStep.id));
            // }}
            className={classNames(
              styles.one_step_progress,
              oneStep.id <= currentStep && styles.active
            )}
            key={`one-step-${oneStep.id}`}
          >
            <div className={styles.one_step_progress_img_container}>
              <img src={oneStep.img} alt={`step-img-${oneStep.id}`} />
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default ProgressBarForm;
