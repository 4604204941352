import React from 'react';
import Advantage from './Advantage';
import { Box } from '@mui/material';
import advantageCreditImg from '../../../../assets/images/homePage/advantages/credit.webp';
import advantageCashbackImg from '../../../../assets/images/homePage/advantages/cashback.webp';
import advantageChecklistImg from '../../../../assets/images/homePage/advantages/checklist.webp';
import styles from './advantages.module.scss';
const advantages = [
  {
    id: 0,
    image: advantageChecklistImg,
    title: 'הצ׳קליסט שלנו',
    description: 'רשימה מסודרת של השירותים להם תזדקקו כדי לארגן אירוע מושלם - לא שוכחים דבר.',
  },

  {
    id: 1,
    image: advantageCashbackImg,
    title: 'Cashback',
    description: 'החזר כספי על כל סגירה מיידית באמצעות Buy<br> Me All ישירות אליכם לנייד.',
  },
  {
    id: 2,
    image: advantageCreditImg,
    title: 'סגירה און ליין',
    description: 'אישור סגירה מיידי שחוסך זמן ומבטיח לכם שהשגתם את הספק שרציתם.',
  },
];

const Advantages = () => {
  return (
    <section className={styles.container}>
      <Box className={styles.row}>
        {advantages.map((advantage) => (
          <Advantage key={`key-advantage-${advantage.id}`} advantage={advantage} />
        ))}
      </Box>
    </section>
  );
};

export default Advantages;
