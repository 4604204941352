import styles from "./ratingStar.module.scss";
import emptyStar from "./img/empty-star.png";
import fillStar from "./img/fill-star.png";
// import { startsWith } from 'lodash';
import React, {useEffect, useState} from "react";
import * as Styles from "./styles";

const RatingStar = (props) => {
  //in rem
  const {
    colorFill,
    colorFillM,
    colorEmpty,
    widthM,
    widthD,
    gapM,
    gapD,
    changeRate,
  } = props;
  const defaultStars = [0, 0, 0, 0, 0];

  //example
  // const colorFill = "red";
  // const colorEmpty = "green";

  // const widthM = "2rem";
  // const widthD = "2rem";

  // const gapM = "1rem";
  // const gapD = "1rem";

  const [stars, setStars] = useState(defaultStars);

  useEffect(() => {
    if (!props || !props.rating || isNaN(+props.rating)) return;
    let newStars = [];
    for (let i = 1; i <= 5; i++) {
      if (i <= props.rating) {
        newStars.push(1);
      } else {
        newStars.push(0);
      }
    }
    setStars(newStars);
  }, [props]);

  return (
    <>
      {widthM && widthD ? (
        <>
          <Styles.MainWrapper
            css={{
              $$gapM: gapM || 0,
              $$gapD: gapD || 0,
            }}
          >
            {stars.map((star, index) => {
              return (
                <React.Fragment key={`star-${index}`}>
                  {star ? (
                    <Styles.MainSvgFill
                      style={{cursor: changeRate ? "pointer" : ""}}
                      onClick={() => changeRate && changeRate(index + 1)}
                      css={{
                        $$widthM: widthM,
                        $$widthD: widthD,
                        $$colorFillM: colorFillM || colorFill || "#E7B1AD",
                        $$scaleD: parseFloat(widthD),
                        $$scaleM: parseFloat(widthM),
                      }}
                    >
                      <path
                        id="Icon_awesome-star"
                        data-name="Icon awesome-star"
                        d="M18.289,1.256,13.682,10.6,3.374,12.1a2.259,2.259,0,0,0-1.249,3.852l7.457,7.267L7.819,33.483a2.256,2.256,0,0,0,3.273,2.377l9.221-4.847,9.221,4.847a2.258,2.258,0,0,0,3.273-2.377L31.044,23.218,38.5,15.951A2.259,2.259,0,0,0,37.252,12.1L26.945,10.6,22.338,1.256a2.259,2.259,0,0,0-4.05,0Z"
                        transform="translate(-1.441 0.001)"
                        fill={colorFill || "#E7B1AD"}
                      />
                    </Styles.MainSvgFill>
                  ) : (
                    <Styles.MainSvgEmpty
                      style={{cursor: changeRate ? "pointer" : ""}}
                      onClick={() => changeRate && changeRate(index + 1)}
                      css={{
                        $$widthM: widthM,
                        $$widthD: widthD,
                        $$scaleD: parseFloat(widthD),
                        $$scaleM: parseFloat(widthM),
                      }}
                    >
                      <path
                        id="Icon_awesome-star"
                        data-name="Icon awesome-star"
                        d="M18.289,1.256,13.682,10.6,3.374,12.1a2.259,2.259,0,0,0-1.249,3.852l7.457,7.267L7.819,33.483a2.256,2.256,0,0,0,3.273,2.377l9.221-4.847,9.221,4.847a2.258,2.258,0,0,0,3.273-2.377L31.044,23.218,38.5,15.951A2.259,2.259,0,0,0,37.252,12.1L26.945,10.6,22.338,1.256a2.259,2.259,0,0,0-4.05,0Z"
                        transform="translate(-1.441 0.001)"
                        fill={colorEmpty || "#EEE9E5"}
                      />
                    </Styles.MainSvgEmpty>
                  )}
                </React.Fragment>
              );
            })}
          </Styles.MainWrapper>
        </>
      ) : (
        <div className={styles.starsWrapper}>
          {stars.map((star, index) => {
            return star ? (
              <div key={index} className={styles.star}>
                <img alt="fillStar" src={fillStar} />
              </div>
            ) : (
              <div key={index} className={styles.star}>
                <img alt="emptyStar" src={emptyStar} />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default RatingStar;
