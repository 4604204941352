import { FormProvider, useForm } from 'react-hook-form';
import MetaSuppliers from '../../components/metaComponents/metaSuppliers';
import Footer from '../../components/footer/Footer';
import HeaderSuppliers from './headerSuppliers/HeaderSuppliers';
import ContentSuppliers from './contentSuppliers/ContentSuppliers';
import { useEffect, useRef } from 'react';
import ChecklistButton from '../../components/checklistButton/checklistButton';
import WhatsApp from '../../components/whatsApp/whatsApp';

const Suppliers = () => {
  const form = useForm({
    defaultValues: {
      // typeEvent: '',
      priceRangesId: [],
      geoLocationsId: [],
      venueTypesId: [],
      koshersId: [],
      parkingOptionsId: '',
      weekendEventsId: '',
      amountGuests: '',
      nameSearch: '',
      category: undefined,
      categoryFiltersId: [],
      dateRange: null,
      date: null,
    },
    mode: 'onChange',
  });
  const ref = useRef(null);
  useEffect(() => {
    if (ref) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, []);
  return (
    <FormProvider {...form}>
      {/* <WhatsApp/> */}
      <div id="for-scroll" ref={ref} />
      <MetaSuppliers />
      <ChecklistButton />
      <HeaderSuppliers />
      <ContentSuppliers />
      <Footer />
    </FormProvider>
  );
};
export default Suppliers;
