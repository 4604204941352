// import { fontSize } from "@mui/system";
import { createStitches } from '@stitches/react';

export const { styled, css } = createStitches({
  media: {
    bp1: '(max-width: 60rem)',
    bp2: '(min-width: 60.0625rem)',
  },
});

export const Wrapper = styled('div', {
  position: 'relative',
  width: '100%',
  height: '100%',
  boxSizing: 'border-box',
  fontFamily: 'Heebo-Regular',
  justifyContent: 'inherit',
  fontSize: '1em',
});

export const InputWrapper = styled('div', {
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'inherit',
  width: '100%',
  height: '100%',
  boxSizing: 'border-box',
  color: '#A4A2A2',
  fontFamily: 'Heebo-Regular',
  fontSize: '1em',
  justifyContent: 'inherit',
});

export const CalInputImg = styled('img', {
  '@bp2': {
    margin: '0 1.2rem 0 0.87125rem',
  },
  '@bp1': {
    margin: '0 0rem 0 0.87125rem',
  },

  height: '2.3125rem',
  width: '2.3125rem',
});

export const CalInputText = styled('span', {
  '@bp2': {
    fontSize: '1.4rem',
  },
  '@bp1': {
    fontSize: '1.1rem',
  },
  color: '#3E3D3D',
});
export const InputWrapperOne = styled('div', {
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'inherit',
  width: '100%',
  height: '100%',
  boxSizing: 'border-box',
  color: '#3E3D3D',
  fontFamily: 'Heebo-Bold',
  fontSize: '1em',
  justifyContent: 'inherit',
});

export const CalInputImgOne = styled('img', {
  '@bp2': {
    margin: '0',
    marginRight: '0.3125rem',
  },
  '@bp1': {
    margin: '0',
    marginRight: '0.3125rem',
  },

  height: '1.6875rem',
  width: '1.6875rem',
});

export const CalInputTextOne = styled('span', {
  '@bp2': {
    fontSize: '1.125rem',
    lineHeight: '1.6875rem',
  },
  '@bp1': {
    fontSize: '1.125rem',
    lineHeight: '1.6875rem',
  },
});

export const MainWrapper = styled('div', {
  position: 'absolute',
  zIndex: 50,
  width: '35.75rem',
  // width: "23.43rem",
  boxSizing: 'border-box',
  borderRadius: '0.3125rem',
  backgroundColor: 'white',
  padding: '0 1.4375rem 1.125rem 1.4375rem',
  boxShadow: '0px 20px 40px #00000040',
  fontFamily: 'Heebo-Regular',
});

export const CalendarHeaderWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Heebo-Bold',
  backgroundColor: '#EEE9E5',
  justifyContent: 'space-between',
  position: 'relative',
  color: '#405F6A',
  fontSize: '1.5rem',
  '@bp2': {
    height: '5.6875rem',
  },
  '@bp1': {
    height: '4rem',
  },

  padding: '0 1.4375rem',
  marginLeft: '-1.4375rem',
  marginRight: '-1.4375rem',
});

export const DateNumber = styled('div', {
  position: 'absolute',
  display: 'flex',
  top: '0.25rem',
  left: '0',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: 'Heebo-Bold',
  fontSize: '1rem',
  color: '#405F6A',
  height: '2.1875rem',
  width: '2.1875rem',
});
export const CurentDate = styled('div', {
  width: '8rem',
  color: '#405F6A',
  fontFamily: 'Heebo-Bold',
  fontSize: '1.5rem',
});
export const CurentDateWithArrows = styled('div', {
  display: 'flex',
  fontSize: '1.5rem',
});
export const Arrows = styled('div', {
  display: 'flex',
  gap: '0.6875rem',
});

export const CalImgWithNumber = styled('div', {
  position: 'relative',
  height: '2.3rem',
  width: '2.3rem',
});

export const CalImg = styled('img', {
  height: '2.3rem',
  width: '2.3rem',
});

export const ArrowImg = styled('img', {
  cursor: 'pointer',
  height: '2.1875rem',
  width: '2.1875rem',
});

export const WeekDaysWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  paddingTop: '1.1875rem',
  justifyContent: 'space-between',
});

export const WeekDayCell = styled('div', {
  height: 30,
  width: '2.4375rem',
  margin: 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: 'Heebo-Bold',
  fontSize: '1.25rem',
  color: '#242323',
});

export const CalendarMonthWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '1.75rem',
  '@bp2': {
    gap: '1.8rem',
  },
  '@bp1': {
    gap: '.5rem',
  },

  justifyContent: 'space-between',
});

export const CalendarContentWrapper = styled('div', {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  flexDirection: 'row',
});

export const CalendarButton = styled('div', {
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
  flexDirection: 'row',
});

export const Underline = styled('div', {
  width: '100%',
  borderTop: '1px solid #707070',
  marginTop: '0.71875rem',
  paddingBottom: '0.96875rem',
});

export const Button = styled('div', {
  display: 'flex',
  fontSize: '1rem',
  lineHeight: '1.5rem',
  padding: '0.75rem 1.5rem',
  color: 'white',
  backgroundColor: '#405F6A',
  borderRadius: '0.3125rem',
  cursor: 'pointer',
});

export const CalendarDayCell = styled('div', {
  height: '2.4375rem',
  width: '2.4375rem',
  fontSize: '1.375rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '0.3125rem',
  cursor: 'pointer',
  // margin: 2,

  variants: {
    variant: {
      default: {
        color: '#1B1B2F',
      },
      today: {
        color: '#E43F5A',
      },
      selected: {
        color: '#1B1B2F',
        backgroundColor: '#E7B1AD',
      },
      nextMonth: {
        color: '#DAE1E7',
      },
    },
  },
});
