import TypeEvent from './steps/TypeEvent';
import styles from '../../styleBasicForm.module.scss';
import WhatCelebrating from './steps/WhatCelebrating';
import ExistsPlace from './steps/ExistsPlace';
import InputStep from './steps/InputStep';
import DateStep from './steps/dateStep/DateStep';
import { Button, CircularProgress } from '@mui/material';
import classNames from 'classnames';
import PhoneStep from './steps/PhoneStep';
import { STEPS_ENUM } from './stepForm.config';
import AutoCompleteStep from './steps/autoCompleteStep/AutoCompleteStep';
import LoginWithPhoneModal from '../../../../../components/loginWithPhoneModal/LoginWithPhoneModal';
import { useStepFormLogic } from './hooks/useStepFormLogic';
import { IS_NUMBER_REGEX } from '../../../../../shared/regex';

const StepForm = () => {
  const {
    isExistsPlace,
    currentRole,
    isLoading,
    factoryHandleSubmit,
    handleSubmit,
    handleCloseModal,
    currentStep,
    codeModalIsOpen,
  } = useStepFormLogic();
  return (
    <div className={styles.steps_container}>
      <TypeEvent />
      {currentStep >= STEPS_ENUM.WHAT_CELEBRATING && <WhatCelebrating />}
      {currentStep >= STEPS_ENUM.EXISTS_PLACE && <ExistsPlace />}
      {currentStep >= STEPS_ENUM.DATE_SELECTION  && <DateStep isExistsPlace = {isExistsPlace}/>}
      {currentStep >= STEPS_ENUM.COUNT_GUESTS && isExistsPlace && (
        <InputStep
          name="countGuests"
          label="כמות מוזמנים"
          sx={{ height: { xs: '42px', sm: '53px' } }}
          placeholder="הכנס את מספר המוזמנים שלך"
          typeInput="number"
          mineStep={STEPS_ENUM.COUNT_GUESTS}
          nextStep={STEPS_ENUM.WHERE_EVENT}
          rules={{
            min: { value: 1, message: 'ערך מינימלי 1' },
            required: { value: true, message: 'שדה נדרש' },
            pattern: { value: IS_NUMBER_REGEX, message: 'ערך לא חוקי' },
          }}
        />
      )}
      {currentStep >= STEPS_ENUM.WHERE_EVENT && isExistsPlace && (
        <AutoCompleteStep
          name="whereEvent"
          mineStep={STEPS_ENUM.WHERE_EVENT}
          nextStep={!currentRole ? STEPS_ENUM.PHONE : STEPS_ENUM.SUBMIT}
          label="איפה האירוע?"
          placeholder="הקלד את שם האולם"
          rules={{
            required: { value: true, message: 'שדה נדרש' },
          }}
        />
      )}
      {currentStep >= STEPS_ENUM.PHONE && !currentRole && <PhoneStep />}
      {currentStep >= STEPS_ENUM.SUBMIT && (
        <div className={classNames(styles.one_step, styles.submit_container)}>
          <Button
            disabled={isLoading}
            type="submit"
            onClick={factoryHandleSubmit}
            variant="contained"
            sx={{
              backgroundColor: '#E7B1AD',
              lineHeight: '27px',
              // minWidth: '153px',
              maxWidth: { xs: '130px', md: '153px' },
              width: '100%',
              height: { xs: '45px', sm: '53px' },
            }}
          >
            {isLoading ? (
              <div
                style={{ color: '#E7B1AD', height: '100%', display: 'flex', alignItems: 'center' }}
              >
                <CircularProgress color="inherit" size={20} />
              </div>
            ) : (
              'בואו נצא לדרך'
            )}
          </Button>
        </div>
      )}

      <LoginWithPhoneModal
        open={codeModalIsOpen}
        onlyCode
        overridedOnConfirm={handleSubmit}
        handleClose={handleCloseModal}
      />
    </div>
  );
};

export default StepForm;
