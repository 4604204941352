import styles from "./PersonalSupplierArea.module.scss";
// import Chat from "../../pages/Chat/Chat";
//import Footer from "../../components/footer/Footer";
import {useEffect, useState} from "react";
import person from "../../../assets/images/person_black.svg"
import person_active from "../../../assets/images/person_pink.svg"
import line from "../../../assets/images/line_black.svg"
import business from "../../../assets/images/business_black.svg"
import business_active from "../../../assets/images/business_pink.svg"
import calendar from "../../../assets/images/calendar_black.svg"
import calendar_active from "../../../assets/images/calendar-pink.png"
import chat from "../../../assets/images/chat_black.svg"
import chat_active from "../../../assets/images/chat_pink.svg"
import { useLocation } from "react-router-dom";
// import Documents from "./documents/Documents";
import { Outlet } from 'react-router-dom'
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import MetaFavicon from '../../components/metaComponents/metaFavicon'
import useAuth from "../../hooks/useAuth";


function PersonalSupplierArea() {
  const {auth} = useAuth();
    let currentRole = auth.roles[0];
    let { pathname } = useLocation();
    const [tab, setAppState] = useState({ data: [
        {name:'person', isActive: false},
        {name:'business', 
          isActive: (
            (pathname === "/personal-supplier/change-supplier-business") || 
            (pathname === "/personal-supplier/images")
          ) ? true : false},
        {name:'chat', isActive: pathname.indexOf('chat') !== -1},
        {name:'calendar', 
          isActive: 
            (pathname === "/personal-supplier/calendar")? true : false
        },
        {name:'line', isActive: false},
        {name:'business2', 
          isActive: pathname === "/personal-supplier/change-label-business" ? true : false
        }], isLoading: false});

    function changeActiveTab(tabName){
        let newTab = tab.data.map((val) => {
            if(val.name === tabName){
               val.isActive = true;
            } else {
               val.isActive = false;
            }
            return val
        })
        setAppState({data:newTab,isLoading:false})
    }

    useEffect(() => {
        if((pathname === "/personal-supplier/calendar")){
            changeActiveTab('calendar')
        }  else if((pathname === "/personal-supplier/change-supplier")){
            changeActiveTab('person')
        }  else if((pathname === "/personal-supplier/change-supplier-business")|| (pathname === "/personal-supplier/images" && currentRole !== 'LABEL')){
            changeActiveTab('business')
        }  else if((pathname.indexOf('/personal-supplier/chat') !== -1) || (pathname === '/personal-supplier/chats')){
            changeActiveTab('chat')
        }  else if((pathname === "/personal-supplier/documents")){
            changeActiveTab('line')
        }  else if((pathname === "/personal-supplier/change-label-business") || (pathname === "/personal-supplier/images" && currentRole === 'LABEL')){
          changeActiveTab('business2')
      } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[pathname])

  return (
    <>
       <MetaFavicon />
      <div className={styles.tab}>
          <div className={styles.block}>
          { tab.data[0].isActive === false &&  <NavLink onClick={()=>{changeActiveTab('person')}} to="change-supplier"><img alt="person" src={person} className={styles.img} /></NavLink> }
          { tab.data[0].isActive === true &&  <NavLink onClick={()=>{changeActiveTab('person')}} to="change-supplier"><img alt="person" src={person_active} className={styles.img} /></NavLink> }
            <div className={tab.data[0].isActive === false ? styles.textBlock : styles.textBlockActive}>  <NavLink onClick={()=>{changeActiveTab('person')}} to="change-supplier">פרטים אישיים </NavLink></div> 
          </div>
        
          <div className={styles.block}>
          { tab.data[1].isActive === false && <NavLink onClick={()=>{changeActiveTab('business')}} to="/personal-supplier/change-supplier-business"><img alt="business" src={business} className={styles.img} /> </NavLink>}
          { tab.data[1].isActive === true && <NavLink onClick={()=>{changeActiveTab('business')}} to="/personal-supplier/change-supplier-business"><img alt="business" src={business_active} className={styles.img} /> </NavLink>}
            <div className={tab.data[1].isActive === false ? styles.textBlock : styles.textBlockActive}> <NavLink onClick={()=>{changeActiveTab('business')}} to="/personal-supplier/change-supplier-business">פרטי העסק </NavLink></div>
          </div>
         {currentRole === 'LABEL' && <div className={styles.block}>
          { tab.data[5].isActive === false && <NavLink onClick={()=>{changeActiveTab('business2')}} to="/personal-supplier/change-label-business"><img alt="business2" src={business} className={styles.img} /> </NavLink>}
          { tab.data[5].isActive === true && <NavLink onClick={()=>{changeActiveTab('business2')}} to="/personal-supplier/change-label-business"><img alt="business2" src={business_active} className={styles.img} /> </NavLink>}
            <div className={tab.data[5].isActive === false ? styles.textBlock : styles.textBlockActive}> <NavLink onClick={()=>{changeActiveTab('business2')}} to="/personal-supplier/change-label-business">שליטה על ספק</NavLink></div>
          </div> }
        <div className={styles.block}>
        { tab.data[2].isActive === false && <NavLink to="/personal-supplier/chats" onClick={()=>{changeActiveTab('chat')}} >
          <img alt="chat" src={chat} className={styles.img} /> 
          </NavLink>}
        { tab.data[2].isActive === true && <NavLink onClick={()=>{changeActiveTab('chat')}} to="/personal-supplier/chats"><img alt="chat" src={chat_active} className={styles.img} /></NavLink> }
          <div className={tab.data[2].isActive === false ? styles.textBlock : styles.textBlockActive}><NavLink onClick={()=>{changeActiveTab('chat')}} to="/personal-supplier/chats"> מערכת פניות </NavLink></div>
        </div>
        <div className={styles.block}>
        { tab.data[3].isActive === false && <NavLink to="/personal-supplier/calendar" onClick={()=>{changeActiveTab('calendar')}}>
            <img alt="calendar" src={calendar} className={classNames(styles.img,styles.calendar)} />
          </NavLink>
           }
        { tab.data[3].isActive === true && <NavLink to="/personal-supplier/calendar" onClick={()=>{changeActiveTab('calendar')}} >
          <img alt="calendar" src={calendar_active} className={classNames(styles.img,styles.calendar)} />
        </NavLink>
        }
          <div className={tab.data[3].isActive === false ? styles.textBlock : styles.textBlockActive}>
            <NavLink onClick={()=>{changeActiveTab('calendar')}} to="/personal-supplier/calendar">
              {"עדכון יומן"}
            </NavLink>
            </div>
        </div>


          <div className={styles.block}>
          <NavLink to="/personal-supplier/documents"><img alt="line" src={line} className={styles.img} /> </NavLink>
            <div className={tab.data[4].isActive ? styles.textBlockDocumentActive : styles.textBlock }> <NavLink onClick={()=>{changeActiveTab('line')}} to="/personal-supplier/documents"> מסמכים </NavLink></div>
          </div>
      </div>
      <hr className={styles.line}></hr>
      { tab.data[0].isActive === true && 
        <></>
      }
      { tab.data[1].isActive === true && 
        <div className={styles.content}>
        </div> }
      { tab.data[2].isActive === true && 
        <div className={styles.content}>
          
        </div> }
      { tab.data[3].isActive === true && 
        <div className={styles.content}>
        {/* <Chat/> */}
        </div> }
      {/* { tab.data[4].isActive === true && 
        <div className={styles.content}>
        
        </div> } */}
      <main>
          <Outlet />
      </main>
      {/* <div className={styles.desctop}> 
        < Footer />
      </div> */}
    </>
    );
  }

export default PersonalSupplierArea;
