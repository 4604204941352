import { Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Card from './Card';
import styles from '../styleFeedback.module.scss';
import axios from 'axios';
import dayjs from 'dayjs';

const cards = [
  {
    id: 0,
    title: 'חבל שלא ידעתי על Event-Do קודם',
    howMuchStars: 5,
    message:
      'לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית קונסקטורר אדיפיסינג אלית. סת אלמנקום ניסי נון ניבאה. דס איאקוליס וולופטה דיאם. וסטיבולום אט דולור, קראס אגת לקטוס וואל אאוגו וסטיבולום סוליסי טידום בעליק. קונדימנטום קורוס בליקרה, נונסטי קלובר בריקנה סטום, לפריקך תצטריק לרטי.',
    who: 'שיר ומיכאל',
    date: '03.01.2022',
  },
  {
    id: 1,
    title: 'חבל שלא ידעתי על Event-Do קודם',
    howMuchStars: 5,
    message:
      'לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית קונסקטורר אדיפיסינג אלית. סת אלמנקום ניסי נון ניבאה. דס איאקוליס וולופטה דיאם. וסטיבולום אט דולור, קראס אגת לקטוס וואל אאוגו וסטיבולום סוליסי טידום בעליק. קונדימנטום קורוס בליקרה, נונסטי קלובר בריקנה סטום, לפריקך תצטריק לרטי.',
    who: 'שיר ומיכאל',
    date: '03.01.2022',
  },
  {
    id: 2,
    title: 'חבל שלא ידעתי על Event-Do קודם',
    howMuchStars: 5,
    message:
      'לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית קונסקטורר אדיפיסינג אלית. סת אלמנקום ניסי נון ניבאה. דס איאקוליס וולופטה דיאם. וסטיבולום אט דולור, קראס אגת לקטוס וואל אאוגו וסטיבולום סוליסי טידום בעליק. קונדימנטום קורוס בליקרה, נונסטי קלובר בריקנה סטום, לפריקך תצטריק לרטי.',
    who: 'שיר ומיכאל',
    date: '03.01.2022',
  },
];
const Cards = () => {
  const [cardsBe,setCardsBe] = useState([])
  const getCardsBe = async () => {
     await axios(`${process.env.REACT_APP_API_PATH}main/about/us/feedback/page?page=0&size=100`).then(res => {
      if(res){
        const filteredCards = res?.data?.content?.filter(card => card.isShow) || []
        setCardsBe(filteredCards.map(oneCard => ({
          ...oneCard,
          title:oneCard.header,
          howMuchStars:5,
          message:oneCard.description,
          who:oneCard.clientName,
          date:dayjs(new Date(oneCard.creationDate)).format('DD.MM.YYYY')
        })))
      }
     })
  }
  useEffect(() => {
    getCardsBe()
  },[])
  const currentCardsList = cardsBe.length !== 0 ? cardsBe : cards
  return (
    <div className={styles.cards_container}>
      {currentCardsList.map((oneCard, i) => (
        <React.Fragment key={`feedback-card-${oneCard.id}`}>
          <Card card={oneCard} />
          {i !== currentCardsList.length - 1 && (
            <Divider sx={{ height: '250px', marginTop: '6px' }} orientation="vertical" flexItem />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Cards;
