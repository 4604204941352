import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../api/axios";

export const getLabelSuppliers = createAsyncThunk(
  "changeSupplier/getSupplierData",
  async function ({axiosPrivate}, {rejectWithValue}) {
    // console.log("getAll suppliers2")
    try {
      const response = await axiosPrivate.get(`/supplier/inferior/label`);
      const data = await response.data;
      return data === undefined ? null : data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const changeSupPremission = createAsyncThunk(
  "changeSupplier/getSupplierData",
  async function ({data1, axiosPrivate}, {rejectWithValue}) {
    const supplierId = data1.id
    try {
      const response = await axiosPrivate.put(`/supplier/optional/label?supId=${supplierId}`,
        data1
      );
      const data = await response.data;
      return data === undefined ? null : data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getSupplierData = createAsyncThunk(
  "changeSupplier/getSupplierData",
  async function ({axiosPrivate}, {rejectWithValue}) {
    
    try {
      const response = await axiosPrivate.get(`/supplier/personal/details`);
      const data = await response.data;
      return data === undefined ? null : data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const removeSupplier = createAsyncThunk(
  'changeSupplier/removeSupplier',
  async ({axiosPrivate}, {rejectWithValue}) => {

    try{
      const response = await axiosPrivate.delete(`/supplier/delete`);
      const data = await response.data;
      return data === undefined ? null : data;
    }catch (error){
      return rejectWithValue(error)
    }

  }
);

export const saveSupplier = createAsyncThunk(
  'changeSupplier/saveSupplier',
  async ({data1, axiosPrivate}, {rejectWithValue}) => {

    try{
      const {email, name, phoneNumber, password, oldPassword, passwordConfirm} = data1;
    
      const response = await axiosPrivate.put(`/supplier/personal/details`, 
        {email, name, phoneNumber, password: oldPassword, newPassword: password, confirmPassword: passwordConfirm});
      const data = await response.data;
      return data === undefined ? null : data;
    }catch (error){
      return rejectWithValue(error)
    }

  }
);

export const getRegions = createAsyncThunk(
  'changeSupplier/getRegions', 
  async function (_, {rejectWithValue}) {
    try {
      const response = await axios.get(`/venue/details/locations`);
      /* console.log(response) */
      if (response.status!==200) {
        throw new Error('Server Error');
      }
      const data = await response.data;
      /* console.log(data); */
      return data === undefined ? null : data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getEvents = createAsyncThunk(
  'changeSupplier/getEvents', 
  async function ({categoryId, axiosPrivate, id}, {rejectWithValue}) {
    try {
      let response
      if(categoryId === 1 ){
        response = id ? await axiosPrivate.get(`/venue/eventTypes?supplierId=${id}`):
          await axiosPrivate.get(`/venue/eventTypes`);
      } else{
        response = id ? await axiosPrivate.get(`/supplier/category/${categoryId}/eventTypes?supplierId=${id}`) : 
        await axiosPrivate.get(`/supplier/category/${categoryId}/eventTypes`);
      }
      
      /* console.log(response) */
      if (response.status!==200) {
        throw new Error('Server Error');
      }
      const data = await response.data;
      /* console.log(data); */
      return data === undefined ? null : data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getServices = createAsyncThunk(
  'changeSupplier/getServices', 
  async function ({categoryId, eventTypeId, axiosPrivate, id}, {rejectWithValue}) {
    try {
      const response = id ? await axiosPrivate.get(`/supplier/services?categoryId=${categoryId}&eventTypeId=${eventTypeId}&supplierId=${id}`)
      : await axiosPrivate.get(`/supplier/services?categoryId=${categoryId}&eventTypeId=${eventTypeId}`);
      /* console.log(response) */
      if (response.status!==200) {
        throw new Error('Server Error');
      }
      const data = await response.data;
      /* console.log(data); */
      return data === undefined ? null : data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getSupplierBusinessData = createAsyncThunk(
  "changeSupplier/getSupplierBusinessData",
  async function ({axiosPrivate, id}, {rejectWithValue}) {
    console.log(axiosPrivate,id);
    try {
      const response = id ? await axiosPrivate.get(`/supplier/personal/business?supplierId=${id}`)
        : await axiosPrivate.get(`/supplier/personal/business`);
      const data = await response.data;
      return data === undefined ? null : data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);


export const saveSupplierBusiness = createAsyncThunk(
  'changeSupplier/saveSupplierBusiness',
  async ({data1, axiosPrivate, id}, {rejectWithValue}) => {

    try{
      const {businessName, hasDocument, geoLocationsId, prePayment, businessAddress, contractBusinessName, companyRegistrationNumber, businessDescription, contractRequired, documentName} = data1;
      console.log('contractBusinessName', contractBusinessName);
      const response = id ? await axiosPrivate.put(`/supplier/personal/business?supplierId=${id}`, 
      {businessName,hasDocument: hasDocument? true: false, geoLocationsId, businessAddress, prePayment, contractBusinessName, companyRegistrationNumber, businessDescription, contractRequired, documentName})
      : await axiosPrivate.put(`/supplier/personal/business`, 
      {businessName,hasDocument: hasDocument? true: false, geoLocationsId, businessAddress, prePayment, contractBusinessName, companyRegistrationNumber, businessDescription, contractRequired, documentName});
      const data = await response.data;
      return data === undefined ? null : data;
    }catch (error){
      return rejectWithValue(error)
    }

  }
);

export const saveSupplierContract = createAsyncThunk(
  'changeSupplier/saveSupplierContract',
  async ({contract, axiosPrivate, id}, {rejectWithValue}) => {

    try{
      const formData = new FormData();
        formData.append("file", contract["file"]);
        if(!contract.url) return
        const response = id ? await axiosPrivate.post(`/supplier/personal/document/upload?supplierId=${id}`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        }) :
        await axiosPrivate.post(`/supplier/personal/document/upload`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      // const {businessName, geoLocationsId, businessAddress, contractBusinessName, companyRegistrationNumber, businessDescription, contractRequired} = data1;
    
      // const response = await axiosPrivate.put(`/supplier/personal/business`, 
      // {businessName, geoLocationsId, businessAddress, contractBusinessName, companyRegistrationNumber, businessDescription, contractRequired});
      const data = await response.data;
      return data === undefined ? null : data;
    }catch (error){
      return rejectWithValue(error)
    }

  }
);

export const deleteSupplierContract = createAsyncThunk(
  'changeSupplier/deleteSupplierContract',
  async ({axiosPrivate, id}, {rejectWithValue}) => {
    // console.log(12344)
    try{
      const response = id ? await axiosPrivate.delete(`/supplier/personal/document/delete?supplierId=${id}`) :
        await axiosPrivate.delete(`/supplier/personal/document/delete`);
      const data = await response.data;
      return data === undefined ? null : data;
    }catch (error){
      return rejectWithValue(error)
    }

  }
);

export const saveSupplierService = createAsyncThunk(
  'changeSupplier/saveSupplierService',
  async ({data1, axiosPrivate, id}, {rejectWithValue}) => {

    try{
      const response = id ? await axiosPrivate.post(`/supplier/service?supplierId=${id}`, 
      data1) : await axiosPrivate.post(`/supplier/service`, 
      data1);
      const data = await response.data;
      return data === undefined ? null : data;
    }catch (error){
      return rejectWithValue(error)
    }

  }
);
export const saveSupplierServiceCustom = createAsyncThunk(
  'changeSupplier/saveSupplierService',
  async ({data1, axiosPrivate, id}, {rejectWithValue}) => {

    try{
      const response = id ? await axiosPrivate.post(`/supplier/service/custom?supplierId=${id}`, 
      data1) : await axiosPrivate.post(`/supplier/service/custom`, 
      data1);
      const data = await response.data;
      return data === undefined ? null : data;
    }catch (error){
      return rejectWithValue(error)
    }

  }
);

export const saveSupplierFlash = createAsyncThunk(
  'changeSupplier/saveSupplierFlash',
  async ({data1, axiosPrivate, id}, {rejectWithValue}) => {

    try{
      const response = id ? await axiosPrivate.put(`/supplier/eventType/discount?supplierId=${id}`, 
      data1) : await axiosPrivate.put(`/supplier/eventType/discount`, 
      data1);
      const data = await response.data;
      return data === undefined ? null : data;
    }catch (error){
      return rejectWithValue(error)
    }

  }
);

export const getWeekends = createAsyncThunk(
  'changeSupplier/weekends', 
  async function (_, {rejectWithValue}) {
    try {
      const response = await axios.get(`/venue/details/event`);
      /* console.log(response) */
      if (response.status!==200) {
        throw new Error('Server Error');
      }
      const data = await response.data;
      /* console.log(data); */
      return data === undefined ? null : data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);
export const getVenueType = createAsyncThunk(
  'changeSupplier/venueType', 
  async function (_, {rejectWithValue}) {
    try {
      const response = await axios.get(`/venue/details/type`);
      /* console.log(response) */
      if (response.status!==200) {
        throw new Error('Server Error');
      }
      const data = await response.data;
      /* console.log(data); */
      return data === undefined ? null : data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);
export const getKosher = createAsyncThunk(
  'changeSupplier/kosher', 
  async function (_, {rejectWithValue}) {
    try {
      const response = await axios.get(`/venue/details/kosher`);
      /* console.log(response) */
      if (response.status!==200) {
        throw new Error('Server Error');
      }
      const data = await response.data;
      /* console.log(data); */
      return data === undefined ? null : data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);
export const getParkingOption = createAsyncThunk(
  'changeSupplier/parkingOption', 
  async function (_, {rejectWithValue}) {
    try {
      const response = await axios.get(`/venue/details/parking`);
      /* console.log(response) */
      if (response.status!==200) {
        throw new Error('Server Error');
      }
      const data = await response.data;
      /* console.log(data); */
      return data === undefined ? null : data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getSupplierBusinessVenueData = createAsyncThunk(
  "changeSupplier/getSupplierBusinessVenueData",
  async function ({eventTypeId, axiosPrivate, id}, {rejectWithValue}) {
    
    try { 
      const response = id ? await axiosPrivate.get(`/venue/supplier/eventType/${eventTypeId}?supplierId=${id}`):
      await axiosPrivate.get(`/venue/supplier/eventType/${eventTypeId}`);
      const data = await response.data;
      return data === undefined ? null : data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const saveSupplierVenueBusiness = createAsyncThunk(
  'changeSupplier/saveSupplierVenueBusiness',
  async ({sendObj, axiosPrivate, id}, {rejectWithValue}) => {

    try{ 
      const response = id ? await axiosPrivate.put(`/venue/supplier/eventType?supplierId=${id}`, sendObj):
        await axiosPrivate.put(`/venue/supplier/eventType`, sendObj);
      const data = await response.data;
      return data === undefined ? null : data;
    }catch (error){
      return rejectWithValue(error)
    }

  }
);

export const getAllImages = createAsyncThunk(
  'changeSupplier/getAllImages',
  async ({axiosPrivate, id}, {rejectWithValue}) => {
    // console.log(axiosPrivate)

    try{
      const response = id ? await axiosPrivate.get(`/supplier/image?supplierId=${id}`) :
      await axiosPrivate.get(`/supplier/image`);
      const data = await response.data;
      return data === undefined ? null : data;
    }catch (error){
      return rejectWithValue(error)
    }

  }
);

// dispatch(sendImages({supplierImages: supplierImages, axiosPrivate, supplierId: (JSON.parse(auth.user).id)}))


export const sendImages = createAsyncThunk(
  'changeSupplier/sendImages',
  async function ({supplierImages, axiosPrivate, supplierId, id}, {rejectWithValue}) {
    function timeout(delay) {
      return new Promise( res => setTimeout(res, delay) );
    }
    
    try {
      for (const [index, image] of supplierImages.entries()){
        if(index % 10 === 0 && index !== 0){
          await timeout(80)
        }
        const formData = new FormData();
        formData.append("file", image["file"]);
        if(image.url){
          try {
            id ? await axiosPrivate.post(`/supplier/upload/image?order=${image.order}&defaultImage=${image.defaultImage}&supplierId=${id}`, formData, {
              headers: { "Content-Type": "multipart/form-data" },
            }) : 
            await axiosPrivate.post(`/supplier/upload/image?supplierId=${supplierId}&order=${image.order}&defaultImage=${image.defaultImage}`, formData, {
              headers: { "Content-Type": "multipart/form-data" },
            });
          } catch (error) {
            
          }
        }
      }

      // supplierImages?.forEach(async(image, index) => {

      //   // console.log(index)
      //   // if (index % 2 === 0){
      //   //   await timeout(1000)
      //   // }
      //   // console.log("after timout")
      //   const formData = new FormData();
      //   formData.append("file", image["file"]);
      //   if(!image.url) return
      //   id ? await axiosPrivate.post(`/supplier/upload/image?order=${image.order}&defaultImage=${image.defaultImage}&supplierId=${id}`, formData, {
      //     headers: { "Content-Type": "multipart/form-data" },
      //   }) : 
      //   await axiosPrivate.post(`/supplier/upload/image?supplierId=${supplierId}&order=${image.order}&defaultImage=${image.defaultImage}`, formData, {
      //     headers: { "Content-Type": "multipart/form-data" },
      //   });
      // })
      return true
    } catch (error) {
      console.log(error)
      return rejectWithValue(error)
    }
  }
);

export const saveAllImages = createAsyncThunk(
  'changeSupplier/saveAllImages',
  async ({images, axiosPrivate, id}, {rejectWithValue}) => {

    try{
      const response = id ? await axiosPrivate.put(`/supplier/image/update?supplierId=${id}`, 
      images) :
      await axiosPrivate.put(`/supplier/image/update`, 
      images);
      const data = await response.data;
      return data === undefined ? null : data;
    }catch (error){
      return rejectWithValue(error)
    }

  }
);


export const sendYouImages = createAsyncThunk(
  'supplierRegistration/sendYouImages',
  async ({axiosPrivate, allYouImages, id}, {rejectWithValue}) => {
    let youImages = allYouImages?.map(image => {
      return image.url;
    })

    try{
      const response = id ? await axiosPrivate.post(`/supplier/video?supplierId=${id}`, youImages) :
      await axiosPrivate.post(`/supplier/video`, youImages);
      const data = await response.data;
      return data === undefined ? null : data;
    }catch (error){
      return rejectWithValue(error)
    }

  }
);

export const getYouImages = createAsyncThunk(
  "changeSupplier/getYouImages",
  async function ({axiosPrivate, id}, {rejectWithValue}) {
    
    try {
      const response = id ? await axiosPrivate.get(`/supplier/video?supplierId=${id}`) :
      await axiosPrivate.get(`/supplier/video`) ;
      const data = await response.data;
      return data === undefined ? null : data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);





const changeSupplierSlice = createSlice({
  name: "changeSupplier",
  initialState: {
    error: null,
    loadingStatus: null,
  },
  reducers: {
    // setSelectedEvents(state,action) {
    //   state.selectedEvents = action.payload;
    // },
    // setFilterDate(state, action) {
      
    //   console.log(action.payload)
    //   state.filterDate = action.payload;
    // },
    // clearForm(state,action) {
    //   state.filterDate = '';
    //   state.selectedEvents=[];
    // },
  },
  extraReducers: {
    
    // [getAllEvents.fulfilled]: (state, action) => {
    //   console.log(action.payload)
    //   state.allEvents = action.payload;
    //   // state.email = action.payload.email;
    // },
    // [downLoadDocument.fulfilled]: (state, action) => {
    //   console.log("ok")
    // }
    
  },
});

// export const {setFilterDate, clearForm, setSelectedEvents } = changeSupplierSlice.actions;

export default changeSupplierSlice.reducer;
