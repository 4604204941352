import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/axios";

export const sendGoogleCode = createAsyncThunk(   
  "calendar/sendGoogleCode",
  async function ({code, axiosPrivate, supplierId}, {rejectWithValue}) {
    
    try {
      const response = await axiosPrivate.post(supplierId ? `/calendar/google/auth?supplierId=${supplierId}` : `/calendar/google/auth`, {code});
      // console.log(response)
      if (response.status!==200) {
        throw new Error('Server Error');
      }
      const data = await response.data;
      // console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const sendGoogleCodeReg = createAsyncThunk(   
  "calendar/sendGoogleCodeReg",
  async function ({code, axiosPrivate, token, supId}, {rejectWithValue}) {
    
    try {
      const response = await axios.post(`/calendar/join/google/auth?temporaryToken=${token}&supplierId=${supId}`, {code})
      // console.log(response)
      if (response.status!==200) {
        throw new Error('Server Error');
      }
      const data = await response.data;
      // console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getIsSinchronized = createAsyncThunk(
  "calendar/getIsSinchronized",
  async function ({axiosPrivate, supplierId}, {rejectWithValue}) {
    
    try {
      const response = await axiosPrivate.get(supplierId ? `/calendar/google/user/info?supplierId=${supplierId}` :  `/calendar/google/user/info`);
      // console.log(response)
      if (response.status!==200) {
        throw new Error('Server Error');
      }
      const data = await response.data;
      // console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getLabelSuppliers = createAsyncThunk(
  "calendar/getSupplierData",
  async function ({axiosPrivate}, {rejectWithValue}) {
    // console.log("getAll suppliers2")
    try {
      const response = await axiosPrivate.get(`/supplier/inferior/label`);
      const data = await response.data;
      return data === undefined ? null : data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getPermissionById = createAsyncThunk(
  "calendar/getPermissionById",
  async function ({axiosPrivate, id}, {rejectWithValue}) {
    
    try {
      const response = await axiosPrivate.get(`/supplier/check/optional/label?id=${id}`);
      // console.log(response)
      if (response.status!==200) {
        throw new Error('Server Error');
      }
      const data = await response.data;
      // console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const sendAllIntervals = createAsyncThunk(
  "calendar/sendAllIntervals",
  async function ({intervals, axiosPrivate, supplierId}, {rejectWithValue}) {
    // console.log(data1)
    try {
      const response = await axiosPrivate.post(supplierId ? `/calendar/google/events?supplierId=${supplierId}` : `/calendar/google/events`, [...intervals]);
      // console.log(response)
      if (response.status!==200) {
        throw new Error('Server Error');
      }
      const data = await response.data;
      // console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);


export const sendAllIntervalsReg = createAsyncThunk(
  "calendar/sendAllIntervalsReg",
  async function ({intervals, axiosPrivate, token, supId}, {rejectWithValue}) {
    // console.log(data1)
    try {
      const response = await axios.post(`/calendar/join/google/events?temporaryToken=${token}&supplierId=${supId}`, [...intervals]);
      // console.log(response)
      if (response.status!==200) {
        throw new Error('Server Error');
      }
      const data = await response.data;
      // console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);
export const getIntervals3Month = createAsyncThunk(
  "calendar/getIntervals3Month",
  async function ({axiosPrivate, date, supplierId}, {rejectWithValue}) {
    // console.log(data1)
    try {
      const response = await axiosPrivate.get(supplierId ? `/calendar/events?date=${date}&supplierId=${supplierId}` : `/calendar/events?date=${date}`);
      // console.log(response)
      if (response.status!==200) {
        throw new Error('Server Error');
      }
      const data = await response.data;
      // console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const updateInterval = createAsyncThunk(
  //can work fore label
  "calendar/updateInterval",
  async function ({eventId, interval ,axiosPrivate, supplierId}, {rejectWithValue}) {
    try {
      const response = await axiosPrivate.put(supplierId ? `/calendar/event/${eventId}?supplierId=${supplierId}` : `/calendar/event/${eventId}`, interval);
      // console.log(response)
      if (response.status!==200) {
        throw new Error('Server Error');
      }
      const data = await response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const deleteIntervals = createAsyncThunk(
  "calendar/deleteInterval",
  async function ({interval, axiosPrivate, supplierId}, {rejectWithValue}) {
    try {
      // console.log(interval)
      const response = await axiosPrivate.delete(supplierId ? `/calendar/events?eventIds=${interval}&supplierId=${supplierId}`: `/calendar/events?eventIds=${interval}`);
      // console.log(response)
      if (response.status!==200) {
        throw new Error('Server Error');
      }
      const data = await response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);


const calendarSlice = createSlice({
  name: "calendar",
  initialState: {
    email: '',
    allInterval: [],
    error: null,
    loadingStatus: null,
  },
  reducers: {
    setAllInterval(state,action) {
      state.allInterval = action.payload;
    },
  },
  extraReducers: {
    
    [sendGoogleCode.fulfilled]: (state, action) => {
      state.loadingStatus = "resolved";
      state.email = action.payload.email;
    },
    [sendAllIntervals.fulfilled]: (state, action) => {
      console.log("ok")
    }
    
  },
});

export const { setAllInterval } = calendarSlice.actions;

export default calendarSlice.reducer;
