import {useNavigate } from "react-router";
import styles from "./finalScreen.module.scss";
const FinalScreen = (props) => {
  const navigate = useNavigate();
  
  return <div className={styles.container}>
    <div className={styles.form}>
      <div className={styles.title}>
        <span>בהצלחה</span><span>!!</span>
      </div>

      <div className={styles.desctop}>
        <div className={styles.description}>
          {!(props.isLabel) ?<><span>הרשמתך התקבלה. אנא המתן לאישור מנהל האתר על הוספתך למאגר הספקים של אוונטדו</span>.</>
          :<span>מזל טוב! נוספת כלייבל למאגר הספקים של איוונטדו</span>}
        </div>
        {/* <div className={styles.description}>
          כעת תוכל להתעדכן בפניות חדשות דרך האיזור האישי 
        </div> */}
      </div>
      <div className={styles.mobile}>
        { props.isLabel ? 
        <span>
          {"מזל טוב! נוספת כלייבל למאגר הספקים של איוונטדו כעת תוכל להתעדכן בפניות חדשות דרך האיזור האישי"}
        </span>
        :
        <span>
          {`.מזל טוב! נוספת למאגר הספקים של 
איוונטדו כעת תוכל להתעדכן בפניות 
חדשות דרך האיזור האישי`}
        </span>


        }
        
      </div>
      


      <div className={styles.buttonContainer}>
        <div className={styles.button} onClick={() => navigate("/personal-supplier")}>
          מעבר לאיזור האישי 
        </div>
      </div>
    </div>
  </div>
  
  // <>
  //   {props.isLabel ?
  //     <h1>registrated label page</h1>
  //     :<h1>registrated supplier page</h1>
  //   }
  // </>
  
}

export default FinalScreen