import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import axios from "../../api/axios";


export const getEvents = createAsyncThunk(
  "changeUser/getEvents",
  async function ({axiosPrivate}, {rejectWithValue}) {
    try {
      const response = await axiosPrivate.get(`/user/personal/checkLists`);
      const data = await response.data;
      return data === undefined ? null : data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getContracts = createAsyncThunk(
  "changeUser/getContracts",
  async function ({axiosPrivate}, {rejectWithValue}) {
    try {
      const response = await axiosPrivate.get(`/user/personal/contracts`);
      const data = await response.data;
      return data === undefined ? null : data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getUserData = createAsyncThunk(
  "changeUser/getUserData",
  async function ({axiosPrivate}, {rejectWithValue}) {
    
    try {
      const response = await axiosPrivate.get(`/user/personal/details`);
      const data = await response.data;
      return data === undefined ? null : data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const removeUser = createAsyncThunk(
  'changeUser/removeUser',
  async ({axiosPrivate}, {rejectWithValue}) => {

    try{
      const response = await axiosPrivate.delete(`/user/delete`);
      const data = await response.data;
      return data === undefined ? null : data;
    }catch (error){
      return rejectWithValue(error)
    }

  }
);


export const removeEvent = createAsyncThunk(
  'changeUser/removeEvent',
  async ({axiosPrivate, contractId}, {rejectWithValue}) => {

    try{
      const response = await axiosPrivate.delete(`/user/checkList/delete?checkListId=${contractId}`);
      const data = await response.data;
      return data === undefined ? null : data;
    }catch (error){
      return rejectWithValue(error)
    }

  }
);


export const saveUser = createAsyncThunk(
  'changeUser/saveUser',
  async ({data1, axiosPrivate}, {rejectWithValue}) => {

    try{
      const {email, name, phoneNumber, password, oldPassword, passwordConfirm} = data1;
    
      const response = await axiosPrivate.put(`/user/personal/details`, 
        {email, name, phoneNumber, password: oldPassword, newPassword: password, confirmPassword: passwordConfirm});
      const data = await response.data;

      // const accessToken = data?.jwtAuthenticationResponseDto.accessToken;
      // const refreshToken = data?.jwtAuthenticationResponseDto.refreshToken;
      // const roles = [data?.supplierDto.supplierType];
      // const user = JSON.stringify(data?.supplierDto);
      // if (accessToken && refreshToken && roles && user) {
      //   setAuth({accessToken, refreshToken, user, roles})
      // }

      return data === undefined ? null : data;
    }catch (error){
      return rejectWithValue(error)
    }

  }
);





const changeUserSlice = createSlice({
  name: "changeUser",
  initialState: {
    

    error: null,
    loadingStatus: null,
  },
  reducers: {
    // setSelectedEvents(state,action) {
    //   state.selectedEvents = action.payload;
    // },
    // setFilterDate(state, action) {
      
    //   console.log(action.payload)
    //   state.filterDate = action.payload;
    // },
    // clearForm(state,action) {
    //   state.filterDate = '';
    //   state.selectedEvents=[];
    // },
  },
  extraReducers: {
    
    // [getAllEvents.fulfilled]: (state, action) => {
    //   console.log(action.payload)
    //   state.allEvents = action.payload;
    //   // state.email = action.payload.email;
    // },
    // [downLoadDocument.fulfilled]: (state, action) => {
    //   console.log("ok")
    // }
    
  },
});

export const {setFilterDate, clearForm, setSelectedEvents } = changeUserSlice.actions;

export default changeUserSlice.reducer;
