import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";

const loaderSlice = createSlice({
  name: "loader",
  initialState: {
    isShowLoader: false
  },
  reducers: {
    setLoader(state, action) {
      state.isShowLoader = action.payload;
    },
    
  },
});

export const { setLoader} = loaderSlice.actions;

export default loaderSlice.reducer;
