import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import styles from "./CategorySwiper.module.scss";
import "swiper/css";
import "swiper/css/pagination";

function CategorySwiper(props) {
  const { categories, currentCategory, selectedCategory } = props;
  const [my_swiper, set_my_swiper] = useState({});
  const [is_beginning, set_is_beginning] = useState(true);
  const [is_end, set_is_end] = useState(false);

  function setValues(ev) {
    set_is_end(ev.isEnd);
    set_is_beginning(ev.isBeginning);
    set_my_swiper(ev);
  }

  function goToNextSlide(ev) {
    my_swiper.slideNext();
  }

  function goToPrevSlide(ev) {
    my_swiper.slidePrev();
  }

  const stylesForLeftArrow = `${styles.arrow} ${
    is_end ? "disableSliderNextPrev" : ""
  }`;
  const stylesForRightArrow = `${styles.arrow} ${
    is_beginning ? "disableSliderNextPrev" : ""
  }`;

  return (
    <div className={styles.swiperSlideComponentContainer}>
      <div className={styles.swiperSlideContainer}>
        {categories?.length && <Swiper
          slidesPerView={"auto"}
  /*                   spaceBetween={15} */
          /* allowTouchMove={false} */
          centeredSlides={false}
          onInit={(ev) => {
            setValues(ev);
          }}
          onSlideChange={(ev) => {
            setValues(ev);
          }}
          onSliderMove={(ev) => {
            setValues(ev);
          }}
          onReachEnd={(ev) => {
            set_is_end(true);
            set_is_beginning(ev.isBeginning);
            // console.log("reachEnd: ", ev);
          }}
          onReachBeginning={(ev) => {
            set_is_end(false);
            set_is_beginning(ev.isBeginning);
          }}
          style={{ transform: "none" }}
        >
          {categories &&
            categories.map((slide) => (
              <SwiperSlide
                style={{ width: "auto" }}
                className={
                  currentCategory?.id === slide.id
                    ? styles.swiperSlideSelected
                    : styles.swiperSlide
                }
                key={slide.id}
                onClick={(ev) => selectedCategory(slide)}
              >
                <span style={{ userSelect: "none" }}>{slide.name}</span>
              </SwiperSlide>
            ))}
        </Swiper>}
      </div>
      <div className={styles.arrowContainer}>
        <div onClick={goToNextSlide} className={stylesForLeftArrow}>
          <button className={styles.arrowButton}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19.904"
              height="19.4"
              viewBox="0 0 19.904 19.4"
            >
              <path
                id="Icon_awesome-arrow-left"
                data-name="Icon awesome-arrow-left"
                d="M8.463,3.947l.986-.986a1.062,1.062,0,0,1,1.506,0l8.636,8.631a1.062,1.062,0,0,1,0,1.506l-8.636,8.636a1.062,1.062,0,0,1-1.506,0l-.986-.986a1.067,1.067,0,0,1,.018-1.524l5.353-5.1H1.066A1.064,1.064,0,0,1,0,13.058V11.636A1.064,1.064,0,0,1,1.066,10.57H13.833L8.48,5.47A1.06,1.06,0,0,1,8.463,3.947Z"
                transform="translate(19.904 22.047) rotate(180)"
                fill={is_end ? "#9c9c9c78" : "#A4A2A2"}
              />
            </svg>
          </button>
        </div>

        <div onClick={goToPrevSlide} className={stylesForRightArrow}>
          <button className={styles.arrowButton}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19.904"
              height="19.4"
              viewBox="0 0 19.904 19.4"
            >
              <path
                id="Icon_awesome-arrow-right"
                data-name="Icon awesome-arrow-right"
                d="M8.463,3.947l.986-.986a1.062,1.062,0,0,1,1.506,0l8.636,8.631a1.062,1.062,0,0,1,0,1.506l-8.636,8.636a1.062,1.062,0,0,1-1.506,0l-.986-.986a1.067,1.067,0,0,1,.018-1.524l5.353-5.1H1.066A1.064,1.064,0,0,1,0,13.058V11.636A1.064,1.064,0,0,1,1.066,10.57H13.833L8.48,5.47A1.06,1.06,0,0,1,8.463,3.947Z"
                transform="translate(0 -2.647)"
                fill={is_beginning ? "#9c9c9c78" : "#A4A2A2"}
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}

export default CategorySwiper;
