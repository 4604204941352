// import { fontSize } from "@mui/system";
//import { fontSize } from "@mui/system";
import { createStitches } from "@stitches/react";

export const { styled, css } = createStitches({
  media: {
    bp1: '(max-width: 60rem)',
    bp2: '(min-width: 60.0625rem)',
  },
});

export const Wrapper = styled("div", {
  display: 'flex',
  flexDirection: "column",
  position: 'relative',
  width: "100%",
  maxWidth: "62.5625rem",
  height: "100%",
  boxSizing: "border-box",
  fontFamily: "Heebo-Regular",
  justifyContent:"inherit",
  alignItems: "center",
  fontSize: "1em",
});

//
export const InputWrapper = styled("div", {
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  backgroundColor: "inherit",
  width: "100%",
  height: "100%",
  boxSizing: "border-box",
  color: "#A4A2A2",
  fontFamily: "Heebo-Regular",
  fontSize: "1em",
  justifyContent: "inherit",
});

export const CalInputImg = styled("img", {
  "@bp2": {
    margin: "0 1.2rem 0 0.87125rem",
  },
  "@bp1": {
    margin: "0 0rem 0 0.87125rem",
  },
  
  height:"2.3125rem",
  width:"2.3125rem",
  
});

export const CalInputText = styled("span", {
  
  "@bp2": {
    fontSize: "1.4rem",
  },
  "@bp1": {
    fontSize: "1.1rem",
  },
  color: "#3E3D3D",
});




export const MainWrapper = styled("div", {
  // position: 'absolute',
  zIndex: 50,
  
  width: "100%",
  // width: "23.43rem",
  boxSizing: "border-box",
  borderRadius: "0.3125rem",
  backgroundColor: "white",
   
   "@bp2": {
    padding: "0 5.25rem 5.0625rem 4.625rem",
    boxShadow: "0px 20px 40px #0000001A",
  },
   "@bp1": {
    padding: "0.6rem 0.75rem 1.875rem 0.75rem",
    boxShadow: "0px 0.3125rem 0.625rem #0000000D",
  },
  // padding: "0 1.4375rem 1.125rem 1.4375rem",
  
  fontFamily: "Heebo-Regular",
});

export const CalendarHeaderWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  // fontFamily: "Heebo-Bold",
  // fontFamily: "Heebo-Bold",
  backgroundColor: "#EEE9E5",
  justifyContent: "space-between",
  position: "relative",
  // color: "#405F6A",
  fontSize: "1.5rem",
  "@bp2": {
    height: "5.6875rem",
    padding: "0 1.71875rem 0 4.625rem",
    marginLeft: "-4.625rem",
    marginRight: "-5.25rem",
  },
  "@bp1": {
    height: "2.9375rem",
    padding: "0 1.1875rem 0 0.75rem",
    marginLeft: "-0.75rem",
    marginRight: "-0.75rem",
  },
  //  padding: "0 5.25rem 5.0625rem 4.625rem",
  
  
});

export const DateNumber = styled("div", {
  position: "absolute",
  display: "flex",
  
  left: "0",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: "Heebo-Bold",
  
  color: "#405F6A",
  

  "@bp2": {
    height:"2.1875rem",
    width:"2.1875rem",
    fontSize: "1rem",
    top: "0.25rem",
  },
  "@bp1": {
    height:"1.3rem",
    width:"1.3rem",
    fontSize: ".5624rem",
    top: "0.4rem",
  },
  
});
export const CurentDate = styled("div", {
  
  color: "#405F6A",
  fontFamily: "Heebo-Bold",
  "@bp2": {
     width: "8rem",
    fontSize: "1.5rem",
  },
   "@bp1": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "4rem",
    fontSize: "0.875rem",
  },
  
});
export const CurentDateWithArrows = styled("div", {
  display: "flex",
  fontSize: "1.5rem",
  
  
});
export const Arrows = styled("div", {
  
  display: "flex",
  alignItems: "center",
  gap: "0.6875rem",
  
});



export const CalImgWithNumber = styled("div", {
  position:"relative",

  "@bp2": {
    height:"2.3rem",
    width:"2.3rem",
 },
  "@bp1": {
    height:"1.375rem",
    width:"1.375rem",
 },
  
  
});

export const CalImg = styled("img", {
  "@bp2": {
    height:"2.3rem",
    width:"2.3rem",
 },
  "@bp1": {
    height:"1.375rem",
    width:"1.375rem",
 },
  
});

export const ArrowImg = styled("img", {
  cursor: "pointer",

  "@bp2": {
    height:"2.1875rem",
    width:"2.1875rem",
  },
    "@bp1": {
      height:"1rem",
      width:"1rem",
  },
  
  
});



export const Menu = styled("div", {
  zIndex: "3",
  backgroundColor: "white",
  right: "3.46875rem",
  top: "-1.67rem",
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  width: '25.25rem',
  maxHeight: "32.1875rem",
  overflow:"auto",
  padding: "2.4375rem 1.9375rem 1.8125rem 2.5rem",
  boxShadow: "0px 1.25rem 2.5rem #0000001A;"
  
});

export const MenuItem = styled("div", {
  position: "relative",
  // cursor: "pointer",
  paddingLeft: "1.675rem",
  paddingBottom: "1.125rem",
  variants: {
    variant: {
      default: {
        fontFamily: "Heebo-Regular",
      },
      selected: {
        fontFamily: "Heebo-Bold",
      },
      last: {
        paddingBottom: "1.4375rem",
      },
      lastSelected: {
        fontFamily: "Heebo-Bold",
        paddingBottom: "1.4375rem",
      }
    },
  },

});

export const MenuImgWrapper = styled("div", {
  position: "relative",
  "@bp2": {
    paddingLeft: "1.675rem",
  },
   "@bp1": {
    
  },
  
  
});

export const MenuImg = styled("img", {
  cursor: "pointer",
  "@bp2": {
    height:"0.875rem",
    width:"1.79375rem",
  },
   "@bp1": {
    height: "0.625rem",
    width: "1.25rem",
  },
  
});

export const MenuTitle = styled("span", {
  fontSize: "1.125rem",
  paddingTop: "1.4375rem",
  paddingBottom: "1.5625rem",
});

export const MenuEmailRow = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingBottom: "2.125rem"
});

export const MenuEmail = styled("span", {
  fontSize: "1.125rem"
});

export const MenuBtns = styled("div", {
  display: "flex",
  gap: "2.1875rem"
});

export const MenuBtn = styled("div", {
  cursor: "pointer",
  textDecoration: "underline",
  fontSize: "1.125rem",
});

export const WeekDaysWrapper = styled("div", {
  display: "flex",
  flexDirection: "row",
  
  "@bp2": {
    paddingTop: "1.1875rem",
  },
  "@bp1": {
    paddingTop: "1rem",
  },
  justifyContent: "space-between",
});

export const WeekDayCell = styled("div", {
  
 
  margin: 2,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: "Heebo-Bold",
  
  color: "#242323",
  width: "2.4375rem",
  "@bp2": {
     height: "1.875rem",
     fontSize: "1.25rem",
  },
  "@bp1": {
    height: "1.3125rem",
    fontSize: "0.875rem",
  },
});

export const CalendarMonthWrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  
  "@bp2": {
    paddingTop: "1.75rem",
    gap: "1.8rem",
  },
  "@bp1": {
    paddingTop: ".75rem",
    gap: "1.5rem",
  },
  
  justifyContent: "space-between",
});

export const CalendarContentWrapper = styled("div", {
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  flexDirection: "row",
});

export const CalendarButton = styled("div", {
  display: "flex",
  width: "100%",
  justifyContent: "flex-end",
  flexDirection: "row",
});

export const Underline = styled("div", {
  width: "100%",
  opacity:.17,
  borderTop:"1px solid #707070",
  // marginTop: "0.71875rem",
  // paddingBottom: "0.96875rem",
});

export const Button = styled("div", {
  display: "flex",
  fontSize: "1rem",
  lineHeight: "1.5rem",
  padding: "0.75rem 1.5rem",
  color: "white",
  backgroundColor: "#405F6A",
  borderRadius: "0.3125rem",
  cursor: "pointer",
});

export const CalendarDayCell = styled("div", {
  position: "relative",
  
  
  display: 'flex',
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "0.3125rem",
  cursor: "pointer",

  "@bp2": {
    height: "2.4375rem",
    width: "2.4375rem",
    fontSize: "1.375rem",
  },
  "@bp1": {
    height: "1.5rem",
    width: "2.4375rem",
    fontSize: "1.rem",
  },
  // margin: 2,

  variants: {
    variant: {
      default: {
        color: "#1B1B2F",
      },
      today: {
        
        color: "#1B1B2F",
      },
      selected: {
        color: "#1B1B2F",
        
      },
      nextMonth: {
        color: "#DAE1E7",
      },
    },
  },
});

export const Today = styled("div", {
  zIndex: '-1',
  
  position: "absolute",
  backgroundColor:"#EEE9E5",
  borderRadius: "0.3125rem",
  bottom: "0",
  left: "0",
  "@bp2": {
    bottom: "0",
  left: "0",
    height: "2.4375rem",
    width: "2.4375rem",
  },
  "@bp1": {
    bottom: "-0.2rem",
    left: ".2rem",
    height: "2rem",
    width: "2rem",
  },
});

export const Selected = styled("div", {
  position: "absolute",
  borderRadius: "0.1875rem",
  border: "0.1875rem solid #405F6A ",
  "@bp2": {
    bottom: "-0.625rem",
    left: "-0.625rem",
    width: "3.75rem",
    height: "3.3125rem",
  },
  "@bp1": {
    bottom: "-0.625rem",
    left: "-0.3rem",
    width: "2.6rem",
    height: "2.43rem",
  },
  
});


export const Cell1 = styled("div", {
  width: "100%",
  position: "absolute",
  borderTop: "0.25rem solid #E7B1AD ",
  
  left: "0",

  "@bp2": {
    bottom: "0",
  },
  "@bp1": {
    bottom: "-0.2rem",
  },
  variants: {
    variant: {
      default: {
        width: "1rem",
      },
      left: {
        width: "1rem",
      },
      right: {
        width: "1rem",
      },
      all: {
        width: "2rem",
      },
    },
  },
});
export const Cell2 = styled("div", {
  width: "100%",
  position: "absolute",
  borderTop: "0.25rem solid #ADE3E7 ",
  
  left: "0",
  "@bp2": {
    bottom: "-0.38rem",
  },
  "@bp1": {
    bottom: "-0.5rem",
  },
  variants: {
    variant: {
      default: {
        width: "1rem",
      },
      left: {
        width: "1rem",
      },
      right: {
        width: "1rem",
      },
      all: {
        width: "2rem",
      },
    },
  },
});
export const Cell3 = styled("div", {
  width: "100%",
  position: "absolute",
  borderTop: "0.25rem solid #DAADE7 ",
  
  "@bp2": {
    bottom: "0.4rem",
  },
  "@bp1": {
    bottom: "0.1rem",
  },
  variants: {
    variant: {
      default: {
        width: "1rem",
      },
      left: {
        width: "1rem",
      },
      right: {
        width: "1rem",
      },
      all: {
        width: "3rem",
      },
    },
  },
});


//for mobile

