import React, {useEffect, useState} from "react";
import styles from "./changeSupplier.module.scss";
import arrowTop from "./img/arrowTop.png";
// import { useNavigate } from "react-router";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import classNames from "classnames";
import {useForm} from "react-hook-form";
// import axios from "../../../../api/axios";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import {useDispatch} from "react-redux";
import {setErr} from "../../../store/forAll/errSlice";
//   getContracts,
//   getEvents,
//   getUserData,
//   removeEvent,
//   removeUser,
//   saveUser,
// } from "../../../store/personalUser/changeUserSlice";
import useAuth from "../../../hooks/useAuth";
import axios from "../../../../api/axios";

import {
  getSupplierData,
  saveSupplier,
} from "../../../store/personalSupplier/changeSupplierSlice";
import PopUp from "../../../components/defaultPopUp/PopUp";

const ChangeSupplier = () => {
  const {auth, setAuth, logOut} = useAuth();
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  // const navigate = useNavigate();
  let [isShowPass, setIsShowPass] = useState(true);

  const clearPasswords = () => {
    resetField("password", "");
    resetField("oldPassword", "");
    resetField("passwordConfirm", "");
  };

  const schema = yup.object().shape({
    name: yup
      .string()
      .min(2, "לפחות 2 אותיות") // min char 2
      .required("הכנס שם משתמש"), //name required
    phoneNumber: yup
      .string()
      .matches(/^(?:\+[\d\s-]+|[\d\s-]+)$/, "רק ספרות") // ONLY_NUMBERS
      .min(9, "לפחות 9 ספרות") //
      //.max(10, "מקסימום 10 ספרות") // MIN_CHAR_10
      .required("שדה חובה") //  FIELD_REQUIRED
      .test(
        "leading-zero",
        "מספר טלפון צריך להתחיל מ 0 או 972+", // LEDAING_ZERO
        (value, context) => {
          return context.originalValue && (context.originalValue.startsWith(0) || context.originalValue.startsWith('+'));
        }
      ),
    // 'You must enter a email'
    email: yup
      .string()
      .email("רשום מייל תקין") // 'Enter a valid email'
      .required("שדה חובה"),
    // 'Password is too short - should be 6 chars minimum.'
    oldPassword: yup
      .string()

      // .required("נא לרשום סיסמא") // 'Please enter your password.'
      .min(6, "הסיסמא קצרה מדי, לפחות 6 תוים."),
    password: yup
      .string()
      // .required("נא לרשום סיסמא") // 'Please enter your password.'
      .min(6, "הסיסמא קצרה מדי, לפחות 6 תוים."),
    passwordConfirm: yup
      .string()
      /* .required("שדה חובה") */
      .oneOf([yup.ref("password")], "הסיסמאות אינן תואמות"),
  });

  const defaultValues = {
    name: "",
    phoneNumber: "",
    email: "",
    oldPassword: "",
    password: "",
    passwordConfirm: "",
    rememberMe: false,
  };

  const {
    register,
    // reset,
    // handleSubmit,
    formState: {errors},
    formState,
    getValues,
    setValue,
    resetField,
  } = useForm({
    mode: "all",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const saveUser1 = async (data1) => {
    let cleaned = (getValues().phoneNumber).replace(/[\s-]/g, '');
    if(cleaned.startsWith('+972')){
      cleaned = cleaned.replace(/^(\+972)/, '0')
    }
    if(cleaned.startsWith('+') && !cleaned.startsWith('+972')){
      dispatch(
        setErr({
          message: "מספר טלפון צריך להתחיל מ 0 או 972+",
          type: 1,
        })
      );
      return false;
    }
    if (cleaned.length >= 9 && cleaned.length <= 10) {
    } else {
      dispatch(
        setErr({
          message: "מקסימום 10 ספרות",
          type: 1,
        })
      );
      return false;
    }
    let isChangedPassword = false;
    let newData = {...data1};
    newData.phoneNumber = cleaned; 
    setValue("name", getValues().name, {shouldValidate: true});
    setValue("phoneNumber", cleaned, {shouldValidate: true});

    setValue("email", getValues().email, {shouldValidate: true});
    if (
      getValues("password") ||
      getValues("oldPassword") ||
      getValues("passwordConfirm")
    ) {
      isChangedPassword = true;
      setValue("password", getValues().password, {shouldValidate: true});
      setValue("oldPassword", getValues().oldPassword, {
        shouldValidate: true,
      });
      setValue("passwordConfirm", getValues().passwordConfirm, {
        shouldValidate: true,
      });
    }

    // console.log(data1)

    if (Object.keys(formState.errors).length) return;
   // console.log(data1);

    dispatch(saveSupplier({axiosPrivate, data1: newData})).then((res) => {
      if (res?.error) {
        dispatch(
          setErr({
            message: res?.payload?.response?.data?.message,
            code: res?.payload?.response?.data?.code,
          })
        );
      } else {
        if(isChangedPassword) setIsOpenPass(true)
        dispatch(
          setErr({
            message: "השינויים נשמרו",
            type: 2,
          })
        );
        clearPasswords();
        //update info
        let newAuth = {...auth};
        newAuth.user = JSON.stringify(res?.payload);
        setAuth(newAuth);
      }
    });
  };

  const deleteUser = () => {
    logOut();
    // dispatch(removeSupplier({ axiosPrivate })).then((res) => {
    //   if (res?.error) {
    //     dispatch(
    //       setErr({
    //         message: res?.payload?.response?.data?.message,
    //         code: res?.payload?.response?.data?.code,
    //       })
    //     );
    //   } else {
    //     logOut();
    //     //update info
    //   }
    // });
  };

  useEffect(() => {
    dispatch(getSupplierData({axiosPrivate})).then((res) => {
      if (res?.error) {
        dispatch(
          setErr({
            message: res?.payload?.response?.data?.message,
            code: res?.payload?.response?.data?.code,
          })
        );
      } else {
        const {email, name, phoneNumber} = res?.payload;
        setValue("email", email);
        setValue("name", name);
        setValue("phoneNumber", phoneNumber);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let email = auth?.user?.length ? JSON.parse(auth?.user).email : "";
 // console.log(email);
  const sendEmail = async (data1) => {
    try {
      await axios.get(
        `${process.env.REACT_APP_API_PATH}auth/reset/password?email=${email}&userType=SUPPLIER`
      );
      dispatch(
        setErr({
          message: "סיסמה חדשה נשלחה למייל",
          type: 2,
        })
      );
    } catch (error) {
      if (error) {
        dispatch(
          setErr({
            message: error?.response?.data?.message,
            code: error?.response?.data?.code,
          })
        );
      }
    }
  };

  const [isOpenPass, setIsOpenPass] = useState(false);

  return (
    <>
      <PopUp
        isOpen={isOpenPass}
        setIsOpen={setIsOpenPass}
        message={"הסיסמא שונתה בהצלחה"}
      />
      <div className={styles.mainContainer}>
        <div className={styles.formWrapper}>
          <div className={styles.title}>{"עדכון פרופיל"}</div>
          <div className={styles.underline}></div>
          <div className={styles.titleMobile}>{"פרטים אישיים"}</div>
          <form noValidate className={styles.form}>
            <div className={styles.formNamePhone}>
              <div className={styles.inputContainer}>
                <input
                  type="text"
                  placeholder="שם מלא"
                  {...register("name")}
                  className={classNames({
                    [styles.invalidField]: errors.name,
                  })}
                />
                {errors.name && (
                  <p className={styles.errorMessage}>{errors.name.message}</p>
                )}
              </div>
              <div className={styles.inputContainer}>
                <input
                  type="text"
                  placeholder="מספר טלפון"
                  {...register("phoneNumber")}
                  className={classNames({
                    [styles.invalidField]: errors.phoneNumber,
                  }, styles.phoneInput)}
                />
                {errors.phoneNumber && (
                  <p className={styles.errorMessage}>
                    {errors.phoneNumber.message}
                  </p>
                )}
              </div>
            </div>

            <div
              style={{marginBottom: "15px", width: "100%"}}
              className={styles.inputContainer}
            >
              <input
                type="email"
                placeholder="כתובת מייל"
                {...register("email")}
                className={classNames({
                  [styles.invalidField]: errors.email,
                })}
              />
              {errors.email && (
                <p className={styles.errorMessage}>{errors.email.message}</p>
              )}
            </div>

            <div className={styles.forgot}>
              <span
                onClick={() => {
                  sendEmail();
                }}
              >
                {"שכחתי סיסמא"}
              </span>
            </div>
            <span
              className={styles.showPasswords}
              onClick={() => {
                clearPasswords();
                setIsShowPass(!isShowPass);
              }}
            >
              <span>{"שינוי סיסמא"}</span>
              <img
                style={{transform: isShowPass ? "rotate(180deg)" : ""}}
                alt="arrow"
                src={arrowTop}
              />
            </span>

            {isShowPass && (
              <div className={styles.passwords}>
                <div className={styles.oldPassInput}>
                  <div className={styles.inputContainer}>
                    <input
                      type="password"
                      placeholder="סיסמא נוכחית"
                      autoComplete="new-password"
                      {...register("oldPassword")}
                      className={classNames({
                        [styles.invalidField]: errors.oldPassword,
                      })}
                    />
                    {errors.oldPassword && (
                      <p className={styles.errorMessage}>
                        {errors.oldPassword.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className={styles.confirmationFields}>
                  <div className={classNames(styles.inputContainer)}>
                    <input
                      type="password"
                      placeholder="סיסמא חדשה"
                      autoComplete="new-password"
                      {...register("password", {
                        deps: ["passwordConfirm"],
                      })}
                      className={classNames({
                        [styles.invalidField]: errors.password,
                      })}
                    />
                    {errors.password && (
                      <p className={styles.errorMessage}>
                        {errors.password.message}
                      </p>
                    )}
                  </div>
                  <div className={classNames(styles.inputContainer)}>
                    <input
                      type="password"
                      placeholder="אישור סיסמא חדשה"
                      autoComplete="new-password"
                      {...register("passwordConfirm", {
                        deps: ["password"],
                      })}
                      className={classNames({
                        [styles.invalidField]: errors.passwordConfirm,
                      })}
                    />
                    {errors.passwordConfirm && (
                      <p className={styles.errorMessage}>
                        {errors.passwordConfirm.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className={styles.buttons}>
              <div
                className={styles.save}
                onClick={() => saveUser1(getValues())}
              >
                {"שמירת שינויים"}
              </div>
              <div className={styles.remove} onClick={() => deleteUser()}>
                {"אני רוצה להתנתק"}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ChangeSupplier;
