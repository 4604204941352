import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";

const errSlice = createSlice({
  name: "err",
  initialState: {
      isShow: false,
      message: '',
      type: 1,
      time: 2000,
      code: 0,
  },
  reducers: {
    setErr(state, action) {
      const {isShow, message, type, code, time} = action.payload;
      state.isShow = isShow || true;
      state.message = message;
      state.type = type || 1;
      state.time = time;
      state.code = code;
    },
    clearErr(state, action) {
      state.isShow = false;
      state.message = "";
      state.type = 1;
      state.time = 2000;
      state.code = 0;
    },
    
  },
})

export const { setErr, clearErr } = errSlice.actions;

export default errSlice.reducer;
