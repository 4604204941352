import styles from './SupplierImageWithLink.module.css'

function SupplierImageWithLink(props) {

  const {link, defaultImageUrl} = props;
  
  function goToComments() {
    link();
  }

  return(<>
    <div className={styles.imageContainer}>
      <div>
        <div>
         {defaultImageUrl !== null && <img src={defaultImageUrl} className={styles.defaultImg} alt=""/> }
         {defaultImageUrl === null && <img style={{objectFit: 'none'}} src='/static/media/logo.8915ebd25d310c92475d.png' className={styles.defaultImg} alt=""/> }
        </div>
      </div>
    </div>
    <div style={{cursor:'pointer'}} className={styles.linkForSuppliers} onClick={() => goToComments()}>
      קראו חוות דעת
    </div>
  </>)
}

export default SupplierImageWithLink