import styles from "./PersonalInformation.module.scss";
import classNames from "classnames";
import { Controller, useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { setIsEmailAccept, setIsRulsAccept } from "../../../store/supplierRegistrationSlice";


function PersonalInformation() {
  // const dispatch = useDispatch();
  // const {isEmailAccept, isRulsAccept} = useSelector(state => state.supplierRegistration);
  const methods = useFormContext({ });
  const { control, register, getValues, setValue, formState, watch, getFieldState, trigger } = methods;
  const { errors } = formState;
  const password = watch("password");
  let isPasswordFieldTouched = getFieldState("passwordConfirm").isTouched
  
  let [rulesKey, setRulesKey] = useState("12");
  let [emailKey, setEmailKey] = useState("13");

  function removeSpaces(str){
    // console.log(str)
    if(!str && str!=="")return
    let newStr = str.replace(/\s+/g, ' ').trim()
    return newStr
  }

  // function numbersOnly(event)  { 
  //   if(!event.target.value.match(/^\d+$/) && event.target.value !== ''){
  //     return false;
  //   }else{
  //     return true;
  //   }
  // }

  const openTerms = async() => {   
    let url = process.env.REACT_APP_PATH;
    window.open(url + 'terms/1','_blank')
  }

  useEffect(() => {
    if(isPasswordFieldTouched) {
      trigger("passwordConfirm");
    }
  }, [isPasswordFieldTouched, password, trigger]);
  return (
    <div className={styles.mainContainer}>
      <form noValidate className={styles.form}>
        <div className={styles.dataInputs}>
          <span className={styles.inputContainer}>

          {/* <Controller
            name="contractRequired"
            control={control}
            render={({ field: { onChange, value } }) => (
              <div className='flex items-center'>
                  <Typography className="text-16 sm:text-20 truncate ">
                    {t("CONTRACT_REQUIRED")}
                  </Typography>
                <Checkbox
                  // defaultChecked={true}
                  id="contractRequired"
                  style={{ marginRight: 8 }} 
                  checked={(value!==true && value!==false) ? true : value} 
                  onChange={(event, newValue) => {
                    onChange(newValue);
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </div>
              
            )}
          /> */}
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, value } }) => (
              <input
                error={!!errors.name}
                placeholder="שם מלא"
                type="text"
                className={classNames({
                  [styles.invalidField]: errors.name,
                })}
                value={value? value : ''}
                onBlur={(event, newValue) => {
                  onChange(removeSpaces(event.target.value,newValue));
                }}
                onChange={(event, newValue) => {
                  onChange(event.target.value);
                }}
                id="name"
              />
              
            )}
            
          />
          { errors.name && 
              <p className={styles.errorMessage}>
                {errors.name.message}
              </p>
            }
          </span>



          <div className={styles.inputContainer}>

          <Controller
            name="phoneNumber"
            control={control}
            render={({ field: { onChange, value } }) => (
              <input
                // {...field}
                placeholder="מספר טלפון"
                error={!!errors.phoneNumber}
                className={classNames({
                  [styles.invalidField]: errors.phoneNumber,
                }, styles.phoneInput)}
                type='text'
                value={value? value : ''}
                  onBlur={(event, newValue) => {
                    onChange(removeSpaces(event.target.value,newValue));
                  }}
                  onChange={(event, newValue) => 
                    // numbersOnly(event) ? onChange(event.target.value) : ''
                    onChange(event.target.value)
                  }
                id="phoneNumber"
              />
            )}
          />
          { errors.phoneNumber && 
              <p className={styles.errorMessage}>
                {errors.phoneNumber.message}
              </p>
            }
            
          </div>

          <div className={styles.inputContainer}>

            <Controller
              name="email"
              control={control}
              render={({ field: { onChange, value } }) => (
                <input
                  error={!!errors.email}
                  className={classNames({
                    [styles.invalidField]: errors.email,
                  })}
                  placeholder="כתובת מייל"
                  value={value? value : ''}
                    onBlur={(event, newValue) => {
                      onChange(removeSpaces(event.target.value,newValue));
                    }}
                    onChange={(event, newValue) => {
                      onChange(event.target.value);
                    }}
                  id="email"
                  
                />
              )}
            />
            { errors.email && 
              <p className={styles.errorMessage}>
                {errors.email.message}
              </p>
            }


    
          </div>
        </div>
        <p className={styles.passwordChoosingNote}>בחירת סיסמא</p> {/* PasswordChoosing */}
        <div className={classNames(styles.dataInputs, styles.passwordContainer)}>
          <div className={styles.inputContainer}>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <input
                type='password'
                {...field}
                className={classNames({
                  [styles.invalidField]: errors.password,
                })}
                error={!!errors.password}
                required
                placeholder="הזן סיסמא"
                autoComplete='new-password'
                id="password"
                
              />
            )}
          />
          { errors.password && 
              <p className={styles.errorMessage}>
                {errors.password.message}
              </p>
            }

            
          </div>

          <div className={styles.inputContainer}>
          <Controller
            name="passwordConfirm"
            control={control}
            render={({ field }) => (
              <input
                type='password'
                {...field}
                className={classNames({
                  [styles.invalidField]: errors.passwordConfirm,
                })}
                error={!!errors.passwordConfirm}
                required
                placeholder="אישור סיסמא"
                autoComplete='new-password'
                id="passwordConfirm"
                
              />
            )}
          />
          { errors.passwordConfirm && 
              <p className={styles.errorMessage}>
                {errors.passwordConfirm.message}
              </p>
            }

          </div>
        </div>
        <div key={rulesKey} className={styles.radio}  style={{display: 'flex', gap:'10px'}}>
          <input
            value={true}
            {...register('isRulsAccept')}
            onClick={() => {
              if(getValues('isRulsAccept') === "true"){
                setValue("isRulsAccept", null);
              }
              
              setRulesKey(Math.random())
            }}
            id="isRulsAccept" type="radio" name="isRulsAccept"  />
            <label htmlFor="isRulsAccept" className={styles.checkboxLabel}>קראתי ואני מאשר את </label>{" "}
            {/* AgreeWithRules */}
              <div className={styles.checkboxDiv} onClick={()=>{openTerms()}} style={{textDecoration: 'underline', color: '#3E3D3D', cursor: 'pointer'}}>תקנון האתר</div>
          {/* AgreeWithRules */}
          { errors.isRulsAccept && 
              <p className={styles.errorMessage}>
                שדה חובה
              </p>
            }
        </div>
        <div key={emailKey} className={styles.radio}>
          <input 
            value={true}
            {...register('isEmailAccept')}
            onClick={() => {
              if(getValues('isEmailAccept') === "true"){
                setValue("isEmailAccept", null);
              }
              setEmailKey(Math.random())
            }}
            id="isEmailAccept" type="radio" name="isEmailAccept"  />
          <label htmlFor="isEmailAccept">
            אני מאשר קבלת דיוור לכתובת המייל שהזנתי
          </label>
          {/* ConfirmGetMailsToEmail */}
          { errors.isEmailAccept && 
              <p className={styles.errorMessage}>
                שדה חובה
              </p>
            }
        </div>
      </form>
    </div>
      
  );
}

export default PersonalInformation;
