import {createContext, useState} from "react";
import {EXPIRE_ANONIM_DATE, TEMP_USER_KEY} from "../consts/local_keys";
import axios from "../../api/axios";
// import { useNavigate } from "react-router";
import he from "dayjs/locale/he"; // load on demand
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

const AuthContext = createContext({});
//setAuth({ user, roles, accessToken, refreshToken });

export const AuthProvider = ({children}) => {
  dayjs.locale(he);
  dayjs.extend(isSameOrBefore);
  // const navigate = useNavigate()
  const authCheck = localStorage.getItem("jwt_access_token") && localStorage.getItem("jwt_refresh_token") &&
    localStorage.getItem("roles")  && localStorage.getItem("user")
     && (localStorage.getItem("jwt_access_token") !== 'undefined') &&
    (localStorage.getItem("jwt_refresh_token") !== 'undefined') &&
    (localStorage.getItem("roles")  !== 'undefined') 
    && (localStorage.getItem("user")  !== 'undefined') ? {
      accessToken: localStorage.getItem("jwt_access_token"),
      refreshToken: localStorage.getItem("jwt_refresh_token"),
      roles: JSON.parse(localStorage.getItem("roles")),
      user: localStorage.getItem("user"),
    } :
    {};

  const [auth, setAuth1] = useState(authCheck);

  const setToken = () => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}main/anonymous/user/token`)
      .then((e) => {
        localStorage.setItem(TEMP_USER_KEY, e.data);
        localStorage.setItem(EXPIRE_ANONIM_DATE, dayjs().add(1, "day"));
      });
  };

  const logOut = () => {
    setAuth1({});
    localStorage.removeItem("jwt_access_token");
    localStorage.removeItem("jwt_refresh_token");
    localStorage.removeItem("roles");
    localStorage.removeItem("user");
    // navigate('/');
    let user_id = localStorage.getItem(TEMP_USER_KEY);
    let expire_date = localStorage.getItem(EXPIRE_ANONIM_DATE);
    if (!user_id || dayjs(expire_date).isSameOrBefore(dayjs(), "date")) {
      setToken();
    }
  };

  const setAuth = (e) => {
    //console.log(e)
    if (!e) {
      logOut();
    } else {
      setAuth1(e);
      localStorage.setItem("jwt_access_token", e.accessToken);
      localStorage.setItem("jwt_refresh_token", e.refreshToken);
      localStorage.setItem("roles", JSON.stringify(e.roles));
      localStorage.setItem("user", e.user);
    }
  };

  const setStartAuth = () => {
    if(localStorage.getItem("roles") === "undefined" ||
    localStorage.getItem("user") === "undefined"||
    localStorage.getItem("jwt_access_token") === "undefined"||
    localStorage.getItem("jwt_refresh_token") === "undefined"){
      return logOut();
    }

    let accessToken = localStorage.getItem("jwt_access_token");
    let refreshToken = localStorage.getItem("jwt_refresh_token");
    let roles = JSON.parse(localStorage.getItem("roles"));
    let user = localStorage.getItem("user");
    
    if (accessToken && refreshToken && roles && user) {
      setAuth1({accessToken, refreshToken, roles, user});
      // console.log(auth, {accessToken, refreshToken, roles, user})
    } else {
      logOut();
    }
  };

  return (
    <AuthContext.Provider value={{auth, setAuth, logOut, setStartAuth}}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
