import { Typography, useMediaQuery } from '@mui/material';
import styles from '../styleSuppliers.module.scss';
import CategoryFilters from './categoryFilters/CategoryFilters';
import Cards from './cards/Cards';
import { useFormContext } from 'react-hook-form';
import Filters from './filters/Filters';
import ActionButtons from './ActionButtons';
import { useState } from 'react';
import MobileFilters from './filters/MobileFilters';
import MobileCategoryFilters from './categoryFilters/MobileCategoryFilters';
import { useSelector } from 'react-redux';
import PopUp from '../../../components/defaultPopUp/PopUp';
const ContentSuppliers = () => {
  const { watch } = useFormContext();
  let { selectedEventTypeId } = useSelector(
    (state) => state.suppliers
  );
  const isMobile = useMediaQuery('(max-width:720px)');
  const currentCategory = watch('category');
  const [openMobileCategory, setOpenMobileCategory] = useState(false);
  const [showNotSelectedEventError, setShowNotSelectedEventError] = useState(false);
  const [openMobileFilters, setOpenMobileFilters] = useState(false);

  return (
    <div id="content_suppliers" className={styles.content_container}>
      <PopUp
        message={"קודם יש לבחור את סוג הארוע"}
        setIsOpen={setShowNotSelectedEventError}
        isOpen={showNotSelectedEventError && !selectedEventTypeId}
      />
      <ActionButtons
        setOpenMobileCategory={setOpenMobileCategory}
        setOpenMobileFilters={setOpenMobileFilters}
        openError={() => setShowNotSelectedEventError(true)}
      />
      {!isMobile ? (
        <Filters
          openError={() => setShowNotSelectedEventError(true)} />
      ) : (
        <MobileFilters
          openError={() => setShowNotSelectedEventError(true)}
          open={openMobileFilters}
          onClose={() => {
            setOpenMobileFilters(false);
          }}
        />
      )}
      <Typography className={styles.content_title}>
        {currentCategory ? currentCategory.name : 'כל הספקים שלנו'}
      </Typography>
      {currentCategory?.id !== 1 && selectedEventTypeId &&
        (!isMobile ? (
          <CategoryFilters />
        ) : (
          <MobileCategoryFilters
            open={openMobileCategory}
            onClose={() => {
              setOpenMobileCategory(false);
            }}
          />
        ))}
      <Cards />
    </div>
  );
};
export default ContentSuppliers;
