import {useDispatch} from "react-redux";
import useAuth from "./useAuth";
import {useNavigate} from "react-router-dom";
import axios from "../../api/axios";
import {setCurrentEventChecklist} from "../store/checkListSlice";
import {setCurrentEventForChecklist} from "../store/mainSlice";
import {setErr} from "../store/forAll/errSlice";
import {TEMP_USER_KEY} from "../consts/local_keys";
import {useMemo} from "react";

export async function useAutoLogin({access, refresh, type}) {
  const {setAuth} = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //types : user, supplier

  useMemo(async () => {
    if (!(access && refresh && type)) return;
    let data1 = {
      accessToken: access,
      refreshToken: refresh,
    };
    try {
      const response = await axios.post(
        type === "user"? `${process.env.REACT_APP_API_PATH}user/login/info` : `${process.env.REACT_APP_API_PATH}supplier/login/info`,
        data1, {headers: {
          'Authorization': `Bearer ${access}` 
        }}
      );
      const data = await response?.data;
      if(!data?.jwtAuthenticationResponseDto)return
      const accessToken = data?.jwtAuthenticationResponseDto.accessToken;
      const refreshToken = data?.jwtAuthenticationResponseDto.refreshToken;
      const roles = [
        data?.userDto?.userType || data?.supplierDto?.supplierType,
      ];
      const user = JSON.stringify(data?.userDto || data?.supplierDto);
      dispatch(
        setCurrentEventChecklist({
          currentEvent: {checkListId: 0, eventTypeName: ""},
        })
      );
      dispatch(
        setCurrentEventForChecklist({
          currentEventForChecklist: {id: 0, name: ""},
        })
      );
      if (accessToken && refreshToken && roles && user) {
        setAuth({accessToken, refreshToken, user, roles});
      }
      localStorage.removeItem(TEMP_USER_KEY);
      navigate("/");
      return data === undefined ? null : data;
    } catch (error) {
      if (error) {
        
        dispatch(
          setErr({
            message: error?.response?.data?.message,
            code: error?.response?.data?.code,
          })
        );
      }
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [access, refresh, type]);
}
