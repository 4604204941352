import { createSlice } from "@reduxjs/toolkit"
//the slice do not used right now

const authSlice = createSlice({
    name: 'auth',
    initialState: { user: null, accessToken: null, refreshToken : null, roles: [] },
    reducers: {
        setCredentials: (state, action) => {
            const { user, accessToken, refreshToken, roles } = action.payload
            state.user = user
            state.accessToken = accessToken
            state.refreshToken = refreshToken
            state.roles = roles
        },
        logOut: (state, action) => {
            state.user = null
            state.accessToken = null
            state.refreshToken = null
            state.roles = []
        }
    },
})

export const { setCredentials, logOut } = authSlice.actions

export default authSlice.reducer

export const selectCurrentUser = (state) => state.auth.user
export const selectCurrentAccessToken = (state) => state.auth.accessToken
export const selectCurrentrefreshToken = (state) => state.auth.refreshToken
export const selectCurrentRoles = (state) => state.auth.roles