import React, { useEffect } from 'react';
import BasicInformationForm from './basicInformationForm/BasicInformationForm';
import { Box } from '@mui/material';
import Advantages from './advantages/Advantages';
import CallToAction from './CallToAction';
import InfoSuppliersWithFeedback from './infoSupplWithFeed/InfoSuppliersWithFeedback';
import Footer from '../../components/footer/Footer';
import MetaHome from '../../components/metaComponents/metaHome';
import { useAutoLogin } from '../../hooks/useAutoLogin';
import { useParams } from 'react-router-dom';
import WhatsApp from '../../components/whatsApp/whatsApp';

const Home = () => {
  let { ac, dc, type } = useParams();
  useAutoLogin({ access: ac, refresh: dc, type: type });
  return (
    <Box sx={{ padding: { xs: '0px 0px 100px 0px ', sm: '0px' }, overflow: 'hidden' }}>
      {/* <WhatsApp/> */}
      <MetaHome />
      <BasicInformationForm />
      <Advantages />
      <CallToAction />
      <InfoSuppliersWithFeedback />
      <Footer />
    </Box>
  );
};

export default Home;
