import React from 'react';
import { Box, Typography } from '@mui/material';
import styles from './advantages.module.scss';
const Advantage = ({ advantage }) => {
  return (
    <Box className={styles.one_advantage}>
      <Box className={styles.img_container}>
        <img loading="lazy" src={advantage.image} alt="advantage-img"></img>
      </Box>
      <Typography className={styles.title}>{advantage.title}</Typography>
      <Box
        component={'div'}
        className={styles.description}
        dangerouslySetInnerHTML={{
          __html: advantage.description,
        }}
      ></Box>
    </Box>
  );
};

export default Advantage;
