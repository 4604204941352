import { useDispatch, useSelector } from 'react-redux';
import { useAnalyticsEvents } from '../../../../../../hooks/useAnalyticsEvents';
import { EVENT_TYPE_NAME_ENUM, EXISTS_PLACE_ENUM, STEPS_ENUM } from '../stepForm.config';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';
import { getFormattedPhone } from '../../../../../../helperFunctions/phone.help';
import {
  confirmFormWithCode,
  resetCountCodeResend,
  selectAuthPhoneNumber,
  selectIsCodeSent,
  sendCode,
  setAuthPhoneNumber,
  setIsCodeSent,
} from '../../../../../../store/authCodeSlice';
import { checklistCreate, setCurrentEventChecklist } from '../../../../../../store/checkListSlice';
import { useNavigate } from 'react-router-dom';
import {
  setCodeModalIsOpen,
  setCurrentEvent,
  setCurrentEventForChecklist,
  setCurrentStep,
  setIsNeedPlace,
} from '../../../../../../store/mainSlice';
import {
  setAmountOfGuestsFrom,
  setDateFrom,
  setDateTo,
  setSelectedEventTypeId,
} from '../../../../../../store/suppliersSlice';
import dayjs from 'dayjs';
import { TEMP_USER_KEY } from '../../../../../../consts/local_keys';
import useAuth from '../../../../../../hooks/useAuth';
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';

export const useSubmigLogic = ({ currentRole }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setAuth } = useAuth();
  const { VenueForm } = useAnalyticsEvents();
  const { allEvents } = useSelector((state) => state.main);
  const { auth } = useAuth();
  const form = useFormContext();
  const [isLoading, setIsLoading] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const isCodeSent = useSelector(selectIsCodeSent);
  const watchPhone = form.watch('phoneNumber');
  const statePhone = useSelector(selectAuthPhoneNumber);

  const redirectToSuppliers = () => {
    navigate(`/suppliers`);
  };

  const redirectToCheckList = () => {
    navigate('/personal-guest/checklist');
  };

  const postGtmVenue = (data, isNeedPlace) => {
    const objGtm = {
      phone: !currentRole
        ? getFormattedPhone(data.phoneNumber)
        : auth && auth?.user
        ? JSON.parse(auth.user)?.phoneNumber
        : '',
      hasVenue: isNeedPlace ? 'no' : 'yes',
      eventType: allEvents.find((oneItem) => oneItem.id === data.celebrating)?.name || '',
      venuePlace: !isNeedPlace ? data.whereEvent?.label || '' : '',
      guestsNum: !isNeedPlace ? +data.countGuests || '' : '',
      date: !isNeedPlace ? getFormattedDate(data.date) || '' : '',
    };

    VenueForm(objGtm);
  };
  const getFormattedDate = (spesificDatePickerDate) => {
    return spesificDatePickerDate
      ? new Date(spesificDatePickerDate).toISOString()
      : new Date().toISOString();
  };

  const handleSubmit = form.handleSubmit(async (data) => {
    const isNeedPlace = EXISTS_PLACE_ENUM.NO === data.existsPlace;
    const currentVenue = !isNeedPlace
      ? typeof data.whereEvent == 'object'
        ? { venueId: data.whereEvent.id, venueName: data.whereEvent.label }
        : { venueName: data.whereEvent }
      : {};
    const currentCountGuest = !isNeedPlace ? { amountOfGuest: +data.countGuests } : {};

    //analytics
    postGtmVenue(data, isNeedPlace);

    const eventType = allEvents.find((oneItem) => oneItem.id === data.celebrating);
    const currentDate = !isNeedPlace
      ? { eventDate: getFormattedDate(data.date) }
      : {
          dateFrom: getFormattedDate(data.dateRange.start),
          dateTo: getFormattedDate(data.dateRange.end),
        };
    const mainData = {
      eventForm: EVENT_TYPE_NAME_ENUM[data.typeEvent],
      eventTypeId: data.celebrating,
      venue: !isNeedPlace,
      ...currentDate,
      ...currentVenue,
      ...currentCountGuest,
    };
    const mainPayload = !currentRole
      ? {
          createDto: mainData,
          phoneNumber: getFormattedPhone(data.phoneNumber),
          authCode: data.authCode,
        }
      : { axiosPrivate, ...mainData };
    const currentThunk = !currentRole ? confirmFormWithCode : checklistCreate;
    const date = dayjs(new Date(data.date)).format('YYYY-MM-DD');
    const dateFromDateRange = data.dateRange?.start
      ? dayjs(new Date(data.dateRange.start)).format('YYYY-MM-DD')
      : null;
    const dateToDateRange = data.dateRange?.end
      ? dayjs(new Date(data.dateRange.end)).format('YYYY-MM-DD')
      : null;
    dispatch(currentThunk(mainPayload)).then(({ payload }) => {
      if (payload.data) {
        dispatch(setIsNeedPlace(isNeedPlace));
        dispatch(setSelectedEventTypeId(data.celebrating));
        if (!!eventType) {
          dispatch(setCurrentEvent({ currentEvent: eventType }));
        }
        if (!isNeedPlace) {
          dispatch(setDateFrom(date));
          dispatch(setAmountOfGuestsFrom(+data.countGuests));
        }
        if (isNeedPlace) {
          dispatch(setDateFrom(dateFromDateRange));
          dispatch(setDateTo(dateToDateRange));
        }
        if (!currentRole) {
          dispatch(setCurrentEventChecklist({ currentEvent: payload.data.checkList }));
          dispatch(setCodeModalIsOpen(false));
          dispatch(setIsCodeSent(false));
          dispatch(setAuthPhoneNumber(''));
          dispatch(resetCountCodeResend())
          const accessToken = payload.data?.jwtAuthenticationResponseDto.accessToken;
          const refreshToken = payload.data?.jwtAuthenticationResponseDto.refreshToken;
          const roles = [payload.data?.userDto.userType];
          const user = JSON.stringify(payload.data?.userDto);

          if (accessToken && refreshToken && roles && user) {
            setAuth({ accessToken, refreshToken, user, roles });
          }
          localStorage.removeItem(TEMP_USER_KEY);
        } else {
          dispatch(setCurrentEventChecklist({ currentEvent: payload.data }));
        }
        dispatch(setCurrentStep(STEPS_ENUM.TYPE_EVENT));
        isNeedPlace ? redirectToSuppliers() : redirectToCheckList();
        setIsLoading(false);
        form.reset();
      }
    });
  });

  const factoryHandleSubmit = (e) => {
    const currentPhone = `+${getFormattedPhone(watchPhone)}`;
    if (isCodeSent && statePhone == currentPhone) {
      e.preventDefault();
      dispatch(setCodeModalIsOpen(true));
      return;
    }
    if (!currentRole) {
      e.preventDefault();
      e.stopPropagation();
      form.handleSubmit(async (data) => {
        setIsLoading(true);
        dispatch(sendCode(getFormattedPhone(data.phoneNumber)))
          .then((data) => {
            if (!!data?.error) return;
            dispatch(setCodeModalIsOpen(true));
          })
          .finally(() => setIsLoading(false));
      })();
    } else {
      setIsLoading(true);
      handleSubmit();
    }
  };

  return { handleSubmit, factoryHandleSubmit, isLoading };
};
