import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import './App.css';
import AppLayout from './AppLayout';
import Supplier from './pages/supplier/Supplier';
import Home from './pages/home/Home';
import NotFound from './pages/notFound/NotFound';
import UserLogin from './pages/userLogin/UserLogin';
import UserRegistration from './pages/userRegistration/UserRegistration';
import SupplierLogin from './pages/supplierLogin/SupplierLogin';
import axios from 'axios';
import Suppliers from './pages/suppliers/Suppliers';
import Blog from './pages/blog/Blog';
import Details from './pages/blog/details/Details';
import SupplierRegistrationDesktop from './pages/supplierRegistration/SupplierRegistrationDesktop';
import Contacts from './pages/contacts/Contacts';
import AboutUs from './pages/aboutUs/AboutUs';
import RequireAuth from './components/RequireAuth';
import { useEffect } from 'react';
import useAuth from './hooks/useAuth';
import HomeTab from './pages/personalAreaTab/PersonalAreaTab';
import PersonalSupplierArea from './pages/personalSupplierArea/PersonalSupplierArea';
import Chat from './pages/Chat/Chat';
import Chats from './pages/Chats/Chats';
import Documents from './pages/personalSupplierArea/documents/Documents';
import CheckList from './pages/CheckList/CheckList';
import ChangeUser from './pages/personalAreaTab/changeUser/ChangeUser';
import ChangeSupplier from './pages/personalSupplierArea/changeSupplier/ChangeSupplier';
import ChangeSupplierBusiness from './pages/personalSupplierArea/changeSupplierBusiness/ChangeSupplierBusiness';
import AddEvent from './pages/addEvent/addEvent';
import SupplierImages from './pages/personalSupplierArea/changeSupplierBusiness/change-images/SupplierImages';
import Favorites from './pages/Favorites/Favorites';
import Terms from './pages/terms/Terms';
import DocumentsUser from './pages/documents/Documents';
import SupplierCalendar from './pages/personalSupplierArea/calendar/CustomCalendar';
import MetaMain from './components/metaComponents/metaMain';
import { HelmetProvider } from 'react-helmet-async';
import { useCustomFavicon } from './components/metaComponents/useCustomFavicon';
import { useSelector, useDispatch } from 'react-redux';
import { setIsOpenMobleAuth, setIsOpenSupplierAuth, setIsOpenUserAuth, setIsOpenUserReg } from './store/mainSlice';
import OpenSignup from './components/openSignup/OpenSignup';
import ChangeLabelBusiness from './pages/personalSupplierArea/changeLabelBusiness/ChangeLabelBusiness';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme';
import LoginWithPhoneModal from './components/loginWithPhoneModal/LoginWithPhoneModal';
import FeaturesPopup from './components/FeaturesPopup/FeaturesPopup';
import WhatsApp from './components/whatsApp/whatsApp';
import WhatsAppPopup from './components/whatsAppPopup/whatsAppPopup';
const ROLES = {
  User: 'USER',
  Supplier: 'SUPPLIER',
  Label: 'LABEL',
  Admin: 'ADMIN',
};

axios.defaults.baseURL = process.env.REACT_APP_API_PATH;

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  (error) => {
    if (error.message && error.message.indexOf('code 404') !== -1) {
      // snackActions.error(error.message);
      // console.log(error.message);
      return Promise.reject(error);
    }
    if (error.response && (error.response.status === 500 || error.response.status === 400)) {
      // console.log("Internal error", error);
      if (error.response.data.code === 1410) {
        // useNavigate("/suppliers")
      }
      // snackActions.error(`Error occurred ${error.response?.data}`);
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use((req) => {
  const token = localStorage.getItem('jwt_access_token_event_do');
  req.headers.Authorization = token ? token : '';
  return req;
});

function App() {
  const { setStartAuth } = useAuth();
  const { i18n } = useTranslation();

  const { isOpenSupplierAuth, isOpenUserAuth, isOpenUserReg,isOpenMobileAuth } = useSelector((state) => state.main);
  const dispatch = useDispatch();
  document.body.dir = i18n.dir();

  useEffect(() => {
    sessionStorage.setItem('url', '');
    setStartAuth();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useCustomFavicon();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <HelmetProvider>
          <MetaMain />
          <Routes>
            <Route path="/" element={<AppLayout />}>
              <Route index element={<Home />} />
              {/* route for login from admin */}
              <Route path="login/:ac/:dc/:type" element={<Home />} />
              <Route path="suppliers" element={<Suppliers />} />
              <Route path="signup" element={<OpenSignup />} />
              <Route path="search" element={<Suppliers />} />
              <Route path="supplier-registration" element={<SupplierRegistrationDesktop />} />
              <Route path="supplier-registration/:id" element={<SupplierRegistrationDesktop />} />
              {/* we want to protect these routes */}
              <Route path="personal-guest" element={<HomeTab />}>
                <Route path="checklist" element={<CheckList />} />
                <Route path="add-event" element={<AddEvent />} />
                <Route
                  path="chat/:supplierId/:userId/:eventTypeId/:categoryId/:checkListId"
                  element={<Chat />}
                />
                <Route path="chats" element={<Chats />} />
                <Route path="change-user" element={<ChangeUser />} />
                <Route path="add-event" element={<AddEvent />} />
              </Route>
              <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
                <Route path="personal" element={<HomeTab />}>
                  {/* <Route path="chat/:supplierId" element={<HomeTab/>} /> */}
                  <Route
                    path="chat/:supplierId/:userId/:eventTypeId/:categoryId/:checkListId"
                    element={<Chat />}
                  />
                  <Route path="chats" element={<Chats />} />
                  <Route path="change-user" element={<ChangeUser />} />
                  <Route path="favorites" element={<Favorites />} />
                  <Route path="checklist" element={<CheckList />} />
                  <Route path="add-event" element={<AddEvent />} />
                  <Route path="documents" element={<DocumentsUser />} />
                </Route>
              </Route>
              <Route element={<RequireAuth allowedRoles={[ROLES.Supplier, ROLES.Label]} />}>
                <Route path="personal-supplier" element={<PersonalSupplierArea />}>
                  <Route path="change-supplier" element={<ChangeSupplier />} />
                  <Route path="change-supplier-business" element={<ChangeSupplierBusiness />} />
                  <Route path="change-label-business" element={<ChangeLabelBusiness />} />
                  <Route path="images" element={<SupplierImages />} />
                  <Route path="images/:supplierId" element={<SupplierImages />} />
                  <Route path="calendar" element={<SupplierCalendar />} />
                  <Route
                    path="chat/:supplierId/:userId/:eventTypeId/:categoryId/:checkListId"
                    element={<Chat />}
                  />
                  <Route path="chats" element={<Chats />} />
                  <Route path="documents" element={<Documents />} />
                </Route>
              </Route>
              <Route path="*" element={<NotFound />} />
            </Route>

            <Route path="supplier/:id" element={<Supplier />} />
            <Route path="supplier/:id/:currntEvent" element={<Supplier />} />
            {/* <Route path="supplier" element={<Supplier />} /> */}
            {/*<Route path="user-login" element={<UserLogin />} />*/}
            {/*<Route path="user-registration" element={<UserRegistration />} />*/}
            {/*<Route path="user-registration/:id" element={<UserRegistration />} />*/}
            {/*<Route path="supplier-login" element={<SupplierLogin />} />*/}

            <Route path="blog" element={<Blog />} />
            <Route path="blog/:id" element={<Details />} />
            <Route path="contacts" element={<Contacts />} />
            <Route path="about-us" element={<AboutUs />} />
            <Route path="about" element={<AboutUs />} />

            <Route path="terms/:id" element={<Terms />} />
          </Routes>
          <Dialog
            open={isOpenUserReg}
            onClose={() => dispatch(setIsOpenUserReg(false))}
            fullScreen={true}
            sx={{
              '& .MuiDialog-container': {
                '& .MuiPaper-root': {
                  background: 'transparent',
                },
              },
            }}
          >
            <DialogContent style={{ padding: '0' }}>
              <UserRegistration />
            </DialogContent>
          </Dialog>
          <Dialog
            open={isOpenUserAuth}
            onClose={() => dispatch(setIsOpenUserAuth(false))}
            fullScreen={true}
            sx={{
              '& .MuiDialog-container': {
                '& .MuiPaper-root': {
                  background: 'transparent',
                },
              },
            }}
          >
            <DialogContent style={{ padding: '0' }}>
              <UserLogin />
            </DialogContent>
          </Dialog>
          <Dialog
            open={isOpenSupplierAuth}
            onClose={() => dispatch(setIsOpenSupplierAuth(false))}
            fullScreen={true}
            sx={{
              '& .MuiDialog-container': {
                '& .MuiPaper-root': {
                  background: 'transparent',
                },
              },
            }}
          >
            <DialogContent style={{ padding: '0' }}>
              <SupplierLogin />
            </DialogContent>
          </Dialog>
          <LoginWithPhoneModal open={isOpenMobileAuth} handleClose={() => {dispatch(setIsOpenMobleAuth(false))}}/>
          <FeaturesPopup/>
          <WhatsAppPopup/>
          <WhatsApp/>
        </HelmetProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
