import { createStitches } from "@stitches/react";

export const { styled, css } = createStitches({
  media: {
    bp1: "(max-width: 60rem)",
    bp2: "(min-width: 60.0625rem)",
  },
});

export const MainSvgFill = styled("svg", {
  textAlign: "center",
  xmlns: "http://www.w3.org/2000/svg",

  "@bp2": {
    width: "$$widthD",
    height: "$$widthD",
    path: {
      scale: "calc($$scaleD/3.3333)",
    },
  },

  "@bp1": {
    width: "$$widthM",
    height: "$$widthM",
    path: {
      fill: "$$colorFillM",
      scale: "calc($$scaleM/3.3333)",
    },
  },
});

export const MainSvgEmpty = styled("svg", {
  textAlign: "center",
  xmlns: "http://www.w3.org/2000/svg",

  "@bp2": {
    width: "$$widthD",
    height: "$$widthD",
    path: {
      scale: "calc($$scaleD/3.3333)",
    },
  },

  "@bp1": {
    width: "$$widthM",
    height: "$$widthM",
    path: {
      scale: "calc($$scaleM/3.3333)",
    },
  },
});

export const MainWrapper = styled("div", {
  display: "flex",

  "@bp2": {
    gap: "$$gapD",
  },

  "@bp1": {
    gap: "$$gapM",
  },
});
