import close from "../../../assets/images/for-pages/icons/closeDialog.png";

import styles from "./popUp.module.scss";
const PopUp = (props) => {
  const {message, isOpen, setIsOpen} = props;

  return (
    <>
      {isOpen && (
        <div className={styles.dialogWrapper}>
          <div className={styles.mainDialog}>
            <div className={styles.closeDialog}>
              <img
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
                alt="closeImage"
                src={close}
              />
            </div>
            <div className={styles.content}>
              <span className={styles.descr}>{message}</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PopUp;
