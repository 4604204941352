import { Box } from '@mui/material';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import { Controller } from 'react-hook-form';
import arrowDown from '../../../assets/images/arrowDown.png';
const CustomTelInput = (props) => {
  const { name, disableValidation, disabledFlag, defaultCountry, preferredCountries, sx } = props;
  return (
    <Box sx={{ position: 'relative' }}>
      <img
        style={{ position: 'absolute', zIndex: '99', left: 22, top: '40%' }}
        src={arrowDown}
        alt="arrow"
      />
      <Controller
        name={name}
        rules={disableValidation ? undefined : { pattern: /.{5,}/, validate: matchIsValidTel }}
        render={({ field, fieldState }) => (
          <MuiTelInput
            onChange={(val, about) => {
              field.onChange(val);
            }}
            sx={{
              ...(disabledFlag
                ? {
                    '.MuiInputAdornment-root': {
                      position: 'absolute',
                      height: '100%',
                      maxHeight: 'unset',
                      maxWidth: '76px',
                      width: '100%',
                      padding: '10px 0px',
                      boxSizing: 'border-box',
                      zIndex: '100',
                      left: '0px',
                      //   marginLeft: '10px',
                    },
                    '.MuiIconButton-root': {
                      width: '85%',
                      height: '100%',
                      aspectRatio: 'unset',
                      //   borderRight: '1px solid #405F6A',
                      borderRadius: '0px',
                    },
                    '.MuiTelInput-Flag': {
                      width: '100%',
                    },
                    '.MuiTelInput-FlagImg': {
                      visibility: 'hidden',
                    },
                    ...sx,
                  }
                : {
                    ...sx,
                  }),
            }}
            defaultCountry={!!defaultCountry ? defaultCountry : undefined}
            value={field.value}
            preferredCountries={!!preferredCountries ? preferredCountries : undefined}
            error={fieldState.invalid}
            helperText={fieldState.error ? 'מספר טלפון לא תקין‍‍' : undefined}
          />
        )}
      />
    </Box>
  );
};
export default CustomTelInput;
