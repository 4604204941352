import classNames from 'classnames';
import styles from './styleCustomCheckBox.module.scss';
const CustomCheckBox = (props) => {
  const { value, onChange, label, variant, labelStyle, isError, name } = props;
  const currentVariant = () => {
    switch (variant) {
      case 'circle_agree':
        return styles.circle_agree;
      case 'circle_select':
        return styles.circle_select;
      default:
        return styles.basic;
    }
  };
  return (
    <>
      <input
        id={`${label}-${variant}-${name}-checkbox`}
        className={classNames(styles.checkbox, currentVariant(), isError && styles.error)}
        type="checkbox"
        value={value}
        checked={value}
        onChange={onChange}
      />
      <label
        style={{ ...labelStyle }}
        className={styles.label}
        htmlFor={`${label}-${variant}-${name}-checkbox`}
      >
        {label}
      </label>
    </>
  );
};
export default CustomCheckBox;
