import styles from "./SupplierRegistrationDesktop.module.scss";
import {Box, createTheme, LinearProgress, ThemeProvider} from "@mui/material";
import PersonalInformation from "./personalInformation/PersonalInformation";
import BusinessDetails from "./businessDetails/BusinessDetails";
import PriceList from "./priceList/PriceList";
import Images from "./images/Images";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {
  addSupplier,
  clearData,
  getCategories,
  getCompanyTypes,
  getRegions,
  saveSupplierContractRegistration,
  sendImagesRegistration,
  sendYouImagesRegistration,
  setIsLabel,
} from "../../store/supplierRegistrationSlice";
import GenLabel from "./genLabel/GenLabel";
import BusinessLabelDetails from "./businessLabelDetails/BusinessLabelDetails";
import {FormProvider, useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
// import LabelCalendar from "./labelCalendar/LabelCalendar";
import FinalScreen from "./finalScreen/FinalScreen";
// import SupplierCalendar from "./supplierCalendar/SupplierCalendar";
import useAuth from "../../hooks/useAuth";
import Calendar from "./calendar/CustomCalendar";
import {sendAllIntervalsReg} from "../../store/calendarSlice";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import arrowTop from "./img/arrowTop.png";
// import plus from "./img/plus.png"
// import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
// import Typography from '@mui/material/Typography';
import {styled} from "@mui/material/styles";
import {setErr} from "../../store/forAll/errSlice";
// import { useParams } from "react-router-dom";
import MetaFavicon from '../../components/metaComponents/metaFavicon'
import { setCategoriesWithServices} from "../../store/supplierRegistrationSlice";
import { useAnalyticsEvents } from "../../hooks/useAnalyticsEvents";
import { _MAXIMUI_UPLOAD_IMG } from "../../consts/local_keys";

const theme = createTheme({
  palette: {
    secondary: {
      main: '#E7B1AD'
    }
  }
});

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
  margin: "0 0.8125rem 0 0.8125rem",
  border: `none`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <div style={{height: "1.8125rem"}}>
        <img alt="arrow" src={arrowTop} />
      </div>
    }
    {...props}
  />
))(({theme}) => ({
  padding: "0 0.8125rem 0 0",
  borderBottom: "1px solid rgba(112, 112, 112, .28)",
  height: "3.75rem",
  backgroundColor: "#EEE9E5",
  // flexDirection: 'row-reverse',
  alignItems: "flex-end",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
    // marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
  padding: "0",
  paddingTop: "1.8125rem",
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  backgroundColor: "#EEE9E5",
}));

function SupplierRegistrationDesktop() {
  /* const {routeParams} = useParams(); let isGoogle = routeParams?.id; */
  // routeParams.id === "google"

  const allFieldsLabel0 = [
    "name",
    "phoneNumber",
    "email",
    "password",
    "passwordConfirm",
    "isRulsAccept",
    "isEmailAccept",
  ];
  const allFieldsLabel2 = [
    "name",
    "phoneNumber",
    "email",
    "password",
    "passwordConfirm",
    "businessName",
    // "companyRegistrationNumber",
    // "businessAddress",
    "isRulsAccept",
    "isEmailAccept",
  ];
  const allFieldsSupplier0 = [
    "name",
    "phoneNumber",
    "email",
    "password",
    "passwordConfirm",
    "isRulsAccept",
    "isEmailAccept",
  ];
  const allFieldsSupplier2 = [
    "name",
    "phoneNumber",
    "email",
    "password",
    "passwordConfirm",
    "prePayment",
    "businessName",
    "catWithServ",
    // "companyRegistrationNumber",
    // "businessAddress",
    "isRulsAccept",
    "isEmailAccept",
    "contractRequired",

    // "geoLocationsId",
    // "labelCode",
    // "companyTypeId",
    "businessDescription",
  ];
  const dispatch = useDispatch();
  const {ProviderSignUp} = useAnalyticsEvents();
  const axiosPrivate = useAxiosPrivate();
  const {setAuth, auth} = useAuth();
  let [stepNumber, setStepNumber1] = useState(0);
  const [loader, setLoader] = useState(false);
  const [isDisableButton,] = useState(false)
  const setStepNumber = (value) => {
    let div = document.getElementsByClassName('samyBody');
    div[0].scrollTop = 0
    //to block go back
    if (value < 3 && stepConfirm > 2) return;
    setStepNumber1(value);
  };
  let [stepConfirm, setStepConfirm] = useState(0);
  let [token, settoken] = useState('');
  let [supId, setsupId] = useState(0);
  let [isShowCalendarStep, setIsShowCalendarStep] = useState(0);

  const handleChange = (panel) => (event, newExpanded) => {
    setStepNumber(newExpanded ? panel : false);
  };

  const {
    isLabel,
    supplierYouImages,
    supplierUnicServices,
    images,
    supplierServices,
    isRegistredLabel,
    supplierImages,
    venuesServices,
  } = useSelector((state) => state.supplierRegistration);
  const {allInterval} = useSelector((state) => state.calendar);

  const [curentContract, setCurentContract] = useState({});

  const schema = yup.object().shape({
    name: yup
      .string()
      .min(2, "לפחות 2 אותיות") // min char 2
      .required("הכנס שם משתמש"), //name required
    // .min(5, 'The product name must be at least 5 characters'),

    phoneNumber: yup
      .string()
      .matches(/^(?:\+[\d\s-]+|[\d\s-]+)$/, "רק ספרות") // ONLY_NUMBERS
      .min(9, "לפחות 9 ספרות") //
     // .max(10, "מקסימום 10 ספרות") // MIN_CHAR_10
      .required("שדה חובה") //  FIELD_REQUIRED
      .test(
        "leading-zero",
        "מספר טלפון צריך להתחיל מ 0 או 972+", // LEDAING_ZERO
        (value, context) => {
          return context.originalValue && (context.originalValue.startsWith(0) || context.originalValue.startsWith('+'));
        }
      ),
    email: yup
      .string()
      .email("רשום מייל תקין") // 'Enter a valid email'
      .required("שדה חובה"), // 'You must enter a email'
    password: yup
      .string()
      .required("נא לרשום סיסמא") // 'Please enter your password.'
      .min(6, "הסיסמא קצרה מדי, לפחות 6 תוים."), // 'Password is too short - should be 6 chars minimum.'
    passwordConfirm: yup
      .string()
      .required("שדה חובה")
      .oneOf([yup.ref("password"), null], "הסיסמאות אינן תואמות"),
    businessName: yup.string().required("שדה חובה"),
    prePayment: yup.number().min(1, "רק ספרות").typeError("רק ספרות"),
    // .number()
    //   .typeError("רק ספרות"),
    catWithServ: yup
      .array()
      .min(1, "שדה חובה")
      .of(
        yup.object().shape({
          cat: yup.string().required("שדה חובה"),
          serv: yup
            .string()
            // .min(1,'שדה חובה' )
            .required("שדה חובה"),
        })
      ),
    // companyTypeId: yup
    //   .string()
    //   .required('שדה חובה'),
    // labelCode: yup
    //   .string(),
    // .required('שדה חובה'),
    geoLocationsId: yup.array().nullable(true),
    // .min(1,'שדה חובה' )
    // .required('שדה חובה'),
    isRulsAccept: yup.string().required("שדה חובה"),
    // isEmailAccept: yup
    //   .string()
    //   .required('שדה חובה'),
    contractRequired: yup.boolean().required("שדה חובה"),
    companyRegistrationNumber: yup
      .number()
      .nullable(true)
      .typeError("רק ספרות")
      .nullable(true)
      .transform((_, val) => (val === Number(val) ? val : null)),
    //   .required('שדה חובה'),
    // geoLocationId: yup
    //   .array()
    //   .required('You must enter a regions'),
    // labelCode: yup
    //   .string(),
    // .required('FIELD_REQUIRED'),
    companyTypeId: isLabel
      ? yup.number().typeError("רק ספרות").nullable(true)
      : // .required('FIELD_REQUIRED'),
        yup.number().typeError("רק ספרות").nullable(true),
    // .required('שדה חובה')),
    // businessAddress: yup
    //   .string()
    //   .required('שדה חובה'),
    businessDescription: isLabel
      ? yup.string()
      : yup.string().required("שדה חובה"),

    // discount: yup
    //   .number()
    //   .typeError('is required must be a number')
    //   .positive('min must be greater than zero')
    //   .required('min is required must be a number'),
  });

  const methods = useForm({
    mode: "all",
    defaultValues: {
      prePayment: 10,
      // type: {id:'1', name: 'Supplier', translate: t('SUPPLIER')},
    },
    resolver: yupResolver(schema),
  });

  const {getFieldState, trigger, setValue, getValues} = methods;

  const onChangeIsLabelOption = (e) => {
    if (e.target.value === "1") {
      dispatch(setIsLabel(true));
    } else {
      dispatch(setIsLabel(false));
    }
  };
  // const handleLabelSubmit = (func) => {
  //   func()
  // }

  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const checkVenueError = () => {
    return !!venuesServices?.find((service) => {
     //  console.log('venuesServices',venuesServices);
      if(service.isError){
        console.log(service)
      }
      return service.isError
    })
  }

  const checkWeddingError = () => {
    // console.log(supplierServices)
    return !!supplierServices?.find((service) => {
      return service?.category?.id === 2
    })?.supplierEventDto?.find((event) => {
      return event?.supplierService?.find(serv => {
        if(!(serv?.price>=serv?.minPrice)){
          return true
        } else {
          return false
        }
      })
      
    })
  }
  const checkFields = async (allFields) => {
    if (!allFields) {
      return true;
    }
    // console.log(allFields);
     console.log(auth);
    let isValid = true;

    allFields.forEach((item) => {
      setValue(item, getValues()[item] || "", {shouldValidate: true});
    });
    await timeout(0);
    allFields.forEach((item) => {
      trigger(item);
      const {error} = getFieldState(item);
      if (error) {
        isValid = false;
      }
    });
    if(getValues().hasDocument && getValues().hasDocument === '1' && !getValues().documentLoaded && stepNumber === 1){
      dispatch(
        setErr({
          message: 'יש להעלות מסמך חוזה',
          type: 1,
        })
      );
      isValid = false;
    }
    let cleaned = (getValues().phoneNumber).replace(/[\s-]/g, '');
    if(cleaned.startsWith('+972')){
      cleaned = cleaned.replace(/^(\+972)/, '0')
    }
    if(cleaned.startsWith('+') && !cleaned.startsWith('+972')){
      dispatch(
        setErr({
          message: "מספר טלפון צריך להתחיל מ 0 או 972+",
          type: 1,
        })
      );
      return false;
    }
    if (cleaned.length >= 9 && cleaned.length <= 10) {
      if(true){
        console.log(isValid);
      }
    } else {
      dispatch(
        setErr({
          message: "מקסימום 10 ספרות",
          type: 1,
        })
      );
      return false;
    }
    return isValid;
  };

  const sendAnalitics = () => {
    const obj = ({
      supplierName: getValues('name'),
      supplierEmail: getValues('email'),
      supplierPhone: getValues('phoneNumber'),
            // url: pathname,
      })
      ProviderSignUp(obj)
  }

  const goToNextPage = (event) => {
    if(event){
      event.target.disabled = true;
    }
    if (isLabel) {
      if (isLabel && stepNumber === 0) {
        // checkFields().then(isValid => {
        checkFields(allFieldsLabel0).then((isValid) => {
          if (!isValid){
            if(event){
              event.target.disabled = false;
            }
            return;
          }
          sendAnalitics()
          setStepNumber(++stepNumber);
          setStepConfirm(stepNumber);
          if(event){
            event.target.disabled = false;
          }
        });
      } else {
        if (isLabel && stepNumber === 1) {
          // checkFields().then(isValid => {
          checkFields(allFieldsLabel2).then((isValid) => {
            if (!isValid){
              if(event){
                event.target.disabled = false;
              }
              return;
            }
            setStepNumber(++stepNumber);
            setStepConfirm(stepNumber);
            if(event){
              event.target.disabled = false;
            }
          });
        } else {
          if (isLabel && stepNumber === 2) {
            // checkFields().then(isValid => {
            checkFields(allFieldsLabel2).then((isValid) => {
              // console.log(isValid);
              if (!isValid){
                if(event){
                  event.target.disabled = false;
                }
                return;
              }
              setStepNumber(++stepNumber);
              setStepConfirm(stepNumber);
              if(event){
                event.target.disabled = false;
              }
            });
          } else {
            if (isLabel && stepNumber === 3) {
              // checkFields().then(isValid => {
              checkFields(allFieldsLabel2).then((isValid) => {
                if (!isValid){
                  if(event){
                    event.target.disabled = false;
                  }
                  return;
                }
                setStepNumber(++stepNumber);
                setStepConfirm(stepNumber);
                dispatch(clearData());
                if(event){
                  event.target.disabled = false;
                }
              });
            }
          }
        }
      }
    }

    if (!isLabel && !isDisableButton) {
      if (!isLabel && stepNumber === 0) {
        // checkFields().then(isValid => {
        checkFields(allFieldsSupplier0).then((isValid) => {
          if (!isValid){
            if(event){
              event.target.disabled = false;
            }
            return;
          }
          sendAnalitics()
          setStepNumber(++stepNumber);
          setStepConfirm(stepNumber);
          if(event){
            event.target.disabled = false;
          }
        });
      } else {
        if (!isLabel && stepNumber === 1) {
          // checkFields().then(isValid => {
          checkFields(allFieldsSupplier2).then((isValid) => {
            if (!isValid){
              if(event){
                event.target.disabled = false;
              }
              return;
            }
            setStepNumber(++stepNumber);
            setStepConfirm(stepNumber);
            if(event){
              event.target.disabled = false;
            }
          });
        } else {
          if (!isLabel && stepNumber === 2) {
            checkFields(allFieldsSupplier2).then((isValid) => {
              if (!isValid){
                if(event){
                  event.target.disabled = false;
                }
                return;
              }
              console.log(checkVenueError(),checkWeddingError())
              if(checkVenueError() || checkWeddingError()){ 
                if(event){
                  event.target.disabled = false;
                }
                return
              }
              methods.handleSubmit(
                dispatch(
                  addSupplier({
                    data1: methods.getValues(),
                    supplierServices,
                    supplierUnicServices,
                    images,
                    setAuth,
                    venuesServices,
                  })
                ).then((res) => {
                  if (res?.error) {
                    if(event){
                      event.target.disabled = false;
                    }
                    dispatch(
                      setErr({
                        message: res?.payload?.response?.data?.message,
                        code: res?.payload?.response?.data?.code,
                      })
                    );
                  } else {
                    settoken(res.payload.temporaryToken);
                    setsupId(res.payload.supplierDto.id);
                    setStepNumber(++stepNumber);
                    setStepConfirm(stepNumber);
                    
                  }
                  if(event){
                    event.target.disabled = false;
                  }
                })
              );
            });
          } else {
            if (!isLabel && stepNumber === 3) {
              // checkFields().then(isValid => {
                if((supplierImages?.filter((item) => {
                  return item?.type || item?.fileType
                })?.length) > _MAXIMUI_UPLOAD_IMG){
                  dispatch(setErr({
                    message:" מספר מקסימלי של תמונות הוא " + _MAXIMUI_UPLOAD_IMG,
                    // code: res?.payload?.response?.data?.code,
                  }))
                  if(event){
                    event.target.disabled = false;
                  }
                  return
                }
              checkFields(allFieldsSupplier2).then((isValid) => {
                if (!isValid){
                  if(event){
                    event.target.disabled = false;
                  }
                  return;
                }
                setLoader(true)
                dispatch(
                  sendImagesRegistration({
                    supplierImages: supplierImages,
                    token,
                    supId
                  })
                ).then((result) => {
                  if (result?.error) {
                    dispatch(
                      setErr({
                        message: result?.payload?.response?.data?.message,
                        code: result?.payload?.response?.data?.code,
                      })
                    );
                  } else {
                  dispatch(
                    sendYouImagesRegistration({token, supId, supplierYouImages})
                  ).then((res) => {
                    if (res?.error ) {
                      dispatch(
                        setErr({
                          message: res?.payload?.response?.data?.message,
                          code: res?.payload?.response?.data?.code,
                        })
                      );
                    } else {
                      dispatch(setCategoriesWithServices([{id: 1 ,category: {}, services: {}, minPrice: {}, minPriceWithTax: {}, price: {}, priceWithTax: {}}]))
                      setStepNumber(++stepNumber);
                      setStepConfirm(stepNumber);
                      if(event){
                        event.target.disabled = false;
                      }
                    }
                    if(event){
                      event.target.disabled = false;
                    }
                  })
                }}).finally(() => {
                  setLoader(false);
                })
              });
            } else {
              if (!isLabel && stepNumber === 4) {
                // console.log(methods.getValues(),methods.errors, supplierServices)
                // methods.handleSubmit(
                //   dispatch(addSupplier({data1:methods.getValues(), supplierServices, images, setAuth})).then((res) => {

                // if(!res.error){
                // setStepNumber(++stepNumber);
                // setStepConfirm(stepNumber);
                checkFields(allFieldsSupplier2).then((isValid) => {
                  if (!isValid){
                    if(event){
                      event.target.disabled = false;
                    }
                    return;
                  }
                  dispatch(
                    sendAllIntervalsReg({intervals: allInterval, axiosPrivate, token, supId})  
                  ).then((res) => {
                            dispatch(
                              saveSupplierContractRegistration({
                                token,
                                contract: curentContract,
                                supId
                              })
                            ).then((res) => {
                              if (res?.error) {
                                dispatch(
                                  setErr({
                                    message:
                                      res?.payload?.response?.data?.message,
                                    code: res?.payload?.response?.data?.code,
                                  })
                                );
                              } else {
                              }
                            });
                            setStepNumber(++stepNumber);
                            setStepConfirm(stepNumber);
                            dispatch(clearData());
                            if(event){
                              event.target.disabled = false;
                            }
                          }
                  );
                });
                // }
                //   })
                // )
              } 
            }
          }
        }
      }
      // if (!isLabel && stepNumber < 5) { setStepNumber(++stepNumber);setStepConfirm(stepNumber);}
    }
  };

  useEffect(() => {
    //shoud be 0
    setStepNumber(0);
    setStepConfirm(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLabel]);

  useEffect(() => {
    dispatch(clearData());
    dispatch(getCompanyTypes());
    dispatch(getRegions());
    dispatch(getCategories());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MetaFavicon />
      <FormProvider {...methods}>
        <div className={styles.containerDesctop}>
          <div className={styles.content}>
            <h2 className={styles.header}>הרשמה ראשונה כספק</h2>
            <div className={styles.radioContainer}>
              {stepConfirm === 0 && (
                <div className={styles.radio}>
                  <input
                    id="radio-1"
                    type="radio"
                    name="radio"
                    checked={!isLabel}
                    value={"2"}
                    onChange={(e) => onChangeIsLabelOption(e)}
                  />
                  <label htmlFor="radio-1">ספק יחיד</label>
                </div>
              )}
              {stepConfirm === 0 && (
                <div className={styles.radio}>
                  <input
                    id="radio-2"
                    type="radio"
                    name="radio"
                    checked={isLabel}
                    value={"1"}
                    onChange={(e) => onChangeIsLabelOption(e)}
                  />
                  <label htmlFor="radio-2">לייבל</label>
                </div>
              )}
            </div>
            <div
              className={styles.progressContainer}
              style={{
                marginBottom: stepNumber === 2 && !isLabel ? "2.69rem" : "",
              }}
            >
              <div className={styles.navItem} onClick={() => setStepNumber(0)}>
                <svg
                  style={{
                    color: stepConfirm >= 0 ? "#405F6A" : "#242323",
                    opacity: stepConfirm >= 0 ? "1" : "",
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                >
                  <g
                    id="Group_198"
                    data-name="Group 198"
                    transform="translate(14125.5 339.5)"
                  >
                    <rect
                      id="Rectangle_230"
                      data-name="Rectangle 230"
                      width="25"
                      height="25"
                      transform="translate(-14125.5 -339.5)"
                      fill="none"
                    />
                    <g id="user" transform="translate(-14125.333 -339)">
                      <rect
                        id="Rectangle_229"
                        data-name="Rectangle 229"
                        width="24"
                        height="24"
                        transform="translate(0.333)"
                        fill="none"
                      />
                      <path
                        id="Ellipse_8"
                        data-name="Ellipse 8"
                        d="M-1.156-8A6.852,6.852,0,0,1,5.688-1.156,6.852,6.852,0,0,1-1.156,5.688,6.852,6.852,0,0,1-8-1.156,6.852,6.852,0,0,1-1.156-8Zm0,12.167A5.329,5.329,0,0,0,4.167-1.156,5.329,5.329,0,0,0-1.156-6.479,5.329,5.329,0,0,0-6.479-1.156,5.329,5.329,0,0,0-1.156,4.167Z"
                        transform="translate(13.323 10.281)"
                        fill="currentColor"
                      />
                      <path
                        id="Path_5"
                        data-name="Path 5"
                        d="M42.2,158.836a.76.76,0,0,1-.659-.38,9.885,9.885,0,0,0-17.123,0,.76.76,0,1,1-1.317-.76,11.406,11.406,0,0,1,19.757,0,.761.761,0,0,1-.658,1.141Z"
                        transform="translate(-20.813 -137.544)"
                        fill="currentColor"
                      />
                    </g>
                  </g>
                </svg>
                <p
                  style={{
                    fontFamily: stepNumber === 0 ? "Heebo-Medium" : "",
                    opacity: stepConfirm >= 0 ? "1" : "",
                    color: stepConfirm >= 0 ? "#405F6A" : "#242323",
                  }}
                >
                  פרטים אישיים
                </p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9.037"
                  height="15.807"
                  viewBox="0 0 9.037 15.807"
                >
                  <path
                    id="Icon_ionic-ios-arrow-back"
                    data-name="Icon ionic-ios-arrow-back"
                    d="M13.975,14.095l5.981-5.976a1.13,1.13,0,1,0-1.6-1.6L11.58,13.295a1.127,1.127,0,0,0-.033,1.558l6.8,6.818a1.13,1.13,0,1,0,1.6-1.6Z"
                    transform="translate(-11.251 -6.194)" /* fill="#405f6a"  Arrow past */
                    /* opacity="0.48" Arrow future */
                  />
                </svg>
              </div>
              <div
                className={styles.navItem}
                onClick={() => {
                  stepConfirm >= 1 && setStepNumber(1);
                }}
              >
                <svg
                  style={{
                    color: stepConfirm >= 1 ? "#405F6A" : "#242323",
                    opacity: stepConfirm >= 1 ? "1" : "",
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="27.556"
                  height="25"
                  viewBox="0 0 27.556 25"
                >
                  <g
                    id="Icon_feather-briefcase"
                    data-name="Icon feather-briefcase"
                    transform="translate(-2 -3.5)"
                  >
                    <path
                      id="Path_20"
                      data-name="Path 20"
                      d="M5.556,10.5H26a2.494,2.494,0,0,1,2.556,2.429V25.071A2.494,2.494,0,0,1,26,27.5H5.556A2.494,2.494,0,0,1,3,25.071V12.929A2.494,2.494,0,0,1,5.556,10.5Z"
                      transform="translate(0 0)"
                      fill="none"
                      stroke="currentColor" /* "#405f6a"; details: actual  */
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <path
                      id="Path_21"
                      data-name="Path 21"
                      d="M24,27.5V7.056A2.8,2.8,0,0,0,21,4.5H15a2.8,2.8,0,0,0-3,2.556V27.5"
                      transform="translate(-2.222 0)"
                      fill="none"
                      stroke="currentColor" /* stroke="#405f6a"; details: actual and past */
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                  </g>
                </svg>{" "}
                {/* Details future  */}
                <p
                  style={{
                    fontFamily: stepNumber === 1 ? "Heebo-Medium" : "",
                    opacity: stepConfirm >= 1 ? "1" : "",
                    color: stepConfirm >= 1 ? "#405F6A" : "#242323",
                  }}
                >
                  פרטי העסק
                </p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9.037"
                  height="15.807"
                  viewBox="0 0 9.037 15.807"
                >
                  <path
                    id="Icon_ionic-ios-arrow-back"
                    data-name="Icon ionic-ios-arrow-back"
                    d="M13.975,14.095l5.981-5.976a1.13,1.13,0,1,0-1.6-1.6L11.58,13.295a1.127,1.127,0,0,0-.033,1.558l6.8,6.818a1.13,1.13,0,1,0,1.6-1.6Z"
                    transform="translate(-11.251 -6.194)" /* fill="#405f6a"  Arrow past */
                    /* opacity="0.48" Arrow future */
                  />
                </svg>
              </div>
              {!isLabel ? (
                <>
                  <div
                    className={styles.navItem}
                    onClick={() => {
                      stepConfirm >= 2 && setStepNumber(2);
                    }}
                  >
                    <svg
                      style={{
                        color: stepConfirm >= 2 ? "#405F6A" : "#242323",
                        opacity: stepConfirm >= 2 ? "1" : "",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="27.221"
                      height="27"
                      viewBox="0 0 27.221 27"
                    >
                      <g
                        id="Icon_feather-tag"
                        data-name="Icon feather-tag"
                        transform="translate(-2 -2)"
                      >
                        <path
                          id="Path_18"
                          data-name="Path 18"
                          d="M27.248,17.883,17.9,27.235a2.609,2.609,0,0,1-3.691,0L3,16.044V3H16.044l11.2,11.2A2.609,2.609,0,0,1,27.248,17.883Z"
                          fill="none"
                          stroke="currentColor" /*  stroke="#405f6a": actual, past */
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        />
                        <path
                          id="Path_19"
                          data-name="Path 19"
                          d="M10.5,10.5h0"
                          transform="translate(-0.978 -0.978)"
                          fill="none"
                          stroke="currentColor" /* stroke="#405f6a"": actual, past */
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        />
                      </g>
                    </svg>{" "}
                    {/* Price future  */}
                    <p
                      style={{
                        fontFamily: stepNumber === 2 ? "Heebo-Medium" : "",
                        opacity: stepConfirm >= 2 ? "1" : "",
                        color: stepConfirm >= 2 ? "#405F6A" : "#242323",
                      }}
                    >
                      הזנת מחירון
                    </p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="9.037"
                      height="15.807"
                      viewBox="0 0 9.037 15.807"
                    >
                      <path
                        id="Icon_ionic-ios-arrow-back"
                        data-name="Icon ionic-ios-arrow-back"
                        d="M13.975,14.095l5.981-5.976a1.13,1.13,0,1,0-1.6-1.6L11.58,13.295a1.127,1.127,0,0,0-.033,1.558l6.8,6.818a1.13,1.13,0,1,0,1.6-1.6Z"
                        transform="translate(-11.251 -6.194)" /* fill="#405f6a"  Arrow past */
                        /* opacity="0.48" Arrow future */
                      />
                    </svg>
                  </div>

                  <div
                    className={styles.navItem}
                    onClick={() => {
                      stepConfirm >= 3 && setStepNumber(3);
                    }}
                  >
                    <svg
                      style={{
                        color: stepConfirm >= 3 ? "#405F6A" : "#242323",
                        opacity: stepConfirm >= 3 ? "1" : "",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="28.571"
                      height="25"
                      viewBox="0 0 28.571 25"
                    >
                      <path
                        id="Icon_metro-image"
                        data-name="Icon metro-image"
                        d="M29.353,5.642l0,0V27.067l0,0H4.36l0,0V5.645l0,0Zm0-1.786h-25A1.791,1.791,0,0,0,2.571,5.642V27.07a1.791,1.791,0,0,0,1.786,1.786h25a1.791,1.791,0,0,0,1.786-1.786V5.642a1.791,1.791,0,0,0-1.786-1.786Zm-3.571,6.25a2.679,2.679,0,1,1-2.679-2.679A2.679,2.679,0,0,1,25.785,10.106Zm1.786,15.179H6.142V21.713L12.392,11l7.143,8.929h1.786l6.25-5.357Z"
                        transform="translate(-2.571 -3.856)"
                        fill="currentColor"
                        /* fill="#405f6a" : actual */
                      />
                    </svg>{" "}
                    {/* Gallery future  */}
                    <p
                      style={{
                        fontFamily: stepNumber === 3 ? "Heebo-Medium" : "",
                        opacity: stepConfirm >= 3 ? "1" : "",
                        color: stepConfirm >= 3 ? "#405F6A" : "#242323",
                      }}
                    >
                      גלריה
                    </p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="9.037"
                      height="15.807"
                      viewBox="0 0 9.037 15.807"
                    >
                      <path
                        id="Icon_ionic-ios-arrow-back"
                        data-name="Icon ionic-ios-arrow-back"
                        d="M13.975,14.095l5.981-5.976a1.13,1.13,0,1,0-1.6-1.6L11.58,13.295a1.127,1.127,0,0,0-.033,1.558l6.8,6.818a1.13,1.13,0,1,0,1.6-1.6Z"
                        transform="translate(-11.251 -6.194)" /* fill="#405f6a"  Arrow past */
                        /* opacity="0.48" Arrow future */
                      />
                    </svg>
                  </div>
                </>
              ) : (
                <div
                  className={styles.navItem}
                  onClick={() => {
                    stepConfirm >= 2 && setStepNumber(2);
                  }}
                >
                  <p
                    style={{
                      fontFamily: stepNumber === 2 ? "Heebo-Medium" : "",
                      opacity: stepConfirm >= 2 ? "1" : "",
                      color: stepConfirm >= 2 ? "#405F6A" : "#242323",
                    }}
                  >
                    הפק קוד לייבל
                  </p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9.037"
                    height="15.807"
                    viewBox="0 0 9.037 15.807"
                  >
                    <path
                      id="Icon_ionic-ios-arrow-back"
                      data-name="Icon ionic-ios-arrow-back"
                      d="M13.975,14.095l5.981-5.976a1.13,1.13,0,1,0-1.6-1.6L11.58,13.295a1.127,1.127,0,0,0-.033,1.558l6.8,6.818a1.13,1.13,0,1,0,1.6-1.6Z"
                      transform="translate(-11.251 -6.194)" /* fill="#405f6a"  Arrow past */
                      /* opacity="0.48" Arrow future */
                    />
                  </svg>{" "}
                </div>
              )}

              <div
                className={styles.navItem}
                onClick={() => {
                  stepConfirm >= 4 && setStepNumber(4);
                }}
              >
                <svg
                  style={{
                    color: stepConfirm >= 4 ? "#405F6A" : "#242323",
                    opacity: stepConfirm >= 3 ? "1" : "",
                  }}
                  id="calendar-blank_1_"
                  data-name="calendar-blank (1)"
                  xmlns="http://www.w3.org/2000/svg"
                  width="28.741"
                  height="28.741"
                  viewBox="0 0 28.741 28.741"
                >
                  <rect
                    id="Rectangle_287"
                    data-name="Rectangle 287"
                    width="28.741"
                    height="28.741"
                    fill="none"
                  />
                  <path
                    id="Rectangle_288"
                    data-name="Rectangle 288"
                    d="M-6.183-8H11.988a1.819,1.819,0,0,1,1.817,1.817V11.988a1.819,1.819,0,0,1-1.817,1.817H-6.183A1.819,1.819,0,0,1-8,11.988V-6.183A1.819,1.819,0,0,1-6.183-8ZM11.988,11.988V-6.183H-6.184V11.988Z"
                    transform="translate(11.468 11.468)"
                    fill="currentColor"
                  />
                  <path
                    id="Line_19"
                    data-name="Line 19"
                    d="M-7.133-2.8A.867.867,0,0,1-8-3.666V-7.133A.867.867,0,0,1-7.133-8a.867.867,0,0,1,.867.867v3.468A.867.867,0,0,1-7.133-2.8Z"
                    transform="translate(26.905 9.734)"
                    fill="currentColor"
                  />
                  <path
                    id="Line_20"
                    data-name="Line 20"
                    d="M-7.133-2.8A.867.867,0,0,1-8-3.666V-7.133A.867.867,0,0,1-7.133-8a.867.867,0,0,1,.867.867v3.468A.867.867,0,0,1-7.133-2.8Z"
                    transform="translate(16.102 9.734)"
                    fill="currentColor"
                  />
                  <path
                    id="Line_21"
                    data-name="Line 21"
                    d="M12.9-6.266H-7.091A.888.888,0,0,1-8-7.133.888.888,0,0,1-7.091-8H12.9a.888.888,0,0,1,.909.867A.888.888,0,0,1,12.9-6.266Z"
                    transform="translate(11.468 17.002)"
                    fill="currentColor"
                  />
                </svg>
                <p
                  style={{
                    fontFamily: stepNumber === 4 ? "Heebo-Medium" : "",
                    opacity: stepConfirm >= 4 ? "1" : "",
                    color: stepConfirm >= 4 ? "#405F6A" : "#242323",
                  }}
                >
                  עדכון יומן
                </p>
              </div>
            </div>
            <div className={styles.formsContainer}>
              {stepNumber === 0 &&
                (isLabel ? <PersonalInformation /> : <PersonalInformation />)}
              {stepNumber === 1 &&
                (isLabel ? (
                  <BusinessLabelDetails />
                ) : (
                  <BusinessDetails changeContract={setCurentContract} curentContract={curentContract}/>
                ))}
              {stepNumber === 2 && (isLabel ? <GenLabel /> : <PriceList />)}
              {
                stepNumber === 3 &&
                  (!isLabel ? (
                    <>
                      <Images />
                      {loader && 
                        <ThemeProvider theme={theme}>

                          <Box sx={{ width: '95%', paddingBottom: "0.5rem"}}>
                          {"ממתין להטענת התמונות"}
                          <LinearProgress color="secondary"/>
                        </Box>
                        </ThemeProvider>
                        }
                    </>
                    
                  ) : (
                    <></>
                  ))
                // <Calendar/>
              }
              {stepNumber === 4 &&
                (!isLabel ? <Calendar
                  token={token}
                  supId={supId} /> : <FinalScreen isLabel={isLabel} />)}
              {stepNumber === 5 && <FinalScreen isLabel={isLabel} />}
            </div>
            <button
              hidden={isLabel && stepNumber === 2 && !isRegistredLabel}
              className={styles.nextButton}
              onClick={(e) => goToNextPage(e)}
              disabled={isDisableButton}
            >
              {`אישור והמשך`}
            </button>
          </div>
        </div>

        {isShowCalendarStep ? (
          <div className={styles.calendarMobile}>
            {" "}
            <Calendar
              step={isShowCalendarStep}
              changeStep={setIsShowCalendarStep}
              token={token}
              supId={supId}
            />
            {(isShowCalendarStep === 1 || isShowCalendarStep === 3) && (
              <div className={styles.mainBtns}>
                <button
                  // hidden={(isLabel && stepNumber===2 && !isRegistredLabel)}
                  className={styles.nextMobileButton}
                  disabled={isDisableButton}
                  onClick={(e) => {
                    goToNextPage(e);
                    setIsShowCalendarStep(0);
                  }}
                >
                  {"אישור וסיום"}
                </button>

                <div
                  className={styles.link}
                  onClick={() => setIsShowCalendarStep(0)}
                >
                  {"יציאה מהיומן"}
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className={styles.containerMobile}>
            <span className={styles.mobileTitle}>הרשמה ראשונה כלייבל</span>
            <div className={styles.radioMobileContainer}>
              {stepConfirm === 0 && (
                <div className={styles.radio}>
                  <input
                    id="radio-3"
                    type="radio"
                    name="radio1"
                    checked={!isLabel}
                    value={"2"}
                    onChange={(e) => onChangeIsLabelOption(e)}
                  />
                  <label htmlFor="radio-3">ספק יחיד</label>
                </div>
              )}
              {stepConfirm === 0 && (
                <div className={styles.radio}>
                  <input
                    id="radio-4"
                    type="radio"
                    name="radio1"
                    checked={isLabel}
                    value={"1"}
                    onChange={(e) => onChangeIsLabelOption(e)}
                  />
                  <label htmlFor="radio-4">לייבל</label>
                </div>
              )}
            </div>
            <div className={styles.accordion}>
              <Accordion expanded={stepNumber === 0} onChange={handleChange(0)}>
                <AccordionSummary aria-controls="0d-content" id="0d-header">
                  <div className={styles.tabTitle}>פרטים אישיים</div>
                </AccordionSummary>
                <AccordionDetails>
                  <PersonalInformation />
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      hidden={isLabel && stepNumber === 2 && !isRegistredLabel}
                      className={styles.nextMobileButton}
                      onClick={(e) => goToNextPage(e)}
                      disabled={isDisableButton}
                    >{`אישור והמשך`}</button>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={stepNumber === 1}
                onChange={stepConfirm >= 1 && handleChange(1)}
              >
                <AccordionSummary aria-controls="1d-content" id="1d-header">
                  <div className={styles.tabTitle}>פרטי העסק</div>
                </AccordionSummary>
                <AccordionDetails>
                  {isLabel ? (
                    <BusinessLabelDetails />
                  ) : (
                    <BusinessDetails changeContract={setCurentContract} curentContract={curentContract}/>
                  )}
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      hidden={isLabel && stepNumber === 2 && !isRegistredLabel}
                      className={styles.nextMobileButton}
                      onClick={(e) => goToNextPage(e)}
                      disabled={isDisableButton}
                    >{`אישור והמשך`}</button>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={stepNumber === 2}
                onChange={stepConfirm >= 2 && handleChange(2)}
              >
                <AccordionSummary aria-controls="2d-content" id="2d-header">
                  <div className={styles.tabTitle}>
                    {isLabel ? "קוד לייבל" : "הזנת מחירון"}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {isLabel ? (
                    <GenLabel changeStep={goToNextPage} />
                  ) : (
                    <PriceList changeStep={goToNextPage} />
                  )}
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {/* {!isLabel && isRegistredLabel && (
                      <button
                        // hidden={(isLabel && stepNumber===2 && !isRegistredLabel)}
                        className={styles.nextMobileButton}
                        onClick={() => goToNextPage()}
                      >{`אישור והמשך`}</button>
                    )} */}
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={stepNumber === 3}
                onChange={stepConfirm >= 3 && handleChange(3)}
              >
                <AccordionSummary aria-controls="3d-content" id="3d-header">
                  <div className={styles.tabTitle}>
                    {isLabel ? "עדכון יומן" : "גלריה"}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {
                    !isLabel ? (
                      <> 
                        <Images />
                        {loader && 
                        <ThemeProvider theme={theme}>

                          <Box sx={{ maxWidth: '100%', paddingBottom: "0.5rem"}}>
                          {"ממתין להטענת התמונות"}
                          <LinearProgress color="secondary"/>
                        </Box>
                        </ThemeProvider>
                        }
                      </>
                     
                      
                    ) : (
                      <></>
                    )
                    // <Calendar/>
                  }
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      // hidden={(isLabel && stepNumber===2 && !isRegistredLabel)}
                      className={styles.nextMobileButton}
                      onClick={(e) => goToNextPage(e)}
                      disabled={isDisableButton}
                    >
                      {isLabel ? "מעבר ליומן" : `אישור והמשך`}
                    </button>
                  </div>
                </AccordionDetails>
              </Accordion>
              {!isLabel && (
                <Accordion
                  expanded={stepNumber === 4}
                  onChange={stepConfirm >= 4 && handleChange(4)}
                >
                  <AccordionSummary aria-controls="4d-content" id="4d-header">
                    <div className={styles.tabTitle}>עדכון יומן</div>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* <Calendar /> */}
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        // hidden={(isLabel && stepNumber===2 && !isRegistredLabel)}
                        className={styles.nextMobileButton}
                        onClick={() => setIsShowCalendarStep(1)}
                      >
                        {"מעבר ליומן"}
                      </button>
                    </div>
                  </AccordionDetails>
                </Accordion>
              )}
              {(stepNumber === 5 || (stepNumber === 4 && isLabel)) && (
                <FinalScreen isLabel={isLabel} />
              )}
            </div>
          </div>
        )}
      </FormProvider>
    </>
  );
}

export default SupplierRegistrationDesktop;
