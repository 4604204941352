import { Typography } from '@mui/material';
import styles from '../styleSuppliers.module.scss';
import { useSelector } from 'react-redux';
const HelperText = () => {
  const { selectedEventTypeId } = useSelector((state) => state.suppliers);
  return !selectedEventTypeId ? (
    <div className={styles.helper_text_container}>
      <Typography>על מנת להתקדם עם חיפוש הספקים, עליך לבחור תחילה את סוג האירוע</Typography>
    </div>
  ) : (
    <></>
  );
};
export default HelperText;
