import { Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../../../styleBasicForm.module.scss';
import { EVENT_TYPE_ENUM, STEPS_ENUM } from '../stepForm.config';
import { fetchAllEvetns, setCurrentStep } from '../../../../../../store/mainSlice';
import CustomSelect from '../../../../../../components/customSelect/CustomSelect';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
const WhatCelebrating = () => {
  const dispatch = useDispatch();
  const {watch,resetField, setValue} = useFormContext()
  let { allEvents, currentStep } = useSelector((state) => state.main);
  const typeEvent = watch('typeEvent')
  const currentEvents = useMemo(() => {
    if(EVENT_TYPE_ENUM.BUSINESS === typeEvent && allEvents?.find((event) => event.id === 3))
      return [allEvents?.find((event) => event.id === 3)]
    return allEvents.filter((item) => item.id !== 3)
  },[typeEvent,allEvents])

  useEffect(() => {
    if(typeEvent === EVENT_TYPE_ENUM.PRIVATE) {
      resetField('celebrating')
    }
    if(typeEvent === EVENT_TYPE_ENUM.BUSINESS && currentEvents.length !== 0) {
      setValue('celebrating', currentEvents[0]?.id)
      if (currentStep === STEPS_ENUM.WHAT_CELEBRATING)
        dispatch(setCurrentStep(STEPS_ENUM.EXISTS_PLACE));
    }
  }
  ,[typeEvent,currentEvents])
  return (
    
    <div className={styles.one_step}>
      <Typography className={styles.one_step_label}>מה חוגגים?</Typography>
      <div className={styles.field_container} style={{ flex: '1 0 auto', maxHeight: '53px' }}>
        <CustomSelect
          disabled={currentEvents.length === 0}
          name="celebrating"
          rules={{ required: { value: true, message: 'Field is required' } }}
          placeholder="בחר מתוך רשימת האירועים"
          onChange={() => {
            if (currentStep === STEPS_ENUM.WHAT_CELEBRATING)
              dispatch(setCurrentStep(STEPS_ENUM.EXISTS_PLACE));
          }}
          options={currentEvents}
          sxSelect={{ height: { xs: '42px', sm: '53px' } }}
        />
      </div>
    </div>
  );
};
export default WhatCelebrating;
