import classNames from "classnames";
import {useEffect, useMemo, useState} from "react";
import styles from "./TableRow.module.css";
import shekelSymbol from '../../../../assets/images/icon-payment-ils.png'
import useAuth from "../../../hooks/useAuth"
import useAxiosPrivate from "../../../hooks/useAxiosPrivate"
import axios from "../../../../api/axios";
import {TEMP_USER_KEY} from "../../../consts/local_keys";
import { useSelector } from "react-redux";

function TableRow(props) {
    const {service, getTotalAmount, getMinTotalAmount, id, activeId, chooseActive, eventTypeId, categoryId, supplierId, isContractCreated} = props;
    const { currentEvent:currentChecklist } = useSelector((state) => state.checklist);
    const { suppliersFilters,venuesFilters } = useSelector((state) => state.suppliers);
    const {auth} = useAuth();
    const [services, setServices] = useState({data: [], loading: false});
    let currentRole = (Object.keys(auth).length !== 0) ? auth?.roles[0] : '';
    let userId = (Object.keys(auth).length !== 0) ? JSON.parse(auth.user).id : '';
    let [counter, setCounter] = useState("0");
    const axiosPrivate = useAxiosPrivate();
    /*   let [isActive, setIsActive] = useState(false); */
    const currentDateProps = useMemo(() => {
        const isVenuesFilters = categoryId ===1 && Object.keys(venuesFilters).length !== 0 
        return {
            query: isVenuesFilters ? venuesFilters?.dateFrom ? `&date=${venuesFilters.dateFrom}` : '' : suppliersFilters?.eventDate ? `&date=${suppliersFilters.eventDate}` : '',
            date: isVenuesFilters ? venuesFilters?.dateFrom ? venuesFilters.dateFrom : '' : suppliersFilters?.eventDate ? suppliersFilters.eventDate: ''
        }
      },[suppliersFilters,venuesFilters,categoryId])
    useEffect(() => {
        const fetchData = async () => {
            if ((currentRole === 'USER' || currentRole === '') && props.categoryId && props.eventTypeId) {
                const result =
                currentRole ? 
                await axiosPrivate.get(`${process.env.REACT_APP_API_PATH}user/choose/service?userId=${userId}&supId=${supplierId}&eventTypeId=${eventTypeId}&categoryId=${categoryId}&checkListId=${currentChecklist?.checkListId || 0}${currentDateProps.query}`) :
                await axios.get(`${process.env.REACT_APP_API_PATH}user/choose/service/anonymous?userId=${userId}&supId=${supplierId}&eventTypeId=${eventTypeId}&categoryId=${categoryId}&userToken=${localStorage.getItem(TEMP_USER_KEY) || ''}&checkListId=${currentChecklist?.checkListId || 0}${currentDateProps.query}`)
                ;
                setServices({data: [], loading: true});
                setServices({data: result.data.userServiceTypeChooseDtos, loading: false});
                getTotalAmount(true, result.data.commonPrice);
                getMinTotalAmount(true, result.data.minCommonPrice);
                if (!currentRole) {
                    const value = result.data.userServiceTypeChooseDtos.find(item => item.id === id);
                    if (value) {
                        setCounter(value.count);
                    }
                }
            }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.categoryId && props.eventTypeId]);

    const changeServiceCount = async (item, action) => {
        setServices({loading: true});
        let newCount = 0;
        let serviceId = 1;
        let newServices = services.data.map((val) => {
            if (val.id === item.id) {
                if (action) {
                    val.count++;
                    getTotalAmount(action, val.price);
                    getMinTotalAmount(action, val.minPrice);
                    newCount = val.count;
                    serviceId = val.id;
                    return val
                } else if (!action && val.count > 0) {
                    val.count--;
                    getTotalAmount(action, val.price);
                    getMinTotalAmount(action, val.minPrice);
                    newCount = val.count;
                    serviceId = val.id;
                    return val
                } else if (!action && val.count <= 0) {
                    return val
                }
            }
            return val
        })
        setServices({data: newServices, loading: false});
        let data = {
            userId: userId,
            supId: Number(supplierId),
            eventTypeId: eventTypeId,
            categoryId: categoryId,
            serviceTypeId: serviceId,
            countServiceType: newCount,
            checkListId:currentChecklist?.checkListId || 0,
            ...(currentDateProps.date ? {date:currentDateProps.date} : {})
        }
        setTimeout(() => {
            axiosPrivate.put(`${process.env.REACT_APP_API_PATH}user/update/choose/service`, data);
        }, 100);
    };

    const changeCounter = (action) => {
        if (action) {
            setCounter(++counter);
            getTotalAmount(action, service.priceWithTax);
            getMinTotalAmount(action, service.minPriceWithTax);
        }
        if (!action && counter > 0) {
            setCounter(--counter);
            getTotalAmount(action, service.priceWithTax);
            getMinTotalAmount(action, service.minPriceWithTax);
        }

        chooseActive(id)
        let data = {
            supId: Number(supplierId),
            eventTypeId: eventTypeId,
            categoryId: categoryId,
            serviceTypeId: service.id,
            countServiceType: counter,
            userId: localStorage.getItem(TEMP_USER_KEY) || 0,
            checkListId:currentChecklist?.checkListId || 0,
            ...(currentDateProps.date ? {date:currentDateProps.date} : {})
        }
        setTimeout(() => {
            axios.put(`${process.env.REACT_APP_API_PATH}user/update/choose/service/anonymous`, data).then((e) => {
                localStorage.setItem(TEMP_USER_KEY, e.data);
            });
        }, 100);
    };

    return (
        <>
            {(currentRole === 'USER') && !services.loading && services.data.map((item, index) => {
                return (<tr key={index} className={classNames(styles.tableRow, {
                    [styles.activRow]: activeId === id,
                })}>
                    <td className={styles.tableCell}>{item.name}</td>
                    <td className={styles.tableCell}>
                        {categoryId === 2 && item.minPrice !== null ? `${item.minPrice}-${item.price}` : `${item.price}`}
                        <img src={shekelSymbol} alt='shekel' className={styles.shekelSymbol}/>

                    </td>
                    <td className={styles.tableCell}>
                        <div className={styles.blockForCell}>
                            {!isContractCreated && <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="33"
                                height="32"
                                viewBox="0 0 33 32"
                                className={styles.counterButton}
                                onClick={() => changeServiceCount(item, false)}
                            >
                                <g
                                    id="Group_343"
                                    data-name="Group 343"
                                    transform="translate(0.368 -0.161)"
                                    opacity="0.71"
                                >
                                    <ellipse
                                        id="Ellipse_8"
                                        data-name="Ellipse 8"
                                        cx="16.5"
                                        cy="16"
                                        rx="16.5"
                                        ry="16"
                                        transform="translate(-0.368 0.161)"
                                        fill="#fff"
                                    />
                                    <g
                                        id="Group_341"
                                        data-name="Group 341"
                                        transform="translate(3.252 4.335)"
                                    >
                                        <rect
                                            id="Rectangle_333"
                                            data-name="Rectangle 333"
                                            width="25"
                                            height="24"
                                            transform="translate(0.381 -0.174)"
                                            fill="none"
                                        />
                                        <g
                                            id="Group_340"
                                            data-name="Group 340"
                                            transform="translate(0 0)"
                                        >
                                            <rect
                                                id="Rectangle_332"
                                                data-name="Rectangle 332"
                                                width="25"
                                                height="24"
                                                transform="translate(0.381 -0.174)"
                                                fill="none"
                                            />
                                            <g id="Group_339" data-name="Group 339">
                                                <rect
                                                    id="Rectangle_331"
                                                    data-name="Rectangle 331"
                                                    width="25"
                                                    height="24"
                                                    transform="translate(0.381 -0.174)"
                                                    fill="none"
                                                />
                                                <g
                                                    id="Group_337"
                                                    data-name="Group 337"
                                                    transform="translate(0.396 11.784)"
                                                >
                                                    <path
                                                        id="Line_24"
                                                        data-name="Line 24"
                                                        d="M22.828-.066H-.533A.467.467,0,0,1-1-.533.467.467,0,0,1-.533-1h23.36a.467.467,0,0,1,.467.467A.467.467,0,0,1,22.828-.066Z"
                                                        transform="translate(1 1)"
                                                        fill="#242323"
                                                    />
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>}
                            <span className={styles.quontity}>{item.count}</span>

                            {!isContractCreated && <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                className={styles.counterButton}
                                onClick={() => changeServiceCount(item, true)}
                            >
                                <g
                                    id="Group_342"
                                    data-name="Group 342"
                                    transform="translate(-0.252 -0.161)"
                                >
                                    <circle
                                        id="Ellipse_8"
                                        data-name="Ellipse 8"
                                        cx="16"
                                        cy="16"
                                        r="16"
                                        transform="translate(0.252 0.161)"
                                        fill="#fff"
                                    />
                                    <g
                                        id="Group_341"
                                        data-name="Group 341"
                                        transform="translate(2.168 4.335)"
                                    >
                                        <rect
                                            id="Rectangle_333"
                                            data-name="Rectangle 333"
                                            width="26"
                                            height="24"
                                            transform="translate(0.084 -0.174)"
                                            fill="none"
                                        />
                                        <g
                                            id="Group_340"
                                            data-name="Group 340"
                                            transform="translate(0 0)"
                                        >
                                            <rect
                                                id="Rectangle_332"
                                                data-name="Rectangle 332"
                                                width="26"
                                                height="24"
                                                transform="translate(0.084 -0.174)"
                                                fill="none"
                                            />
                                            <g id="Group_339" data-name="Group 339">
                                                <rect
                                                    id="Rectangle_331"
                                                    data-name="Rectangle 331"
                                                    width="26"
                                                    height="24"
                                                    transform="translate(0.084 -0.174)"
                                                    fill="none"
                                                />
                                                <g
                                                    id="Group_337"
                                                    data-name="Group 337"
                                                    transform="translate(1.608 0.104)"
                                                >
                                                    <path
                                                        id="Line_24"
                                                        data-name="Line 24"
                                                        d="M22.828-.066H-.533A.467.467,0,0,1-1-.533.467.467,0,0,1-.533-1h23.36a.467.467,0,0,1,.467.467A.467.467,0,0,1,22.828-.066Z"
                                                        transform="translate(1 12.68)"
                                                        fill="#242323"
                                                    />
                                                    <path
                                                        id="Line_25"
                                                        data-name="Line 25"
                                                        d="M23.828.934H.467A.467.467,0,0,1,.467,0h23.36a.467.467,0,0,1,0,.934Z"
                                                        transform="translate(12.615 0) rotate(90)"
                                                        fill="#242323"
                                                    />
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>}
                        </div>
                    </td>
                </tr>)
            })

            }

            {(currentRole === 'SUPPLIER' || currentRole === 'LABEL') && <tr className={classNames(styles.tableRow, {
                [styles.activRow]: activeId === id,
            })}>
                <td className={styles.tableCell}>{service.name}</td>
                <td className={styles.tableCell}>
                    {categoryId === 2 && service.minPriceWithTax!==null ? `${Math.round(service.minPriceWithTax)}-${Math.round(service.priceWithTax)}` : `${Math.round(service.priceWithTax)}`}  
                    {/* {service.priceWithTax} */}


                    <img src={shekelSymbol} alt='shekel' className={styles.shekelSymbol}/>

                </td>
               {currentRole !== 'SUPPLIER' && <td className={styles.tableCell}>
                    <div className={styles.blockForCell}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="33"
                            height="32"
                            viewBox="0 0 33 32"
                            className={styles.counterButton}
                            onClick={() => changeCounter(false)}
                        >
                            <g
                                id="Group_343"
                                data-name="Group 343"
                                transform="translate(0.368 -0.161)"
                                opacity="0.71"
                            >
                                <ellipse
                                    id="Ellipse_8"
                                    data-name="Ellipse 8"
                                    cx="16.5"
                                    cy="16"
                                    rx="16.5"
                                    ry="16"
                                    transform="translate(-0.368 0.161)"
                                    fill="#fff"
                                />
                                <g
                                    id="Group_341"
                                    data-name="Group 341"
                                    transform="translate(3.252 4.335)"
                                >
                                    <rect
                                        id="Rectangle_333"
                                        data-name="Rectangle 333"
                                        width="25"
                                        height="24"
                                        transform="translate(0.381 -0.174)"
                                        fill="none"
                                    />
                                    <g
                                        id="Group_340"
                                        data-name="Group 340"
                                        transform="translate(0 0)"
                                    >
                                        <rect
                                            id="Rectangle_332"
                                            data-name="Rectangle 332"
                                            width="25"
                                            height="24"
                                            transform="translate(0.381 -0.174)"
                                            fill="none"
                                        />
                                        <g id="Group_339" data-name="Group 339">
                                            <rect
                                                id="Rectangle_331"
                                                data-name="Rectangle 331"
                                                width="25"
                                                height="24"
                                                transform="translate(0.381 -0.174)"
                                                fill="none"
                                            />
                                            <g
                                                id="Group_337"
                                                data-name="Group 337"
                                                transform="translate(0.396 11.784)"
                                            >
                                                <path
                                                    id="Line_24"
                                                    data-name="Line 24"
                                                    d="M22.828-.066H-.533A.467.467,0,0,1-1-.533.467.467,0,0,1-.533-1h23.36a.467.467,0,0,1,.467.467A.467.467,0,0,1,22.828-.066Z"
                                                    transform="translate(1 1)"
                                                    fill="#242323"
                                                />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <span className={styles.quontity}>{counter}</span>

                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            className={styles.counterButton}
                            onClick={() => changeCounter(true)}
                        >
                            <g
                                id="Group_342"
                                data-name="Group 342"
                                transform="translate(-0.252 -0.161)"
                            >
                                <circle
                                    id="Ellipse_8"
                                    data-name="Ellipse 8"
                                    cx="16"
                                    cy="16"
                                    r="16"
                                    transform="translate(0.252 0.161)"
                                    fill="#fff"
                                />
                                <g
                                    id="Group_341"
                                    data-name="Group 341"
                                    transform="translate(2.168 4.335)"
                                >
                                    <rect
                                        id="Rectangle_333"
                                        data-name="Rectangle 333"
                                        width="26"
                                        height="24"
                                        transform="translate(0.084 -0.174)"
                                        fill="none"
                                    />
                                    <g
                                        id="Group_340"
                                        data-name="Group 340"
                                        transform="translate(0 0)"
                                    >
                                        <rect
                                            id="Rectangle_332"
                                            data-name="Rectangle 332"
                                            width="26"
                                            height="24"
                                            transform="translate(0.084 -0.174)"
                                            fill="none"
                                        />
                                        <g id="Group_339" data-name="Group 339">
                                            <rect
                                                id="Rectangle_331"
                                                data-name="Rectangle 331"
                                                width="26"
                                                height="24"
                                                transform="translate(0.084 -0.174)"
                                                fill="none"
                                            />
                                            <g
                                                id="Group_337"
                                                data-name="Group 337"
                                                transform="translate(1.608 0.104)"
                                            >
                                                <path
                                                    id="Line_24"
                                                    data-name="Line 24"
                                                    d="M22.828-.066H-.533A.467.467,0,0,1-1-.533.467.467,0,0,1-.533-1h23.36a.467.467,0,0,1,.467.467A.467.467,0,0,1,22.828-.066Z"
                                                    transform="translate(1 12.68)"
                                                    fill="#242323"
                                                />
                                                <path
                                                    id="Line_25"
                                                    data-name="Line 25"
                                                    d="M23.828.934H.467A.467.467,0,0,1,.467,0h23.36a.467.467,0,0,1,0,.934Z"
                                                    transform="translate(12.615 0) rotate(90)"
                                                    fill="#242323"
                                                />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                </td> }
            </tr>}

            
            {(currentRole === '') && <tr className={classNames(styles.tableRow, {
                [styles.activRow]: activeId === id,
            })}>
                <td className={styles.tableCell}>{service.name}</td>
                <td className={styles.tableCell}>
                    {categoryId === 2 && service.minPriceWithTax!==null ? `${Math.round(service.minPriceWithTax)}-${Math.round(service.priceWithTax)}` : `${Math.round(service.priceWithTax)}`}  

                    {/* {service.priceWithTax} */}


                    <img src={shekelSymbol} alt='shekel' className={styles.shekelSymbol}/>

                </td>
               {currentRole !== 'SUPPLIER' && <td className={styles.tableCell}>
                    <div className={styles.blockForCell}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="33"
                            height="32"
                            viewBox="0 0 33 32"
                            className={styles.counterButton}
                            onClick={() => changeCounter(false)}
                        >
                            <g
                                id="Group_343"
                                data-name="Group 343"
                                transform="translate(0.368 -0.161)"
                                opacity="0.71"
                            >
                                <ellipse
                                    id="Ellipse_8"
                                    data-name="Ellipse 8"
                                    cx="16.5"
                                    cy="16"
                                    rx="16.5"
                                    ry="16"
                                    transform="translate(-0.368 0.161)"
                                    fill="#fff"
                                />
                                <g
                                    id="Group_341"
                                    data-name="Group 341"
                                    transform="translate(3.252 4.335)"
                                >
                                    <rect
                                        id="Rectangle_333"
                                        data-name="Rectangle 333"
                                        width="25"
                                        height="24"
                                        transform="translate(0.381 -0.174)"
                                        fill="none"
                                    />
                                    <g
                                        id="Group_340"
                                        data-name="Group 340"
                                        transform="translate(0 0)"
                                    >
                                        <rect
                                            id="Rectangle_332"
                                            data-name="Rectangle 332"
                                            width="25"
                                            height="24"
                                            transform="translate(0.381 -0.174)"
                                            fill="none"
                                        />
                                        <g id="Group_339" data-name="Group 339">
                                            <rect
                                                id="Rectangle_331"
                                                data-name="Rectangle 331"
                                                width="25"
                                                height="24"
                                                transform="translate(0.381 -0.174)"
                                                fill="none"
                                            />
                                            <g
                                                id="Group_337"
                                                data-name="Group 337"
                                                transform="translate(0.396 11.784)"
                                            >
                                                <path
                                                    id="Line_24"
                                                    data-name="Line 24"
                                                    d="M22.828-.066H-.533A.467.467,0,0,1-1-.533.467.467,0,0,1-.533-1h23.36a.467.467,0,0,1,.467.467A.467.467,0,0,1,22.828-.066Z"
                                                    transform="translate(1 1)"
                                                    fill="#242323"
                                                />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <span className={styles.quontity}>{counter}</span>

                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            className={styles.counterButton}
                            onClick={() => changeCounter(true)}
                        >
                            <g
                                id="Group_342"
                                data-name="Group 342"
                                transform="translate(-0.252 -0.161)"
                            >
                                <circle
                                    id="Ellipse_8"
                                    data-name="Ellipse 8"
                                    cx="16"
                                    cy="16"
                                    r="16"
                                    transform="translate(0.252 0.161)"
                                    fill="#fff"
                                />
                                <g
                                    id="Group_341"
                                    data-name="Group 341"
                                    transform="translate(2.168 4.335)"
                                >
                                    <rect
                                        id="Rectangle_333"
                                        data-name="Rectangle 333"
                                        width="26"
                                        height="24"
                                        transform="translate(0.084 -0.174)"
                                        fill="none"
                                    />
                                    <g
                                        id="Group_340"
                                        data-name="Group 340"
                                        transform="translate(0 0)"
                                    >
                                        <rect
                                            id="Rectangle_332"
                                            data-name="Rectangle 332"
                                            width="26"
                                            height="24"
                                            transform="translate(0.084 -0.174)"
                                            fill="none"
                                        />
                                        <g id="Group_339" data-name="Group 339">
                                            <rect
                                                id="Rectangle_331"
                                                data-name="Rectangle 331"
                                                width="26"
                                                height="24"
                                                transform="translate(0.084 -0.174)"
                                                fill="none"
                                            />
                                            <g
                                                id="Group_337"
                                                data-name="Group 337"
                                                transform="translate(1.608 0.104)"
                                            >
                                                <path
                                                    id="Line_24"
                                                    data-name="Line 24"
                                                    d="M22.828-.066H-.533A.467.467,0,0,1-1-.533.467.467,0,0,1-.533-1h23.36a.467.467,0,0,1,.467.467A.467.467,0,0,1,22.828-.066Z"
                                                    transform="translate(1 12.68)"
                                                    fill="#242323"
                                                />
                                                <path
                                                    id="Line_25"
                                                    data-name="Line 25"
                                                    d="M23.828.934H.467A.467.467,0,0,1,.467,0h23.36a.467.467,0,0,1,0,.934Z"
                                                    transform="translate(12.615 0) rotate(90)"
                                                    fill="#242323"
                                                />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                </td> }
            </tr>}
        </>
    );
}

export default TableRow;
