import React, { useEffect, useRef, useState } from "react";
import styles from "./SelectForm.module.scss";
import arrow from "../../../assets/images/for-pages/caret-down.png";
import arrowUp from "../../../assets/images/for-pages/caret-up.png";

import { useController } from "react-hook-form";

export default function Select(props) {

  const { field/* , fieldState */ } = useController(props);

  const {
    invalid,
    fontSize,
    justifyContent,
    backgroundColor,
    borderRadius,
    color,
    fontFamily,
    paddingInlineStart,
    paddingInlineEnd,
  } = props;

  


  const main = {
    outline: (invalid ? ".06rem solid red" : ''),
    borderRadius,
    backgroundColor: (invalid ? "#f3e8e8" : backgroundColor),
  };

  const dropDownContainer = {
    // width, height,
  };

  const dropDownHeader = {
    paddingInlineStart,
    paddingInlineEnd
  };

  const headerOption = {
    color: props?.selectedOption?.name ? "#242323" : '#9B9999',

    //
    justifyContent,
    width: "100%",
  };

  const dropDownList = {
    // backgroundColor,
    // width,
  };

  const dropDownListOption = {
    justifyContent,
    fontSize,
    color,
    "&:hover": {
      fontFamily,
    },
  };

  const rootEl = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = (value) => () => {
    if(value.id === props?.selectedOption?.id){
      setIsOpen(false);
      props.onChangeOption({id: 0, name: ''});
      field.onChange({id: 0, name: ''})
    }else {
      props.onChangeOption(value);
      setIsOpen(false);

      field.onChange(value)
    }
    
  };

  useEffect(() => {
    const onClick1 = (e) =>
      rootEl.current.contains(e.target) || setIsOpen(false);
    document.addEventListener("click", onClick1);
    return () => document.removeEventListener("click", onClick1);
  }, []);

  return (
    <div ref={rootEl} className={styles.main} style={main}>
      <div style={dropDownContainer} className={styles.dropDownContainer}>
        <div className={styles.dropDownHeader} onClick={toggling} style={dropDownHeader}>
          <span style={headerOption} className={styles.headerOption}>
            {props.selectedOption?.name ||
              props.placeholder ||
              "undefined"}
          </span>
          <img
            alt="arrow"
            src={isOpen ? arrowUp : arrow}
            className={styles.arrow}
          />
        </div>
        {isOpen && (
          <div className={styles.dropDownListContainer}>
            <div style={dropDownList} className={styles.dropDownList}>
              {props?.options?.map((option) => (
                <div
                  style={dropDownListOption}
                  className={styles.listItem}
                  onClick={onOptionClicked(option)}
                  key={Math.random()}
                >
                  <span
                    style={{
                      fontFamily:
                        option?.id === props.selectedOption?.id
                          ? fontFamily || "Heebo-Bold"
                          : "",
                    }}
                  >
                    {option.name}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
