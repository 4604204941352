import {useGTMDispatch} from "@elgorditosalsero/react-gtm-hook";

const types = {
  VIDEO: "video_call_req",
  CONTACT_FORM: "contact_form_submissions",
  SHOW_PHONE: "user_click_on_show_phone_number",
  CHAT_WITH_PROVIDE: "user_click_on_chat_with_provide",
  USER_SIGNUP: "user_signup",
  PRIVIDER_SIGNUP: "provider_signup",
  NEW_CONTRACT_SIGNUP: "new_contract_signup",
  PURCHASE: "purchase",
  VENUE_FORM: "VanueFormSubmission",
};


export function useAnalyticsEvents() {
  const sendDataToGTM = useGTMDispatch();
  // customer_name, custome_phone, provider_name +
  const Video = ({userName, supplierName, userPhone}) => {
    return sendDataToGTM({
      event: types.VIDEO,
      customer_name: userName, 
      customer_phone: userPhone, 
      provider_name: supplierName
    });
  };

  const ContactForm = ({email, name, phoneNumber, question}) => {

    return sendDataToGTM({
      event: types.CONTACT_FORM,
      email: email,
      customer_name: name,
      customer_phone: phoneNumber,
      question: question
    });
  };

  const ContactFormHome = ({name, number}) => {

    return sendDataToGTM({
      event: types.CONTACT_FORM,
      // email: "",
      customer_name: name,
      customer_phone: number,
      // question: question
    });
  };
  const VenueForm = ({hasVenue, phone, eventType, venuePlace, guestsNum, region, date}) => {

    return sendDataToGTM({
      event: types.VENUE_FORM,
      phone: phone,
      has_vanue: hasVenue,
      Event_type: eventType,
      vanue_place: venuePlace,
      Vanue_guests_num: guestsNum,
      Vanue_Region: region,
      Vanue_Date: date,
    });
  };

  // customer_name, customer_phone, provider_name, url+

  const ShowPhone = ({userName, supplierName, userPhone, url}) => {
    return sendDataToGTM({
      event: types.SHOW_PHONE,
      customer_name: userName, 
      customer_phone: userPhone, 
      provider_name: supplierName,
      url: window.location.href
    });
  };

  // customer_name, customer_phone, provider_name, url+

  const ChatWithProvider = ({userName, supplierName, userPhone, url}) => {

    return sendDataToGTM({
      event: types.CHAT_WITH_PROVIDE,
      customer_name: userName, 
      customer_phone: userPhone, 
      provider_name: supplierName,
      url: window.location.href
    });
  };
  // customer_name, customer_phone, url

  const UserSignUp = ({userName, userPhone, url}) => {

    return sendDataToGTM({
      event: types.USER_SIGNUP,
      customer_name: userName, 
      customer_phone: userPhone, 
      url: window.location.href
    });
  };
  // provider_name, provider_email, provider_phone

  const ProviderSignUp = ({supplierName, supplierEmail, supplierPhone}) => {

    return sendDataToGTM({
      event: types.PRIVIDER_SIGNUP,
      provider_name: supplierName, 
      provider_email: supplierEmail, 
      provider_phone: supplierPhone
    });
  };

  // customer_name, customer_phone, provider_name, total_price, discount, url

  const NewContractSignUp = ({userName, supplierName, userPhone, url, total, discount}) => {

    return sendDataToGTM({
      event: types.NEW_CONTRACT_SIGNUP,
      customer_name: userName, 
      customer_phone: userPhone,
      url: window.location.href, 
      provider_name: supplierName,
      total_price: total,
      discount: discount
    });
  };

  // customer_name, custome_phone, provider_name, total_price, discount, url

  const Purchase = ({userName, supplierName, url, userPhone, total, discount}) => {

    return sendDataToGTM({
      event: types.PURCHASE,
      customer_name: userName, 
      customer_phone: userPhone, 
      provider_name: supplierName,
      url: window.location.href,
      total_price: total,
      discount: discount,
    });
  };

  return {Video, ContactForm, ContactFormHome, VenueForm, ShowPhone, ChatWithProvider, UserSignUp, ProviderSignUp, NewContractSignUp, Purchase};
}
