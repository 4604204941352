import axios from '../../api/axios';
import useAuth from "./useAuth";
// import axios from '../api/axios';
const useRefreshToken = () => {
    const { auth, setAuth } = useAuth();

    const refresh = async () => {
        try{
            const response = await axios.post(`/auth/token?refreshToken=${auth?.refreshToken}`, {
            // refreshToken: 
           // withCredentials: true
            });
            setAuth(prev => {
                return { ...prev, accessToken: response.data.accessToken }
            });
            return response.data.accessToken;
        }catch(e){
            return("error")
        }
        
    }
    return refresh;
};

export default useRefreshToken;
