import styles from './Header.module.scss';
import logo from '../../../assets/images/logo.png';
import { NavLink } from 'react-router-dom';
//import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
//import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import { savePath } from '../../store/returnToUrlSlice';
import {
  setIsOpenUserAuth,
  setIsOpenSupplierAuth,
  setCurrentEventForChecklist,
} from '../../store/mainSlice';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { useEffect } from 'react';
import { setCurrentEventChecklist } from '../../store/checkListSlice';
//import notification from "../../../assets/images/notification.svg"
const activeClass = ({ isActive }) => (isActive ? 'chosenLink' : '');

function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { pathname } = useLocation();
  const { auth, logOut } = useAuth();
  let currentRole = auth?.roles ? auth.roles[0] : '';
  //const axiosPrivate = useAxiosPrivate();
  //const [newNotification, setnewNotification] = useState(false);

  const goToUserLogin = () => {
    dispatch(savePath(pathname));
    dispatch(setIsOpenUserAuth(true));
  };

  const getUrlPresonalArea = () => {
    if (currentRole === 'USER') {
      return `/personal/checklist`;
    } else if (currentRole === 'SUPPLIER' || currentRole === 'LABEL') {
      return `/personal-supplier/chats`;
    } else {
      return `/personal-guest/checklist`;
    }
  };
  const goToPersonalArea = () => {
    const url = getUrlPresonalArea();
    navigate(url);
  };

  const { currentEvent } = useSelector((state) => state.checklist);
  const clear = () => {
    dispatch(setCurrentEventChecklist({ currentEvent: { checkListId: 0, eventTypeName: '' } }));
    dispatch(setCurrentEventForChecklist({ currentEventForChecklist: { id: 0, name: '' } }));
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEvent]);

  // useEffect(() => {
  //     let notificationId1;
  //     if(auth?.user){
  //     const result = axiosPrivate.get(`${process.env.REACT_APP_API_PATH}chats/check/messages`);
  //       result.then(res=>{
  //         setnewNotification(res.data);
  //       })
  //     notificationId1 = setInterval(() => {
  //     const result = axiosPrivate.get(`${process.env.REACT_APP_API_PATH}chats/check/messages`);
  //       result.then(res=>{
  //         setnewNotification(res.data);
  //       })
  //     }, 60000);
  //     }
  //     return ()=>{clearInterval(notificationId1)}
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <>
      {/* <div className={styles.headerContainer}>

  </div> */}

      <header className={styles.headerContainer1}>
        {auth?.user ? (
          <ul
            style={{
              gap: '1.625rem',
            }}
            className={styles.headerListLogin}
          >
            <li
              style={{
                overflow: 'hidden',
                // width: "100%",
                // width:"15.125rem",
                width: 'fit-content',
                fontFamily: 'Heebo-Bold',
                marginLeft: 0,
              }}
            >
              {'שלום, '}
              {JSON.parse(auth?.user)?.name}
            </li>
            <li
              style={{
                maxWidth: 'fit-content',
                cursor: 'pointer',
              }}
              className={styles.linkToCustomerEnry1}
              onClick={() => {
                clear();
                logOut();
                navigate('/');
              }}
            >
              התנתק
            </li>
            {/*setnewNotification(false);*/}
          </ul>
        ) : (
          <ul className={styles.headerListLogin}>
            <li className={styles.linkToCustomerEnry1} onClick={goToUserLogin}>
              <div>כניסת חוגג</div>
            </li>
            <li className={styles.linkToCustomerEnry2}>
              <div onClick={() => dispatch(setIsOpenSupplierAuth(true))}>כניסת ספק</div>
            </li>
          </ul>
        )}

        <ul className={styles.headerListMenu}>
          <li>
            <NavLink to="/suppliers" className={activeClass}>
              הספקים שלנו
            </NavLink>
          </li>
          <li>
            <NavLink to="/blog" className={activeClass}>
              הבלוג שלנו
            </NavLink>
          </li>
          <li>
            <NavLink to="/contacts" className={activeClass}>
              צרו קשר
            </NavLink>
          </li>
          <li>
            <NavLink className={activeClass} to="/about-us">
              מי אנחנו
            </NavLink>
          </li>
          <li
            onClick={() => {
              goToPersonalArea();
            }}
          >
            <NavLink className={activeClass} to={getUrlPresonalArea()}>
              אזור אישי
            </NavLink>
          </li>
        </ul>
        <div className={styles.logoContainer}>
          <img
            onClick={() => {
              navigate('/');
            }}
            src={logo}
            alt="logo"
            className={styles.logo}
          />
        </div>
      </header>
    </>
  );
}

export default Header;
