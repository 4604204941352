import React from 'react';
import Feedback from '../feedBack/Feedback';
import InfoForSuppliers from '../infoForSuppliers/InfoForSuppliers';
import { Box } from '@mui/material';
import arrow from '../../../../assets/images/homePage/infoSuppliers/arrow2.webp';
import styles from './style.module.scss';

const InfoSuppliersWithFeedback = () => {
  return (
    <Box id="info-suppliers-with-feedback" className={styles.container}>
      <Box className={styles.feedback_container}>
        <Feedback />
        <img className={styles.arrow} src={arrow} alt="arrow"></img>
      </Box>
      <InfoForSuppliers />
    </Box>
  );
};

export default InfoSuppliersWithFeedback;
