import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Drawer,
  Typography,
  Box,
  Divider,
  Button,
} from '@mui/material';
import expandIcon from '../../../../../assets/images/arrowDown.png';
import { useState } from 'react';
import CustomCalendar from '../../../../components/customCalendar/CustomCalendar';
import CustomTextField from '../../../../components/customTextField/CustomTextField';
import styles from '../../styleSuppliers.module.scss';
import CustomCheckBoxGroup from '../../../../components/customCheckbox/CustomCheckBoxGroup';
import classNames from 'classnames';
import { useFiltersLogic } from './useFiltersLogic';
import { useFormContext } from 'react-hook-form';
import { IS_NUMBER_REGEX } from '../../../../shared/regex';
// const rowFilters = [
//   {id:0,
//     isVenuesFilter:true,
//     title:'',
//     content:'',

//   }
// ]
const MobileFilters = (props) => {
  const { open, onClose, openError } = props;
  const accordionSx = {
    '&.MuiAccordion-root': {
      margin: '0px',
      boxShadow: 'none',
    },
    '&.MuiAccordion-root.Mui-expanded:before': {
      opacity: 1,
    },
  };
  const summarySx = {
    '&.MuiAccordionSummary-root': {
      minHeight: 'unset',
      paddingRight: '19px',
    },
    '.MuiAccordionSummary-content': {
      margin: '13px 0px 9.5px',
    },
  };
  const detailsSx = {
    '&.MuiAccordionDetails-root': {
      padding: '1px 19px 16px 16px',
    },
  };
  const [expanded, setExpanded] = useState('first');
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(panel);
  };
  const { formGet, onSubmit, isDisabled, currentCategoryId, isSubmit, onEdit, isVenues } =
    useFiltersLogic({ onSuccess: onClose });
  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <div
        className={classNames(styles.filters_sidebar_container, styles.mobile_filters_container)}
      >
        <Typography className={styles.filters_sidebar_title}>מיון ספקים</Typography>
        <Divider></Divider>
        <Accordion
          expanded={expanded === 'first'}
          onChange={handleChange('first')}
          sx={accordionSx}
        >
          <AccordionSummary
            sx={summarySx}
            aria-controls="first-content"
            id="first-header"
            expandIcon={<img src={expandIcon} alt="expandIcon" />}
          >
            <Box>
              <Typography sx={{ fontSize: '16px', lineHeight: '24px' }}>
                בחר את תאריך האירוע
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={detailsSx}>
            {isVenues ? (
              <CustomCalendar range={true} name={'dateRange'} />
            ) : (
              <CustomCalendar name={'date'} />
            )}
            <Button
              onClick={handleChange('second')}
              sx={{ maxWidth: '70px', height: '35px' }}
              variant="contained"
            >
              בחר
            </Button>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'second'}
          onChange={handleChange('second')}
          sx={accordionSx}
        >
          <AccordionSummary
            sx={summarySx}
            aria-controls="second-content"
            id="second-header"
            expandIcon={<img src={expandIcon} alt="expandIcon" />}
          >
            <Typography sx={{ fontSize: '16px', lineHeight: '24px' }}>הזן כמות אורחים</Typography>
          </AccordionSummary>
          <AccordionDetails sx={detailsSx}>
            <Box sx={{ display: 'flex' }}>
              <div className={styles.filters_one_input}>
                <CustomTextField
                  rules={{
                    min: { value: formGet.guestsRange.min || 1, message: 'Min value 1' },
                    // max: { value: formGet.guestsRange.max || 1200, message: 'Max value 1200' },
                    pattern: { value: IS_NUMBER_REGEX, message: 'ערך לא חוקי' },
                  }}
                  typeInput="number"
                  name="amountGuests"
                  placeholder="הזן כמות אורחים"
                />
              </div>
              <Button
                onClick={handleChange("second2")}
                sx={{ maxWidth: '70px', height: '35px' }}
                variant="contained"
              >
                בחר
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'second2'}
          onChange={handleChange('second2')}
          sx={accordionSx}
        >
          <AccordionSummary
            sx={summarySx}
            aria-controls="second2-content"
            id="second2-header"
            expandIcon={<img src={expandIcon} alt="expandIcon" />}
          >
            <Typography sx={{ fontSize: '16px', lineHeight: '24px' }}>{"חיפוש ספק"}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={detailsSx}>
            <Box sx={{ display: 'flex' }}>
              <div className={styles.filters_one_input}>
                <CustomTextField
                  typeInput="string"
                  name="nameSearch"
                  placeholder="חיפוש ספק"
                />
              </div>
              <Button
                onClick={handleChange(isVenues ? 'third' : 'fourth')}
                sx={{ maxWidth: '70px', height: '35px' }}
                variant="contained"
              >
                בחר
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
        {isVenues && (
          <Accordion
            expanded={expanded === 'third'}
            onChange={handleChange('third')}
            sx={accordionSx}
          >
            <AccordionSummary
              sx={summarySx}
              aria-controls="third-content"
              id="third-header"
              expandIcon={<img src={expandIcon} alt="expandIcon" />}
            >
              <Typography sx={{ fontSize: '16px', lineHeight: '24px' }}>
                טווח מחירים למנה
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={detailsSx}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CustomCheckBoxGroup
                  fieldName="priceRangesId"
                  multiple={true}
                  // rules={{ required: { value: true, message: 'This field is required!' } }}
                  options={formGet.priceRanges}
                  labelStyle={{ fontSize: '16px', lineHeight: '24px' }}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        )}
        {isVenues && (
          <Accordion
            expanded={expanded === 'third1'}
            onChange={handleChange('third1')}
            sx={accordionSx}
          >
            <AccordionSummary
              sx={summarySx}
              aria-controls="third1-content"
              id="third1-header"
              expandIcon={<img src={expandIcon} alt="expandIcon" />}
            >
              <Typography sx={{ fontSize: '16px', lineHeight: '24px' }}>
                {`כשרות ${"\\"} סוג תפריט`}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={detailsSx}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CustomCheckBoxGroup
                  fieldName="koshersId"
                  multiple={true}
                  // rules={{ required: { value: true, message: 'This field is required!' } }}
                  options={formGet.koshers}
                  labelStyle={{ fontSize: '16px', lineHeight: '24px' }}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        )}
        {isVenues && (
          <Accordion
            expanded={expanded === 'third2'}
            onChange={handleChange('third2')}
            sx={accordionSx}
          >
            <AccordionSummary
              sx={summarySx}
              aria-controls="third2-content"
              id="third2-header"
              expandIcon={<img src={expandIcon} alt="expandIcon" />}
            >
              <Typography sx={{ fontSize: '16px', lineHeight: '24px' }}>
                {"אפשרויות חניה"}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={detailsSx}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CustomCheckBoxGroup
                  fieldName="parkingOptionsId"
                  multiple={false}
                  // rules={{ required: { value: true, message: 'This field is required!' } }}
                  options={formGet.parkingOptions}
                  labelStyle={{ fontSize: '16px', lineHeight: '24px' }}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        )}
        {isVenues && (
          <Accordion
            expanded={expanded === 'third3'}
            onChange={handleChange('third3')}
            sx={accordionSx}
          >
            <AccordionSummary
              sx={summarySx}
              aria-controls="third3-content"
              id="third3-header"
              expandIcon={<img src={expandIcon} alt="expandIcon" />}
            >
              <Typography sx={{ fontSize: '16px', lineHeight: '24px' }}>
                {"סוג מתחם"}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={detailsSx}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CustomCheckBoxGroup
                  fieldName="venueTypesId"
                  multiple={true}
                  // rules={{ required: { value: true, message: 'This field is required!' } }}
                  options={formGet.venueTypes}
                  labelStyle={{ fontSize: '16px', lineHeight: '24px' }}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        )}

        <Accordion
          expanded={expanded === 'fourth'}
          onChange={handleChange('fourth')}
          sx={accordionSx}
        >
          <AccordionSummary
            sx={summarySx}
            aria-controls="fourth-content"
            id="fourth-header"
            expandIcon={<img src={expandIcon} alt="expandIcon" />}
          >
            <Typography sx={{ fontSize: '16px', lineHeight: '24px' }}>איזור בארץ</Typography>
          </AccordionSummary>
          <AccordionDetails sx={detailsSx}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <CustomCheckBoxGroup
                fieldName="geoLocationsId"
                variant="circle_select"
                multiple
                // rules={{ required: { value: true, message: 'This field is required!' } }}
                options={formGet.geoLocations}
                labelStyle={{ fontSize: '16px', lineHeight: '24px' }}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'fifth'}
          onChange={handleChange('fifth')}
          sx={accordionSx}
        >
          <AccordionSummary
            sx={summarySx}
            aria-controls="fifth-content"
            id="fifth-header"
            expandIcon={<img src={expandIcon} alt="expandIcon" />}
          >
            <Typography sx={{ fontSize: '16px', lineHeight: '24px' }}>אירועים בסופ״ש</Typography>
          </AccordionSummary>
          <AccordionDetails sx={detailsSx}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <CustomCheckBoxGroup
                fieldName="weekendEventsId"
                variant="circle_select"
                // rules={{ required: { value: true, message: 'This field is required!' } }}
                options={formGet.weekendEvents}
                labelStyle={{ fontSize: '16px', lineHeight: '24px' }}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Divider></Divider>
        <Button
          onClick={(e) => {onSubmit(e); openError()}}
          // disabled={selectItems.length === 0}
          variant="contained"
          sx={{
            margin: '38px 0px',
            backgroundColor: '#E7B1AD',
            maxWidth: '83px !important',
            minWidth: 'unset',
            width: '100%',
            height: '45px',
            fontSize: '14px',
            lineHeight: '21px',
            borderRadius: '8px',
            marginRight: '19px',
          }}
        >
          המשך
        </Button>
      </div>
    </Drawer>
  );
};
export default MobileFilters;
