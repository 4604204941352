import {Box, createTheme, LinearProgress, ThemeProvider} from "@mui/material";
import classNames from "classnames";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import trash from "../img/trash.png";
import styles from "./supplierImages.module.scss";
import ImagesGrid from "./imagesGrid/ImagesGrid";
import {
  getAllImages,
  getYouImages,
  saveAllImages,
  sendImages,
  sendYouImages,
} from "../../../../store/personalSupplier/changeSupplierSlice";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import useAuth from "../../../../hooks/useAuth";
import {setErr} from "../../../../store/forAll/errSlice";
import {useNavigate} from "react-router-dom";
import { _MAXIMUI_UPLOAD_IMG } from "../../../../consts/local_keys";
// import { setSupplierImages } from "../../../../store/supplierRegistrationSlice";


const theme = createTheme({
  palette: {
    secondary: {
      main: '#E7B1AD'
    }
  }
});
function SupplierImages() {
  // const {supplierId} = useParams();
  let _;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [correctImageType] = useState([
    "jpeg",
    "png",
    "jpg",
    "mp4",
    "mkv",
    "webp",
  ]);
  const {auth} = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const container = useRef();
  // const {supplierImages} = useSelector((state) => state.supplierRegistration);
  let {SupplierForLabel} = useSelector((state) => state.main);
  const [isChangeOrderPage, setIsChangeOrderPage] = useState(false);
  const [supplierImages, setSupplierImages] = useState([]);
  const [allYouImages, setAllYouImages] = useState([]);
  const [loader, setLoader] = useState(false);
  let currentRole = auth.roles[0];
  let currentSupId = JSON.parse(auth.user).id;
  const [editPermission, seteditPermission] = useState(
    currentRole !== "LABEL" ? false : SupplierForLabel.isOptionProfile
  );
  // console.log(SupplierForLabel);
  const generateGUID = () => {
    function S4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return S4() + S4();
  };

  const getCell = (id, order, url, file, type) => {
    return {
      file: file ? file : "",
      type: type ? type : "",
      id: id ? id : generateGUID(),
      url: url ? url : "",
      order: order,
      defaultImage: id ? true : false,
    };
  };

  const setSupplierImages1 = (imgs) => {
    // dispatch(setSupplierImages(imgs))
    setSupplierImages(imgs);
  };

  // setSupplierImages1(
  //   [{}, {}, {}, {}].map((item, index) => {
  //     if(index === 0 ){
  //       return getCell('1', index)
  //     }
  //     else{
  //       return getCell(_, index)
  //     }
  //   })
  // )

  useEffect(() => {
    if (currentRole !== "LABEL") {
      checkPermission();
    } else {
      dispatch(getAllImages({axiosPrivate, id: SupplierForLabel.id})).then(
        (res) => {
          if (res?.error) {
            dispatch(
              setErr({
                message: res?.payload?.response?.data?.message,
                code: res?.payload?.response?.data?.code,
              })
            );
          } else {
            const images = [
              ...res?.payload.map((i) => {
                return {
                  ...i,
                  id: i.imageId,
                };
              }),
            ];
            if (!images?.length) {
              setSupplierImages1(
                [{}, {}, {}, {}].map((item, index) => {
                  if (index === 0) {
                    return getCell("1", index);
                  } else {
                    return getCell(_, index);
                  }
                })
              );
            } else {
              setSupplierImages1(images);
            }
            //update info
          }
        }
      );

      dispatch(getYouImages({axiosPrivate, id: SupplierForLabel.id})).then(
        (res) => {
          if (res?.error) {
            dispatch(
              setErr({
                message: res?.payload?.response?.data?.message,
                code: res?.payload?.response?.data?.code,
              })
            );
          } else {
            let imgs = res.payload;
            let newImgs = imgs.map((img, index) => {
              return {
                url: img.videoUrl,
                id: index + 1,
                isSaved: true,
                error: null,
              };
            });
            setAllYouImages(newImgs);
          }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // setSupplierImages1(

  // )

  // const [supplierImages, setSupplierImages1] = useState(

  //   [{}, {}, {}, {}].map((item, index) => {
  //     if(index === 0 ){
  //       return getCell('1', index)
  //     }
  //     else{
  //       return getCell(_, index)
  //     }

  //   }),
  // );

  const getRow = () => {
    return [{}, {}, {}, {}].map((item, index) => {
      return getCell();
    });
  };

  const addImage = (image1) => {
    let newImages = [...supplierImages].map((image) => {
      if (
        (image.id && image1.id && image.id === image1.id) ||
        (image.imageId && image1.imageId && image.imageId === image1.imageId)
      ) {
        return image1;
      } else {
        return image;
      }
    });

    // let newImages = [...supplierImages].map((row, index) => {
    //   if(indexCell === index){
    //     return newImagesRow
    //   } else {
    //     return row
    //   }
    // })
    setSupplierImages1(newImages);
  };

  const removeImage = (image1) => {
    let newImages = [...supplierImages].map((image, index) => {
      if (image?.imageId) {
        if (image.imageId === image1.imageId) {
          return getCell(_, image.order);
        } else {
          return image;
        }
      } else {
        if (image.id === image1.id) {
          return getCell(_, image.order);
        } else {
          return image;
        }
      }
    });

    setSupplierImages1(newImages);
  };

  const changeOrder = (images) => {
    setSupplierImages1(images);
  };

  const reorderBeforeSave = (images) => {
    let newImages = images?.map((image, index) => {
      let newImage = {...image};
      newImage.order = index;
      if (index === 0) {
        newImage.defaultImage = true;
      } else {
        newImage.defaultImage = false;
      }
      return newImage;
    });
    return newImages;
  };

  const addRow = () => {
    const actualState = [...supplierImages];
    actualState.push(...getRow());
    setSupplierImages1(reorderBeforeSave(actualState));
  };

  const getRowWithImg = (length = 0, imgs) => {
    // console.log(supplierImages)
    return imgs.map((item, index) => {
      return getCell(_, length + index, item.url, item.file, item.type);
    });
  };

  const uploadBanchImg = (imgs) => {
    const actualState = [...supplierImages];
    actualState.push(...getRowWithImg(actualState.length, imgs));
    // console.log(actualState)
    setSupplierImages1(actualState);
  };

  const imagesLength = useMemo(() => {
    return supplierImages?.filter((item) => {
      return item?.type || item?.fileType
    })?.length
  }, [supplierImages])

  useEffect(() => {
    console.log(supplierImages)
  },[supplierImages])

  const saveImages = () => {
    if(imagesLength > _MAXIMUI_UPLOAD_IMG){
      dispatch(setErr({
        message:" מספר מקסימלי של תמונות הוא " + _MAXIMUI_UPLOAD_IMG,
        // code: res?.payload?.response?.data?.code,
      }))
      return
    }
    setLoader(true)
    // [
    //   {
    //     "imageId": 0,
    //     "imageUrl": "string",
    //     "order": 0,
    //     "defaultImage": true
    //   }
    // ]
    // {
    //   "imageId": 0,
    //   "imageUrl": "string",
    //   "order": 0,
    //   "defaultImage": true
    // }

    // /supplier/upload/image post

    let oneByOne = supplierImages.filter((image) => !image.imageId);
    let allByOne = supplierImages.filter((image) => image.imageId);

    dispatch(
      saveAllImages(
        currentRole === "LABEL"
          ? {images: allByOne, axiosPrivate, id: SupplierForLabel.id}
          : {images: allByOne, axiosPrivate}
      )
    ).then((res) => {
      if (res?.error) {
        dispatch(
          setErr({
            message: res?.payload?.response?.data?.message,
            code: res?.payload?.response?.data?.code,
          })
        );
      } else {
        //update info
      }
    });
    dispatch(
      sendImages(
        currentRole === "LABEL"
          ? {
              supplierImages: oneByOne,
              axiosPrivate,
              supplierId: JSON.parse(auth.user).id,
              id: SupplierForLabel.id,
            }
          : {
              supplierImages: oneByOne,
              axiosPrivate,
              supplierId: JSON.parse(auth.user).id,
            }
      )
    ).then((res) => {
      console.log("after sending")
      if (res?.error) {
        dispatch(
          setErr({
            message: res?.payload?.response?.data?.message,
            code: res?.payload?.response?.data?.code,
          })
        );
      } else {
        //update info
      }
    }).finally(() => {
      setLoader(false);
      currentRole === "LABEL"
        ? navigate("/personal-supplier/change-label-business")
        : navigate("/personal-supplier/change-supplier-business")
    });
  };

  const saveYouImages = () => {
    dispatch(
      sendYouImages(
        currentRole === "LABEL"
          ? {axiosPrivate, allYouImages, id: SupplierForLabel.id}
          : {axiosPrivate, allYouImages}
      )
    ).then((res) => {
      if (res?.error) {
        dispatch(
          setErr({
            message: res?.payload?.response?.data?.message,
            code: res?.payload?.response?.data?.code,
          })
        );
      } else {
      }
    });
  };

  function getId(arr) {
    //last elem id + 1
    return arr[arr.length - 1]?.id + 1 || 1;
  }

  const addFields = () => {
    let actualState = [...allYouImages];
    actualState.push({
      id: getId(actualState),
      url: "",
      isSaved: false,
      error: null,
    });
    setAllYouImages(actualState);
  };

  const deleteYou = (id) => {
    let actualState = [...allYouImages];
    let newImages = actualState.filter((item) => {
      return item.id !== id;
    });
    setAllYouImages(newImages);
  };

  const changeUrl = (id, value) => {
    let actualState = [...allYouImages];
    let newImages = actualState.map((item) => {
      let newItem = {...item};
      if (item.id === id) {
        newItem.url = value;
      }
      return newItem;
    });
    setAllYouImages(newImages);
  };

  const saveYou = (id) => {
    let actualState = [...allYouImages];
    let newImages = actualState.map((item) => {
      let newItem = {...item};
      if (item.id === id && item.url) {
        newItem.isSaved = true;
      }
      return newItem;
    });
    setAllYouImages(newImages);
  };

  const checkPermission = async () => {
    let id = currentRole !== "LABEL" ? currentSupId : null;
    let request;
    if (currentRole !== "LABEL") {
      request = await axiosPrivate.get(
        `${process.env.REACT_APP_API_PATH}supplier/check/optional/label?id=${id}`
      );
    } else {
      request = await axiosPrivate.get(
        `${process.env.REACT_APP_API_PATH}supplier/check/optional/label`
      );
    }
    const result = request;
    if (result) {
      seteditPermission(
        currentRole === "LABEL"
          ? result.data.isOptionProfile
          : result.data.isOptionProfile
      );
      dispatch(getAllImages({axiosPrivate})).then((res) => {
        if (res?.error) {
          dispatch(
            setErr({
              message: res?.payload?.response?.data?.message,
              code: res?.payload?.response?.data?.code,
            })
          );
        } else {
          const images = [
            ...res?.payload.map((i) => {
              return {
                ...i,
                id: i.imageId,
              };
            }),
          ];
          if (!images?.length) {
            setSupplierImages1(
              [{}, {}, {}, {}].map((item, index) => {
                if (index === 0) {
                  return getCell("1", index);
                } else {
                  return getCell(_, index);
                }
              })
            );
          } else {
            setSupplierImages1(images);
          }
          //update info
        }
      });

      dispatch(getYouImages({axiosPrivate})).then((res) => {
        if (res?.error) {
          dispatch(
            setErr({
              message: res?.payload?.response?.data?.message,
              code: res?.payload?.response?.data?.code,
            })
          );
        } else {
          let imgs = res.payload;
          let newImgs = imgs.map((img, index) => {
            return {
              url: img.videoUrl,
              id: index + 1,
              isSaved: true,
              error: null,
            };
          });
          setAllYouImages(newImgs);
        }
      });
    }
  };

  return (
    <div className={styles.globalWrapper}>
      <div className={styles.mainImagesWrapper}>
        {isChangeOrderPage ? (
          <div className={styles.container}>
            <div className={styles.containerImg} style={{direction: "rtr"}}>
              <ImagesGrid images={supplierImages} changeOrder={changeOrder} />
            </div>

            <div
              className={styles.linkPrevPage}
              onClick={() => setIsChangeOrderPage(!isChangeOrderPage)}
            >
              <span>חזרה לגלריה</span>
            </div>
          </div>
        ) : (
          <div className={styles.container} ref={container}>
            {/* <p className={styles.addSomeImages}>הוסף מקבץ תמונות</p> */}
            <div className={styles.imagesWrapper}>
              {supplierImages.map((cell, indexCell) => (
                <div className={styles.imageRow}>
                  {/* // {row.map((cell, indexCell) => ( */}
                  <div
                    key={cell.ImageId || cell.id}
                    className={classNames(styles.imageField, {
                      [styles.profileImageField]: indexCell === 0,
                    })}
                  >
                    {cell?.imageUrl || cell?.url ? (
                      cell.fileType === "VIDEO" || cell.type === "video" ? (
                        //   <Box className={styles.downloadButton}
                        //   component='label'
                        // >
                        <video
                          style={{cursor: "pointer"}}
                          controls
                          autoPlay=""
                          alt=""
                          src={cell.imageUrl || cell.url}
                        />
                      ) : (
                        //   <Box className={styles.downloadButton}
                        //   component='label'
                        // >
                        <img alt="" src={cell.imageUrl || cell.url} />
                      )
                    ) : (
                      <Box
                        style={{
                          cursor: editPermission ? "pointer" : "default",
                          height: "100%",
                        }}
                        component="label"
                      >
                        <div style={{height: "100%"}}></div>

                        {editPermission && (
                          <input
                            className={styles.uploadImg}
                            id="button-file"
                            name="logoFile"
                            type="file"
                            accept="video/*,image/*"
                            onChange={(e) => {
                              function readFileAsync(index = 0, cell) {
                                return new Promise((resolve, reject) => {
                                  const file = e.target.files[index];
                                  if (!file) {
                                    return;
                                  }
                                  const reader = new FileReader();

                                  reader.onload = () => {
                                    // const { result } = e.target;
                                    const imageType = file.name.split(".")[1];
                                    if (
                                      Boolean(
                                        correctImageType.find(
                                          (el) => el === imageType
                                        ) === undefined
                                      )
                                    ) {
                                      dispatch(
                                        setErr({
                                          message: "Incorrect file type",
                                          code: 1461,
                                        })
                                      );
                                    } else if (file?.size < 15728640) {
                                      // const match = file.match(/^data:([^/]+)\/([^;]+);/) || [];
                                      // const type = match[1];
                                      // const format = match[2];
                                      let type;
                                      if (file.type.indexOf("image") !== -1) {
                                        type = "image";
                                      }
                                      if (file.type.indexOf("video") !== -1) {
                                        type = "video";
                                      }
                                      resolve({
                                        id: cell.id,
                                        url: `data:${file.type};base64,${btoa(
                                          reader.result
                                        )}`,
                                        file: e.target.files[index],
                                        order: cell.order,
                                        type: type,
                                        defaultImage:
                                          cell.id === "1" ? true : false,
                                      });
                                    } else {
                                      // snackActions.warning(t('SIZE_ERROR'));
                                    }
                                  };

                                  reader.onerror = reject;

                                  reader.readAsBinaryString(file);
                                });
                              }

                              readFileAsync(_, cell).then((item) => {
                                addImage(item);
                                return item;
                              });
                              // addImage(await newImg, indexCell)
                            }}
                            hidden
                          />
                        )}
                      </Box>
                    )}
                    {indexCell === 0 && <p>תמונת פרופיל</p>}
                    <div className={styles.interfaceContainer}>
                      <Box
                        className={styles.downloadButton}
                        style={{cursor: editPermission ? "pointer" : "default"}}
                        component="label"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <g
                            id="Icon_feather-upload"
                            data-name="Icon feather-upload"
                            transform="translate(-3.5 -3.5)"
                          >
                            <path
                              id="Frame_22"
                              data-name="Frame_22"
                              d="M26.5,22.5v6a2.765,2.765,0,0,1-2.444,3H6.944a2.765,2.765,0,0,1-2.444-3v-6"
                              transform="translate(0 -5)"
                              fill="none"
                              stroke="#242323"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            />
                            <path
                              id="Frame_23"
                              data-name="Frame_23"
                              d="M22.5,12l-6-7.5-6,7.5"
                              transform="translate(-1)"
                              fill="none"
                              stroke="#242323"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            />
                            <path
                              id="Frame_24"
                              data-name="Frame_24"
                              d="M18,4.5v18"
                              transform="translate(-2.5)"
                              fill="none"
                              stroke="#242323"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            />
                          </g>
                        </svg>

                        {editPermission && (
                          <input
                            className={styles.uploadImg}
                            id="button-file"
                            name="logoFile"
                            type="file"
                            accept="video/*,image/*"
                            onChange={(e) => {
                              function readFileAsync(index = 0, cell) {
                                return new Promise((resolve, reject) => {
                                  const file = e.target.files[index];
                                  if (!file) {
                                    return;
                                  }
                                  const reader = new FileReader();

                                  reader.onload = () => {
                                    // const { result } = e.target;
                                    const imageType = file.name.split(".")[1];
                                    if (
                                      Boolean(
                                        correctImageType.find(
                                          (el) => el === imageType
                                        ) === undefined
                                      )
                                    ) {
                                      dispatch(
                                        setErr({
                                          message: "Incorrect file type",
                                          code: 1461,
                                        })
                                      );
                                    } else if (file?.size < 15728640) {
                                      // const match = file.match(/^data:([^/]+)\/([^;]+);/) || [];
                                      // const type = match[1];
                                      // const format = match[2];
                                      let type;
                                      if (file.type.indexOf("image") !== -1) {
                                        type = "image";
                                      }
                                      if (file.type.indexOf("video") !== -1) {
                                        type = "video";
                                      }
                                      resolve({
                                        id: cell.id,
                                        url: `data:${file.type};base64,${btoa(
                                          reader.result
                                        )}`,
                                        file: e.target.files[index],
                                        order: cell.order,
                                        type: type,
                                        defaultImage:
                                          cell.id === "1" ? true : false,
                                      });
                                    } else {
                                      // snackActions.warning(t('SIZE_ERROR'));
                                    }
                                  };

                                  reader.onerror = reject;

                                  reader.readAsBinaryString(file);
                                });
                              }

                              readFileAsync(_, cell).then((item) => {
                                addImage(item);
                                return item;
                              });
                              // addImage(await newImg, indexCell)
                            }}
                            hidden
                          />
                        )}
                      </Box>
                      <button
                        onClick={() =>
                          editPermission ? removeImage(cell) : ""
                        }
                        className={styles.trashButton}
                        style={{cursor: editPermission ? "pointer" : "default"}}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="33.344"
                          height="32.55"
                          viewBox="0 0 33.344 32.55"
                        >
                          <g
                            id="Group_491"
                            data-name="Group491"
                            transform="translate(-425 -832.5)"
                          >
                            <rect
                              id="Rect_241"
                              data-name="Rect_241"
                              width="33.344"
                              height="32.55"
                              transform="translate(425 832.5)"
                              fill="none"
                            />
                            <g
                              id="trash"
                              transform="translate(426.588 833.294)"
                            >
                              <rect
                                id="Rect_240"
                                data-name="Rect_240"
                                width="30.169"
                                height="30.169"
                                transform="translate(0 0)"
                                fill="none"
                              />
                              <path
                                id="Line_18"
                                data-name="Line 18"
                                d="M14.318-6.059H-7.03A.97.97,0,0,1-8-7.03.97.97,0,0,1-7.03-8H14.318a.97.97,0,0,1,.97.97A.97.97,0,0,1,14.318-6.059Z"
                                transform="translate(11.471 13.351)"
                                fill="#3e3d3d"
                              />
                              <path
                                id="Line_19"
                                data-name="Line 19"
                                d="M-7.03,1.7A.97.97,0,0,1-8,.733V-7.03A.97.97,0,0,1-7.03-8a.97.97,0,0,1,.97.97V.733A.97.97,0,0,1-7.03,1.7Z"
                                transform="translate(19.233 19.173)"
                                fill="#3e3d3d"
                              />
                              <path
                                id="Line_20"
                                data-name="Line 20"
                                d="M-7.03,1.7A.97.97,0,0,1-8,.733V-7.03A.97.97,0,0,1-7.03-8a.97.97,0,0,1,.97.97V.733A.97.97,0,0,1-7.03,1.7Z"
                                transform="translate(25.055 19.173)"
                                fill="#3e3d3d"
                              />
                              <path
                                id="Path_4"
                                data-name="Path 4"
                                d="M65.466,69.348H49.941A1.943,1.943,0,0,1,48,67.407V48.97a.97.97,0,0,1,1.941,0V67.407H65.466V48.97a.97.97,0,0,1,1.941,0V67.407A1.943,1.943,0,0,1,65.466,69.348Z"
                                transform="translate(-42.589 -42.649)"
                                fill="#3e3d3d"
                              />
                              <path
                                id="Path_5"
                                data-name="Path 5"
                                d="M90.674,21.822a.97.97,0,0,1-.97-.97V18.911a.971.971,0,0,0-.97-.97H82.911a.971.971,0,0,0-.97.97v1.941a.97.97,0,0,1-1.941,0V18.911A2.914,2.914,0,0,1,82.911,16h5.822a2.914,2.914,0,0,1,2.911,2.911v1.941A.97.97,0,0,1,90.674,21.822Z"
                                transform="translate(-70.707 -14.531)"
                                fill="#3e3d3d"
                              />
                            </g>
                          </g>
                        </svg>
                      </button>
                    </div>
                  </div>
                  {/* // ))} */}
                </div>
              ))}
            </div>
            <div style={{fontSize: "1.5rem"}}>
              {`${_MAXIMUI_UPLOAD_IMG} /`} {imagesLength || 0}
            </div>
            <div className={styles.links}>
              {editPermission && (
                <Box
                  className={styles.addRowLink}
                  component="label"
                  style={{cursor: editPermission ? "pointer" : "default"}}
                >
                  {"העלאה של מספר תמונות"}
                  <input
                    className={styles.uploadImg}
                    id="button-file"
                    name="logoFile"
                    type="file"
                    multiple
                    accept="video/*,image/*"
                    onChange={async (e) => {
                      const length = e.target.files.length;
                      function readFileAsync(index = 0, cell) {
                        // console.log(JSON.stringify(cell1))
                        return new Promise((resolve, reject) => {
                          const file = e.target.files[index];
                          if (!file) {
                            return;
                          }
                          const reader = new FileReader();
                          reader.onload = () => {
                            // const { result } = e.target;
                            const imageType = file.name.split(".")[1];
                            if (
                              Boolean(
                                correctImageType.find(
                                  (el) => el === imageType
                                ) === undefined
                              )
                            ) {
                              dispatch(
                                setErr({
                                  message: "Incorrect file type",
                                  code: 1461,
                                })
                              );
                            } else if (file?.size < 15728640) {
                              // const match = file.match(/^data:([^/]+)\/([^;]+);/) || [];
                              // const type = match[1];
                              // const format = match[2];
                              let type;
                              if (file.type.indexOf("image") !== -1) {
                                type = "image";
                              }
                              if (file.type.indexOf("video") !== -1) {
                                type = "video";
                              }
                              resolve({
                                // id: cell.id,
                                url: `data:${file.type};base64,${btoa(
                                  reader.result
                                )}`,
                                file: e.target.files[index],
                                // order: cell.order,
                                type: type,
                                defaultImage: false,
                              });
                            } else {
                              // snackActions.warning(t('SIZE_ERROR'));
                            }
                          };

                          reader.onerror = reject;

                          reader.readAsBinaryString(file);
                        });
                      }

                      // readFileAsync(_, cell).then(item => {
                      //   // console.log(item)
                      //   uploadBanchImg(item)
                      //   return item
                      // });

                      let newImages = [...e.target.files].map(
                        async (fl, index) => {
                          if (index === length) return;
                          let newImg = await readFileAsync(index);
                          return newImg;
                        }
                      );

                      await Promise.all(newImages).then((images) => {
                        // console.log(value)
                        // console.log([...images, ...value])
                        let newImagesArr = [...images].map((image, index) => {
                          let newImage = {...image};
                          newImage.order = index;
                          return newImage;
                        });

                        uploadBanchImg([...newImagesArr]);
                      });
                      e.target.value = null;
                    }}
                    hidden
                  />
                </Box>
              )}
              <p
                style={{cursor: editPermission ? "pointer" : "default"}}
                className={styles.addRowLink}
                onClick={editPermission ? addRow : ""}
              >
                הוסף עוד שורת תמונות
              </p>
              <p
                className={styles.sortLink}
                onClick={() => setIsChangeOrderPage(!isChangeOrderPage)}
              >
                {"סידור ידני של הגלריה"}
              </p>
            </div>
            <div className={styles.youTube}>
              <div className={styles.youTitle}>{"הוסף סרטוני וידאו"}</div>
              <div className={styles.youSTitle}>
                {
                  "(על מנת להוסיף וידאו לגלריה, יש להעתיק את כתובת ה-url של הסרטון ולאחר מכן ללחוץ על - העלה וידאו)"
                }
              </div>
              <div className={styles.youAllLinks}>
                {allYouImages.map((item, index) => {
                  return (
                    <>
                      {item.isSaved ? (
                        <div
                          key={item.id + "youTube" + index}
                          className={styles.youRow}
                        >
                          <textarea
                            disabled={item.isSaved}
                            value={item.url}
                            onChange={(e) => {
                              changeUrl(item.id, e.target.value);
                            }}
                            placeholder="העתק כתובת URL לכאן"
                          />
                          <div className={styles.delete}>
                            <img
                              style={{
                                cursor: editPermission ? "pointer" : "default",
                              }}
                              onClick={() =>
                                editPermission ? deleteYou(item.id) : ""
                              }
                              alt=""
                              src={trash}
                            />
                          </div>
                        </div>
                      ) : (
                        <div
                          key={item.id + "youTube" + index}
                          className={styles.youRow}
                        >
                          <textarea
                            disabled={item.isSaved}
                            value={item.url}
                            onChange={(e) => {
                              changeUrl(item.id, e.target.value);
                            }}
                            placeholder="העתק כתובת URL לכאן"
                          />
                          <div
                            className={styles.save}
                            onClick={() => {
                              saveYou(item.id);
                            }}
                          >
                            {"העלה וידאו"}
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
              <div
                className={styles.youAdd}
                style={{cursor: editPermission ? "pointer" : "default"}}
              >
                {" + "}
                <span
                  onClick={() => (editPermission ? addFields() : "")}
                  style={{textDecoration: "underline"}}
                >
                  {"הוסף וידאו נוסף"}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={styles.mainBtnWrapper}>
        <div
          className={styles.button}
          style={{cursor: editPermission ? "pointer" : "default"}}
          onClick={() =>
            (editPermission && !loader)
              ? (saveImages(),
                saveYouImages())
              : ""
          }
        >
          {"שמירת שינויים"}
        </div>
      </div>
      {loader && 
      <ThemeProvider theme={theme}>

        <Box sx={{ width: '100%'}}>
        {"ממתין להטענת התמונות"}
        <LinearProgress color="secondary"/>
      </Box>
      </ThemeProvider>
      }
    </div>
  );
}

export default SupplierImages;
