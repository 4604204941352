import styles from "./addEvent.module.scss";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentEvent, setHavePlace, fetchAllEvetnsHomePage } from "../../store/mainSlice";
import { useLocation, useNavigate } from "react-router";
import useAuth from "../../hooks/useAuth";
import Select from "./selectComponent/Select";
import Dialog from "@mui/material/Dialog";
import classNames from "classnames";
import axios from "../../../api/axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import {TEMP_USER_KEY} from "../../consts/local_keys";
import { setCurrentEventForChecklist } from "../../store/mainSlice";
import CalendarOneDay from "../../components/calendarOneDay/CalendarOneDay";
import MetaFavicon from '../../components/metaComponents/metaFavicon'
import { saveNextPath, savePath } from "../../store/returnToUrlSlice";
import { setIsOpenUserAuth } from "../../store/mainSlice";
import { useAnalyticsEvents } from "../../hooks/useAnalyticsEvents";
import DateStep from "../home/basicInformationForm/form/stepForm.js/steps/dateStep/DateStep";
import { FormProvider, useForm } from "react-hook-form";
import { DEFAULT_FORM_VALUES, EVENT_TYPE_ENUM, EVENT_TYPE_NAME_ENUM } from "../home/basicInformationForm/form/stepForm.js/stepForm.config";
import TypeEvent from "../home/basicInformationForm/form/stepForm.js/steps/TypeEvent";

function AddEvent() {
    let { pathname } = useLocation();
    const {VenueForm} = useAnalyticsEvents();

    const defaultValues = DEFAULT_FORM_VALUES;
    const form = useForm({ defaultValues: defaultValues, mode: 'onChange' });
    const watchDateRange = form.watch('dateRange');
    const watchTypeEvent = form.watch('typeEvent');

    const resetErrConfig = {date:false, amount:false, venue: false, region: false,eventType:false}
    const defaultEventType = JSON.stringify({'id': 0, 'name': ''})    
    const axiosPrivate = useAxiosPrivate();
   // const [allPlace, setallPlace] = useState({data:[], loading: true});
    const [place, setplace] = useState('');
    const [amountGuest, setAmountGuest] = useState()
    const navigate = useNavigate();
    //mistake in name isHavePlace: should be named isNeedPlace
    let { allEvents , isHavePlace } = useSelector((state) => state.main)
    let { currentEvent} = useSelector((state) => state.main)
    const [currentRegion, setCurrentRegion] = useState({id: 0,name: ''})
    const dispatch = useDispatch();
    const [allRegions, setAllRegions] = useState()
    const [open, setOpen] = useState(false);
    const [save, setsave] = useState(false);
    const [error, seterror] = useState(resetErrConfig);
    const [ifFirstLoad, setIfFirstLoad] = useState(true);//for no showing error when page loaded
    let [filterDate, setFilterDate] = useState()

    const { auth } = useAuth();
    let currentRole = (auth?.roles?.length) ? auth?.roles[0] : '';
    
    useEffect(() => {
      let role = auth?.roles ? auth.roles[0] : '';
      dispatch(fetchAllEvetnsHomePage({role, axiosPrivate}))
      dispatch(setCurrentEvent({"currentEvent":{id:0, name:''}}))
      const fetch = async() =>{
        const result = await axios.get('/venue/details/locations')
        setAllRegions(result.data)
      }
      fetch()
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    
    const openDialog = () => {
      setOpen(true)
    }

    const redirectToChecklist = () => {
      navigate(`/personal/checklist`);
    }
  
    const redirectToChecklistAnonimous = () => {
      navigate(`/personal-guest/checklist`);
    }

    const redirectToRegUser = () => {
      dispatch(savePath(pathname));
      dispatch(saveNextPath(`/personal/checklist`));
      setOpen(false)
      dispatch(setIsOpenUserAuth(true))
    }

    const onChangeOption = (option) => {
      seterror(prev=> {
        return{
        ...prev,
        eventType: option === ''
        }
      });
      dispatch(setCurrentEvent({"currentEvent":option}))
     // getVenue(option);
    }
    const onChangeRegion = (reg)=>{
      setCurrentRegion(reg)
      seterror(prev=> {
        return{
        ...prev,
        region: reg.id === 0
        }
      });
    }

    const checkUser = async () => {
        setsave(true);
        redirectToRegUser();
    }
  
    const onChangeHavePlaceOption = ( e => {
      if(e.target.value === '1'){
        dispatch(setHavePlace(true))
      } else {
        dispatch(setHavePlace(false))
      }
    })

    const getFormattedDate = (spesificDatePickerDate) => {
      return spesificDatePickerDate
        ? new Date(spesificDatePickerDate).toISOString()
        // : new Date().toISOString();
        : null;
    };
  
    const sendFormToConnect = async() => {
      const currentDate = isHavePlace
        ? { eventDate: filterDate }
        : {
            dateFrom: getFormattedDate(watchDateRange?.start),
            dateTo: getFormattedDate(watchDateRange?.end),
          };
      if((currentEvent.name === '') || 
          ((!currentDate?.dateFrom || !currentDate?.dateTo )&& !isHavePlace) ||
          ((!currentDate?.eventDate || 
            currentRegion.name === '' || 
            place === '' || 
            ((amountGuest !== undefined && amountGuest !== '') && (amountGuest>100000 || amountGuest<1))
          ) && isHavePlace)
        ) {
        seterror({
          date: isHavePlace ? (
            !currentDate?.eventDate 

          ) : (!currentDate?.dateFrom || !currentDate?.dateTo),
          venue: place === '', 
          region: currentRegion.name === '',
          eventType: !currentEvent?.name,
          amount: (amountGuest>100000 || amountGuest < 1) && amountGuest !== ''
        });
        return

      } else {
        seterror(resetErrConfig);
        //mistake in name isHavePlace: should be named isNeedPlace
        const obj = {
          hasVenue: isHavePlace ? 'yes' : 'no',
          phone:auth && auth?.user ? JSON.parse(auth.user)?.phoneNumber : "",
          eventType: currentEvent.name ||'',
          venuePlace: isHavePlace ? (place ||'') : '',
          guestsNum: isHavePlace ? (amountGuest ||'') : '',
          region: isHavePlace ? (currentRegion.name ||'') : '',
          date: isHavePlace ? (currentDate?.eventDate ||'') : '',
        }
        VenueForm(obj)
        let data = {
          eventForm: EVENT_TYPE_NAME_ENUM[watchTypeEvent],
          eventTypeId: currentEvent.id,
          venue: isHavePlace,
          venueName: place,
          ...currentDate,
          locationId: currentRegion.id,
          amountOfGuest: amountGuest || 0
        }
        if(currentRole === 'USER'){ 
          setsave(true);
          const result = await axiosPrivate.post(`${process.env.REACT_APP_API_PATH}checklist`, data);
          if(result){
            dispatch(setCurrentEventForChecklist({currentEventForChecklist: {id: String(result.data), name:''}}));
            redirectToChecklist();
          } else{setsave(false)}
        } else {
          // let data = {
          //   eventTypeId: currentEvent.id,
          //   venue: isHavePlace,
          //   venueName: place,
          //   eventDate: filterDate || "",
          //   locationId: currentRegion.id,
          //   amountOfGuest: amountGuest || 0
          // }
          const result = await axios.post(`${process.env.REACT_APP_API_PATH}checklist/anonymous?userToken=${localStorage.getItem(TEMP_USER_KEY) || ''}`, data);
          if(result){ 
            dispatch(setCurrentEventForChecklist({currentEventForChecklist: {id: String(result.data), name:''}}));
            openDialog();
          } else{
            setsave(false)
          }
          seterror(false);
        }
      }
    }

    const onChangePlace = (event) => {
        setplace(event.target.value);
        seterror(prev=> {
          return{
          ...prev,
          venue: event.target.value === ''
          }
        });
    };
  
    const onChangeGuest = (event) =>{
      setAmountGuest(event.target.value)
      seterror(prev=> {
        return{
        ...prev,
        amount: (event.target.value > 100000 || event.target.value < 1) && event.target.value !== ''
        }
      });
    }

    // const getVenue = async (option) => {
    //   let eventId = option.id;
    //   const result = await axios.get(`${process.env.REACT_APP_API_PATH}venue/eventType${eventId}`);
    //   setallPlace({data:[],loading:true})
    //   setallPlace({data:result.data,loading:false})
    // };

    const typeEvent = form.watch('typeEvent')


    const currentEvents = useMemo(() => {
      if(EVENT_TYPE_ENUM.BUSINESS === typeEvent && allEvents?.find((event) => event.id === 3)){
        return [allEvents?.find((event) => event.id === 3)]
      }
      return allEvents.filter((item) => item.id !== 3)
    },[typeEvent, allEvents])

    useEffect(() => {
      if(typeEvent === EVENT_TYPE_ENUM.PRIVATE) {
        onChangeOption("")
      }
      if(typeEvent === EVENT_TYPE_ENUM.BUSINESS && currentEvents.length !== 0) {
        onChangeOption(currentEvents[0])
        // form.setValue('celebrating', currentEvents[0]?.id)
        
      }
    }
    ,[typeEvent,currentEvents])

    useEffect(()=>{
      if(ifFirstLoad){
        return; 
      }
      seterror(prev=> {
        return{
        ...prev,
        date: filterDate === '',
        }
      });
    },[filterDate])
    
    useEffect(()=>{
      if(ifFirstLoad){
        setIfFirstLoad(false); return; 
      }
      let currentDate =  {
        dateFrom: getFormattedDate(watchDateRange?.start),
        dateTo: getFormattedDate(watchDateRange?.end),
      }
      seterror(prev=> {
        return{
        ...prev,
        date: (!currentDate?.dateFrom || !currentDate?.dateTo),
        }
      });
    },[watchDateRange])
    
    return (
    <>
    <MetaFavicon />
    <div>
    <FormProvider {...form}>
      <div className={styles.first}>
          <div className={styles.firstForm}>
              <div className={!isHavePlace ? styles.dropdownsBlock : styles.dropdownsSmallBlock}>
                <TypeEvent isFromPersonal={true} />
                <div>
                  <div className={styles.title} >מה חוגגים?</div>
                  <div className={styles.select}>
                      <Select 
                      options={currentEvents} 
                      paddingInlineStart={"0.95rem"}
                      placeholder={"בחר מתוך הרשימה"}
                      onChangeOption={onChangeOption} 
                      selectedOption={currentEvent || defaultEventType}
                      />
                      {error.eventType && <div className={styles.errorMessageSuppliers}>מלא את כל השדות</div>}
                  </div>
                </div>
                {!isHavePlace && <div style={{minWidth: "272px"}} className={styles.place} >
                  <div style={{width: '100%'}}>
                    <DateStep isOnlyDatePicker={true} isExistsPlace={isHavePlace}/>
                    {error.date && <div className={styles.errorMessageSuppliers}>מלא את כל השדות</div>}
                  </div>    
                </div> }
                {isHavePlace &&  <div className={styles.place} >
                  <div>
                    <div className={styles.title} >מקום אירוע</div>
                    <div className={styles.datalistBlock}>
                      <input onChange={(event) => onChangePlace(event)}
                        value={place} placeholder={`אולם`} type="text" list="data" className={styles.datalist} />
                      {/* <datalist id="data" className={styles.list}>
                      { !allPlace.loading && allPlace.data.map((item, key) =>
                          <div key={key} className={styles.opt}><option value={item.name}></option></div>
                      )}
                      </datalist> */}
                    </div>
                    {error.venue && <div className={styles.errorMessageSuppliers}>מלא את כל השדות</div>}
                  </div>
                </div> }
                {isHavePlace && <div className={styles.place} >
                  <div>
                    <div className={styles.title} >כמות אורחים</div>
                    <div className={styles.datalistBlock}>
                      <input onChange={(event) => onChangeGuest(event)}
                        value={amountGuest} placeholder={`מספר אורחים`} type="number" className={styles.datalist}/>
                    </div>
                    {error.amount && <div className={styles.errorMessageSuppliers}>מספר לא חוקי</div>}
                  </div>
                </div>}
              </div>
              <div className={styles.secondBlock}>
                <div className={styles.radioButtonBlock}>
                    <div className={styles.title}>יש תאריך?</div>
                    <form className={styles.checked}>
                        <label className={styles.containerCheck}>
                        <input className={styles.input}
                            type="radio" 
                            value={'2'} 
                            name="radio" 
                            checked={! isHavePlace}
                            onChange={(e) => onChangeHavePlaceOption(e)} 
                        />
                        <span className={styles.checkmark}></span>
                        </label>
                        <div className={styles.marginL}>עדיין לא</div>
                        <label className={styles.containerCheck}>
                        <input  className={styles.input}
                            type="radio" 
                            value={'1'} 
                            name="radio" 
                            checked={isHavePlace}
                            onChange={(e) => {onChangeHavePlaceOption(e); setFilterDate()}} 
                        />
                        <span className={styles.checkmark}></span>
                        </label>
                        <div className={styles.margin}>{"כן"}{"!"}</div>
                    </form>
                  </div>
                  {isHavePlace &&  <div>
                <div className={styles.title} >איזור אירוע</div>
                <div className={styles.select}>
                    <Select 
                    options={allRegions} 
                    paddingInlineStart={"0.95rem"}
                    placeholder={"בחר מתוך הרשימה"}
                    onChangeOption={onChangeRegion} 
                    selectedOption={currentRegion}
                    />
                    {error.region && <div className={styles.errorMessageSuppliers}>מלא את כל השדות</div>}
                </div>
                </div>}
                {isHavePlace && 
                  <div className={styles.divWithCalendar}>
                    <div className={styles.calendar}>
                      <div className={styles.title} >תאריך</div>
                      <CalendarOneDay
                      top={"2.5rem"}
                      left={'-4.6875rem'}
                      setDisplayDate={setFilterDate}
                      displayDate={filterDate}
                      typeOfInput={"1"}
                      />
                    {error.date && <div className={styles.errorMessageSuppliers}>מלא את כל השדות</div>}
                  </div>
                  <div className={styles.mobileCalendar}>
                  <div className={styles.title} >תאריך</div>
                      <CalendarOneDay
                      width={"22.5rem"}
                      top={"2.5rem"}
                      left={'-4.6875rem'}
                      setDisplayDate={setFilterDate}
                      displayDate={filterDate}
                      typeOfInput={"1"}/>
                      {error.date && <div className={styles.errorMessageSuppliers}>מלא את כל השדות</div>}
                  </div>
                  <div className={styles.smallMobileCalendar}>
                  <div className={styles.title} >תאריך</div>
                      <CalendarOneDay
                      width={"17.5rem"}
                      top={"2.5rem"}
                      left={'-4.6875rem'}
                      setDisplayDate={setFilterDate}
                      displayDate={filterDate}
                      typeOfInput={"1"}/>
                      {error.date && <div className={styles.errorMessageSuppliers}>מלא את כל השדות</div>}
                  </div>        
                </div>}
              </div>
          </div>
          <div className={styles.paddingButton}>
            <div onClick={()=>{sendFormToConnect()}} className={styles.button}>
              הוספת אירוע 
            </div>
          </div>
      </div>
    </FormProvider>
    </div>
    
    <Dialog hideBackdrop={true} maxWidth={'lg'} open={open} sx={{
      "& .MuiDialog-container": {
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "1049px",
          boxShadow: 'none',
          overflow: 'hidden',
          borderRadius: '0'
        },
      },
    }} fullWidth={true} className={styles.infoDialog}>
      <div className={styles.dialog}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25.477"
        height="25.47"
        viewBox="0 0 25.477 25.47"
        onClick={() => {setOpen(false); redirectToChecklistAnonimous()}}
        className={classNames(
          styles.closeIcon,
          styles.closeIconDesktop
        )}>
        <path
          id="Icon_ionic-ios-close"
          data-name="Icon ionic-ios-close"
          d="M27.042,24.024l9.1-9.1a2.132,2.132,0,1,0-3.015-3.015l-9.1,9.1-9.1-9.1a2.132,2.132,0,1,0-3.015,3.015l9.1,9.1-9.1,9.1a2.132,2.132,0,0,0,3.015,3.015l9.1-9.1,9.1,9.1a2.132,2.132,0,0,0,3.015-3.015Z"
          transform="translate(-11.285 -11.289)"
          fill="#242323"
        />
      </svg>
      <div className={styles.dialogTitle}>?עדיין לא נרשמת לאיוונט-דו</div>
      <div className={styles.dialogDescription}> על מנת שבפעם הבאה תוכל להמשיך מאותה נקודה וכל המידע שאספת עד כה ישמר, הירשם עכשיו לאיוונט-דו ותכנן את האירוע שלך בקלות!</div>
      <div className={styles.dialogBtnBlock}>
        <div onClick={()=> !save ? checkUser() : ''} className={styles.dialogButton}>אני רוצה להירשם</div>
      </div>
      </div>
    </Dialog>
    </>
    );
}   
export default AddEvent;