import styles from "./Popup.module.scss";
import close from "../../../../assets/images/for-pages/icons/closeDialog.png";
import dayjs from "dayjs";
import he from 'dayjs/locale/he'; // load on demand
import localizedFormat from 'dayjs/plugin/localizedFormat'; 
import {useState} from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { setErr } from "../../../store/forAll/errSlice";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import { DAY_STATUS } from "../../../consts/dayStatus";

function Popup(props) {
  const dispatch = useDispatch();
  dayjs.locale(he);
  const axiosPrivate = useAxiosPrivate();
  dayjs.extend(localizedFormat)
  const {isOpen, setIsOpen, value, closePopup} = props;    
  const [place, setplace] = useState('');
  const [eventDate, seteventDate] = useState(null);
  const [, setOpenPopup] = useState(false)
  const [contractId, setContractId] = useState()
  
  const onChangePlace = (event) => {
  setplace(event.target.value);
};
const { currentEvent:currentChecklist } = useSelector((state) => state.checklist);
const [firstDialog, setfirstDialog] = useState(true);
const [secondDialog, setsecondDialog] = useState(false);
const [isPartialDayBlocked, setIsPartialDayBlocked] = useState(false);

const sendFirstForm = async() => {
  let data = {
    supplierId: Number(value.supplierId),
    eventTypeId: Number(value.eventTypeId),
    categoryId: Number(value.categoryId),
    additionalNotes: 
    place,
    checkListId:currentChecklist?.checkListId || 0
  }
  let contactForm = {
    eventTypeId: Number(value.eventTypeId),
    categoryId: Number(value.categoryId),
    userId: value.userId,
    discount: Number(value.discount),
    checkListId:currentChecklist?.checkListId || 0
  }
  try {
    const result = await axiosPrivate.post(`${process.env.REACT_APP_API_PATH}contract/approve/discount?supId=${value.supplierId}`, contactForm);
    if(result){
      setContractId(result.data.contractDto.id)
      if((result.data.contractDto.dateStatus === DAY_STATUS.AVAILABLE) || (result.data.contractDto.dateStatus === DAY_STATUS.PARTIALLY)){
        // sendContract(id)
        const res = await axiosPrivate.post(`/contract/approve?userId=${value.userId}&contractId=${result.data.contractDto.id}`,data);
        if (res?.error) {
          dispatch(
            setErr({
              message: res?.payload?.response?.data?.message,
              code: res?.payload?.response?.data?.code,
            })
          );
        } else {
          seteventDate(res.data.eventDate);
          if(res.data.eventDate){
            if(result.data.contractDto.dateStatus === DAY_STATUS.PARTIALLY){setIsPartialDayBlocked(true)}
            setfirstDialog(false);
            setsecondDialog(true);
          } else {
            closePopup();
            setIsOpen(false);
            setfirstDialog(true);
          }
        }
      } else{
        //error that day blocked
        closePopup();
        setIsOpen(false);
        setfirstDialog(true);//is neded??

        dispatch(
          setErr({
            message: "לא ניתן לשלוח חוזה מכיוון שיום זה מוגדר כתפוס",
          })
        );
      }
    }
  } catch (error) {
    dispatch(
      setErr({
        message: error?.response?.data?.message,
        code: 1927,
    }) )
  }
}

const disabledayoncalendar = async() => {
  try {
    const res = await axiosPrivate.post(`/calendar/google/contract/event?contractId=${contractId}&date=${eventDate}&supId=${value.supplierId}&blocked=true`);
    if (res?.error) {
      dispatch(
        setErr({
          message: res?.payload?.response?.data?.message,
          code: res?.payload?.response?.data?.code,
        })
      );
    } else {
        closePopup();
        setIsOpen(false);
        setfirstDialog(true);
    }
    
  } catch (error) {
    // console.log(error)
  }
}

const samyDisabledayoncalendar = async() => {
  try {
    const res = await axiosPrivate.post(`/calendar/google/contract/event?contractId=${contractId}&date=${eventDate}&supId=${value.supplierId}&blocked=false`);
    if (res?.error) {
      dispatch(
        setErr({
          message: res?.payload?.response?.data?.message,
          code: res?.payload?.response?.data?.code,
        })
      );
    } else {
        setIsOpen(!isOpen)
        closeForm(true);
    }
    
  } catch (error) {
    // console.log(error)
  }
}
const closeForm = () => {
  closePopup();
  setfirstDialog(true);
  setsecondDialog(false);
};
  return (
    <>
      { isOpen && (
        <>
        {firstDialog && <div className={styles.dialogWrapper}>
         <div className={styles.mainDialog}>
            <div className={styles.closeDialog}>
              <img
                onClick={() => {
                  setIsOpen(!isOpen); closeForm();
                }}
                alt="closeImage"
                src={close}
              />
            </div>
            <div className={styles.content}>
              <div className={styles.titleNotes}>רגע לפני שליחת החוזה אל {value.name}, ניתן להוסיף נספח עם הערות אישיות לחוזה. אם ברונך להוסיף הערות, רשום אותן כאן. במידה ולא, לחץ - המשך לשליחת חוזה</div>    
              <textarea  onChange={(event) => onChangePlace(event)} value={place} placeholder="הוסף הערות" className={styles.notes}></textarea>
            </div>
            <div className={styles.buttonFirstBlock} ><input onClick={(e)=>{sendFirstForm(); setfirstDialog(false)}} type='button' value='המשך לשליחת חוזה' className={styles.buttonFirst} /></div>
            <Dialog
            open={false}
            onClose={setOpenPopup}
            >
            <DialogContent>
              <DialogContentText>חשוב לנו להזכיר! מרגע שליחת החוזה על ידך, על הלקוח להחזיר את החוזה חתום תוך שני ימי עסקים.</DialogContentText>
              <DialogContentText>זאת על מנת לסגור את האירוע סופי ולעבור לשלב המקדמה מול הלקוח {value.name}.</DialogContentText>
              <DialogContentText>בכל מצב בו לא ישלח הלקוח חוזה חתום לאחר 2 ימי עסקים, באופן אוטומטי יפוג תוקפו של החוזה המדובר. במידה והלקוח ירצה את החוזה בשנית עליך לשלוח אותו מחדש.</DialogContentText>
            </DialogContent>
              <DialogActions sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}>
              <Button onClick={()=>{setOpenPopup(false); setfirstDialog(false);}}>
                המשך       
              </Button>
            </DialogActions>
          </Dialog>
          </div>
        </div> }
        {secondDialog && <div className={styles.dialogWrapper2}>
          <div className={styles.mainDialog}>
            <div className={styles.contentSecondDialog}>
              <div className={styles.content}>
                <div className={styles.secondTitle}>מזל טוב!! העסקה מול הלקוח {value.name} נסגרה בהצלחה</div>
                <div className={styles.secondContent}>באפשרותך לבחור האם האירוע חוסם אותך מפעילות באותו היום ואינך תופיע כזמין בתאריך זה, או שתעדיף לקבוע אירוע נוסף באותו תאריך ולהישאר זמין בפיד הספקים לאירוע נוסף באותו תאריך</div>
                <div className={styles.buttonsContent}>
                  {!isPartialDayBlocked && <button onClick={() => {disabledayoncalendar()}} className={styles.leftBtn}>חסום לכל היום</button>}
                  <button onClick={() => {samyDisabledayoncalendar()}} className={styles.rightBtn}>הישאר זמין</button>
                </div>
              </div>
            </div>
          </div>
        </div>}
      </>
      )}
    </>
  );
}
export default Popup;
