import styles from "./SupplierLogin.module.scss";
// import googleLogo from "../../../assets/images/google_logo.png";
import supplierLoginBackground from "../../../assets/images/supplierLoginBackground.png";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import axios from "../../../api/axios";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
// import { setLoader } from "../../store/loaderSlice";
import { useDispatch } from "react-redux";
import { useRef, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { GoogleLogin } from "@react-oauth/google";
import { setIdToken } from "../../store/supplierRegistrationSlice";
import { setErr } from "../../store/forAll/errSlice";
// import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import MetaFavicon from '../../components/metaComponents/metaFavicon'
import { setIsOpenSupplierAuth } from "../../store/mainSlice";
import iconHide from "../../../assets/images/icon-hide.png";
import iconView from "../../../assets/images/icon-view.png";

function SupplierLogin() {
  let [isShow, setIsShow] = useState(false);
  // const axiosPrivate = useAxiosPrivate();
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const imageContainer = useRef();
  const dispatch = useDispatch();
  // let [isDownloaded, setIsDownloaded] = useState(false);
  const backgroundImage = new Image();
  backgroundImage.src = supplierLoginBackground;
  const [forgotpassword, setforgotpassword] = useState(false);

  const goToForgotPassword = () => {
    setforgotpassword(true);
  };

  const onLoginRegisterGoogle = async (res) => {
    // console.log(res)
    try {
      const response = await axios.post(
        `/supplier/login/google`,
        {token: res.credential}
      );
      const data = await response.data;

      const accessToken = data?.jwtAuthenticationResponseDto.accessToken;
      const refreshToken = data?.jwtAuthenticationResponseDto.refreshToken;
      const roles = [data?.supplierDto.supplierType];
      const user = JSON.stringify(data?.supplierDto);

      if (accessToken && refreshToken && roles && user) {
        setAuth({accessToken, refreshToken, user, roles})
      }

      navigate("/personal-supplier/chats")

      return data === undefined ? null : data;
    } catch (error) {
      dispatch(setIdToken(res.credential))
      navigate("/supplier-registration/google")
      // console.log(error)
    }
  }

  // const handleLoadingBackground = () => {
  //   imageContainer.current.style.backgroundImage =
  //     "url(" + supplierLoginBackground + ")";
  //   setIsDownloaded(true);
  //   dispatch(setLoader(false));
  // };

  // useLayoutEffect(() => {
  //   dispatch(setLoader(true));
  //   backgroundImage.addEventListener("load", handleLoadingBackground);
  //   setTimeout(() => {dispatch(setLoader(false))},5000)
  //   // return backgroundImage.remove("load", handleLoadingBackground);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const schema = !forgotpassword ? yup
    .object()
    .shape({
     // businessName: yup.string().required("שדה חובה"), // 'Field is required'
      email: yup
        .string()
        .email("רשום מייל תקין") // 'Enter a valid email'
        .required("שדה חובה"), // 'You must enter a email'
      password: yup
        .string()
        .required("נא לרשום סיסמא") // 'Please enter your password.'
        .min(6, "הסיסמא קצרה מדי, לפחות 6 תוים."), // 'Password is too short - should be 4 chars minimum.'
    }) : yup
    .object()
    .shape({
      forgotEmail: yup
        .string()
        .email("רשום מייל תקין") // 'Enter a valid email'
        .required("שדה חובה"), // 'You must enter a email'
    })
    .required();

  const defaultValues = {
    businessName: "",
    email: "",
    password: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const loginSupplier = async (data) => {
    let data1 = {
     email: data.email,
     password: data.password,
    // businessName: data.businessName,
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_PATH}supplier/login`,
        data1
      );
      const data = await response.data;

      const accessToken = data?.jwtAuthenticationResponseDto.accessToken;
      const refreshToken = data?.jwtAuthenticationResponseDto.refreshToken;
      const roles = [data?.supplierDto.supplierType];
      const user = JSON.stringify(data?.supplierDto);

      if (accessToken && refreshToken && roles && user) {
        setAuth({accessToken, refreshToken, user, roles})
      }
      // if(window.location.pathname === '/supplier-registration'){
      //   navigate("/");
      // } else if(window.location.pathname === '/personal-guest/checklist'){
      //   navigate("/personal-supplier/chats");
      // } else if(window.location.pathname === '/personal-guest/change-user'){
      //   navigate("/personal-supplier/chats");
      // } else if(window.location.pathname === '/personal-guest'){
      //   navigate("/personal-supplier/chats");
      // }
      navigate("/personal-supplier/chats");
      dispatch(setIsOpenSupplierAuth(false))
      return data === undefined ? null : data;
    } catch (error) {
      if (error) {
        dispatch(
          setErr({
            message: error?.response?.data?.message,
            code: error?.response?.data?.code,
          })
        );
      } 
    }
  };

  const sendEmail = async (data1) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_PATH}auth/reset/password?email=${data1.forgotEmail}&userType=SUPPLIER`,
      );
      if(response){
        setforgotpassword(false);
        dispatch(
          setErr({
            message: "סיסמה חדשה נשלחה למייל",
            type: 2,
          })
        );
      }
    } catch (error) {
      if (error) {
        // console.log(error)
        dispatch(
          setErr({
            message: error?.response?.data?.message,
            code: error?.response?.data?.code,
          })
        );
      } 
    }
  };

  return (
    <>
    <MetaFavicon />
    <div className={styles.rootWrapper} onClick={(e)=>{dispatch(setIsOpenSupplierAuth(false))}}>
      <div className={styles.supplierLoginContainer} onClick={(e)=>{e.stopPropagation();}}>
        <div className={styles.supplierLoginBackgroundImage}>
          <div className={styles.adaptiveWrapperFirstBlock}>
            <div
              ref={imageContainer}
              style={{backgroundImage: "url(" + supplierLoginBackground + ")"}}
              className={styles.supplierLoginBackgroundImageContent}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25.477"
                height="25.47"
                viewBox="0 0 25.477 25.47"
                onClick={() =>{ !forgotpassword ? dispatch(setIsOpenSupplierAuth(false)) : setforgotpassword(false);}}
                className={classNames(styles.closeIcon)}
              >
                <path
                  id="Icon_ionic-ios-close"
                  data-name="Icon ionic-ios-close"
                  d="M27.042,24.024l9.1-9.1a2.132,2.132,0,1,0-3.015-3.015l-9.1,9.1-9.1-9.1a2.132,2.132,0,1,0-3.015,3.015l9.1,9.1-9.1,9.1a2.132,2.132,0,0,0,3.015,3.015l9.1-9.1,9.1,9.1a2.132,2.132,0,0,0,3.015-3.015Z"
                  transform="translate(-11.285 -11.289)"
                  fill="#242323"
                />
              </svg>
            </div>
          </div>
        </div>
          <div className={styles.supplierLoginFormContainer}>
            <div className={styles.adaptiveWrapperSecondBlock}>
              <div className={styles.supplierLoginFormContainerContent}>
{/*                 <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25.477"
                  height="25.47"
                  viewBox="0 0 25.477 25.47"
                  onClick={() => navigate(-1)}
                  className={classNames(
                    styles.closeIcon,
                    styles.closeIconDesktop
                  )}
                >
                  <path
                    id="Icon_ionic-ios-close"
                    data-name="Icon ionic-ios-close"
                    d="M27.042,24.024l9.1-9.1a2.132,2.132,0,1,0-3.015-3.015l-9.1,9.1-9.1-9.1a2.132,2.132,0,1,0-3.015,3.015l9.1,9.1-9.1,9.1a2.132,2.132,0,0,0,3.015,3.015l9.1-9.1,9.1,9.1a2.132,2.132,0,0,0,3.015-3.015Z"
                    transform="translate(-11.285 -11.289)"
                    fill="#242323"
                  />
                </svg> */}
                {!forgotpassword && <h2 className={styles.loginHeader}>התחברות כספק</h2>}
                {forgotpassword && <h2 className={styles.loginHeader}>שכחית סיסמה</h2>}

                {!forgotpassword && <div className={styles.regButtonsWithOtherResContainer}>
                  <div>
                    <GoogleLogin
                      onSuccess={credentialResponse => {
                        onLoginRegisterGoogle(credentialResponse)
                      }}
                      onError={() => {
                        // console.log('Login Failed');
                      }}
                      locale={"he"}
                    />
                  </div>
                  {/* <button onClick={() => onLoginRegisterGoogle()}>
                    <img src={googleLogo} alt="googleLogo" />
                    להתחברות עם Google
                  </button> */}
                </div> }

                {!forgotpassword && <form
                  className={styles.loginForm}
                  onSubmit={handleSubmit(loginSupplier)}
                >
                  <div className={styles.loginFormBlock}>
                    {/* <div className={styles.inputContainer}>
                      <input
                        type="text"
                        placeholder="שם העסק"
                        defaultValue=""
                        {...register("businessName")}
                        className={classNames({
                          [styles.invalidField]: errors.businessName,
                        })}
                      />
                      {errors.businessName && (
                        <p className={styles.errorMessage}>
                          {errors.businessName.message}
                        </p>
                      )}
                    </div> */}
                    <div className={styles.inputContainer}>
                      <input
                        type="email"
                        placeholder="כתובת מייל"
                        defaultValue=""
                        {...register("email")}
                        className={classNames({
                          [styles.invalidField]: errors.businessName,
                        })}
                      />
                      {errors.email && (
                        <p className={styles.errorMessage}>
                          {errors.email.message}
                        </p>
                      )}
                    </div>
                    <div className={styles.inputContainer}>
                      <input
                        type={isShow ? "text" : "password"}
                        placeholder="סיסמא"
                        defaultValue=""
                        {...register("password")}
                        className={classNames({
                          [styles.invalidField]: errors.businessName,
                        })}
                      />
                      <img
                        src={iconHide}
                        alt="hide"
                        className={styles.hideShowIcon}
                        style={{ display: isShow ? "block" : "none" }}
                        onClick={() => setIsShow(!isShow)}
                      />
                      <img
                        src={iconView}
                        alt="view"
                        className={styles.hideShowIcon}
                        style={{ display: isShow ? "none" : "block" }}
                        onClick={() => setIsShow(!isShow)}
                      />
                      {errors.password && (
                        <p className={styles.errorMessage}>
                          {errors.password.message}
                        </p>
                      )}
                    </div>
                    <div className={styles.forgotPasswordLink}>
                      <span  style={{cursor:'pointer'}} onClick={()=>goToForgotPassword()}>שכחתי סיסמא</span>
                    </div>
                  </div>
                  <div className={styles.loginFormBlock}>
                    <button className={styles.submitButton} type="submit">
                      התחבר
                    </button>
                  </div>
                </form> }
                {forgotpassword && <form
                  className={styles.loginForm}
                  onSubmit={handleSubmit(sendEmail)}
                >
                  <div className={styles.loginFormBlock}>
                    <div className={styles.inputContainer}>
                      <input
                        type="email"
                        placeholder="כתובת מייל"
                        defaultValue=""
                        {...register("forgotEmail")}
                        className={classNames({
                          [styles.invalidField]: errors.forgotEmail,
                        })}
                      />
                      {errors.forgotEmail && (
                        <p className={styles.errorMessage}>
                          {errors.forgotEmail.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className={styles.loginFormBlock}>
                    <button className={styles.submitButton2} type="submit">
                      שלח סיסמה 
                    </button>
                  </div>
                </form> }
                <div className={styles.goTeRegContainer}>
                  <p>עדיין אין לי חשבון באיוונט-דו</p>
                  <Link to="/supplier-registration" onClick={ ()=> dispatch(setIsOpenSupplierAuth(false))}>הירשם עכשיו</Link>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
    </>
  );
}

export default SupplierLogin;
