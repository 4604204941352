import styles from './Details.module.scss';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import HeaderMobile from '../../../components/headerMobile/HeaderMobile';
import { useEffect, useState } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { sendContact } from '../../../store/mainSlice';
import { useParams } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import MetaBlogDetails from '../../../components/metaComponents/metaBlogDetails';
import ChecklistButton from '../../../components/checklistButton/checklistButton';
import WhatsApp from '../../../components/whatsApp/whatsApp';

function Details() {
  const routeParams = useParams();
  let blogId = routeParams.id;
  const [blog, setAppState] = useState({ data: null, loading: true });
  let [name, setName] = useState('');
  let [number, setNumber] = useState('');
  let [numberError, setNumberError] = useState({ error: false, value: '' });
  let [nameError, setNameError] = useState({ error: false, value: '' });
  const dispatch = useDispatch();
  const [sendInfo, setsendInfo] = useState(false);

  const sendFormToConnect = () => {
    const data = {
      phoneNumber: number,
      name: name,
    };
    if (name.trim().length < 2) {
      setNameError({ error: true, value: 'לפחות 2 אותיות' });
    } else if (
      number.trim().length < 2 ||
      (number.trim().length !== 9 && number.trim().length !== 10)
    ) {
      setNumberError({ error: true, value: 'מספר הטלפון שגוי' });
    } else if (number[0] !== '0') {
      setNumberError({ error: true, value: 'מספר הטלפון צריך להתחיל מאפס' });
    } else {
      setNumberError({ error: false, value: '' });
      setNameError({ error: false, value: '' });
      dispatch(sendContact(data)).then(() => {
        setName('');
        setNumber('');
      });
      setsendInfo(true);
    }
  };

  const checkNumber = (value) => {
    if (
      value.trim().length > 0 &&
      value[0] === '0' &&
      (value.trim().length === 9 || value.trim().length === 10)
    ) {
      setNumberError({ error: false, value: '' });
    }
  };

  const checkName = (value) => {
    if (value.trim().length > 0) {
      setNameError({ error: false, value: '' });
    }
  };

  function checkImageFormat(value) {
    if (
      value === 'jpeg' ||
      value === 'png' ||
      value === 'svg' ||
      value === 'gif' ||
      value === 'jpg' ||
      value === 'webp'
    ) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    let id = blogId;
    const fetchData = async () => {
      const result = await axios(`${process.env.REACT_APP_API_PATH}main/blog/${id}`);
      setAppState({ data: result.data, loading: false });
    };
    fetchData();
  }, [blogId]);

  function replaceTagsP(value) {
    return value.replace(/p>/gi, 'div>');
  }
  return (
    <>
      {/* <WhatsApp /> */}
      <MetaBlogDetails blog={blog?.data?.articleHeader} />
      <Header />
      <HeaderMobile />
      <ChecklistButton />

      {!blog.loading && blog.data != null && (
        <div>
          {/* desctop version*/}
          <section className={styles.desctop}>
            <div className={styles.imgBlock}>
              {blog.data.type === 'VIDEO' ? (
                <div
                  style={{
                    cursor: 'pointer',
                    width: '100%',
                    height: '100%',
                    // height: "16.8125rem",
                  }}
                >
                  <iframe
                    width="100%"
                    height="100%"
                    src={blog.data.videoUrl}
                    title={'video'}
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>
              ) : (
                <>
                  {checkImageFormat(blog.data.fileType) && (
                    <img src={blog.data.fileUrl} className={styles.img} alt="testImg" />
                  )}
                  {!checkImageFormat(blog.data.fileType) && (
                    <video className={styles.img} controls>
                      <source src={blog.data.fileUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  )}
                </>
              )}
            </div>
            <h1 className={styles.title}>{blog.data.articleHeader}</h1>
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: replaceTagsP(blog.data.articleText) }}
            ></div>
            <div className={styles.backLink}>
              <a href="/blog">חזרה לכל הבלוגים</a>
            </div>
          </section>
          {/* */}

          {/* mobile version*/}
          <section className={styles.mobile}>
            <div className={styles.backLink}>
              <a href="/blog">חזרה לכל הבלוגים</a>
            </div>
            <div className={styles.title}>{blog.data.articleHeader}</div>
            <div className={styles.imgBlock}>
              {blog.data.type === 'VIDEO' ? (
                <div
                  style={{
                    cursor: 'pointer',
                    width: '100%',
                    height: '100%',
                    // height: "16.8125rem",
                  }}
                >
                  <iframe
                    width="100%"
                    height="100%"
                    src={blog.data.videoUrl}
                    title={'video'}
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>
              ) : (
                <>
                  {checkImageFormat(blog.data.fileType) && (
                    <img src={blog.data.fileUrl} className={styles.img} alt="testImg" />
                  )}
                  {!checkImageFormat(blog.data.fileType) && (
                    <video className={styles.img} controls>
                      <source src={blog.data.fileUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  )}
                </>
              )}
            </div>
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: replaceTagsP(blog.data.articleText) }}
            ></div>
          </section>
          {/* */}

          <section className={styles.fifth}>
            <h2 className={styles.fifthTitle}>רוצים לדעת יותר? הזינו פרטים ונחזור אליכם</h2>
            <div className={styles.fifthFields}>
              <div className={styles.inputContainer} style={{ marginLeft: '3rem' }}>
                <input
                  onChange={(e) => {
                    setName(e.target.value);
                    checkName(e.target.value);
                  }}
                  value={name}
                  type="text"
                  placeholder="שם מלא"
                />
                {nameError.error && <p className={styles.errorMessage}>{nameError.value}</p>}
              </div>
              <div className={styles.inputContainer}>
                <input
                  className={styles.phoneInput}
                  onChange={(e) => {
                    setNumber(e.target.value);
                    checkNumber(e.target.value);
                  }}
                  value={number}
                  type="text"
                  placeholder="מספר טלפון"
                />
                {numberError.error && <p className={styles.errorMessage}>{numberError.value}</p>}
              </div>
            </div>
            <div className={styles.fifthBtn}>
              <div onClick={() => sendFormToConnect()} className={styles.button}>
                שלח
              </div>
            </div>

            <div></div>
          </section>
          <section className={styles.beforeFooter}></section>
        </div>
      )}
      <section className={styles.desctop}>
        <Footer />
      </section>
      <Dialog
        hideBackdrop={true}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '400px',
              boxShadow: 'none',
              overflow: 'hidden',
              borderRadius: '0',
            },
          },
        }}
        open={sendInfo}
        fullWidth={true}
        className={styles.infoDialog}
      >
        <div className={styles.mainPopup}>
          <div className={styles.close}>
            <svg
              style={{ cursor: 'pointer' }}
              xmlns="http://www.w3.org/2000/svg"
              width="25.477"
              height="25.47"
              viewBox="0 0 25.477 25.47"
              onClick={() => setsendInfo(!sendInfo)}
            >
              <path
                id="Icon_ionic-ios-close"
                data-name="Icon ionic-ios-close"
                d="M27.042,24.024l9.1-9.1a2.132,2.132,0,1,0-3.015-3.015l-9.1,9.1-9.1-9.1a2.132,2.132,0,1,0-3.015,3.015l9.1,9.1-9.1,9.1a2.132,2.132,0,0,0,3.015,3.015l9.1-9.1,9.1,9.1a2.132,2.132,0,0,0,3.015-3.015Z"
                transform="translate(-11.285 -11.289)"
                fill="#242323"
              />
            </svg>
          </div>
          <div className={styles.blockCalendar}>
            <div className={styles.infoDialogClose}>
              <div className={styles.infoDialogtitle}>
                ‌פנייתך נשלחה אלינו ‌ ‌בהצלחה,‌ ‌מבטיחים ליצור איתך ‌ ‌קשר בהקדם ‌ ‌האפשרי
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}
export default Details;
