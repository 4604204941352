import { useDispatch } from "react-redux";
import styles from "./UserLogin.module.scss";
import { setIsOpenUserAuth } from "../../store/mainSlice";
import MetaFavicon from "../../components/metaComponents/metaFavicon";
import { useState } from "react";
import DefaultUserLogin from "./variantsAuth/DefaultUserLogin";
import userLoginBackground from "../../../assets/images/userLoginBackground.png";
import classNames from "classnames";
import { Box, Stack, Typography } from "@mui/material";
import LoginWithPhoneContent from "../../components/loginWithPhoneModal/LoginWithPhoneContent";
const UserLogin = () => {
  const dispatch = useDispatch();
  const [tab, setTab] = useState(0);
  const [forgotPassword, setForgotPassword] = useState(false);
  return (
    <>
      <MetaFavicon />
      <div
        className={styles.rootWrapper}
        onClick={(e) => {
          dispatch(setIsOpenUserAuth(false));
        }}
      >
        <div
          className={styles.userLoginContainer}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className={styles.userLoginBackgroundImage}>
            <div className={styles.adaptiveWrapperFirstBlock}>
              <div
                className={styles.userLoginBackgroundImageContent}
                style={{ backgroundImage: "url(" + userLoginBackground + ")" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25.477"
                  height="25.47"
                  viewBox="0 0 25.477 25.47"
                  onClick={() => {
                    dispatch(setIsOpenUserAuth(false));
                  }}
                  className={classNames(
                    styles.closeIcon,
                    styles.closeIconMobile
                  )}
                >
                  <path
                    id="Icon_ionic-ios-close"
                    data-name="Icon ionic-ios-close"
                    d="M27.042,24.024l9.1-9.1a2.132,2.132,0,1,0-3.015-3.015l-9.1,9.1-9.1-9.1a2.132,2.132,0,1,0-3.015,3.015l9.1,9.1-9.1,9.1a2.132,2.132,0,0,0,3.015,3.015l9.1-9.1,9.1,9.1a2.132,2.132,0,0,0,3.015-3.015Z"
                    transform="translate(-11.285 -11.289)"
                    fill="#242323"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className={styles.userLoginFormContainer}>
            <div className={styles.adaptiveWrapperSecondBlock}>
              <div className={styles.userLoginFormContainerContent}>
                <Stack
                  direction={"row"}
                  gap={"2em"}
                  justifyContent={"center"}
                  mb={{ xs: "1rem", xl: "1.75rem" }}
                >
                  <>
                    {forgotPassword && (
                      <h2
                        onClick={() => setTab(0)}
                        className={classNames(
                          styles.loginHeader,
                          tab === 0 && styles.loginHeader_active
                        )}
                      >
                        שכחית סיסמה
                      </h2>
                    )}
                    {!forgotPassword && (
                      <h2
                        onClick={() => setTab(0)}
                        className={classNames(
                          styles.loginHeader,
                          tab === 0 && styles.loginHeader_active
                        )}
                      >
                        התחברות עם שם משתמש
                      </h2>
                    )}
                  </>
                  <h2
                    onClick={() => setTab(1)}
                    className={classNames(
                      styles.loginHeader,
                      tab === 1 && styles.loginHeader_active
                    )}
                  >
                    התחברות מהירה
                  </h2>
                </Stack>
                {tab === 0 ? (
                  <DefaultUserLogin
                    forgotPassword={forgotPassword}
                    setForgotPassword={setForgotPassword}
                  />
                ) : (
                  <LoginWithPhoneContent
                    afterSubmit={() => {
                      dispatch(setIsOpenUserAuth(false));
                    }}
                  />
                )}

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25.477"
                  height="25.47"
                  viewBox="0 0 25.477 25.47"
                  onClick={() => {
                    dispatch(setIsOpenUserAuth(false));
                    /*console.log('sessionStorage.getItem("page")', sessionStorage.getItem("page"));
                    console.log(pathForReturn);
                    sessionStorage.getItem("page") === "homePage" ? navigate('/personal-guest/checklist') :
                    !forgotPassword ? navigate(pathForReturn || '/') : setForgotPassword(false)*/
                  }}
                  className={classNames(
                    styles.closeIcon,
                    styles.closeIconDesktop
                  )}
                >
                  <path
                    id="Icon_ionic-ios-close"
                    data-name="Icon ionic-ios-close"
                    d="M27.042,24.024l9.1-9.1a2.132,2.132,0,1,0-3.015-3.015l-9.1,9.1-9.1-9.1a2.132,2.132,0,1,0-3.015,3.015l9.1,9.1-9.1,9.1a2.132,2.132,0,0,0,3.015,3.015l9.1-9.1,9.1,9.1a2.132,2.132,0,0,0,3.015-3.015Z"
                    transform="translate(-11.285 -11.289)"
                    fill="#242323"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UserLogin;
