import styles from "./PriceList.module.scss";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import CategorySwiper from "../components/categorySwiper/CategorySwiper";
import classNames from "classnames";
import shekelImg from "../../../../assets/images/icon-payment-ils.png";
import closeIcon from "../../../../assets/images/for-pages/icons/closeIcon.png";

import {
  fetchAllEvents /* , fetchAllRecommended, sendContact, setCurrentEvent, setIsNeedPlace */,
  setSupplierServices,
  setSupplierUnicServices,
} from "../../../store/supplierRegistrationSlice";
import Select from "../../../components/selectComponent/Select";
import Venue from "./Venue/Venue";

function PriceList(props) {
  const {changeStep} = props;
  const dispatch = useDispatch();
  let {categoriesWithServices, supplierServices, supplierUnicServices} =
    useSelector((state) => state.supplierRegistration);

  // const [ services, setServices ] = useState([])

  const [events, setEvents] = useState([]);
  const [currentEvent, setCurrentEvent] = useState({});
  const [openDialog, setOpenDialog] = useState(false);

  const [categories, setCategories] = useState([]);
  const [unicServices, setUnicServices] = useState([]);
  const [currentCategory, setCurrentCategory] = useState({});
  const [isVenueError, setIsVenueError] = useState(false)
  // const [supplierServices, setCurrentTable] = useState([])

  function setCurrentTable(table) {
    // console.log(table);
    dispatch(setSupplierServices(table));
  }

  // const configureSupplierServices = () => {

  // }

  const selectCategory = (e) => {
    setCurrentCategory(e);
  };

  const selectEvent = (e) => {
    setCurrentEvent(e);
  };

  const setPrice = (e, categoryId, eventId, serviceId) => {
    let newTabel = supplierServices?.map((category) => {
      if (category.categoryId === categoryId) {
        let newCategory = {...category};
        newCategory.supplierEventDto = newCategory.supplierEventDto.map(
          (event) => {
            if (event.eventId === eventId) {
              let newEvent = {...event};
              newEvent.supplierService = newEvent.supplierService.map(
                (service) => {
                  if (service.serviceId === serviceId) {
                    let newService = {...service};
                    let price = +e.target.value;
                    newService.price = price?.toFixed(0);
                    newService.priceWithTax = (price * 1.17)?.toFixed(0);
                    return newService;
                  } else {
                    return service;
                  }
                }
              );
              return newEvent;
            } else {
              return event;
            }
          }
        );
        return newCategory;
      } else {
        return category;
      }
    });
    // console.log(supplierServices, newTabel);
    setCurrentTable(newTabel);
    // console.log("setPrice", categoryId, eventId, serviceId);
  };
  const setMinPrice = (value, categoryId, eventId, serviceId) => {
    let newTabel = supplierServices?.map((category) => {
      if (category.categoryId === categoryId) {
        let newCategory = {...category};
        newCategory.supplierEventDto = newCategory.supplierEventDto.map(
          (event) => {
            if (event.eventId === eventId) {
              let newEvent = {...event};
              newEvent.supplierService = newEvent.supplierService.map(
                (service) => {
                  if (service.serviceId === serviceId) {
                    let newService = {...service};
                    let minPrice = +value;
                    newService.minPrice = minPrice;
                    newService.minPriceWithTax = (minPrice * 1.17).toFixed(0);
                    // newService.priceWithTax = (price * 1.17)?.toFixed(0);
                    return newService;
                  } else {
                    return service;
                  }
                }
              );
              return newEvent;
            } else {
              return event;
            }
          }
        );
        return newCategory;
      } else {
        return category;
      }
    });
    // console.log(supplierServices, newTabel);
    setCurrentTable(newTabel);
    // console.log("setPrice", categoryId, eventId, serviceId);
  };

  // const setPriceWithTax = (e, categoryId, eventId, serviceId) => {
  //   let newTabel = supplierServices?.map((category) => {
  //     if (category.categoryId === categoryId) {
  //       let newCategory = {...category};
  //       newCategory.supplierEventDto = newCategory.supplierEventDto.map(
  //         (event) => {
  //           if (event.eventId === eventId) {
  //             let newEvent = {...event};
  //             newEvent.supplierService = newEvent.supplierService.map(
  //               (service) => {
  //                 if (service.serviceId === serviceId) {
  //                   let newService = {...service};
  //                   let priceWithTax = +e.target.value;
  //                   newService.priceWithTax = priceWithTax?.toFixed(0);
  //                   newService.price = (priceWithTax / 1.17)?.toFixed(0);
  //                   return newService;
  //                 } else {
  //                   return service;
  //                 }
  //               }
  //             );
  //             return newEvent;
  //           } else {
  //             return event;
  //           }
  //         }
  //       );
  //       return newCategory;
  //     } else {
  //       return category;
  //     }
  //   });
  //   console.log(supplierServices, newTabel);
  //   setCurrentTable(newTabel);
  //   console.log("setPrice", categoryId, eventId, serviceId);
  // };

  const setIsPrice = (e, categoryId, eventId, serviceId) => {
    //
    let newTabel = supplierServices?.map((category) => {
      if (category.categoryId === categoryId) {
        let newCategory = {...category};
        newCategory.supplierEventDto = newCategory.supplierEventDto.map(
          (event) => {
            if (event.eventId === eventId) {
              let newEvent = {...event};
              newEvent.supplierService = newEvent.supplierService.map(
                (service) => {
                  if (service.serviceId === serviceId) {
                    let newService = {...service};
                    newService.isSelected = !newService.isSelected;
                    if (!newService.isSelected) {
                      newService.price = 0;
                      newService.minPrice = 0;
                      newService.priceWithTax = 0;
                      newService.minPriceWithTax = 0;
                    }
                    return newService;
                  } else {
                    return service;
                  }
                }
              );
              return newEvent;
            } else {
              return event;
            }
          }
        );
        return newCategory;
      } else {
        return category;
      }
    });
    // console.log(supplierServices, newTabel);
    setCurrentTable(newTabel);
    // console.log("setIsPrice", categoryId, eventId, serviceId);
  };

  const setIsFlashEvent = (e, categoryId, eventId) => {
    // console.log(supplierServices);
    let newTabel = supplierServices?.map((category) => {
      if (category.categoryId === categoryId) {
        let newCategory = {...category};
        newCategory.supplierEventDto = newCategory.supplierEventDto.map(
          (event) => {
            if (event.eventId === eventId) {
              let newEvent = {...event};
              newEvent.flashEvent = !newEvent.flashEvent;
              if (!newEvent.flashEvent) {
                newEvent.discount = 0;
              }

              return newEvent;
            } else {
              return event;
            }
          }
        );
        return newCategory;
      } else {
        return category;
      }
    });
    // console.log(supplierServices, newTabel);
    setCurrentTable(newTabel);
    // console.log("setIsFlashEvent", categoryId, eventId);
  };

  const setDiscount = (e, categoryId, eventId) => {
    let newTabel = supplierServices?.map((category) => {
      if (category.categoryId === categoryId) {
        let newCategory = {...category};
        newCategory.supplierEventDto = newCategory.supplierEventDto.map(
          (event) => {
            if (event.eventId === eventId) {
              let newEvent = {...event};
              let discount = +e.target.value;
              newEvent.discount = discount?.toFixed(0);
              return newEvent;
            } else {
              return event;
            }
          }
        );
        return newCategory;
      } else {
        return category;
      }
    });
    // console.log(supplierServices, newTabel);
    setCurrentTable(newTabel);
    // let value = e.target.value
    // console.log("setDiscount", categoryId, eventId);
  };

  const createTableForAllPrices = (categories) => {
    // supplierServices

    // console.log(categories);
    let newSupplierServices = categories?.map((category) => {
      let curCat = supplierServices.filter((cat1) => {
        return cat1.categoryId === category.id;
      });
      let supplierEventDtoItems;

      if (curCat.length) {
        supplierEventDtoItems = category?.eventTypes?.map((event) => {
          let newEvt = curCat[0].supplierEventDto.filter((ev) => {
            return ev.eventId === event.id;
          });

          if (newEvt.length) {
            let newServ = categoriesWithServices
              .filter((cat) => cat.category.id === category.id)
              .map((cate) => {
                let newServ = newEvt[0].supplierService.filter((serv) => {
                  return serv.serviceId === cate.services.id;
                });

                if (newServ.length) {
                  return {
                    ...newServ[0],
                  };
                } else {
                  return {
                    services: cate.services,
                    isSelected: false,
                    serviceId: cate.services.id,
                    price: 0,
                    minPrice: 0,
                    priceWithTax: 0,
                    minPriceWithTax: 0,
                  };
                }
              });

            return {
              eventId: event.id,
              flashEvent: newEvt[0].flashEvent,
              discount: newEvt[0].discount,
              supplierService: newServ,
            };
          } else {
            let services = categoriesWithServices
              .filter((cat) => cat.category.id === category.id)
              .map((cate) => {
                return {
                  services: cate.services,
                  isSelected: false,
                  serviceId: cate.services.id,
                  price: 0,
                  minPrice: 0,
                  priceWithTax: 0,
                  minPriceWithTax: 0,
                };
              });
            return {
              eventId: event.id,
              flashEvent: false,
              discount: 0,
              supplierService: services,
            };
          }
        });
      } else {
        supplierEventDtoItems = category?.eventTypes?.map((event) => {
          let services = categoriesWithServices
            .filter((cat) => cat.category.id === category.id)
            .map((cate) => {
              return {
                services: cate.services,
                isSelected: false,
                serviceId: cate.services.id,
                price: 0,
                minPrice: 0,
                minPriceWithTax: 0,
              };
            });
          return {
            eventId: event.id,
            flashEvent: false,
            discount: 0,
            supplierService: services,
          };
        });
      }

      return {
        categoryId: category.id,
        category,
        supplierEventDto: supplierEventDtoItems,
      };
    });
    // console.log(newSupplierServices);
    // console.log(currentCategory, supplierServices.find(categ =>categ.categoryId === currentCategory.id))
    return newSupplierServices;
  };

  const getCategoriesFromCat = () => {
    const key = "category";
    let newCategory = [
      ...new Map(
        categoriesWithServices.map((item) => [item[key].id, item.category])
      ).values(),
    ];
    // console.log(newCategory);
    setCategories(newCategory);
    setCurrentCategory((newCategory[0] && newCategory[0]) || {});
    setEvents(newCategory[0] && newCategory[0]?.eventTypes);
    setCurrentEvent(
      newCategory[0] &&
        newCategory[0].eventTypes &&
        newCategory[0]?.eventTypes[0]
    );

    setCurrentTable(createTableForAllPrices(newCategory));
  };

  function removeSpaces(str) {
    // console.log(str)
    if (!str && str !== "") return;
    let newStr = str.replace(/\s+/g, " ").trim();
    return newStr;
  }

  function getId(arr) {
    //last elem id + 1
    return arr[arr.length - 1]?.id + 1 || 1;
  }

  const addFields = () => {
    if (currentCategory.id && currentEvent.id) {
      let actualState = [...unicServices];
      actualState.push({
        id: getId(actualState),
        categoryId: currentCategory?.id,
        eventTypeId: currentEvent?.id,
        serviceTypeName: "",
        price: null,
        minPrice: null,
        priceWithTax: null,
        minPriceWithTax: null,
        isSaved: false,
        error: null,
      });
      setUnicServices(actualState);
    }
  };

  const changeUnicName = (id, value) => {
    let newUnicServices = [...unicServices].map((service) => {
      let newServ = {...service};
      if (newServ.id === id) {
        newServ.serviceTypeName = value;
      }
      return newServ;
    });
    setUnicServices(newUnicServices);
  };

  const saveUnic = (id) => {
    let newArr = unicServices.map((item) => {
      let newItem = {...item};
      if (item.id === id) {
        newItem.isSaved = true;
      }
      return newItem;
    });
    let serv = {
      ...newArr.find((item) => {
        return item.id === id;
      }),
    };
    // console.log(serv);
    if (!serv?.serviceTypeName || !serv?.price) return;
    if (currentCategory?.id === 2 && !serv?.minPrice) return;
    setUnicServices(newArr);
    serv.serviceTypeName = removeSpaces(serv.serviceTypeName);
    let isFind = false;
    let newUnicServices = [...supplierUnicServices].map((service) => {
      let newServ = {...service};
      if (newServ.id === id) {
        isFind = true;
        newServ = {...serv};
      }
      return newServ;
    });
    if (!isFind) {
      newUnicServices.push(serv);
    }
    // console.log(newUnicServices);
    // setUnicServices(newUnicServices)
    dispatch(setSupplierUnicServices(newUnicServices));
  };

  const changeUnicPrice = (id, val) => {
    let value = +val;
    // console.log(typeof value);
    if (typeof value !== "number" || isNaN(value)) return;
    let newUnicServices = [...unicServices].map((service) => {
      let newServ = {...service};
      if (newServ.id === id) {
        newServ.price = value;
        newServ.priceWithTax = (value * 1.17).toFixed(0);
      }
      return newServ;
    });
    setUnicServices(newUnicServices);
  };

  const checkWeddingError = () => {
    // console.log(supplierServices)
    return !!supplierServices?.find((service) => {
      return service?.category?.id === 2
    })?.supplierEventDto?.find((event) => {
      return event?.supplierService?.find(serv => {
        if(!(serv?.price>=serv?.minPrice)){
          return true
        } else {
          return false
        }
      })
    })
  }

  const changeUnicMinPrice = (id, val) => {
    let value = +val;
    // console.log(typeof value);
    if (typeof value !== "number" || isNaN(value)) return;
    let newUnicServices = [...unicServices].map((service) => {
      let newServ = {...service};
      if (newServ.id === id) {
        newServ.minPrice = value;
        newServ.minPriceWithTax = (value * 1.17).toFixed(0);
      }
      return newServ;
    });
    setUnicServices(newUnicServices);
  };

  // const findServicesByCategory = () =>{
  //   return categoriesWithServices.filter(item => item.category.id === currentCategory.id)
  // }

  useEffect(() => {
    setEvents(currentCategory.eventTypes);
    setCurrentEvent((events && events[0]) || {});
    // setServices(findServicesByCategory())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, currentCategory]);

  useEffect(() => {
    dispatch(fetchAllEvents());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (categoriesWithServices !== undefined) {
      getCategoriesFromCat();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriesWithServices]);

  useEffect(() => {
    if (openDialog) {
      window.scrollTo(0, 0);
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "initial";
    }
  }, [openDialog]);

  // useEffect(() => {
  //   console.log(events)
  // }, [events]);

  /* const { allEvents } =  */

  return (
    <div style={{maxWidth: "55.937rem", width: "100%"}}>
      <div className={styles.mainContainer}>
        <p className={styles.priceListTip}>
          {" "}
          ברשימה זו יופיעו כל תתי התחומים הקשורים לתחום שבחרת, מלא.י מחיר{" "}
          <span>רק</span> עבור השירותים שאת.ה מציע.ה
        </p>
        <CategorySwiper
          selectedCategory={selectCategory}
          currentCategory={currentCategory}
          categories={categories}
        />
        <div className={styles.gap}></div>
        {!!events?.length && (
          <CategorySwiper
            selectedCategory={selectEvent}
            currentCategory={currentEvent}
            categories={events}
          />
        )}
        <div className={styles.gap}></div>
        {currentCategory?.id === 1 && currentEvent?.id ? (
          <Venue
            setIsVenueError={setIsVenueError}
            allEvents={events}
            curentCategory={currentCategory}
            curentEvent={currentEvent}
          />
        ) : (
          <div className={styles.priceListContainer}>
            <div className={styles.priceListHeader}>
              <div className={styles.firstCol}>סוג השירות</div>
              <div className={styles.secondCol}>הזן מחיר (לפני מע"מ)</div>
              <div className={styles.thirdCol}></div>
            </div>
            <div className={styles.priceListGap}>
              <div className={styles.firstCol}></div>
              <div
                style={
                  currentCategory?.id === 2
                    ? {width: "31.5%", marginRight: "1.63rem"}
                    : {}
                }
                className={styles.secondColHead}
              >
                {currentCategory?.id === 2 ? (
                  <>
                    <span>{"מקסימום"}</span>
                    <span>{"מינימום"}</span>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div
                style={
                  currentCategory?.id === 2
                    ? {width: "20%", paddingRight: "3.7rem"}
                    : {}
                }
                className={styles.thirdCol}
              >
                כולל מע"מ
              </div>
            </div>
            <div className={styles.priceListContent}>
              {supplierServices?.length &&
                supplierServices[0] &&
                currentCategory?.id &&
                (
                  (
                    supplierServices?.find(
                      (categ) => categ.categoryId === currentCategory.id
                    ) || []
                  )?.supplierEventDto?.find(
                    (event) => event.eventId === currentEvent.id
                  ) || {}
                )?.supplierService?.map((service) => (
                  <div
                    key={`cat${currentCategory.id}ev${currentEvent.id}se${service?.services?.id}`}
                    className={styles.contentRow}
                  >
                    <div className={styles.firstCol}>
                      <div className={classNames(styles.radio)}>
                        <input
                          id={`radioService${service?.services?.id}1`}
                          type="radio"
                          // name={`isProvided${service?.services?.id}`}
                          checked={service.isSelected}
                          onClick={(e) =>
                            setIsPrice(
                              e,
                              currentCategory.id,
                              currentEvent.id,
                              service?.services?.id
                            )
                          }
                        />                     
                        <label
                          htmlFor={`radioService${service?.services?.id}1`}
                        >
                          {service?.services?.name}
                        </label>
                      </div>
                    </div>
                    <div
                      style={currentCategory?.id === 2 ? {width: "31.5%"} : {}}
                      className={styles.secondCol}
                    >
                      <p className={styles.inpWithError}>
                        <input
                          type="number"
                          disabled={!service.isSelected}
                          // value={supplierServices.find(cat => cat.categoryId===currentCategory.id).supplierEventDto.find(event => event.eventId === currentEvent.id).supplierService.find( serv => serv.serviceId === service?.services?.id).price}
                          value={service.price}
                          
                          onChange={(e) =>
                            setPrice(
                              e,
                              currentCategory.id,
                              currentEvent.id,
                              service?.services?.id
                            )
                          }
                        />
                        <img src={shekelImg} alt="shekel" />
                        {((!(service.price>=service.minPrice) || service.price < 0 || service.minPrice < 0)) && (
                          <div className={styles.errorMessageLeft}> {"מספר לא חוקי"}</div>
                        )}
                      </p>
                      {currentCategory?.id === 2 && (
                        <p className={styles.inpWithError}>
                          <input
                            type="number"
                            disabled={!service.isSelected}
                            // value={supplierServices.find(cat => cat.categoryId===currentCategory.id).supplierEventDto.find(event => event.eventId === currentEvent.id).supplierService.find( serv => serv.serviceId === service?.services?.id).price}
                            value={service.minPrice}
                            
                            onChange={(e) =>
                              setMinPrice(
                                e.target.value,
                                currentCategory.id,
                                currentEvent.id,
                                service?.services?.id
                              )
                            }
                          />
                          <img src={shekelImg} alt="shekel" />
                          {((!(service?.price>=service?.minPrice)|| service.price < 0 || service.minPrice < 0)) && (
                            <div className={styles.errorMessageRight}> {"מספר לא חוקי"}</div>
                          )}
                        </p>
                      )}
                    </div>
                    <div
                      style={
                        currentCategory?.id === 2
                          ? {width: "20%", paddingRight: "5.3125rem"}
                          : {}
                      }
                      className={styles.thirdCol}
                    >
                      <span>{service.priceWithTax}</span>
                    </div>
                  </div>
                ))}
              <div className={styles.contentRow}>
                <div className={styles.firstCol}>
                  <div className={classNames(styles.radio)}>
                    <input
                      id="radioServiceDiscount1"
                      type="radio"
                      // name="isProvidedDiscount"
                      checked={
                        supplierServices
                          ?.find((cat) => cat.categoryId === currentCategory.id)
                          ?.supplierEventDto?.find(
                            (event) => event.eventId === currentEvent.id
                          )?.flashEvent
                      }
                      // value={true}
                      onClick={(e) =>
                        setIsFlashEvent(e, currentCategory.id, currentEvent.id)
                      }
                    />
                    <label htmlFor="radioServiceDiscount1">
                      אירוע בזק (עד 30 יום מראש)
                    </label>
                  </div>
                </div>
                <div className={styles.secondCol}>
                  <p>
                    <input
                      type="number"
                      disabled={
                        !supplierServices
                          ?.find((cat) => cat.categoryId === currentCategory.id)
                          ?.supplierEventDto?.find(
                            (event) => event.eventId === currentEvent.id
                          )?.flashEvent
                      }
                      value={
                        supplierServices
                          ?.find((cat) => cat.categoryId === currentCategory.id)
                          ?.supplierEventDto?.find(
                            (event) => event.eventId === currentEvent.id
                          )?.discount
                      }
                      onChange={(e) =>
                        setDiscount(e, currentCategory.id, currentEvent.id)
                      }
                    />
                    <span>%</span>
                  </p>
                </div>
                <div className={styles.thirdCol}></div>
              </div>
            </div>
            <div className={styles.underline}></div>
            {unicServices
              .filter((item) => {
                return (
                  item.categoryId === currentCategory?.id &&
                  item.eventTypeId === currentEvent.id
                );
              })
              .map((service) => {
                return (
                  <>
                    {service.isSaved ? (
                      <div
                        key={"unic" + service.id}
                        className={styles.contentRow}
                      >
                        <div className={styles.firstCol}>
                          <div className={classNames(styles.radio)}>
                            <input
                              id={`radioUnicService${service.id}1`}
                              type="radio"
                              // name="isProvidedDiscount"
                              checked={service.isSaved}
                              // value={true}
                              // onClick={(e) =>
                              //   setIsFlashEvent(
                              //     e,
                              //     currentCategory.id,
                              //     currentEvent.id
                              //   )
                              // }
                            />
                            <label htmlFor={`radioUnicService${service.id}1`}>
                              {service.serviceTypeName}
                            </label>
                          </div>
                        </div>
                        <div
                          style={
                            currentCategory?.id === 2 ? {width: "32.5%"} : {}
                          }
                          className={styles.secondCol}
                        >
                          <p>
                            <input
                              type="number"
                              disabled={true}
                              value={service.price}
                              // onChange={(e) =>
                              //   setDiscount(
                              //     e,
                              //     currentCategory.id,
                              //     currentEvent.id
                              //   )
                              // }
                            />
                          </p>
                          {currentCategory?.id === 2 && (
                            <p className={styles.inpWithError}>
                              <input
                                type="number"
                                disabled={true}
                                value={service.minPrice}
                                // onChange={(e) =>
                                //   setDiscount(
                                //     e,
                                //     currentCategory.id,
                                //     currentEvent.id
                                //   )
                                // }
                              />
                            </p>
                          )}
                        </div>
                        <div
                          style={{width: "15%"}}
                          className={styles.thirdCol}
                        ></div>
                      </div>
                    ) : (
                      <div key={"unic" + service.id} className={styles.unicRow}>
                        <div
                          style={
                            currentCategory?.id === 2 ? {width: "79.5%"} : {}
                          }
                          className={styles.inputs}
                        >
                          <input
                            style={
                              currentCategory?.id === 2 ? {width: "59%"} : {}
                            }
                            className={styles.firstInput}
                            placeholder={"הכנס תת תחום"}
                            value={service.serviceTypeName}
                            onChange={(e) =>
                              changeUnicName(service.id, e.target.value)
                            }
                          />
                          <div className={styles.inpWithError}>
                            <input
                              style={
                                currentCategory?.id === 2
                                  ? {width: "58%", marginRight: "7.625rem"}
                                  : {}
                              }
                              className={styles.secondInput}
                              placeholder={"הזן מחיר"}
                              type={"number"}
                              pattern="\d*"
                              value={service.price || ""}
                              onChange={(e) =>
                                changeUnicPrice(service.id, e.target.value)
                              }
                            />
                            {((currentCategory?.id === 2) && !(service.price>=service.minPrice)) && (
                              <div className={styles.errorMessageLeftSpec}> {"מספר לא חוקי"}</div>
                            )}
                          </div>

                          
                          {currentCategory?.id === 2 && (
                            <div
                              className={styles.inpWithError}>
                              <input
                                style={{width: "63%",marginLeft:'0.8rem'}}
                                className={styles.secondInput}
                                placeholder={"הזן מחיר"}
                                type={"number"}
                                pattern="\d*"
                                value={service.minPrice || ""}
                                onChange={(e) =>
                                  changeUnicMinPrice(service.id, e.target.value)
                                }
                              />
                              {((currentCategory?.id === 2) && !(service?.price>=service?.minPrice)) && (
                                <div className={styles.errorMessageRightSpec}> {"מספר לא חוקי"}</div>
                              )}
                            </div>
                          )}
                        </div>

                        <div
                          className={styles.saveUnic}
                          onClick={() => {
                            saveUnic(service.id);
                          }}
                        >
                          {" "}
                          {"הוסף"}
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            <div className={styles.moreFields}>
              <span onClick={() => addFields()}>+ {"הוספת תת תחום ידני"}</span>
            </div>
          </div>
        )}
      </div>

      <div className={styles.firstMobile}>
        {openDialog && (
          <div className={styles.second}>
            <div className={styles.formContainer}>
              <div
                onClick={() => {
                  setOpenDialog(false);
                }}
                className={styles.close}
              >
                <img src={closeIcon} alt="close" />
              </div>
              {currentCategory?.id === 1 ? (
                <Venue
                  setIsVenueError={setIsVenueError}
                  isMobile={true}
                  allEvents={events}
                  curentCategory={currentCategory}
                  curentEvent={currentEvent}
                />
              ) : (
                <>
                  <div className={styles.title}>
                    {
                      "ברשימה זו יופיעו כל תת התחומים הקשורים לתחום בחרת. מלא.י מחיר רק עבור השירותים שאת.ה מציע.ה"
                    }
                  </div>
                  <div
                    style={
                      currentCategory?.id === 2
                        ? {paddingLeft: "4rem", width: "initial"}
                        : {}
                    }
                    className={styles.withTax}
                  >{`הזן מחיר-כולל מע"מ`}</div>
                  <div className={styles.main}>
                    <div className={styles.priceListContent}>
                      {supplierServices?.length &&
                        supplierServices[0] &&
                        currentCategory?.id &&
                        (
                          (
                            supplierServices?.find(
                              (categ) => categ.categoryId === currentCategory.id
                            ) || []
                          )?.supplierEventDto?.find(
                            (event) => event.eventId === currentEvent.id
                          ) || {}
                        )?.supplierService?.map((service) => (
                          <div
                            key={`cat${currentCategory.id}ev${currentEvent.id}se${service?.services?.id}`}
                            className={styles.contentRow}
                          >
                            <div className={styles.firstCol}>
                              <div className={classNames(styles.radio)}>
                                <input
                                  id={`radioService${service?.services?.id}2`}
                                  type="radio"
                                  // name={`isProvided${service?.services?.id}`}
                                  checked={service.isSelected}
                                  onClick={(e) =>
                                    setIsPrice(
                                      e,
                                      currentCategory.id,
                                      currentEvent.id,
                                      service?.services?.id
                                    )
                                  }
                                />
                                <label
                                  htmlFor={`radioService${service?.services?.id}2`}
                                >
                                  {service?.services?.name}
                                </label>
                              </div>
                            </div>
                            <div
                              style={
                                currentCategory?.id === 2
                                  ? {
                                      width: "7.8125rem",
                                      paddingLeft: "2.4375rem",
                                      backgroundColor: service.isSelected
                                        ? "#EEE9E5"
                                        : "#C9C8C8",
                                    }
                                  : {
                                      backgroundColor: service.isSelected
                                        ? "#EEE9E5"
                                        : "#C9C8C8",
                                    }
                              }
                              className={styles.secondCol}
                            >
                              <p className={styles.inpWithError} style={{display: "flex"}}>
                                <input
                                  style={{
                                    padding: "0",
                                    backgroundColor: service.isSelected
                                      ? "#EEE9E5"
                                      : "#C9C8C8",
                                  }}
                                  type="number"
                                  disabled={!service.isSelected}
                                  // value={supplierServices.find(cat => cat.categoryId===currentCategory.id).supplierEventDto.find(event => event.eventId === currentEvent.id).supplierService.find( serv => serv.serviceId === service?.services?.id).price}
                                  value={service.price}
                                  
                                  onChange={(e) =>
                                    setPrice(
                                      e,
                                      currentCategory.id,
                                      currentEvent.id,
                                      service?.services?.id
                                    )
                                  }
                                />
                                {!(currentCategory?.id === 2) && (
                                  <img src={shekelImg} alt="shekel" />
                                )}
                                {((currentCategory?.id === 2) && (!(service?.price>=service?.minPrice)|| service.price < 0 || service.minPrice < 0)) && (
                                  <div className={styles.errorMessage}> {"מספר לא חוקי"}</div>
                                )}
                              </p>
                              {currentCategory?.id === 2 && <div>{" - "}</div>}
                              {currentCategory?.id === 2 && (
                                <p className={styles.inpWithError}>
                                  <input
                                    style={{
                                      paddingTop: "0",
                                      backgroundColor: service.isSelected
                                        ? "#EEE9E5"
                                        : "#C9C8C8",
                                    }}
                                    type="number"
                                    disabled={!service.isSelected}
                                    // value={supplierServices.find(cat => cat.categoryId===currentCategory.id).supplierEventDto.find(event => event.eventId === currentEvent.id).supplierService.find( serv => serv.serviceId === service?.services?.id).price}
                                    value={service.minPrice}
                                    
                                    onChange={(e) =>
                                      setMinPrice(
                                        e.target.value,
                                        currentCategory.id,
                                        currentEvent.id,
                                        service?.services?.id
                                      )
                                    }
                                  />
                                  <img src={shekelImg} alt="shekel" />
                                  {((!(service?.price>=service?.minPrice)|| service.price < 0 || service.minPrice < 0)) && (
                                    <div className={styles.errorMessageRight}> {"מספר לא חוקי"}</div>
                                  )}
                                </p>
                              )}
                            </div>
                          </div>
                        ))}
                      <div className={styles.contentRow}>
                        <div className={styles.firstCol}>
                          <div className={classNames(styles.radio)}>
                            <input
                              id="radioServiceDiscount2"
                              type="radio"
                              // name="isProvidedDiscount"
                              checked={
                                supplierServices
                                  ?.find(
                                    (cat) =>
                                      cat.categoryId === currentCategory.id
                                  )
                                  ?.supplierEventDto?.find(
                                    (event) => event.eventId === currentEvent.id
                                  )?.flashEvent
                              }
                              // value={true}
                              onClick={(e) =>
                                setIsFlashEvent(
                                  e,
                                  currentCategory.id,
                                  currentEvent.id
                                )
                              }
                            />
                            <label htmlFor="radioServiceDiscount2">
                              אירוע בזק (עד 30 יום מראש)
                            </label>
                          </div>
                        </div>
                        <div className={styles.secondCol}>
                          <p>
                            <input
                              type="number"
                              style={{
                                backgroundColor: supplierServices
                                  ?.find(
                                    (cat) =>
                                      cat.categoryId === currentCategory.id
                                  )
                                  ?.supplierEventDto?.find(
                                    (event) => event.eventId === currentEvent.id
                                  )?.flashEvent
                                  ? "#EEE9E5"
                                  : "#C9C8C8",
                              }}
                              disabled={
                                !supplierServices
                                  ?.find(
                                    (cat) =>
                                      cat.categoryId === currentCategory.id
                                  )
                                  ?.supplierEventDto?.find(
                                    (event) => event.eventId === currentEvent.id
                                  )?.flashEvent
                              }
                              value={
                                supplierServices
                                  ?.find(
                                    (cat) =>
                                      cat.categoryId === currentCategory.id
                                  )
                                  ?.supplierEventDto?.find(
                                    (event) => event.eventId === currentEvent.id
                                  )?.discount
                              }
                              onChange={(e) =>
                                setDiscount(
                                  e,
                                  currentCategory.id,
                                  currentEvent.id
                                )
                              }
                            />
                            <span>%</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.underline}></div>
                  {unicServices
                    .filter((item) => {
                      return (
                        item.categoryId === currentCategory?.id &&
                        item.eventTypeId === currentEvent.id
                      );
                    })
                    .map((service) => {
                      return (
                        <>
                          {service.isSaved ? (
                            <div
                              key={"unic" + service.id}
                              className={styles.contentRow}
                            >
                              <div className={styles.firstCol}>
                                <div className={classNames(styles.radio)}>
                                  <input
                                    id={`radioUnicService${service.id}2`}
                                    type="radio"
                                    // name={`isProvided${service?.services?.id}`}
                                    checked={service.isSaved}
                                    // onClick={(e) =>
                                    //   setIsPrice(
                                    //     e,
                                    //     currentCategory.id,
                                    //     currentEvent.id,
                                    //     service?.services?.id
                                    //   )
                                    // }
                                  />
                                  <label
                                    htmlFor={`radioUnicService${service.id}2`}
                                  >
                                    {service.serviceTypeName}
                                  </label>
                                </div>
                              </div>
                              <div
                                className={styles.secondCol}
                                style={
                                  currentCategory?.id === 2
                                    ? {width: "10.3125rem"}
                                    : {}
                                }
                              >
                                <p
                                  style={
                                    currentCategory?.id === 2
                                      ? {width: "76%"}
                                      : {}
                                  }
                                  
                                >
                                <p className={styles.inpWithError}>
                                  <input
                                    type="string"
                                    disabled={true}
                                    // value={supplierServices.find(cat => cat.categoryId===currentCategory.id).supplierEventDto.find(event => event.eventId === currentEvent.id).supplierService.find( serv => serv.serviceId === service?.services?.id).price}
                                    
                                    value={
                                      currentCategory?.id !== 2
                                        ? service.price
                                        : service.price +
                                          "  -  " +
                                          service.minPrice
                                    }
                                    // onChange={(e) =>
                                    //   // setPriceWithTax(
                                    //   //   e,
                                    //   //   currentCategory.id,
                                    //   //   currentEvent.id,
                                    //   //   service?.services?.id
                                    //   // )
                                    // }
                                  />
                                  <img src={shekelImg} alt="shekel" />
                                  {((!(service?.price>=service?.minPrice) || service.price < 0 || service.minPrice < 0)) && (
                                    <div className={styles.errorMessageRight}> {"מספר לא חוקי"}</div>
                                  )}
                                </p>
                                </p>
                              </div>
                            </div>
                          ) : (
                            <div
                              key={"unic" + service.id}
                              className={styles.unicRow}
                            >
                              <div
                                style={{alignItems: "center"}}
                                className={styles.inputs}
                              >
                                <input
                                  className={styles.firstInput}
                                  placeholder={"הכנס תת תחום"}
                                  value={service.serviceTypeName}
                                  onChange={(e) =>
                                    changeUnicName(service.id, e.target.value)
                                  }
                                />
                                <div className={styles.inpWithError}>
                                  <input
                                    className={styles.secondInput}
                                    placeholder={"הזן מחיר"}
                                    type={"number"}
                                    pattern="\d*"
                                    value={service.price || ""}
                                    onChange={(e) =>
                                      changeUnicPrice(service.id, e.target.value)
                                    }
                                  />
                                  {((currentCategory?.id === 2) && !(service?.price>=service?.minPrice)) && (
                                    <div className={styles.errorMessageLeft}> {"מספר לא חוקי"}</div>
                                  )}
                                </div>

                                {currentCategory?.id === 2 && (
                                  <div>{" - "}</div>
                                )}
                                {currentCategory?.id === 2 && (
                                  <div className={styles.inpWithError}>
                                    <input
                                      className={styles.secondInput}
                                      placeholder={"הזן מחיר"}
                                      type={"number"}
                                      pattern="\d*"
                                      value={service.minPrice || ""}
                                      onChange={(e) =>
                                        changeUnicMinPrice(
                                          service.id,
                                          e.target.value
                                        )
                                      }
                                    />
                                    {((currentCategory?.id === 2) && !(service?.price>=service?.minPrice)) && (
                                      <div className={styles.errorMessageRight}> {"מספר לא חוקי"}</div>
                                    )}
                                  </div>
                                  
                                )}
                              </div>

                              <div
                                className={styles.saveUnic}
                                onClick={() => {
                                  saveUnic(service.id);
                                }}
                              >
                                {" "}
                                {"הוסף"}
                              </div>
                            </div>
                          )}
                        </>
                      );
                    })}
                  <div className={styles.moreFields}>
                    <span onClick={() => addFields()}>
                      + {"הוספת תת תחום ידני"}
                    </span>
                  </div>
                </>
              )}

              <div className={styles.dialogBtn}>
                <div
                  onClick={() => {
                    if(
                      !isVenueError &&
                      !checkWeddingError()
                    ){
                      setOpenDialog(false);
                      changeStep();
                    }
                  }}
                  className={styles.button}
                >
                  עדכן מחירים
                </div>
              </div>
            </div>
          </div>
        )}
        <div className={styles.title}>
          בחר סוג אירוע מתוך הרשימה ומלא את המחירון בהתאם
        </div>
        <div className={styles.categorySelect}>
          <Select
            // invalid={errors.companyTypeId}
            // control={control}
            id="categoryForPrice1"
            name="categoryForPrice1"
            // rules={{ required: true }}
            fontSize={"1rem"}
            paddingInlineStart={"0.9375rem"}
            paddingInlineEnd={"0.75rem"}
            placeholder={"סוג אירוע"}
            // width={"100%"}
            borderRadius={"0.125rem"}
            backgroundColor={"white"}
            color={"#242323"}
            options={
              categories
              //   [
              //   { id: 1, name: "first" },
              //   { id: 2, name: "second" },
              //   { id: 3, name: "third" },
              //   { id: 4, name: "fourth" },
              // ]
            }
            onChangeOption={(e) => {
              selectCategory(e);
            }}
            selectedOption={currentCategory}
          />
        </div>
        <div className={styles.eventSelect}>
          <Select
            // invalid={errors.companyTypeId}
            // control={control}
            // name="eventToPrice"
            // rules={{ required: true }}
            fontSize={"1rem"}
            paddingInlineStart={"0.9375rem"}
            paddingInlineEnd={"0.75rem"}
            placeholder={"סוג אירוע"}
            // width={"100%"}
            borderRadius={"0.125rem"}
            backgroundColor={"white"}
            color={"#242323"}
            options={
              events
              //   [
              //   { id: 1, name: "first" },
              //   { id: 2, name: "second" },
              //   { id: 3, name: "third" },
              //   { id: 4, name: "fourth" },
              // ]
            }
            onChangeOption={(e) => {
              selectEvent(e);
            }}
            selectedOption={currentEvent}
          />
        </div>
        <div className={styles.btn}>
          <div
            onClick={() => {
              setOpenDialog(true);
            }}
            className={styles.button}
          >
            אישור
          </div>
        </div>
      </div>
    </div>
  );
}

export default PriceList;
