import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import React, {useEffect, useState} from "react";
import styles from "./changeSupplierBusiness.module.scss";
import {styled} from "@mui/material/styles";
import arrowTop from "./img/arrowTop.png";
import download from "./img/download_active.svg";
import {useNavigate} from "react-router";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import classNames from "classnames";
import {Controller, useForm} from "react-hook-form";
// import axios from "../../../../api/axios";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import {useDispatch} from "react-redux";
import {setErr} from "../../../store/forAll/errSlice";
import useAuth from "../../../hooks/useAuth";
import dayjs from "dayjs";
import he from "dayjs/locale/he"; // load on demand
import localizedFormat from "dayjs/plugin/localizedFormat";
import MultiSelect from "../../../components/multiSelectComponent/MultiSelect";
import Select from "../../../components/selectComponent/Select";
import shekelImg from "../../../../assets/images/icon-payment-ils.png";
import close from "../../../../assets/images/for-pages/icons/closeDialog.png";
import edit from "../../../../assets/images/for-pages/icons/edit.png";
import {
  deleteSupplierContract,
  getEvents,
  getKosher,
  getParkingOption,
  getRegions,
  getServices,
  getSupplierBusinessData,
  getSupplierBusinessVenueData,
  getVenueType,
  getWeekends,
  saveSupplierBusiness,
  saveSupplierContract,
  saveSupplierFlash,
  saveSupplierService,
  saveSupplierServiceCustom,
  saveSupplierVenueBusiness
} from "../../../store/personalSupplier/changeSupplierSlice";
import {Box} from "@mui/material";
import trash from "../../../../assets/images/trash.png";
import filePdf from "../../../../assets/images/for-pages/icons/pdf.png";
import LabelPremissions from "./LabelPremissions/LabelPremissions";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
  [theme.breakpoints.down("md")]: {
    margin: "0 0.875rem 0 0.875rem",
  },
  [theme.breakpoints.up("md")]: {
    margin: "0 3.5rem 0 3.5rem",
  },

  border: `none`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <div className={styles.mobielArrow} style={{height: "1.8125rem"}}>
        <img alt="arrow" src={arrowTop} />
      </div>
    }
    {...props}
  />
))(({theme}) => ({
  [theme.breakpoints.down("md")]: {
    height: "2.9375rem",
  },
  [theme.breakpoints.up("md")]: {
    height: "4.9rem",
  },
  padding: "1.6875rem 0 0 0",
  borderBottom: "1px solid rgba(112, 112, 112, .28)",

  backgroundColor: "#EEE9E5",
  // flexDirection: 'row-reverse',
  alignItems: "flex-end",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
    // marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
  padding: "0",
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  backgroundColor: "#EEE9E5",
  [theme.breakpoints.down("md")]: {
    paddingTop: "1rem",
  },
  [theme.breakpoints.up("md")]: {
    paddingTop: "1.75rem",
  },
}));

const ChangeSupplierBusiness = () => {
  // let [rulesKey, setRulesKey] = useState("12");
  // let [emailKey, setEmailKey] = useState("13");
  const isLabel = false;
  dayjs.locale(he);
  dayjs.extend(localizedFormat);
  const {auth} = useAuth();
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  let [stepNumber, setStepNumber] = useState(0);

  let [allEvents, setAllEvents] = useState([]);
  let [allCategories, setAllCategories] = useState([]);
  let [allServices, setAllServices] = useState({});
  // let [allVenueServices, setAllVenueServices] = useState([]);
  // let [services, setServices] = useState([]);
  let [allGeo, setAllGeo] = useState([]);
  let [curentCategory, setCurentCategory] = useState({});
  let [curentEvent, setCurentEvent] = useState({});
  let [isDisabled, setIsDisabled] = useState(true);
  let [venueData, setVenueData] = useState({});
  //from api
  let [weekends, setWeekends] = useState([]);
  let [venueType, setVenueType] = useState([]);
  let [kosher, setKosher] = useState([]);
  let [parkingOption, setParkingOption] = useState([]);
  let [openDialog, setOpenDialog] = useState(false);
  let currentRole = auth.roles[0];
  
  let [unicServices, setUnicServices] = useState([]);
  let currentSupId = JSON.parse(auth.user).id;
  const [editPermission, seteditPermission] = useState(currentRole !== 'LABEL' ? false : true);
  // let [isVenue, setIsVenue] = useState(false);
  // let [isAuth, setIsAuth] = useState(true);
  const handleChange = (panel) => (event, newExpanded) => {
    setStepNumber(newExpanded ? panel : false);
  };

  const schema = currentRole !== 'LABEL' ? yup.object().shape({
    businessName: yup.string().required("שדה חובה"),
    geoLocationsId: yup.array().nullable(true),
    contractBusinessName: yup.string().nullable(true),
    companyRegistrationNumber: yup.lazy((value) =>
      value === ""
        ? yup.string()
        : yup.number().typeError("רק ספרות").nullable(true)
    ),
    prePayment: yup.number().min(1, "רק ספרות").typeError("רק ספרות"),
    businessDescription: isLabel
      ? yup.string()
      : yup.string().required("שדה חובה"),
  }) :
  yup.object().shape({
    businessName: yup.string().required("שדה חובה"),
    geoLocationsId: yup.array().nullable(true),
    contractBusinessName: yup.string().nullable(true),
    companyRegistrationNumber: yup.lazy((value) =>
      value === ""
        ? yup.string()
        : yup.number().typeError("רק ספרות").nullable(true)
    ),
    prePayment: yup.number().min(1, "רק ספרות").typeError("רק ספרות"),
  })
  ;

  const methods = useForm({
    mode: "all",
    // defaultValues: {
    //   name: '',
    //   // type: {id:'1', name: 'Supplier', translate: t('SUPPLIER')},
    // },
    resolver: yupResolver(schema),
  });

  const {control, formState, register, watch, setValue, getValues} = methods;
  const {errors} = formState;
  let watcher = watch("contractRequired");
  let watcher1 = watch("hasDocument");
  let watcher2 = watch("documentLoaded");

  const countWords = (str) => {
    if (!str) return 300;
    return (
      300 -
      str?.split(" ").filter((num) => {
        return num !== "";
      }).length
    );
  };

  function removeSpaces(str) {
    // console.log(str)
    if (!str && str !== "") return;
    let newStr = str.replace(/\s+/g, " ").trim();
    return newStr;
  }
  // console.log('errors', formState.errors);
  const saveMainPart = () => {
    // console.log(12341);
    let newData = {...getValues()};
    setValue("businessName", getValues().businessName, {
      shouldValidate: true,
    });
    setValue("geoLocationsId", getValues().geoLocationsId, {
      shouldValidate: true,
    });
    setValue("businessAddress", getValues().businessAddress, {
      shouldValidate: true,
    });
    setValue("contractBusinessName", getValues().contractBusinessName, {
      shouldValidate: true,
    });
    setValue(
      "companyRegistrationNumber",
      getValues().companyRegistrationNumber,
      {shouldValidate: true}
    );
    setValue("prePayment", getValues().prePayment, {shouldValidate: true});
    setValue("businessDescription", getValues().businessDescription, {
      shouldValidate: true,
    });
    // setValue("contractRequired", getValues().contractRequired, {
    //   shouldValidate: true,
    // });
    // console.log(data1)
    // console.log('errors', formState.errors);
    // console.log(Object.keys(formState.errors).length);
    if (Object.keys(formState.errors).length) return;
    // if (!formState.isValid) return;
    let newGeoLocationsId = getValues().geoLocationsId?.map((location) => {
      return location.id;
    });
    newData.contractRequired = !!getValues().contractRequired;
    newData.geoLocationsId = newGeoLocationsId;
    if(!newData.documentLoaded && newData.hasDocument === '1'){
      dispatch(
        setErr({
          message: 'יש להעלות מסמך חוזה',
          type: 1,
        })
      );
    } else{
      dispatch(saveSupplierBusiness({axiosPrivate, data1: newData})).then(
        (res) => {
          if (res?.error) {
            dispatch(
              setErr({
                message: res?.payload?.response?.data?.message,
                code: res?.payload?.response?.data?.code,
              })
            );
          } else {
            dispatch(
              setErr({
                message: "השינויים נשמרו",
                type: 2,
              })
            );
            //update info
          }
        }
      );
    }
  };

  const getMainData = () => {
    dispatch(getSupplierBusinessData({axiosPrivate})).then((res) => {
      if (res?.error) {
        dispatch(
          setErr({
            message: res?.payload?.response?.data?.message,
            code: res?.payload?.response?.data?.code,
          })
        );
      } else {
        // {
        //   "businessName": "string",
        //   "geoLocationsId": [
        //     0
        //   ],
        //   "businessAddress": "string",
        //   "contractBusinessName": "string",
        //   "companyRegistrationNumber": "string",
        //   "businessDescription": "string",
        //   "contractRequired": true
        // }
        const {
          businessName,
          businessAddress,
          categories,
          contractBusinessName,
          companyRegistrationNumber,
          prePayment,
          hasDocument,
          documentLoaded,
          businessDescription,
          contractRequired,
          geoLocations,
          documentName
        } = res?.payload;
        setValue("businessName", businessName);
        setValue("businessAddress", businessAddress);
        setValue("contractBusinessName", contractBusinessName);
        setValue("companyRegistrationNumber", companyRegistrationNumber);
        setValue("prePayment", prePayment);
        setValue("documentLoaded", documentLoaded);
        setValue("documentName", documentName);
        setValue("hasDocument", hasDocument ? "1" : "");
        setValue("businessDescription", businessDescription);
        setValue("contractRequired", contractRequired);
        setAllCategories(categories || []);
        if (categories?.length) {
          setCurentCategory(categories[0]);
          getEventsByCurentCategory(categories[0].id);
          setCurentEvent({});
        }
        if (geoLocations?.length) {
          setValue("geoLocationsId", geoLocations);
        }
      }
    });
  };

  // const calcBetween1_100 = (number) => {
  //   if(typeof number !=="number") return number
  //   if(number > 100)return 100;
  //   if(number < 0)return 0;
  //   return number
  // }

  const uploadContract = (contract, name = "") => {
    dispatch(saveSupplierContract({axiosPrivate, contract})).then((res) => {
      if (res?.error) {
        dispatch(
          setErr({
            message: res?.payload?.response?.data?.message,
            code: res?.payload?.response?.data?.code,
          })
        );
      } else {
        // console.log(name);
        setValue("documentLoaded", true);
        setValue("documentName", name);
      }
    });
  };

  const getEventsByCurentCategory = (id) => {
    if (!curentCategory?.id && !id) return;
    dispatch(
      getEvents({categoryId: id ? id : curentCategory?.id, axiosPrivate})
    ).then((res) => {
      if (res?.error) {
        dispatch(
          setErr({
            message: res?.payload?.response?.data?.message,
            code: res?.payload?.response?.data?.code,
          })
        );
      } else {
        setAllEvents(res.payload);
      }
    });
  };

  const getAllServices = (event) => {
    if (curentCategory.id === 1) return;
    dispatch(
      getServices({
        eventTypeId: event?.id,
        axiosPrivate,
        categoryId: curentCategory.id,
      })
    ).then((res) => {
      if (res?.error) {
        dispatch(
          setErr({
            message: res?.payload?.response?.data?.message,
            code: res?.payload?.response?.data?.code,
          })
        );
      } else {
        let newServices = {...res.payload};
        newServices.services = res.payload?.services?.map((service) => {
          let newService = {...service};
          newService.disabled = true;
          return newService;
        });
        newServices.disabled = true;
        // console.log(res);
        setAllServices(newServices);
      }
    });
  };

  const saveFlash = () => {
    // {
    //   "categoryId": 0,
    //   "categoryName": "string",
    //   "eventId": 0,
    //   "eventName": "string",
    //   "flashEvent": true,
    //   "discount": 0,
    //   "services": [
    //     {
    //       "id": 0,
    //       "name": "string",
    //       "price": 0,
    //       "priceWithTax": 0
    //     }
    //   ]
    // }
    let data1 = {
      eventTypeId: allServices.eventId,
      categoryId: allServices.categoryId,
      discount: allServices.discount,
    };
    let newAllServ = {...allServices};
    newAllServ.disabled = true;

    setAllServices(newAllServ);
    dispatch(saveSupplierFlash({data1, axiosPrivate})).then((res) => {
      if (res?.error) {
        dispatch(
          setErr({
            message: res?.payload?.response?.data?.message,
            code: res?.payload?.response?.data?.code,
          })
        );
      } else {
        dispatch(
          setErr({
            message: "השינויים נשמרו",
            type: 2,
          })
        );
        // console.log(res);
      }
    });
  };

  const saveService = (serviceId) => {
    // {
    //   "categoryId": 0,
    //   "categoryName": "string",
    //   "eventId": 0,
    //   "eventName": "string",
    //   "flashEvent": true,
    //   "discount": 0,
    //   "services": [
    //     {
    //       "id": 0,
    //       "name": "string",
    //       "price": 0,
    //       "priceWithTax": 0
    //     }
    //   ]
    // }
    let data1 = [];

    if (!serviceId) {
      data1 = allServices.services.map((service) => {
        let newService = {
          serviceTypeId: service.id,
          eventTypeId: allServices.eventId,
          categoryId: allServices.categoryId,
        };
        newService.price = Math.round(Number(service.price)) || 0;
        newService.minPrice = Math.round(Number(service.minPrice)) || 0;
        newService.priceWithTax = Math.round(Number(service.priceWithTax)) || 0;
        newService.minPriceWithTax = Math.round(service.minPriceWithTax) || 0;

        return newService;
      });
      let err = data1?.find((item) => {
        return ((+item.price) < (+item.minPrice));
      });
      if (err) {
        // console.log(1);
        dispatch(
          setErr({
            message: "מספר לא חוקי",
          })
        );
        return;
      }
      saveFlash();
    } else {
      let data2 = {
        serviceTypeId: serviceId,
        eventTypeId: allServices.eventId,
        categoryId: allServices.categoryId,
      };

      let oneService = allServices.services?.find((item) => {
        return item.id === serviceId;
      });
      let err = !!!((+oneService?.price) >= (+oneService?.minPrice));
      if (err) {
        // console.log(2);
        dispatch(
          setErr({
            message: "מספר לא חוקי",
          })
        );
        return;
      }

      let newServices = {...allServices};
      newServices.services = allServices.services.map((service) => {
        let newService = {...service};
        if (newService.id === serviceId) {
          data2.price = Math.round(Number(service.price)) || 0;
          data2.minPrice = Math.round(Number(service.minPrice)) || 0;
          data2.priceWithTax = Math.round(Number(service.priceWithTax)) || 0;
          data2.minPriceWithTax = Math.round(Number(service.minPriceWithTax)) || 0;
          newService.disabled = true;
        }
        return newService;
      });
      setAllServices(newServices);

      data1 = [data2];
    }

    dispatch(saveSupplierService({data1, axiosPrivate})).then((res) => {
      if (res?.error) {
        dispatch(
          setErr({
            message: res?.payload?.response?.data?.message,
            code: res?.payload?.response?.data?.code,
          })
        );
      } else {
        dispatch(
          setErr({
            message: "השינויים נשמרו",
            type: 2,
          })
        );
        // console.log(res);
      }
    });
  };

  const updateService = (serviceId) => {
    let newServices = {...allServices};
    // newServices.disabled = true;
    newServices.services = allServices.services.map((service) => {
      let newService = {...service};
      if (newService.id === serviceId) {
        newService.disabled = !newService.disabled;
      }
      return newService;
    });
    setAllServices(newServices);
  };

  const updateFlash = () => {
    let newServices = {...allServices};
    newServices.disabled = !newServices.disabled;
    setAllServices(newServices);
  };

  const changeInput = (value, serviceId) => {
      let newServices = {...allServices};
      newServices.services = allServices.services.map((service) => {
        let newService = {...service};
        if (newService.id === serviceId) {
          if(String(value).match(/^\d+$/)!==null || String(value) === ''){
            newService.price = value;
            newService.priceWithTax = Math.round((value * 1.17));
          }else{
          newService.price = Number(value.replace(/[^0-9\s]/gi, ' '));
          newService.priceWithTax = Math.round((Number(value.replace(/[^0-9\s]/gi, ' ')) * 1.17));
          }
        }
        return newService;
      });
      setAllServices(newServices);
      // console.log(serviceId);
  };

  const changeInput1 = (value, serviceId) => {
    let newServices = {...allServices};
    newServices.services = allServices.services.map((service) => {
      let newService = {...service};
      if (newService.id === serviceId) {
        if(String(value).match(/^\d+$/)!==null || String(value) === ''){
          newService.minPrice = value;
          newService.minPriceWithTax = (value * 1.17).toFixed(2);
        } else {
          newService.minPrice = Number(value.replace(/[^0-9\s]/gi, ' '));
          newService.minPriceWithTax = Math.round((Number(value.replace(/[^0-9\s]/gi, ' ')) * 1.17));
        }
      }
      return newService;
    });
    setAllServices(newServices);
    // console.log(serviceId);
  };

  const changeFlashInput = (value, serviceId) => {
    let newServices = {...allServices};
    newServices.discount = (+value > 100) ? 100 : value;
    setAllServices(newServices);
    // console.log(serviceId);
  };

  const getFiltersData = () => {
    dispatch(getKosher()).then((res) => {
      if (res?.error) {
        dispatch(
          setErr({
            message: res?.payload?.response?.data?.message,
            code: res?.payload?.response?.data?.code,
          })
        );
      } else {
        setKosher(res.payload);
        // console.log(res);
      }
    });
    dispatch(getParkingOption()).then((res) => {
      if (res?.error) {
        dispatch(
          setErr({
            message: res?.payload?.response?.data?.message,
            code: res?.payload?.response?.data?.code,
          })
        );
      } else {
        setParkingOption(res.payload);
        // console.log(res);
      }
    });
    dispatch(getWeekends()).then((res) => {
      if (res?.error) {
        dispatch(
          setErr({
            message: res?.payload?.response?.data?.message,
            code: res?.payload?.response?.data?.code,
          })
        );
      } else {
        setWeekends(res.payload);
      }
    });
    dispatch(getVenueType()).then((res) => {
      if (res?.error) {
        dispatch(
          setErr({
            message: res?.payload?.response?.data?.message,
            code: res?.payload?.response?.data?.code,
          })
        );
      } else {
        setVenueType(res.payload);
        // console.log(res);
      }
    });
  };

  const getVenueData = (option) => {
    // console.log(curentCategory?.id, option?.id);
    if (curentCategory?.id !== 1 && option?.id !== 0) return;
    dispatch(
      getSupplierBusinessVenueData({
        eventTypeId: option?.id ? option?.id : curentEvent?.id,
        axiosPrivate,
      })
    ).then((res) => {
      if (res?.error) {
        dispatch(
          setErr({
            message: res?.payload?.response?.data?.message,
            code: res?.payload?.response?.data?.code,
          })
        );
      } else {
        setVenueData(res.payload);
        // console.log(res);
      }
    });
    // {
    //   "venueId": 0,
    //   "venueName": "string",
    //   "eventTypeId": 0,
    //   "priceRangeFrom": 0,1
    //   "priceRangeTo": 0,1
    //   "amountOfGuestsFrom": 0,2
    //   "amountOfGueststo": 0,2
    //   "weekendEventId": 0,3
    //   "venueTypeId": 0,4
    //   "kosherId": 0,
    //   "menuTypeId": 0,5
    //   "parkingOptionId": 0,6
    //   "flashEvent": true,
    //   "discount": 0
    // }
    let res = {};
    setVenueData(res);
  };

  const setField = (option, number) => {
    let newVenueData = {...venueData};
    switch (number) {
      case 1:
        newVenueData.priceRangeFrom = Number(option.replace (/\D/, ''));
        break;
      case 11:
        newVenueData.priceRangeTo = Number(option.replace (/\D/, ''));
        break;
      case 2:
        newVenueData.amountOfGuestsFrom = Number(option.replace (/\D/, ''));
        break;
      case 22:
        newVenueData.amountOfGuestsTo = Number(option.replace (/\D/, ''));
        break;
      case 3:
        newVenueData.weekendEvent = option;
        break;
      case 4:
        newVenueData.venueType = option;
        break;
      case 5:
        newVenueData.kosher = option;
        break;
      case 6:
        newVenueData.parkingOption = option;
        break;
      case 7:
        newVenueData.discount = option;
        break;
      case 8:
        newVenueData.flashEvent = option;
        break;
      default:
    }

    setVenueData(newVenueData);
  };

  const saveField = (number, value) => {
    if (!curentEvent?.id) return;
    let sendObj = {eventTypeId: curentEvent.id};

    switch (number) {
      case 1:
        sendObj.priceRangeFrom = venueData.priceRangeFrom;
        sendObj.priceRangeTo = venueData.priceRangeTo;
        break;
      case 2:
        sendObj.amountOfGuestsFrom = venueData.amountOfGuestsFrom;
        sendObj.amountOfGuestsTo = venueData.amountOfGuestsTo;
        break;
      case 3:
        sendObj.weekendEventId = venueData.weekendEvent.id;

        break;
      case 4:
        sendObj.venueTypeId = venueData.venueType.id;

        break;
      case 5:
        sendObj.kosherId = venueData.kosher.id;

        break;
      case 6:
        sendObj.parkingOptionId = venueData.parkingOption.id;
        break;
      case 7:
        sendObj.discount = value ? value : "0";
        sendObj.flashEvent = venueData.flashEvent;
        setField(value ? value : "0", number);
        break;
      case 8:
        sendObj.flashEvent = !venueData.flashEvent;
        sendObj.discount = venueData.discount;
        setField(!venueData.flashEvent, number);
        break;
      case 9:
        sendObj.priceRangeFrom = venueData.priceRangeFrom;
        sendObj.priceRangeTo = venueData.priceRangeTo;
        sendObj.amountOfGuestsFrom = venueData.amountOfGuestsFrom;
        sendObj.amountOfGuestsTo = venueData.amountOfGuestsTo;
        sendObj.weekendEventId = venueData.weekendEvent.id;
        sendObj.venueTypeId = venueData.venueType.id;
        sendObj.kosherId = venueData.kosher.id;
        sendObj.parkingOptionId = venueData.parkingOption.id;
        // for(let i = 1 ; i < 7; i++){
        //   saveField(i);
        // };
        break;
      default:
    }

    if (
      (number === 1 && sendObj?.priceRangeTo < sendObj?.priceRangeFrom) ||
      (number === 2 &&
        sendObj?.amountOfGuestsTo < sendObj?.amountOfGuestsFrom) ||
      (number === 9 &&
        (sendObj?.priceRangeTo < sendObj?.priceRangeFrom ||
          sendObj?.amountOfGuestsTo < sendObj?.amountOfGuestsFrom))
    ) {
      dispatch(
        setErr({
          message: "מספר לא חוקי",
        })
      );
      return;
    }

    dispatch(saveSupplierVenueBusiness({sendObj, axiosPrivate})).then((res) => {
      if (res?.error) {
        dispatch(
          setErr({
            message: res?.payload?.response?.data?.message,
            code: res?.payload?.response?.data?.code,
          })
        );
      } else {
        dispatch(
          setErr({
            message: "השינויים נשמרו",
            type: 2,
          })
        );
      }
    });

    // {
    //   "eventTypeId": 0,
    //   "priceRangeId": 0,
    //   "amountOfGuestsId": 0,
    //   "weekendEventId": 0,
    //   "venueTypeId": 0,
    //   "kosherId": 0,
    //   "menuTypeId": 0,
    //   "parkingOptionId": 0,
    //   "flashEvent": true,
    //   "discount": 0
    // }
  };

  function getId(arr) {
    //last elem id + 1
    return arr[arr.length - 1]?.id + 1 || 1;
  }

  const addFields = () => {
    if (curentCategory.id && curentEvent.id) {
      let actualState = [...unicServices];
      actualState.push({
        id: getId(actualState),
        categoryId: curentCategory?.id,
        eventTypeId: curentEvent?.id,
        serviceTypeName: "",
        price: 0,
        priceWithTax: 0,
        minPrice: 0,
        minPriceWithTax: 0,
        isSaved: false,
        error: null,
      });
      setUnicServices(actualState);
    }
  };

  const changeUnicName = (id, value) => {
    let newUnicServices = [...unicServices].map((service) => {
      let newServ = {...service};
      if (newServ.id === id) {
        newServ.serviceTypeName = value;
      }
      return newServ;
    });
    setUnicServices(newUnicServices);
  };

  const saveUnic = (id) => {
    let serv = {
      ...unicServices?.find((item) => {
        return item.id === id;
      }),
    };
    if (!serv?.serviceTypeName || !serv?.price) return;
    if (curentCategory?.id === 2 && !serv?.minPrice) return;
    if (curentCategory?.id === 2 && !(+serv?.price >= +serv?.minPrice)) return;
    // console.log("save");
    serv.serviceTypeName = removeSpaces(serv.serviceTypeName);

    // console.log(newUnicServices);
    // setUnicServices(newUnicServices)
    dispatch(saveSupplierServiceCustom({data1: [serv], axiosPrivate})).then(
      (res) => {
        if (res?.error) {
          dispatch(
            setErr({
              message: res?.payload?.response?.data?.message,
              code: res?.payload?.response?.data?.code,
            })
          );
        } else {
          dispatch(
            setErr({
              message: "השינויים נשמרו",
              type: 2,
            })
          );
          getAllServices(curentEvent);
          let newArr = unicServices.filter((item) => {
            return item.id !== id;
          });
          setUnicServices([...newArr]);
        }
      }
    );
  };

  const saveAllUnicServices = () => {
    let newServices = unicServices?.filter((service) => {
      return service.price && service.serviceTypeName;
    });
    if (!newServices?.length) return;
    // let serv = {
    //   ...unicServices?.find((item) => {
    //     return item.id === id;
    //   }),
    // };
    // console.log(serv);
    // if (!serv?.serviceTypeName || !serv?.price) return;
    let error = !!newServices.find((serv) => {
      return ((+serv.price) < (+serv.minPrice));
    });
    if (error) {
      dispatch(
        setErr({
          message: "מספר לא חוקי",
        })
      );
      return;
    }
    newServices = newServices.map((item) => {
      let newItem = {...item};
      newItem.serviceTypeName = removeSpaces(item.serviceTypeName);
      return newItem;
    });

    // serv.serviceTypeName = removeSpaces(serv.serviceTypeName);

    // console.log(newUnicServices);
    // setUnicServices(newUnicServices)
    dispatch(
      saveSupplierServiceCustom({data1: newServices, axiosPrivate})
    ).then((res) => {
      if (res?.error) {
        dispatch(
          setErr({
            message: res?.payload?.response?.data?.message,
            code: res?.payload?.response?.data?.code,
          })
        );
      } else {
        dispatch(
          setErr({
            message: "השינויים נשמרו",
            type: 2,
          })
        );
        getAllServices(curentEvent);
        setUnicServices([]);
      }
    });
  };

  const changeUnicPrice = (id, val) => {
    let value = +val;
    // console.log(typeof value);
    if (typeof value !== "number" || isNaN(value)) return;
    let newUnicServices = [...unicServices].map((service) => {
      let newServ = {...service};
      if (newServ.id === id) {
        newServ.price = value;
        newServ.priceWithTax = (value * 1.17).toFixed(2);
      }
      return newServ;
    });
    setUnicServices(newUnicServices);
  };

  const changeUnicMinPrice = (id, val) => {
    let value = +val;
    // console.log(typeof value);
    if (typeof value !== "number" || isNaN(value)) return;
    let newUnicServices = [...unicServices].map((service) => {
      let newServ = {...service};
      if (newServ.id === id) {
        newServ.minPrice = value;
        newServ.minPriceWithTax = (value * 1.17).toFixed(2);
      }
      return newServ;
    });
    setUnicServices(newUnicServices);
  };

  const removeContractFile = () => {
    dispatch(deleteSupplierContract({axiosPrivate})).then((res) => {
      if (res?.error) {
        dispatch(
          setErr({
            message: res?.payload?.response?.data?.message,
            code: res?.payload?.response?.data?.code,
          })
        );
      } else {
        setValue("documentLoaded", false);
        setValue("documentName", "");
      }
    });
  };

  useEffect(() => {
    dispatch(getRegions()).then((res) => {
      if (res?.error) {
        dispatch(
          setErr({
            message: res?.payload?.response?.data?.message,
            code: res?.payload?.response?.data?.code,
          })
        );
      } else {
        // console.log(res);
        setAllGeo(res.payload);
      }
    });

    getMainData();
    getFiltersData();
    if (curentCategory.id === 1) {
      getVenueData();
    }
    if(currentRole !== 'LABEL'){
      checkPermission();
    }

    //   dispatch(getContracts({axiosPrivate})).then((res) => {
    //     if(res?.error){
    //       dispatch(setErr({
    //         message: res?.payload?.response?.data?.message,
    //         code: res?.payload?.response?.data?.code
    //       }))
    //     } else{
    //       console.log(res)
    //       setContracts(res.payload)
    //     }
    //   })
    //   dispatch(getUserData({axiosPrivate})).then((res) => {
    //     if(res?.error){
    //       dispatch(setErr({
    //         message: res?.payload?.response?.data?.message,
    //         code: res?.payload?.response?.data?.code
    //       }))
    //     } else{
    //       const {email, name, phoneNumber} = res?.payload
    //       setValue("email", email)
    //       setValue("name", name)
    //       setValue("phoneNumber",phoneNumber)
    //     }
    //   })
    //

  //  return ()=> formState.errors = null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkPermission = async () => {  
    let id = currentRole !== 'LABEL' ? currentSupId : null;
    let request;
    if(currentRole !== 'LABEL'){
      request = await axiosPrivate.get(`${process.env.REACT_APP_API_PATH}supplier/check/optional/label?id=${id}`);
    } else {
      request = await axiosPrivate.get(`${process.env.REACT_APP_API_PATH}supplier/check/optional/label`);
    }
    const result = request;
    if(result){
      seteditPermission( currentRole === 'LABEL' ? result.data.isOptionProfile : result.data.isOptionProfile);
    }
  };

  const getPDF = async() => {
    try {
      const response = await axiosPrivate.get(`${process.env.REACT_APP_API_PATH}supplier/personal/document`,{responseType: 'blob'});
      const data = await response.data;
      // console.log('data', data);
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.pdf");
      // console.log('link', link)
      document.body.appendChild(link);
      link.click();
      if (response.status!==200) {
        throw new Error('Server Error');
      }
    } catch (error) {
      // console.log(error)
    }
  }

  return (
    <div className={styles.mainContainer}>
      <div className={styles.mobile}>
        <span className={styles.title}>{"פרטי העסק"}</span>
      </div>

      <div
        style={{paddingBottom: stepNumber === 0 ? "0" : ""}}
        className={styles.accordion}
      >
        <Accordion expanded={stepNumber === 0} onChange={handleChange(0)}>
          <AccordionSummary aria-controls="0d-content" id="0d-header">
            <div className={styles.tabTitle}>{"עדכון פרופיל עסק"}</div>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <form noValidate className={styles.form}>
                <div className={styles.doubleFields}>
                  <div className={styles.inputContainer}>
                    <Controller
                      name="businessName"
                      control={control}
                      render={({field: {onChange, value}}) => (
                        <input
                          // {...field}
                          className={classNames({
                            [styles.invalidField]: errors.businessName,
                          })}
                          error={!!errors.businessName}
                          required
                          placeholder="שם העסק"
                          value={value ? value : ""}
                          onBlur={(event, newValue) => {
                            onChange(
                              removeSpaces(event.target.value, newValue)
                            );
                          }}
                          onChange={(event, newValue) => {
                            onChange(event.target.value);
                          }}
                          id="businessName"
                        />
                      )}
                    />
                    {errors.businessName && (
                      <p className={styles.errorMessage}>
                        {errors.businessName.message}
                      </p>
                    )}
                  </div>
                  <div className={styles.inputContainer}>
                    <Controller
                      name="geoLocationsId"
                      control={control}
                      render={({field: {onChange, value}}) => (
                        <MultiSelect
                          invalid={errors.geoLocationsId}
                          options={
                            [...allGeo]
                            //   [
                            //   { id: 1, name: "first" },
                            //   { id: 2, name: "second" },
                            //   { id: 3, name: "third" },
                            //   { id: 4, name: "fourth" },
                            // ]
                          }
                          onChangeOption={(e) => {
                            // onChangeLocation(e);
                            onChange(e);
                          }}
                          // selectedOptions={selectedLocations}
                          selectedOptions={value}
                          fontFamily={"Heebo-Medium"}
                          placeholder={"איזור עבודה"}
                          borderRadius={"0.31rem"}
                          paddingInlineStart={"1.75rem"}
                          paddingInlineEnd={"1.33rem"}
                          backgroundColor={"white"}
                          color={"#242323"}
                          fontSize={"1rem"}
                        />
                      )}
                    />
                    {errors.geoLocationsId && (
                      <p className={styles.errorMessage}>
                        {errors.geoLocationsId.message}
                      </p>
                    )}
                  </div>
                </div>
                {/* <div className={styles.full}> */}
                <div style={{width: "100%"}} className={styles.inputContainer}>
                  <Controller
                    name="businessAddress"
                    control={control}
                    render={({field: {onChange, value}}) => (
                      <>
                        <input
                          // {...field}
                          className={classNames({
                            [styles.invalidField]: errors.businessAddress,
                          })}
                          error={!!errors.businessAddress}
                          // required
                          placeholder="כתובת בית העסק (רחוב, מספר, עיר)" // adress by law
                          value={value ? value : ""}
                          onBlur={(event, newValue) => {
                            onChange(
                              removeSpaces(event.target.value, newValue)
                            );
                          }}
                          onChange={(event, newValue) => {
                            onChange(event.target.value);
                          }}
                          id="businessAddress"
                        />
                      </>
                    )}
                  />
                  {errors.businessAddress && (
                    <p className={styles.errorMessage}>
                      {errors.businessAddress.message}
                    </p>
                  )}
                </div>
                {/* </div> */}
                <div className={styles.doubleFields}>
                  <div className={styles.inputContainer}>
                    <Controller
                      name="contractBusinessName"
                      control={control}
                      render={({field: {onChange, value}}) => (
                        <input
                          // {...field}
                          className={classNames({
                            [styles.invalidField]: errors.contractBusinessName,
                          })}
                          placeholder="שם לחשבונית"
                          error={!!errors.contractBusinessName}
                          // required
                          value={value ? value : ""}
                          onBlur={(event, newValue) => {
                            onChange(
                              removeSpaces(event.target.value, newValue)
                            );
                          }}
                          onChange={(event, newValue) => {
                            onChange(event.target.value);
                          }}
                          id="contractBusinessName"
                        />
                      )}
                    />
                    {errors.contractBusinessName && (
                      <p className={styles.errorMessage}>
                        {errors.contractBusinessName.message}
                      </p>
                    )}
                  </div>
                  <div className={styles.inputContainer}>
                    <Controller
                      name="companyRegistrationNumber"
                      control={control}
                      render={({field: {onChange, value}}) => (
                        <input
                          // {...field}
                          type={"number"}
                          className={classNames({
                            [styles.invalidField]:
                              errors.companyRegistrationNumber,
                          })}
                          placeholder="ח.פ \ עוסק מורשה"
                          error={!!errors.companyRegistrationNumber}
                          // required
                          value={value ? value : ""}
                          onBlur={(event, newValue) => {
                            onChange(
                              removeSpaces(event.target.value, newValue)
                            );
                          }}
                          onChange={(event, newValue) => {
                            onChange(event.target.value);
                          }}
                          id="companyRegistrationNumber"
                        />
                      )}
                    />
                    {errors.companyRegistrationNumber && (
                      <p className={styles.errorMessage}>
                        {errors.companyRegistrationNumber.message}
                      </p>
                    )}
                  </div>
                </div>

                {/* <div style={{position: "relative"}}>
                    <Controller
                      name="prePayment"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                       
                        
                        <div 
                        style={{backgroundColor: !!errors.prePayment ? "#f3e8e8": ""}}

                          className={classNames(styles.inputFlash1,{
                            [styles.invalidField]:
                              errors.prePayment,
                          })}>
                          <input
                        style={{backgroundColor: !!errors.prePayment ? "#f3e8e8": ""}}

                        pattern={"^[0-9][0-9]?$|^100$"}
                          // {...field}
                          type={"number"}
                          

                          placeholder="אחוז מקדמה"
                          error={!!errors.prePayment}
                          // required
                          value={value ? value : ""}
                          
                          onChange={(event, newValue) => {
                            onChange(calcBetween1_100(+event.target.value));
                          }}

                          onBlur={(event, newValue) => {
                            onChange(
                              removeSpaces(event.target.value, newValue)
                            );
                          }}
                          id="prePayment"
                        />
                          {"%"}
                        </div>
                       
                        
                      )}
                    />
                    {errors.prePayment && (
                      <p className={styles.errorMessage}>
                        {errors.prePayment.message}
                      </p>
                    )}
                  </div> */}

                {!!(auth?.roles[0] !== "LABEL") && (
                  <>
                    <div className={styles.catTitle}>{"התחום שלי"}</div>
                    <div className={styles.inputContainer}>
                      <Controller
                        name="category"
                        control={control}
                        render={({field: {onChange, value}}) => (
                          <Select
                            fontSize={"1rem"}
                            paddingInlineStart={"1.75rem"}
                            paddingInlineEnd={"1.33rem"}
                            placeholder={"תחום"}
                            width={"100%"}
                            borderRadius={"0.31rem"}
                            backgroundColor={"white"}
                            color={"#242323"}
                            options={
                              allCategories
                              //   [
                              //   { id: 1, name: "first" },
                              //   { id: 2, name: "second" },
                              //   { id: 3, name: "third" },
                              //   { id: 4, name: "fourth" },
                              // ]
                            }
                            onChangeOption={(option) => {
                              onChange(option?.name);
                              // console.log(errors)
                              setCurentCategory(option);
                              getEventsByCurentCategory(option?.id);
                              setCurentEvent({});
                            }}
                            selectedOption={curentCategory}
                          />
                        )}
                      />
                      {errors.category && (
                        <p className={styles.errorMessage}>
                          {errors.category.message}
                        </p>
                      )}
                    </div>
                    <div className={styles.textAreaContainer}>
                      <div className={styles.mobileDescription}>תיאור העסק</div>
                      <Controller
                        name="businessDescription"
                        control={control}
                        render={({field: {onChange, value}}) => (
                          <>
                            <textarea
                              // {...field}
                              className={classNames({
                                [styles.invalidField]:
                                  errors.businessDescription,
                              })}
                              error={!!errors.businessDescription}
                              required
                              placeholder="תאור העסק"
                              value={value}
                              type="text"
                              id="businessDescription"
                              variant="outlined"
                              onChange={(e) => {
                                if (countWords(e.target.value) <= 0) return;
                                return onChange(e.target.value);
                              }}
                            />
                            {!errors.businessDescription && (
                              <p
                                style={{bottom: "0.45rem", color: "black"}}
                                className={styles.errorMessage}
                              >
                                {countWords(value)}
                              </p>
                            )}
                          </>
                        )}
                      />

                      {errors.businessDescription && (
                        <p
                          style={{bottom: "0.45rem"}}
                          className={styles.errorMessage}
                        >
                          {errors.businessDescription.message}
                        </p>
                      )}
                    </div>
                  </>
                )}

                <div className={styles.underline}></div>
                <div className={styles.contracts}>
                  <div className={styles.contractsTitle}>
                    <span className={styles.first}>
                      {"האם במסגרת עבודתך את.ה מתנהל.ת עם לקוחותיך בעזרת חוזים"}
                      {"?"}
                    </span>
                    <span
                      className={styles.second}
                      onClick={() => {
                        setIsDisabled(false);
                      }}
                    >
                      {"שנה הגדרות"}
                    </span>
                  </div>
                  <div className={styles.radBtns}>
                    <div className={styles.doubleRadio}>
                      <div
                        className={classNames(styles.radio, styles.radio1)}
                        style={{
                          opacity: isDisabled && !!!watcher ? "0.17" : "",
                          pointerEvents: isDisabled && !!!watcher ? "none" : "",
                        }}
                      >
                        <input
                          // onClick={() => setRulesKey(Math.random())}
                          {...register("contractRequired")}
                          id="radioBusinessDetail-1"
                          type="radio"
                          // name="contractRequired"
                          checked={!!watcher}
                          value={1}
                        />
                        <label htmlFor="radioBusinessDetail-1">
                          כן, כל עסקה עם לקוח מתבצעת בכפוף לחתימת חוזה
                        </label>
                      </div>
                      {watcher && (
                        <div className={styles.radioOwnContract}>
                          <div
                            className={classNames(styles.radio, styles.radio1)}
                          >
                            <input
                              // onClick={() => setRulesKey(Math.random())}
                              {...register("hasDocument")}
                              id="hasDocument-1"
                              type="radio"
                              // name="contractRequired"
                              checked={!!watcher1}
                              value={1}
                              onClick={(e, value) => {
                                if (watcher1) {
                                  setValue("hasDocument", "");
                                } else {
                                  setValue("hasDocument", "1");
                                }
                              }}
                            />
                            <label htmlFor="hasDocument-1">
                              {"יש לי חוזה"}
                            </label>
                          </div>
                          {!!watcher1 && !watcher2 && (
                            <Box style={{cursor: editPermission ? "pointer" : 'default'}}
                              className={styles.loadContract}
                              component="label"
                            >
                              {"טען חוזה"}
                              {editPermission && <input
                                className={styles.uploadImg}
                                id="button-file"
                                name="logoFile"
                                type="file"
                                accept="application/pdf"
                                onChange={(e) => {
                                  function readFileAsync(index = 0) {
                                    return new Promise((resolve, reject) => {
                                      const file = e.target.files[index];
                                      if (!file) {
                                        return;
                                      }
                                      const reader = new FileReader();

                                      reader.onload = () => {
                                        if (file?.size < 15728640) {
                                          resolve({
                                            id: file?.size || 911,
                                            name: file?.name,
                                            url: `data:${
                                              file.type
                                            };base64,${btoa(reader.result)}`,
                                            file: e.target.files[index],
                                          });
                                        } else {
                                        }
                                      };

                                      reader.onerror = reject;

                                      reader.readAsBinaryString(file);
                                    });
                                  }

                                  readFileAsync().then((item) => {
                                    // console.log(item);
                                    uploadContract(
                                      item,
                                      item?.file?.name || ""
                                    );
                                    return item;
                                  });
                                  // addImage(await newImg, indexCell)
                                }}
                                hidden
                              />}
                            </Box>
                          )}
                          {!!watcher1 && watcher2 && (
                            <div className={styles.showDeleteContract}>
                              <div className={styles.contractName}>
                                {getValues("documentName") || ""}
                              </div>
                              <img src={download}  
                                style={{cursor:  editPermission ? "pointer" : 'default'}}
                                
                                onClick={() => editPermission ?  getPDF() : ''} 
                                alt="download" 
                              />
                              <img src={filePdf} alt="filePdf" />
                              <img
                                style={{cursor: editPermission ? "pointer" : 'default'}}
                                onClick={() => editPermission ? removeContractFile() : ''}
                                src={trash}
                                alt="trash"
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>

                    <div
                      className={classNames(styles.radio, styles.radio2)}
                      style={{
                        opacity: isDisabled && !!watcher ? "0.17" : "",
                        pointerEvents: isDisabled && !!watcher ? "none" : "",
                      }}
                    >
                      <input
                        onClick={() => setValue("hasDocument", "")}
                        {...register("contractRequired")}
                        id="radioBusinessDetail-2"
                        type="radio"
                        // name="contractRequired"
                        checked={!!!watcher}
                        value={""}
                      />
                      <label htmlFor="radioBusinessDetail-2">
                        לא, איני מתנהל עם חוזים מול הלקוחות שלי
                      </label>
                    </div>
                  </div>
                </div>
                {stepNumber === 0 && (
                  <div style={{cursor: editPermission ? "pointer" : 'default'}} className={classNames(styles.buttons, styles.mobile)}>
                    <div onClick={() => editPermission ? saveMainPart() : ''} className={styles.save}>
                      {"שמירת שינויים"}
                    </div>
                  </div>
                )}
              </form>
            </div>
          </AccordionDetails>
        </Accordion>
        {currentRole !== 'LABEL' && <Accordion expanded={stepNumber === 1} onChange={handleChange(1)}>
          <AccordionSummary aria-controls="1d-content" id="1d-header">
            <div className={styles.tabTitle}>{"עדכון מחירון"}</div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={styles.desctop}>
              <div className={styles.eventsContainer}>
                <Controller
                  name="category"
                  control={control}
                  render={({field: {onChange, value}}) => (
                    <Select
                      fontSize={"1rem"}
                      paddingInlineStart={"1.75rem"}
                      paddingInlineEnd={"1.33rem"}
                      placeholder={"סוג אירוע"}
                      width={"100%"}
                      borderRadius={"0.31rem"}
                      backgroundColor={"white"}
                      color={"#242323"}
                      options={
                        allEvents
                        // [
                        //   { id: 1, name: "first" },
                        //   { id: 2, name: "second" },
                        //   { id: 3, name: "third" },
                        //   { id: 4, name: "fourth" },
                        // ]
                      }
                      onChangeOption={(option) => {
                        onChange(option?.name);
                        // console.log(errors)
                        setCurentEvent(option);
                        getAllServices(option);
                        getVenueData(option);
                      }}
                      selectedOption={curentEvent}
                    />
                  )}
                />
                {errors.category && (
                  <p className={styles.errorMessage}>
                    {errors.category.message}
                  </p>
                )}
              </div>
              {curentCategory?.id === 1 && curentEvent?.id ? (
                <div className={styles.tableVenueContainer}>
                  <div className={styles.tableVenue}>
                    <div style={{height: "3.0625rem"}} className={styles.row1}>
                      <div className={styles.spacebetween}>
                        <div className={styles.tableVenueTitles}>
                          {"טווח מחירים (עלות אורח באירוע)"}
                          <div>{"עבור הלקוח יוצגו המחירים כולל מע״מ"}</div>
                        </div>
                        <div className={styles.priceRange}>
                          <div className={styles.inp}>
                            <input
                              onChange={(e) => {
                                setField(e.target.value, 11);
                              }}
                              value={venueData.priceRangeTo}
                            />
                            <img src={shekelImg} alt="shekel" />
                            {!(
                              +venueData?.priceRangeTo >=
                              +venueData?.priceRangeFrom
                            ) && (
                              <p className={styles.errorMessage}>
                                {" "}
                                {"מספר לא חוקי"}
                              </p>
                            )}
                          </div>
                          {"-"}

                          <div className={styles.inp}>
                            <input
                              onChange={(e) => {
                                setField(e.target.value, 1);
                              }}
                              value={venueData.priceRangeFrom}
                            />
                            <img src={shekelImg} alt="shekel" />
                            {!(
                              +venueData?.priceRangeTo >=
                              +venueData?.priceRangeFrom
                            ) && (
                              <p className={styles.errorMessage}>
                                {" "}
                                {"מספר לא חוקי"}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div
                        className={styles.saveRow}
                        onClick={() => {
                          saveField(1);
                        }}
                      >
                        {"שמירת שינויים"}
                      </div>
                    </div>
                    <div className={styles.row2}>
                      <div className={styles.spacebetween}>
                        <div className={styles.tableVenueTitles}>
                          {"כמות אורחים"}
                        </div>
                        <div className={styles.guestsRange}>
                          <div className={styles.inp}>
                            <div className={styles.abbr}>{"מקסימום"}</div>
                            
                              <input
                                name
                                onChange={(e) => {
                                  setField(e.target.value, 22);
                                }}
                                value={venueData.amountOfGuestsTo}
                              /> 
                            {(!(
                              +venueData?.amountOfGuestsTo >=
                              +venueData?.amountOfGuestsFrom
                            )) && (
                              <p className={styles.errorMessage}>
                                {" "}
                                {"מספר לא חוקי"}
                              </p>
                            )}
                          </div>
                          {"-"}
                          <div className={styles.inp}>
                            <div className={styles.abbr}>{"מינימום"}</div>
                              <input
                                onChange={(e) => {
                                  setField(e.target.value, 2);
                                }}
                                value={venueData.amountOfGuestsFrom}
                              />
                            {(!(
                              +venueData?.amountOfGuestsTo >=
                              +venueData?.amountOfGuestsFrom
                            )) && (
                              <p className={styles.errorMessage}>
                                {" "}
                                {"מספר לא חוקי"}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div
                        className={styles.saveRow}
                        onClick={() => {
                          saveField(2);
                        }}
                      >
                        {"שמירת שינויים"}
                      </div>
                    </div>
                    <div className={styles.row3}>
                      <div className={styles.spacebetween}>
                        <div className={styles.tableVenueTitles}>
                          {"אירועים בסופי שבוע"}
                        </div>
                        <div className={styles.venueSelect}>
                          <Controller
                            name="field1"
                            control={control}
                            render={({field: {onChange, value}}) => (
                              <Select
                                fontSize={"1rem"}
                                paddingInlineStart={"1.75rem"}
                                paddingInlineEnd={"1.33rem"}
                                width={"100%"}
                                borderRadius={"0"}
                                backgroundColor={"white"}
                                color={"#242323"}
                                options={
                                  weekends
                                  // [
                                  //   { id: 1, name: "first" },
                                  //   { id: 2, name: "second" },
                                  //   { id: 3, name: "third" },
                                  //   { id: 4, name: "fourth" },
                                  // ]
                                }
                                onChangeOption={(option) => {
                                  onChange(option?.name);
                                  // console.log(errors)
                                  setField(option, 3);
                                }}
                                selectedOption={venueData.weekendEvent}
                              />
                            )}
                          />
                          {errors.field1 && (
                            <p className={styles.errorMessage}>
                              {errors.field1.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div
                        className={styles.saveRow}
                        onClick={() => {
                          saveField(3);
                        }}
                      >
                        {"שמירת שינויים"}
                      </div>
                    </div>
                    <div className={styles.row4}>
                      <div className={styles.spacebetween}>
                        <div className={styles.tableVenueTitles}>
                          {"סוג מתחם"}
                        </div>
                        <div className={styles.venueSelect}>
                          <Controller
                            name="field2"
                            control={control}
                            render={({field: {onChange, value}}) => (
                              <Select
                                fontSize={"1rem"}
                                paddingInlineStart={"1.75rem"}
                                paddingInlineEnd={"1.33rem"}
                                width={"100%"}
                                borderRadius={"0"}
                                backgroundColor={"white"}
                                color={"#242323"}
                                options={
                                  venueType
                                  // [
                                  //   { id: 1, name: "first" },
                                  //   { id: 2, name: "second" },
                                  //   { id: 3, name: "third" },
                                  //   { id: 4, name: "fourth" },
                                  // ]
                                }
                                onChangeOption={(option) => {
                                  onChange(option?.name);
                                  // console.log(errors)
                                  setField(option, 4);
                                }}
                                selectedOption={venueData.venueType}
                              />
                            )}
                          />
                          {errors.field2 && (
                            <p className={styles.errorMessage}>
                              {errors.field2.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div
                        className={styles.saveRow}
                        onClick={() => {
                          saveField(4);
                        }}
                      >
                        {"שמירת שינויים"}
                      </div>
                    </div>
                    <div className={styles.row5}>
                      <div className={styles.spacebetween}>
                        <div className={styles.tableVenueTitles}>
                          {"כשרות / סוג תפריט"}
                        </div>
                        <div className={styles.venueSelect}>
                          <Controller
                            name="field3"
                            control={control}
                            render={({field: {onChange, value}}) => (
                              <Select
                                fontSize={"1rem"}
                                paddingInlineStart={"1.75rem"}
                                paddingInlineEnd={"1.33rem"}
                                width={"100%"}
                                borderRadius={"0"}
                                backgroundColor={"white"}
                                color={"#242323"}
                                options={
                                  kosher
                                  // [
                                  //   { id: 1, name: "first" },
                                  //   { id: 2, name: "second" },
                                  //   { id: 3, name: "third" },
                                  //   { id: 4, name: "fourth" },
                                  // ]
                                }
                                onChangeOption={(option) => {
                                  onChange(option?.name);
                                  // console.log(errors)
                                  setField(option, 5);
                                }}
                                selectedOption={venueData.kosher}
                              />
                            )}
                          />
                          {errors.field3 && (
                            <p className={styles.errorMessage}>
                              {errors.field3.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div
                        className={styles.saveRow}
                        onClick={() => {
                          saveField(5);
                        }}
                      >
                        {"שמירת שינויים"}
                      </div>
                    </div>
                    <div className={styles.row6}>
                      <div className={styles.spacebetween}>
                        <div className={styles.tableVenueTitles}>
                          {"אפשרויות חניה"}
                        </div>
                        <div className={styles.venueSelect}>
                          <Controller
                            name="field4"
                            control={control}
                            render={({field: {onChange, value}}) => (
                              <Select
                                fontSize={"1rem"}
                                paddingInlineStart={"1.75rem"}
                                paddingInlineEnd={"1.33rem"}
                                width={"100%"}
                                borderRadius={"0"}
                                backgroundColor={"white"}
                                color={"#242323"}
                                options={
                                  parkingOption
                                  // [
                                  //   { id: 1, name: "first" },
                                  //   { id: 2, name: "second" },
                                  //   { id: 3, name: "third" },
                                  //   { id: 4, name: "fourth" },
                                  // ]
                                }
                                onChangeOption={(option) => {
                                  onChange(option?.name);
                                  // console.log(errors)
                                  setField(option, 6);
                                }}
                                selectedOption={venueData.parkingOption}
                              />
                            )}
                          />
                          {errors.field4 && (
                            <p className={styles.errorMessage}>
                              {errors.field4.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div
                        className={styles.saveRow}
                        onClick={() => {
                          saveField(6);
                        }}
                      >
                        {"שמירת שינויים"}
                      </div>
                    </div>
                  </div>
                  <div className={styles.flashVenue}>
                    <div className={styles.flashTitle}>
                      {"אירוע בזק - הזן אחוז הנחה עבור אירוע מסוג זה"}
                    </div>
                    <div className={styles.inputFlash}>
                      <input
                        value={venueData.discount || 0}
                        onChange={(e) => {
                          saveField(7, e.target.value);
                        }}
                      />
                      {"%"}
                    </div>

                    <div className={styles.rad}>
                      <div className={classNames(styles.radio, styles.radio2)}>
                        <input
                          onClick={() => saveField(8)}
                          // {...register("isFlash")}
                          id="radioFlash"
                          type="radio"
                          // name="contractRequired"
                          checked={venueData.flashEvent}
                          // value={""}
                        />
                        <label htmlFor="radioFlash">
                          {"איני מעוניין לתת שירות עבור אירועי בזק"}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (allServices?.services?.length && curentEvent?.id) ||
                unicServices?.length ? (
                <>
                  <div className={styles.tableSupplierContainer}>
                    <table>
                      <thead>
                        <tr>
                          <th></th>
                          <th></th>
                          {curentCategory?.id === 2 ? (
                            <>
                              <th>{"מקסימום"}</th>
                              <th>{"מינימום"}</th>
                            </>
                          ) : (
                            <>
                              <th>לפני מע"מ</th>
                              <th>כולל מע"מ</th>
                            </>
                          )}
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {allServices.services?.map((service, index) => {
                          return (
                            <React.Fragment key={service.checkListId}>
                              <tr className={styles.body}>
                                <td
                                  style={{
                                    marginTop: index === 0 ? 0 : "",
                                    fontFamily: service.price
                                      ? "Heebo-Medium"
                                      : "",
                                  }}
                                  className={styles.nameServ}
                                >
                                  {service.name}
                                </td>
                                <td
                                  style={{marginTop: index === 0 ? 0 : "",cursor:  editPermission ? "pointer" : 'default'}}
                                  className={styles.editServ}
                                >
                                  <span
                                    onClick={() => 
                                      editPermission ? updateService(service.id) : ''
                                    }
                                  >
                                    {"עריכה"}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    marginTop: index === 0 ? 0 : "",
                                    color: service.price ? "" : "#A4A2A2",
                                  }}
                                  className={styles.priceServ}
                                >
                                  <input
                                    style={{
                                      border: service.disabled
                                        ? ""
                                        : "1px solid #36606B",
                                      color: service.price ? "" : "#A4A2A2",
                                    }}
                                    onChange={(e) => {
                                      changeInput(e.target.value, service.id);
                                    }}
                                    disabled={service.disabled}
                                    type={"number"}
                                    defaultValue={0}
                                    value={service.price || 0}
                                  />
                                  {curentCategory?.id === 2 &&
                                    !(+service.price >= +service.minPrice) && (
                                      <div className={styles.errorMessage}>
                                        {" "}
                                        {"מספר לא חוקי"}
                                      </div>
                                    )}
                                  {/* {service.price||0} */}
                                </td>

                                {curentCategory?.id === 2 ? (
                                  <td
                                    style={{
                                      marginTop: index === 0 ? 0 : "",
                                      color: service.price ? "" : "#A4A2A2",
                                    }}
                                    className={styles.priceServ}
                                  >
                                    <input
                                      style={{
                                        border: service.disabled
                                          ? ""
                                          : "1px solid #36606B",
                                        color: service.price ? "" : "#A4A2A2",
                                      }}
                                      onChange={(e) => {
                                        changeInput1(
                                          e.target.value,
                                          service.id
                                        );
                                      }}
                                      disabled={service.disabled}
                                      type={"number"}
                                      defaultValue={0}
                                      value={service.minPrice || 0}
                                    />
                                    {curentCategory?.id === 2 &&
                                      !(+service.price >= +service.minPrice) && (
                                        <div className={styles.errorMessage}>
                                          {" "}
                                          {"מספר לא חוקי"}
                                        </div>
                                      )}
                                    {/* {service.price||0} */}
                                  </td>
                                ) : (
                                  <td
                                    style={{
                                      marginTop: index === 0 ? 0 : "",
                                      color: service.price
                                        ? "#242323"
                                        : "#A4A2A2",
                                    }}
                                    className={styles.priceWTServ}
                                  >
                                    {Math.round(Number(service.priceWithTax)) || 0}
                                  </td>
                                )}

                                <td
                                  style={{marginTop: index === 0 ? 0 : "", cursor:  editPermission ? "pointer" : 'default'}}
                                  className={styles.saveServ}
                                >
                                  <div
                                    className={styles.img}
                                    onClick={() => 
                                      editPermission ? saveService(service.id) : ''
                                    }
                                  >
                                    {"שמירת שינויים"}
                                  </div>
                                </td>
                              </tr>
                            </React.Fragment>
                          );
                        })}
                        <tr className={styles.body}>
                          <td
                            style={{
                              fontFamily: allServices.discount
                                ? "Heebo-Medium"
                                : "",
                            }}
                            className={styles.nameServ}
                          >
                            {"אירוע בזק"}
                          </td>
                          <td className={styles.editServ} style={{cursor:  editPermission ? "pointer" : 'default'}}>
                            <span
                              onClick={() => 
                                editPermission ?  updateFlash() : ''
                              }
                            >
                              {"עריכה"}
                            </span>
                          </td>
                          <td
                            style={{
                              color: allServices.discount
                                ? "#242323"
                                : "#A4A2A2",
                            }}
                            className={styles.priceServ}
                          >
                            {!allServices.disabled ? (
                              <input
                                style={{
                                  border: allServices.disabled
                                    ? ""
                                    : "1px solid #36606B",
                                  color: allServices.discount
                                    ? "#242323"
                                    : "#A4A2A2",
                                }}
                                onChange={(e) => {
                                  changeFlashInput(e.target.value);
                                }}
                                disabled={allServices.disabled}
                                type={"number"}
                                defaultValue={0}
                                value={allServices.discount}
                              />
                            ) : (
                              <>{`${allServices.discount || 0}%` || 0}</>
                            )}
                            {/* {service.price||0} */}
                          </td>
                          <td
                            style={{
                              color: allServices.discount
                                ? "#242323"
                                : "#A4A2A2",
                            }}
                            className={styles.priceWTServ}
                          >
                            {`${allServices.discount || 0}%` || 0}
                          </td>
                          <td className={styles.saveServ} style={{cursor:  editPermission ? "pointer" : 'default'}}>
                            <div
                              className={styles.img}
                              onClick={() => 
                                editPermission ?  saveFlash(): ''
                              }
                            >
                              {"שמירת שינויים"}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className={styles.unicServices}>
                    {!!unicServices?.length &&
                      unicServices.map((service, index) => {
                        return (
                          <div
                            key={"unic" + service.id}
                            className={styles.unicRow}
                          >
                            <div className={styles.inputs}>
                              <input
                                className={styles.firstInput}
                                placeholder={"הכנס תת תחום"}
                                value={service.serviceTypeName}
                                onChange={(e) =>
                                  changeUnicName(service.id, e.target.value)
                                }
                              />
                              <div className={styles.inpWithError}>
                                <input
                                  className={styles.secondInput}
                                  placeholder={
                                    curentCategory?.id === 2
                                      ? "מקסימום"
                                      : "הזן מחיר לפני מע״מ"
                                  }
                                  type={"number"}
                                  pattern="\d*"
                                  value={Math.round(Number(service.price)) || 0}
                                  onChange={(e) =>
                                    changeUnicPrice(service.id, e.target.value)
                                  }
                                />
                                {curentCategory?.id === 2 &&
                                  !(+service.price >= +service.minPrice) && (
                                    <div className={styles.errorMessage}>
                                      {" "}
                                      {"מספר לא חוקי"}
                                    </div>
                                  )}
                              </div>

                              {curentCategory?.id === 2 && (
                                <div className={styles.inpWithError}>
                                  <input
                                    className={styles.secondInput}
                                    placeholder={"מינימום"}
                                    type={"number"}
                                    pattern="\d*"
                                    value={service.minPrice || 0}
                                    onChange={(e) =>
                                      changeUnicMinPrice(
                                        service.id,
                                        e.target.value
                                      )
                                    }
                                  />
                                  {curentCategory?.id === 2 &&
                                    !(+service.price >= +service.minPrice) && (
                                      <div className={styles.errorMessage}>
                                        {" "}
                                        {"מספר לא חוקי"}
                                      </div>
                                    )}
                                </div>
                              )}
                            </div>

                            <div
                              className={styles.saveUnic}
                              onClick={() => {
                                saveUnic(service.id);
                              }}
                            >
                              {" "}
                              {"הוסף"}
                            </div>
                          </div>
                        );
                      })}

                    <div style={{cursor: editPermission ? "pointer" : 'default'}}
                      className={styles.addUnicServ}
                      onClick={() =>  editPermission ?  addFields() : ''}
                    >
                      {"+"}
                      {"הוספת תת תחום ידני"}
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            <div
              style={{
                flexDirection: "column",
                alignItems: "center",
                position: "relative",
              }}
              className={styles.mobile}
            >
              <div className={styles.titelEventSelect}>
                {"בחר סוג אירוע מתוך הרשימה ומלא את המחירון בהתאם"}
              </div>

              <div className={styles.eventSelector}>
                <Controller
                  name="category"
                  control={control}
                  render={({field: {onChange, value}}) => (
                    <Select
                      fontSize={"1rem"}
                      paddingInlineStart={"0.9375rem"}
                      paddingInlineEnd={"0.625rem"}
                      placeholder={"תחום"}
                      width={"100%"}
                      // borderRadius={"0.31rem"}
                      backgroundColor={"white"}
                      color={"#242323"}
                      options={
                        allEvents
                        // [
                        //   { id: 1, name: "first" },
                        //   { id: 2, name: "second" },
                        //   { id: 3, name: "third" },
                        //   { id: 4, name: "fourth" },
                        // ]
                      }
                      onChangeOption={(option) => {
                        onChange(option?.name);
                        // console.log(errors)
                        setCurentEvent(option);
                        getAllServices(option);
                        getVenueData(option);
                      }}
                      selectedOption={curentEvent}
                    />
                  )}
                />
                {errors.category && (
                  <p className={styles.errorMessage}>
                    {errors.category.message}
                  </p>
                )}
              </div>
              {openDialog && (
                <div className={styles.mobileDialog}>
                  <div className={styles.close}>
                    <img
                      onClick={() => {
                        setOpenDialog(false);
                      }}
                      alt="logoImage"
                      src={close}
                    />
                  </div>

                  <div className={styles.container}>
                    {curentCategory?.id !== 1 && (
                      <div className={styles.title1}>
                        {
                          "ברשימה זו יופיעו כל תת התחומים הקשורים לתחום בחרת. מלא.י מחיר רק עבור השירותים שאת.ה מציע.ה"
                        }
                      </div>
                    )}
                    {curentCategory?.id === 1 && curentEvent?.id ? (
                      <>
                        <div className={styles.venueTable1}>
                          <div className={styles.tableVenueContainer}>
                            <div className={styles.mainTitle}>
                              <div className={styles.mainTitle1}>
                                {
                                  "ברשימה זו יופיעו כל תת התחומים הקשורים לתחום בחרת. מלא.י מחיר רק עבור השירותים שאת.ה מציע.ה"
                                }
                              </div>
                              <div className={styles.mainTitle2}>
                                {"אפשרויות מתחם"}
                              </div>
                              <div className={styles.underline1}></div>
                              <div className={styles.mainTitle3}>
                                {"טווח מחירים (עלות אורח באירוע)"}
                              </div>
                              <div className={styles.mainTitle4}>
                                {"*"}
                                {"עבור הלקוח יוצגו המחירים כולל מע״מ"}
                              </div>
                            </div>
                            <div className={styles.tableVenue}>
                              <div
                                // style={{height: "3.0625rem"}}
                                className={styles.row1}
                              >
                                <div className={styles.spacebetween}>
                                  {/* <div className={styles.tableVenueTitles}>
                                    {"טווח מחירים"}
                                  </div> */}
                                  <div className={styles.priceRange}>
                                    <div className={styles.inp}>
                                      <input
                                        onChange={(e) => {
                                          setField(e.target.value, 11);
                                        }}
                                        value={venueData.priceRangeTo}
                                      />
                                      <img src={shekelImg} alt="shekel" />
                                      {!(
                                        +venueData?.priceRangeTo >=
                                        +venueData?.priceRangeFrom
                                      ) && (
                                        <p className={styles.errorMessage}>
                                          {" "}
                                          {"מספר לא חוקי"}
                                        </p>
                                      )}
                                    </div>
                                    {"-"}

                                    <div className={styles.inp}>
                                      <input
                                        onChange={(e) => {
                                          setField(e.target.value, 1);
                                        }}
                                        value={venueData.priceRangeFrom}
                                      />
                                      <img src={shekelImg} alt="shekel" />
                                      {!(
                                        +venueData?.priceRangeTo >=
                                        +venueData?.priceRangeFrom
                                      ) && (
                                        <p className={styles.errorMessage}>
                                          {" "}
                                          {"מספר לא חוקי"}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>

                                {/* <div
                                  className={styles.saveRow}
                                  onClick={() => {
                                    saveField(1);
                                  }}
                                >
                                  {"שמירת שינויים"}
                                </div> */}
                              </div>
                              <div className={styles.underline2}></div>
                              <div className={styles.row2}>
                                <div className={styles.spacebetween}>
                                  <div className={styles.tableVenueTitles}>
                                    {"כמות אורחים"}
                                  </div>
                                  <div className={styles.guestsRange}>
                                    <div className={styles.inp}>
                                      <div className={styles.abbr}>
                                        {"מקסימום"}
                                      </div>
                                      <input
                                        onChange={(e) => {
                                          setField(e.target.value, 22);
                                        }}
                                        value={venueData.amountOfGuestsTo}
                                      />
                                      {((+venueData?.priceRangeTo >=
                                        +venueData?.priceRangeFrom) ||!(
                                        +venueData?.amountOfGuestsTo >=
                                        +venueData?.amountOfGuestsFrom
                                      )) && (
                                        <p className={styles.errorMessage}>
                                          {" "}
                                          {"מספר לא חוקי"}
                                        </p>
                                      )}
                                    </div>
                                    {"-"}
                                    <div className={styles.inp}>
                                      <div className={styles.abbr}>
                                        {"מינימום"}
                                      </div>
                                      <input
                                        onChange={(e) => {
                                          setField(e.target.value, 2);
                                        }}
                                        value={venueData.amountOfGuestsFrom}
                                      />
                                      {((+venueData?.priceRangeTo >=
                                        +venueData?.priceRangeFrom) ||!(
                                        +venueData?.amountOfGuestsTo >=
                                        +venueData?.amountOfGuestsFrom
                                      )) && (
                                        <p className={styles.errorMessage}>
                                          {" "}
                                          {"מספר לא חוקי"}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>

                                {/* <div
                                  className={styles.saveRow}
                                  onClick={() => {
                                    saveField(2);
                                  }}
                                >
                                  {"שמירת שינויים"}
                                </div> */}
                              </div>
                              <div className={styles.underline2}></div>

                              <div className={styles.row3}>
                                {/* <div className={styles.spacebetween}> */}
                                {/* <div className={styles.tableVenueTitles}>
                                    {"אירועים בסופי שבוע"}
                                  </div> */}
                                <div className={styles.venueSelect}>
                                  <Controller
                                    name="field1"
                                    control={control}
                                    render={({field: {onChange, value}}) => (
                                      <Select
                                        placeholder={"אירועים בסופי שבוע"}
                                        fontSize={"1rem"}
                                        paddingInlineStart={"1.75rem"}
                                        paddingInlineEnd={"1.33rem"}
                                        width={"100%"}
                                        borderRadius={"0.125rem"}
                                        backgroundColor={"#EEE9E5"}
                                        color={"#242323"}
                                        options={
                                          weekends
                                          // [
                                          //   { id: 1, name: "first" },
                                          //   { id: 2, name: "second" },
                                          //   { id: 3, name: "third" },
                                          //   { id: 4, name: "fourth" },
                                          // ]
                                        }
                                        onChangeOption={(option) => {
                                          onChange(option?.name);
                                          // console.log(errors)
                                          setField(option, 3);
                                        }}
                                        selectedOption={venueData.weekendEvent}
                                      />
                                    )}
                                  />
                                  {errors.field1 && (
                                    <p className={styles.errorMessage}>
                                      {errors.field1.message}
                                    </p>
                                  )}
                                </div>
                                {/* </div> */}
                                {/* <div
                                  className={styles.saveRow}
                                  onClick={() => {
                                    saveField(3);
                                  }}
                                >
                                  {"שמירת שינויים"}
                                </div> */}
                              </div>
                              <div className={styles.row4}>
                                {/* <div className={styles.spacebetween}> */}
                                {/* <div className={styles.tableVenueTitles}>
                                    {"סוג מתחם"}
                                  </div> */}
                                <div className={styles.venueSelect}>
                                  <Controller
                                    name="field2"
                                    control={control}
                                    render={({field: {onChange, value}}) => (
                                      <Select
                                        placeholder={"סוג מתחם"}
                                        fontSize={"1rem"}
                                        paddingInlineStart={"1.75rem"}
                                        paddingInlineEnd={"1.33rem"}
                                        width={"100%"}
                                        borderRadius={"0.125rem"}
                                        backgroundColor={"#EEE9E5"}
                                        color={"#242323"}
                                        options={
                                          venueType
                                          // [
                                          //   { id: 1, name: "first" },
                                          //   { id: 2, name: "second" },
                                          //   { id: 3, name: "third" },
                                          //   { id: 4, name: "fourth" },
                                          // ]
                                        }
                                        onChangeOption={(option) => {
                                          onChange(option?.name);
                                          // console.log(errors)
                                          setField(option, 4);
                                        }}
                                        selectedOption={venueData.venueType}
                                      />
                                    )}
                                  />
                                  {errors.field2 && (
                                    <p className={styles.errorMessage}>
                                      {errors.field2.message}
                                    </p>
                                  )}
                                </div>
                                {/* </div> */}
                                {/* <div
                                  className={styles.saveRow}
                                  onClick={() => {
                                    saveField(4);
                                  }}
                                >
                                  {"שמירת שינויים"}
                                </div> */}
                              </div>
                              <div className={styles.row5}>
                                {/* <div className={styles.spacebetween}> */}
                                {/* <div className={styles.tableVenueTitles}>
                                    {"כשרות / סוג תפריט"}
                                  </div> */}
                                <div className={styles.venueSelect}>
                                  <Controller
                                    name="field3"
                                    control={control}
                                    render={({field: {onChange, value}}) => (
                                      <Select
                                        placeholder={"כשרות / סוג תפריט"}
                                        fontSize={"1rem"}
                                        paddingInlineStart={"1.75rem"}
                                        paddingInlineEnd={"1.33rem"}
                                        width={"100%"}
                                        borderRadius={"0.125rem"}
                                        backgroundColor={"#EEE9E5"}
                                        color={"#242323"}
                                        options={
                                          kosher
                                          // [
                                          //   { id: 1, name: "first" },
                                          //   { id: 2, name: "second" },
                                          //   { id: 3, name: "third" },
                                          //   { id: 4, name: "fourth" },
                                          // ]
                                        }
                                        onChangeOption={(option) => {
                                          onChange(option?.name);
                                          // console.log(errors)
                                          setField(option, 5);
                                        }}
                                        selectedOption={venueData.kosher}
                                      />
                                    )}
                                  />
                                  {errors.field3 && (
                                    <p className={styles.errorMessage}>
                                      {errors.field3.message}
                                    </p>
                                  )}
                                </div>
                                {/* </div> */}
                                {/* <div
                                  className={styles.saveRow}
                                  onClick={() => {
                                    saveField(5);
                                  }}
                                >
                                  {"שמירת שינויים"}
                                </div> */}
                              </div>
                              <div className={styles.row6}>
                                {/* <div className={styles.spacebetween}> */}
                                {/* <div className={styles.tableVenueTitles}>
                                    {"אפשרויות חניה"}
                                  </div> */}
                                <div className={styles.venueSelect}>
                                  <Controller
                                    name="field4"
                                    control={control}
                                    render={({field: {onChange, value}}) => (
                                      <Select
                                        placeholder={"אפשרויות חניה"}
                                        fontSize={"1rem"}
                                        paddingInlineStart={"1.75rem"}
                                        paddingInlineEnd={"1.33rem"}
                                        width={"100%"}
                                        borderRadius={"0.125rem"}
                                        backgroundColor={"#EEE9E5"}
                                        color={"#242323"}
                                        options={
                                          parkingOption
                                          // [
                                          //   { id: 1, name: "first" },
                                          //   { id: 2, name: "second" },
                                          //   { id: 3, name: "third" },
                                          //   { id: 4, name: "fourth" },
                                          // ]
                                        }
                                        onChangeOption={(option) => {
                                          onChange(option?.name);
                                          // console.log(errors)
                                          setField(option, 6);
                                        }}
                                        selectedOption={venueData.parkingOption}
                                      />
                                    )}
                                  />
                                  {errors.field4 && (
                                    <p className={styles.errorMessage}>
                                      {errors.field4.message}
                                    </p>
                                  )}
                                </div>
                                {/* </div> */}

                                {/* <div
                                  className={styles.saveRow}
                                  onClick={() => {
                                    saveField(6);
                                  }}
                                >
                                  {"שמירת שינויים"}
                                </div> */}
                              </div>
                              <div className={styles.underline2}></div>
                            </div>
                            <div
                              style={{
                                paddingTop: "1.25rem",
                                fontSize: "0.875rem",
                                lineHeight: "1.3125rem",
                              }}
                            >
                              {"אירוע בזק - הזן אחוז הנחה"}
                            </div>
                            <div className={styles.flashVenue}>
                              <div className={styles.inputFlash}>
                                <input
                                  value={venueData.discount || 0}
                                  onChange={(e) => {
                                    saveField(7, e.target.value);
                                  }}
                                />
                                {"%"}
                              </div>
                              <div className={styles.flashTitle}>
                                <div className={styles.rad}>
                                  <div
                                    className={classNames(
                                      styles.radio,
                                      styles.radio2
                                    )}
                                  >
                                    <input
                                      onClick={() => saveField(8)}
                                      // {...register("isFlash")}
                                      id="radioFlash"
                                      type="radio"
                                      // name="contractRequired"
                                      checked={venueData.flashEvent}
                                      // value={""}
                                    />
                                    <label htmlFor="radioFlash1">
                                      {"אירוע בזק"}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <div className={styles.flashVenue}>
                              <div className={styles.flashTitle}>
                                <div className={styles.rad}>
                                  <div
                                    className={classNames(
                                      styles.radio,
                                      styles.radio2
                                    )}
                                  >
                                    <input
                                      onClick={() => saveField(8)}
                                      // {...register("isFlash")}
                                      id="radioFlash"
                                      type="radio"
                                      // name="contractRequired"
                                      checked={venueData.flashEvent}
                                      // value={""}
                                    />
                                    <label htmlFor="radioFlash">
                                      {"אירוע בזק"}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className={styles.inputFlash}>
                                <input
                                  value={venueData.discount}
                                  onChange={(e) => {
                                    saveField(7, e.target.value);
                                  }}
                                />
                                {"%"}
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </>
                    ) : allServices?.services?.length && curentEvent?.id ? (
                      <>
                        <div className={styles.table1}>
                          {allServices.services?.map((service, index) => {
                            return (
                              <React.Fragment key={service.checkListId}>
                                <div className={styles.table1Row}>
                                  <div style={{cursor:  editPermission ? "pointer" : 'default'}}
                                    onClick={() => 
                                      editPermission ? updateService(service.id) : ''
                                    }
                                    className={styles.radio}
                                  >
                                    <input
                                      // onClick={() => setRulesKey(Math.random())}
                                      // {...register("contractRequired")}
                                      id="radioBusinessDetail-1"
                                      type="radio"
                                      // name="contractRequired"
                                      checked={!!service.price}

                                      // value={1}
                                    />
                                    <label htmlFor="radioBusinessDetail-1">
                                      {service.name}
                                    </label>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "0.4375rem",
                                    }}
                                  >
                                    <span className={styles.inpWithError}>
                                      <input
                                        style={{
                                          color: service.price
                                            ? "#242323"
                                            : "#A4A2A2",
                                        }}
                                        onChange={(e) => {
                                          changeInput(
                                            e.target.value,
                                            service.id
                                          );
                                        }}
                                        disabled={service.disabled}
                                        type={"number"}
                                        defaultValue={0}
                                        value={service.price || 0}
                                      />
                                      {curentCategory?.id === 2 &&
                                        !(
                                          +service.price >= +service.minPrice
                                        ) && (
                                          <div className={styles.errorMessage}>
                                            {" "}
                                            {"מספר לא חוקי"}
                                          </div>
                                        )}
                                    </span>
                                    {curentCategory?.id === 2 && <>{"-"}</>}
                                    {curentCategory?.id === 2 && (
                                      <span className={styles.inpWithError}>
                                        <input
                                          style={{
                                            color: service.price
                                              ? "#242323"
                                              : "#A4A2A2",
                                          }}
                                          onChange={(e) => {
                                            changeInput1(
                                              e.target.value,
                                              service.id
                                            );
                                          }}
                                          disabled={service.disabled}
                                          type={"number"}
                                          defaultValue={0}
                                          value={service.minPrice || 0}
                                        />
                                        {curentCategory?.id === 2 &&
                                          !(
                                            +service.price >= +service.minPrice
                                          ) && (
                                            <div
                                              className={styles.errorMessage}
                                            >
                                              {" "}
                                              {"מספר לא חוקי"}
                                            </div>
                                          )}
                                      </span>
                                    )}
                                    <span className={styles.image}>
                                      <img
                                        style={{
                                          opacity: service.disabled
                                            ? "0.55"
                                            : "", cursor: editPermission ? 'pointer' : 'default'
                                        }}
                                        onClick={() => 
                                          editPermission ? updateService(service.id) : ''
                                        }
                                        alt="edit"
                                        src={edit}
                                      />
                                    </span>
                                  </div>
                                </div>
                              </React.Fragment>
                            );
                          })}

                          <div className={styles.table1Row}>
                            <div
                              onClick={() => {
                                updateFlash();
                              }}
                              className={styles.radio}
                            >
                              <input
                                // onClick={() => setRulesKey(Math.random())}
                                // {...register("contractRequired")}
                                id="radioBusinessDetail-1"
                                type="radio"
                                // name="contractRequired"
                                checked={!!allServices.discount}

                                // value={1}
                              />
                              <label htmlFor="radioBusinessDetail-1">
                                {"אירוע בזק (עד 30 יום מראש)  "}
                              </label>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "0.4375rem",
                              }}
                            >
                              <span>
                                <input
                                  style={{
                                    color: allServices.discount
                                      ? "#242323"
                                      : "#A4A2A2",
                                  }}
                                  onChange={(e) => {
                                    changeFlashInput(e.target.value);
                                  }}
                                  disabled={allServices.disabled}
                                  type={"number"}
                                  defaultValue={0}
                                  value={allServices.discount}
                                />
                              </span>
                              <span className={styles.image}>
                                <img
                                  style={{
                                    opacity: allServices.disabled ? "0.55" : "",
                                    cursor: editPermission ? 'pointer' : 'default'
                                  }}
                                  onClick={() => 
                                    editPermission ? updateFlash() : ''
                                  }
                                  alt="edit"
                                  src={edit}
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className={styles.unicServices}>
                          {!!unicServices?.length &&
                            unicServices.map((service, index) => {
                              return (
                                <div
                                  key={"unic" + service.id}
                                  className={styles.unicRow}
                                >
                                  <div className={styles.inputs}>
                                    <input
                                      className={styles.firstInput}
                                      placeholder={"הכנס תת תחום"}
                                      value={service.serviceTypeName}
                                      onChange={(e) =>
                                        changeUnicName(
                                          service.id,
                                          e.target.value
                                        )
                                      }
                                    />
                                    <div className={styles.inpWithError}>
                                      <input
                                        className={styles.secondInput}
                                        style={
                                          curentCategory?.id === 2
                                            ? {maxWidth: "4.5rem"}
                                            : {}
                                        }
                                        placeholder={"הזן מחיר"}
                                        type={"number"}
                                        pattern="\d*"
                                        value={service.price || 0}
                                        onChange={(e) =>
                                          changeUnicPrice(
                                            service.id,
                                            e.target.value
                                          )
                                        }
                                      />
                                      {curentCategory?.id === 2 &&
                                        !(
                                          +service.price >= +service.minPrice
                                        ) && (
                                          <div className={styles.errorMessage}>
                                            {" "}
                                            {"מספר לא חוקי"}
                                          </div>
                                        )}
                                    </div>

                                    {curentCategory?.id === 2 && (
                                      <div className={styles.inpWithError}>
                                        <input
                                          style={{
                                            marginLeft: "0",
                                            maxWidth: "4.5rem",
                                          }}
                                          className={styles.secondInput}
                                          placeholder={"הזן מחיר"}
                                          type={"number"}
                                          pattern="\d*"
                                          value={service.minPrice || 0}
                                          onChange={(e) =>
                                            changeUnicMinPrice(
                                              service.id,
                                              e.target.value
                                            )
                                          }
                                        />
                                        {curentCategory?.id === 2 &&
                                          !(
                                            +service.price >= +service.minPrice
                                          ) && (
                                            <div
                                              className={styles.errorMessage}
                                            >
                                              {" "}
                                              {"מספר לא חוקי"}
                                            </div>
                                          )}
                                      </div>
                                    )}
                                  </div>

                                  {/* <div
                        className={styles.saveUnic}
                        onClick={() => {
                          saveUnic(service.id);
                        }}
                      >
                        {" "}
                        {"הוסף"}
                      </div> */}
                                </div>
                              );
                            })}

                          <div style={{cursor: editPermission ? "pointer" : 'default'}}
                            className={styles.addUnicServ}
                            onClick={() => editPermission ? addFields() : ''}
                          >
                            {"+"}
                            {"הוספת תת תחום ידני"}
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    <div style={{cursor: editPermission ? 'pointer' : 'default'}}
                      onClick={() => {
                        if (curentCategory?.id === 1) {
                          editPermission ? saveField(9) : console.log();
                        } else {
                          editPermission ? saveService() : console.log();
                          editPermission ? saveAllUnicServices() : console.log();
                        }
                      }}
                      className={styles.saveDialog}
                    >
                      {"עדכן מחירים"}
                    </div>
                  </div>
                </div>
              )}

              {curentEvent?.id && (
                <div
                  onClick={() => {setOpenDialog(true)}
                  }
                  className={styles.goTo}
                >
                  {"אישור והמשך"}
                </div>
              )}
            </div>
          </AccordionDetails>
        </Accordion>}
        {currentRole !== 'LABEL' && <Accordion
          expanded={stepNumber === 2}
          onChange={auth?.user && handleChange(2)}
        >
          <AccordionSummary aria-controls="2d-content" id="2d-header">
            <div className={styles.tabTitle}>{"גלריית תמונות"}</div>
          </AccordionSummary>
          <AccordionDetails>
            <div
              onClick={() => {
                navigate("/personal-supplier/images");
              }}
              className={styles.goToImages}
            >
              {"שנה או ערוך את הגלריה שלך "}
            </div>
          </AccordionDetails>
        </Accordion>}

        {!!(auth?.roles[0] === "LABEL") && (
          <Accordion
            expanded={stepNumber === 3}
            onChange={auth?.user && handleChange(3)}
          >
            <AccordionSummary aria-controls="2d-content" id="2d-header">
              <div className={styles.tabTitle}>{"ניהול ספקים"}</div>
            </AccordionSummary>
            <AccordionDetails>
              <LabelPremissions />
            </AccordionDetails>
          </Accordion>
        )}
        {stepNumber === 0 && (
          <div style={{cursor: editPermission ? "pointer" : 'default'}} className={classNames(styles.buttons, styles.desctop)}>
            <div style={{cursor: editPermission ? "pointer" : 'default'}} onClick={() => editPermission ? saveMainPart() : ''} className={styles.save}>
              {"שמירת שינויים"}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChangeSupplierBusiness;
