export const EVENT_TYPE_ENUM = {
  PRIVATE: 0,
  BUSINESS: 1,
};
export const EVENT_TYPE_NAME_ENUM = {
  0: 'PRIVATE',
  1: 'BUSINESS',
};

export const EXISTS_PLACE_ENUM = {
  YES: 0,
  NO: 1,
};

export const STEPS_ENUM = {
  TYPE_EVENT: 0,
  WHAT_CELEBRATING: 1,
  EXISTS_PLACE: 2,
  DATE_SELECTION: 3,
  COUNT_GUESTS: 4,
  WHERE_EVENT: 5,
  PHONE: 6,
  SUBMIT: 7,
};
export const DEFAULT_FORM_VALUES = {
  typeEvent: EVENT_TYPE_ENUM.PRIVATE,
  celebrating: '',
  phoneNumber: '',
  existsPlace: undefined,
  whereEvent: '',
  date: null,
  dateRange:null,
  countGuests: '',
  authCode: '',
}

export const EXISTS_PLACE_FIELDS = [
  'date',
  'whereEvent',
  'countGuests',
  'phoneNumber',
]
export const AUTH_EXISTS_PLACE_FIELDS = [
  'date',
  'whereEvent',
  'countGuests',
]

export const NO_EXISTS_PLACE_FIELDS = [
  'dateRange',
  'phoneNumber',
]
export const AUTH_NO_EXISTS_PLACE_FIELDS = [
  'dateRange',
]


export const RELATIONSHIP_OF_FIELDS_TO_STEPS = {
  typeEvent:STEPS_ENUM.TYPE_EVENT,
  celebrating:STEPS_ENUM.WHAT_CELEBRATING,
  existsPlace:STEPS_ENUM.EXISTS_PLACE,
  dateRange:STEPS_ENUM.DATE_SELECTION,
  date:STEPS_ENUM.DATE_SELECTION,
  countGuests:STEPS_ENUM.COUNT_GUESTS,
  whereEvent:STEPS_ENUM.WHERE_EVENT,
  phoneNumber:STEPS_ENUM.PHONE
}