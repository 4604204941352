import styles from "./Documents.module.scss";
import SelectDocument from "./selectDocuments/Select";
import SelectDays from "./selectDays/Select";
import exportFile from "./export.svg";
import shekel from "./money.svg"
import file_active from "./file_active.svg"
import {useDispatch} from "react-redux";
import file_inactive from "./file_inactive.svg"
import download_active from "./download_active.svg"
import download_inactive from "./download_inactive.svg"
import filters from "./filters.svg"
import details from "./details.svg"
import { useEffect, useState } from "react";
import Popup from "./popup/Popup";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate"
import {useNavigate} from "react-router-dom";
import classNames from "classnames";
import PopupFilters from "./popupFilters/PopupFilters";
import PopupDetails from "./popupDetails/PopupDetails";
import {setCurrentEventChat} from "../../../store/chatSlice";
import useAuth from "../../../hooks/useAuth";

function Documents() {

	const dispatch = useDispatch();
	const axiosPrivate = useAxiosPrivate();
	const [currentDocumentStatus, setcurrentDocumentStatus] = useState({id:1, name:'‌כל המסמכים', value:'all'});
	const [currentDays, setcurrentDays] = useState({id:1, name:'12 חודשים אחרונים', value:'YEAR'});
	const [chousedSupplier, setchousedSupplier] = useState(false)
	const [currentSupplier, setcurrentSupplier] = useState({id:0, name:''});
	const [documentStatus] = useState([
		{id:1, name:'‌כל המסמכים', value:'all'},
		{id:2, name:'חתומים', value:'SIGNED'},
		{id:3, name:'לחתימה', value:'PENDING'},
		{id:4, name:'פג תוקף', value:'EXPIRED'},
		{id:5, name:'בתשלום', value:'PAYED'}
	])
	const [suppliers, setsuppliers] = useState([])
	const [daysFilter] = useState([
		{id:1, name:'12 חודשים אחרונים', value:'YEAR'},
		{id:2, name:'6 חודשים אחרונים', value:'HALF_YEAR'},
		{id:3, name:'3 חודשים אחרונים', value:'THREE_MONTHS'},
		{id:4, name:'חודש נוכחי', value:'MONTH'}])
	const [isOpenMobileFilters, setisOpenMobileFilters] = useState(false)
	const [isOpenMobileDetails, setisOpenMobileDetails] = useState(false)
	const [isOpenPopup, setIsOpenPopup] = useState(false)
	const [isRead, setIsRead] = useState(false)
	const [isCurrentId, setIsCurrentId] = useState(0)
	const [searchName, setSearchName] = useState('')
	const [currentDocument, setcurrentDocument] = useState()
	const [documents, setdocuments] = useState({data:[], loading: false});
	const navigate = useNavigate();
	const { auth } = useAuth();
	let currentRole = auth.roles[0];

	useEffect(() => {
		let div = document.getElementsByClassName('samyBody');
		div[0].scrollTop = 0
		if(currentRole === 'LABEL'){
		  LabelChats();
		}
	   if(currentRole !== 'LABEL'){
	   const fetchData = async () => {
		let data = {  
		   customerName: '',
		   contractStatus: [],
		   dateFilter: currentDays.value
		 }
		 const result = await axiosPrivate.post(`${process.env.REACT_APP_API_PATH}contract/supplier/month`,data);
		 if(result){
		   setdocuments({data:result.data.content, loading:false});
		 }
	   };
	   fetchData();
	   }
	   // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	 const getDocuments = async (document,supName,dayFilter) => {
		let data = {}
		if(currentRole === 'LABEL'){
			data = {  
				customerName: supName,
				contractStatus: document === 'all' ? [] : [document],
				dateFilter: dayFilter,
				supplierId: currentSupplier.id
			}
		} else {
			data = {  
				customerName: supName,
				contractStatus: document === 'all' ? [] : [document],
				dateFilter: dayFilter
			}
		}
		const result = await axiosPrivate.post(`${process.env.REACT_APP_API_PATH}contract/supplier/month`,data);
		if(result){
		  setdocuments({data:result.data.content, loading:false});
		  setchousedSupplier(true);
		}

	 }

	 const getDocumentsForLabel = async (document,supName,dayFilter,id) => {
		let data = {}
		if(currentRole === 'LABEL'){
			data = {  
				customerName: supName,
				contractStatus: document === 'all' ? [] : [document],
				dateFilter: dayFilter,
				supplierId: id
			}
		} else {
			data = {  
				customerName: supName,
				contractStatus: document === 'all' ? [] : [document],
				dateFilter: dayFilter
			}
		}
		
		const result = await axiosPrivate.post(`${process.env.REACT_APP_API_PATH}contract/supplier/month`,data);
		if(result){
		  setdocuments({data:result.data.content, loading:false});
		  setchousedSupplier(true);
		}

	 }

	const onChangedocuments = (option) => {
		setcurrentDocumentStatus(option);
		  getDocuments(option.value, searchName, currentDays.value);
	};
	
	const onChangedays = (option) => {
		setcurrentDays(option);
		getDocuments(currentDocumentStatus.value, searchName, option.value);
	};

	const onChangeSuppliers = (option) => {
		setcurrentSupplier(option);
		getDocumentsForLabel(currentDocumentStatus.value, searchName, currentDays.value, option.id);
		setchousedSupplier(false);
		setdocuments({data:[], loading:true});
	};
	
	let timeout = 0;
	function doSearch(ev) {
	  setSearchName(ev.target.value);
		if (timeout) {
			clearTimeout(timeout);
		}
		timeout = setTimeout(() => {
			getDocuments(currentDocumentStatus.value, ev.target.value, currentDays.value);
		}, 500);
	}

	const openDocumentDialog = async (id,isRead) => {
		setIsCurrentId(id);
		setIsRead(isRead);
		setIsOpenPopup(true);
	}

	const getMonth =  (month) => {
		if(Number(month) === 1){
			return 'ינואר';
		} else if(Number(month) === 2){
			return 'פברואר';
		} else if(Number(month) === 3){
			return 'מרץ';
		} else if(Number(month) === 4){
			return 'אפריל';
		} else if(Number(month) === 5){
			return 'מאי';
		} else if(Number(month) === 6){
			return 'יוני';
		} else if(Number(month) === 7){
			return 'יולי';
		} else if(Number(month) === 8){
			return 'אוגוסט';
		} else if(Number(month) === 9){
			return 'ספטמבר';
		} else if(Number(month) === 10){
			return 'אוקטובר';
		} else if(Number(month) === 11){
			return 'נובמבר';
		} else if(Number(month) === 12){
			return 'דצמבר';
		}
	}

	function convertToTwoCharacter(numb) {
		return numb.toString().length === 1 ? "0" + numb : numb.toString();
	  }

	function convertDate(date){
		const time = new Date(date);
		let month = time.getMonth() + 1;
		let day = time.getDate();
		let dayString = convertToTwoCharacter(day);
		let monthString = convertToTwoCharacter(month);
		let dateFull = dayString + '/' + monthString + '/' + time.getFullYear();
		return dateFull
	  }

	function openSupplier(categoryId, supId, userId,eventTypeId,eventTypeName) {
		// console.log(categoryId, supId, userId,eventTypeId);
		let eventType = {id: eventTypeId, name: eventTypeName};
		dispatch(setCurrentEventChat({currentEvent: eventType}));
		navigate(`/personal-supplier/chat/${supId}/${userId}/${eventTypeId}/${categoryId}/0`);
	}

	const getPDF = async(contractId, supplierDocument = false) => {
		try {
			let actualState = [...documents.data];
			let secondResponse = null
			let isHaveSecondFile = false;
			try{
				const response = await axiosPrivate.get(`${process.env.REACT_APP_API_PATH}contract/document/request?contractId=${contractId}`,{responseType: 'blob'});
				const data = await response.data;
				const url = window.URL.createObjectURL(new Blob([data]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", "file.pdf");
				document.body.appendChild(link);
				link.click();
			} catch (error){

			}
			actualState.forEach(el=>{
			isHaveSecondFile = el.contracts.find(el=>el.id === contractId).id
			})
			if (isHaveSecondFile && supplierDocument) {
				try{
					secondResponse = await axiosPrivate.get(`${process.env.REACT_APP_API_PATH}contract/supplier/document?contractId=${contractId}`,{responseType: 'blob'});
					const link2 = document.createElement("a");
					const dataSecond = await secondResponse.data;
					const url2 = window.URL.createObjectURL(new Blob([dataSecond]));
					link2.href = url2;
					link2.setAttribute("download", "file2.pdf");
					document.body.appendChild(link2);
					link2.click()
				} catch (error){

				}
			}
		  // if (response.status!==200) {
			// throw new Error('Server Error');
		  // }
		} catch (error) {
		//   console.log(error)
		}
	}

	const getPDFDocuments = async() => {
		let contractIds = [];
		let actualState = [...documents.data];
		actualState.map((item)=>{
			item.contracts.map((val)=>{
				if(val.isSelected){
					contractIds.push(val.id);
				}
				return val
			})
			return item
		})
		try {
		  const response = await axiosPrivate.post(`${process.env.REACT_APP_API_PATH}contract/export/excel`,contractIds,{responseType: 'blob'});
		  const data = await response.data;
		  const url = window.URL.createObjectURL(new Blob([data]));
		  const link = document.createElement("a");
		  link.href = url;
		  link.setAttribute("download", "document.xlsx");
		  document.body.appendChild(link);
		  link.click();
		  if (response.status!==200) {
			throw new Error('Server Error');
		  }
		} catch (error) {
		//   console.log(error)
		}
	}

	const onChangeDocuments = (index2, index, event) => {
		let actualState = [...documents.data];
		let newLine = actualState[index].contracts;
		if (event) {
			newLine[index2].isSelected = false;
		} else {
			newLine[index2].isSelected = true;
		}
		actualState[index].contracts = newLine;
		let newList = actualState;
		setdocuments({data: newList, loading: false});
	};

	const openFilters = () => {
		setisOpenMobileFilters(true);
	};

	const openDetails = (option) => {
		setisOpenMobileDetails(true);
		setcurrentDocument(option);
	};

	function check(item,days){
		getDocuments(item.value, searchName, days.value);
	};

	
	function detailsInfo(){
		openDocumentDialog(currentDocument.id, true);
	};

	function pdfDownload(){
		getPDF(currentDocument.id);
	};

	const LabelChats = async () => {
	  const result = await axiosPrivate.get(`${process.env.REACT_APP_API_PATH}supplier/inferior/label`);
	  if (result){
		setsuppliers(result.data);
	  }
	};  

  return (
	<>
	<div className={styles.filtersBlock}>
	  <div className={styles.secondBlock}>
		{ currentRole === 'LABEL' &&
		<div className={styles.secondFilter} style={{justifyContent: 'center'}}>
			{/* <div className={styles.secondFilterTitle}>סנן</div> */}
			<div className={styles.secondFilterDocument}>
				<SelectDays
					fontSize={"1.25rem"}
					paddingInlineStart={"1rem"}
					placeholder={" "}
					width={"100%"}
					height={"2.9375rem"}
					borderRadius={"0.125rem"}
					color={"#242323"}
					selectedOption={currentSupplier}
					options={suppliers}
					onChangeOption={onChangeSuppliers}
					/>
			</div>
		</div>}
		{((currentRole === 'LABEL' && chousedSupplier) || (currentRole === 'SUPPLIER')) ? <div className={styles.secondFilter}>
			<div className={styles.secondFilterTitle}>סנן</div>
			<div className={styles.secondFilterDocument}>
				<SelectDocument
					fontSize={"1.25rem"}
					paddingInlineStart={"1rem"}
					placeholder={" "}
					width={"100%"}
					height={"2.9375rem"}
					borderRadius={"0.125rem"}
					color={"#242323"}
					selectedOption={currentDocumentStatus}
					options={documentStatus}
					onChangeOption={onChangedocuments}
					/>
			</div>
			<div className={styles.secondFilterDocument}>
				<SelectDays
					fontSize={"1.25rem"}
					paddingInlineStart={"1rem"}
					placeholder={" "}
					width={"100%"}
					height={"2.9375rem"}
					borderRadius={"0.125rem"}
					color={"#242323"}
					selectedOption={currentDays}
					options={daysFilter}
					onChangeOption={onChangedays}
					/>
			</div>
			<div className={styles.searchFieldContainer}>
				<input type="text" className={styles.searchingField}
					onChange={(e) => doSearch(e)}
					placeholder='חיפוש חופשי'/>
				<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"
						className={styles.magnifierIcon}>
					<g id="magnifying-glass" transform="translate(-0.4)">
						<rect id="Rectangle_204" data-name="Rectangle 204" width="28" height="28" transform="translate(0.4)"
								fill="none"/>
						<path id="Ellipse_1" data-name="Ellipse 1"
								d="M2.106-8A10.118,10.118,0,0,1,12.212,2.106,10.118,10.118,0,0,1,2.106,12.212,10.118,10.118,0,0,1-8,2.106,10.118,10.118,0,0,1,2.106-8Zm0,18.455A8.348,8.348,0,1,0-6.242,2.106,8.358,8.358,0,0,0,2.106,10.455Z"
								transform="translate(10.636 10.848)" fill="#3e3d3d"/>
						<path id="Line_4" data-name="Line 4"
								d="M-1.783-.9A.876.876,0,0,1-2.4-1.161L-7.743-6.5a.879.879,0,0,1,0-1.243.879.879,0,0,1,1.243,0L-1.161-2.4a.879.879,0,0,1,0,1.243A.876.876,0,0,1-1.783-.9Z"
								transform="translate(26.389 26.601)" fill="#3e3d3d"/>
					</g>
				</svg>
			</div>
		</div> : <></>}
			{(!documents.loading && ((currentRole === 'LABEL' && chousedSupplier) || (currentRole === 'SUPPLIER'))) ? <img alt="export" onClick={(e)=>{getPDFDocuments();}} className={styles.exportImage} src={exportFile} /> : <></>}
	  </div>

	  <div className={styles.filtersMobile}>
		{ currentRole === 'LABEL' &&
		<div className={styles.secondFilter} style={{justifyContent: 'center'}}>
			{/* <div className={styles.secondFilterTitle}>סנן</div> */}
			<div className={styles.secondFilterDocument}>
				<SelectDays
					fontSize={"1.25rem"}
					paddingInlineStart={"1rem"}
					placeholder={" "}
					width={"100%"}
					height={"2.9375rem"}
					borderRadius={"0.125rem"}
					color={"#242323"}
					selectedOption={currentSupplier}
					options={suppliers}
					onChangeOption={onChangeSuppliers}
					/>
			</div>
		</div> }
	  <div className={styles.filters}>
		<div className={classNames(styles.tableTitle, styles.mobile)} >
			<div className={styles.searchFieldContainer}>
				<input type="text" className={styles.searchingField}
					onChange={(e) => doSearch(e)}
					placeholder='חיפוש חופשי'/>
				<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"
						className={styles.magnifierIcon}>
					<g id="magnifying-glass" transform="translate(-0.4)">
						<rect id="Rectangle_204" data-name="Rectangle 204" width="28" height="28" transform="translate(0.4)"
								fill="none"/>
						<path id="Ellipse_1" data-name="Ellipse 1"
								d="M2.106-8A10.118,10.118,0,0,1,12.212,2.106,10.118,10.118,0,0,1,2.106,12.212,10.118,10.118,0,0,1-8,2.106,10.118,10.118,0,0,1,2.106-8Zm0,18.455A8.348,8.348,0,1,0-6.242,2.106,8.358,8.358,0,0,0,2.106,10.455Z"
								transform="translate(10.636 10.848)" fill="#3e3d3d"/>
						<path id="Line_4" data-name="Line 4"
								d="M-1.783-.9A.876.876,0,0,1-2.4-1.161L-7.743-6.5a.879.879,0,0,1,0-1.243.879.879,0,0,1,1.243,0L-1.161-2.4a.879.879,0,0,1,0,1.243A.876.876,0,0,1-1.783-.9Z"
								transform="translate(26.389 26.601)" fill="#3e3d3d"/>
					</g>
				</svg>
			</div>
		</div>
		<img src={filters} alt='filters' onClick={(e)=>{openFilters();}} className={styles.mobile} style={{cursor:'pointer'}} />
	  </div>
	  </div>
	</div>
	{(!documents.loading && ((currentRole === 'LABEL' && chousedSupplier) || (currentRole === 'SUPPLIER'))) && <div className={styles.tableBlock}>
	  <div className={styles.table} >
		<div className={styles.tableContents}>
			<div className={styles.headerContent}>
				<div></div>
				<div></div>
				<div>מס׳</div>
				<div>תאריך המסמך</div>
				<div>שם הלקוח</div>
				<div>תיאור</div>
				<div>סכום עסקה</div>
				<div>סטטוס חוזה</div>
				<div>סטטוס תשלום</div>
				<div>סכום</div>
				<div></div>
				<div></div>
			</div>
		</div>
		<Popup isOpen={isOpenPopup} isRead={isRead} setIsOpen={setIsOpenPopup} documentId={isCurrentId} />
		<PopupFilters isOpen={isOpenMobileFilters} onChange={check} setIsOpen={setisOpenMobileFilters} documents={documentStatus} currentDocument={currentDocumentStatus} setDocument={setcurrentDocumentStatus} currentDay={currentDays} setDay={setcurrentDays}  days={daysFilter} />
		<PopupDetails isOpen={isOpenMobileDetails} setIsOpen={setisOpenMobileDetails} downloadPDF={pdfDownload} openDetails={detailsInfo} currentDocument={currentDocument} />
		{!documents.loading && documents.data.map((item, index)=>{
				return (
				<div key={index}>
				<div className={styles.tableMonth}>
					<div>{getMonth(item.month)} {item.year}</div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
				{ item.contracts.map((val, index2)=>{
				// console.log('val Supp ', val);
				return (
				<div key={index2} className={styles.mainContent} style={{backgroundColor: val.amount >= 0 ? '#FFFFFF' : '#E7B1AD'}} >
					<div className={styles.empty}></div>
					<div className={classNames(styles.textBlock,styles.desctop)}>
						<div className={styles.contract_services_checkbox}>
							<div className={styles.radio}>
								<input
									type="checkbox"
									name={index +  '' + index2}
									id={index + '' + index2}
									checked={val.isSelected}
									value={val.isSelected}
									onChange={(e) => onChangeDocuments(index2, index, val.isSelected)}
								/>
								<label htmlFor={index + '' + index2}></label>
							</div>
						</div>
					</div>
					<div className={classNames(styles.textBlock,styles.contractId)}>{val.id}</div>
					<div className={classNames(styles.textBlock,styles.desctop)}>{convertDate(val.date)}</div>
					<div className={styles.supplierName} onClick={(e)=>{openSupplier(val.categoryId,val.supId,val.userId,val.eventTypeId,val.eventTypeName);}}>{val.customerName}</div>
					<div className={classNames(styles.textBlock,styles.desctop)}>{val.categoryName}</div>
					<div className={classNames(styles.textBlock,styles.desctop)}>{val.amount}<img src={shekel} alt='shekel' className={styles.shekel} /></div>
					{val.hasContract && val.status === 'PENDING' && <div className={classNames(styles.textBlock)} style={{fontWeight:'bold'}} >חתום עכשיו</div>}
					{val.hasContract && val.status === 'EXPIRED' && <div className={classNames(styles.textBlock)} style={{fontWeight:'bold', color: 'red'}}>פג תוקף</div>}
					{val.hasContract && val.status === 'SIGNED' && <div className={classNames(styles.textBlock)}>נחתם</div>}
					{val.hasContract && val.status === 'PAYED' && <div className={classNames(styles.textBlock)} >בתשלום</div>}
					{(!val.hasContract || val.status === 'UN_PAYED') && <div className={classNames(styles.textBlock)}>{'-'}</div>}
					<div className={styles.textBlock}>{val.paymentStatus ? val.paymentStatus === 'UN_PAYED' ? 'לא שולם' : 'שולם' : '-'}</div>
					<div className={classNames(styles.textBlock,styles.desctop)}>{val.advancePayment ? val.advancePayment : '-'}</div>
					<div className={classNames(styles.fileDownload,styles.desctop)}>
						{val.status !== 'EXPIRED' && <img src={file_active} alt='file' className={styles.download} onClick={(e)=>{openDocumentDialog(val.id,true);}} />}
						{val.status === 'EXPIRED' && <img src={file_inactive} alt='file' className={styles.download} />}
						<span className={styles.tooltiptext}>{"פירוט עסקה"}</span>
					</div>
					<div className={classNames(styles.fileDownload,styles.desctop)}>
						{(val.hasContract && val.status !== 'EXPIRED') && <img src={download_active} onClick={(e)=>{getPDF(val.id, val.supplierDocument);}} alt='download' className={styles.download} />}
						{(val.status === 'EXPIRED' || !val.hasContract) && <img src={download_inactive} alt='download' className={styles.download} />}
						<span className={styles.tooltiptext}>{"הורדת חוזה"}</span>
					</div>
					<div className={styles.details}> <img src={details} alt='details' onClick={(e)=>{openDetails(val)}} style={{cursor:'pointer'}} /> </div>
				</div>
				) 
				})}
				</div>
				)
			})
			}
	  </div>
	

	</div> }
	</>
	);
  }   
export default Documents;