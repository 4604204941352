import styles from './searchingField.module.css'
import {useNavigate} from "react-router";
import {useDispatch} from "react-redux";
import {setCurrentCategory} from "../../store/mainSlice";
import { useEffect } from 'react';



function SearchingField(props) {
    const navigate = useNavigate();
    const {searchHandler, category} = props;
    const dispatch = useDispatch();

    let timeout = 0;

    function doSearch(ev) {
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            if (searchHandler) {
                searchHandler(ev.target.value);
            }
        }, 500);
    }

      useEffect(()=> {
        dispatch(
          setCurrentCategory({
            currentCategory: category,
          })
        );
         // eslint-disable-next-line react-hooks/exhaustive-deps
      },[category])
      
    function goBackToCategory(category) {
        dispatch(setCurrentCategory({
          currentCategory: category
        }))
        navigate("/suppliers")
    }

    return (<div className={styles.searchFieldComponentContainer}>
        <div className={styles.searchFieldContainer}>
            <input type="text" className={styles.searchingField}
                   onChange={(e) => doSearch(e)}
                   placeholder='חיפוש ספקים'/>
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"
                 className={styles.magnifierIcon}>
                <g id="magnifying-glass" transform="translate(-0.4)">
                    <rect id="Rectangle_204" data-name="Rectangle 204" width="28" height="28" transform="translate(0.4)"
                          fill="none"/>
                    <path id="Ellipse_1" data-name="Ellipse 1"
                          d="M2.106-8A10.118,10.118,0,0,1,12.212,2.106,10.118,10.118,0,0,1,2.106,12.212,10.118,10.118,0,0,1-8,2.106,10.118,10.118,0,0,1,2.106-8Zm0,18.455A8.348,8.348,0,1,0-6.242,2.106,8.358,8.358,0,0,0,2.106,10.455Z"
                          transform="translate(10.636 10.848)" fill="#3e3d3d"/>
                    <path id="Line_4" data-name="Line 4"
                          d="M-1.783-.9A.876.876,0,0,1-2.4-1.161L-7.743-6.5a.879.879,0,0,1,0-1.243.879.879,0,0,1,1.243,0L-1.161-2.4a.879.879,0,0,1,0,1.243A.876.876,0,0,1-1.783-.9Z"
                          transform="translate(26.389 26.601)" fill="#3e3d3d"/>
                </g>
            </svg>
        </div>
        <div className={styles.linkForBack} onClick={() => {
                goBackToCategory(category)
                /* navigate("/suppliers") */
            }}>
            <span className={styles.link}>חזרה ל</span>
            <span className={styles.link}>{category?.name}</span>
        </div>
    </div>)
}

export default SearchingField
