import styles from './PhotoSlider.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import PopUp from '../../../components/popUp/PopUp';
import React, { useEffect, useState } from 'react';

function PhotoSlider({ slides, isVideo }) {
  const [my_swiper, set_my_swiper] = useState({});
  const [open, setOpen] = useState(false);
  const [openedImage, setOpenedImage] = useState('');
  const [is_beginning, set_is_beginning] = useState(false);
  const [is_end, set_is_end] = useState(false);
  const [id, setId] = useState();
  const closeDialog = () => {
    setOpen(false);
  };

  const openDialog = (imgUrl, id) => {
    console.log('Prev id', id);
    setOpenedImage(imgUrl);
    setId(id);
    setOpen(true);
  };

  function setValues(ev) {
    set_my_swiper(ev);
    set_is_beginning(my_swiper.isBeginning);
    set_is_end(my_swiper.isEnd);
  }

  const rebuild = (url) => {
    if (!url) {
      return '';
    }

    let newUrl = url.replace('watch?v=', 'embed/');

    return newUrl;
  };

  useEffect(() => {
    set_is_beginning(my_swiper.isBeginning);
    set_is_end(my_swiper.isEnd);
  }, [my_swiper]);

  function goToNextSlide(ev) {
    my_swiper.slideNext();
  }

  function goToPrevSlide(ev) {
    my_swiper.slidePrev();
  }

  const stylesForLeftArrow = `${styles.arrow} ${is_end ? 'disableSliderNextPrev' : ''}`;
  const stylesForRightArrow = `${styles.arrow} ${is_beginning ? 'disableSliderNextPrev' : ''}`;

  return (
    <>
      <div className={styles.swiperSlideContainer}>
        {/* <h2 className={styles.title}>גלריית תמונות</h2> */}
        <Swiper
          slidesPerView={'auto'}
          spaceBetween={20}
          centeredSlides={false}
          onInit={(ev) => {
            setValues(ev);
          }}
          onSlideChange={(ev) => {
            setValues(ev);
          }}
          onReachEnd={(ev) => {
            set_is_end(true);
            set_is_beginning(ev.isBeginning);
          }}
          onReachBeginning={(ev) => {
            set_is_end(false);
            set_is_beginning(ev.isBeginning);
          }}
        >
          {!!(slides && slides.length) &&
            slides.map((item, id) => {
              return (
                <React.Fragment key={`photo-slide-${id}`}>
                  {isVideo ? (
                    <SwiperSlide className={styles.slideWrapper} key={item.videoId}>
                      <div
                        onClick={() => openDialog(rebuild(item.videoUrl))}
                        className={styles.slideImage}
                      >
                        <iframe
                          style={{ pointerEvents: 'none' }}
                          width="100%"
                          height="100%"
                          src={rebuild(item.videoUrl)}
                          title={'video' + item.videoId}
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                        ></iframe>

                        {/* {item.videoId} */}
                      </div>
                      {/* {rebuild(item.videoUrl)} */}
                      {/* <img
                      src={item.videoUrl}
                      className={styles.slideImage}
                      alt="testImg"
                      onClick={() => openDialog(item.videoUrl)}
                    /> */}
                    </SwiperSlide>
                  ) : (
                    <SwiperSlide className={styles.slideWrapper} key={item.imageId}>
                      <img
                        src={item.imageUrl}
                        className={styles.slideImage}
                        alt="testImg"
                        onClick={() => openDialog(item.imageUrl, id)}
                      />
                    </SwiperSlide>
                  )}
                </React.Fragment>
              );
            })}
        </Swiper>

        {!!slides?.length && (
          <div className={styles.arrowContainer}>
            <div onClick={goToPrevSlide} className={stylesForRightArrow}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19.904"
                height="19.4"
                viewBox="0 0 19.904 19.4"
              >
                <path
                  id="Icon_awesome-arrow-right"
                  data-name="Icon awesome-arrow-right"
                  d="M8.463,3.947l.986-.986a1.062,1.062,0,0,1,1.506,0l8.636,8.631a1.062,1.062,0,0,1,0,1.506l-8.636,8.636a1.062,1.062,0,0,1-1.506,0l-.986-.986a1.067,1.067,0,0,1,.018-1.524l5.353-5.1H1.066A1.064,1.064,0,0,1,0,13.058V11.636A1.064,1.064,0,0,1,1.066,10.57H13.833L8.48,5.47A1.06,1.06,0,0,1,8.463,3.947Z"
                  transform="translate(0 -2.647)"
                  fill={is_beginning ? '#a4a2a2' : '#e7b1ad'}
                />
              </svg>
            </div>
            <div onClick={goToNextSlide} className={stylesForLeftArrow}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19.904"
                height="19.4"
                viewBox="0 0 19.904 19.4"
              >
                <path
                  id="Icon_awesome-arrow-right"
                  data-name="Icon awesome-arrow-right"
                  d="M8.463,3.947l.986-.986a1.062,1.062,0,0,1,1.506,0l8.636,8.631a1.062,1.062,0,0,1,0,1.506l-8.636,8.636a1.062,1.062,0,0,1-1.506,0l-.986-.986a1.067,1.067,0,0,1,.018-1.524l5.353-5.1H1.066A1.064,1.064,0,0,1,0,13.058V11.636A1.064,1.064,0,0,1,1.066,10.57H13.833L8.48,5.47A1.06,1.06,0,0,1,8.463,3.947Z"
                  transform="translate(19.904 22.047) rotate(180)"
                  fill={is_end ? '#a4a2a2' : '#e7b1ad'}
                />
              </svg>
            </div>
          </div>
        )}
      </div>

      {isVideo ? (
        <PopUp
          content={
            <div style={{ widows: '100%', height: '50vh', overflow: 'hidden' }}>
              <iframe title="Demo video" width="100%" height="100%" src={openedImage}></iframe>
            </div>
          }
          closeDialog={closeDialog}
          isVideo={true}
          open={open}
        />
      ) : (
        <PopUp closeDialog={closeDialog} open={open} isVideo={false} id={id} slides={slides} />
      )}
    </>
  );
}

export default PhotoSlider;
