import { Typography } from '@mui/material';
import defaultImg from '../../../../../assets/images/suppliers/defaultImg.png';
import styles from '../../styleSuppliers.module.scss';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import { setCurrentCategory } from '../../../../store/mainSlice';
import { useDispatch } from 'react-redux';
const Category = (props) => {
  const { setValue, watch } = useFormContext();
  const currentCategory = watch('category');
  const dispatch = useDispatch()
  const { item } = props;
  const onClick = () => {
    setValue('category', item);
    dispatch(
      setCurrentCategory({
        currentCategory: item,
      })
    )
    setValue('categoryFiltersId', []);
  };
  return (
    <div
      onClick={onClick}
      id={`category-${item.id}`}
      className={classNames(
        styles.category_container,
        currentCategory?.id === item.id && styles.select_category
      )}
    >
      <div className={styles.category_img_container}>
        <img src={item?.iconUrl || defaultImg} alt="icon-category" />
      </div>
      <Typography component={'h3'} className={styles.category_name}>
        {item.name}
      </Typography>
    </div>
  );
};
export default Category;
