import { useState, useEffect } from "react"
import { Helmet } from "react-helmet-async";
//import img from "../../../assets/images/for-pages/eventDo.jpeg";

const MetaHome = () => {
    const [ icon,setIcon ]=useState(sessionStorage.getItem('url'))

    useEffect(()=>{
        setIcon(sessionStorage.getItem('url'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[sessionStorage.getItem('url')])

    return(
        <Helmet>
            <meta property="og:image" content={process.env.REACT_APP_API_PATH + 'static/media/eventDo.2021aadd1ab23ff25c69.jpeg'} ></meta>
            <meta charSet="utf-8" />
            <title> להפיק בקליק! - EventDO </title>
            <meta name="description" content="איוונטדו - באנו לעשות לכם סדר בתכנון האירוע. חפשו את הספקים שיתאימו בדיוק לאירוע שלכם!"></meta>
            <meta property="להפיק בקליק! - EventDO"></meta>
            <link rel="canonical" href="http://mysite.com/example" />
            <meta property="og:site_name" content="להפיק בקליק! - EventDO"></meta>
            <meta property="og:url" content={window.location.href}></meta>
            <meta property="og:description" content="איוונטדו - באנו לעשות לכם סדר בתכנון האירוע. חפשו את הספקים שיתאימו בדיוק לאירוע שלכם!"></meta>
            <link rel="icon" href={icon} />     
        </Helmet>
    )
}
export default MetaHome;
