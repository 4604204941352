import image from "../../../assets/images/for-pages/file_svg_checkist.svg"
import styles from "./checklistButton.module.scss";
import { useNavigate } from "react-router";
import useAuth from "../../hooks/useAuth";
const ChecklistButton = () => {
  const {auth} = useAuth()
  const navigate = useNavigate();
    return (
    <>
      {auth?.roles?.[0] !== "SUPPLIER" && auth?.roles?.[0] !== "LABEL" && <div style={{cursor: 'pointer'}}  onClick={()=>navigate(auth?.roles?.[0] === "USER" ? `/personal/checklist` : `/personal-guest/checklist`)} className={styles.wrapper}>
        <img alt="img" src={image} />
      </div>}
    </>
    )
}
export default ChecklistButton