import React, { useEffect, useRef, useState } from "react";
import styles from "./select.module.scss";
import arrow from "../../../assets/images/for-pages/caret-down.png";
import arrowUp from "../../../assets/images/for-pages/caret-up.png";

export default function Select(props) {

  const {
    disabled,
    datalist,
    fontSize,
    justifyContent,
    backgroundColor,
    borderRadius,
    color,
    fontFamily,
    fontFamilyHeader,
    paddingInlineStart,
    paddingInlineEnd,
  } = props;

  const main = {
    borderRadius,
    backgroundColor,
  };

  const dropDownContainer = {
    // width, height,
  };

  const dropDownHeader = {
    paddingInlineStart,
    paddingInlineEnd
  };

  const headerOption = {
    color: props?.selectedOption?.name ? "#242323" : '#9B9999',
    //
    fontFamily: fontFamilyHeader,
    justifyContent,
    width: "100%",
  };

  const dropDownList = {
    // backgroundColor,
    // width,
  };

  const dropDownListOption = {
    justifyContent,
    fontSize,
    color,
    "&:hover": {
      fontFamily,
    },
  };

  const rootEl = useRef(null);
  const inputEl = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const toggling = (is) => {
    if(disabled){
      setIsOpen(false)
    }else{
      setIsOpen(is || !isOpen)
    }
  };

  const onOptionClicked = (value) => () => {
    props.onChangeOption(value);
    setIsOpen(false);
  };

  useEffect(() => {
    const onClick1 = (e) =>
      {
        rootEl.current.contains(e.target) || setIsOpen(false)
      };
    document.addEventListener("click", onClick1);
    return () => document.removeEventListener("click", onClick1);
  }, []);

/*   useEffect(() => {}, [props]); */
useEffect(() =>{
  if (isOpen && inputEl.current) {
    inputEl.current.focus()
  } else {
    setInputValue('')
  }
},[isOpen])

  return (
    <div ref={rootEl} className={styles.main} style={main}>
      <div style={dropDownContainer} className={styles.dropDownContainer}>
        <div className={styles.dropDownHeader}  style={dropDownHeader}>
          {
            (isOpen && datalist) ?
            
            <input ref={inputEl} onClick={() => toggling}
              value={inputValue || ""}
              onChange={
                (e) => {
                  setInputValue(e.target.value)
                }
              }
            />
            : 
            <span onClick={(ev) => { toggling();ev.stopPropagation(); }} style={headerOption} className={styles.headerOption}>
              {props.selectedOption?.name ||
                props.placeholder ||
                "בחר מתוך הרשימה"}
            </span>

          }
          
          <img
            onClick={ () => toggling()}
            alt="arrow"
            src={isOpen ? arrowUp : arrow}
            className={styles.arrow}
          />
        </div>
        {isOpen && (
          <div className={styles.dropDownListContainer}>
            <div style={dropDownList} className={styles.dropDownList}>
              {props?.options?.filter((elem) => (
                elem.name.toLowerCase().includes(inputValue.toLowerCase())
              )).map(option => 
                <div
                  style={dropDownListOption}
                  className={styles.listItem}
                  onClick={onOptionClicked(option)}
                  key={Math.random()}
                >
                  <span
                    style={{
                      fontFamily:
                        option?.id === props.selectedOption?.id
                          ? fontFamily || "Heebo-Bold"
                          : "",
                    }}
                  >
                    {option.name}
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>

  );
}
