import React, { useEffect } from 'react';
import Photo from './Photo';
import Form from './form/Form';
import styles from './styleBasicForm.module.scss';
import { useDispatch } from 'react-redux';
import { setCurrentStep } from '../../../store/mainSlice';
import { STEPS_ENUM } from './form/stepForm.js/stepForm.config';

const BasicInformationForm = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(setCurrentStep(STEPS_ENUM.TYPE_EVENT));
    };
  }, []);
  return (
    <section className={styles.container}>
      <Form />
      <Photo />
    </section>
  );
};

export default BasicInformationForm;
