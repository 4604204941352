import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import {BrowserRouter} from "react-router-dom";
import "./app/i18n";
import {Provider} from "react-redux";
import store from "./app/store";
import Loader from "./app/components/loader/Loader";
import {AuthProvider} from "./app/context/AuthProvider";
import {GoogleOAuthProvider} from "@react-oauth/google";
import ErrorMessenger from "./app/components/errorMessenger/ErrorMessenger";
// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Import the functions you need from the SDKs you need import { initializeApp } from "firebase/app"; // TODO: Add SDKs for Firebase products that you want to use // https://firebase.google.com/docs/web/setup#available-libraries  // Your web app's Firebase configuration
// Initialize Firebase const app = initializeApp(firebaseConfig);
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
//const firebaseConfig = {   apiKey: "AIzaSyCUr6amPjTYOJh-1LxKt-BQcq9R4UYqYns",   authDomain: "eventdo-b5a17.firebaseapp.com",   projectId: "eventdo-b5a17",   storageBucket: "eventdo-b5a17.appspot.com",   messagingSenderId: "836358834805",   appId: "1:836358834805:web:aa46888ba561bfe387c63c" };
// const firebaseConfigDev = {   apiKey: "AIzaSyCUr6amPjTYOJh-1LxKt-BQcq9R4UYqYns",
//   authDomain: "eventdo-b5a17.firebaseapp.com",
//     projectId: "eventdo-b5a17",
//      storageBucket: "eventdo-b5a17.appspot.com",
//         messagingSenderId: "836358834805",
//          appId: "1:836358834805:web:aa46888ba561bfe387c63c" };
// const firebaseConfigStg = {
//   apiKey: "AIzaSyCopBwGIylJhOzbt7tfMGH1V0Yzsjl3xss",
//   authDomain: "eventdo-stg.firebaseapp.com",
//   projectId: "eventdo-stg",
//   storageBucket: "eventdo-stg.appspot.com",
//   messagingSenderId: "501798638736",
//   appId: "1:501798638736:web:08f779fa8ac97d2319f415"
// };
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_REL_DB_API_KEY,
  authDomain: process.env.REACT_APP_FB_REL_DB_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FB_REL_DB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_REL_DB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_REL_DB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_REL_DB_APP_ID,
};
//const env = process.env.REACT_APP_API_PATH.indexOf('api-dev') !== -1 ? firebaseConfigDev : firebaseConfigStg
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
if (0) {
  console.log(analytics);
}

const gtmParams = {
  id: process.env.REACT_APP_GTM_ID,
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <GTMProvider state={gtmParams}>
      <BrowserRouter>
        <AuthProvider>
          <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
            <Provider store={store}>
              <ErrorMessenger />
              <Loader />
              <App />
            </Provider>
          </GoogleOAuthProvider>
        </AuthProvider>
      </BrowserRouter>
    </GTMProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
