import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearErr } from "../../store/forAll/errSlice";
import styles from "./errorMessenger.module.scss";
import errorCodesHe from './ErrorCodes.json'

const ErrorMessenger = () => {
  let { isShow, message, type, time, code } = useSelector((state) => state.err)
  const dispatch = useDispatch();

  const [errMess, setErrMess] = useState('')

  //types: 1 - error; 2 - success

  const selectedLanguage = (lang='he') =>{
    let data;
    switch (lang) {
      case 'he': data = errorCodesHe; break;
      default: data = errorCodesHe; break;
    }
    return data
  }
  const findedMess = (code) => {
    const file = selectedLanguage()
    return file[Number(code)] ?? ''
  } 
  useEffect(() => {
    setErrMess(findedMess(code))
    let timeout = setTimeout(() => {
      if(isShow){
        dispatch(clearErr())
      }
    },(time || 2000))
    return(() => {
      clearTimeout(timeout);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow])

  useEffect(() => {
    let timeout;
    if(message){
        timeout = setTimeout(() => {
        if(isShow){
          dispatch(clearErr())
        }
      },(time || 2000))
    }
    return(() => {
      clearTimeout(timeout);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message])
  
  
 
  return (
  <>
    { 
    (isShow && message)
    &&
      <div className={ type === 1 ? styles.errorContainer : styles.successContainer}>
        <div className={styles.messageContainer}>
          {errMess === '' ? message  : errMess}
        </div>
      </div>
    }
  </>
    
    
  )
}

export default ErrorMessenger