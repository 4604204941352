import { useLayoutEffect, useRef, useState } from "react";
import styles from "./imagesGrid.module.scss";
import "./dndPolyfill";

const ImagesGrid = (props) => {
  // const [items, setItems] = useState(props.images); // supply your own state
 
  // target id will only be set if dragging from one dropzone to another.
  // function onChange(sourceId, sourceIndex, targetIndex, targetId) {
  //   console.log(sourceIndex)
  //   const nextState = swap(items, sourceIndex, targetIndex);
  //   setItems(nextState);
  // }
  const {changeOrder} = props
  const dragItem = useRef();
  const dragOverItem = useRef();

  let [scrollable, setScrollable] = useState(true)
  // let scrollable = true;

  let listener = (e) => {
    if(!scrollable){
      e.preventDefault();
    }
  }

  useLayoutEffect(() => {
    const wrap = document.getElementById("samiRoot")
    wrap.addEventListener('touchmove', listener, {passive: false})
    return(() => {
      wrap.removeEventListener('touchmove', listener, {passive: false})
    
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useLayoutEffect(() => {
    const wrap = document.getElementById("samiRoot")
    if(!scrollable){
      wrap.style.overflow = 'hidden'
    }else(
      wrap.style.overflow = 'auto'
    )
    return(() => {
      wrap.style.overflow = 'auto'
    
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[scrollable])

  
  // const [list, setList] = useState(props.images);

  const dragStart = (e, position) => {
    setScrollable(false);
    // document.documentElement.style.overflow = 'hidden';
    dragItem.current = position;
    // console.log(e.target.innerHTML);
  };
 
  const dragEnter = (e, position) => {
    dragOverItem.current = position;
    // console.log(e.target.innerHTML);
  };

  const reorderBeforeSave = (images) => {
    // console.log(images)
    let newImages = images?.map((image, index) => {
      let newImage = {...image};
      newImage.order = index;
      if(index===0){
        newImage.defaultImage = true;
      }else{
        newImage.defaultImage = false;
      }
      return newImage;
    })
    // console.log(newImages)
    return newImages
  }
 
  const drop = (e) => {
    // document.documentElement.style.overflow = 'auto';
    const copyListItems = [...props?.images];
    const dragItemContent = copyListItems[dragItem.current];
    if(!dragItemContent) return
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    
    changeOrder(reorderBeforeSave(copyListItems))
    setScrollable(true);
    // setList(copyListItems);
  };
  
  return (
    <div className={styles.imagesWrapper}>
      {props?.images &&
        props?.images.map((item, index) => (
          <div
            className={styles.imageWrap}
            onDragStart={(e) => dragStart(e, index)}
            onDragEnter={(e) => dragEnter(e, index)}
            onDragEnd={drop}
            key={(item.id || "") +'/' + (item.imageId || " ") + '/' + item.order + '/' + index + " "}
            draggable
          >
            { index === 0 &&
              <span className={styles.first}>{"התמונה הראשונה בגלריית התמונות שלך"}</span>}
            {(item?.imageUrl || item?.url) &&
              (
                ((item.fileType === "VIDEO") || 
                  (item.type === 'video')) 
                ? (
                <video controls autoPlay="" alt="" src={item.imageUrl || item.url} />
              ) : (
                <img alt="" src={item.imageUrl || item.url} />
              ))}

            {/* {item.id} */}
          </div>
        ))}
    </div>
  );
}

export default ImagesGrid