import styles from './Blog.module.scss';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import HeaderMobile from '../../components/headerMobile/HeaderMobile';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { sendContact } from '../../store/mainSlice';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import MetaBlog from '../../components/metaComponents/metaBlog';
import ChecklistButton from '../../components/checklistButton/checklistButton';
import WhatsApp from '../../components/whatsApp/whatsApp';
import { setLoader } from '../../store/loaderSlice';

function Blog() {
  const [blog, setAppState] = useState([]);
  const navigate = useNavigate();
  const [firstItem, setFirstItem] = useState({ data: null, loading: true });
  let [name, setName] = useState('');
  let [number, setNumber] = useState('');
  const dispatch = useDispatch();
  let [numberError, setNumberError] = useState({ error: false, value: '' });
  let [nameError, setNameError] = useState({ error: false, value: '' });
  const [sendInfo, setsendInfo] = useState(false);

  const sendFormToConnect = () => {
    const data = {
      phoneNumber: number,
      name: name,
    };
    if (name.trim().length < 2) {
      setNameError({ error: true, value: 'לפחות 2 אותיות' });
    } else if (
      number.trim().length < 2 ||
      (number.trim().length !== 9 && number.trim().length !== 10)
    ) {
      setNumberError({ error: true, value: 'מספר הטלפון שגוי' });
    } else if (number[0] !== '0') {
      setNumberError({ error: true, value: 'מספר הטלפון צריך להתחיל מאפס' });
    } else {
      setNumberError({ error: false, value: '' });
      setNameError({ error: false, value: '' });
      dispatch(sendContact(data)).then(() => {
        setName('');
        setNumber('');
      });
      setsendInfo(true);
    }
  };

  const checkNumber = (value) => {
    if (
      value.trim().length > 0 &&
      value[0] === '0' &&
      (value.trim().length === 9 || value.trim().length === 10)
    ) {
      setNumberError({ error: false, value: '' });
    }
  };

  const checkName = (value) => {
    if (value.trim().length > 0) {
      setNameError({ error: false, value: '' });
    }
  };

  useEffect(() => {
    dispatch(setLoader(true));
    let div = document.getElementsByClassName('samyBody');
    div[0].scrollTop = 0;
    const fetchData = async () => {
      const result = await axios(`${process.env.REACT_APP_API_PATH}main/blog`);
      setAppState(result.data);
      const mainItem = result.data.filter((el) => el.isMain === true)[0];
      setFirstItem({ data: mainItem, loading: false });
      dispatch(setLoader(false));
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAppState]);

  function blockDetailsById(id) {
    navigate(`/blog/${id}`);
  }

  function checkImageFormat(value) {
    if (
      value === 'jpeg' ||
      value === 'png' ||
      value === 'svg' ||
      value === 'gif' ||
      value === 'jpg' ||
      value === 'webp'
    ) {
      return true;
    }
    return false;
  }

  function replaceTagsP(value) {
    return value.replace(/p>/gi, 'div>');
  }

  return (
    <>
      {/* <WhatsApp /> */}
      <MetaBlog />
      <Header />
      <HeaderMobile />
      <div className={styles.desctopMainBlock}></div>
      <ChecklistButton />
      <div>
        {!firstItem.loading && firstItem.data != null && (
          <section className={styles.main}>
            <div className={styles.info}>
              <div className={styles.infoLeft}>
                {firstItem.data.type === 'VIDEO' ? (
                  <iframe
                    width="100%"
                    height="100%"
                    src={firstItem.data.videoUrl}
                    title={'video'}
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                ) : (
                  <>
                    {checkImageFormat(firstItem.data.fileType) && (
                      <img src={firstItem.data.fileUrl} className={styles.mainImg} alt="testImg" />
                    )}
                    {!checkImageFormat(firstItem.data.fileType) && (
                      <video className={styles.slideVideo} controls>
                        <source src={firstItem.data.fileUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    )}
                  </>
                )}
              </div>
              <div className={styles.infoRight}>
                <h2 className={styles.infoTitle}>{firstItem.data.articleHeader}</h2>
                <div
                  className={styles.description}
                  dangerouslySetInnerHTML={{ __html: replaceTagsP(firstItem.data.articleText) }}
                ></div>
                <div className={styles.blockButton}>
                  {false && <button className={styles.infoButton}>תכננו את אירוע הבזק שלכם</button>}
                </div>
              </div>
            </div>
          </section>
        )}

        <section className={styles.swiperSlideContainer}>
          <Swiper slidesPerView={'auto'} spaceBetween={61} centeredSlides={false}>
            {blog &&
              blog.map((item) => {
                return (
                  <SwiperSlide className={styles.slideWrapper} key={item.id}>
                    <div>
                      <div className={styles.imgBlock}>
                        {item.type === 'VIDEO' ? (
                          <div
                            onClick={() => blockDetailsById(item.id)}
                            style={{
                              cursor: 'pointer',
                              width: '100%',
                              height: '100%',
                            }}
                          >
                            <iframe
                              style={{ pointerEvents: 'none' }}
                              width="100%"
                              height="100%"
                              src={item.videoUrl}
                              title={'video'}
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowFullScreen
                            ></iframe>
                          </div>
                        ) : (
                          <>
                            {checkImageFormat(item.fileType) && (
                              <img
                                onClick={() => blockDetailsById(item.id)}
                                src={item.fileUrl}
                                className={styles.slideImage}
                                alt="testImg"
                              />
                            )}
                            {!checkImageFormat(item.fileType) && (
                              <video className={styles.slideVideo} controls>
                                <source src={item.fileUrl} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            )}
                          </>
                        )}
                      </div>
                      <h2 onClick={() => blockDetailsById(item.id)} className={styles.slideTitle}>
                        {item.articleHeader}
                      </h2>
                      <div
                        onClick={() => blockDetailsById(item.id)}
                        className={styles.slideDescription}
                        dangerouslySetInnerHTML={{ __html: replaceTagsP(item.articleText) }}
                      ></div>
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </section>

        <h2 className={styles.blogTitle}>הבלוג שלנו</h2>
        <section className={styles.mobileSwiperSlide}>
          {blog &&
            blog.map((item) => {
              return (
                <div className={styles.mobileSlideWrapper} key={item.id}>
                  <div className={styles.content}>
                    <div className={styles.imgContainer}>
                      {item.type === 'VIDEO' ? (
                        <div
                          onClick={() => blockDetailsById(item.id)}
                          style={{
                            cursor: 'pointer',
                            width: '100%',
                            height: '16.8125rem',
                          }}
                        >
                          <iframe
                            style={{ pointerEvents: 'none' }}
                            width="100%"
                            height="100%"
                            src={item.videoUrl}
                            title={'video'}
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                          ></iframe>
                        </div>
                      ) : (
                        <>
                          {checkImageFormat(item.fileType) && (
                            <img
                              onClick={() => blockDetailsById(item.id)}
                              src={item.fileUrl}
                              className={styles.mobileImgBlock}
                              alt="testImg"
                            />
                          )}
                          {!checkImageFormat(item.fileType) && (
                            <video className={styles.slideVideo} controls>
                              <source src={item.fileUrl} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          )}
                        </>
                      )}
                    </div>
                    <h2
                      onClick={() => blockDetailsById(item.id)}
                      className={styles.mobileSlideTitle}
                    >
                      {item.articleHeader}
                    </h2>
                    <div
                      onClick={() => blockDetailsById(item.id)}
                      className={styles.mobileSlideDescription}
                      dangerouslySetInnerHTML={{ __html: replaceTagsP(item.articleText) }}
                    ></div>
                    {/* {!checkImageFormat(item.fileType) && <div className={styles.mobileButtonBlock}>
                            <button className={styles.mobileSliderButton}>אירוע בזק</button>
                          </div>} */}
                  </div>
                </div>
              );
            })}
        </section>

        <section className={styles.fifth}>
          <h2 className={styles.fifthTitle}>רוצים לדעת יותר? הזינו פרטים ונחזור אליכם</h2>
          <div className={styles.fifthFields}>
            <div className={styles.inputContainer} style={{ marginLeft: '3rem' }}>
              <input
                onChange={(e) => {
                  setName(e.target.value);
                  checkName(e.target.value);
                }}
                value={name}
                type="text"
                placeholder="שם מלא"
              />
              {nameError.error && <p className={styles.errorMessage}>{nameError.value}</p>}
            </div>
            <div className={styles.inputContainer}>
              <input
                className={styles.phoneInput}
                onChange={(e) => {
                  setNumber(e.target.value);
                  checkNumber(e.target.value);
                }}
                value={number}
                type="text"
                placeholder="מספר טלפון"
              />
              {numberError.error && <p className={styles.errorMessage}>{numberError.value}</p>}
            </div>
          </div>
          <div className={styles.fifthBtn}>
            <div onClick={() => sendFormToConnect()} className={styles.button}>
              שלח
            </div>
          </div>

          <div></div>
        </section>
        <section className={styles.beforeFooter}></section>
      </div>
      <section className={styles.desctop}>
        <Footer />
      </section>
      <Dialog
        hideBackdrop={true}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '400px',
              boxShadow: 'none',
              overflow: 'hidden',
              borderRadius: '0',
            },
          },
        }}
        open={sendInfo}
        fullWidth={true}
        className={styles.infoDialog}
      >
        <div className={styles.mainPopup}>
          <div className={styles.close}>
            <svg
              style={{ cursor: 'pointer' }}
              xmlns="http://www.w3.org/2000/svg"
              width="25.477"
              height="25.47"
              viewBox="0 0 25.477 25.47"
              onClick={() => setsendInfo(!sendInfo)}
            >
              <path
                id="Icon_ionic-ios-close"
                data-name="Icon ionic-ios-close"
                d="M27.042,24.024l9.1-9.1a2.132,2.132,0,1,0-3.015-3.015l-9.1,9.1-9.1-9.1a2.132,2.132,0,1,0-3.015,3.015l9.1,9.1-9.1,9.1a2.132,2.132,0,0,0,3.015,3.015l9.1-9.1,9.1,9.1a2.132,2.132,0,0,0,3.015-3.015Z"
                transform="translate(-11.285 -11.289)"
                fill="#242323"
              />
            </svg>
          </div>
          <div className={styles.blockCalendar}>
            <div className={styles.infoDialogClose}>
              <div className={styles.infoDialogtitle}>
                ‌פנייתך נשלחה אלינו ‌ ‌בהצלחה,‌ ‌מבטיחים ליצור איתך ‌ ‌קשר בהקדם ‌ ‌האפשרי
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}
export default Blog;
