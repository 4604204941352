import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";

const lastPathSlice = createSlice({
  name: "lastPath",
  initialState: {
      lastPath: ""
  },
  reducers: {
    setLastPath(state, action) {
      const {path} = action.payload;
      state.lastPath = path;
    },
    clearLastPath(state, action) {
      state.lastPath = '';
    },
    
  },
})

export const { setLastPath, clearLastPath } = lastPathSlice.actions;

export default lastPathSlice.reducer;
