import { useDispatch, useSelector } from 'react-redux';
import { EXISTS_PLACE_ENUM } from '../stepForm.config';
import { fetchAllEvetns, setCodeModalIsOpen } from '../../../../../../store/mainSlice';
import { useSubmigLogic } from './useSubmitLogic';
import { useFormContext } from 'react-hook-form';
import useAuth from '../../../../../../hooks/useAuth';
import { useEffect } from 'react';
export const useStepFormLogic = () => {
  const { auth } = useAuth();
  const currentRole = Object.keys(auth).length !== 0 && auth?.roles?.length ? auth?.roles[0] : '';
  const { handleSubmit, factoryHandleSubmit, isLoading } = useSubmigLogic({ currentRole });
  const dispatch = useDispatch();
  const {allEvents, currentStep, codeModalIsOpen } = useSelector((state) => state.main);
  const form = useFormContext();
  const existsPlace = form.watch('existsPlace');
  const isExistsPlace = !!existsPlace ? existsPlace === EXISTS_PLACE_ENUM.YES : true;
  const handleCloseModal = () => {
    dispatch(setCodeModalIsOpen(false));
  };
  useEffect(() => {
    if (!!allEvents || allEvents.length === 0) {
      dispatch(fetchAllEvetns());
    }
  }, []);
  return {
    isExistsPlace,
    currentRole,
    handleCloseModal,
    currentStep,
    handleSubmit,
    factoryHandleSubmit,
    isLoading,
    codeModalIsOpen,
  };
};
