import HelperText from './HelperText';
import TypeEvent from './TypeEvent';
import styles from '../styleSuppliers.module.scss';
import Categories from './categories/Categories';

const HeaderSuppliers = () => {
  return (
    <div id="header_suppliers" className={styles.header_suppliers}>
      <HelperText />
      <TypeEvent />
      <Categories />
    </div>
  );
};
export default HeaderSuppliers;
