import { Button, Divider, Drawer, Typography } from '@mui/material';
import styles from '../../styleSuppliers.module.scss';
import { useGetData } from './useGetData';
import { useEffect, useState } from 'react';
import CategoryFilter from './CategoryFilter';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';
const MobileCategoryFilters = (props) => {
  const { open, onClose } = props;
  const { categoryFilters } = useGetData();
  const [selectItems, setSelectItems] = useState([]);
  const { setValue } = useFormContext();
  useEffect(() => {
    return () => {
      setSelectItems([]);
    };
  }, []);
  const setNewValue = (value) => {
    setSelectItems(value);
  };
  const onSubmit = () => {
    setValue('categoryFiltersId', selectItems);
    onClose();
  };
  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <div
        className={classNames(
          styles.mobile_category_filters_container,
          styles.filters_sidebar_container
        )}
      >
        <Typography className={styles.filters_sidebar_title}>מיון ספקים</Typography>
        <Divider></Divider>
        <div className={styles.mobile_category_filters_row}>
          {categoryFilters?.map((oneFilter) => (
            <CategoryFilter
              setValue={setNewValue}
              allItems={selectItems}
              key={`category-filter-${oneFilter.id}`}
              item={oneFilter}
            />
          ))}
        </div>
        <Button
          onClick={onSubmit}
          // disabled={selectItems.length === 0}
          variant="contained"
          sx={{
            margin: '38px 0px',
            backgroundColor: '#E7B1AD',
            maxWidth: '83px',
            minWidth: 'unset',
            width: '100%',
            height: '45px',
            fontSize: '14px',
            lineHeight: '21px',
            borderRadius: '8px',
          }}
        >
          המשך
        </Button>
      </div>
    </Drawer>
  );
};
export default MobileCategoryFilters;
