import { Typography } from '@mui/material';
import styles from '../../../../styleBasicForm.module.scss';
import CustomAutoComplete from '../../../../../../../components/customAutoComplete/CustomAutoComplete';
import { useAutoCompleteStepLogic } from './useAutoCompleteStepLogic';
const AutoCompleteStep = (props) => {
  const { name, label, placeholder, rules, nextStep } = props;
  const { getRules, onChangeInput, onSkip, allServices } = useAutoCompleteStepLogic({
    name,
    rules,
    nextStep,
  });
  return (
    <div className={styles.one_step}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography className={styles.one_step_label}>{label}</Typography>
        {/* {nextStep && (
          <Typography onClick={onSkip} className={styles.miss_text}>
            דלג
          </Typography>
        )} */}
      </div>
      <div className={styles.field_container} style={{ flex: '1 0 auto' }}>
        <CustomAutoComplete
          name={name}
          rules={getRules()}
          options={
            allServices
              ? allServices.map((oneItem) => ({ id: oneItem.id, label: oneItem.name }))
              : []
          }
          placeholder={placeholder}
          onChangeInput={onChangeInput}
          autoCompleteProps={{
            renderOption: (itemProps, option) => (
              <li {...itemProps} key={option.id}>
                {option.label}
              </li>
            ),
            filterOptions: (options, state) => options,
            freeSolo: true,
          }}
          inputProps={{ sx: { height: { xs: '42px', sm: '53px' } } }}
        />
      </div>
    </div>
  );
};
export default AutoCompleteStep;
