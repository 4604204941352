import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import axios from "../../api/axios";

// export const downLoadDocument = createAsyncThunk(
//   "supplierDocument/downLoadDocument",
//   async function ({contractId, axiosPrivate, isShow}, {rejectWithValue}) {
    
//     try {
//       const response = await axiosPrivate.get(`/contract/document?contractId=${contractId}`,{responseType: 'blob'});
//       console.log(response)
//       if (response.status!==200) {
//         throw new Error('Server Error');
//       }
//       const data = await response.data;
//       //download

//       if(!isShow){
//         console.log(123)
// 			const url = window.URL.createObjectURL(new Blob([data]));
// 			const link = document.createElement("a");
// 			link.href = url;
// 			link.setAttribute("download", "file.pdf");
// 			document.body.appendChild(link);
// 			link.click();}



//       // if(isShow){const file = new Blob(
//       // [data], 
//       // {type: 'application/pdf'});
//       //   //Build a URL from the file
//       // const fileURL = URL.createObjectURL(file);
//       //   //Open the URL on new Window
//       // window.open(fileURL);}


//       console.log(data) 
//       return JSON.stringify(data);
//     } catch (error) {
//       return rejectWithValue(error)
//     }
//   }
// );

export const loadTableByFilters = createAsyncThunk(
  "supplierDocument/loadTableByFilters",
  async function ({filters, axiosPrivate}, {rejectWithValue}) {
    // {
    //   "customerName": "string",
    //   "contractId": "string",
    //   "date": "2023-01-19",
    //   "eventTypesId": [
    //     0
    //   ]
    // }
    try {
      const response = await axiosPrivate.post(`/contract/supplier/month`, {filters});
      // console.log(response)
      if (response.status!==200) {
        throw new Error('Server Error');
      }
      const data = await response.data;
      // console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getAllEvents = createAsyncThunk(
  "supplierDocument/getAllEvents",
  async function ({axiosPrivate}, {rejectWithValue}) {
    
    try {
      // console.log("slice")
      const response = await axiosPrivate.get(`/contract/supplier/eventType`);
      // console.log(response)
      if (response.status!==200) {
        throw new Error('Server Error');
      }
      const data = await response.data;
      // console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);


const supplierDocumentSlice = createSlice({
  name: "supplierDocument",
  initialState: {
    filterDate: '',
    allEvents: [],
    selectedEvents: [],

    error: null,
    loadingStatus: null,
  },
  reducers: {
    setSelectedEvents(state,action) {
      state.selectedEvents = action.payload;
    },
    setFilterDate(state, action) {
      
      // console.log(action.payload)
      state.filterDate = action.payload;
    },
    clearForm(state,action) {
      state.filterDate = '';
      state.selectedEvents=[];
    },
  },
  extraReducers: {
    
    [getAllEvents.fulfilled]: (state, action) => {
      // console.log(action.payload)
      state.allEvents = action.payload;
      // state.email = action.payload.email;
    },
    // [downLoadDocument.fulfilled]: (state, action) => {
    //   console.log("ok")
    // }
    
  },
});

export const {setFilterDate, clearForm, setSelectedEvents } = supplierDocumentSlice.actions;

export default supplierDocumentSlice.reducer;
