import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import React, {useEffect, useState} from "react";
import styles from "./changeUser.module.scss";
import {styled} from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import arrowTop from "./img/arrowTop.png";
import buyme from "./img/buyme.svg";
import { useNavigate} from "react-router";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import classNames from "classnames";
import {useForm} from "react-hook-form";
// import axios from "../../../../api/axios";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import {useDispatch} from "react-redux";
import {setErr} from "../../../store/forAll/errSlice";
import {
  getContracts,
  getEvents,
  getUserData,
  removeEvent,
  saveUser,
} from "../../../store/personalUser/changeUserSlice";
import useAuth from "../../../hooks/useAuth";
import dayjs from "dayjs";
import he from "dayjs/locale/he"; // load on demand
import localizedFormat from "dayjs/plugin/localizedFormat";
import trash from "../../../../assets/images/trash.png";
import axios from "../../../../api/axios";
import PopUp from "../../../components/defaultPopUp/PopUp";
import { savePath } from "../../../store/returnToUrlSlice";
import { setIsOpenUserAuth } from "../../../store/mainSlice";
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
  [theme.breakpoints.down("md")]: {
    margin: "0 1.3125rem 0 1.3125rem",
  },
  [theme.breakpoints.up("md")]: {
    margin: "0 3.5rem 0 3.5rem",
  },

  border: `none`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <div style={{height: "1.8125rem"}}>
        <img alt="arrow" src={arrowTop} />
      </div>
    }
    {...props}
  />
))(({theme}) => ({
  [theme.breakpoints.down("md")]: {
    height: "2.9375rem",
  },
  [theme.breakpoints.up("md")]: {
    height: "3.75rem",
  },
  padding: "0 0 0 0",
  borderBottom: "1px solid rgba(112, 112, 112, .28)",

  backgroundColor: "#EEE9E5",
  // flexDirection: 'row-reverse',
  alignItems: "flex-end",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
    // marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
  padding: "0",
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  backgroundColor: "#EEE9E5",
  [theme.breakpoints.down("md")]: {
    paddingTop: "1rem",
  },
  [theme.breakpoints.up("md")]: {
    paddingTop: "1.75rem",
  },
}));

const ChangeUser = () => {
  dayjs.locale(he);
  dayjs.extend(localizedFormat);
  const {auth, setAuth, logOut} = useAuth();
  const dispatch = useDispatch();
  // let {pathname} = useLocation();

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  let [stepNumber, setStepNumber] = useState(0);
  // let [isAuth, setIsAuth] = useState(true);
  let [isGoogle, setIsGoogle] = useState(false);
  let [contracts, setContracts] = useState([]);
  let [events, setEvents] = useState([]);
  const handleChange = (panel) => (event, newExpanded) => {
    setStepNumber(newExpanded ? panel : false);
  };
  let [isShowPass, setIsShowPass] = useState(true);
  const [sendInfo, setsendInfo] = useState(false);
  const [infoBuyMe, setinfoBuyMe] = useState(false);
  const [buyMe, setbuyMe] = useState();
  const [isDeleteUser, setIsDeleteUser] = useState(false)
  const [iddeleteId, setiddeleteId] = useState(0);
  const [isDisableButton, setIsDisableButton] = useState(false)
  const role = localStorage.getItem('roles');
  const clearPasswords = () => {
    resetField("password", "");
    resetField("oldPassword", "");
    resetField("passwordConfirm", "");
  };

  const schema = yup.object().shape({
    name: yup
      .string()
      .min(2, "לפחות 2 אותיות") // min char 2
      .required("הכנס שם משתמש"), //name required
    phoneNumber: yup
      .string()
      .matches(/^(?:\+[\d\s-]+|[\d\s-]+)$/, "רק ספרות") // ONLY_NUMBERS
      .min(9, "לפחות 9 ספרות") //
      // .max(10, "מקסימום 10 ספרות") // MIN_CHAR_10
      .required("שדה חובה") //  FIELD_REQUIRED
      .test(
        "leading-zero",
        "מספר טלפון צריך להתחיל מ 0 או 972+", // LEDAING_ZERO
        (value, context) => {
          return context.originalValue && (context.originalValue.startsWith(0) || context.originalValue.startsWith('+'));
        }
      ),
    // 'You must enter a email'
    email: isGoogle
      ? yup.string()
      : yup
          .string()
          .email("רשום מייל תקין") // 'Enter a valid email'
          .required("שדה חובה"),
    // 'Password is too short - should be 6 chars minimum.'
    oldPassword: isGoogle
      ? yup.string()
      : yup
          .string()

          // .required("נא לרשום סיסמא") // 'Please enter your password.'
          .min(6, "הסיסמא קצרה מדי, לפחות 6 תוים."),
    password: isGoogle
      ? yup.string()
      : yup
          .string()
          // .required("נא לרשום סיסמא") // 'Please enter your password.'
          .min(6, "הסיסמא קצרה מדי, לפחות 6 תוים."),
    passwordConfirm: isGoogle
      ? yup.string()
      : yup
          .string()
          /* .required("שדה חובה") */
          .oneOf([yup.ref("password")], "הסיסמאות אינן תואמות"),
  });

  const defaultValues = {
    name: "",
    phoneNumber: "",
    email: "",
    oldPassword: "",
    password: "",
    passwordConfirm: "",
    rememberMe: false,
  };

  const {
    register,
    // reset,
    // handleSubmit,
    formState: {errors},
    formState,
    getValues,
    setValue,
    resetField,
  } = useForm({
    mode: "all",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const saveUser1 = async (data1) => {
    let cleaned = (getValues().phoneNumber).replace(/[\s-]/g, '');
    if(cleaned.startsWith('+972')){
      cleaned = cleaned.replace(/^(\+972)/, '0')
    }
    if(cleaned.startsWith('+') && !cleaned.startsWith('+972')){
      dispatch(
        setErr({
          message: "מספר טלפון צריך להתחיל מ 0 או 972+",
          type: 1,
        })
      );
      return false;
    }
    if (cleaned.length >= 9 && cleaned.length <= 10) {
    } else {
      dispatch(
        setErr({
          message: "מקסימום 10 ספרות",
          type: 1,
        })
      );
      return false;
    }
    let isChangedPassword = false;
    let newData = {...data1};
    newData.phoneNumber = cleaned; 
    setValue("name", getValues().name, {shouldValidate: true});
    setValue("phoneNumber", cleaned, {shouldValidate: true});
    if (!isGoogle) {
      setValue("email", getValues().email, {shouldValidate: true});
      if (
        getValues("password") ||
        getValues("oldPassword") ||
        getValues("passwordConfirm")
      ) {
        isChangedPassword = true;
        setValue("password", getValues().password, {shouldValidate: true});
        setValue("oldPassword", getValues().oldPassword, {
          shouldValidate: true,
        });
        setValue("passwordConfirm", getValues().passwordConfirm, {
          shouldValidate: true,
        });
      }
    }
    // console.log(data1)

    if (Object.keys(formState.errors).length) return;

    dispatch(saveUser({axiosPrivate, data1: newData})).then((res) => {
      if (res?.error) {
        dispatch(
          setErr({
            message: res?.payload?.response?.data?.message,
            code: res?.payload?.response?.data?.code,
          })
        );
      } else {
        if(isChangedPassword) setIsOpenPass(true)
        dispatch(
          setErr({
            message: "השינויים נשמרו",
            type: 2,
          })
        );
        clearPasswords();
        let newAuth = {...auth};
        newAuth.user = JSON.stringify(res?.payload);
        setAuth(newAuth);
        //update info
      }
    });
  };

  const deleteUser = () => {
    logOut();
    // dispatch(removeUser({axiosPrivate})).then((res) => {
    //   if(res?.error){
    //     dispatch(setErr({
    //       message: res?.payload?.response?.data?.message,
    //       code: res?.payload?.response?.data?.code
    //     }))
    //   } else{
    //     logOut();
    //     //update info
    //   }
    // })
  };

  const getAllEvents = () => {
    dispatch(getEvents({axiosPrivate})).then((res) => {
      if (res?.error) {
        dispatch(
          setErr({
            message: res?.payload?.response?.data?.message,
            code: res?.payload?.response?.data?.code,
          })
        );
      } else {
        setEvents(res.payload);
      }
    });
  };

  // {
  //   "checkListId": 0,
  //   "geoLocationName": "string",
  //   "eventTypeName": "string",
  //   "eventDate": "2023-02-06T06:56:59.636Z",
  //   "signedContract": true
  // }

  const sendRequestForBuyMe = async() => {
    try{
      const result = await axiosPrivate.post(`/user/buyMePoints/request`);
      if(result){
        setinfoBuyMe(true);
      }
    } catch (error){
      dispatch(
        setErr({
          message: error,
          code: 400,
      }) )
    }
  };

  const deleteSelectedEvent = () => {
    setIsDisableButton(true)
    setsendInfo(!sendInfo);
    dispatch(removeEvent({contractId: iddeleteId, axiosPrivate})).then(
      (res) => {
        if (res?.error) {
          dispatch(
            setErr({
              message: res?.payload?.response?.data?.message,
              code: res?.payload?.response?.data?.code,
            })
          );
        } else {
          getAllEvents();
        }
        setIsDisableButton(false)
      }
    );
  };

  const deleteDialog = (id) => {
    setiddeleteId(id);
    setsendInfo(true);
  };

  const getBuyMeInfo = async() => {
    try{
      const result = await axiosPrivate.get(`/user/buyMePoints`);
      if(result){
        setbuyMe(result?.data);
      }
    } catch (error){
      dispatch(
        setErr({
          message: error,
          code: 400,
      }) )
    }
  };

  useEffect(() => {
    getAllEvents();
    if(role){
      getBuyMeInfo();
    }
    dispatch(getContracts({axiosPrivate})).then((res) => {
      if (res?.error) {
        dispatch(
          setErr({
            message: res?.payload?.response?.data?.message,
            code: res?.payload?.response?.data?.code,
          })
        );
      } else {
        setContracts(res.payload);
      }
    });
    dispatch(getUserData({axiosPrivate})).then((res) => {
      if (res?.error) {
        dispatch(
          setErr({
            message: res?.payload?.response?.data?.message,
            code: res?.payload?.response?.data?.code,
          })
        );
      } else {
        const {email, name, phoneNumber} = res?.payload;
        setValue("email", email);
        setValue("name", name);
        setValue("phoneNumber", phoneNumber);
      }
    });
    if (auth?.user) {
      let user = JSON.parse(auth?.user);
      if (user.registrationType === "GOOGLE") {
        setIsGoogle(true);
      } else setIsGoogle(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let currentRole = auth?.roles?.length ? auth?.roles[0] : "";
  let email = auth?.user?.length ? JSON.parse(auth?.user).email : "";
  const sendEmail = async (data1) => {
    try {
      await axios.get(
        `${process.env.REACT_APP_API_PATH}auth/reset/password?email=${email}&userType=${currentRole}`
      );
      dispatch(
        setErr({
          message: "סיסמה חדשה נשלחה למייל",
          type: 2,
        })
      );
    } catch (error) {
      if (error) {
        dispatch(
          setErr({
            message: error?.response?.data?.message,
            code: error?.response?.data?.code,
          })
        );
      }
    }
  };

  const [isOpenPass, setIsOpenPass] = useState(false);
 
  const deleteRequest = async () => {
    logOut();
    setIsDeleteUser(false)
    await axiosPrivate.post(`/user/remove/request`)
  }

  const getPDF = async(contractId) => {
    try {
      let secondResponse = null
      const response = await axiosPrivate.get(`${process.env.REACT_APP_API_PATH}contract/document/request?contractId=${contractId}`,{responseType: 'blob'});
      const data = await response.data;
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.pdf");
      document.body.appendChild(link);
      link.click();
      if (response.status!==200 || secondResponse.status!==200) {
        throw new Error('Server Error');
      }
    } catch (error) {
      console.log(error)
    }
}
  return (
    <>
      <PopUp
        isOpen={isOpenPass}
        setIsOpen={setIsOpenPass}
        message={"הסיסמא שונתה בהצלחה"}
      />

      <div className={styles.mainContainer}>
        <div className={styles.mobile}>
          <span className={styles.title}>{"איזור אישי / פרטים אישיים"}</span>
        </div>
        <div className={styles.accordion}>
          <Accordion expanded={stepNumber === 0} onChange={handleChange(0)}>
            <AccordionSummary aria-controls="0d-content" id="0d-header">
              <div className={styles.tabTitle}>{"עדכון פרופיל"}</div>
            </AccordionSummary>
            <AccordionDetails>
              {auth?.user ? (
                <div>
                  <form noValidate className={styles.form}>
                    <div className={styles.formNamePhone}>
                      <div className={styles.inputContainer}>
                        <input
                          type="text"
                          placeholder="שם מלא"
                          {...register("name")}
                          className={classNames({
                            [styles.invalidField]: errors.name,
                          })}
                        />
                        {errors.name && (
                          <p className={styles.errorMessage}>
                            {errors.name.message}
                          </p>
                        )}
                      </div>
                      <div className={styles.inputContainer}>
                        <input
                          type="text"
                          placeholder="מספר טלפון"
                          {...register("phoneNumber")}
                          className={classNames({
                            [styles.invalidField]: errors.phoneNumber,
                          }, styles.phoneInput)}
                        />
                        {errors.phoneNumber && (
                          <p className={styles.errorMessage}>
                            {errors.phoneNumber.message}
                          </p>
                        )}
                      </div>
                    </div>

                    {!isGoogle && (
                      <div
                        style={{marginBottom: "15px", width: "100%"}}
                        className={styles.inputContainer}
                      >
                        <input
                          type="email"
                          placeholder="כתובת מייל"
                          {...register("email")}
                          className={classNames({
                            [styles.invalidField]: errors.email,
                          })}
                        />
                        {errors.email && (
                          <p className={styles.errorMessage}>
                            {errors.email.message}
                          </p>
                        )}
                      </div>
                    )}

                    {!isGoogle && (
                      <div className={styles.forgot}>
                        <span
                          onClick={() => {
                            sendEmail();
                          }}
                        >
                          {"שכחתי סיסמא"}
                        </span>
                      </div>
                    )}
                    {!isGoogle && (
                      <span
                        className={styles.showPasswords}
                        onClick={() => {
                          clearPasswords();
                          setIsShowPass(!isShowPass);
                        }}
                      >
                        <span>{"שינוי סיסמא"}</span>
                        <img
                          style={{
                            transform: isShowPass ? "rotate(180deg)" : "",
                          }}
                          alt="arrow"
                          src={arrowTop}
                        />
                      </span>
                    )}

                    {isShowPass && !isGoogle && (
                      <div className={styles.passwords}>
                        <div className={styles.oldPassInput}>
                          <div className={styles.inputContainer}>
                            <input
                              type="password"
                              placeholder="סיסמא נוכחית"
                              autoComplete="new-password"
                              {...register("oldPassword")}
                              className={classNames({
                                [styles.invalidField]: errors.oldPassword,
                              })}
                            />
                            {errors.oldPassword && (
                              <p className={styles.errorMessage}>
                                {errors.oldPassword.message}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className={styles.confirmationFields}>
                          <div className={classNames(styles.inputContainer)}>
                            <input
                              type="password"
                              placeholder="סיסמא חדשה"
                              autoComplete="new-password"
                              {...register("password", {
                                deps: ["passwordConfirm"],
                              })}
                              className={classNames({
                                [styles.invalidField]: errors.password,
                              })}
                            />
                            {errors.password && (
                              <p className={styles.errorMessage}>
                                {errors.password.message}
                              </p>
                            )}
                          </div>
                          <div className={classNames(styles.inputContainer)}>
                            <input
                              type="password"
                              placeholder="אישור סיסמא חדשה"
                              autoComplete="new-password"
                              {...register("passwordConfirm", {
                                deps: ["password"],
                              })}
                              className={classNames({
                                [styles.invalidField]: errors.passwordConfirm,
                              })}
                            />
                            {errors.passwordConfirm && (
                              <p className={styles.errorMessage}>
                                {errors.passwordConfirm.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    <div className={styles.buttons}>
                      <div
                        className={styles.save}
                        onClick={() => saveUser1(getValues())}
                      >
                        {"שמירת שינויים"}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: "25px",
                        }}
                      >
                        <div
                          className={styles.remove}
                          onClick={() => deleteUser()}
                        >
                          {"אני רוצה להתנתק"}
                        </div>

                        <div
                          className={styles.remove}
                          onClick={() => {
                            setIsDeleteUser(true)
                          }}
                        >
                          {"מחקו את הפרופיל שלי"}
                        </div>
                    </div>
                    </div>
                  </form>
                </div>
              ) : (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <span
                    onClick={() => {
                      dispatch(setIsOpenUserAuth(true));
                      dispatch(savePath("/personal/change-user"));
                    }}
                    className={styles.regLink}
                  >
                    {"עדיין אין לי פרופיל איוונט-דו"}
                  </span>
                </div>
              )}
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={stepNumber === 1} onChange={handleChange(1)}>
            <AccordionSummary aria-controls="1d-content" id="1d-header">
              <div className={styles.tabTitle}>{"רשימת האירועים שלי"}</div>
            </AccordionSummary>
            <AccordionDetails>
              {events?.length ? (
                <div className={styles.eventsWrapper}>
                  <table>
                    {/* <thead>
                    <tr>
                      <th className={styles.dateHead}>1</th>
                      <th className={styles.eventCell}>2</th>
                      <th className={styles.goCell}>3</th>
                      <th className={styles.goCell}>4</th>
                    </tr>
                  </thead> */}
                    {events?.map((event, index) => {
                      return (
                        <React.Fragment key={event.checkListId}>
                          <tr>
                            <td
                              style={{paddingTop: index === 0 ? 0 : ""}}
                              className={styles.dateHead}
                            >
                              {event?.eventDate
                                ? dayjs(event?.eventDate).format("DD/MM/YYYY")
                                : null}
                            </td>
                            <td
                              style={{paddingTop: index === 0 ? 0 : ""}}
                              className={styles.eventCell}
                            >
                              {event.eventTypeName}
                            </td>
                            <td
                              style={{paddingTop: index === 0 ? 0 : ""}}
                              className={styles.eventCell}
                            >
                              {event.geoLocationName}
                            </td>
                            <td
                              style={{paddingTop: index === 0 ? 0 : ""}}
                              className={styles.goCell}
                            >
                              {!event?.signedContract && (
                                <div className={styles.img}>
                                  <img
                                    src={trash}
                                    alt="delete"
                                    onClick={() =>
                                      deleteDialog(event.checkListId)
                                    }
                                  />
                                </div>
                              )}
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </table>
                </div>
              ) : (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: ".5rem",
                  }}
                >
                  <div className={styles.addEventTitle}>
                    עדיין לא הוספת אירועים לרשימה שלך
                  </div>
                  <div
                    className={styles.addEventLink}
                    onClick={() => {
                      if (auth?.user) {
                        navigate("/personal/add-event");
                      } else {
                        navigate("/personal-guest/add-event");
                      }
                    }}
                  >
                    הוספת אירוע
                  </div>
                </div>
              )}
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={stepNumber === 2}
            onChange={auth?.user && handleChange(2)}
          >
            <AccordionSummary aria-controls="2d-content" id="2d-header">
              <div className={styles.tabTitle}>{"חוזים מול ספקים"}</div>
            </AccordionSummary>
            <AccordionDetails>
              {contracts?.length ? (
                <div className={styles.contracts}>
                  <span className={styles.contractsTitle}>
                    {"בחר אירוע מתוך הרשימה"}
                  </span>
                  <div className={styles.underline}></div>
                  <div className={styles.contractsWrapper}>
                    <table>
                      {/* <thead>
                      <tr>
                        <th className={styles.dateHead}>1</th>
                        <th className={styles.eventCell}>2</th>
                        <th className={styles.goCell}>3</th>
                      </tr>
                    </thead> */}
                      {contracts?.map((contract, index) => {
                        return (
                          <React.Fragment key={contract.contractId}>
                            <tr>
                              <td className={styles.contractDate}>
                                {contract?.date
                                  ? dayjs(contract?.date).format("DD/MM/YYYY")
                                  : null}
                              </td>
                              <td className={styles.contractName}>
                                {contract.eventTypeName}
                              </td>

                              <td onClick={(e)=>{getPDF(contract.contractId);}} style={{cursor:'pointer'}} className={styles.goCell}>{"בחר"}</td>
                            </tr>
                          </React.Fragment>
                        );
                      })}
                    </table>
                  </div>

                  <div></div>
                </div>
              ) : (
                <span className={styles.noContractTitle}>
                  {"עדיין לא סגרת חוזים עם הספקים שלנו"}
                </span>
              )}
            </AccordionDetails>
          </Accordion>
          {role && <Accordion expanded={stepNumber === 3} onChange={handleChange(3)}>
          <AccordionSummary aria-controls="3d-content" id="3d-header">
            <div className={styles.tabTitle}>
             הטבות BUYME <img  className={styles.buyme_image} alt="buyme" src={buyme}  />
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={styles.buyme_block}>
              <div>
                <div className={styles.userPoints}> - סך נקודות שצברתי <span className={styles.userNumbers}> {buyMe?.points || buyMe?.points === 0 ? buyMe?.points : 0} נק׳ </span> </div>
                <div className={styles.pointsInfo}>* ניתן לממש נקודות בצבירה של מעל <span>{50}</span> </div>
              </div>
              <div>
                <div>
                  <p className={buyMe?.sendRequest ? styles.buyme_activeButton : styles.buyme_inActiveButton} onClick={()=> buyMe?.sendRequest ? sendRequestForBuyMe() : '' }>אני רוצה לממש</p> {/* buyme_activeButton */}
                </div>
                {/* <div className={styles.redTitle}>תישלח בקשת מימוש למערכת איוונט-דו</div> */}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>}
        </div>

        <Dialog
        open={isDeleteUser}
        onClose={setIsDeleteUser}
        >
          <DialogContent>
            <DialogContentText>{"קיבלנו את פנייתך למחיקת הפרופיל."}</DialogContentText>
            <DialogContentText>{"הפרופיל יימחק לאלתר תוך 48 שעות 😊"}</DialogContentText>
          </DialogContent>
            <DialogActions sx={{
                display: 'flex',
                justifyContent: 'center',
              }}>
            <Button onClick={()=>deleteRequest()}>
              כן  
            </Button>
            <Button onClick={()=>setIsDeleteUser(false)}>
              לא
            </Button>
          </DialogActions>
        </Dialog>
      
        <PopUp
          open={isDeleteUser}
          setIsOpen={setIsDeleteUser}
          content={ 
            <div style={{widows: "100%", height: "50vh", overflow: "hidden"}}>
              <div className={styles.dialogButtons}>
                    <button
                      onClick={() => {}}
                      className={styles.buttonVideo}
                    >
                      כן
                    </button>
                    <button
                      onClick={() => {}}
                      className={styles.buttonVideo}
                    >
                      לא
                    </button>
                </div>   
              </div>   
          }
        />

        <Dialog
          hideBackdrop={true}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "400px",
                boxShadow: "none",
                overflow: "hidden",
                borderRadius: "0",
              },
            },
          }}
          open={sendInfo}
          fullWidth={true}
          className={styles.infoDialog}
        >
          <div className={styles.mainPopup}>
            <div className={styles.blockCalendar}>
              <div className={styles.infoDialogClose}>
                <div className={styles.infoDialogtitle}>
                  האם ברצונך למחוק ארוע זה?
                  <div className={styles.dialogButtons}>
                    <button
                      disabled={isDisableButton}
                      onClick={() => deleteSelectedEvent()}
                      className={styles.buttonVideo}
                    >
                      כן
                    </button>
                    <button
                      onClick={() => setsendInfo(!sendInfo)}
                      className={styles.buttonVideo}
                    >
                      לא
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog>

        <Dialog
          hideBackdrop={true}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "400px",
                boxShadow: "none",
                overflow: "hidden",
                borderRadius: "0",
              },
            },
          }}
          open={infoBuyMe}
          fullWidth={true}
          className={styles.infoDialog}
        >
          <div className={styles.mainPopup}>
            <div className={styles.close}>
              <svg style={{cursor:'pointer'}}
              xmlns="http://www.w3.org/2000/svg"
              width="25.477"
              height="25.47"
              viewBox="0 0 25.477 25.47"
              onClick={() => setinfoBuyMe(!infoBuyMe)}
              >
              <path
                id="Icon_ionic-ios-close"
                data-name="Icon ionic-ios-close"
                d="M27.042,24.024l9.1-9.1a2.132,2.132,0,1,0-3.015-3.015l-9.1,9.1-9.1-9.1a2.132,2.132,0,1,0-3.015,3.015l9.1,9.1-9.1,9.1a2.132,2.132,0,0,0,3.015,3.015l9.1-9.1,9.1,9.1a2.132,2.132,0,0,0,3.015-3.015Z"
                transform="translate(-11.285 -11.289)"
                fill="#242323"
              />
              </svg>
            </div>
            <div className={styles.blockCalendar}>
              <div className={styles.infoDialogClose}>
                <div className={styles.infoDialogtitle}>
                בקשתך נקלטה , שובר הביימי שלך ישלח תוך 7 ימי עסקים, תהנו.
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default ChangeUser;
