import { axiosPrivate } from "../../api/axios";
//import { axiosPrivate } from "../api/axios";
import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";

const useAxiosPrivate = () => {
    const refresh = useRefreshToken();
    const { auth, logOut } = useAuth();

    useEffect(() => {

        const requestIntercept = axiosPrivate.interceptors.request.use(
            config => {
                const controller = new AbortController();
                if (!auth.user) {
                    controller.abort();
                    return {
                        ...config,
                        signal: controller.signal
                      };
                }else{
                    if (!config.headers['Authorization']) {
                        config.headers['Authorization'] = `Bearer ${auth?.accessToken}`;
                    }
                    return config;
                }
 
            }, (error) => Promise.reject(error)
        );

        const responseIntercept = axiosPrivate.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config;
                if ((error?.response?.status === 401 
                    || error?.response?.status === 403
                    ) 
                    && (!prevRequest?.sent)) {
                    prevRequest.sent = true;
                    const newAccessToken = await refresh();
                    if(newAccessToken === "error"){
                        logOut() 
                        return
                    }else{
                        prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                        return axiosPrivate(prevRequest);
                    }
                    
                }else{
                    if((error?.response?.status === 401 || error?.response?.status === 403)
                    //  && (prevRequest?.sent)
                     ){

                       logOut() 
                    }
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.response.eject(responseIntercept);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth, refresh])

    return axiosPrivate;
}

export default useAxiosPrivate;