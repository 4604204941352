import React, { useState } from "react";
import classNames from "classnames";
import styles from "./SupplierDescription.module.css";
import useAuth from "../../hooks/useAuth";
import ShowMoreText from "../common/ShowMoreText";
import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { savePath } from "../../store/returnToUrlSlice";
import { setIsOpenUserAuth } from "../../store/mainSlice";
import { useAnalyticsEvents } from "../../hooks/useAnalyticsEvents";

function SupplierDescription(props) {
  const dispatch = useDispatch();
  let { pathname } = useLocation();
  const { businessDescription, businessAddr, businessName, regions, phoneNumber } = props;
  const [showPhone, setShowPhone] = useState(false);
  const { auth } = useAuth();
  let currentRole = auth?.roles ? auth.roles[0] : '';
  const {ShowPhone} = useAnalyticsEvents();
  // const navigate = useNavigate();

  function replaceWithBr(text) {
    if (!text) {
      return "";
    }
    return text.replace(/\n/g, "<br />");
  }

  function goToLogin() {
    dispatch(savePath(pathname));
    dispatch(setIsOpenUserAuth(true))
  }

  return (
    <>
      <h2 className={styles.supplierTitle}>{businessName}</h2>
      <ShowMoreText text={replaceWithBr(businessDescription)}></ShowMoreText>

      { (businessAddr || regions?.length > 0) && (
        <div className={styles.supplierLocations}>
          <span className={styles.supplierLocationsTitle}>מיקום</span>&nbsp;
          &nbsp;
          {!!businessAddr ? 
          <>
            <span className={styles.supplierLocationsContent}>
              {businessAddr}
            </span>
          </>
          :
          <>
            {regions.map((region, index) => {
              return (
                <span key={region.id} className={styles.supplierLocationsContent}>
                  {region.name}
                  {index !== regions.length - 1 && " ,"}
                </span>
              );
            })}
          </>
          
          }
          
        </div>
      )}
      {/* {phoneNumber && (
        <div className={styles.supplierContacts}>
          <span className={styles.supplierContactsTitle}>טלפון</span>&nbsp;
          &nbsp;
          <span
            className={classNames(styles.supplierContactsLink, {
              [styles.noDispaly]: showPhone,
            })}
            onClick={() => {
              currentRole ? setShowPhone(true) : goToLogin();
              
              const obj = {
                userName: (auth?.user ? JSON.parse(auth?.user)?.name : ""),
                supplierName: businessName,
                userPhone: (auth?.user ? JSON.parse(auth?.user)?.phoneNumber : ''),
                // url: pathname,
              }
              ShowPhone(obj)
            } }
          >
            הצג מספר טלפון
          </span>
          <span
            className={classNames(styles.supplierContactsNumber, {
              [styles.noDispaly]: !showPhone,
            })}
          >
            {phoneNumber}
          </span>
        </div>
      )} */}
    </>
  );
}

export default SupplierDescription;
