import { Divider, Modal } from '@mui/material';
import styles from './styleLoginWithPhone.module.scss';
import LoginWithPhoneContent from './LoginWithPhoneContent';
const LoginWithPhoneModal = (props) => {
  const {
    open = false,
    handleClose,
    onlyCode = false,
    overridedOnConfirm,
  } = props;
  return (
    <Modal
      sx={{
        '.MuiBackdrop-root': {
          backgroundColor: '#405F6A',
          opacity: '0.8',
        },
      }}
      onClose={handleClose}
      open={open}
    >
      <div className={styles.modal_container}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25.477"
          height="25.47"
          viewBox="0 0 25.477 25.47"
          onClick={handleClose}
          className={styles.closeIcon}
        >
          <path
            id="Icon_ionic-ios-close"
            data-name="Icon ionic-ios-close"
            d="M27.042,24.024l9.1-9.1a2.132,2.132,0,1,0-3.015-3.015l-9.1,9.1-9.1-9.1a2.132,2.132,0,1,0-3.015,3.015l9.1,9.1-9.1,9.1a2.132,2.132,0,0,0,3.015,3.015l9.1-9.1,9.1,9.1a2.132,2.132,0,0,0,3.015-3.015Z"
            transform="translate(-11.285 -11.289)"
            fill="white"
          />
        </svg>
        <div className={styles.boxImg} />
        <LoginWithPhoneContent
          onlyCode={onlyCode}
          overridedOnConfirm={overridedOnConfirm}
        />
      </div>
    </Modal>
  );
};
export default LoginWithPhoneModal;
