import React from 'react';
import styles from './styleForSuppliers.module.scss';

const advantages = [
  {
    id: 0,
    name: 'שקיפות',
    text: 'כולם כאן ללא יוצא מן הכלל - במקום לחשוף רק את הספקים שמשלמים על פרסום, איוונטדו חושפת את<br> כל הספקים באופן שווה ומאפשרת ללקוחות לבחור עבור עצמם.',
  },
  {
    id: 1,
    name: 'חוסכים לכם כסף',
    text: 'ללא עלויות פרסום - באיוונטדו משלמים רק דמי הצלחה עבור עסקה שנסגרה באתר.',
  },
  {
    id: 2,
    name: 'חוסכים לכם זמן יקר',
    text: 'באיוונטדו לקוח פונה אליך לאחר שבדק ומצא אותך מתאים עבורו יותר מספקים אחרים.<br>הזמן שלך יושקע בעבודה אמיתית, ולא במשא ומתן עם לקוחות לא רלוונטיים.',
  },
  {
    id: 3,
    name: 'שירותים בתוך הפלטפורמה',
    text: 'שליחת הצעת מחיר, ניהול משא ומתן עם לקוחות, עדכון תיק עבודות ויומן<br>זמינות - בלי להרים טלפון.',
  },
  {
    id: 4,
    name: 'כל האירועים, כל הספקים',
    text: 'איוונטדו היא הפלטפורמה היחידה בישראל שמאגדת במקום אחד את כל הספקים<br>וכל אנשי המקצוע עבור כל סוגי האירועים.',
  },
];

const RowAdvantages = () => {
  return (
    <div className={styles.row_items}>
      {advantages.map((oneAd, i) => (
        <div
          key={`info-suppliers-one-advantage-${oneAd.id}`}
          className={styles.one_item}
          dangerouslySetInnerHTML={{
            __html: `<span style="font-family:'Heebo-Bold'">${oneAd.name}</span> ${oneAd.text}`,
          }}
        />
      ))}
    </div>
  );
};

export default RowAdvantages;
