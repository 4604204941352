import styles from "./CheckList.module.scss";
import * as React from "react";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import shekelSymbol from "../../../assets/images/checklist_shekel.svg";
import shekel from "./checklist_shekel_black.svg";
import remove from "./remove.svg";
import contract from "./contract.svg";
import sum_shekel from "./sum_shekel.svg";
import plus from "./plus.svg";
import eye from "./eye.svg";
import close from "./close.svg";
import details from "./details.svg";
import close_icon from "./close_icon.svg";
import mobile_plus from "./mobile_plus.svg";
import remove_supplier from "./remove_supplier.svg";
import contract_pink from "./contract_pink.svg";
import shekel_calc from "./calc_shekel.svg";
import Select from "./selectComponent/Select";
import SelectMobile from "./selectComponentMobile/Select";
import {
  setCurrentEventChecklist,
  setSuppliers,
} from "../../store/checkListSlice";
import {
  setCurrentEvent,
  setCurrentEventForChecklist,
  setCurrentSupplierEvent,
} from "../../store/mainSlice";
import {setDateFrom, setDateTo, setSelectedEventTypeId} from "../../store/suppliersSlice";
import Dialog from "@mui/material/Dialog";
import classNames from "classnames";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {useLocation, useNavigate} from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import arrow from "./arrowDown.svg";
import arrowUp from "./arrowUp.svg";
import {setSelectedCategoryId} from "../../store/suppliersSlice";
import axios from "../../../api/axios";
import {TEMP_USER_KEY} from "../../consts/local_keys";
import {setCurrentEventChat} from "../../store/chatSlice";
import MetaFavicon from "../../components/metaComponents/metaFavicon";
import {savePath} from "../../store/returnToUrlSlice";
import {setIsOpenUserAuth} from "../../store/mainSlice";
import {setErr} from "../../store/forAll/errSlice";
import dayjs from 'dayjs';

function CheckList() {
  let {pathname} = useLocation();
  const dispatch = useDispatch();
  //const [allEvents] = useState({data:[{id:1,name:'first'},{id:2,name:'second'},{id:3,name:'third'},], loading: true});
  const [categories, setcategories] = useState({data: [], loading: true});
  const [findChecklist, setfindChecklist] = useState({
    generalBudget: "",
    countOfGuests: "",
  });
  const [dialogServices, setdialogServices] = useState([]);
  const {currentEvent, suppliersList} = useSelector((state) => state.checklist);
  const [openPopup, setOpenPopup] = useState(false);
  const [, setcurrentEventName] = useState(currentEvent);
  const [openChecklist, setopenChecklist] = useState(false);
  const [openMobileChecklist, setopenMobileChecklist] = useState(false);
  const [openMobileAddSupplier, setopenMobileAddSupplier] = useState(false);
  const [calculation, setCalculation] = useState();
  const [openMobileShowServices, setMobileShowServices] = useState(false);
  const [showDelete, setshowDelete] = useState(false);
  const [deletedCategoryId, setdeletedCategoryId] = useState(0);
  const [showMobileDetails, setshowMobileDetails] = useState(false);
  //const [loader, setloader] = useState(false);
  const [currentEventType, setcurrentEventType] = useState(0);
  const [scrollToSupplier, setscrollToSupplier] = useState(true);
  const [userCalculation, setuserCalculation] = useState({
    budget: 0,
    services: 0,
  });
  const [currentEventChecklist, setcurrentEventChecklist] = useState();
  const [servicesMobile, setservicesMobile] = useState();
  const [budget, setbudget] = useState(0);
  let defaultEventType = {checkListId: 0, eventTypeName: "", eventTypeId: 0};
  const axiosPrivate = useAxiosPrivate();
  const [checklists, setchecklists] = useState({data: null, loading: true});
  const {auth} = useAuth();
  const navigate = useNavigate();

  const myRef = React.useRef(null);
  const myRef2 = React.useRef(null);
  let currentRole = auth?.roles?.length ? auth?.roles[0] : "";

  useEffect(() => {
    let div = document.getElementsByClassName("samyBody");
    div[0].scrollTop = 0;
    //  dispatch(setCurrentEventChecklist({currentEvent: defaultEventType}));
    setopenChecklist(false);
    const fetchData = async () => {
      const result = currentRole
        ? await axiosPrivate.get(`${process.env.REACT_APP_API_PATH}checklist`)
        : await axios.get(
            `${process.env.REACT_APP_API_PATH}checklist/anonymous?userToken=${
              localStorage.getItem(TEMP_USER_KEY) || ""
            }`
          );
      setchecklists({data: result.data, loading: false});
      if (result) {
        let lastChecklist;
        if(result.data.length >= 0){
          const findItem = result.data.find(item => item.checkListId === currentEvent.checkListId)
          onChangeOption(findItem || result.data[0])
        } 
        // result.data.map((item, index) => {
        //   if (index !== 0) {
        //     if (item.lastUpdate) {
        //       if (
        //         new Date(lastChecklist.lastUpdate) < new Date(item.lastUpdate)
        //       ) {
        //         lastChecklist = item;
        //       }
        //     }
        //   } else {
        //     lastChecklist = item;
        //   }
        //   // if(item.checkListId === lastChecklist?.checkListId){
        //   //     onChangeOption(item);
        //   // }
        //   return item;
        // });
        // console.log('lastChecklist = ',lastChecklist)
        // result.data.map((item, index) => {
        //   if (item.checkListId === lastChecklist?.checkListId) {
        //     onChangeOption(item);
        //   }
        //   return item;
        // });
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useLayoutEffect(() => {
    function fetchBusinesses() {
      if (scrollToSupplier) {
        setTimeout(() => {
          myRef?.current?.scrollIntoView();
        }, 350);
        setscrollToSupplier(false);
      }
    }
    fetchBusinesses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  const getCheckList = async (id) => {
    let data = {
      generalBudget:
        findChecklist.generalBudget !== ""
          ? Number(findChecklist.generalBudget)
          : "",
      countOfGuests:
        findChecklist.countOfGuests !== ""
          ? Number(findChecklist.countOfGuests)
          : "",
    };
    let anonymusData = {
      generalBudget:
        findChecklist.generalBudget !== ""
          ? Number(findChecklist.generalBudget)
          : 0,
      countOfGuests:
        findChecklist.countOfGuests !== ""
          ? Number(findChecklist.countOfGuests)
          : 0,
    };
    let payload = {};
    if (data.generalBudget !== "" && data.countOfGuests !== "") {
      payload.generalBudget = data.generalBudget;
      payload.countOfGuests = data.countOfGuests;
    }
    const result = currentRole
      ? await axiosPrivate.post(
          `${process.env.REACT_APP_API_PATH}checklist/${id}`,
          payload
        )
      : await axios.post(
          `${
            process.env.REACT_APP_API_PATH
          }checklist/${id}/anonymous?userToken=${
            localStorage.getItem(TEMP_USER_KEY) || ""
          }`,
          anonymusData
        );
    setDateForSuppliersSearch(result)
    setbudget(result.data.remainingBudget);
    setuserCalculation({
      budget: findChecklist.generalBudget - result.data.generalBudget,
      services: result.data.generalBudget,
    });
    setCalculation(result.data);
    //if(currentRole){
    setfindChecklist({
      generalBudget:
        result.data.overallBudget !== 0 ? result.data.overallBudget : "",
      countOfGuests:
        result.data.amountOfGuests !== 0 ? result.data.amountOfGuests : "",
    });
    // }
    if (result.data?.categories?.length > 0) {
      let newlist = result.data.categories.map((val) => {
        // val.open = false;
        return val;
      });
      setcategories({data: newlist, loading: false});

      setuserCalculation({services: 0, budget: 0});
      let newServices = result.data.generalBudget;
      let newBudget = findChecklist.generalBudget - result.data.generalBudget;
      result.data?.categories.map((val) => {
        val?.suppliers.map((value) => {
          if (value.selected && value.supplierId !== 0) {
            if(value.totalCost != null && value.totalMinCost != null){
              if(findChecklist.countOfGuests){
                 newServices = newServices + (((value.totalMinCost + value.totalCost) / 2) * findChecklist.countOfGuests);
              } 
            } else{
              newServices = newServices + value.totalCost;
            }
            newBudget = newBudget - value.totalCost;
          }
          return value;
        });
        return val;
      });
      setuserCalculation({services: newServices, budget: newBudget});
    } else {
      setcategories({data: [], loading: false});
    }
    //   if(categories.data.length > 0){
    //     addOpenField();
    //   }
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    //setEvents({data:result.data.categories,loading:false});
    // if(result.data !== null){
    //   result.data.map((item)=> {
    //     if(item.id === Number(eventTypeId)){
    //       dispatch(setCurrentEventChecklist({ currentEvent: item }));
    //     }
    //     return item
    //   })
    // }
    setopenChecklist(true);
  };

  const goToDocuments = () => {
    if (currentRole === "USER") {
      navigate("/personal/documents");
    } else {
      dispatch(savePath(pathname));
      dispatch(setIsOpenUserAuth(true));
    }
  };

  const onChangeForm = (event, fieldName) => {
    let actualState = {...findChecklist};
    if (!isNaN(event.target.value.split(",").join(""))) {
      if (fieldName === "generalBudget") {
        actualState.generalBudget = event.target.value.split(",").join("");
      } else if (fieldName === "countOfGuests") {
        actualState.countOfGuests = event.target.value.split(",").join("");
      }
    }
    setfindChecklist(actualState);
  };

  const getChecklist = () => {
    // let actualState = {...findChecklist};
    // console.log(actualState, currentEvent);
    setcurrentEventName(currentEvent);
    if (currentEvent.checkListId !== 0) {
      getCheckList(currentEvent.checkListId);
    }
  };

  const handleClickOpen = (
    services,
    action,
    categoryIndex,
    supplierIndex,
    categoryId,
    supId,
    name
  ) => {
    if (categoryId !== 0) {
      setshowDelete(action);
      setdialogServices([]);
      setdialogServices({
        services: services,
        categoryIndex: categoryIndex,
        supplierIndex: supplierIndex,
        categoryId: categoryId,
        supplierId: supId,
        supplierName: name,
      });
      setOpenPopup(true);
    }
  };

  const handleClickClose = () => {
    setOpenPopup(false);
  };

  const openMobileFindChecklist = () => {
    setopenMobileChecklist(true);
  };

  const closeMobileFindChecklist = (action) => {
    if (action) {
      setcurrentEventName(currentEvent);
    }
    if (currentEvent.checkListId !== 0 && action) {
      getCheckList(currentEvent.checkListId);
    }
    setopenMobileChecklist(false);
  };

  const openMobileAddNewSupplier = () => {
    dispatch(
      setSuppliers([
        {
          id: Math.random(),
          categoryName: "",
          supplierName: "",
          price: "",
          error: false,
        },
      ])
    );
    setopenMobileAddSupplier(true);
  };

  const closeMobileAddNewSupplier = (action) => {
    if (action) {
      let actualState = [...suppliersList];
      let newList = actualState.map((val) => {
        if (
          actualState.categoryName === "" ||
          val.supplierName === "" ||
          val.price < 1
        ) {
          return {
            id: val.id,
            categoryName: val.categoryName,
            supplierName: val.supplierName,
            price: val.price,
            error: true,
          };
        }
        return val;
      });
      if (newList[0].error) {
        dispatch(setSuppliers(newList));
      } else {
        addSuppliers();
        setopenMobileAddSupplier(false);
      }
    } else {
      setopenMobileAddSupplier(false);
    }
  };

  const openMobileservices = (
    services,
    categoryId,
    categoryIndex,
    supplierIndex,
    action
  ) => {
    let supplierServices = services;
    supplierServices.categoryId = categoryId;
    //setservicesMobile(supplierServices);
    setservicesMobile({
      services: services,
      categoryIndex: categoryIndex,
      supplierIndex: supplierIndex,
    });
    setshowDelete(action);
    setMobileShowServices(true);
  };

  const closeMobileservices = (action) => {
    if (currentEvent.id !== 0 && action) {
      setMobileShowServices(true);
    }
    setshowDelete(false);
    setMobileShowServices(false);
  };

  function onChangeOption(option) {
    setfindChecklist({generalBudget: "", countOfGuests: ""});
    setcurrentEventType(option?.eventTypeId);
    setcurrentEventChecklist(option);
    dispatch(
      setCurrentEventForChecklist({
        currentEventForChecklist: {
          id: option?.checkListId,
          eventTypeId: option?.eventTypeId,
          eventTypeName: option?.eventTypeName,
        },
      })
    );
    dispatch(
      setSuppliers([
        {id: 0, categoryName: "", supplierName: "", price: "", error: false},
      ])
    );
    dispatch(setCurrentEventChecklist({currentEvent: option}));
    applyCheckList(option);
    setTimeout(() => {
      setscrollToSupplier(true);
    }, 100);
    // getCheckList(option.checkListId);
  }

  function applyCheckList(option) {
    setcurrentEventName(currentEvent);
    if (option?.checkListId && option?.checkListId !== 0) {
      getCheckListWithoutFields(option.checkListId);
    }
  }

  const getCheckListWithoutFields = async (id) => {
    let anonymusData = {};
    let payload = {};
    const result = currentRole
      ? await axiosPrivate.post(
          `${process.env.REACT_APP_API_PATH}checklist/${id}`,
          payload
        )
      : await axios.post(
          `${
            process.env.REACT_APP_API_PATH
          }checklist/${id}/anonymous?userToken=${
            localStorage.getItem(TEMP_USER_KEY) || ""
          }`,
          anonymusData
        );
    setDateForSuppliersSearch(result)
    setbudget(result.data.remainingBudget);
    setuserCalculation({
      budget: findChecklist.generalBudget - result.data.generalBudget,
      services: result.data.generalBudget,
    });
    setCalculation(result.data);
    // if(currentRole){
    setfindChecklist({
      generalBudget:
        result.data.overallBudget !== 0 ? result.data.overallBudget : "",
      countOfGuests:
        result.data.amountOfGuests !== 0 ? result.data.amountOfGuests : "",
    });
    // }
    if (result.data?.categories?.length > 0) {
      let newlist = result.data.categories.map((val) => {
        //   val.open = false;
        return val;
      });

      setuserCalculation({budget: 0, services: 0});
      let newServices = result.data.generalBudget;
      let newBudget = findChecklist.generalBudget - result.data.generalBudget;
      result.data?.categories.map((val) => {
        val?.suppliers.map((value) => {
          if (value.selected && value.supplierId !== 0) {
            if(value.totalCost != null && value.totalMinCost != null){
              if(result.data.amountOfGuests){
                 newServices = newServices + (((value.totalMinCost + value.totalCost) / 2) * result.data.amountOfGuests);
              } 
            } else{
              newServices = newServices + value.totalCost;
            }
            newBudget = newBudget - value.totalCost;
          }
          return value;
        });
        return val;
      });
      setuserCalculation({services: newServices, budget: newBudget});
      setcategories({data: newlist, loading: false});
    } else {
      setcategories({data: [], loading: false});
    }
    setopenChecklist(true);
  };

  const getFormattedDate = (spesificDatePickerDate) => {
    return spesificDatePickerDate
      ? new Date(spesificDatePickerDate).toISOString()
      : new Date().toISOString();
  };

  function setDateForSuppliersSearch(result){
// dateFrom
// dateTo
// eventDate
// "2024-11-28T00:00:00.000+00:00"

    if(result?.data){
      dispatch(setDateFrom(result?.data?.eventDate || result?.data?.dateFrom ? dayjs(result?.data?.eventDate || result?.data?.dateFrom).format('YYYY-MM-DD') : result?.data?.eventDate || result?.data?.dateFrom))
      dispatch(setDateTo( result?.data?.dateTo ? dayjs(result?.data?.dateTo).format('YYYY-MM-DD') : result?.data?.dateTo ))
    }
  }

  const deleteCategory = async (index, categoryId, additionalId) => {
    if (additionalId === null) {
      if (deletedCategoryId !== categoryId) {
        setdeletedCategoryId(categoryId);
        const result = currentRole
          ? await axiosPrivate.delete(
              `${process.env.REACT_APP_API_PATH}checklist/${currentEvent.checkListId}/category?categoryId=${categoryId}&eventTypeId=${currentEvent.eventTypeId}`
            )
          : await axios.delete(
              `${process.env.REACT_APP_API_PATH}checklist/${
                currentEvent.checkListId
              }/category/anonymous?categoryId=${categoryId}&eventTypeId=${
                currentEvent.eventTypeId
              }&userToken=${localStorage.getItem(TEMP_USER_KEY) || ""}`
            );
        if (result) {
          let actualState = [...categories.data];
          actualState.splice(index, 1);
          let newLine = actualState;
          //setcategories({data:[],loading:false});
          setcategories({data: newLine, loading: false});
          getChecklist();
          onChangeCalculation(newLine);
        }
      }
    } else {
      setdeletedCategoryId(categoryId);
      const result = currentRole
        ? await axiosPrivate.delete(
            `${process.env.REACT_APP_API_PATH}checklist/${currentEvent.checkListId}/custom/sup?additionalId=${additionalId}`
          )
        : await axios.delete(
            `${process.env.REACT_APP_API_PATH}checklist/${
              currentEvent.checkListId
            }/custom/sup/anonymous?additionalId=${additionalId}&eventTypeId=${
              currentEvent.eventTypeId
            }&userToken=${localStorage.getItem(TEMP_USER_KEY) || ""}`
          );
      if (result) {
        let actualState = [...categories.data];
        actualState.splice(index, 1);
        let newLine = actualState;
        //setcategories({data:[],loading:false});
        setcategories({data: newLine, loading: false});
        getChecklist();
        onChangeCalculation(newLine);
      }
    }
  };

  const deleteCategorySupplier = async (
    index2,
    index,
    categoryId,
    supplierId
  ) => {
    let actualState = [...categories.data];
    let newLine = actualState[index].suppliers;
    const result = currentRole
      ? await axiosPrivate.delete(
          `${process.env.REACT_APP_API_PATH}checklist/${currentEvent.checkListId}/supplier?categoryId=${categoryId}&eventTypeId=${currentEvent.eventTypeId}&supId=${supplierId}`
        )
      : await axios.delete(
          `${process.env.REACT_APP_API_PATH}checklist/${
            currentEvent.checkListId
          }/supplier/anonymous?categoryId=${categoryId}&eventTypeId=${
            currentEvent.eventTypeId
          }&supId=${supplierId}&userToken=${
            localStorage.getItem(TEMP_USER_KEY) || ""
          }`
        );
    if (result) {
      newLine.splice(index2, 1);
      actualState[index].suppliers = newLine;
      let newList = actualState;
      setcategories({data: newList, loading: false});
      getChecklist();
      onChangeCalculation();
    }
  };

  const onChangeCalculation = async (state) => {
    let actualState = state ? state : [...categories.data];
    let newServices = budget;
    let newBudget = budget;
    // let flag = false;
    if (actualState?.length > 0) {
      actualState.map((item) => {
        if (item.suppliers?.length > 0) {
          item.suppliers.map((val) => {
            if (val.services?.length > 0) {
              val.services.map((value) => {
                if (val.selected) {
                  newServices = newServices - value.price;
                  newBudget = newBudget + value.price;
                  //   flag = true;
                }
                return value;
              });
            }
            return val;
          });
        }
        return item;
      });
      // if(flag){
      //   console.log(newServices,newBudget);
      //   setuserCalculation({services: newServices, budget:newBudget})
      // }
      // console.log(newServices,newBudget);
      setuserCalculation({services: newServices, budget: newBudget});
    }
  };

  const checkOpenFields = async (indexNumber) => {
    //   dispatch(setLoader(true));
    // setloader(true);
    let newList = categories.data.map((val, index) => {
      if (indexNumber === index && val.open) {
        try {
          const result = currentRole
            ? axiosPrivate.put(
                `${process.env.REACT_APP_API_PATH}checklist/${
                  currentEvent.checkListId
                }/category/open?categoryId=${
                  categories.data[indexNumber].categoryId
                }&open=${false}`,
                {}
              )
            : axios.put(
                `${process.env.REACT_APP_API_PATH}checklist/${
                  localStorage.getItem(TEMP_USER_KEY) || ""
                }/category/open/anonymous?categoryId=${
                  categories.data[indexNumber].categoryId
                }&eventTypeId=${currentEvent.eventTypeId}&open=${false}`,
                {}
              );
          if (result) {
            val.open = false;
          }
        } catch (error) {
          dispatch(
            setErr({
              message: error,
              code: 400,
            })
          );
        }
        return val;
      } else if (indexNumber === index && !val.open) {
        try {
          const result = currentRole
            ? axiosPrivate.put(
                `${process.env.REACT_APP_API_PATH}checklist/${
                  currentEvent.checkListId
                }/category/open?categoryId=${
                  categories.data[indexNumber].categoryId
                }&open=${true}`,
                {}
              )
            : axios.put(
                `${process.env.REACT_APP_API_PATH}checklist/${
                  localStorage.getItem(TEMP_USER_KEY) || ""
                }/category/open/anonymous?categoryId=${
                  categories.data[indexNumber].categoryId
                }&eventTypeId=${currentEvent.eventTypeId}&open=${true}`,
                {}
              );
          if (result) {
            val.open = true;
          }
        } catch (error) {
          dispatch(
            setErr({
              message: error,
              code: 400,
            })
          );
        }
        return val;
      } else {
        return val;
      }
    });
    // dispatch(setLoader(false));
    // setloader(false);
    setcategories({data: newList, loading: false});
  };

  const addNewSupplier = () => {
    let value = [...suppliersList];
    value.push({
      id: Math.random(),
      categoryName: "",
      supplierName: "",
      price: "",
      error: false,
    });
    dispatch(setSuppliers(value));
  };

  const addSuppliers = async () => {
    let actualState = [...suppliersList];
    let flag = false;
    let newList = actualState.map((val) => {
      if (val.categoryName === "" || val.supplierName === "" || val.price < 1) {
        flag = true;
        return {
          id: val.id,
          categoryName: val.categoryName,
          supplierName: val.supplierName,
          price: val.price,
          error: true,
        };
      }
      return val;
    });
    dispatch(
      setSuppliers([
        {id: 0, categoryName: "", supplierName: "", price: "", error: false},
      ])
    );
    dispatch(setSuppliers(newList));
    if (!flag) {
      let data = actualState.map((val) => {
        return {
          categoryName: val.categoryName,
          supplierName: val.supplierName,
          price: Number(val.price),
        };
      });
      const result = await axiosPrivate.post(
        `${process.env.REACT_APP_API_PATH}checklist/${currentEvent.checkListId}/custom/category/supplier`,
        data
      );
      if (result) {
        dispatch(
          setSuppliers([
            {
              id: 0,
              categoryName: "",
              supplierName: "",
              price: "",
              error: false,
            },
          ])
        );
        getCheckList(currentEvent.checkListId);
      }
    }
  };

  const deleteSupplier = (index) => {
    let actualState = [...suppliersList];
    actualState.splice(index, 1);
    dispatch(setSuppliers(actualState));
  };

  // const addContract = (item) => {
  //   let actualState = [...suppliersList];
  //   let newList = actualState.map((val)=>{
  //     if((val.categoryName === '' || val.supplierName === '' || val.price < 1) && item.id === val.id){
  //       return {id:val.id, categoryName: val.categoryName, supplierName:val.supplierName, sum:val.price, error:true};
  //     }
  //     return val;
  //   })
  //   dispatch(setSuppliers(newList));
  // };

  const onChangeContract = (index, event, fieldName) => {
    let actualState = [...suppliersList];
    let newLine = {...actualState[index]};
    if (fieldName === "categoryName") {
      newLine.categoryName = event.target.value;
    } else if (fieldName === "supplierName") {
      newLine.supplierName = event.target.value;
    } else if (fieldName === "price") {
      newLine.price = event.target.value;
    }
    actualState[index] = newLine;
    //   dispatch(setSuppliers(actualState));
    let newList = actualState.map((val) => {
      if (val.error) {
        if (
          val.categoryName !== "" &&
          val.supplierName !== "" &&
          val.price > 0
        ) {
          return {
            id: val.id,
            categoryName: val.categoryName,
            supplierName: val.supplierName,
            price: val.price,
            error: false,
          };
        }
        return val;
      }
      return val;
    });
    dispatch(setSuppliers(newList));
  };

  const onChangeServices = async (index2, index, event) => {
    //  dispatch(setLoader(true));
    // setloader(true);
    let actualState = [...categories.data];
    let newLine = actualState[index].suppliers;
    try {
      const result = currentRole
        ? await axiosPrivate.put(
            `${process.env.REACT_APP_API_PATH}checklist/${
              currentEvent.checkListId
            }/supplier/selected?categoryId=${
              actualState[index].categoryId
            }&supplierId=${
              actualState[index].suppliers[index2].supplierId
            }&selected=${!event}`,
            {}
          )
        : await axios.put(
            `${process.env.REACT_APP_API_PATH}checklist/${
              localStorage.getItem(TEMP_USER_KEY) || ""
            }/supplier/selected/anonymous?categoryId=${
              actualState[index].categoryId
            }&eventTypeId=${currentEvent.eventTypeId}&supplierId=${
              actualState[index].suppliers[index2].supplierId
            }&selected=${!event}`,
            {}
          );
      if (result) {
        if (event) {
          newLine[index2].selected = false;
          let newServices =
            userCalculation.services - newLine[index2].totalCost;
          if(newLine[index2].totalMinCost != null && newLine[index2].totalCost != null){
            if(findChecklist.countOfGuests){
              newServices = userCalculation.services - (((newLine[index2].totalMinCost + newLine[index2].totalCost) / 2) * findChecklist.countOfGuests);
            } else {
              newServices = userCalculation.services 
            }
          }
          let newBudget = userCalculation.budget + newLine[index2].totalCost;
          setuserCalculation({services: newServices, budget: newBudget});
        } else {
          newLine[index2].selected = true;
          let newServices =
            userCalculation.services + newLine[index2].totalCost;
          if(newLine[index2].totalMinCost != null && newLine[index2].totalCost != null){
            if(findChecklist.countOfGuests){
              newServices = userCalculation.services + (((newLine[index2].totalMinCost + newLine[index2].totalCost) / 2) * findChecklist.countOfGuests);
            } else {
              newServices = userCalculation.services 
            }
          }
          let newBudget = userCalculation.budget - newLine[index2].totalCost;
          setuserCalculation({services: newServices, budget: newBudget});
        }
        actualState[index].suppliers = newLine;
        let newList = actualState;
        setcategories({data: newList, loading: false});
      }
    } catch (error) {
      dispatch(
        setErr({
          message: error,
          code: 400,
        })
      );
    }
    // setloader(false);
    //  dispatch(setLoader(false));
  };

  function openchat(supplierId, categoryId) {
    let userId = JSON.parse(auth.user).id;
    let eventType = {
      id: currentEvent.eventTypeId,
      name: currentEvent.eventTypeName,
    };
    dispatch(setCurrentEventChat({currentEvent: eventType}));
    navigate(
      `/personal/chat/${supplierId}/${userId}/${currentEventType}/${categoryId}/${currentEvent.checkListId}`
    );
  }

  function getPrice() {
    if (dialogServices?.services?.length > 0) {
      let price = 0;
      dialogServices?.services.map((service) => {
        price = price + service.price;
        return service;
      });
      return price.toFixed(0);
    } else {
      return 0;
    }
  }

  function getMobilePrice() {
    if (servicesMobile?.services?.services?.length > 0) {
      let price = 0;
      servicesMobile?.services?.services.map((service) => {
        price = price + service.price;
        return service;
      });
      return price;
    } else {
      return 0;
    }
  }

  function openSuppliers(item) {
    let data = {id: item.categoryId, name: item.name};
    let eventType = {
      id: currentEvent.eventTypeId,
      name: currentEvent.eventTypeName,
    };
    dispatch(setCurrentSupplierEvent({currentSupplierEvent: eventType}));
    dispatch(setCurrentEvent({currentEvent: eventType}));
    dispatch(setSelectedCategoryId(data));

    localStorage.setItem("idCategory", item.categoryId);
    localStorage.setItem("nameCategory", item.name);
    if (numberWithCommas(findChecklist.countOfGuests)) {
      localStorage.setItem(
        "guestAmount",
        numberWithCommas(findChecklist.countOfGuests)
      );
    }
    navigate(`/suppliers`);
  }

  function openSupplier(item, supId) {
    if (item.categoryId !== 0) {
      let data = {id: item.categoryId, name: item.name};
      let eventType = {
        id: currentEvent.eventTypeId,
        name: currentEvent.eventTypeName,
      };
      dispatch(setCurrentSupplierEvent({currentSupplierEvent: eventType}));
      dispatch(setCurrentEvent({currentEvent: eventType}));
      dispatch(setSelectedCategoryId(data));

      dispatch(setSelectedEventTypeId(currentEventChecklist.eventTypeId));
      navigate(`/supplier/${supId}`);
    }
  }

  function showDetails() {
    if (!showMobileDetails) {
      setshowMobileDetails(true);
    } else {
      setshowMobileDetails(false);
    }
  }

  const deleteMobileSupplier = async () => {
    let mobileServices = servicesMobile.services;
    let actualState = [...categories.data];
    let newLine;
    let categoryIndex;

    actualState.map((item, index) => {
      if (item.categoryId === mobileServices.categoryId) {
        categoryIndex = index;
        newLine = item;
      } else {
        return item;
      }
      return item;
    });
    let supllierIndex = 0;
    let newServices = newLine.suppliers;
    newLine.suppliers.map((val, index) => {
      if (val.supplierId === mobileServices.supplierId) {
        supllierIndex = index;
      }
      return val;
    });
    const result = currentRole
      ? await axiosPrivate.delete(
          `${process.env.REACT_APP_API_PATH}checklist/${currentEvent.checkListId}/supplier?categoryId=${servicesMobile.services.categoryId}&eventTypeId=${currentEvent.eventTypeId}&supId=${servicesMobile.services.supplierId}`
        )
      : await axios.delete(
          `${process.env.REACT_APP_API_PATH}checklist/${
            currentEvent.checkListId
          }/supplier/anonymous?categoryId=${
            servicesMobile.services.categoryId
          }&eventTypeId=${currentEvent.eventTypeId}&supId=${
            servicesMobile.services.supplierId
          }&userToken=${localStorage.getItem(TEMP_USER_KEY) || ""}`
        );
    if (result) {
      newServices.splice(supllierIndex, 1);
      let newList = actualState;
      newList[categoryIndex].suppliers = newServices;
      setcategories({data: newList, loading: false});
      setMobileShowServices(false);
      onChangeCalculation();
    }
  };

  const deleteService = async (service, index) => {
    let serviceTypeId = service.serviceTypeId;
    let actualState = [...categories.data];
    let categoryIndex = dialogServices.categoryIndex;
    let supplierIndex = dialogServices.supplierIndex;
    let newLine = actualState;
    let categoryId = dialogServices.categoryId;
    let supplierId = dialogServices.supplierId;
    const result = currentRole
      ? await axiosPrivate.delete(
          `${process.env.REACT_APP_API_PATH}checklist/${currentEvent.checkListId}/service?categoryId=${categoryId}&eventTypeId=${currentEvent.eventTypeId}&supId=${supplierId}&serviceTypeId=${serviceTypeId}`
        )
      : await axios.delete(
          `${process.env.REACT_APP_API_PATH}checklist/${
            currentEvent.checkListId
          }/service/anonymous?categoryId=${categoryId}&eventTypeId=${
            currentEvent.eventTypeId
          }&supId=${supplierId}&serviceTypeId=${serviceTypeId}&userToken=${
            localStorage.getItem(TEMP_USER_KEY) || ""
          }`
        );
    if (result) {
      newLine[categoryIndex].suppliers[supplierIndex].totalCost =
        result.data.totalCost;
      if (result.data.totalMinCost !== null) {
        newLine[categoryIndex].suppliers[supplierIndex].totalMinCost =
          result.data.totalMinCost;
      }
      newLine[categoryIndex].suppliers[supplierIndex].services =
        result.data.services;
      let services = newLine[categoryIndex].suppliers[supplierIndex].services;
      let name = newLine[categoryIndex].suppliers[supplierIndex].supplierName;
      // if (services.length < 1) {
      //     newLine[categoryIndex].suppliers.splice(supplierIndex, 1);
      // }
      setcategories({data: newLine, loading: false});
      setdialogServices({
        services: services,
        categoryIndex: categoryIndex,
        supplierIndex: supplierIndex,
        categoryId: categoryId,
        supplierId: supplierId,
        supplierName: name,
      });
      getChecklist();
      onChangeCalculation();
    }
  };

  const deleteMobileService = async (index, service) => {
    let actualState = [...categories.data];
    let categoryIndex = servicesMobile.categoryIndex;
    let supplierIndex = servicesMobile.supplierIndex;
    let newLine = actualState;
    let categoryId = servicesMobile.services.categoryId;
    let supplierId = servicesMobile.services.supplierId;
    let serviceTypeId = service.serviceTypeId;
    const result = currentRole
      ? await axiosPrivate.delete(
          `${process.env.REACT_APP_API_PATH}checklist/${currentEvent.checkListId}/service?categoryId=${categoryId}&eventTypeId=${currentEvent.eventTypeId}&supId=${supplierId}&serviceTypeId=${serviceTypeId}`
        )
      : await axios.delete(
          `${process.env.REACT_APP_API_PATH}checklist/${
            currentEvent.checkListId
          }/service/anonymous?categoryId=${categoryId}&eventTypeId=${
            currentEvent.eventTypeId
          }&supId=${supplierId}&serviceTypeId=${serviceTypeId}&userToken=${
            localStorage.getItem(TEMP_USER_KEY) || ""
          }`
        );
    if (result) {
      newLine[categoryIndex].suppliers[supplierIndex].totalCost =
        result.data.totalCost;
      newLine[categoryIndex].suppliers[supplierIndex].services =
        result.data.services;
      let services = newLine[categoryIndex].suppliers[supplierIndex].services;
      if (result.data.totalMinCost !== null) {
        newLine[categoryIndex].suppliers[supplierIndex].totalMinCost =
          result.data.totalMinCost;
      }
      let name = newLine[categoryIndex].suppliers[supplierIndex].supplierName;
      if (newLine[categoryIndex].suppliers[supplierIndex].services.length < 1) {
        //newLine[categoryIndex].suppliers.splice(supplierIndex, 1);
        setshowDelete(false);
      }
      setcategories({data: newLine, loading: false});
      setdialogServices({
        services: services,
        categoryIndex: categoryIndex,
        supplierIndex: supplierIndex,
        categoryId: categoryId,
        supplierId: supplierId,
        supplierName: name,
      });
      onChangeCalculation();
    }
  };
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  // function numberWithCommasAnonimous(val) {
  //     let x = Number(val) - Number(calculation.overallBudget);
  //     return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // }

  function numberWithCommasAnonimousLeftField(val) {
    //   let x = (Number(val) - Number(calculation.overallBudget)) / Number(findChecklist?.countOfGuests);
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <>
      {/* {loader && <div className={styles.mainContainer} style={{height: document.getElementById('parent').clientHeight}}>
              <div className={styles.ldsDualRing}></div>
        </div>} */}
      <MetaFavicon />
      <div id="parent">
        {calculation &&
          (calculation.overallBudget === 0 ||
            calculation.amountOfGuests === 0) && (
            <div className={styles.checklist_massage}>
              על מנת להתחיל לעבוד עם רשימת הצ׳קליסט עבור האירוע שלך, עליך להזין
              תחילה מהו התקציב ומספר המוזמנים המתוכננים לאירוע
            </div>
          )}
        {openChecklist && (
          <div className={styles.calculatorLine}>
            <div className={styles.calculationBlocks}>
              <div>
                <div className={styles.calculatorTitles}>?על כמה אני</div>
                <div className={styles.calcButton}>
                  <div style={{direction: "ltr"}} className={styles.calcSum}>
                    {numberWithCommas(userCalculation.services.toFixed(0))}
                  </div>
                  {/* {(currentRole === 'SUPPLIER' || currentRole === 'LABEL' || currentRole === 'USER') && <div style={{direction: 'ltr'}} className={styles.calcSum}>{numberWithCommas((userCalculation.services).toFixed(0))}</div>}
                            {(currentRole !== 'SUPPLIER' && currentRole !== 'LABEL' && currentRole !== 'USER') && <div style={{direction: 'ltr'}} className={styles.calcSum}>{numberWithCommasAnonimous((userCalculation.services).toFixed(0))}</div>} */}
                  <div className={styles.shekel_calc}>
                    <img
                      className={styles.calc_shekel}
                      src={shekel_calc}
                      alt="shekel"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.calcBlock}>
                <div className={styles.calculatorTitles}>
                  פלוס\מינוס מהתקציב
                </div>
                {/* {(currentRole === 'SUPPLIER' || currentRole === 'LABEL' || currentRole === 'USER') && <div className={styles.calcButton}>
                            {calculation?.overallBudget - userCalculation?.services > 0 && <div style={{direction: 'ltr'}}
                                                                className={styles.calcSum}>+{numberWithCommas((calculation?.overallBudget - userCalculation?.services).toFixed(0))}</div>}
                            {calculation?.overallBudget - userCalculation?.services <= 0 && <div style={{direction: 'ltr'}}
                                                                className={styles.calcSum}>{numberWithCommas((calculation?.overallBudget - userCalculation?.services).toFixed(0))}</div>}
                            <div className={styles.shekel_calc}><img class={styles.calc_shekel} src={shekel_calc} alt="shekel"/></div>
                        </div>}
                        {(currentRole !== 'SUPPLIER' && currentRole !== 'LABEL' && currentRole !== 'USER') && <div className={styles.calcButton}>
                            {calculation?.overallBudget - userCalculation?.services > 0 && <div style={{direction: 'ltr'}}
                                                                className={styles.calcSum}>+{numberWithCommas((calculation?.overallBudget - userCalculation?.services + Number(calculation?.generalBudget)).toFixed(0))}</div>}
                            {calculation?.overallBudget - userCalculation?.services <= 0 && <div style={{direction: 'ltr'}}
                                                                className={styles.calcSum}>{numberWithCommas((calculation?.overallBudget - userCalculation?.services + Number(calculation?.generalBudget)).toFixed(0))}</div>}
                            <div className={styles.shekel_calc}><img class={styles.calc_shekel} src={shekel_calc} alt="shekel"/></div>
                        </div>} */}
                <div className={styles.calcButton}>
                  {calculation?.overallBudget - userCalculation?.services >
                    0 && (
                    <div style={{direction: "ltr"}} className={styles.calcSum}>
                      +
                      {numberWithCommas(
                        (
                          calculation?.overallBudget - userCalculation?.services
                        ).toFixed(0)
                      )}
                    </div>
                  )}
                  {calculation?.overallBudget - userCalculation?.services <=
                    0 && (
                    <div style={{direction: "ltr"}} className={styles.calcSum}>
                      {numberWithCommas(
                        (
                          calculation?.overallBudget - userCalculation?.services
                        ).toFixed(0)
                      )}
                    </div>
                  )}
                  <div className={styles.shekel_calc}>
                    <img
                      className={styles.calc_shekel}
                      src={shekel_calc}
                      alt="shekel"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.calcBlock}>
                <div className={styles.calculatorTitles}>עלות לאורח</div>
                <div className={styles.calcButton}>
                  {(currentRole === "SUPPLIER" ||
                    currentRole === "LABEL" ||
                    currentRole === "USER") && (
                    <div style={{direction: "ltr"}} className={styles.calcSum}>
                      {Number(findChecklist.countOfGuests) === 0 ||
                      Number(userCalculation.services) /
                        Number(findChecklist.countOfGuests) <
                        1
                        ? 0
                        : numberWithCommas(
                            (
                              Number(userCalculation.services) /
                              Number(findChecklist.countOfGuests)
                            ).toFixed(0)
                          )}
                    </div>
                  )}
                  {currentRole !== "SUPPLIER" &&
                    currentRole !== "LABEL" &&
                    currentRole !== "USER" && (
                      <div
                        style={{direction: "ltr"}}
                        className={styles.calcSum}
                      >
                        {Number(findChecklist.countOfGuests) === 0 ||
                        Number(userCalculation.services) /
                          Number(findChecklist.countOfGuests) <
                          1
                          ? 0
                          : numberWithCommasAnonimousLeftField(
                              (
                                Number(userCalculation.services) /
                                Number(findChecklist.countOfGuests)
                              ).toFixed(0)
                            )}
                      </div>
                    )}
                  <div className={styles.shekel_calc}>
                    <img
                      className={styles.calc_shekel}
                      src={shekel_calc}
                      alt="shekel"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className={styles.mobile}>
          <div className={styles.mobile_title}>הצ'קליסט שלי</div>
          {!openChecklist && (
            <div className={styles.mobile_add_checklist}>
              <img
                style={{cursor: "pointer"}}
                onClick={() => openMobileFindChecklist()}
                src={mobile_plus}
                alt="plus"
              />
              <div
                style={{cursor: "pointer"}}
                onClick={() => openMobileFindChecklist()}
              >
                הוסף את פרטי האירוע
              </div>
            </div>
          )}
          {openChecklist && (
            <div
              style={{cursor: "pointer"}}
              onClick={() => openMobileFindChecklist()}
              className={styles.mobile_change_checklist}
            >
              פרטי האירוע
            </div>
          )}
        </div>
        <div className={styles.desctop}>
          <div className={styles.findChecklist}>
            <div className={styles.header}>
              <div>בחר\י אירוע מתוך רשימת האירועים שלך</div>
              <div className={styles.checklistSelect}>
                <Select
                  placeholder={" "}
                  fontFamilyHeader={"Heebo-Regular"}
                  backgroundColor={"#EEE9E5"}
                  options={checklists.data}
                  onChangeOption={onChangeOption}
                  selectedOption={currentEvent || defaultEventType}
                />
              </div>
            </div>
            <div className={styles.header}>
              <div>התקציב שלי (מחיר כולל)</div>
              <div className={styles.shekelContainer}>
                <input
                  value={numberWithCommas(findChecklist.generalBudget)}
                  onChange={(event) => onChangeForm(event, "generalBudget")}
                  className={styles.headerPrice}
                  type="text"
                />
                <img
                  src={shekelSymbol}
                  alt="shekel"
                  className={styles.shekelSymbol}
                />
              </div>
            </div>
            <div className={styles.header}>
              <div>מספר מוזמנים (מקסימום)</div>
              <div>
                <input
                  value={numberWithCommas(findChecklist.countOfGuests)}
                  onChange={(event) => onChangeForm(event, "countOfGuests")}
                  type="text"
                  className={styles.people}
                />
              </div>
            </div>
            <div>
              <button
                onClick={() => getChecklist()}
                className={styles.headerButton}
              >
                {"שמור"}
              </button>
            </div>
          </div>
        </div>
        {openChecklist && (
          <div className={styles.mainBlock}>
            <div className={styles.firstLine}>
              <hr className={styles.line}></hr>
            </div>
            <div className={styles.calculatorBlock}>
              <div className={styles.calculatorInfo}>
                המחשבון שלנו יעזור לך לתכנן ולשלוט בהוצאות עבור{" "}
                {currentEvent.eventTypeName}{" "}
              </div>
              <div>
                <div className={styles.useless_title}>
                  חישוב לפי ספקים שסגרתי
                </div>
                <div className={styles.buttonsBlock}>
                  <div className={styles.calculatorButton}>
                    <div className={styles.buttonTitle}>ניצול מהתקציב</div>
                    <div className={styles.buttonSum}>
                      {numberWithCommas(calculation?.percent)}%
                    </div>
                  </div>
                  <div className={styles.calculatorButton}>
                    <div className={styles.buttonTitle}>עלות עבור חוגג</div>
                    <div className={styles.buttonSum}>
                      {numberWithCommas(
                        Math.round(calculation?.sumForOneGuest)
                      )}
                    </div>
                  </div>
                  <div className={styles.calculatorButton}>
                    <div className={styles.buttonTitle}>עלות עד כה</div>
                    {(currentRole === "SUPPLIER" ||
                      currentRole === "LABEL" ||
                      currentRole === "USER") && (
                      <div className={styles.buttonSumDesctop}>
                        {numberWithCommas(
                          Math.round(calculation?.generalBudget)
                        )}
                      </div>
                    )}
                    {currentRole !== "SUPPLIER" &&
                      currentRole !== "LABEL" &&
                      currentRole !== "USER" && (
                        <div className={styles.buttonSumDesctop}>0</div>
                      )}
                    <div className={styles.buttonSumMobile}>
                      {Math.round(calculation?.generalBudget / 1000)}K
                    </div>
                    <img
                      src={shekel}
                      alt="shekel"
                      className={styles.shekel_desctop}
                    />
                  </div>
                  {calculation?.remainingBudget >= 0 && (
                    <div
                      className={styles.calculatorButton}
                      style={{backgroundColor: "#6EF265"}}
                    >
                      <div className={styles.buttonTitle}>עודף\חוסר</div>
                      <div className={styles.buttonSumDesctop}>
                        {numberWithCommas(
                          Math.round(calculation?.remainingBudget)
                        )}
                      </div>
                      <div className={styles.buttonSumMobile}>
                        {numberWithCommas(
                          Math.round(calculation?.remainingBudget / 1000)
                        )}
                        K
                      </div>
                      <img
                        src={shekel}
                        alt="shekel"
                        className={styles.shekel_desctop}
                      />
                    </div>
                  )}
                  {calculation?.remainingBudget < 0 && (
                    <div
                      className={styles.calculatorButton}
                      style={{backgroundColor: "rgb(242 101 101)"}}
                    >
                      <div className={styles.buttonTitle}>עודף\חוסר</div>
                      <div className={styles.buttonSumDesctop}>
                        {numberWithCommas(
                          Math.round(calculation?.remainingBudget)
                        )}
                      </div>
                      <div className={styles.buttonSumMobile}>
                        {numberWithCommas(
                          Math.round(calculation?.remainingBudget / 1000)
                        )}
                        K
                        {/* className={styles.buttonSumMobile}>{numberWithCommas(Math.round(calculation?.rechecklistsmainingBudget / 1000))}K */}
                      </div>
                      <img
                        src={shekel}
                        alt="shekel"
                        className={styles.shekel_desctop}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className={styles.table}>
              {!categories.loading &&
                categories.data.map((item, index) => {
                  return (
                    <div
                      key={index + " first"}
                      ref={item.lastUpdateCategory === true ? myRef : myRef2}
                      id={`checklist_${item?.name || 'item'}`}
                      className={styles.tableBlock}
                    >
                      <div className={styles.categoryColumn}>
                        <div
                          onClick={() => checkOpenFields(index)}
                          id={`checklist_${item?.name || 'item'}_toggle`}
                          className={styles.categoryName}
                        >
                          <div
                            className={
                              item?.suppliers.length > 0
                                ? styles.rowTitleBold
                                : styles.rowTitle
                            }
                          >
                            {item.name}
                          </div>
                          {item.countSignedContracts > 0 && (
                            <div className={styles.rowText}>
                              נסגרה בהצלחה עסקה עם ספק{" "}
                              {item.countSignedContracts}
                            </div>
                          )}
                          {item.countSignedContracts === 0 &&
                            item?.suppliers.length > 0 && (
                              <div className={styles.rowText}>
                                קיימים ספקים ברשימת החיפוש שלך
                              </div>
                            )}
                        </div>
                        {(item.countSignedContracts < 1 ||
                          item.additionalId !== null) && (
                          <div id={`checklist_${item?.name || 'item'}_delete`}>
                            <img
                              onClick={() => {
                                deleteCategory(
                                  index,
                                  item.categoryId,
                                  item.additionalId
                                );
                              }}
                              src={remove}
                              alt="remove"
                              className={classNames(
                                styles.remove,
                                styles.mobile_remove
                              )}
                            />
                          </div>
                        )}
                        {item.countSignedContracts > 0 &&
                          item.open &&
                          item.additionalId === null && (
                            <div>
                              <img
                                onClick={() => checkOpenFields(index)}
                                src={close}
                                alt="close"
                                className={styles.close_category}
                              />
                            </div>
                          )}
                      </div>

                      {item.open && (
                        <div className={styles.detailsBlock}>
                          <table>
                            <tbody>
                              {item?.suppliers?.map((service, index2) => {
                                return (
                                  service.isThereContract === true && (
                                    <tr
                                      key={index2 + " supplier"}
                                      className={styles.mobile_contract_block}
                                    >
                                      <td className={styles.contract_icon}>
                                        <img
                                          src={contract_pink}
                                          className={styles.contract_supplier}
                                          alt="contract"
                                        />
                                      </td>
                                      <td
                                        className={styles.contract_name}
                                        style={{
                                          cursor:
                                            item.categoryId !== 0
                                              ? "pointer"
                                              : "default",
                                        }}
                                        onClick={() =>
                                          !service.isDelete
                                            ? openSupplier(
                                                item,
                                                service.supplierId
                                              )
                                            : ""
                                        }
                                      >
                                        {service.supplierName}
                                      </td>
                                      <td>
                                        <div className={styles.contract_sum}>
                                          <div>
                                            {numberWithCommas(
                                              service.totalCost
                                            )}
                                          </div>
                                          <div>
                                            <img
                                              src={shekel}
                                              alt="shekel"
                                              className={styles.contract_shekel}
                                            />
                                          </div>
                                        </div>
                                      </td>
                                      {item.categoryId !== 0 && (
                                        <td
                                          className={classNames(styles.desctop)}
                                        >
                                          <div
                                            className={classNames(
                                              styles.contract_eye_padding
                                            )}
                                          >
                                            <img
                                              style={{cursor: "pointer"}}
                                              src={eye}
                                              onClick={() => {
                                                handleClickOpen(
                                                  service.services,
                                                  item.countSignedContracts > 0
                                                    ? false
                                                    : true,
                                                  index,
                                                  index2,
                                                  item.categoryId,
                                                  service.supplierId,
                                                  service.supplierName
                                                );
                                              }}
                                              alt="eye"
                                              className={classNames(
                                                styles.contract_eye_icon,
                                                styles.mobile_contract_eye
                                              )}
                                            />
                                          </div>
                                        </td>
                                      )}
                                      {item.categoryId !== 0 && (
                                        <td
                                          className={classNames(styles.mobile)}
                                        >
                                          <div
                                            className={classNames(
                                              styles.contract_eye_padding
                                            )}
                                          >
                                            <img
                                              style={{cursor: "pointer"}}
                                              src={eye}
                                              onClick={() => {
                                                openMobileservices(
                                                  service,
                                                  item.categoryId,
                                                  index,
                                                  index2,
                                                  item.countSignedContracts > 0
                                                    ? false
                                                    : true
                                                );
                                              }}
                                              alt="eye"
                                              className={classNames(
                                                styles.contract_eye_icon,
                                                styles.mobile_contract_eye
                                              )}
                                            />{" "}
                                          </div>
                                        </td>
                                      )}
                                    </tr>
                                  )
                                );
                              })}
                            </tbody>
                          </table>

                          {item.countSignedContracts > 0 && (
                            <hr className={styles.contract_line}></hr>
                          )}
                          <table className={styles.table_margin_bottom}>
                            <tbody>
                              {item?.suppliers?.map((service, index2) => {
                                return (
                                  service.isThereContract === false && (
                                    <tr
                                      key={index2 + "second"}
                                      className={styles.contract_services_block}
                                    >
                                      <td>
                                        <div
                                          className={
                                            styles.contract_services_checkbox
                                          }
                                        >
                                          {service.supplierId === 0 ? (
                                            <img
                                              src={contract_pink}
                                              alt="contract"
                                              className={styles.supplierIcon}
                                              style={{cursor: "pointer"}}
                                            />
                                          ) : (
                                            <div className={styles.radio}>
                                              <input
                                                type="checkbox"
                                                name={index + "" + index2}
                                                id={index + "" + index2}
                                                checked={service.selected}
                                                value={service.selected}
                                                onChange={(e) =>
                                                  onChangeServices(
                                                    index2,
                                                    index,
                                                    service.selected
                                                  )
                                                }
                                              />
                                              <label
                                                htmlFor={index + "" + index2}
                                              ></label>
                                            </div>
                                          )}
                                        </div>
                                      </td>
                                      <td
                                        className={classNames(
                                          styles.contract_service_name,
                                          styles.mobile_name
                                        )}
                                        style={{cursor: "pointer"}}
                                        onClick={() =>
                                          !service.isDelete ||
                                          item.additionalId !== null
                                            ? openSupplier(
                                                item,
                                                service.supplierId
                                              )
                                            : ""
                                        }
                                      >
                                        {service.supplierName}
                                      </td>
                                      <td>
                                        <div
                                          className={classNames(
                                            styles.contract_sum,
                                            styles.mobile_table_price
                                          )}
                                        >
                                          {service?.totalMinCost !== null ? (
                                            <div>
                                              {" "}
                                              {numberWithCommas(
                                                service.totalCost.toFixed(0)
                                              )}{" "}
                                              -{" "}
                                              {numberWithCommas(
                                                service.totalMinCost.toFixed(0)
                                              )}
                                            </div>
                                          ) : (
                                            <div>
                                              {numberWithCommas(
                                                service.totalCost.toFixed(0)
                                              )}
                                            </div>
                                          )}{" "}
                                          <div>
                                            <img
                                              src={shekel}
                                              alt="shekel"
                                              className={styles.contract_shekel}
                                            />
                                          </div>
                                        </div>
                                      </td>
                                      {item.additionalId === null && (
                                        <td
                                          className={classNames(
                                            styles.contract_eye,
                                            styles.desctop
                                          )}
                                        >
                                          <img
                                            src={eye}
                                            onClick={() => {
                                              handleClickOpen(
                                                service.services,
                                                true,
                                                index,
                                                index2,
                                                item.categoryId,
                                                service.supplierId,
                                                service.supplierName
                                              );
                                            }}
                                            alt="eye"
                                            className={classNames(
                                              styles.contract_eye_icon,
                                              styles.desctop_contract_eye
                                            )}
                                          />
                                        </td>
                                      )}
                                      {item.additionalId === null && (
                                        <td
                                          style={{cursor: "pointer"}}
                                          className={
                                            styles.contract_service_supplier
                                          }
                                        >
                                          {currentRole && (
                                            <span
                                              onClick={() =>
                                                !service.isDelete
                                                  ? openchat(
                                                      service.supplierId,
                                                      item.categoryId
                                                    )
                                                  : ""
                                              }
                                            >
                                              פנייה לספק
                                            </span>
                                          )}
                                        </td>
                                      )}
                                      {item.additionalId === null && (
                                        <td className={styles.desctop}>
                                          <div style={{height: "1.125rem"}}>
                                            <img
                                              onClick={(e) =>
                                                deleteCategorySupplier(
                                                  index2,
                                                  index,
                                                  item.categoryId,
                                                  service.supplierId
                                                )
                                              }
                                              src={remove_supplier}
                                              alt="remove_supplier"
                                              className={
                                                styles.contract_suppliers_remove
                                              }
                                            />
                                          </div>
                                        </td>
                                      )}
                                      {item.additionalId === null && (
                                        <td
                                          className={classNames(
                                            styles.contract_eye,
                                            styles.mobile
                                          )}
                                        >
                                          <img
                                            onClick={() => {
                                              openMobileservices(
                                                service,
                                                item.categoryId,
                                                index,
                                                index2,
                                                true
                                              );
                                            }}
                                            src={details}
                                            alt="details"
                                            className={classNames(
                                              styles.contract_eye_icon,
                                              styles.mobile_contract_eye
                                            )}
                                          />
                                        </td>
                                      )}
                                    </tr>
                                  )
                                );
                              })}
                            </tbody>
                          </table>

                          {(item.countSignedContracts > 0 ||
                            item?.suppliers.length > 0) && (
                            <hr className={styles.contract_line}></hr>
                          )}

                          <div
                            id={`checklist_${item?.name || 'item'}_search`}
                            style={{cursor: "pointer"}}
                            className={styles.contract_supplier_link}
                          >
                            <span
                              onClick={() => {
                                openSuppliers(item);
                              }}
                            >
                              חיפוש ספקים
                            </span>
                          </div>
                        </div>
                      )}

                      <hr className={styles.rowLine}></hr>
                    </div>
                  );
                })}
              <span
                className={classNames(styles.lineEndTable, styles.desctop)}
              ></span>
              {currentRole && (
                <div className={styles.mobile}>
                  <div
                    style={{cursor: "pointer"}}
                    onClick={() => openMobileAddNewSupplier()}
                    className={styles.mobile_add_supplier}
                  >
                    הוספת ספק (ידני)
                  </div>
                </div>
              )}

              {currentRole && (
                <div
                  className={classNames(styles.supplierblock, styles.desctop)}
                >
                  <div className={styles.suppliertitle}>
                    הוספת ספק (ידני - על מנת לכלול מחיר במחשבון האירוע שלך)
                  </div>

                  {suppliersList.map((item, index) => {
                    return (
                      <div key={item.id + index}>
                        <div className={styles.addSupplierBlock}>
                          <input
                            className={styles.supplierCategory}
                            value={item.categoryName}
                            onChange={(event) =>
                              onChangeContract(index, event, "categoryName")
                            }
                            placeholder="קטגוריה (מלל חופשי)"
                            type="text"
                          />
                          <input
                            className={styles.supplierCompanyName}
                            onChange={(event) =>
                              onChangeContract(index, event, "supplierName")
                            }
                            value={item.supplierName}
                            placeholder="שם העסק (מלל חופשי)"
                            type="text"
                          />
                          <div style={{position: "relative"}}>
                            <input
                              className={styles.supplierSum}
                              value={item.price}
                              onChange={(event) =>
                                onChangeContract(index, event, "price")
                              }
                              placeholder="הכנס סכום ידני"
                              type="number"
                            />
                            <img
                              src={sum_shekel}
                              alt="shekel"
                              className={styles.shekelSum}
                            />
                          </div>
                          <img
                            src={contract}
                            alt="contract"
                            className={styles.supplierContract}
                            style={{cursor: "pointer"}}
                          />
                          {index !== 0 && (
                            <img
                              src={remove}
                              alt="remove"
                              onClick={() => deleteSupplier(index)}
                              className={styles.remove}
                            />
                          )}
                          {index === 0 && (
                            <img
                              src={remove}
                              alt="remove"
                              style={{cursor: "default"}}
                              className={styles.remove}
                            />
                          )}
                        </div>
                        {item?.error && (
                          <div className={styles.errorMessageSuppliers}>
                            מלא את כל השדות
                          </div>
                        )}
                      </div>
                    );
                  })}

                  <div className={styles.supplierFooter}>
                    <div
                      className={styles.supplierAdd}
                      onClick={() => {
                        addNewSupplier();
                      }}
                    >
                      <img src={plus} alt="plus" className={styles.remove} />{" "}
                      &nbsp; הוספת ספק
                    </div>
                    <div
                      onClick={() => {
                        addSuppliers();
                      }}
                      style={{cursor: "pointer"}}
                      className={styles.supplierLink}
                    >
                      הוספה לצ'קליסט שלי
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className={styles.footer}></div>
          </div>
        )}
      </div>
      <Dialog
        hideBackdrop={true}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "755px",
              boxShadow: "none",
              overflow: "hidden",
              borderRadius: "0",
            },
          },
        }}
        open={openPopup}
        fullWidth={true}
        className={styles.infoDialog}
      >
        <div className={styles.mainPopup}>
          <div className={styles.close}>
            <svg
              style={{cursor: "pointer"}}
              xmlns="http://www.w3.org/2000/svg"
              width="25.477"
              height="25.47"
              viewBox="0 0 25.477 25.47"
              onClick={() => handleClickClose()}
            >
              <path
                id="Icon_ionic-ios-close"
                data-name="Icon ionic-ios-close"
                d="M27.042,24.024l9.1-9.1a2.132,2.132,0,1,0-3.015-3.015l-9.1,9.1-9.1-9.1a2.132,2.132,0,1,0-3.015,3.015l9.1,9.1-9.1,9.1a2.132,2.132,0,0,0,3.015,3.015l9.1-9.1,9.1,9.1a2.132,2.132,0,0,0,3.015-3.015Z"
                transform="translate(-11.285 -11.289)"
                fill="#242323"
              />
            </svg>
          </div>
          <div className={styles.blockCalendar}>
            <div className={styles.dialog_title}>
              פירוט השירותים שנקבעו עם {dialogServices.supplierName}
            </div>
            <hr className={styles.dialog_title_line}></hr>
          </div>
          <div className={styles.dialog_services_block}>
            <table>
              <tbody>
                {dialogServices?.services?.length > 0 &&
                  dialogServices?.services.map((service, index) => {
                    return (
                      <tr key={index} className={styles.dialog_services}>
                        {showDelete && (
                          <td className={styles.dialog_service_name}>
                            {service.serviceName}
                          </td>
                        )}
                        {!showDelete && (
                          <td className={styles.dialog_service_name_delete}>
                            {service.serviceName}
                          </td>
                        )}
                        <td className={styles.dialog_service_sum_td}>
                          <div className={styles.dialog_service_sum}>
                            <div>{numberWithCommas(service.price)}</div>
                            <div style={{marginRight: "0.23125rem"}}>
                              <img
                                src={shekel}
                                alt="shekel"
                                className={styles.contract_shekel}
                              />
                            </div>
                          </div>
                        </td>
                        {showDelete && (
                          <td
                            onClick={() => {
                              deleteService(service, index);
                            }}
                            className={styles.dialog_service_delete}
                          >
                            <span style={{cursor: "pointer"}}>הסר</span>
                          </td>
                        )}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {/* {dialogServices.length > 0 && dialogServices.map((service, index) => {
          return (
          <div key={index} className={styles.dialog_services}>
              <div className={styles.dialog_service_name}>{service.name}</div>
              <div className={styles.dialog_service_sum}>
                <div>{service.price}</div>
                <img src={shekel} alt="shekel" className={styles.contract_shekel}/>
              </div>
          </div>
          )}
        )} */}
          </div>
          <hr className={styles.dialog_services_line}></hr>
          <div className={styles.dialog_all_sum}>
            סכום כולל - {numberWithCommas(getPrice())}
            <img src={shekel} alt="shekel" className={styles.dialog_shekel} />
          </div>
          <div className={styles.dialog_buttons_block}>
            <button
              className={styles.dialog_right_button}
              onClick={() => handleClickClose()}
            >
              חזרה לצ׳קליסט
            </button>
            <button
              className={styles.dialog_left_button}
              onClick={() => goToDocuments()}
            >
              מעבר למסמכים
            </button>
          </div>
        </div>
      </Dialog>

      {/* add supplier */}
      <Dialog
        hideBackdrop={true}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "340px",
              boxShadow: "none",
              overflow: "hidden",
              borderRadius: "0",
            },
          },
        }}
        open={openMobileAddSupplier}
        fullWidth={true}
        className={styles.infoDialog}
      >
        <div className={styles.mainPopup}>
          <div className={styles.close}>
            <img
              style={{cursor: "pointer"}}
              onClick={() => closeMobileAddNewSupplier(false)}
              src={close_icon}
              alt="closeIcon"
            />
          </div>
          <div className={styles.dialog_supplier_title}>הוספת ספק (ידני)</div>
          <div className={styles.mobile_add_suppliers}>
            <input
              className={styles.mobile_add_supplierCategory}
              value={suppliersList[0].categoryName}
              onChange={(event) => onChangeContract(0, event, "categoryName")}
              placeholder="סוג השירות"
              type="text"
            />
            <input
              className={styles.mobile_add_supplierCategory}
              onChange={(event) => onChangeContract(0, event, "supplierName")}
              value={suppliersList[0].supplierName}
              placeholder="שם העסק"
              type="text"
            />
            <div
              className={styles.mobile_add_block}
              style={{position: "relative"}}
            >
              <input
                className={styles.mobile_add_sum}
                value={suppliersList[0].price}
                onChange={(event) => onChangeContract(0, event, "price")}
                placeholder="סכום בשקלים"
                type="number"
              />
              <img
                src={sum_shekel}
                alt="shekel"
                className={styles.mobile_shekelSum}
              />
            </div>
            {suppliersList[0].error && (
              <div className={styles.errorMessageSuppliers}>
                מלא את כל השדות
              </div>
            )}
          </div>
          <div
            onClick={() => closeMobileAddNewSupplier(true)}
            style={{cursor: "pointer"}}
            className={styles.mobile_add_new_supplier}
          >
            הוספה לצ'קליסט שלי
          </div>
        </div>
      </Dialog>

      <Dialog
        hideBackdrop={true}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "340px",
              boxShadow: "none",
              overflow: "hidden",
              borderRadius: "0",
            },
          },
        }}
        open={openMobileChecklist}
        fullWidth={true}
        className={styles.infoDialog}
      >
        <div className={styles.mainPopup}>
          <div className={styles.close}>
            <img
              style={{cursor: "pointer"}}
              onClick={() => closeMobileFindChecklist(false)}
              src={close_icon}
              alt="closeIcon"
            />
          </div>
          <div className={styles.dialog_checklist_block}>
            <div className={styles.dialog_buttons_block_titles}>
              <div className={styles.dialog_checklist_title}>פרטי האירוע</div>
              {openChecklist && (
                <div className={styles.dialog_checklist_back}>עריכה</div>
              )}
            </div>
            <div className={styles.dialog_checklist_inputs_block}>
              <div className={styles.dialog_checklist_select_label}>
                בחר\י אירוע מתוך רשימת האירועים שלך
              </div>
              <SelectMobile
                placeholder={" "}
                fontFamilyHeader={"Heebo-Regular"}
                borderRadius={"0.3125rem"}
                backgroundColor={"#F7F4F4"}
                height={"2.625rem"}
                options={checklists.data}
                onChangeOption={onChangeOption}
                selectedOption={currentEvent || defaultEventType}
              />
            </div>
            <div>
              <div className={styles.dialog_checklist_select_label}>
                התקציב שלי (מחיר כולל)
              </div>
              <div className={styles.dialog_checklist_shekelContainer}>
                <input
                  value={numberWithCommas(findChecklist.generalBudget)}
                  onChange={(event) => onChangeForm(event, "generalBudget")}
                  className={styles.dialog_checklist_headerPrice}
                  type="text"
                />
                <img
                  src={shekel}
                  alt="shekel"
                  className={styles.dialog_checklist_shekelSymbol}
                />
              </div>
            </div>
            <div>
              <div className={styles.dialog_checklist_select_label}>
                מספר מוזמנים (מקסימום)
              </div>
              <input
                value={numberWithCommas(findChecklist.countOfGuests)}
                onChange={(event) => onChangeForm(event, "countOfGuests")}
                className={styles.dialog_checklist_headerCount}
                type="text"
              />
            </div>
          </div>
          <div className={styles.dialog_checklist_button_block}>
            <button
              onClick={() => closeMobileFindChecklist(true)}
              className={styles.dialog_checklist_button}
            >
              {"שמור"}
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        hideBackdrop={true}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "340px",
              boxShadow: "none",
              overflow: "hidden",
              borderRadius: "0",
            },
          },
        }}
        open={openMobileShowServices}
        fullWidth={true}
        className={styles.infoDialog}
      >
        <div className={styles.mainPopup}>
          <div className={styles.close}>
            <img
              style={{cursor: "pointer"}}
              onClick={() => closeMobileservices()}
              src={close_icon}
              alt="closeIcon"
            />
          </div>
          <div className={styles.dialog_supplier_main_block}>
            <div className={styles.dialog_services_title}>פעולות</div>
            <div className={styles.dialog_supplier_services}>
              <div className={styles.dialog_supplier_service_title}>
                {servicesMobile?.services?.supplierName}
              </div>
              <div className={styles.dialog_supplier_service_price}>
                <div className={styles.dialog_supplier_service_sum}>
                  {getMobilePrice()}
                </div>
                <img
                  src={shekel}
                  alt="shekel"
                  className={styles.dialog_supplier_shekelSymbol}
                />
              </div>
            </div>
            {currentRole && (
              <div
                onClick={() =>
                  !servicesMobile.isDelete
                    ? openchat(
                        servicesMobile?.services?.supplierId,
                        servicesMobile?.services?.categoryId
                      )
                    : ""
                }
                className={styles.dialog_supplier_link}
              >
                פנייה לספק
              </div>
            )}

            <div
              style={{cursor: "pointer"}}
              className={styles.dialog_supplier_service_select_block}
            >
              <div
                onClick={() => showDetails()}
                className={styles.dialog_supplier_service_select}
              >
                צפייה בשירותים שנבחרו
              </div>
              {!showMobileDetails && (
                <img
                  onClick={() => showDetails()}
                  src={arrow}
                  alt="arrow"
                  className={styles.dialog_supplier_select}
                />
              )}
              {showMobileDetails && (
                <img
                  onClick={() => showDetails()}
                  src={arrowUp}
                  alt="arrow"
                  className={styles.dialog_supplier_select}
                />
              )}
            </div>
          </div>
          {showMobileDetails && (
            <div className={styles.dialog_supplier_services_block}>
              {servicesMobile?.services?.services.map((service, index) => {
                return (
                  <div
                    key={index}
                    className={styles.dialog_supplier_services_row}
                  >
                    <div className={styles.dialog_supplier_services_name}>
                      {service.serviceName}
                    </div>
                    <div className={styles.dialog_supplier_services_sum}>
                      <div className={styles.dialog_supplier_service_sum}>
                        {service.price}
                      </div>
                      <img
                        src={shekel}
                        alt="shekel"
                        className={styles.dialog_supplier_services_shekelSymbol}
                      />
                    </div>
                    {showDelete && (
                      <div
                        onClick={() => {
                          deleteMobileService(index, service);
                        }}
                        className={styles.dialog_supplier_services_delete}
                      >
                        הסר
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
          {showDelete && (
            <div
              onClick={() => deleteMobileSupplier()}
              className={styles.dialog_supplier_service_back}
            >
              מחיקת ספק מהצ׳קליסט
            </div>
          )}
        </div>
      </Dialog>
    </>
  );
}

export default CheckList;
