import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from '../../../../store/loaderSlice';
import { fetchAllSuppliersForCard, fetchAllVenuesForCard } from '../../../../store/suppliersSlice';
import useAuth from '../../../../hooks/useAuth';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { savePath } from '../../../../store/returnToUrlSlice';
import { setIsOpenMobleAuth } from '../../../../store/mainSlice';
import { useLocation } from 'react-router';

export const useCardsLogic = () => {
  const dispatch = useDispatch();
  let { pathname } = useLocation();
  const { formGet, isFlashSelected, venuesFilters, suppliersFilters, selectedEventTypeId } =
    useSelector((state) => state.suppliers);
  const { auth } = useAuth();
  const [totalSize, setTotalSize] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [curentlPage, setCurentlPage] = useState(0);
  const axiosPrivate = useAxiosPrivate();
  const { watch } = useFormContext();
  const currentCategoryId = watch('category.id');
  const currentCategoryFiltersId = watch('categoryFiltersId');
  const formAmountGuests = watch('amountGuests');
  const [items, setItems] = useState([]);
  const abortControllerRef = useRef(new AbortController());

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  function getSuppliers(page) {

    abortControllerRef.current.abort();
    abortControllerRef.current = new AbortController();
    const dataForSuppliers = {
      page: page || 0,
      size: 12,
      isFlashSelected: false,
      servicesId: currentCategoryFiltersId,
      eventTypeId: selectedEventTypeId || 0,
      categoryId: currentCategoryId || 0,
      ...suppliersFilters,
    };

    dispatch(
      fetchAllSuppliersForCard(
        auth.user ? { filters: dataForSuppliers, axiosPrivate, signal: abortControllerRef.current.signal  } : { filters: dataForSuppliers, signal: abortControllerRef.current.signal  }
      )
    )
      .then((suppliers) => {
        setTotalSize(suppliers?.payload?.totalElements);
        setTotalPages(suppliers?.payload?.totalPages);
        setCurentlPage(page || 0);
        // if (page) setCurentlPage(page);
        const content = suppliers?.payload?.content;
        const currentItems = page ? [...items, ...content] : content ? content : [];
        setItems(currentItems);
      })
      .catch((error) => {
        if (error.name === 'AbortError') {
          // console.log('Previous request aborted');
        } else {
          // console.error('Error fetching suppliers:', error);
        }})
      .finally(() => {
        dispatch(setLoader(false));
      });
  }
  const getVenues = (page) => {
    abortControllerRef.current.abort();
    abortControllerRef.current = new AbortController();

    let amountOfGuestsTo = localStorage.getItem('guestAmount');
    const isMoreMin = +amountOfGuestsTo > (+formAmountGuests || formGet.guestsRange.min);
    const isLessMax = +amountOfGuestsTo < formGet.guestsRange.max;
    let dataForVenues = {
      page: page || 0,
      size: 12,

      eventTypeId: selectedEventTypeId,
      isFlashSelected: isFlashSelected,

      ...(!!venuesFilters
        ? venuesFilters
        : {
            amountOfGuestsFrom: formGet.guestsRange.min,
            // amountOfGuestsTo:  // no need for now 
            //   !!amountOfGuestsTo && isMoreMin && isLessMax
            //     ? amountOfGuestsTo
            //     : formGet.guestsRange.max,
          }),
      amountOfGuestsTo: null // no need for now 
    };
    dispatch(
      fetchAllVenuesForCard(
        auth.user ? { filters: dataForVenues, axiosPrivate, signal: abortControllerRef.current.signal } : 
        { filters: dataForVenues, signal: abortControllerRef.current.signal }
      )
    )
      .then((venues) => {
        setTotalSize(venues?.payload?.totalElements);
        setTotalPages(venues?.payload?.totalPages);
        // if (page) {
        setCurentlPage(page || 0);
        // }
        setItems(page ? [...items, ...venues?.payload?.content] : venues?.payload?.content);
      })
      .catch((error) => {
        if (error.name === 'AbortError') {
          // console.log('Previous request aborted');
        } else {
          // console.error('Error fetching suppliers:', error);
        }})
      .finally(() => {
        dispatch(setLoader(false));
      });
  };

  const getData = (page) => {
    dispatch(setLoader(true));
    if (!!currentCategoryId && currentCategoryId === 1) getVenues(page);
    else getSuppliers(page);
  };

  useEffect(() => {
    // setCurentlPage(0);
    getData();
  }, [
    currentCategoryId,
    currentCategoryFiltersId.length,
    selectedEventTypeId,
    venuesFilters,
    suppliersFilters,
  ]);

  const refFlag = useRef();

  //getAdditional data
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        if (curentlPage + 1 < totalPages) getData(curentlPage + 1);
      }
    });

    if (refFlag.current) {
      observer.observe(refFlag.current);
    }

    return () => {
      if (refFlag.current) {
        observer.unobserve(refFlag.current);
      }
    };
  }, [curentlPage, totalPages]);

  const addToFavourite = async (event, supplierId, isFavorite) => {
    if (!auth.user) {
      dispatch(savePath(pathname));
      dispatch(setIsOpenMobleAuth(false));
      return;
    }
    event.stopPropagation();
    const currentAction = !isFavorite ? axiosPrivate.post : axiosPrivate.delete;
    try {
      const response = await currentAction(`/user/favorite/supplier?supplierId=${+supplierId}`);
      if (response.status !== 200) {
        throw new Error('Server Error');
      }
      setItems((prev) =>
        prev.map((oneItem) =>
          oneItem.id === supplierId ? { ...oneItem, favorite: !oneItem.favorite } : oneItem
        )
      );
    } catch (error) {
      console.log(error);
    }
  };
  return { items, refFlag, addToFavourite, totalSize, currentCategoryId };
};
