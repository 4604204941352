import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import axios from '../../api/axios';
import { TEMP_USER_KEY } from '../consts/local_keys';

export const sendCode = createAsyncThunk('authCode/sendCode', async (phoneNumber, thunkAPI) => {
  try {
    const { data } = await axios.post(`user/authCode/send`, phoneNumber, {
      headers: { 'Content-Type': 'application/json' },
    });
    return { ...data, phoneNumber };
  } catch (e) {
    return thunkAPI.rejectWithValue(null);
  }
});
export const confirmFormWithCode = createAsyncThunk(
  'authCode/confirmFormWithCode',
  async (payload, thunkAPI) => {
    try {
      const response = await axios.post(
        `user/authCode/join/checklist?userToken=${localStorage.getItem(TEMP_USER_KEY)}`,
        payload
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const confirmCode = createAsyncThunk('authCode/confirmCode', async (payload, thunkAPI) => {
  try {
    const response = await axios.post(
      `user/authCode/login?phoneNumber=${payload.phoneNumber}&userToken=${localStorage.getItem(
        TEMP_USER_KEY
      )}`,
      payload.authCode,
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(null);
  }
});

const initialState = {
  sendCodeLoading: false,
  sendError: false,
  confirmLoading: false,
  confirmError: false,
  isCodeSent: false,
  timeResend: 120,
  phoneNumber: '',
  countCodeResend:2,
};
const authCodeSlice = createSlice({
  name: 'authCode',
  initialState: initialState,
  reducers: {
    setSendCodeLoading: (state, action) => {
      state.sendCodeLoading = action.payload;
    },
    setSendError: (state, action) => {
      state.sendError = action.payload;
    },
    setConfirmLoading: (state, action) => {
      state.confirmLoading = action.payload;
    },
    setConfirmError: (state, action) => {
      state.confirmError = action.payload;
    },
    setIsCodeSent: (state, action) => {
      state.isCodeSent = action.payload;
    },
    setTimeResend: (state, action) => {
      state.timeResend = action.payload;
    },
    setAuthPhoneNumber:(state,action) => {
      state.phoneNumber = action.payload
    },
    setCountCodeResend:(state,action) => {
      state.countCodeResend = action.payload
    },
    resetCountCodeResend:(state) => {
      state.countCodeResend = 2
    }
  },
  extraReducers: {
    [sendCode.pending]: (state, action) => {
      state.sendCodeLoading = true;
    },
    [sendCode.fulfilled]: (state, action) => {
      state.sendCodeLoading = false;
      state.sendError = false;
      state.isCodeSent = action.payload.sent;
      state.timeResend = action.payload.time;
      state.phoneNumber = `+${action.payload.phoneNumber}`;
    },
    [sendCode.rejected]: (state, action) => {
      state.sendCodeLoading = false;
      state.sendError = true;
      state.isCodeSent = false;
    },
    [confirmFormWithCode.pending]: (state, action) => {
      state.confirmLoading = true;
    },
    [confirmFormWithCode.fulfilled]: (state, action) => {
      state.confirmError = false;
      state.confirmLoading = false;
      state.isCodeSent = false;
      state.timeResend = 120;
    },
    [confirmFormWithCode.rejected]: (state, action) => {
      state.confirmError = true;
      state.confirmLoading = false;
    },
    [confirmCode.fulfilled]: (state, action) => {
      state.confirmError = false;
      state.confirmLoading = false;
      state.isCodeSent = false;
      state.timeResend = 120;
    },
    [confirmCode.rejected]: (state, action) => {
      state.confirmError = true;
      state.confirmLoading = false;
    },
  },
});

export const {
  setSendCodeLoading,
  setSendError,
  setConfirmLoading,
  setConfirmError,
  setIsCodeSent,
  setTimeResend,
  setAuthPhoneNumber,
  setCountCodeResend,
  resetCountCodeResend
} = authCodeSlice.actions;

export default authCodeSlice.reducer;

const selectAuthCodeState = (state) => state.authCode;

export const selectSendLoading = createSelector(selectAuthCodeState, (s) => s?.sendCodeLoading);
export const selectSendError = createSelector(selectAuthCodeState, (s) => s?.sendError);
export const selectConfirmLoading = createSelector(selectAuthCodeState, (s) => s?.confirmLoading);
export const selectConfirmError = createSelector(selectAuthCodeState, (s) => s?.confirmError);
export const selectIsCodeSent = createSelector(selectAuthCodeState, (s) => s?.isCodeSent);
export const selectTimeResend = createSelector(selectAuthCodeState, (s) => s?.timeResend);
export const selectAuthPhoneNumber = createSelector(selectAuthCodeState, (s) => s?.phoneNumber);
export const selectCountCodeResend = createSelector(selectAuthCodeState, (s) => s?.countCodeResend);
