import axios from "axios";

export  const  useCustomFavicon = async () =>{
    const result = await axios.get('/main/favicon')
    const {data} = result
    if(data !== undefined && data !== ""){
        sessionStorage.setItem('url', data)
    } else{
    sessionStorage.setItem('url', '')
    }
}