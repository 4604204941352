import { Outlet } from 'react-router-dom'
// import Footer from './components/footer/Footer'
import Header from './components/header/Header'
import HeaderMobile from './components/headerMobile/HeaderMobile'

const AppLayout = () => {
  // const activeClass = ({isActive}) => isActive ? 'current' : ''
    return (
        <>
          <Header />
          <HeaderMobile />
            {/* <header>
                <NavLink to="/" className={activeClass}>Home</NavLink>
                <NavLink to="/supplier" className={activeClass}>Supplier</NavLink>
                <NavLink to="/about" className={activeClass}>About</NavLink>
            </header> */}
            <main>
                <Outlet />
            </main>
          {/* <Footer /> */}
        </>
    )
}

export default AppLayout