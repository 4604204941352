import { useSelector } from "react-redux";
import styles from "./loader.module.scss";
const Loader = () => {
  let { isShowLoader} = useSelector((state) => state.loader)
  return (
  <>
    { isShowLoader &&
      <div className={styles.mainContainer}>
            <div className={styles.ldsDualRing}></div>
      </div>
    }
  </>
    
    
  )
}

export default Loader