import styles from "./NotFound.module.scss";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  return <>
  <div className={styles.title}> 404 - העמוד לא נמצא </div>
  <div className={styles.img} >
    <img style={{objectFit: 'none'}} src='/static/media/logo.8915ebd25d310c92475d.png' alt=""/>
  </div>
  <div className={styles.btn}>
  <button onClick={() => navigate('/')} className={styles.areaButton}>עבור לדף הבית</button>
  </div>
  </>
}

export default NotFound