import { Typography } from '@mui/material';
import styles from '../../../styleBasicForm.module.scss';
import CustomTelInput from '../../../../../../components/customTelInput/CustomTelInput';
import { useEffect } from 'react';
import { useFormState } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentStep } from '../../../../../../store/mainSlice';
import { STEPS_ENUM } from '../stepForm.config';
const PhoneStep = () => {
  const formState = useFormState();
  const dispatch = useDispatch();
  const { currentStep } = useSelector((state) => state.main);
  useEffect(() => {
    const errorExists = 'phoneNumber' in formState.errors;
    const isTouched = 'phoneNumber' in formState.dirtyFields;
    if (isTouched && currentStep === STEPS_ENUM.PHONE && !errorExists) {
      dispatch(setCurrentStep(STEPS_ENUM.SUBMIT));
    }
  }, [formState]);
  return (
    <div className={styles.one_step}>
      <Typography className={styles.one_step_label}>הזן את מספר הטלפון שלך</Typography>

      <div className={styles.field_container} style={{ flex: '1 0 auto' }}>
        <CustomTelInput
          sx={{ height: { xs: '42px', sm: '53px' } }}
          name="phoneNumber"
          disabledFlag
          preferredCountries={['IL']}
          defaultCountry={'IL'}
        />
      </div>
    </div>
  );
};
export default PhoneStep;
