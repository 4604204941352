import React from "react";
import styles from "./BusinessDetails.module.scss";
import classNames from "classnames";
import { v4 as uuidv4 } from "uuid";
import SelectForm from "../../../components/selectFormComponent/SelectForm";
import Select from "../../../components/selectComponent/Select";
import MultiSelect from "../../../components/multiSelectComponent/MultiSelect";
import { useState, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import trash from "../../../../assets/images/trash.png";
import filePdf from "../../../../assets/images/for-pages/icons/pdf.png";
import download from "../img/download_active.svg"
import { getServicesForCategory, setCategoriesWithServices} from "../../../store/supplierRegistrationSlice";
// import { setErr } from "../../../store/forAll/errSlice";
// import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

function BusinessDetails(props) {
  const {changeContract, curentContract} = props;
  const dispatch = useDispatch();
  const {categoriesWithServices, allRegions, services, allCompanyTypes, allCategories} = useSelector((state) => state.supplierRegistration);
  // let [businessType, setBusinessType] = useState(null);
  let [rulesKey, setRulesKey] = useState("12");
  let [emailKey, setEmailKey] = useState("13");
  // const axiosPrivate = useAxiosPrivate();

  // const [categoriesWithServices, setCategoriesWithServices] = useState([
  //   { category: null, services: null, price: null, priceWithTax: null },
  // ]);

  // const [selectedLocations, setSelectedLocations] = useState([]);

  const { control, formState, register, setValue, getValues,  resetField, watch, unregister } = useFormContext({});
  let watcher1 = watch("hasDocument");
  let watcher2 = watch("documentLoaded");
  let watcherContract = watch("contractRequired");

  const { errors } = formState;

  // const catWithServ = watch('catWithServ');

  function removeSpaces(str){
    // console.log(str)
    if(!str && str!=="")return
    let newStr = str.replace(/\s+/g, ' ').trim()
    return newStr
  }

  // const onSubmit = (data) => {
  //   console.log(data);
  //   console.log(categoriesWithServices);
  // };

  // const onChangeBusinessType = (option) => {
  //   setBusinessType(option);
  // };

  const onChangeCategory = (option, id) => {
    let ind;
    dispatch(getServicesForCategory(option.id))
    let actualState = [...categoriesWithServices];
    let newLine = {...actualState.find((elem, index) => {
      ind = index;
      return elem.id === id;
    })}
    
    newLine.category = option;
    newLine.services = {id:0, name: ""};
    resetField(`catWithServ.${id}.serv`)
    actualState[ind] = newLine;

    dispatch(setCategoriesWithServices(actualState));
  };

  function getId(arr) {
    //last elem id + 1
    return arr[arr.length-1].id + 1;
  }
  

  const onChangeServices = (option, id) => {
    let ind;
    let actualState = [...categoriesWithServices];
    let newLine = {...actualState.find((elem, index) => {
      ind = index;
      return elem.id === id;
    })}
    newLine.services = option;
    actualState[ind] = newLine;

    dispatch(setCategoriesWithServices(actualState));
  };

  const addFields = () => {
    let actualState = [...categoriesWithServices];
    actualState.push({id: getId(actualState), category: {}, services: [], price: null, priceWithTax: null, error: null });
    dispatch(setCategoriesWithServices(actualState));
  };

  const deleteFields = (index) => {
    let actualState = [...categoriesWithServices];
    actualState.splice(index, 1);
    dispatch(setCategoriesWithServices(actualState));
  };

  const countWords = (str) => {
    if(!str) return 300;
    return (300 - str?.split(' ').filter((num) => {
      return num !== ''
    }).length)
  }

  // const calcBetween1_100 = (number) => {
  //   if(typeof number !=="number") return number
  //   console.log(errors)
  //   if(number > 100)return 100;
  //   if(number < 0)return 0;
  //   return number
  // }

  const uploadContract = (contract, name) => {
    changeContract(contract)
    setValue("documentLoaded", true)
    setValue("documentName", name)

  }

  const removeContractFile = () => {
    changeContract({})
    setValue("documentLoaded", false)
  }
  
  useEffect(()=>{
    console.log('categoriesWithServices', categoriesWithServices);
  },[categoriesWithServices])
  //   console.log(e);
  //   // setSelectedLocations(e);
  // };

  /*   

  const onChangeServices = (option) => {
    setSubCategory(option);
  }; */

  // useEffect(() => {
  //   console.log(services)
  // },[dispatch])
  const getPDF = async() => {
    if(curentContract.id) {
      const url = window.URL.createObjectURL(curentContract.file)
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.pdf");
      document.body.appendChild(link);
      link.click();
    }
  }
  return (
    <div className={styles.mainContainer}>

      <form noValidate className={styles.dataInputsForm}>
      <div className={styles.dataInputs}>
        <div 
          className={classNames(
            styles.inputContainer,
            styles.width33,
          )}>
        <Controller
            name="businessName"
            control={control}
            render={({ field: { onChange, value } }) => (
              <input
                // {...field}
                className={classNames({
                  [styles.invalidField]: errors.businessName,
                })}
                error={!!errors.businessName}
                required
                placeholder="שם העסק"
                value={value? value : ''}
                  onBlur={(event, newValue) => {
                    onChange(removeSpaces(event.target.value,newValue));
                  }}
                  onChange={(event, newValue) => {
                    onChange(event.target.value);
                  }}
                id="businessName"
              />
            )}
          />
          { errors.businessName && 
              <p className={styles.errorMessage}>
                {errors.businessName.message}
              </p>
            }
        </div>

        <div
          className={classNames(
            styles.inputContainer,
            styles.selectInputContainer,
            styles.width33,
            styles.desctop,
          )}
          // style={{ width: "33%" }}
        >

      <Controller
        name="geoLocationsId"
        control={control}
        render={({ field: { onChange, value } }) => (

          <MultiSelect
            // invalid={errors.geoLocationsId}
            options={
              allRegions
            //   [
            //   { id: 1, name: "first" },
            //   { id: 2, name: "second" },
            //   { id: 3, name: "third" },
            //   { id: 4, name: "fourth" },
            // ]
          }
            onChangeOption={(e) => {
              // onChangeLocation(e); 
              onChange(e)}}
            // selectedOptions={selectedLocations}
            selectedOptions={value}
            fontFamily={"Heebo-Medium"}
            placeholder={"איזור עבודה (בחירה מרובה)"}
            borderRadius={"0.31rem"}
            paddingInlineStart={"1.75rem"}
            paddingInlineEnd={"1.33rem"}
            backgroundColor={"#EEE9E5"}
            color={"#242323"}
            fontSize={"1.3rem"}
          />
        )}
      />
      {/* { errors.geoLocationsId && 
              <p className={styles.errorMessage}>
                {errors.geoLocationsId.message}
              </p>
            } */}

          
        </div>
        <div
          className={classNames(
            styles.inputContainer,
            styles.selectInputContainer,
            styles.mobile,
          )}
          // style={{ width: "33%" }}
        >

      <Controller
        name="geoLocationsId"
        control={control}
        render={({ field: { onChange, value } }) => (

          <MultiSelect
            // invalid={errors.geoLocationsId}
            options={
              allRegions
            //   [
            //   { id: 1, name: "first" },
            //   { id: 2, name: "second" },
            //   { id: 3, name: "third" },
            //   { id: 4, name: "fourth" },
            // ]
          }
            onChangeOption={(e) => {
              // onChangeLocation(e); 
              onChange(e)}}
            // selectedOptions={selectedLocations}
            selectedOptions={value}
            fontFamily={"Heebo-Medium"}
            placeholder={"איזור עבודה (בחירה מרובה)"}
            borderRadius={"0.31rem"}
            paddingInlineStart={"1.75rem"}
            paddingInlineEnd={"1.33rem"}
            backgroundColor={"white"}
            color={"#242323"}
            fontSize={"1.3rem"}
          />
        )}
      />
      {/* { errors.geoLocationsId && 
              <p className={styles.errorMessage}>
                {errors.geoLocationsId.message}
              </p>
            } */}

          
        </div>

        <div 
          className={classNames(
            styles.inputContainer,
            styles.width33,
          )}>

        <Controller
            name="labelCode"
            control={control}
            render={({ field: { onChange, value } }) => (
              <>

              <input
                // {...field}
                className={classNames({
                  [styles.invalidField]: errors.labelCode,
                }, styles.desctop)}
                error={!!errors.labelCode}
                placeholder="הכנס קוד לייבל (*במידה ואתה חלק מלייבל)"
            
                value={value? value : ''}
                  onBlur={(event, newValue) => {
                    onChange(removeSpaces(event.target.value,newValue));
                  }}
                  onChange={(event, newValue) => {
                    onChange(event.target.value);
                  }}
                id="labelCode"
                
              />
              <input
                // {...field}
                className={classNames({
                  [styles.invalidField]: errors.labelCode,
                }, styles.mobile)}
                error={!!errors.labelCode}
                placeholder="הכנס קוד לייבל"
            
                value={value? value : ''}
                  onBlur={(event, newValue) => {
                    onChange(removeSpaces(event.target.value,newValue));
                  }}
                  onChange={(event, newValue) => {
                    onChange(event.target.value);
                  }}
                id="labelCode"
                
              />
              </>
              
            )}
          />
          { errors.labelCode && 
              <p className={styles.errorMessage}>
                {errors.labelCode.message}
              </p>
            }
          
        </div>
      </div>
      <div className={classNames(styles.dataInputs, styles.secondBlock)}>
        <div
          className={classNames(
            styles.inputContainer,
            styles.selectInputContainer,
            styles.desctop,
          )}
        >

      <Controller
        className={classNames({
          [styles.invalidField]: errors.companyTypeId,
        })}
        name="companyTypeId"
        control={control}
        render={({ field: { onChange, value } }) => (
          <SelectForm
            // invalid={errors.companyTypeId}
            control={control}
            name="businessType"
            rules={{ required: true }}
            fontSize={"1.3rem"}
            paddingInlineStart={"1.75rem"}
            paddingInlineEnd={"1.33rem"}
            placeholder={"סוג העסק"}
            width={"100%"}
            borderRadius={"0.31rem"}
            backgroundColor={"#EEE9E5"}
            color={"#242323"}
            options={
              allCompanyTypes
            //   [
            //   { id: 1, name: "first" },
            //   { id: 2, name: "second" },
            //   { id: 3, name: "third" },
            //   { id: 4, name: "fourth" },
            // ]
          }
            onChangeOption={(e) => {
              // onChangeBusinessType(e); 
              onChange(e.id)}}
            selectedOption={allCompanyTypes.find(item => item.id === value)}
          />
        )}
          
      />
      {/* { errors.companyTypeId && 
              <p className={styles.errorMessage}>
                {errors.companyTypeId.message}
              </p>
            } */}
        </div>
        <div
          className={classNames(
            styles.inputContainer,
            styles.selectInputContainer,
            styles.mobile,
          )}
        >

      <Controller
        className={classNames({
          [styles.invalidField]: errors.companyTypeId,
        })}
        name="companyTypeId"
        control={control}
        render={({ field: { onChange, value } }) => (
          <SelectForm
            // invalid={errors.companyTypeId}
            control={control}
            name="businessType"
            rules={{ required: true }}
            fontSize={"1.3rem"}
            paddingInlineStart={"1.75rem"}
            paddingInlineEnd={"1.33rem"}
            placeholder={"סוג העסק"}
            width={"100%"}
            borderRadius={"0.31rem"}
            backgroundColor={"white"}
            color={"#242323"}
            options={
              allCompanyTypes
            //   [
            //   { id: 1, name: "first" },
            //   { id: 2, name: "second" },
            //   { id: 3, name: "third" },
            //   { id: 4, name: "fourth" },
            // ]
          }
            onChangeOption={(e) => {
              // onChangeBusinessType(e); 
              onChange(e.id)}}
            selectedOption={allCompanyTypes.find(item => item.id === value)}
          />
        )}
          
      />
      {/* { errors.companyTypeId && 
              <p className={styles.errorMessage}>
                {errors.companyTypeId.message}
              </p>
            } */}
        </div>
        <div className={styles.inputContainer}>


          <Controller
            name="companyRegistrationNumber"
            control={control}
            render={({ field: { onChange, value } }) => (
              <input
                // {...field}
                type={"number"}
                pattern="\d*"
                className={classNames({
                  [styles.invalidField]: errors.companyRegistrationNumber,
                })}
                placeholder="ח.פ \ עוסק מורשה"
                error={!!errors.companyRegistrationNumber}
                // required
                value={value? value : ''}
                  onBlur={(event, newValue) => {
                    onChange(removeSpaces(event.target.value,newValue));
                  }}
                  onChange={(event, newValue) => {
                    onChange(event.target.value);
                  }}
                id="companyRegistrationNumber"
              />
            )}
          />
          { errors.companyRegistrationNumber && 
              <p className={styles.errorMessage}>
                {errors.companyRegistrationNumber.message}
              </p>
            }
          
        </div>
        <div className={styles.inputContainer}>


          <Controller
            name="businessAddress"
            control={control}
            render={({ field: { onChange, value } }) => (
              <>
              <input
                // {...field}
                className={classNames({
                  [styles.invalidField]: errors.businessAddress,
                }, styles.desctop)}
                error={!!errors.businessAddress}
                // required
                placeholder="כתובת בית העסק (רחוב, מספר, עיר)" // adress by law
                value={value? value : ''}
                  onBlur={(event, newValue) => {
                    onChange(removeSpaces(event.target.value,newValue));
                  }}
                  onChange={(event, newValue) => {
                    onChange(event.target.value);
                  }}
                id="businessAddress"
              />
              <input
                // {...field}
                className={classNames({
                  [styles.invalidField]: errors.businessAddress,
                }, styles.mobile)}
                error={!!errors.businessAddress}
                // required
                placeholder="כתובת בית העסק" // adress by law
                value={value? value : ''}
                  onBlur={(event, newValue) => {
                    onChange(removeSpaces(event.target.value,newValue));
                  }}
                  onChange={(event, newValue) => {
                    onChange(event.target.value);
                  }}
                id="businessAddress"
              />
              </>
              
            )}
          />
        { errors.businessAddress && 
              <p className={styles.errorMessage}>
                {errors.businessAddress.message}
              </p>
            }
          
        </div>
      </div>
      {/* <div style={{position: "relative"}}>
                    <Controller
                      name="prePayment"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                       
                        
                        <div
                        style={{backgroundColor: !!errors.prePayment ? "#f3e8e8": ""}}
                        
                        className={classNames(styles.inputFlash1,{
                          [styles.invalidField]:
                            errors.prePayment,
                        })}>
                          <input
                          style={{backgroundColor: !!errors.prePayment ? "#f3e8e8": ""}}
                        // pattern={"^[0-9][0-9]?$|^100$"}
                          // {...field}
                          type={"number"}
                          // className={classNames({
                          //   [styles.invalidField]:
                          //     errors.prePayment,
                          // })}
                          

                          placeholder="אחוז מקדמה"
                          error={!!errors.prePayment}
                          // required
                          value={value ? value : ""}
                          
                          onChange={(event, newValue) => {
                            onChange(calcBetween1_100(+event.target.value));
                          }}

                          onBlur={(event, newValue) => {
                            onChange(
                              removeSpaces(event.target.value, newValue)
                            );
                          }}
                          id="prePayment"
                        />
                          {"%"}
                        </div>
                       
                        
                      )}
                    />
                    {errors.prePayment && (
                      <p className={styles.errorMessage}>
                        {errors.prePayment.message}
                      </p>
                    )}
                  </div> */}

                
      <p className={styles.businessDescription}>
        תיאור העסק  
      </p>
      {/* description of business activity */}
      <div
        className={classNames(styles.textAreaContainer,
           styles.inputContainer,
          )}
      >

        <Controller
          name="businessDescription"
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <textarea
                // {...field}
                className={classNames({
                  [styles.invalidField]: errors.businessDescription,
                })}
                error={!!errors.businessDescription}
                required
                placeholder="(עד 300 מילים)"
                value={value}
                type="text"
                id="businessDescription"
                variant="outlined"
                onChange={(e) => {
                  if(countWords(e.target.value) <= 0) return;
                  return onChange(e.target.value)
                }}
              />
              { !errors.businessDescription && 
              <p style={{bottom: "0.45rem", color: 'black'}} className={styles.errorMessage}>
                {countWords(value)}
              </p>
              }
            </>
          )}
        />
        
        { errors.businessDescription && 
              <p style={{bottom: "0.45rem"}} className={styles.errorMessage}>
                {errors.businessDescription.message}
              </p>
            }
      </div>
      <p className={styles.businessDescription}>מה התחום שלי</p>
      {categoriesWithServices.map((element, index) => (
        <div className={styles.gap} key={uuidv4()}>
          <div
            className={classNames(styles.dataInputs,
               styles.addedDataInputs,
               styles.desctop,
               )}
          >
            <div
              className={classNames(
                styles.inputContainer,
                styles.selectInputContainer,
                styles.width50,
              )}
              // style={{ width: "50%" }}
            >

      <Controller
        name={`catWithServ.${element.id}.cat`}
        control={control}

        render={({ field: { onChange, value } }) => (
          
          <Select
                datalist={true}
                fontSize={"1.3rem"}
                paddingInlineStart={"1.75rem"}
                paddingInlineEnd={"1.33rem"}
                placeholder={"בחר תחום מתוך הרשימה"}
                width={"100%"}
                borderRadius={"0.31rem"}
                backgroundColor={"#EEE9E5"}
                color={"#242323"}
                options={
                  allCategories
                //   [
                //   { id: 1, name: "first" },
                //   { id: 2, name: "second" },
                //   { id: 3, name: "third" },
                //   { id: 4, name: "fourth" },
                // ]
              }
                onChangeOption={(option) => {
                  onChange(option?.name);
                  // console.log(errors)
                  onChangeCategory(option, element.id);
                }}
                selectedOption={categoriesWithServices.find(elem => elem.id === element.id).category}
              />
        )}
      />
            { errors.catWithServ?.[element.id]?.cat && 
              <p className={styles.errorMessage}>
                {errors.catWithServ?.[element.id]?.cat.message}
              </p>
            }
              
            </div>

            <div
              className={classNames(
                styles.inputContainer,
                styles.selectInputContainer,
                styles.width50,
              )}
              // style={{ width: "50%" }}
            >
              <Controller
                name={`catWithServ.${element.id}.serv`}
                control={control}
                render={({ field: { onChange, value } }) => (

                  <Select
                    datalist={true}
                    fontSize={"1.3rem"}
                    paddingInlineStart={"1.75rem"}
                    paddingInlineEnd={"1.33rem"}
                    placeholder={"בחר תת תחום"}
                    width={"100%"}
                    borderRadius={"0.31rem"}
                    backgroundColor={"#EEE9E5"}
                    color={"#242323"}
                    options={
                      services[categoriesWithServices.find(elem => elem.id === element.id)?.category?.id]?.filter((serv) => {
                        let newCWS = categoriesWithServices?.filter((item) => {
                          return (item?.category?.id === categoriesWithServices.find(elem => elem.id === element.id)?.category?.id)
                        });

                        let isFind = newCWS?.find((item) => {
                          if(item?.services){
                            // return (item?.services?.find((servi) => {
                            //   return servi.id === serv.id
                            // }))
                            return item?.services.id === serv.id
                          }else{
                            return false
                          }
                        })
                        return !isFind;
                      })
                    //   [
                    //   { id: 1, name: "first" },
                    //   { id: 2, name: "second" },
                    //   { id: 3, name: "third" },
                    //   { id: 4, name: "fourth" },
                    // ]
                  }
                    onChangeOption={(option) => {onChangeServices(option, element.id);
                      onChange(option?.name)
                    }}
                    selectedOption={
                      categoriesWithServices.find(elem => elem.id === element.id).services
                      // categoriesWithServices[element.id].services
                    }
                  />
                )}
              />
              { errors.catWithServ?.[element.id]?.serv && 
              <p className={styles.errorMessage}>
                {errors.catWithServ?.[element.id]?.serv.message}
              </p>
            }
              
            </div>
          </div>
          <div
            className={classNames(styles.dataInputs,
               styles.addedDataInputs,
               styles.mobile,
               )}
          >
            <div
              className={classNames(
                styles.inputContainer,
                styles.selectInputContainer,
                styles.width50,
              )}
              // style={{ width: "50%" }}
            >
              <Controller
                name={`catWithServ.${element.id}.cat`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    fontSize={"1.3rem"}
                    paddingInlineStart={"1.75rem"}
                    paddingInlineEnd={"1.33rem"}
                    placeholder={"בחר תחום מתוך הרשימה"}
                    width={"100%"}
                    borderRadius={"0.31rem"}
                    backgroundColor={"white"}
                    color={"#242323"}
                    options={
                      allCategories
                    //   [
                    //   { id: 1, name: "first" },
                    //   { id: 2, name: "second" },
                    //   { id: 3, name: "third" },
                    //   { id: 4, name: "fourth" },
                    // ]
                  }
                    onChangeOption={(option) => {onChange(option?.name); onChangeCategory(option, element.id)}}
                    selectedOption={categoriesWithServices.find(elem => elem.id === element.id).category}
                  />
                )}
              />

            { errors.catWithServ?.[element.id]?.cat && 
              <p className={styles.errorMessage}>
                {errors.catWithServ?.[element.id]?.cat.message}
              </p>
            }
            </div>

            <div
              className={classNames(
                styles.inputContainer,
                styles.selectInputContainer,
                styles.width50,
              )}
              // style={{ width: "50%" }}
            >
              <Controller
                name={`catWithServ.${element.id}.serv`}
                control={control}
                render={({ field: { onChange, value } }) => (

                  <Select
                    fontSize={"1.3rem"}
                    paddingInlineStart={"1.75rem"}
                    paddingInlineEnd={"1.33rem"}
                    placeholder={"בחר תת תחום (*אופציונאלי)"}
                    width={"100%"}
                    borderRadius={"0.31rem"}
                    backgroundColor={"white"}
                    color={"#242323"}
                    options={
                      services[categoriesWithServices.find(elem => elem.id === element.id)?.category?.id]?.filter((serv) => {
                        let newCWS = categoriesWithServices?.filter((item) => {
                          return (item?.category?.id === categoriesWithServices.find(elem => elem.id === element.id)?.category?.id)
                        });

                        let isFind = newCWS?.find((item) => {
                          if(item?.services){
                            // return (item?.services?.find((servi) => {
                            //   return servi.id === serv.id
                            // }))
                            return item?.services.id === serv.id
                          }else{
                            return false
                          }

                          


                        })
                        return !isFind;
                      })
                      // services[categoriesWithServices[index]?.category?.id]
                    //   [
                    //   { id: 1, name: "first" },
                    //   { id: 2, name: "second" },
                    //   { id: 3, name: "third" },
                    //   { id: 4, name: "fourth" },
                    // ]
                  }
                    onChangeOption={(option) => {onChangeServices(option, element.id); 
                      onChange(option.name); }}
                    selectedOption={categoriesWithServices.find(elem => elem.id === element.id).services}
                  />
                )}
              />
            { errors.catWithServ?.[element.id]?.serv && 
              <p className={styles.errorMessage}>
                {errors.catWithServ?.[element.id]?.serv.message}
              </p>
            }
            </div>
          </div>
          {(index !== 0 || categoriesWithServices.length !== 1) && (
            <div
              className={styles.lessFields}
              onClick={() => {
                deleteFields(index);
                resetField(`catWithServ.${element.id}.cat`)
                unregister(`catWithServ.${element.id}.cat`);
                resetField(`catWithServ.${element.id}.serv`)
                unregister(`catWithServ.${element.id}.serv`);
                
              }}
            >
              - למחוק תחום
            </div>
          )}
        </div>
      ))}
      <p className={styles.moreFields} onClick={() => addFields()}>
        + הוסף תחום
      </p>

      <div className={styles.line} />

      <p className={styles.questionAboutContacts}>
        האם במסגרת עבודתך את.ה מתנהל.ת עם לקוחותיך בעזרת חוזים?
      </p>
      <div className={styles.doubleRadio}>
        <div key={rulesKey} className={classNames(styles.radio, styles.radio1)}>
        { errors.contractRequired && 
                <p className={styles.errorMessage}>
                  שדה חובה
                </p>
        }
          <input
            onClick={() => setRulesKey(Math.random())}
            {...register('contractRequired')}
            id="radioBusinessDetail-1"
            type="radio"
            name="contractRequired"
            value={true}
          />
          <label htmlFor="radioBusinessDetail-1">
            כן, כל עסקה עם לקוח מתבצעת בכפוף לחתימת חוזה
          </label>
          
        </div>
        {(watcherContract === "true") && <div className={styles.radioOwnContract}>
          <div
            className={classNames(styles.radio)}
            
          >
            <input
              // onClick={() => setRulesKey(Math.random())}
              {...register("hasDocument")}
              id="hasDocument-1"
              type="radio"
              // name="contractRequired"
              checked={!!watcher1}
              value={1}
              onClick={(e,value) => {
                if(watcher1){
                  setValue("hasDocument", '')
                } else{
                  setValue("hasDocument", '1')
                }
                
              }}
            />
            <label htmlFor="hasDocument-1">
              {"יש לי חוזה"}
            </label>
          </div>
          {(!!watcher1 && !watcher2) &&
                <Box className={styles.loadContract}
                  component='label'
                >
                
                  {"טען חוזה"}
                  <input
                      className={styles.uploadImg}
                      id="button-file"
                      name="logoFile"
                      type="file"
                      accept="application/pdf"
                      onChange={(e) => {
                        function readFileAsync(index = 0) {
                          return new Promise((resolve, reject) => {
                            const file = e.target.files[index];
                            if (!file) {
                              return;
                            }
                            const reader = new FileReader();
      
                            reader.onload = () => {
                              if(file?.size < 15728640){
                                
                                resolve({
                                id: file?.size || 911,
                                name: file?.name,
                                url: `data:${file.type};base64,${btoa(
                                  reader.result
                                )}`,
                                file: e.target.files[index],

                              });
                              
                              }else{
                              }
                            };
      
                            reader.onerror = reject;
      
                            reader.readAsBinaryString(file);
                          });
                        }

                        readFileAsync().then(item => {
                          // console.log(item)
                          uploadContract(item, item?.file?.name || "")
                          return item
                        });
                        // addImage(await newImg, indexCell)
                        
                      }}
                      hidden
                  />
                </Box>
            }

          {(!!watcher1 && watcher2) && 
            <div className={styles.showDeleteContract}>
              <div className={styles.contractName}>
                {getValues("documentName") || ""}
              </div>
              <img src={download} alt="download"  style={{cursor: "pointer"}}  onClick={() => getPDF()}/>
              <img src={filePdf} alt="filePdf" />
              <img 
                style={{cursor: "pointer"}}
                onClick={() => removeContractFile()} src={trash} alt="trash" 
              />
            </div>
            }
        </div>}
      </div>
      
      <div key={emailKey} className={classNames(styles.radio, styles.radio2)}>
        <input
          onClick={() => {setEmailKey(Math.random()); setValue("hasDocument", '')}}
          {...register('contractRequired')}
          id="radioBusinessDetail-2"
          type="radio"
          name="contractRequired"
          value={false}
        />
        <label htmlFor="radioBusinessDetail-2">
          לא, איני מתנהל עם חוזים מול הלקוחות שלי
        </label>
      </div>
    </form>
    </div>
    
  );
}

export default BusinessDetails;
