// import "@fontsource/anek-telugu";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import dayjs from "dayjs";
import calendar from "./../../../../assets/images/for-pages/icons/calendar-blank.png";
// import calendarBlack from "./../../../../assets/images/for-pages/calendar-icon.png";
import arrowLeft from "./../../../../assets/images/for-pages/icons/arrow-left.png";
import arrowRight from "./../../../../assets/images/for-pages/icons/arrow-right.png";
import menu from "./../../../../assets/images/for-pages/icons/menu.png";
import add from "./img/add.png";
import plus from "./img/plus.png";
import screpka from "./img/screpka.png";
import location from "./img/location.png";
import { useParams } from "react-router-dom";
// import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import styles from "./customCalendar.module.scss";
import * as Styles from "./styles";
import InputWithArrows from "./inputWithArrows/inputWithArrows";
import he from "dayjs/locale/he"; // load on demand
import localizedFormat from "dayjs/plugin/localizedFormat";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { useDispatch, useSelector } from "react-redux";
// import { setAllInterval } from "../../../store/calendarSlice";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { setErr } from "../../../store/forAll/errSlice";

const Calendar = (props) => {
  const routeParams = useParams();
  const dispatch = useDispatch();
  let supplierId = routeParams.supplierId;
  let userId = routeParams.userId;
  // let eventTypeId = routeParams.eventTypeId;
  // let categoryId = routeParams.categoryId;
  // dayjs.extend(updateLocale)

  // dayjs.updateLocale('en', {
  //   weekdaysMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]
  // })

  // dayjs.extend(updateLocale)

  // dayjs.updateLocale('en', {
  //   weekdaysShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
  // })
  dayjs.locale(he);
  dayjs.extend(localizedFormat);
  dayjs.extend(isSameOrBefore);
  // const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const { width, step, changeStep, eventTypeId} = props;
  const rootEl = useRef(null);
  const [selectedCube, setSelectedCube] = useState(0);
  const [title, setTitle] = useState("");
  const [curentDate, setCurentDate] = useState(dayjs());
  const today = dayjs();
  const [isShowError, setIsShowError] = useState(false);
  const [link, setLink] = useState("");

  // const [selectedDates, setSelectedDates] = useState([]);
  // const [allInterval, setAllIntervals] = useState([]);
  const [currentEventStartDate, setCurrentEventStartDate] = useState(dayjs().minute(0));
  const [currentEventEndDate, setCurrentEventEndDate] = useState(dayjs().minute(0).add(1, "h"));
  // const [selectedDates, setSelectedDates] = useState([]);
  let [isShowMenu, setIsShowMenu] = useState(false);
  // const [displayDate, setDisplayDate] = useState([])
  const {onChange} = props;
  const currentDay = useMemo(() => dayjs().toDate(), []);

  // const setAllIntervals = (intervals) => {
  //   dispatch(setAllInterval(intervals));
  // };

  const { allInterval, email } = useSelector((state) => state.calendar);
  const { currentEvent:currentChecklist } = useSelector((state) => state.checklist);
  // const {allInterval} = useSelector(state => state.calendar)

  // const login = useGoogleLogin({
  //   onSuccess: (codeResponse) => {
  //     console.log(codeResponse);
  //     if (
  //       codeResponse.scope.includes(
  //         "https://www.googleapis.com/auth/calendar"
  //       ) &&
  //       codeResponse.scope.includes(
  //         "https://www.googleapis.com/auth/userinfo.email"
  //       )
  //     ) {
  //       dispatch(sendGoogleCode({ code: codeResponse.code, axiosPrivate }));
  //     }
  //   },

  //   scope:
  //     "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.email",
  //   flow: "auth-code",
  //   // prompt: "consent",
  // });

  const checkIsIncrement = (e) => {
    if (e) {
      return "add";
    } else {
      return "subtract";
    }
  };


  const cahngeCurrentStartTime = (e) => {
    let f = checkIsIncrement(e);
    let newStartDate = dayjs(currentEventStartDate)[f](1, "h");
    if (dayjs(newStartDate).isBefore(currentEventEndDate, "h")) {
      setCurrentEventStartDate(newStartDate);
    }else{
      setCurrentEventStartDate(newStartDate);
      setCurrentEventEndDate(dayjs(newStartDate).add(1, "h"));
    }
  };

  const cahngeCurrentStartDate = (e) => {
    let f = checkIsIncrement(e);
    let newStartDate = dayjs(currentEventStartDate)[f](1, "day");

    if ((!dayjs(newStartDate).isAfter(currentEventEndDate, "day")) && (!dayjs(newStartDate).isAfter(currentEventEndDate, "h"))) {
      setCurrentEventStartDate(newStartDate);
    }
  };
  const cahngeCurrentEndDate = (e) => {
    let f = checkIsIncrement(e);
    let newEndDate = dayjs(currentEventEndDate)[f](1, "day");

    if (((!dayjs(newEndDate).isBefore(currentEventStartDate, "day"))&&(!dayjs(newEndDate).isBefore(currentEventStartDate, "h")))) {
      setCurrentEventEndDate(newEndDate);
    }
  };


  const cahngeCurrentEndTime = (e) => {
    let f = checkIsIncrement(e);
    let newEndDate = dayjs(currentEventEndDate)[f](1, "h"); 
    if ((!dayjs(newEndDate).isBefore(currentEventStartDate, "h")) && dayjs(currentEventStartDate).isSameOrBefore(newEndDate, "day")) {
      setCurrentEventEndDate(newEndDate);
    }
  };

  const setClickedDay = (day) => {
    if(dayjs(day).isBefore(today, "date")) return
    setCurrentEventStartDate(dayjs(day).add(8, "h"));
    setCurrentEventEndDate(dayjs(day).add(9, "h"));
  };

  const submitMessage = async (data) => {
  let categoryId = routeParams.categoryId;
  try{
    const result = await axiosPrivate.post(`${process.env.REACT_APP_API_PATH}chats/google/event/meeting?userId=${userId}&supId=${supplierId}&categoryId=${categoryId}&eventTypeId=${eventTypeId}&checkListId=${currentChecklist?.checkListId || 0}`,
      data
    );
    if(result){
      onChange(result.data);
    }
  } catch(e){
    dispatch(
      setErr({
        message: e.response.data.message,
        code: e.response.data.code,
    }) )
  }
  };

  // const checkInterval = (interval) => {
  //   if (interval.type === 0) {
  //     return false;
  //   }
  //   let oneTypeArr = allInterval?.filter((item) => {
  //     return(
  //       (item.type === interval.type)&&
  //       (item.id !== interval.id)
  //     )
  //   });
  //   if (!oneTypeArr?.length) {
  //     return true;
  //   } else {
  //     let isConflict = oneTypeArr.find((item) => {
  //       console.log(
  //         dayjs(item.startDate).isBefore(interval.startDate, "date") &&
  //           dayjs(item.endDate).isBefore(interval.startDate, "date"),
  //         dayjs(interval.startDate).isBefore(item.startDate, "date") &&
  //           dayjs(interval.endDate).isBefore(item.startDate, "date")
  //       );

  //       if (
  //         !(
  //           (dayjs(item.startDate).isBefore(interval.startDate, "date") &&
  //             dayjs(item.endDate).isBefore(interval.startDate, "date")) ||
  //           (dayjs(interval.startDate).isBefore(item.startDate, "date") &&
  //             dayjs(interval.endDate).isBefore(item.startDate, "date"))
  //         )
  //       ) {
  //         return true;
  //       } else {
  //         return false;
  //       }
  //     });

  //     if (isConflict) {
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   }
  // };

  const setInterval = (interval) => {
    setTitle(interval.name);
    setCurrentEventEndDate(interval.endDate);
    setCurrentEventStartDate(interval.startDate);
    setSelectedCube(interval.type);
  };

  const clearInterval = () => {
    setTitle("");
    setCurrentEventEndDate("");
    setCurrentEventStartDate("");
    setSelectedCube(0);
  };

  const createInterval = () => {
    if(dayjs(currentEventStartDate).isSameOrBefore(today, "day") &&
    dayjs(currentEventStartDate).isSameOrBefore(today, "h")){
      dispatch(
        setErr({
          message: "לא ניתן לבחור תאריך עבר",
        })
      );
      return true
    }
    let newInterval = {
      name: title,
      location: "",
      link: link || "",
      startDate: dayjs(currentEventStartDate).toJSON(),
      endDate: dayjs(currentEventEndDate).toJSON(),
    };
    submitMessage(newInterval);
  };

  const checkCell = (day, type) => {
    let intervals = allInterval?.filter((interval) => {
      return interval.type === type;
    });
    if (!intervals?.length) return false;

    return intervals.find((item) => {
      return (
        dayjs(item.startDate).isSameOrBefore(day, "date") &&
        dayjs(day).isSameOrBefore(item.endDate, "date")
      );
    });
  };

  // const setClickedDay1 = (day) => {
  //   let isBefore = selectedDates.length
  //     ? dayjs(day).isBefore(selectedDates[0], "date")
  //     : false;
  //   console.log(isBefore, selectedDates);
  //   let newDates =
  //     selectedDates.length && selectedDates.length !== 2
  //       ? [...selectedDates]
  //       : [];
  //   if (isBefore) {
  //     newDates.unshift(day);
  //   } else {
  //     newDates.push(day);
  //   }
  //   setSelectedDates(newDates);
  // };

  // const getDates = () => {
  //   let newArr = selectedDates.length
  //     ? selectedDates.map((day) => {
  //         return dayjs(day).format("L");
  //       })
  //     : [];

  //   if (newArr.length === 1) {
  //     newArr.push(newArr[0]);
  //   }
  //   setDisplayDate(newArr);
  //   console.log(newArr);
  // };

  const firstDayOfTheMonth = useMemo(
    () => curentDate.clone().startOf("month"),
    [curentDate]
  );

  const firstDayOfFirstWeekOfMonth = useMemo(
    () => dayjs(firstDayOfTheMonth).startOf("week"),
    [firstDayOfTheMonth]
  );

  const generateFirstDayOfEachWeek = useCallback((day) => {
    const dates = [day];
    for (let i = 1; i < 6; i++) {
      const date = day.clone().add(i, "week");
      dates.push(date);
    }
    return dates;
  }, []);

  const generateWeek = useCallback((day) => {
    const dates = [];
    for (let i = 0; i < 7; i++) {
      const date = day.clone().add(i, "day").toDate();
      dates.push(date);
    }
    return dates;
  }, []);

  const generateWeeksOfTheMonth = useMemo(() => {
    const firstDayOfEachWeek = generateFirstDayOfEachWeek(
      firstDayOfFirstWeekOfMonth
    );
    return firstDayOfEachWeek.map((date) => generateWeek(date));
  }, [generateFirstDayOfEachWeek, firstDayOfFirstWeekOfMonth, generateWeek]);

  useEffect(() => {
    const onClick1 = (e) =>
      rootEl.current?.contains(e.target) || setIsShowMenu(false);
    document.addEventListener("click", onClick1);
    return () => document.removeEventListener("click", onClick1);
  }, []);


  return (
    <>
     
      <div className={styles.mainWrapper}>
      
      <div className={styles.desctop}>
        <Styles.Wrapper ref={rootEl}>
          <Styles.MainWrapper style={{ width }}>
            <Styles.CalendarHeaderWrapper>
              <Styles.CurentDateWithArrows>
                <Styles.MenuImgWrapper>
                  {isShowMenu && (
                    <Styles.Menu>
                      <Styles.MenuItem>שנה</Styles.MenuItem>
                      <Styles.MenuItem variant={"selected"}>
                        חודש
                      </Styles.MenuItem>
                      <Styles.MenuItem>שבוע</Styles.MenuItem>
                      <Styles.MenuItem variant={"last"}>יום</Styles.MenuItem>

                      <Styles.Underline></Styles.Underline>

                      <Styles.MenuTitle>
                        לוחות שנה (בחירה מרובה)
                      </Styles.MenuTitle>
                      {email && (
                        <Styles.MenuEmailRow>
                          <div className={styles.radio}>
                            <input
                              // {...register('isEmailAccept')}
                              // onClick={() => dispatch(setIsEmailAccept())}
                              id="isEmailAccept"
                              type="radio"
                              name="isEmailAccept"
                              checked={true}
                            />
                            <label htmlFor="isEmailAccept"></label>
                            {/* <input type="radio" value={1} /> */}
                          </div>

                          <Styles.MenuEmail>{email}</Styles.MenuEmail>
                        </Styles.MenuEmailRow>
                      )}
                      {/* <Styles.MenuBtns>
                        <Styles.MenuBtn onClick={() => login()}>
                          הוסף חשבון
                        </Styles.MenuBtn>
                        <Styles.MenuBtn>סנכרן עכשיו</Styles.MenuBtn>
                      </Styles.MenuBtns> */}
                    </Styles.Menu>
                  )}
                  
                </Styles.MenuImgWrapper>

                <Styles.CurentDate>
                  {curentDate.clone().format("MMM YYYY")}
                </Styles.CurentDate>

                <Styles.Arrows>
                  <Styles.ArrowImg
                    onClick={() =>
                       setCurentDate((date) => date.subtract(1, "month"))
                    }
                    alt=">"
                    src={arrowRight}
                  />
                  <Styles.ArrowImg
                    onClick={() =>
                      setCurentDate((date) => date.add(1, "month"))
                    }
                    alt="<"
                    src={arrowLeft}
                  />
                </Styles.Arrows>
              </Styles.CurentDateWithArrows>

              <Styles.CalImgWithNumber>
                <Styles.DateNumber>
                  {dayjs(currentDay).format("D")}
                </Styles.DateNumber>
                <Styles.CalImg alt="cal" src={calendar} />
              </Styles.CalImgWithNumber>
            </Styles.CalendarHeaderWrapper>
            <Styles.WeekDaysWrapper>
              {generateWeeksOfTheMonth[0].map((day, index) => (
                <Styles.WeekDayCell key={`week-day-${index}`}>
                  {dayjs(day).format("ddd")}
                </Styles.WeekDayCell>
              ))}
            </Styles.WeekDaysWrapper>
            <Styles.CalendarMonthWrapper>
              {generateWeeksOfTheMonth.map((week, weekIndex) => (
                <Styles.CalendarContentWrapper key={`week-${weekIndex}`}>
                  {week.map((day, dayIndex) => (
                    <Styles.CalendarDayCell
                      onClick={() => {
                        setClickedDay(day);
                        // console.log(day,currentDay)
                      }}
                      key={`day-${dayIndex}`}
                      variant={
                        (curentDate.clone().toDate().getMonth() !==
                        day.getMonth() || dayjs(day).isBefore(currentDay, "date"))
                          ? "nextMonth"
                          : // : (selectedDates?.length === 1 &&
                          //     dayjs(day).isSame(selectedDates[0], "date")) ||
                          //   (selectedDates?.length === 2 &&
                          //     ((dayjs(day).isAfter(selectedDates[0], "date") &&
                          //       dayjs(day).isBefore(selectedDates[1], "date")) ||
                          //       dayjs(day).isSame(selectedDates[1], "date") ||
                          //       dayjs(day).isSame(selectedDates[0], "date")))
                          // ? "selected"
                          dayjs(currentDay).isSame(day, "date")
                          ? "today"
                          : "default"
                      }
                    >
                      {checkCell(day, 1) && <Styles.Cell1></Styles.Cell1>}
                      {dayjs(currentDay).isSame(day, "date") && (
                        <Styles.Today></Styles.Today>
                      )}
                      {dayjs(currentEventStartDate).isSame(day, "date") && (
                        <Styles.Selected></Styles.Selected>
                      )}
                      {checkCell(day, 2) && <Styles.Cell2></Styles.Cell2>}
                      {checkCell(day, 3) && <Styles.Cell3></Styles.Cell3>}
                      {day.getDate()}
                    </Styles.CalendarDayCell>
                  ))}
                </Styles.CalendarContentWrapper>
              ))}
            </Styles.CalendarMonthWrapper>
            {/* <Styles.Underline>

        </Styles.Underline>
        <Styles.CalendarButton>
          <Styles.Button onClick={() => {getDates(); setIsShowCalendar(!isShowCalendar)}}>
            בחר תאריך
          </Styles.Button>
          
        </Styles.CalendarButton> */}
          </Styles.MainWrapper>
        </Styles.Wrapper>
        <div id="dateTime" className={styles.secondPart}>

          <div className={styles.cubsWrapper}>
          <div style={{color:'#A4A2A2',fontSize:'24px'}} className={styles.titleInput}>
                  <input
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="הכנס כותרת"
                  />
          </div>
            <div className={styles.cubeWrapper}>
              <div
                style={{ backgroundColor: "#DAADE7" }}
                className={selectedCube === 1 ? styles.activeCube : styles.cube}
              ></div>
              <span>שיחת וידאו</span>
            </div>
          
          </div>

          <Styles.Underline></Styles.Underline>
          <div style={{ fontSize: "1.375rem", marginTop:'0.625rem' }}>{
                  currentEventStartDate
                    ? dayjs(currentEventStartDate).format("ddd, D MMMM")
                    : ""
                }
          </div>
          <div style={{ paddingTop: "1.8125rem" }} className={styles.timeRow}>
            <span style={{ marginLeft: "0.9375rem" }}>התחלה</span>
            <div className={styles.time}>
              <InputWithArrows
                onChange={cahngeCurrentStartTime}
                dataStr={
                  currentEventStartDate
                    ? dayjs(currentEventStartDate).format("H:mm")
                    : ""
                }
              />
            </div>
          </div>
          <div
            style={{ paddingBottom: "1.6875rem", paddingTop: "1.375rem" }}
            className={styles.timeRow}
          >
            <span style={{ marginLeft: "2.25rem" }}>סיום</span>
            <div className={styles.time}>
              <InputWithArrows
                onChange={cahngeCurrentEndTime}
                dataStr={
                  currentEventEndDate
                    ? dayjs(currentEventEndDate).format("H:mm")
                    : ""
                }
              />
            </div>
          </div>
          <Styles.Underline></Styles.Underline>
          <div
            className={styles.row}
            style={{ paddingBottom: "1.3rem", paddingTop: "1.5rem" }}
          >
            <img className={styles.screpka} alt="screpka" src={screpka} />
                  <div className={styles.titleInput1}>
                    <input
                      value={link}
                      onChange={(e) => setLink(e.target.value)}
                      placeholder="צירוף לינקים והערות לאירוע"
                    />
                  </div>
          </div>
          <div className={styles.btnContainer}>
            <div
              onClick={() => {
                  setIsShowError(false);
                  createInterval();
              }}
              className={styles.addBtn}
            >
               שלח הזמנה ללקוח
            </div>
          </div>
        </div>
      </div>
      <div className={styles.mobile}>
        { (step === 1 || step === 3 ) && <Styles.Wrapper ref={rootEl}>
          <Styles.MainWrapper style={{ width }}>
            <Styles.CalendarHeaderWrapper>

              <Styles.MenuImgWrapper>
                  <Styles.MenuImg
                    onClick={() => changeStep((step === 1) ? 3 : 1)}
                    alt="menu"
                    src={menu}
                  />
                </Styles.MenuImgWrapper>
              <Styles.CurentDateWithArrows>
                

                

                <Styles.Arrows>
                  <Styles.ArrowImg
                    onClick={() =>
                      setCurentDate((date) => date.subtract(1, "month"))
                    }
                    alt=">"
                    src={arrowRight}
                  />

                  <Styles.CurentDate>
                  {curentDate.clone().format("MMM YYYY")}
                </Styles.CurentDate>
                  <Styles.ArrowImg
                    onClick={() =>
                      setCurentDate((date) => date.add(1, "month"))
                    }
                    alt="<"
                    src={arrowLeft}
                  />
                </Styles.Arrows>
              </Styles.CurentDateWithArrows>

              <Styles.CalImgWithNumber>
                <Styles.DateNumber>
                  {dayjs(currentDay).format("D")}
                </Styles.DateNumber>
                <Styles.CalImg alt="cal" src={calendar} />
              </Styles.CalImgWithNumber>
            </Styles.CalendarHeaderWrapper>
            <Styles.WeekDaysWrapper>
              {generateWeeksOfTheMonth[0].map((day, index) => (
                <Styles.WeekDayCell key={`week-day-${index}`}>
                  {dayjs(day).format("ddd")}
                </Styles.WeekDayCell>
              ))}
            </Styles.WeekDaysWrapper>
            <Styles.CalendarMonthWrapper>
              {generateWeeksOfTheMonth.map((week, weekIndex) => (
                <Styles.CalendarContentWrapper key={`week-${weekIndex}`}>
                  {week.map((day, dayIndex) => (
                    <Styles.CalendarDayCell
                      onClick={() => {
                        setClickedDay(day);
                      }}
                      key={`day-${dayIndex}`}
                      variant={
                        (curentDate.clone().toDate().getMonth() !==
                        day.getMonth())
                          ? "nextMonth"
                          : // : (selectedDates?.length === 1 &&
                          //     dayjs(day).isSame(selectedDates[0], "date")) ||
                          //   (selectedDates?.length === 2 &&
                          //     ((dayjs(day).isAfter(selectedDates[0], "date") &&
                          //       dayjs(day).isBefore(selectedDates[1], "date")) ||
                          //       dayjs(day).isSame(selectedDates[1], "date") ||
                          //       dayjs(day).isSame(selectedDates[0], "date")))
                          // ? "selected"
                          dayjs(currentDay).isSame(day, "date")
                          ? "today"
                          : "default"
                      }
                    >
                      {checkCell(day, 1) && <Styles.Cell1></Styles.Cell1>}
                      {dayjs(currentDay).isSame(day, "date") && (
                        <Styles.Today></Styles.Today>
                      )}
                      {dayjs(currentEventStartDate).isSame(day, "date") && (
                        <Styles.Selected></Styles.Selected>
                      )}
                      {checkCell(day, 2) && <Styles.Cell2></Styles.Cell2>}
                      {checkCell(day, 3) && <Styles.Cell3></Styles.Cell3>}
                      {day.getDate()}
                    </Styles.CalendarDayCell>
                  ))}
                </Styles.CalendarContentWrapper>
              ))}
            </Styles.CalendarMonthWrapper>
            {/* <Styles.Underline>

        </Styles.Underline>
        <Styles.CalendarButton>
          <Styles.Button onClick={() => {getDates(); setIsShowCalendar(!isShowCalendar)}}>
            בחר תאריך
          </Styles.Button>
          
        </Styles.CalendarButton> */}
          </Styles.MainWrapper>
        </Styles.Wrapper>}
        {

          (step === 2 ) &&
        
          <div className={styles.secondPart}>
            <div 
              
              className={styles.linkBack}>
             <span onClick={() => changeStep(1)}>{"חזרה ליומן"}</span>
            </div>
            <div className={styles.titleInput}>
              <input
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="הכנס כותרת"
              />
            </div>

            <Styles.Underline></Styles.Underline>
            <span className={styles.titleBusy}>תיוג אירוע</span>
            <div className={styles.cubsWrapper}>
              <div className={styles.cubeWrapper}>
                <div
                  onClick={() => setSelectedCube(1)}
                  style={{ backgroundColor: "#E7B1AD" }}
                  className={selectedCube === 1 ? styles.activeCube : styles.cube}
                ></div>
                <span>חסום</span>
              </div>
              <div className={styles.cubeWrapper}>
                <div
                  onClick={() => setSelectedCube(2)}
                  style={{ backgroundColor: "#ADE3E7" }}
                  className={selectedCube === 2 ? styles.activeCube : styles.cube}
                ></div>
                <span>חסום חלקית</span>
              </div>
              <div className={styles.cubeWrapper}>
                <div
                  onClick={() => setSelectedCube(3)}
                  style={{ backgroundColor: "#DAADE7" }}
                  className={selectedCube === 3 ? styles.activeCube : styles.cube}
                ></div>
                <span>שיחת וידאו</span>
              </div>
              {(!selectedCube && isShowError) && <div className={styles.error}>
                {"שדה חובה"}
              </div>}
            </div>
            <Styles.Underline></Styles.Underline>
            <div style={{ paddingTop: "1.4375rem" }} className={styles.timeRow}>
              <span className={styles.marginLeft1} style={{ marginLeft: "0.9375rem" }}>התחלה</span>
              <div className={styles.date}>
                <InputWithArrows
                  onChange={cahngeCurrentStartDate}
                  // noArrows={true}
                  dataStr={
                    currentEventStartDate
                      ? dayjs(currentEventStartDate).format("ddd, D MMMM")
                      : ""
                  }
                />
              </div>
              <div className={styles.time}>
                <InputWithArrows
                  onChange={cahngeCurrentStartTime}
                  dataStr={
                    currentEventStartDate
                      ? dayjs(currentEventStartDate).format("H:mm")
                      : ""
                  }
                />
              </div>
            </div>
            <div
              style={{ paddingBottom: "1.4375rem", paddingTop: "0.625rem" }}
              className={styles.timeRow}
            >
              <span className={styles.marginLeft2} style={{ marginLeft: "2.25rem" }}>סיום</span>
              <div className={styles.date}>
                <InputWithArrows
                  onChange={cahngeCurrentEndDate}
                  dataStr={
                    currentEventEndDate
                      ? dayjs(currentEventEndDate).format("ddd, D MMMM")
                      : ""
                  }
                />
              </div>
              <div className={styles.time}>
                <InputWithArrows
                  onChange={cahngeCurrentEndTime}
                  dataStr={
                    currentEventEndDate
                      ? dayjs(currentEventEndDate).format("H:mm")
                      : ""
                  }
                />
              </div>
            </div>
            <Styles.Underline></Styles.Underline>
            <div className={styles.row} style={{ paddingTop: "0.96875rem" }}>
              <img className={styles.location} alt="location" src={location} />
              <span>מיקום</span>
            </div>
            <div
              className={styles.row}
              style={{ paddingBottom: "1.3rem", paddingTop: "1.5rem" }}
            >
              <img className={styles.screpka} alt="screpka" src={screpka} />
              <span>צירוף לינקים והערות לאירוע</span>
            </div>
            <Styles.Underline></Styles.Underline>
            <div className={styles.row} style={{ paddingTop: "1rem" }}>
              <img className={styles.add} alt="add" src={add} />
              <span>הוספת אירוע</span>
            </div>
            <div className={styles.btnContainer}>
              <div
                onClick={() => {
                  if(selectedCube){
                    setIsShowError(false);
                    let isAdded = createInterval();
                    if(isAdded){
                      changeStep(1);
                    }else{
                      
                    }
                    
                  }else{
                    setIsShowError(true);
                  }
                  
                }}
                className={styles.addBtn1}
              >
                הוספה ליומן
              </div>

              <div
                onClick={() => {
                  clearInterval(); changeStep(1);
                }}
                className={styles.cancellBtn}
              >
                ביטול  
              </div>
            </div>
          </div> 
        }
        { (step === 1 ) && <div className={styles.addBtn}>
          <div onClick={() => {
                        changeStep(2);
                      }} 
              className={styles.imgWrap}>
            <img src={plus} alt="plus"/>
          </div>
          {'הוספת אירוע ליומן'}
        </div>}
        { (step === 3 ) && 
          <div className={styles.allEvents}>
            {allInterval?.length && 
              allInterval.map((interval,index) => {


                return <>
                  <div className={styles.evetnConteiner}>

                    <div className={styles.head}>
                      <div className={styles.withCube}>
                        <div
                          style={{backgroundColor: 
                            (
                              (interval.type===1) ? "#E7B1AD"
                              :(interval.type===2)?"#ADE3E7"
                              :"#DAADE7"
                            )
                          }}
                          className={styles.cube}>
                        </div>
                        <div className={styles.cubeTitle}>{interval.name || ''}</div>
                      </div>
                      <div 
                        onClick={() => {
                          changeStep(2);
                          setInterval(interval)
                        }}
                        className={styles.change}>{"עריכה"}</div>
                    </div>
                    <div className={styles.times}>
                      <div className={styles.date}>
                        <InputWithArrows dataStr={dayjs(interval.startDate).format("ddd, D MMMM")} noArrows={true}/>
                      </div>
                      <div className={styles.time}>
                        <InputWithArrows dataStr={dayjs(interval.startDate).format("H:mm")} noArrows={true}/>
                      </div>
                    </div>
                    <div className={styles.times}>
                      <div className={styles.date}>
                        <InputWithArrows dataStr={dayjs(interval.endDate).format("ddd, D MMMM")} noArrows={true}/>
                      </div>
                      <div className={styles.time}>
                        <InputWithArrows dataStr={dayjs(interval.endDate).format("H:mm")} noArrows={true}/>
                      </div>
                    </div>
                    {((allInterval?.length) !== (index+1)) &&
                      <Styles.Underline></Styles.Underline>
                    }
                    
                  </div>
                </>
              })
            
            }
        </div>
        }
      </div>
    </div>
    </>
    
  );
};

export default Calendar;
