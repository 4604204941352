// import "@fontsource/anek-telugu";
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import calendar from './../../../assets/images/for-pages/icons/calendar-blank.png';
import calendarBlack from './../../../assets/images/for-pages/calendar-icon.png';
import arrowLeft from './../../../assets/images/for-pages/icons/arrow-left.png';
import arrowRight from './../../../assets/images/for-pages/icons/arrow-right.png';
// import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import he from 'dayjs/locale/he'; // load on demand
import localizedFormat from 'dayjs/plugin/localizedFormat';

import * as Styles from './styles';

const CustomCalendar = (props) => {
  const { setDisplayDate, displayDate, width, top, left, typeOfInput } = props;
  const _typeOne = '1';
  const rootEl = useRef(null);
  dayjs.locale(he);
  dayjs.extend(localizedFormat);
  const [curentDate, setCurentDate] = useState(dayjs());
  const today = dayjs();
  const [selectedDate, setSelectedDate] = useState('');
  let [isShowCalendar, setIsShowCalendar] = useState(false);
  // const [displayDate, setDisplayDate] = useState([])

  const currentDay = useMemo(() => dayjs().toDate(), []);

  const setClickedDay = (day) => {
    // let isBefore = selectedDates.length ?  dayjs(day).isBefore(selectedDates[0], "date") : false
    // console.log(isBefore, selectedDates)
    // let newDates = (selectedDates.length && selectedDates.length !==2) ? [...selectedDates] : [];
    // if(isBefore){
    //   newDates.unshift(day)
    // }else{
    //   newDates.push(day);
    // }
    if (dayjs(day).isBefore(today, 'date')) return;
    if (dayjs(selectedDate).isSame(day, 'date')) {
      setSelectedDate('');
    } else {
      setSelectedDate(day);
    }
  };

  const getDates = () => {
    let select = selectedDate
      ? dayjs(selectedDate).hour(dayjs().get('hour')).minute(dayjs().get('minute')).toJSON()
      : '';

    setDisplayDate(select);
  };

  const firstDayOfTheMonth = useMemo(() => curentDate.clone().startOf('month'), [curentDate]);

  const firstDayOfFirstWeekOfMonth = useMemo(
    () => dayjs(firstDayOfTheMonth).startOf('week'),
    [firstDayOfTheMonth]
  );

  const generateFirstDayOfEachWeek = useCallback((day) => {
    const dates = [day];
    for (let i = 1; i < 6; i++) {
      const date = day.clone().add(i, 'week');
      dates.push(date);
    }
    return dates;
  }, []);

  const generateWeek = useCallback((day) => {
    const dates = [];
    for (let i = 0; i < 7; i++) {
      const date = day.clone().add(i, 'day').toDate();
      dates.push(date);
    }
    return dates;
  }, []);

  const generateWeeksOfTheMonth = useMemo(() => {
    const firstDayOfEachWeek = generateFirstDayOfEachWeek(firstDayOfFirstWeekOfMonth);
    return firstDayOfEachWeek.map((date) => generateWeek(date));
  }, [generateFirstDayOfEachWeek, firstDayOfFirstWeekOfMonth, generateWeek]);

  useEffect(() => {
    const onClick1 = (e) => rootEl.current.contains(e.target) || setIsShowCalendar(false);
    document.addEventListener('click', onClick1);
    return () => document.removeEventListener('click', onClick1);
  }, []);

  return (
    <Styles.Wrapper ref={rootEl}>
      {typeOfInput === _typeOne ? (
        <Styles.InputWrapperOne onClick={() => setIsShowCalendar(!isShowCalendar)}>
          {displayDate ? (
            <Styles.CalInputTextOne>
              {`${dayjs(displayDate).format('DD/MM/YYYY')}`}
              {' | יום ב'}
            </Styles.CalInputTextOne>
          ) : (
            <span>{'בחר את תאריך האירוע'}</span>
          )}
          <Styles.CalInputImgOne alt="cal" src={calendarBlack} />
        </Styles.InputWrapperOne>
      ) : (
        <Styles.InputWrapper onClick={() => setIsShowCalendar(!isShowCalendar)}>
          <Styles.CalInputImg alt="cal" src={calendarBlack} />
          {displayDate ? (
            <Styles.CalInputText>{`${dayjs(displayDate).format('L')}`}</Styles.CalInputText>
          ) : (
            <span>בחר תאריכים ספציפיים</span>
          )}
        </Styles.InputWrapper>
      )}

      {isShowCalendar && (
        <Styles.MainWrapper style={{ width, top, left }}>
          <Styles.CalendarHeaderWrapper>
            <Styles.CurentDateWithArrows>
              <Styles.CurentDate>{curentDate.clone().format('MMM YYYY')}</Styles.CurentDate>

              <Styles.Arrows>
                <Styles.ArrowImg
                  onClick={() => setCurentDate((date) => date.subtract(1, 'month'))}
                  alt=">"
                  src={arrowRight}
                />
                <Styles.ArrowImg
                  onClick={() => setCurentDate((date) => date.add(1, 'month'))}
                  alt="<"
                  src={arrowLeft}
                />
              </Styles.Arrows>
            </Styles.CurentDateWithArrows>

            <Styles.CalImgWithNumber>
              <Styles.DateNumber>{dayjs(currentDay).format('D')}</Styles.DateNumber>
              <Styles.CalImg alt="cal" src={calendar} />
            </Styles.CalImgWithNumber>
          </Styles.CalendarHeaderWrapper>
          <Styles.WeekDaysWrapper>
            {generateWeeksOfTheMonth[0].map((day, index) => (
              <Styles.WeekDayCell key={`week-day-${index}`}>
                {dayjs(day).format('ddd')}
              </Styles.WeekDayCell>
            ))}
          </Styles.WeekDaysWrapper>
          <Styles.CalendarMonthWrapper>
            {generateWeeksOfTheMonth.map((week, weekIndex) => (
              <Styles.CalendarContentWrapper key={`week-${weekIndex}`}>
                {week.map((day, dayIndex) => (
                  <Styles.CalendarDayCell
                    onClick={() => {
                      setClickedDay(day);
                      // console.log(day,currentDay)
                    }}
                    key={`day-${dayIndex}`}
                    variant={
                      curentDate.clone().toDate().getMonth() !== day.getMonth() ||
                      dayjs(day).isBefore(currentDay, 'date')
                        ? 'nextMonth'
                        : dayjs(day).isSame(selectedDate, 'date')
                        ? 'selected'
                        : dayjs(currentDay).isSame(day, 'date')
                        ? 'today'
                        : 'default'
                    }
                  >
                    {day.getDate()}
                  </Styles.CalendarDayCell>
                ))}
              </Styles.CalendarContentWrapper>
            ))}
          </Styles.CalendarMonthWrapper>
          <Styles.Underline></Styles.Underline>
          <Styles.CalendarButton>
            <Styles.Button
              onClick={() => {
                getDates();
                setIsShowCalendar(!isShowCalendar);
              }}
            >
              בחר תאריך
            </Styles.Button>
          </Styles.CalendarButton>
        </Styles.MainWrapper>
      )}
    </Styles.Wrapper>
  );
};

export default CustomCalendar;
