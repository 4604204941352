import React, { useEffect, useRef, useState } from "react";
import styles from "./select.module.scss";
import arrow from "../../../../../assets/images/for-pages/caret-down.png";
import arrowUp from "../../../../../assets/images/for-pages/caret-up.png";

export default function Select(props) {

  const {
    fontSize,
    justifyContent,
    backgroundColor,
    borderRadius,
    color,
    fontFamily,
    fontFamilyHeader,
    paddingInlineStart,
    paddingInlineEnd,
  } = props;

  const main = {
    borderRadius,
    backgroundColor,
  };

  const dropDownContainer = {
    // width, height,
  };

  const dropDownHeader = {
    paddingInlineStart,
    paddingInlineEnd
  };

  const headerOption = {
    //
    fontFamily: fontFamilyHeader,
    justifyContent,
    width: "100%",
  };

  const dropDownList = {
    // backgroundColor,
    // width,
  };
  
  const dropDownListOption = {
    justifyContent,
    fontSize,
    color,
    "&:hover": {
      fontFamily,
    },
  };

  const rootEl = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const toggling = () => { setIsOpen(!isOpen)};

  const onOptionClicked = (value) => () => {
    props.onChangeOption(value);
    setIsOpen(false);
  };

  useEffect(() => {
    const onClick1 = (e) =>
      rootEl.current.contains(e.target) || setIsOpen(false);
    document.addEventListener("click", onClick1);
    return () => document.removeEventListener("click", onClick1);
  }, []);

/*   useEffect(() => {}, [props]); */

  return (
    <div ref={rootEl} className={styles.main} style={main}>
      <div style={dropDownContainer} className={styles.dropDownContainer}>
        <div className={styles.dropDownHeader} onClick={toggling} style={dropDownHeader}>
          <span style={headerOption} className={styles.headerOption}>
            {props.selectedOption?.name ||
              props.placeholder ||
              "בחר מתוך הרשימה"}
          </span>
          <img
            alt="arrow"
            src={isOpen ? arrowUp : arrow}
            className={styles.arrow}
          />
        </div>
        {isOpen && (
          <div className={styles.dropDownListContainer}>
            <div style={dropDownList} className={styles.dropDownList}>
              {props?.options?.map((option) => (
                <div
                  style={dropDownListOption}
                  className={styles.listItem}
                  onClick={onOptionClicked(option)}
                  key={Math.random()}
                >
                  <span
                    style={{
                      fontFamily:
                        option?.id === props.selectedOption?.id
                          ? fontFamily || "Heebo-Bold"
                          : "",
                    }}
                  >
                    {option.name}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>

  );
}
