import styles from "../UserLogin.module.scss";
// import facebookLogo from "../../../assets/images/facebook_logo.png";
// import googleLogo from "../../../assets/images/google_logo.png";
import userLoginBackground from "../../../../assets/images/userLoginBackground.png";
import iconHide from "../../../../assets/images/icon-hide.png";
import iconView from "../../../../assets/images/icon-view.png";
// import { useGoogleLogin } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import useAuth from "../../../hooks/useAuth";
import {
  setIsOpenUserAuth,
  setIsOpenUserReg,
  setIsGoogle,
  setCurrentEventForChecklist,
  setIsOpenMobleAuth,
} from "../../../store/mainSlice";
import { setUserIdToken } from "../../../store/supplierRegistrationSlice";
import { setErr } from "../../../store/forAll/errSlice";
import { TEMP_USER_KEY } from "../../../consts/local_keys";
import MetaFavicon from "../../../components/metaComponents/metaFavicon";
import { setCurrentEventChecklist } from "../../../store/checkListSlice";
import axios from "../../../../api/axios";

function DefaultUserLogin(props) {
  const { forgotPassword, setForgotPassword } = props;
  // let { pathForReturn, nextPathForReturn } = useSelector((state) => state.returnToUrl);
  let [isShow, setIsShow] = useState(false);
  const navigate = useNavigate();
  // let [isDownloaded, setIsDownloaded] = useState(false);
  const imageContainer = useRef();
  const dispatch = useDispatch();
  const { setAuth } = useAuth();
  const backgroundImage = new Image();
  backgroundImage.src = userLoginBackground;

  const goToForgotPassword = () => {
    setForgotPassword(true);
  };
  /*useEffect(()=>{
    console.log(window.location.pathname === '/supplier-registration');
  },[])*/
  const onLoginRegisterGoogle = async (res) => {
    // console.log(res)
    try {
      const response = await axios.post(`/user/login/google`, {
        token: res.credential,
      });
      const data = await response.data;

      const accessToken = data?.jwtAuthenticationResponseDto.accessToken;
      const refreshToken = data?.jwtAuthenticationResponseDto.refreshToken;
      const roles = [data?.userDto.userType];
      const user = JSON.stringify(data?.userDto);

      if (accessToken && refreshToken && roles && user) {
        setAuth({ accessToken, refreshToken, user, roles });
      }
      localStorage.removeItem(TEMP_USER_KEY);
      if (window.location.pathname === "/supplier-registration") {
        navigate("/");
      }
      dispatch(setIsOpenUserAuth(false));
      // let div = document.getElementsByClassName('samyBody');
      // div[0].scrollTop = 0
      // div[0].scrollRight = 0
      // window.scrollTo(0,0);
      // console.log('1-2-3');
      // setTimeout(() => {
      //   window.scrollTo(0,0);
      //   // div[0].scrollTop = 0
      //   // div[0].scrollLeft = 0
      //   console.log('3-2-1');
      // }, 500);
      return data === undefined ? null : data;
    } catch (error) {
      dispatch(setUserIdToken(res.credential));
      dispatch(setIsOpenUserAuth(false));
      dispatch(setIsGoogle(true));
      dispatch(setIsOpenUserReg(true));
      console.log(error);
    }
  };
  // useGoogleLogin({
  //   onSuccess: (tokenResponse) => {
  //     console.log(12312312);
  //     console.log(tokenResponse);
  //   },
  // 'client_id': GOOGLE_API_CLIEND_ID,
  // 'redirect_uri': `${location.origin}/auth/google`,
  // response_type: "",
  // 'scope': GOOGLE_API_SCOPES,
  // state: 'af0ifjsldkj',
  // nonce: 'n-0S6_WzA2Mj'
  // },
  // );

  // const handleLoadingBackground = () => {
  //   imageContainer.current.style.backgroundImage =
  //     "url(" + userLoginBackground + ")";
  //   setIsDownloaded(true);
  //   dispatch(setLoader(false));
  // };

  // useLayoutEffect(() => {
  //   dispatch(setLoader(true));
  //   backgroundImage.addEventListener("load", handleLoadingBackground);
  //   setTimeout(() => {dispatch(setLoader(false))},5000)
  //   // return backgroundImage.remove("load", handleLoadingBackground);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const schema = !forgotPassword
    ? yup
        .object()
        .shape({
          email: yup
            .string()
            .email("רשום מייל תקין") // 'Enter a valid email'
            .required("שדה חובה"), // 'You must enter a email'
          password: yup
            .string()
            .required("נא לרשום סיסמא") // 'Please enter your password.'
            .min(6, "הסיסמא קצרה מדי, לפחות 6 תוים."), // 'Password is too short - should be 4 chars minimum.'
        })
        .required()
    : yup
        .object()
        .shape({
          forgotEmail: yup
            .string()
            .email("רשום מייל תקין") // 'Enter a valid email'
            .required("שדה חובה"), // 'You must enter a email'
        })
        .required();

  const defaultValues = {
    email: "",
    password: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const loginUser = async (data) => {
    let data1 = {
      email: data.email,
      password: data.password,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_PATH}user/login`,
        data1
      );
      const data = await response.data;

      const accessToken = data?.jwtAuthenticationResponseDto.accessToken;
      const refreshToken = data?.jwtAuthenticationResponseDto.refreshToken;
      const roles = [data?.userDto.userType];
      const user = JSON.stringify(data?.userDto);
      dispatch(
        setCurrentEventChecklist({
          currentEvent: { checkListId: 0, eventTypeName: "" },
        })
      );
      dispatch(
        setCurrentEventForChecklist({
          currentEventForChecklist: { id: 0, name: "" },
        })
      );
      if (accessToken && refreshToken && roles && user) {
        setAuth({ accessToken, refreshToken, user, roles });
      }
      localStorage.removeItem(TEMP_USER_KEY);
      if (window.location.pathname === "/supplier-registration") {
        navigate("/");
      } else if (window.location.pathname === "/personal-guest/checklist") {
        navigate("/personal/checklist");
      } else if (window.location.pathname === "/personal-guest/change-user") {
        navigate("/personal/change-user");
      } else if (window.location.pathname === "/personal-guest") {
        navigate("/personal/checklist");
      } else {
        navigate("/personal/checklist");
      }
      dispatch(setIsOpenUserAuth(false));

      return data === undefined ? null : data;
    } catch (error) {
      if (error) {
        console.log("Error", error);
        dispatch(
          setErr({
            message: error?.response?.data?.message,
            code: error?.response?.data?.code,
          })
        );
      }
    }
  };

  const sendEmail = async (data1) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_PATH}auth/reset/password?email=${data1.forgotEmail}&userType=USER`
      );
      if (response) {
        setForgotPassword(false);
        dispatch(
          setErr({
            message: "סיסמה חדשה נשלחה למייל",
            type: 2,
          })
        );
      }
    } catch (error) {
      if (error) {
        // console.log(error)
        dispatch(
          setErr({
            message: error?.response?.data?.message,
            code: error?.response?.data?.code,
          })
        );
      }
    }
  };
  useEffect(() => {
    return () => {
      setForgotPassword(false);
    };
  }, []);

  return (
    // <div className={styles.adaptiveWrapperSecondBlock}>
    //   <div className={styles.userLoginFormContainerContent}>
    <>
      {/* {forgotPassword && <h2 className={styles.loginHeader}>שכחית סיסמה</h2>}
        {!forgotPassword && <h2 className={styles.loginHeader}>התחברות</h2>} */}
      {!forgotPassword && (
        <div className={styles.regButtonsWithOtherResContainer}>
          {/* <button>
                    <img src={facebookLogo} alt="facebookLogo" />
                    להתחברות עם Facebook
                  </button> */}
          <div>
            {/* <GoogleLogin
                    onSuccess={credentialResponse => {
                      console.log(credentialResponse);
                    }}
                    onError={() => {
                      console.log('Login Failed');
                    }}
                    useOneTap
                  />; */}
            <GoogleLogin
              onSuccess={(credentialResponse) => {
                onLoginRegisterGoogle(credentialResponse);
              }}
              onError={() => {
                console.log("Login Failed");
              }}
              locale={"he"}
            />
          </div>
          {/* <button onClick={() => onLoginRegisterGoogle()}>
                    <img src={googleLogo} alt="googleLogo" />
                    להתחברות עם Google
                  </button> */}
        </div>
      )}
      {!forgotPassword && (
        <form className={styles.loginForm} onSubmit={handleSubmit(loginUser)}>
          <div className={styles.loginFormBlock}>
            <div className={styles.inputContainer}>
              <input
                type="email"
                placeholder="כתובת מייל"
                {...register("email")}
                autoComplete="new-password"
                className={classNames({
                  [styles.invalidField]: errors.email,
                })}
              />
              {errors.email && (
                <p className={styles.errorMessage}>{errors.email.message}</p>
              )}
            </div>
            <div className={styles.inputContainer}>
              <input
                type={isShow ? "text" : "password"}
                placeholder="סיסמא"
                autoComplete="new-password"
                {...register("password")}
                className={classNames({
                  [styles.invalidField]: errors.password,
                })}
              />
              <img
                src={iconHide}
                alt="hide"
                className={styles.hideShowIcon}
                style={{ display: isShow ? "block" : "none" }}
                onClick={() => setIsShow(!isShow)}
              />
              <img
                src={iconView}
                alt="view"
                className={styles.hideShowIcon}
                style={{ display: isShow ? "none" : "block" }}
                onClick={() => setIsShow(!isShow)}
              />
              {errors.password && (
                <p className={styles.errorMessage}>{errors.password.message}</p>
              )}
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "5px" }}
            >
              <div className={styles.forgotPasswordLink}>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => goToForgotPassword()}
                >
                  שכחתי סיסמא
                </span>
              </div>

              {/* <span
                style={{
                  cursor: "pointer",
                  color: "#f5b9ab",
                  fontFamily: "Heebo-Regular",
                  fontSize: "1.13rem",
                  lineHeight: "1.69rem",
                }}
                onClick={() => {
                  dispatch(setIsOpenUserAuth(false));
                  dispatch(setIsOpenMobleAuth(true));
                }}
              >
                הירשם דרך טלפון
              </span> */}
            </div>
          </div>
          <div className={styles.loginFormBlock}>
            <button className={styles.submitButton} type="submit">
              התחבר
            </button>
          </div>
        </form>
      )}
      {forgotPassword && (
        <form className={styles.loginForm} onSubmit={handleSubmit(sendEmail)}>
          <div className={styles.loginFormBlock}>
            <div className={styles.inputContainer}>
              <input
                type="email"
                placeholder="כתובת מייל"
                {...register("forgotEmail")}
                className={classNames({
                  [styles.invalidField]: errors.forgotEmail,
                })}
              />
              {errors.forgotEmail && (
                <p className={styles.errorMessage}>
                  {errors.forgotEmail.message}
                </p>
              )}
            </div>
          </div>
          <div className={styles.loginFormBlock}>
            <button className={styles.submitButton2} type="submit">
              שלח סיסמה
            </button>
          </div>
        </form>
      )}
      <div className={styles.goTeRegContainer}>
        <p>עדיין אין לי חשבון איוונט-דו</p>
        <Link
          onClick={() => {
            dispatch(setIsOpenUserAuth(false));
            dispatch(setIsOpenUserReg(true));
          }}
        >
          הירשם
        </Link>
      </div>
      {/* </div>
    </div> */}
    </>
  );
}

export default DefaultUserLogin;
