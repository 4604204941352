import Dialog from "@mui/material/Dialog";
import styles from "./PopUp.module.scss";
import { useEffect, useState} from "react";
function PopUp(props) {

  const { content, open, closeDialog, isVideo, id, slides } = props;

  const [is_beginning, set_is_beginning] = useState(false);
  const [is_end, set_is_end] = useState(false);
  const [currId, setCurrId] = useState()

  const stylesForLeftArrow = `${styles.arrow} ${
    is_end ? "disableSliderNextPrev" : ""
  }`;
  const stylesForRightArrow = `${styles.arrow} ${
    is_beginning ? "disableSliderNextPrev" : ""
  }`;

  
  useEffect(()=>{
    set_is_beginning(false)
    set_is_end(false)
    setCurrId(id)
    if(id === 0){
      set_is_beginning(true)
    } 
     if(id + 1 === slides.length){
      set_is_end(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[id])

  useEffect(()=>{
    set_is_beginning(false)
    set_is_end(false)
    if(currId === 0){
      set_is_beginning(true)
    }
    if(currId + 1 === slides.length){
      set_is_end(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[currId])

  const goToPrevSlide = () => {
    set_is_beginning(false)
    set_is_end(false)
    setCurrId(prevCurrId=>{
      if(prevCurrId === 0){
        set_is_beginning(true)
      } 
      if(prevCurrId !== 0){
        return prevCurrId - 1
      }
      return prevCurrId 
    })
  }
  const goToNextSlide = ()=>{
    set_is_beginning(false)
    set_is_end(false)
    setCurrId(prevCurrId=>{
      if(prevCurrId + 1 === slides.length){
        set_is_end(true)
      } 
      if(prevCurrId + 1 !== slides.length){
        return prevCurrId + 1
      }
      return prevCurrId 
    })
  }

  return (
    !isVideo ? (<Dialog open={open} onClose={closeDialog} fullWidth={true} maxWidth={'md'}>
      <div className={styles.wrapper} onClick={(e)=>e.stopPropagation()}>
        <div onClick={()=>goToPrevSlide()} className={stylesForRightArrow} disabled={id === 0 || currId === 0}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19.904"
            height="19.4"
            viewBox="0 0 19.904 19.4"
          >
            <path
              id="Icon_awesome-arrow-right"
              data-name="Icon awesome-arrow-right"
              d="M8.463,3.947l.986-.986a1.062,1.062,0,0,1,1.506,0l8.636,8.631a1.062,1.062,0,0,1,0,1.506l-8.636,8.636a1.062,1.062,0,0,1-1.506,0l-.986-.986a1.067,1.067,0,0,1,.018-1.524l5.353-5.1H1.066A1.064,1.064,0,0,1,0,13.058V11.636A1.064,1.064,0,0,1,1.066,10.57H13.833L8.48,5.47A1.06,1.06,0,0,1,8.463,3.947Z"
              transform="translate(0 -2.647)"
              fill={is_beginning ? "#a4a2a2" : "#e7b1ad"}
            />
          </svg>
        </div>
        <img alt="SelectedImage" src={slides[currId ?? id]?.imageUrl} className={styles.selectedImage}></img>
        <div onClick={()=>goToNextSlide()} className={stylesForLeftArrow} disabled={id + 1 === slides.length || currId + 1 === slides.length}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19.904"
            height="19.4"
            viewBox="0 0 19.904 19.4"
          >
            <path
              id="Icon_awesome-arrow-right"
              data-name="Icon awesome-arrow-right"
              d="M8.463,3.947l.986-.986a1.062,1.062,0,0,1,1.506,0l8.636,8.631a1.062,1.062,0,0,1,0,1.506l-8.636,8.636a1.062,1.062,0,0,1-1.506,0l-.986-.986a1.067,1.067,0,0,1,.018-1.524l5.353-5.1H1.066A1.064,1.064,0,0,1,0,13.058V11.636A1.064,1.064,0,0,1,1.066,10.57H13.833L8.48,5.47A1.06,1.06,0,0,1,8.463,3.947Z"
              transform="translate(19.904 22.047) rotate(180)"
              fill={is_end ? "#a4a2a2" : "#e7b1ad"}
            />
          </svg>
        </div>
      </div>
    </Dialog>) : (
      <Dialog open={open} onClose={closeDialog} fullWidth={true} maxWidth={'md'}
      >
        {content}
      </Dialog>
    )
  );
}

export default PopUp;
