import { useEffect } from "react"
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom"
import { setIsOpenUserAuth } from "../../store/mainSlice";

const OpenSignup = () => {
 const navigate = useNavigate();
 const dispatch = useDispatch();
    useEffect(()=>{
        navigate('/');
        dispatch(setIsOpenUserAuth(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        <></>
    )
}
export default OpenSignup;