import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {TEMP_USER_KEY} from "../consts/local_keys";

let cancelToken;
export const fetchCategoriesForEvent = createAsyncThunk(
  "suppliers/fetchCategoriesForEvent",
  async function ({response}, {rejectWithValue}) {
    try {     
      const data = await response.data;
      let newData = data?.map(item => {
        let newItem = {...item}
        if(newItem.id === 3){
          newItem.isVenue = true;
        }
        return newItem
      })
      // console.log(data);
      return newData;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const fetchServicesForCategory = createAsyncThunk(
  "suppliers/fetchServicesForCategory",
  async function (category, {rejectWithValue}) {
    try {
      const response = await axios.get(`/category/${category.id}/serviceTypes`);
      // console.log(response)
      if (response.status!==200) {
        throw new Error('Server Error');
      }
      const data = await response.data;
      // console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const fetchAllSuppliersForCard = createAsyncThunk(
  "suppliers/fetchAllSuppliersForCard",
  async function ({filters, axiosPrivate, signal}, {rejectWithValue}) {
    try {
      //Check if there are any previous pending requests
      if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.")
      }
      //Save the cancel token for the current request
      cancelToken = axios.CancelToken.source()
      const response = await (axiosPrivate || axios).post(`/supplier/filter?userToken=${localStorage.getItem(TEMP_USER_KEY) || ''}`, filters, {cancelToken: cancelToken.token, signal});
      if (response.status!==200) {
        throw new Error('Server Error');
      }
      return await response.data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const fetchAllVenuesForCard = createAsyncThunk(
  "suppliers/fetchAllVenuesForCard",
  async function ({filters, axiosPrivate, signal}, {rejectWithValue}) {
    try {
      const response = await (axiosPrivate || axios).post(`/venue/filter`, filters, { signal });
      // console.log(response)
      if (response.status!==200) {
        throw new Error('Server Error');
      }
      const data = await response.data;
      // console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const fetchPriceForForm = createAsyncThunk(
  "suppliers/fetchPriceForForm",
  async function (data, {rejectWithValue}) {
    try {
      const response = await axios.get(`/venue/details/price`);
      // console.log(response)
      if (response.status!==200) {
        throw new Error('Server Error');
      }
      const data = await response.data;
      const dataToReturn = data.map((price) => {
        let newPrice = {...price}
        newPrice.name = `${price?.from} - ${price?.to}`
        return newPrice
      })
      return dataToReturn;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);
export const fetchGuestsRangeForForm = createAsyncThunk(
  "suppliers/fetchGuestsRangeForForm",
  async function (data, {rejectWithValue}) {
    try {
      const response = await axios.get(`/venue/details/guests/range`);
      // console.log(response)
      if (response.status!==200) {
        throw new Error('Server Error');
      }
      const data = await response.data;
      // console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);
export const fetchGeoForForm = createAsyncThunk(
  "suppliers/fetchGeoForForm",
  async function (data, {rejectWithValue}) {
    try {
      const response = await axios.get(`/venue/details/locations`);
      // console.log(response)
      if (response.status!==200) {
        throw new Error('Server Error');
      }
      const data = await response.data;
      // console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);
export const fetchWeekendsForForm = createAsyncThunk(
  "suppliers/fetchWeekendsForForm",
  async function (data, {rejectWithValue}) {
    try {
      const response = await axios.get(`/venue/details/event`);
      // console.log(response)
      if (response.status!==200) {
        throw new Error('Server Error');
      }
      const data = await response.data;
      // console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);
export const fetchVenueForForm = createAsyncThunk(
  "suppliers/fetchVenueForForm",
  async function (data, {rejectWithValue}) {
    try {
      const response = await axios.get(`/venue/details/type`);
      // console.log(response)
      if (response.status!==200) {
        throw new Error('Server Error');
      }
      const data = await response.data;
      // console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);
export const fetchKosherForForm = createAsyncThunk(
  "suppliers/fetchKosherForForm",
  async function (data, {rejectWithValue}) {
    try {
      const response = await axios.get(`/venue/details/kosher`);
      // console.log(response)
      if (response.status!==200) {
        throw new Error('Server Error');
      }
      const data = await response.data;
      // console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);
export const fetchParkingForForm = createAsyncThunk(
  "suppliers/fetchParkingForForm",
  async function (data, {rejectWithValue}) {
    try {
      const response = await axios.get(`/venue/details/parking`);
      // console.log(response)
      if (response.status!==200) {
        throw new Error('Server Error');
      }
      const data = await response.data;
      // console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

const suppliersSlice = createSlice({
  name: "suppliers",
  initialState: {

    formSend:{
      dateFrom:'',
      dateTo: '',
      priceRangesId: [],
      geoLocationsId: [],
      amountOfGuestsFrom: 0,
      amountOfGuestsTo: 0,
      nameSearch: "",
      weekendEventsId: [],
      venueTypesId: [],
      koshersId: [],
      parkingOptionsId: [],
    },
    formGet:{
      priceRanges: [{
        "id": 0,
        "from": 1000,
        "to": 1000,
        "name": "1000-1000"
      }],
      geoLocations: [{
        "id": 0,
        "name": ""
      }],
      guestsRange: {
        "min": 0,
        "max": 100
      },
      weekendEvents: [{
        "id": 0,
        "name": ""
      }],
      venueTypes: [{
        "id": 0,
        "name": ""
      }],
      koshers: [{
        "id": 0,
        "name": ""
      }],
      parkingOptions: [{
        "id": 0,
        "name": ""
      }],
    },
    selectedCategoryId: 0,
    selectedEventTypeId: '',
    categories: [],
    services: [],
    isFlashSelected: false,
    error: null,
    loadingStatus: null,
    suppliersFilters: {},
    venuesFilters: {},
  },
  reducers: {
    setSelectedCategoryId(state,action) {
      state.selectedCategoryId = action.payload;
    },
    setSelectedEventTypeId(state,action) {
      state.selectedEventTypeId = action.payload;
    },

    clearForm(state,action) {
      state.formSend = {
        dateFrom:'',
        dateTo: '',
        priceRangesId: [],
        geoLocationsId: [],
        amountOfGuestsFrom: 0,
        amountOfGuestsTo: 0,
        nameSearch: "",
        weekendEventsId: [],
        venueTypesId: [],
        koshersId: [],
        parkingOptionsId: [],
      }
    },
    setIsFlashSelected(state, action) {
      // console.log(action.payload)
      state.isFlashSelected = action.payload;
    },
    setDateFrom(state, action) {
      // console.log(action.payload)
      state.formSend.dateFrom = action.payload;
    },
    setDateTo(state, action) {
      // console.log(action.payload)
      state.formSend.dateTo = action.payload;
    },
    setAmountOfGuestsFrom(state, action) {
      // console.log(action.payload)
      state.formSend.amountOfGuestsFrom = action.payload;
    },
    setNameSearch(state, action) {
      state.formSend.nameSearch = action.payload;
    },
    setAmountOfGuestsTo(state, action) {
      // console.log(action.payload)
      state.formSend.amountOfGuestsTo = action.payload;
    },
    setGeoLocationsId(state, action) {
      // console.log(action.payload)
      state.formSend.geoLocationsId = action.payload;
    },
    setPriceRangesId(state, action) {
      // console.log(action.payload)
      state.formSend.priceRangesId = action.payload;
    },
    setWeekendEventsId(state, action) {
      // console.log(action.payload)
      state.formSend.weekendEventsId = action.payload;
    },
    setVenueTypesId(state, action) {
      // console.log(action.payload)
      state.formSend.venueTypesId = action.payload;
    },
    setKoshersId(state, action) {
      // console.log(action.payload)
      state.formSend.koshersId = action.payload;
    },
    setParkingOptionsId(state, action) {
      // console.log(action.payload)
      state.formSend.parkingOptionsId = action.payload;
    },
    setSuppliersFilters(state, action) {
      state.suppliersFilters = action.payload;
    },
    setVenuesFilters(state, action) {
      state.venuesFilters = action.payload;
    },
    setServices(state,action) {
      state.services = action.payload
    }
  },
  extraReducers: {
    [fetchCategoriesForEvent.pending]: (state, action) => {
      state.loadingStatus = "loading";
      state.error = null;
    },
    [fetchCategoriesForEvent.fulfilled]: (state, action) => {
      state.loadingStatus = "resolved";
      state.categories = action.payload;
    },
    [fetchCategoriesForEvent.rejected]: (state, action) => {
      state.loadingStatus = 'rejected'
      state.error = action.payload;
    },
    [fetchServicesForCategory.fulfilled]: (state, action) => {
      state.loadingStatus = 'resolved'
      state.services = action.payload;
    },

    [fetchPriceForForm.fulfilled]: (state, action) => {
      state.loadingStatus = 'resolved'
      state.formGet.priceRanges = action.payload;
    },
    [fetchGuestsRangeForForm.fulfilled]: (state, action) => {
      state.loadingStatus = 'resolved'
      state.formGet.guestsRange = action.payload;
    },
    [fetchGeoForForm.fulfilled]: (state, action) => {
      state.loadingStatus = 'resolved'
      state.formGet.geoLocations = action.payload;
    },
    [fetchWeekendsForForm.fulfilled]: (state, action) => {
      state.loadingStatus = 'resolved'
      state.formGet.weekendEvents = action.payload;
    },
    [fetchVenueForForm.fulfilled]: (state, action) => {
      state.loadingStatus = 'resolved'
      state.formGet.venueTypes = action.payload;
    },
    [fetchKosherForForm.fulfilled]: (state, action) => {
      state.loadingStatus = 'resolved'
      state.formGet.koshers = action.payload;
    },
    [fetchParkingForForm.fulfilled]: (state, action) => {
      state.loadingStatus = 'resolved'
      state.formGet.parkingOptions = [{id: '', name: "הכל"}
        ,...action.payload];
    },
  },
});

export const { 
  clearForm,
  setDateFrom,
  setDateTo,
  setIsFlashSelected,
  setAmountOfGuestsFrom,
  setNameSearch,
  setAmountOfGuestsTo, 
  setGeoLocationsId,
  setPriceRangesId,
  setWeekendEventsId,
  setVenueTypesId,
  setKoshersId,
  setParkingOptionsId,
  setSelectedCategoryId,
  setSelectedEventTypeId,
  setVenuesFilters,
  setSuppliersFilters,
  setServices
} = suppliersSlice.actions;

export default suppliersSlice.reducer;
