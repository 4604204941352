import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectAuthPhoneNumber } from '../../store/authCodeSlice';
import { Box } from '@mui/material';

const ShellLoginWithPhone = ({ formExists, children }) => {
  const phoneNumber = useSelector(selectAuthPhoneNumber);
  const form = useForm({
    defaultValues: {
      phoneNumber: phoneNumber,
      authCode: '',
    },
    mode: 'onChange',
  });
  const mainDom = (
    <form
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      {children}
    </form>
  );
  return formExists ? (
    mainDom
  ) : (
    <Box
      sx={{
        '& form': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        },
      }}
    >
      <FormProvider {...form}>{mainDom}</FormProvider>
    </Box>
  );
};
export default ShellLoginWithPhone;
