import styles from "./styleLoginWithPhone.module.scss";
import ShellLoginWithPhone from "./ShellLoginWithPhone";
import PhoneStep from "./steps/PhoneStep";
import CodeStep from "./steps/CodeStep";
import { useSelector } from "react-redux";
import { selectIsCodeSent } from "../../store/authCodeSlice";
import { Divider } from "@mui/material";

const LoginWithPhoneContent = (props) => {
  const { onlyCode, overridedOnConfirm, afterSubmit } = props;
  const isCodeSent = useSelector(selectIsCodeSent);
  return (
    <ShellLoginWithPhone formExists={onlyCode}>
      <div className={styles.steps_container}>
        {!onlyCode && <PhoneStep />}
        {!onlyCode && isCodeSent && (
          <Divider
            sx={{
              width: "100%",
              marginTop: { xs: "14px", md: "18px" },
              borderColor: "#707070",
            }}
          />
        )}
        {(onlyCode || isCodeSent) && (
          <CodeStep
            overridedOnConfirm={overridedOnConfirm}
            afterSubmit={afterSubmit}
          />
        )}
      </div>
    </ShellLoginWithPhone>
  );
};
export default LoginWithPhoneContent;
