import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAllServices,
  setAllServices,
  setCurrentStep,
} from '../../../../../../../store/mainSlice';
import { useEffect, useMemo, useState } from 'react';
import { debounce } from '@mui/material';
export const useAutoCompleteStepLogic = ({ name, nextStep, rules }) => {
  const { watch, formState, clearErrors } = useFormContext();
  const [isSkip, setIsSkip] = useState(false);
  const dispatch = useDispatch();
  const { currentStep, allServices } = useSelector((state) => state.main);
  const goToStep = (step) => {
    if (!step) return;
    dispatch(setCurrentStep(step));
  };
  const currentValue = watch(name);
  const onSkip = () => {
    if (formState.errors[`${name}`]) {
      clearErrors(name);
    }
    if (isSkip) return;
    setIsSkip(true);
    if (!nextStep || currentStep >= nextStep) return;
    goToStep(nextStep);
  };
  const _onChange = (e, field) => {
    if (isSkip) setIsSkip(false);
    const value = e.target.value;
    field.onChange(value);
    if (currentStep >= nextStep) return;
    goToStep(nextStep);
  };
  const onChangeInput = useMemo((e, field) => debounce(_onChange, 300), [_onChange]);
  useEffect(() => {
    if (!currentValue) {
      dispatch(setAllServices([]));
      return;
    }
    if (typeof currentValue === 'object') {
      dispatch(fetchAllServices({ name: currentValue.label }));
      return;
    }
    dispatch(fetchAllServices({ name: currentValue }));
  }, [currentValue]);
  const getRules = () => {
    //rules or reset all rules
    return isSkip ? Object.fromEntries(Object.keys(rules).map((key) => [key, undefined])) : rules;
  };
  return {
    getRules,
    onChangeInput,
    onSkip,
    allServices,
  };
};
