
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';


import translationsEN from './i18n/en.json'
import translationsHE from './i18n/he.json'

const resources = {
  en: {
    translation: translationsEN
  },
  he: {
    translation: translationsHE
  }
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    lng: 'he',
    fallbackLng: 'en',
    keySeparator: false, // we do not use keys in such form as 'messages.welcome'
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

  export default i18n;