import { Autocomplete, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

const CustomAutoComplete = (props) => {
  const {
    name,
    rules,
    sx,
    autoCompleteProps,
    onChangeInput,
    options,
    loading,
    placeholder,
    inputProps,
  } = props;
  return (
    <Controller
      name={name}
      rules={rules}
      render={({ field, fieldState }) => (
        <Autocomplete
          onChange={(e, value) => {
            field.onChange(value);
          }}
          value={field.value}
          {...autoCompleteProps}
          options={options}
          loading={!!loading ? loading : undefined}
          sx={{
            '.MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
              right: 'unset ',
              left: '9px',
              top: '50%',
              transform: 'translate(0,-50%)',
            },
            ...sx,
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              {...field}
              onChange={(e) => {
                onChangeInput && onChangeInput(e, field);
              }}
              {...inputProps}
              placeholder={!!placeholder ? placeholder : ''}
              error={fieldState.error ? Object.keys(fieldState.error).length !== 0 : false}
              helperText={fieldState.error ? fieldState.error.message : undefined}
            />
          )}
        />
      )}
    />
  );
};
export default CustomAutoComplete;
