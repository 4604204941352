import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import mainImg from '../../../../assets/images/homePage/infoSuppliers/image2_dt.webp';
import mobileImg from '../../../../assets/images/homePage/infoSuppliers/image2_mobile.webp';
import RowAdvantages from './RowAdvantages';
import styles from './styleForSuppliers.module.scss';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setIsOpenSupplierAuth } from '../../../store/mainSlice';

const InfoForSuppliers = () => {
  const isMobile = useMediaQuery('(max-width:900px)');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const redirectToRegSupp = () => {
    navigate(`/supplier-registration`);
    dispatch(setIsOpenSupplierAuth(false));
  };
  return (
    <section id="info-for-suppliers" className={styles.container}>
      <div className={styles.img_container}>
        <img loading="lazy" src={!isMobile ? mainImg : mobileImg} alt="img-for-info-suppliers" />
      </div>
      <div className={styles.text_container}>
        <div className={styles.text_block}>
          <Typography className={styles.text_title}>
            {isMobile
              ? 'ספקים! בואו למלא את היומן שלכם באירועים אמיתיים'
              : 'ספקים? בואו למלא את היומן שלכם באירועים אמיתיים במקום בפגישות מיותרות'}

            <Typography component={'span'} className={styles.text_title_additional}>
              {' '}
              ולא בפגישות מיותרות
            </Typography>
          </Typography>
          <RowAdvantages />
          <Typography className={styles.text_descriprion}>
            הפלטפורמה מאפשרת לך להציע את שירותיך גם בסוגי אירועים שעד עתה לא נהנית מהם. כך, ע”י
            חשיפה
            <br />
            קבועה ללקוחות מתעניינים, אנו יוצרים עבודה רציפה בתחום עונתי. תהיה מוכן לעולמות חדשים.
          </Typography>
          <div className={styles.button_container}>
            <Button
              onClick={redirectToRegSupp}
              sx={{ height: { xs: '45px', md: '51px' }, maxWidth: { xs: '130px', md: '152px' } }}
              variant="contained"
            >
              הרשמה כספק
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InfoForSuppliers;
