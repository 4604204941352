import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import styles from "./ShowMoreText.module.css";

const defaultHeight = window.innerWidth > 640 ? 70 : 63;
const ToggleButton = ({ isExpanded, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={classNames(styles.btnToggle, {
        [styles.noDispaly]: isExpanded,
      })}
    >
      קרא עוד
    </button>
  );
};

const ShowMoreText = ({ text }) => {
  const [heightCurrent, setHeightCurrent] = useState(defaultHeight);
  const [heightMax, setHeightMax] = useState(defaultHeight);
  const [heightMin, setHeightMin] = useState(defaultHeight);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);

  const textDisplayRef = useRef();

  useEffect(() => {
    const element = textDisplayRef.current;
    const heightClient = element?.clientHeight || defaultHeight;
    const scrollClient = element?.scrollHeight || defaultHeight;
    if (heightClient !== scrollClient) {
      setIsOverflow(true);
      setHeightMax(scrollClient);
      setHeightMin(heightClient);
      setHeightCurrent(heightClient);
    }
  }, [text]);

  const handleClickBtn = () => {
    setHeightCurrent(isExpanded ? heightMin : heightMax);
    setIsExpanded((prev) => !prev);
  };
  return (
    <div className={styles.root}>
      <div
        ref={textDisplayRef}
        className={classNames(styles.textDisplay, {
          [styles.expanded]: isExpanded,
          [styles.collapsed]: !isExpanded,
        })}
        style={{ height: `${heightCurrent}px` }}
        dangerouslySetInnerHTML={{ __html: text }}
      ></div>
      {isOverflow && (
        <div className={styles.showMoreContainer}>
          <ToggleButton isExpanded={isExpanded} onClick={handleClickBtn} />
        </div>
      )}      
    </div>
  );
};

export default ShowMoreText;
