import { useState, useEffect } from "react"
import { Helmet } from "react-helmet-async";
//import img from "../../../assets/images/for-pages/icon_tag.jpeg";

const MetaSuppliers = () => {
    const [ icon,setIcon ]=useState(sessionStorage.getItem('url'))

    useEffect(()=>{
        setIcon(sessionStorage.getItem('url'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[sessionStorage.getItem('url')])

    return(
        <Helmet>
        <meta property="og:image"  content={process.env.REACT_APP_API_PATH + 'static/media/eventDo.2021aadd1ab23ff25c69.jpeg'}></meta>
        <meta charSet="utf-8" />
        <title> להפיק בקליק! - EventDO </title><meta property="og:site_name" content="להפיק בקליק! - EventDO"></meta>
        <meta property="og:url" content={window.location.href}></meta>
        <link rel="canonical" href="http://mysite.com/example" />
        <link rel="shortcut icon" href={icon} />
        </Helmet>
    )
}
export default MetaSuppliers;
