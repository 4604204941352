import styles from "./Favorites.module.scss";
import classNames from "classnames";
import {useDispatch} from "react-redux";
import {
  changeFavorite,
  getCategories,
  getSupplersByFilter,
} from "../../store/personalUser/favoritesUserSlice";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {setLoader} from "../../store/loaderSlice";
import {useEffect, useLayoutEffect, useRef, useState} from "react";
import SupplierCard from "./supplierCard/SupplierCard";
import Select from "../../components/selectComponent/Select";
import {setErr} from "../../store/forAll/errSlice";
import MetaFavicon from '../../components/metaComponents/metaFavicon'
import { setSelectedEventTypeId } from "../../store/suppliersSlice";

const Favorites = () => {
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const [selectCategory, setSelectCategory] = useState({});
  const [allCategories, setAllCategories] = useState([]);
  const [allSuppliers, setAllSuppliers] = useState([]);
  const [isCallApi, setIsCallApi] = useState(false);
  const [curentlPage, setCurentlPage] = useState(0);
  const [totalSize, setTotalSize] = useState(10);
  const [isScroll, setIsScroll] = useState(false);
  const [e1, setE1] = useState();
  const [e2, setE2] = useState();
  const ref1 = useRef(null);

  function clearApiData() {
    setIsCallApi(false);
    setCurentlPage(0);
  }

  const goScroll = (e) => {
    if (e) {
      setIsScroll(true);
      setE1(e);
    }
  };

  const getAllCategories = () => {
    dispatch(getCategories({axiosPrivate})).then((res) => {
      if (res?.error) {
        dispatch(
          setErr({
            message: res?.payload?.response?.data?.message,
            code: res?.payload?.response?.data?.code,
          })
        );
      } else {
        setAllCategories(res.payload);
      }
    });
  };

  const getSuppliers = (page1) => {
    if (!page1) {
      setCurentlPage(0);
    }
    let page = page1 || 0;
    let size = 10;
    let categoryId = selectCategory?.id || 0;

    dispatch(getSupplersByFilter({page, size, categoryId, axiosPrivate}))
      .then((suppliers) => {
        setTotalSize(suppliers?.payload?.totalElements);
        // setTotalPages(suppliers?.payload?.totalPages);

        // let newSuppliers = [...allSuppliers, ...suppliers?.payload?.content];
        setAllSuppliers(
          page
            ? [...allSuppliers, ...suppliers?.payload?.content]
            : suppliers?.payload?.content
        );
      })
      .finally(() => {
        setIsCallApi(false);
        dispatch(setLoader(false));
        setIsScroll(false);
      });
  };

  const addToFavourite = (event, supplierId) => {
    event.stopPropagation();
    dispatch(changeFavorite({axiosPrivate, supplierId})).then((res) => {
      if (res?.error) {
        dispatch(
          setErr({
            message: res?.payload?.response?.data?.message,
            code: res?.payload?.response?.data?.code,
          })
        );
      } else {
        ref1.current.scrollIntoView();
        dispatch(setLoader(true));
        getSuppliers();
        getAllCategories();
      }
    });
  };

  useEffect(() => {
    dispatch(setLoader(true));
    clearApiData();
    // size
    // page = 0;
    getSuppliers();
    getAllCategories();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectCategory]);

  useEffect(() => {
    dispatch(setSelectedEventTypeId(0));
    let e22 = e2;
    let e0 = e1;
    if (isScroll && !!e0.timeStamp) {
      
      let e = e0;

      if (
        !e22 ||
        !e22.timeStamp ||
        e0?.timeStamp?.toFixed(0) !== e22?.timeStamp?.toFixed(0)
      ) {
        setE2({...e});
        if (!isCallApi) {
          const tableViewHeight = e.target.offsetHeight;
          const tableScrollHeight = e.target.scrollHeight;
          const scrollLocation = e.target.scrollTop;
          const buffer = 300;
          const limit = tableScrollHeight - tableViewHeight - buffer;
          if (
            !isCallApi &&
            scrollLocation >= limit &&
            allSuppliers &&
            allSuppliers.length < totalSize
          ) {
            setIsCallApi(true);
            dispatch(setLoader(true));
            let page = curentlPage + 1;
            setCurentlPage(page);

            getSuppliers(page);
            // setTimeout(() => {
            //   console.log("get")
            //   setIsScroll(false);
            //   setIsCallApi(true);
            // },3000)
          } else {
            setIsScroll(false);
          }
        }
      } else {
        setIsScroll(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScroll]);

  useLayoutEffect(() => {
    let elem = document.getElementById("samiRoot");

    elem.addEventListener("scroll", goScroll);

    return () => {
      elem.removeEventListener("scroll", goScroll);
    };
  }, []);

  return (
    <>
    <MetaFavicon />
    <div ref={ref1} className={styles.favoritesWrapper}>
      <div className={classNames(styles.mobileTitle, styles.mobile)}>
        {"ספקים שאהבתי"}
      </div>
      <div className={styles.dropWrapper}>
      {/*eslint-disable-next-line*/}
        <div className={styles.dropTitle}>{"בחר\\י סוג שירות"}</div>
        <div className={styles.selector}>
          <Select
            fontSize={"1rem"}
            paddingInlineStart={"0.9375rem"}
            paddingInlineEnd={"0.625rem"}
            placeholder={" "}
            width={"100%"}
            // borderRadius={"0.31rem"}
            backgroundColor={"#EEE9E5"}
            color={"#242323"}
            options={
              [...allCategories,{id: 0, name: "כל הספקים"}]
              // [
              //   { id: 1, name: "first" },
              //   { id: 2, name: "second" },
              //   { id: 3, name: "third" },
              //   { id: 4, name: "fourth" },
              // ]
            }
            onChangeOption={(option) => {
              setSelectCategory(option);
            }}
            selectedOption={selectCategory}
          />
        </div>
      </div>
      <div className={styles.allSuppliers}>
        {allSuppliers?.map((supplier, index) => {
          return (
            <div
              // style={{ height: isShowPlaces ? "25.125rem" : "" }}
              key={supplier.type + " " + supplier.id + index}
              // onClick={() => navigate(`/supplier/${supplier.id}`)}
              className={styles.supplierCard}
            >
              <SupplierCard
                selectCategory={selectCategory}
                supplier={supplier}
                addToFavourite={addToFavourite}
              />
            </div>
          );
        })}
      </div>
    </div>
  </>
  );
};

export default Favorites;
