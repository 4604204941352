import { createSlice } from "@reduxjs/toolkit";

const chatSlice = createSlice({
  name: "chats",
  initialState: {
    currentEvent: {id:0, name: ''},
    isNeedPlace: false,
    allEvents: [],
    error: null,
    loadingStatus: null,
    allRecommended: [],
  },
  reducers: {
    setEventOnChats(state, action) {
      state.currentEvent = {...action.payload.currentEvent};
    },
  },
  extraReducers: {}
});

export const { setEventOnChats } = chatSlice.actions;

export default chatSlice.reducer;
