import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import classNames from 'classnames';
import styles from './styleCards.module.scss';
import useAuth from '../../../../hooks/useAuth';
import RatingStar from '../../../../components/ratingStar/RatingStar';
import { setSelectedCategoryId, setSelectedEventTypeId } from '../../../../store/suppliersSlice';
import { setIsOpenMobleAuth } from '../../../../store/mainSlice';
import activeLike from '../../../../../assets/images/like/activeLike.svg';
import inactiveLike from '../../../../../assets/images/like/inactive.svg';

const Card = (props) => {
  const { supplier, addToFavourite } = props;
  const { isFlashSelected } = useSelector((state) => state.suppliers);
  const [isLiked, setIsLiked] = useState(supplier.favorite);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { currentEvent, currentSupplierEvent,  } = useSelector((state) => state.main);
  let [redirectSupplierUrl, setRedirectSupplierUrl] = useState();
  
  const { auth } = useAuth();
  let currentRole = auth?.roles?.length ? auth?.roles[0] : '';
  const redirectTo = (e) => {
    e.preventDefault()
    dispatch(
      setSelectedCategoryId({
        id: props?.supplier?.categoryId || 0,
        name: props?.supplier?.categoryName || '',
      })
    );
    dispatch(setSelectedEventTypeId(currentSupplierEvent?.id || currentEvent?.id));
    navigate(
      redirectSupplierUrl
    );
  };

  useEffect(() => {
    const eventId = currentSupplierEvent?.id || currentEvent?.id;
    const eventName = encodeURIComponent(currentSupplierEvent?.name || currentEvent?.name);
    const categoryId = props?.supplier?.categoryId;
    const categoryName = encodeURIComponent(props?.supplier?.categoryName);
  
    const params = [];
    
    if (eventId) params.push(`eventId=${eventId}`);
    if (categoryName) params.push(`categoryName=${categoryName}`);
    if (categoryId) params.push(`categoryId=${categoryId}`);
    if (eventName) params.push(`eventName=${eventName}`);
  
    const queryString = params.length > 0 ? `?${params.join('&')}` : '';
    
    setRedirectSupplierUrl(`/supplier/${supplier.id}${queryString}`);
  }, [currentEvent, currentSupplierEvent, props]);

  return (
    <section className={styles.cardWrapper}>
      {isFlashSelected && (
        <div className={styles.flashLabel}>
          <span>{props?.supplier?.discount || 0}%</span>
          <span>הנחה</span>
        </div>
      )}
      {currentRole !== 'SUPPLIER' && currentRole !== 'LABEL' && (
        <div
          onClick={(event) => {
            if (currentRole === '') {
              dispatch(setIsOpenMobleAuth(true));
            } else {
              addToFavourite(event, supplier.id, supplier.favorite);
              setIsLiked((v) => !v);
            }
          }}
          className={classNames(styles.heart, 'like_prov')}
        >
          {isLiked ? (
            <img src={activeLike} alt="activeLike" />
          ) : (
            <img src={inactiveLike} alt="activeLike" />
          )}
        </div>
      )}

      <a href={redirectSupplierUrl}
        onClick={(e) => redirectTo(e)} className={styles.image}>
        {supplier.imageUrl !== null && (
          <img
            loading="lazy"
            src={supplier.imageUrl}
            className={styles.slideImage}
            alt="supplier-image"
            onClick={() => {}}
          />
        )}
        {supplier.imageUrl === null && (
          <img
            style={{ objectFit: 'none' }}
            src="/static/media/logo.8915ebd25d310c92475d.png"
            className={styles.slideImage}
            alt=""
            loading="lazy"
            onClick={() => {}}
          />
        )}
      </a>
      <div className={styles.description}>
        <a href={redirectSupplierUrl}
         onClick={(e) => redirectTo(e)} className={styles.name}>
          {supplier.name}
        </a>

        <div className={styles.rate_container}>
          <div className={styles.rating}>
            <RatingStar rating={supplier.rating} widthM="1.4rem" widthD="1.4rem" gapD="0.6rem" />
          </div>
          <span className={styles.count}>{`${supplier.amountOfReviews} חוות דעת `}</span>
        </div>
      </div>
    </section>
  );
};

export default Card;
