import styles from '../../styleSuppliers.module.scss';
import { useFormContext } from 'react-hook-form';
import CategoryFilter from './CategoryFilter';
import { useGetData } from './useGetData';
import CircularProgress from '@mui/material/CircularProgress';
const CategoryFilters = () => {
  const { watch, setValue } = useFormContext();
  const { categoryFilters, categoryFiltersLoading } = useGetData();
  const currentCategoryFiltersId = watch('categoryFiltersId');
  const setNewValue = (value) => {
    setValue('categoryFiltersId', value);
  };
  return (
    <section className={styles.category_filters_container} id="type_filters">
      {categoryFiltersLoading ? (
        <div style={{ color: '#E7B1AD' }}>
          <CircularProgress color="inherit" size={40} />
        </div>
      ) : (
        categoryFilters?.map((oneFilter) => (
          <CategoryFilter
            setValue={setNewValue}
            allItems={currentCategoryFiltersId}
            key={`category-filter-${oneFilter.id}`}
            item={oneFilter}
          />
        ))
      )}
    </section>
  );
};
export default CategoryFilters;
