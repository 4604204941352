import Header from "../../components/header/Header";
import CategorySwiper from "../../components/categorySwiper/CategorySwiper";
import SearchingField from "../../components/searchingField/searchingField";
import styles from "./Supplier.module.scss";
import SupplierImageWithLink from "../../components/supplierImageWithLink/SupplierImageWithLink";
import SupplierDescription from "../../components/supplierDescription/SupplierDescription";
import ServiceTable from "../../components/serviceTable/ServiceTable";
import Footer from "../../components/footer/Footer";
import {useEffect, useState, useRef} from "react";
import axios from "axios";
import PhotoSlider from "./photoSlider/PhotoSlider";
import HeaderMobile from "../../components/headerMobile/HeaderMobile";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentCategory, setCurrentSearch} from "../../store/mainSlice";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import RatingStar from "../../components/ratingStar/RatingStar";
import {getFeedbacks, getVideos, saveFeedback} from "../../store/supplierSlice";
import {setErr} from "../../store/forAll/errSlice";
import {Controller, useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import classNames from "classnames";
import useAuth from "../../hooks/useAuth";
import dayjs from "dayjs";
import close from "../../../assets/images/for-pages/icons/closeDialog.png";
import he from "dayjs/locale/he"; // load on demand
import localizedFormat from "dayjs/plugin/localizedFormat";
import MetaSupplier from "../../components/metaComponents/metaSupplier";
import PopUp from "../../components/defaultPopUp/PopUp";
import Dialog from "@mui/material/Dialog";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import {TEMP_USER_KEY} from "../../consts/local_keys";
import ChecklistButton from "../../components/checklistButton/checklistButton";
import WhatsApp from "../../components/whatsApp/whatsApp";
import { setSelectedCategoryId, setSelectedEventTypeId } from "../../store/suppliersSlice";

function Supplier() {
  dayjs.locale(he);
  dayjs.extend(localizedFormat);
  const [tab, setTab] = useState(1);

  const schema = yup.object().shape({
    userName: yup
      .string()
      .min(2, "לפחות 2 אותיות") // min char 2
      .required("הכנס שם משתמש"), //name required

    feedback: yup.string().required("שדה חובה"),
  });

  const {
    register,

    handleSubmit,
    formState: {errors},
    reset,
    getValues,
    control,
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });
  const {auth} = useAuth();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  // const [firstLoading, setFirstLoading] = useState(true);
  const [supplierPreview, setSupplierPreview] = useState({});
  const [selectCategory, setSelectCategory] = useState({});
  let [isInProcess, setIsInProcess] = useState(false);
  const {selectedCategoryId, selectedEventTypeId} = useSelector(
    (state) => state.suppliers
  );
  const { suppliersFilters } = useSelector((state) => state.suppliers);
  const dispatch = useDispatch();
  const routeParams = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search)
  const supplierId = routeParams.id
  // let supplierId = routeParams.id;
  let [eventTypeId, seteventTypeId] = useState(0);
  let [eventName, seteventName] = useState("");
  let [categoryId, setcategoryId] = useState(1);
  let [isFavourite, setIsFavourite] = useState(false);
  let [isOpenDialog, setIsOpenDialog] = useState(false);
  let [allFeedback, setAllFeedback] = useState([]);
  let [allVideos, setAllVideos] = useState([]);
  let [rating, setRating] = useState(0);
  const commentRef = useRef(null);

  let [isOpenFeedbackDialog, setIsOpenFeedbackDialog] = useState(false);
  let [isShowErr, setIsShowErr] = useState(false);
  let [isShowPopup, setIsShowPopup] = useState(false);

  const countWords = (str) => {
    if (!str) return 200;
    return (
      200 -
      str?.split(" ").filter((num) => {
        return num !== "";
      }).length
    );
  };

  const getAllFeedbacks = () => {
    dispatch(getFeedbacks({supplierId})).then((res) => {
      if (res?.error) {
        dispatch(
          setErr({
            message: res?.payload?.response?.data?.message,
            code: res?.payload?.response?.data?.code,
          })
        );
      } else {
        setAllFeedback(res.payload);
        //update info
      }
    });
  };

  const getAllVidoes = () => {
    dispatch(getVideos({supplierId})).then((res) => {
      if (res?.error) {
        dispatch(
          setErr({
            message: res?.payload?.response?.data?.message,
            code: res?.payload?.response?.data?.code,
          })
        );
      } else {
        // console.log(res.payload)
        setAllVideos(res.payload);
        //update info
      }
    });
  }

  const saveFeedback1 = () => {
    if (!rating) return;
    const {feedback, userName} = getValues();
    // console.log(rating);
    let sendObj = {
      eventTypeId: selectedEventTypeId,
      feedback,
      userName,
      rating,
      supplierId,
    };
    dispatch(saveFeedback({axiosPrivate, sendObj})).then((res) => {
      if (res?.error) {
        dispatch(
          setErr({
            message: res?.payload?.response?.data?.message,
            code: res?.payload?.response?.data?.code,
          })
        );
      } else {
        reset();
        getAllFeedbacks();
        setRating(0);
        setIsOpenFeedbackDialog((v) => !v);
        setIsShowPopup(true)
      }
    });
  };

  const changeCurentRate = (number) => {
    setRating(number);
  };

  useEffect(() => {
    //setDefault params
    let catId = searchParams.get('categoryId')
    let evId = searchParams.get('eventId')
    if(!routeParams?.id)return
    dispatch(
      setSelectedCategoryId({
        id: catId || 0,
        name: searchParams.get('categoryName') || '',
      })
    );
    dispatch(setSelectedEventTypeId(searchParams.get('eventId')));

    let div = document.getElementsByClassName('samyBody');
    div[0].scrollTop = 0
    // window.location.pathname.slice(1);
    const fetchData = async () => {
      let ax = auth?.roles?.[0] === "USER" ? axiosPrivate : axios

      const result = auth?.roles?.[0] !== "USER" ? await ax(
        `${process.env.REACT_APP_API_PATH}supplier/profile/${
          supplierId
        }/preview?categoryId=${catId || selectedCategoryId?.id || 0}&eventTypeId=${
          evId || selectedEventTypeId || 0
        }&userToken=${localStorage.getItem(TEMP_USER_KEY)}${suppliersFilters?.eventDate ? `&date=${suppliersFilters.eventDate}` : ''}`
      ) :
      await ax(
        `${process.env.REACT_APP_API_PATH}supplier/profile/${
          supplierId
        }/preview?categoryId=${catId || selectedCategoryId?.id || 0}&eventTypeId=${
          evId || selectedEventTypeId || 0
        }${suppliersFilters?.eventDate ? `&date=${suppliersFilters.eventDate}` : ''}`
      );

      seteventName(result.data.services?.eventName || result.data.venueServices?.eventName);
      seteventTypeId(result.data.services?.eventId || result.data.venueServices?.eventId);
      setcategoryId(result.data.services?.categoryId || result.data.venueServices?.categoryId);
      setSupplierPreview(result.data);
      // console.log(result.data.categories)
      // console.log(selectedCategoryId)
      // console.log(result.data?.services?.categoryId)
        let newCategory = result.data.categories.find((item) => {
          // console.log(item.id === result.data?.services?.categoryId,item.id === selectedCategoryId.id )
          return (
            item.id == catId ||
            item.id === selectedCategoryId.id ||
            item.id === result.data?.services?.categoryId ||
            item.id === result.data?.venueServices?.categoryId
          );
        })
      if(newCategory){
        setSelectCategory(
          newCategory
        );
      }
    };
      fetchData()
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeParams.id]);

  const getFavourite = async () => {
    try {
      const response = await axiosPrivate.get(`/user/favorite/supplier`);

      if (response.status !== 200) {
        throw new Error("Server Error");
      }

      const result = response?.data?.content.findIndex(
        (supplier) => supplier.id === +supplierId
      );
      result === -1 ? setIsFavourite(false) : setIsFavourite(true);
      setIsInProcess(false);
    } catch (error) {
      // console.log(error);
      setIsInProcess(false);
    }
  };

  useEffect(() => {
    getFavourite();
    getAllFeedbacks();
    getAllVidoes();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addRemoveFavourite = async () => {
    if (!isFavourite && !isInProcess) {
      setIsInProcess(true);
      try {
        const response = await axiosPrivate.post(
          `/user/favorite/supplier?supplierId=${+supplierId}`
        );

        if (response.status !== 200) {
          throw new Error("Server Error");
        }

        getFavourite();
      } catch (error) {
        // console.log(error);
        setIsInProcess(false);
      }
    }

    if (isFavourite && !isInProcess) {
      setIsInProcess(true);
      try {
        const response = await axiosPrivate.delete(
          `/user/favorite/supplier?supplierId=${+supplierId}`
        );

        if (response.status !== 200) {
          throw new Error("Server Error");
        }

        getFavourite();
      } catch (error) {
        // console.log(error);
        setIsInProcess(false);
      }
    }
  };

  useEffect(() => {
    if(!selectCategory.id)return
    if(selectCategory?.isFromEffect)return
    const fetch = async () => {
      let ax = auth?.roles?.[0] === "USER" ? axiosPrivate : axios
      const result =  auth?.roles?.[0] !== "USER" ? await ax.get(
        `${process.env.REACT_APP_API_PATH}supplier/profile/${
          supplierId
        }/preview?categoryId=${selectCategory?.id || 0}&eventTypeId=${
          selectedEventTypeId || 0
        }&userToken=${localStorage.getItem(TEMP_USER_KEY)}${suppliersFilters?.eventDate ? `&date=${suppliersFilters.eventDate}` : ''}`
      ) :
      await ax.get(
        `${process.env.REACT_APP_API_PATH}supplier/profile/${
          supplierId
        }/preview?categoryId=${selectCategory?.id || 0}&eventTypeId=${
          selectedEventTypeId || 0
        }${suppliersFilters?.eventDate ? `&date=${suppliersFilters.eventDate}` : ''}`
      );
      setSupplierPreview(result.data);
      return result;
    };
    fetch().catch((error) => {
      //redirect if no categoriy in supplier
      if (error.response.data.code === 1410) {
        navigate("/suppliers");
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectCategory.id]);

  function selectedCategory(ev) {
    dispatch(
      setCurrentCategory({
        currentCategory: ev,
      })
    );
    navigate("/suppliers");
  }

  function searchSupplier(ev) {
    dispatch(
      setCurrentSearch({
        currentSearch: ev,
      })
    );
    navigate("/suppliers");
  }
  
  function goToComments() {
    commentRef.current.scrollIntoView();
  }

  return (
    <>
    {/* <WhatsApp/> */}
    <ChecklistButton/>
    <PopUp
      isOpen={isOpenDialog}
      setIsOpen={setIsOpenDialog}
      message={"לא ניתן להשאיר חוות דעת עם לא שולמה מקדמה או לא נחתם חוזה"}
    />
    <MetaSupplier supName={supplierPreview?.businessName} description={supplierPreview?.businessDescription} />
      <Header />
      <HeaderMobile />
      <section className={styles.swiperAndSearchFieldContainer}>
        {supplierPreview.categories?.length && (
          <CategorySwiper
            categories={supplierPreview.categories}
            currentCategory={selectCategory}
            selectedCategory={(ev) => selectedCategory(ev)}
          />
        )}
        {/* <SearchingField
          category={selectCategory}
          searchHandler={searchSupplier}
        /> */}
      </section>
      <h1 className={styles.categoryNameFrame}>

        {supplierPreview?.services?.categoryName || supplierPreview?.venueServices?.categoryName}
      </h1>
      <section className={styles.supplierBlock}>
        <div className={styles.tablePart}>
          <SupplierDescription
            businessName={supplierPreview.businessName}
            businessDescription={supplierPreview.businessDescription}
            regions={supplierPreview.regions}
            businessAddr={supplierPreview.businessAddress}
            phoneNumber={supplierPreview.phoneNumber}
          />
          {!!searchParams.get('eventName') && <h1 style={{marginRight:"0"}} className={styles.categoryNameFrame}>
            {searchParams.get('eventName')}
          </h1>}
          <ServiceTable
            selectCategory={selectCategory}
            supplierName={supplierPreview.businessName}
            venueServices={supplierPreview?.venueServices}
            isShowPrices={!!selectedEventTypeId}
            eventTypeId={eventTypeId}
            eventName={eventName}
            categoryId={categoryId}
            services={supplierPreview?.services?.services}
            isFavourite={isFavourite}
            addRemoveFavourite={addRemoveFavourite}
          />
        </div>
        <div className={styles.supplierImageWithLinkContainer}>
          {supplierPreview && (
            <SupplierImageWithLink
              defaultImageUrl={supplierPreview.defaultImageUrl} link={goToComments}
            />
          )}
        </div>
      </section>
      <section className={styles.tabs}>
        <h2
          onClick={() => {
            setTab(1);
          }}
          className={styles.tab}
          style={{backgroundColor: tab === 1 ? "#EBD9CD" : ""}}
        >
          {"גלריית תמונות"}
        </h2>
        { !!allVideos?.length &&<h2
          onClick={() => {
            setTab(2);
          }}
          className={styles.tab}
          style={{backgroundColor: tab === 2 ? "#EBD9CD" : ""}}
        >
          {"וידאו"}
        </h2>}
      </section>
      {
        tab === 2 ?
        allVideos && (
          <PhotoSlider slides={allVideos} isVideo={true} />
        )
        :

        supplierPreview.images && (
          <PhotoSlider slides={supplierPreview.images} />
        )
      }
      
      <section>
        <Dialog
          open={isShowPopup}
          onClose={setIsShowPopup}
          >
          <DialogContent>
            <DialogContentText>{"תודה על הוספת הביקורת!"}</DialogContentText>
            <DialogContentText>{"החוויה שלך חשובה לנו 😊"}</DialogContentText>
          </DialogContent>
            <DialogActions sx={{
                display: 'flex',
                justifyContent: 'center',
              }}>
            <Button onClick={()=>setIsShowPopup(false)}>
              המשך       
            </Button>
          </DialogActions>
        </Dialog>
      </section>

      <section className={styles.beforeFooter}>
        {isOpenFeedbackDialog && (
          <div className={styles.addFeedbackDialog}>
            <div className={styles.closeDialog}>
              <img
                onClick={() => {
                  setIsOpenFeedbackDialog((v) => !v);
                  // setIsShowErr(false);
                }}
                alt="logoImage"
                src={close}
              />
            </div>
            <form
              noValidate
              className={styles.feedbackFrom} 
              onSubmit={handleSubmit(saveFeedback1)}
            >
              <h3 className={styles.formTitle}>
                <span>{" הוספת חוות דעת עבור "}</span>
                <span>{supplierPreview?.businessName}</span>
              </h3>
              <div className={styles.inputs}>
                <div className={styles.inputContainer}>
                  <input
                    type="text"
                    placeholder={"שם מלא"}
                    {...register("userName")}
                    className={classNames({
                      [styles.invalidField]: errors.userName,
                    })}
                  />
                  {errors.userName && (
                    <p className={styles.errorMessage}>
                      {errors.userName.message}
                    </p>
                  )}
                </div>
                <div
                  className={classNames(
                    {
                      [styles.invalidField1]: (!!!rating && isShowErr),
                    },
                    styles.inputContainer
                  )}
                  style={{display: "flex", alignItems: "center"}}
                  // className={styles.inputContainer}
                >
                  <span style={{color: "#9B9999"}}>{"דירוג"}</span>
                  <RatingStar
                    rating={rating}
                    widthM={"1.07875rem"}
                    widthD={"2.35625rem"}
                    gapM={"0.15rem"}
                    gapD={"0.31875rem"}
                    changeRate={changeCurentRate}
                  />
                  {(!!!rating && isShowErr) &&  (
                    <p className={styles.errorMessage}>{"שדה חובה"}</p>
                  )}
                </div>
              </div>
              <div className={styles.textAreaContainer}>
                <Controller
                  name="feedback"
                  control={control}
                  render={({field: {onChange, value}}) => (
                    <>
                      <textarea
                        // {...field}
                        className={classNames(
                          {
                            [styles.invalidField]: errors.feedback,
                          },
                          styles.desctop1
                        )}
                        error={!!errors.feedback}
                        required
                        placeholder={"מלל חופשי (עד 200 מילים)"}
                        value={value}
                        type="text"
                        id="feedback"
                        variant="outlined"
                        onChange={(e) => {
                          if (countWords(e.target.value) <= 0) return;
                          return onChange(e.target.value);
                        }}
                      />
                      <textarea
                        // {...field}
                        className={classNames(
                          {
                            [styles.invalidField]: errors.feedback,
                          },
                          styles.mobile
                        )}
                        error={!!errors.feedback}
                        required
                        placeholder={"מלל חופשי"}
                        value={value}
                        type="text"
                        id="feedback"
                        variant="outlined"
                        onChange={(e) => {
                          if (countWords(e.target.value) <= 0) return;
                          return onChange(e.target.value);
                        }}
                      />
                      {!errors.feedback && (
                        <p
                          style={{bottom: "0.45rem", color: "black"}}
                          className={styles.errorMessage}
                        >
                          {countWords(value)}
                        </p>
                      )}
                    </>
                  )}
                />

                {errors.feedback && (
                  <p
                    style={{bottom: "0.45rem"}}
                    className={styles.errorMessage}
                  >
                    {errors.feedback.message}
                  </p>
                )}
              </div>
              <div className={styles.buttons}>
                <button
                  className={styles.save}
                  onClick={() => setIsShowErr(true)}
                  // onClick={ (e) => {handleSubmit(saveFeedback1())}}
                  type="submit"
                >
                  {"הוסף חוות דעת"}
                </button>
              </div>
            </form>
          </div>
        )}

        <div className={styles.feedbackWrapper} ref={commentRef}>
          <div className={styles.feedbackTitle}>
            <h2 className={styles.fTitle}>{"חוות דעת"}</h2>
            <div className={styles.fRate}>
              <RatingStar
                rating={allFeedback.overallRating}
                colorFillM={"#F5B9AB"}
                widthM={"1.25rem"}
                widthD={"1.8125rem"}
                gapM={"0.16rem"}
                gapD={"0.25rem"}
              />

              <span>
                {allFeedback.amountOfReviews}
                {" חוות דעת "}
              </span>
            </div>
          </div>
          <div className={styles.allFeedback}>
            {/* <div className={styles.fContainer}> */}
            {allFeedback?.feedbacks?.map((feedback,i) => {
              return (
                <section key={`feedback-${i}`} className={styles.fContainer}>
                  <div className={styles.fContainerTitle}>
                    <span>{feedback.userName}</span>
                    <span>
                      {`${dayjs(feedback.date).format("DD/MM/YYYY")}`}
                    </span>
                    <RatingStar
                      rating={feedback.rating}
                      widthM={"1.07875rem"}
                      widthD={"1.8125rem"}
                      gapM={"0.15rem"}
                      gapD={"0.25rem"}
                    />
                  </div>
                  <div className={styles.fContainerDescription}>
                    {feedback.feedback}
                  </div>
                </section>
              );
            })}
            {/* </div> */}
          </div>
          {(auth?.roles?.[0] === "USER" && !!selectedEventTypeId) && (
            <div
              className={styles.linkAddFeedback}
              onClick={() => { 
                if(!supplierPreview?.hasSignedContract){
                  setIsOpenDialog(true)
                }else{
                  setIsOpenFeedbackDialog((v) => !v);
                }
              }}
            >
              {"הוספת חוות דעת"}
            </div>
          )}
        </div>
      </section>
      <section className={styles.desctop}>
        <Footer />
      </section>
    </>
    
  );
}

export default Supplier;
