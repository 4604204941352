import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
// import axios from "../../../api/axios";

export const getCategories = createAsyncThunk(
  "favoritesUser/getCategories",
  async function ({axiosPrivate}, {rejectWithValue}) {
    try {
      const response = await axiosPrivate.get(`/user/favorite/supplier/categories`);
      const data = await response.data;
      return data === undefined ? null : data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// let cancelToken;
export const getSupplersByFilter = createAsyncThunk(
  "favoritesUser/getSupplersByFilter",
  async function ({page, size, axiosPrivate, categoryId}, {rejectWithValue}) {
    try {
      //Check if there are any previous pending requests
      // if (cancelToken) {
      //   cancelToken.cancel("Operation canceled due to new request.");
      // }
      //Save the cancel token for the current request
      // cancelToken = axiosPrivate.CancelToken.source();
      const response = await axiosPrivate.get(
        `/user/favorite/supplier?categoryId=${categoryId || 0}&page=${page}&size=${size}&`
        // {cancelToken: cancelToken.token}
      );
      if (response.status !== 200) {
        throw new Error("Server Error");
      }
      return await response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const changeFavorite = createAsyncThunk(
  "favoritesUser/changeFavorite",
  async function ({supplierId, axiosPrivate}, {rejectWithValue}) {
    try {
      const response = await axiosPrivate.delete(`/user/favorite/supplier?supplierId=${supplierId}`);
      const data = await response.data;
      return data === undefined ? null : data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const favoritesUserSlice = createSlice({
  name: "favoritesUser",
  initialState: {
    error: null,
    loadingStatus: null,
  },
  reducers: {
    // setSelectedEvents(state,action) {
    //   state.selectedEvents = action.payload;
    // },
    // setFilterDate(state, action) {
    //   console.log(action.payload)
    //   state.filterDate = action.payload;
    // },
    // clearForm(state,action) {
    //   state.filterDate = '';
    //   state.selectedEvents=[];
    // },
  },
  extraReducers: {
    // [getAllEvents.fulfilled]: (state, action) => {
    //   console.log(action.payload)
    //   state.allEvents = action.payload;
    //   // state.email = action.payload.email;
    // },
    // [downLoadDocument.fulfilled]: (state, action) => {
    //   console.log("ok")
    // }
  },
});

export const {setFilterDate, clearForm, setSelectedEvents} =
  favoritesUserSlice.actions;

export default favoritesUserSlice.reducer;
