import { Box, TextField, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import styles from '../../../../styleBasicForm.module.scss';
import CustomDatePicker from '../../../../../../../components/customDatePicker/CustomDatePicker';
import { STEPS_ENUM } from '../../stepForm.config';
import { setCurrentStep } from '../../../../../../../store/mainSlice';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import calendarImg from '../../../../../../../../assets/images/datePicker/calendar.png';
import useAuth from '../../../../../../../hooks/useAuth';
import { useEffect, useRef } from 'react';
import CustomDateRangePicker from '../../../../../../../components/customDatePicker/CustomDateRangePicker';
const DateStep = (props) => {
  const {isExistsPlace, isOnlyDatePicker} = props
  const {watch} = useFormContext()
  const date = watch(isExistsPlace ? 'date' : 'dateRange')
  const { auth } = useAuth();
  const currentRole = Object.keys(auth).length !== 0 && auth?.roles?.length ? auth?.roles[0] : '';
  const dispatch = useDispatch();
  const { currentStep } = useSelector((state) => state.main);
  const refButtonDatePicker = useRef(null);
  const CurrentDatePicker = isExistsPlace ? CustomDatePicker : CustomDateRangePicker
  useEffect(() => {
    if(date && !isOnlyDatePicker) dispatch(setCurrentStep(currentStep))
  },[isExistsPlace])
  return (
    <div className={styles.one_step}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ marginLeft: '14px' }} className={styles.one_step_label}>
          בחר את תאריך האירוע
        </Typography>
        <Box
          onClick={() => {
            refButtonDatePicker?.current.click();
          }}
          className={styles.one_step_img}
          src={calendarImg}
          component={'img'}
          alt="datePicker-calendar-img"
        ></Box>
      </div>
      <div className={styles.field_container} style={{ flex: '1 0 auto' }}>
        <CurrentDatePicker
          refButton={refButtonDatePicker}
          withoutLogo
          name={isExistsPlace ? 'date' : 'dateRange'}
          inputSx={{
            '&.MuiTextField-root .MuiOutlinedInput-input': {
              padding: { xs: '0px 16px', sm: '0px 24px' },
              cursor: 'pointer',
            },
          }}
          title="תאריך האירוע"
          onChange={() => {
            if (currentStep === STEPS_ENUM.DATE_SELECTION && !isOnlyDatePicker)
              dispatch(setCurrentStep(isExistsPlace ? STEPS_ENUM.COUNT_GUESTS : !currentRole ? STEPS_ENUM.PHONE : STEPS_ENUM.SUBMIT ));
          }}
        />
      </div>
    </div>
  );
};
export default DateStep;
