import { configureStore } from "@reduxjs/toolkit";
import loaserSlice from "./loaderSlice";
import mainReducer from "./mainSlice";
import chatReducer from "./chatSlice";
import chatsReducer from "./chatsSlice";
import suppliersReducer from "./suppliersSlice";
import returnToUrlReducer from "./returnToUrlSlice";
import supplierRegistrationReducer from "./supplierRegistrationSlice";
import authSlice from "./authSlice";
import calendarSlice from "./calendarSlice";
import supplierDocumentSlice from "./personalSupplier/supplierDocumentSlice";
import checklistReducer from "./checkListSlice";
import errSlice from "./forAll/errSlice";
import changeSupplierSlice from "./personalSupplier/changeSupplierSlice";
import changeUserSlice from "./personalUser/changeUserSlice";
import supplierSlice from "./supplierSlice";
import lastPathSlice from "./forAll/lastPathSlice";
import authCodeSlice from "./authCodeSlice";
import featuresPopupSlice from './featuresPopup'

export default configureStore({
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware({
  //     serializableCheck: {
  //       // Ignore these action types
  //       ignoredActions: ['calendar/setAllInterval'],
  //       // Ignore these field paths in all actions
  //       ignoredActionPaths: ['calendar.allInterval.0.startDate'],
  //       // Ignore these paths in the state
  //       ignoredPaths: [`payload.0.startDate`],
  //     },
  //   }),
  reducer: {
    calendar: calendarSlice,
    auth: authSlice,
    loader: loaserSlice,
    main: mainReducer,
    suppliers: suppliersReducer,
    supplier: supplierSlice,
    returnToUrl: returnToUrlReducer,
    supplierRegistration: supplierRegistrationReducer,
    chat: chatReducer,
    chats: chatsReducer,
    supplierDocument: supplierDocumentSlice,
    checklist: checklistReducer,
    err: errSlice,
    lastPath: lastPathSlice,
    changeSupplier: changeSupplierSlice,
    changeUser: changeUserSlice,
    authCode: authCodeSlice,
    featuresPopup:featuresPopupSlice
  },
  devTools: process.env.NODE_ENV === "development",
});
