import { useEffect } from 'react';
import CustomChecBox from './CustomCheckBox';
import { Controller, useFormContext } from 'react-hook-form';
const CustomCheckBoxGroup = (props) => {
  const { options, multiple, variant, rules, fieldName, labelStyle } = props;
  const { setValue, watch, formState } = useFormContext();
  const currentValue = watch(fieldName);
  useEffect(() => {
    if (!Array.isArray(currentValue) && multiple) {
      setValue(fieldName, []);
      return;
    }
    if (Array.isArray(currentValue) && !multiple) {
      setValue(fieldName, null);
      return;
    }
  }, [multiple, fieldName]);
  return (
    <Controller
      name={fieldName}
      rules={rules}
      render={({ field: { value, onChange } }) => (
        <>
          {options?.map((option) => {
            const currentValue = Array.isArray(value)
              ? value?.includes(option.id)
              : option.id === value;
            return (
              <CustomChecBox
                isError={!!formState.errors[fieldName]}
                variant={variant}
                onChange={(event) => {
                  const newValue = option.id;
                  if (multiple) {
                    if(fieldName === "geoLocationsId"){
                      const updatedValues = value.includes(newValue)
                        ? value.filter((id) => id !== newValue)
                        : (
                            (newValue === "1" || newValue === 1) 
                              ? [newValue]
                              : [...value.filter((oneItem) => (oneItem !== "1" && oneItem !== 1)), newValue]
                          );
                      onChange(updatedValues);
                    } else {
                      const updatedValues = value.includes(newValue)
                        ? value.filter((id) => id !== newValue)
                        : [...value, newValue];
                      onChange(updatedValues);
                    }
                  } else {
                    const updatedValues = value === newValue ? null : newValue;
                    onChange(updatedValues);
                  }
                }}
                labelStyle={labelStyle}
                value={currentValue}
                name={fieldName}
                checked={currentValue}
                key={`option-${fieldName}-${option.id}`}
                label={option.name}
              />
            );
          })}
        </>
      )}
    />
  );
};
export default CustomCheckBoxGroup;
