// import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import RatingStar from "../../../components/ratingStar/RatingStar";
import styles from "./supplierCard.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedCategoryId, setSelectedEventTypeId } from "../../../store/suppliersSlice";
// import logo from "./../../../../assets/images/logo.png";

const SupplierCard = (props) => {
  const navigate = useNavigate();
const dispatch = useDispatch();
let { currentEvent,currentSupplierEvent  } = useSelector((state) => state.main);
  // console.log(props.supplier)

  const redirectTo = () => {
    dispatch(
      setSelectedCategoryId({
        id: props?.supplier?.categoryId || 0,
        name: props?.supplier?.categoryName || "",
      })
    );
    dispatch(setSelectedEventTypeId(currentSupplierEvent?.id || currentEvent?.id));
   
    navigate(`/supplier/${props.supplier.id}`);
  };

  return (
    <div className={styles.cardWrapper}>
      <div className={styles.category}>{props?.supplier?.categoryName}</div>
      <div
        onClick={(event) => props.addToFavourite(event, props.supplier.id)}
        className={styles.heart}
      >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33.161"
            height="30.61"
            viewBox="0 0 33.161 30.61"
          >
            <path
              id="Icon_ionic-md-heart-empty"
              data-name="Icon ionic-md-heart-empty"
              d="M27.417,4.5A9.808,9.808,0,0,0,19.955,8a9.808,9.808,0,0,0-7.461-3.5,9.015,9.015,0,0,0-9.119,9.175c0,6.337,5.636,11.423,14.173,19.267l2.407,2.168,2.407-2.168C30.9,25.1,36.536,20.012,36.536,13.675A9.015,9.015,0,0,0,27.417,4.5ZM20.976,30.75l-.335.311-.686.622-.686-.622-.335-.311a101.321,101.321,0,0,1-9.781-9.725c-2.232-2.766-3.22-5.03-3.22-7.35A6.63,6.63,0,0,1,7.823,8.948a6.487,6.487,0,0,1,4.671-1.9A7.311,7.311,0,0,1,18,9.642l1.953,2.32,1.953-2.32a7.284,7.284,0,0,1,5.508-2.591,6.519,6.519,0,0,1,4.679,1.9,6.644,6.644,0,0,1,1.889,4.727c0,2.312-1,4.584-3.22,7.35A101.525,101.525,0,0,1,20.976,30.75Z"
              transform="translate(-3.375 -4.5)"
              fill="#f5b9ab"
            />
          </svg>
      </div>

      <div onClick={() => redirectTo()} className={styles.image}>
        {props.supplier.imageUrl !== null && (
          <img
            src={props.supplier.imageUrl}
            className={styles.slideImage}
            alt=""
            onClick={() => {}}
          />
        )}
        {props.supplier.imageUrl === null && (
          <img
            style={{ objectFit: "none" }}
            src="/static/media/logo.8915ebd25d310c92475d.png"
            className={styles.slideImage}
            alt=""
            onClick={() => {}}
          />
        )}
      </div>
      <div className={styles.description}>
        <span onClick={() => redirectTo()} className={styles.name}>
          {props.supplier.name}
        </span>

        <div style={{ display: "flex" }}>
          <div className={styles.rating}>
            <RatingStar rating={props.supplier.rating} colorFill={"#F5B9AB"} widthM={"0.84375rem"} widthD={"1.5rem"} gapM={"0.1125rem"} gapD={"0.20625rem"}/>
          </div>
          {/* <span className={styles.count}> {} </span> */}
          <span
            className={styles.count}
          >{`${props.supplier.amountOfReviews} חוות דעת `}</span>
        </div>
      </div>
    </div>
  );
};

export default SupplierCard;
