import styles from "./Documents.module.scss";
import SelectcheckList from "./selectChecklist/Select";
import SelectDocument from "./selectDocuments/Select";
import exportFile from "./export.svg";
import shekel from "./money.svg"
import file_active from "./file_active.svg"
import {useDispatch, useSelector} from "react-redux";
import file_inactive from "./file_inactive.svg"
import download_active from "./download_active.svg"
import download_inactive from "./download_inactive.svg"
import filters from "./filters.svg"
import details from "./details.svg"
import { useEffect, useState, useRef } from "react";
import Popup from "./popup/Popup";
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import {setCurrentEvent} from "../../store/mainSlice";
import {setSelectedCategoryId, setSelectedEventTypeId} from "../../store/suppliersSlice";
import {useNavigate} from "react-router-dom";
import classNames from "classnames";
import PopupFilters from "./popupFilters/PopupFilters";
import PopupDetails from "./popupDetails/PopupDetails";
import MetaFavicon from '../../components/metaComponents/metaFavicon'
import { setErr } from "../../store/forAll/errSlice";
import Payment from '../Chat/payment/Payment'
import arrowMobile from "../Chat/arrowUp.svg"
import {setCurrentEventChat} from "../../store/chatSlice";
import useAuth from "../../hooks/useAuth";
import Dialog from "@mui/material/Dialog";
import { useAnalyticsEvents } from "../../hooks/useAnalyticsEvents";

function Documents() {

		const dispatch = useDispatch();
		const {Purchase, NewContractSignUp} = useAnalyticsEvents();
		let [checklist, setchecklist] = useState([])
		const axiosPrivate = useAxiosPrivate();
		const [currentChecklist, setcurrentChecklist] = useState({});
		const { currentEvent:currentChecklistState } = useSelector((state) => state.checklist);
		const [currentDocumentStatus, setcurrentDocumentStatus] = useState({id:1, name:'‌כל המסמכים', value:'all'});
		const [documentStatus] = useState([
				{id:1, name:'‌כל המסמכים', value:'all'},
				{id:2, name:'חתומים', value:'SIGNED'},
				{id:3, name:'לחתימה', value:'PENDING'},
				{id:4, name:'פג תוקף', value:'EXPIRED'},
				{id:5, name:'בתשלום', value:'PAYED'}])
		const [checklistSelected, setchecklistSelected] = useState(false)
		const { auth } = useAuth();
		const [isOpenMobileFilters, setisOpenMobileFilters] = useState(false)
		const [isOpenMobileDetails, setisOpenMobileDetails] = useState(false)
		const [isOpenPopup, setIsOpenPopup] = useState(false)
		const [isRead, setIsRead] = useState(false)
		const [isCurrentId, setIsCurrentId] = useState(0)
		const [searchName, setSearchName] = useState('')
		const [currentDocument, setcurrentDocument] = useState()
		const [documents, setdocuments] = useState({data:[], loading: true});
		const [suppName, setSuppName] = useState('')
		const [openPayment, setopenPayment] = useState(false);
		const [statusUnPaid, setstatusUnPaid] = useState(false);
		const [showPayment, setshowPayment] = useState(false);
		const [paymentServices, setpaymentServices] = useState({data:[], loading:true});
		const [isOpenDropDown, setIsOpenDropDown] = useState(true);
		const [allInformation, setAllInformation] = useState({});
		const [allInformationAnal, setAllInformationAnal] = useState({})
		const [isPaySuccess, setIsPaySuccess] = useState(false);

		const [userID, setUserID] = useState(0)
		const checkPaymentFunc = useRef(false);
			
		const navigate = useNavigate();

		const sendAnalitics = async(id) => {
			const obj = ({
				userName: (auth?.user ? JSON.parse(auth?.user)?.name : ""),
				supplierName: suppName,
				userPhone: (auth?.user ? JSON.parse(auth?.user)?.phoneNumber : ''),
				total: allInformationAnal.totalAmount,
				discount: (+allInformationAnal?.totalAmount - (+allInformationAnal?.discountPrice || 0)),
			})
	
			try {
				const res = await axiosPrivate.get(`/contract/details?contractId=${id}`);
				if (res?.error) {
					
					Purchase(obj)
				} else {
					const data = await res.data;
					obj.total = data?.totalAmount;
					obj.discount = data?.discountPrice;
					Purchase(obj)
				}
					
			} catch (error) {
				Purchase(obj)
			}
			
		}
		const sendAnalitics2 = async(id) => {
			const obj = ({
				userName: (auth?.user ? JSON.parse(auth?.user)?.name : ""),
				supplierName: suppName,
				userPhone: (auth?.user ? JSON.parse(auth?.user)?.phoneNumber : ''),
				total: allInformationAnal.totalAmount,
				discount: (+allInformationAnal?.totalAmount - (+allInformationAnal?.discountPrice || 0)),
			})
	
			try {
				const res = await axiosPrivate.get(`/contract/details?contractId=${id}`);
				if (res?.error) {
					
					NewContractSignUp(obj)
				} else {
					const data = await res.data;
					obj.total = data?.totalAmount;
					obj.discount = data?.discountPrice;
					NewContractSignUp(obj)
				}
					
			} catch (error) {
				NewContractSignUp(obj)
			}
		}

		useEffect(() => {
			let div = document.getElementsByClassName('samyBody');
			div[0].scrollTop = 0
			const fetchData = async () => {
			const result = await axiosPrivate.get(`${process.env.REACT_APP_API_PATH}contract/user/eventType`);
			setchecklist(result.data);
			if(result.data && result.data.length !== 0){
				setcurrentChecklist(result.data[0])
				getDocuments(currentDocumentStatus.value, searchName, result.data[0].id);
			}
			};
			fetchData();
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

	
		
//******************************* */
	useEffect(() => {
		// const fetchData = async () => {
		//const result = await axiosPrivate.get(`${process.env.REACT_APP_API_PATH}user/choose/service?userId=${userId}&supId=${supplierId}&eventTypeId=${eventTypeId}&categoryId=${categoryId}`);
		setpaymentServices({data: [
			{id:1, name:'צילום סטילס', price:'456'},
			{id:2, name:'צילום סטילס', price:'1223'},
			{id:3, name:'test', price:'4000'},
			{id:4, name:'צילום סטילס', price:'533'},
			{id:5, name:'צילום סטילס', price:'10'},
		], loading: false});
		setshowPayment(false);
		// };
		// fetchData();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
		const getServicesInfoForPayment = async (contractID,supName) =>{
			setSuppName(supName)
				try {
					const res = await axiosPrivate.get(`/contract/details?contractId=${contractID}`);
						if (res?.error) {
							dispatch(
								setErr({
									message: res?.payload?.response?.data?.message,
									code: res?.payload?.response?.data?.code,
								})
							);
						} else {
							setUserID(res.data.userId)
							const data = await res.data;
							let pdesc = `מקדמה ל${data?.supplierName} עבור ${data?.eventTypeName}`
            	setAllInformation({...data, pdesc})
							if(data?.totalAmount){
								setAllInformationAnal(data)
							}
							setshowPayment(true);
							checkPaymentFunc.current = true;
							checkPayment(contractID);
						}
					} catch (error) {
						console.log(error)
					}
		}

		let notificationId1;
		const checkPayment = async (id) =>{
			let checkId = currentDocument && currentDocument?.contractId !== 0 ? currentDocument?.contractId : id;
			let count = 0;
			const result = axiosPrivate.get(`${process.env.REACT_APP_API_PATH}contract/status/pay?contractId=${checkId}&checkListId=${currentChecklistState?.checkListId || 0}`);
				result.then(res=>{
					if(res.data.status === 'PAYED'){
					setIsPaySuccess(true)
					sendAnalitics(checkId);
					setopenPayment(true)
					checkPaymentFunc.current = false;
					}
				})
			notificationId1 = setInterval(() => {
		if(checkPaymentFunc.current){
			const result = axiosPrivate.get(`${process.env.REACT_APP_API_PATH}contract/status/pay?contractId=${checkId}&checkListId=${currentChecklistState?.checkListId || 0}`);
				result.then(res=>{
					if(res.data.status === 'PAYED'){
						setIsPaySuccess(true)
						sendAnalitics(checkId)
						setopenPayment(true);
						checkPaymentFunc.current = false;
					if(count <= 60){
						count++;
						if(res.data.status === 'PAYED'){
							setIsPaySuccess(true)
							sendAnalitics(checkId);
						  setopenPayment(true);
						  checkPaymentFunc.current = false;
						  clearInterval(notificationId1);
						} else if(res.data.status === 'UN_PAYED'){
							setIsPaySuccess(true)
						  setstatusUnPaid(true);
						  clearInterval(notificationId1);
						  setshowPayment(false);
						}
					} else{
						checkPaymentFunc.current = false;
						setshowPayment(false);
						if(res.data.status === 'PAYED'){
							setIsPaySuccess(true)
							sendAnalitics(checkId);
						  setopenPayment(true);
						  checkPaymentFunc.current = false;
						  clearInterval(notificationId1);
						} else if(res.data.status === 'UN_PAYED'){
							setIsPaySuccess(true)
						  setstatusUnPaid(true);
						  clearInterval(notificationId1);
						  setshowPayment(false);
						}
					}
					} else{
					 // clearInterval(notificationId1);
					}
				}).catch(
					()=>{clearInterval(notificationId1);
					}
				)
		} else {
			clearInterval(notificationId1);
		}
		}, 30000);
		return ()=>{clearInterval(notificationId1)}

		}
		useEffect(() => {
			return ()=>{clearInterval(notificationId1);};
			// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
//************************************ */

		const getDocuments = async (document,supName,checklistId) => {
				let data = {  
					supplierName: supName,
					eventTypeId: checklistId,
					contractStatus: document === 'all' ? [] : [document],
				}
				const result = await axiosPrivate.post(`${process.env.REACT_APP_API_PATH}contract/user/month`,data);
				if(result){
					setchecklistSelected(true);
					setdocuments({data:result.data.content, loading:false});
				}

		 }

		const onChangeOption = (option) => {
				setcurrentChecklist(option);
				getDocuments(currentDocumentStatus.value, searchName,option.id);
		};

		const onChangedocuments = (option) => {
				setcurrentDocumentStatus(option);
				if(checklistSelected){
					getDocuments(option.value, searchName,currentChecklist.id);
				}
		};
		
		let timeout = 0;
		function doSearch(ev) {
			setSearchName(ev.target.value);
			if(checklistSelected){
				if (timeout) {
						clearTimeout(timeout);
				}
				timeout = setTimeout(() => {
						getDocuments(currentDocumentStatus.value, ev.target.value,currentChecklist.id);
				}, 500);
			}
		}

		const openDocumentDialog = async (id,isRead) => {
				setIsCurrentId(id);
				setIsRead(isRead);
				setIsOpenPopup(true);
		}
		useEffect(() => {
			if(showPayment === false){
				update()
			}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		},[showPayment])
		const getMonth =  (month) => {
				if(Number(month) === 1){
						return 'ינואר';
				} else if(Number(month) === 2){
						return 'פברואר';
				} else if(Number(month) === 3){
						return 'מרץ';
				} else if(Number(month) === 4){
						return 'אפריל';
				} else if(Number(month) === 5){
						return 'מאי';
				} else if(Number(month) === 6){
						return 'יוני';
				} else if(Number(month) === 7){
						return 'יולי';
				} else if(Number(month) === 8){
						return 'אוגוסט';
				} else if(Number(month) === 9){
						return 'ספטמבר';
				} else if(Number(month) === 10){
						return 'אוקטובר';
				} else if(Number(month) === 11){
						return 'נובמבר';
				} else if(Number(month) === 12){
						return 'דצמבר';
				}
		}

		function convertToTwoCharacter(numb) {
				return numb.toString().length === 1 ? "0" + numb : numb.toString();
			}

		function convertDate(date){
				const time = new Date(date);
				let month = time.getMonth() + 1;
				let day = time.getDate();
				let dayString = convertToTwoCharacter(day);
				let monthString = convertToTwoCharacter(month);
				let dateFull = dayString + '/' + monthString + '/' + time.getFullYear();
				return dateFull
			}

		function openSupplier(categoryId, name, supId) {
				let data = {id: categoryId, name: name};
				let eventType = {id: currentChecklist.id, name: currentChecklist.name};
				dispatch(setCurrentEvent({currentEvent: eventType}));
				dispatch(setSelectedEventTypeId(currentChecklist.id));
				dispatch(setSelectedCategoryId(data));
				navigate(`/supplier/${supId}`);
		}

		const getPDF = async(contractId) => {
				try {
					let actualState = [...documents.data];
					let secondResponse = null
					let isHaveSecondFile = false
					let isSupplierDocument = false
					try{
						const response = await axiosPrivate.get(`${process.env.REACT_APP_API_PATH}contract/document/request?contractId=${contractId}`,{responseType: 'blob'});
						const data = await response.data;
						const url = window.URL.createObjectURL(new Blob([data]));
						const link = document.createElement("a");
						link.href = url;
						link.setAttribute("download", "file.pdf");
						document.body.appendChild(link);
						link.click();
					}catch(err){

					}

					actualState.forEach(el=>{
						isHaveSecondFile = el.contracts.find(el=>el.contractId === contractId).contractId
						isSupplierDocument = el.contracts.find(el=>el.contractId === contractId).supplierDocument
					})

					if (isHaveSecondFile && isSupplierDocument) {
						try{
							secondResponse = await axiosPrivate.get(`${process.env.REACT_APP_API_PATH}contract/supplier/document?contractId=${contractId}`,{responseType: 'blob'});
							const link2 = document.createElement("a");
							const dataSecond = await secondResponse.data;
							const url2 = window.URL.createObjectURL(new Blob([dataSecond]));
							link2.href = url2;
							link2.setAttribute("download", "file2.pdf");
							document.body.appendChild(link2);
							link2.click()
						} catch(err){

						}
					}
					// if (response.status!==200 || secondResponse.status!==200) {
					// 	throw new Error('Server Error');
					// }
				} catch (error) {
					console.log(error)
				}
		}

		const getPDFDocuments = async() => {
				let contractIds = [];
				let actualState = [...documents.data];
				actualState.map((item)=>{
						item.contracts.map((val)=>{
								if(val.selected){
										contractIds.push(val.contractId);
								}
								return val
						})
						return item
				})
				try {
					const response = await axiosPrivate.post(`${process.env.REACT_APP_API_PATH}contract/export/excel`,contractIds,{responseType: 'blob'});
					const data = await response.data;
					const url = window.URL.createObjectURL(new Blob([data]));
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", "document.xlsx");
					document.body.appendChild(link);
					link.click();
					if (response.status!==200) {
						throw new Error('Server Error');
					}
				} catch (error) {
					console.log(error)
				}
		}

		const onChangeDocuments = (index2, index, event) => {
				let actualState = [...documents.data];
				let newLine = actualState[index].contracts;
				if (event) {
						newLine[index2].selected = false;
				} else {
						newLine[index2].selected = true;
				}
				actualState[index].contracts = newLine;
				let newList = actualState;
				setdocuments({data: newList, loading: false});
		};

		const openFilters = () => {
				setisOpenMobileFilters(true);
		};

		const update = (isFromPupup, id1) => {
				if(isFromPupup){
					sendAnalitics2(id1)
				}
				getDocuments(currentDocumentStatus.value, searchName, currentChecklist.id)
		};

		const openDetails = (option) => {
				setisOpenMobileDetails(true);
				setcurrentDocument(option);
		};

		function check(item){
				getDocuments(item.value, searchName, currentChecklist.id);
		};

		
		function detailsInfo(){
				openDocumentDialog(currentDocument.contractId, true);
		};

		function pdfDownload(){
				getPDF(currentDocument.contractId);
		};
		function openUserChat(val) {
			let eventType = {id: currentChecklist.id, name: currentChecklist.name};
			dispatch(setCurrentEventChat({currentEvent: eventType}));
			navigate(`/personal/chat/${val.supplierId}/${JSON.parse(auth?.user).id}/${currentChecklist.id}/${val.categoryId}/0`);
		}
	return (
		<>
		{!showPayment ?
		<>
			<MetaFavicon />
			<div className={styles.filtersBlock}>
					<div className={styles.firstFilter}>
							<div className={styles.firstFilterTitle}>בחר אירוע</div>
							<div>
									<SelectcheckList
											fontSize={"1.375rem"}
											paddingInlineStart={"1.125rem"}
											placeholder={""}
											width={"100%"}
											height={"2.9375rem"}
											borderRadius={"0.125rem"}
											color={"#242323"}
											options={checklist}
											selectedOption={currentChecklist}
											onChangeOption={onChangeOption}
											/>
							</div>
					</div>
				<div className={styles.secondBlock}>
					<div className={styles.secondFilter}>
							<div className={styles.secondFilterTitle}>סנן</div>
							<div className={styles.secondFilterDocument}>
									<SelectDocument
											fontSize={"1.25rem"}
											paddingInlineStart={"1rem"}
											placeholder={" "}
											width={"100%"}
											height={"2.9375rem"}
											borderRadius={"0.125rem"}
											color={"#242323"}
											selectedOption={currentDocumentStatus}
											options={documentStatus}
											onChangeOption={onChangedocuments}
											/>
							</div>
							<div className={styles.searchFieldContainer}>
									<input type="text" className={styles.searchingField}
											onChange={(e) => doSearch(e)}
											placeholder='חיפוש חופשי'/>
									<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"
													className={styles.magnifierIcon}>
											<g id="magnifying-glass" transform="translate(-0.4)">
													<rect id="Rectangle_204" data-name="Rectangle 204" width="28" height="28" transform="translate(0.4)"
																	fill="none"/>
													<path id="Ellipse_1" data-name="Ellipse 1"
																	d="M2.106-8A10.118,10.118,0,0,1,12.212,2.106,10.118,10.118,0,0,1,2.106,12.212,10.118,10.118,0,0,1-8,2.106,10.118,10.118,0,0,1,2.106-8Zm0,18.455A8.348,8.348,0,1,0-6.242,2.106,8.358,8.358,0,0,0,2.106,10.455Z"
																	transform="translate(10.636 10.848)" fill="#3e3d3d"/>
													<path id="Line_4" data-name="Line 4"
																	d="M-1.783-.9A.876.876,0,0,1-2.4-1.161L-7.743-6.5a.879.879,0,0,1,0-1.243.879.879,0,0,1,1.243,0L-1.161-2.4a.879.879,0,0,1,0,1.243A.876.876,0,0,1-1.783-.9Z"
																	transform="translate(26.389 26.601)" fill="#3e3d3d"/>
											</g>
									</svg>
							</div>
					</div>
							{!documents.loading && <img alt="export" onClick={(e)=>{getPDFDocuments();}} className={styles.exportImage} src={exportFile} />}
				</div>
			</div>
			{!documents.loading && <div className={styles.tableBlock}>
				<div className={styles.filters}>
					{currentChecklist?.name  && <div className={styles.tableTitle} >{ (currentChecklist?.date !== null ? convertDate(currentChecklist?.date) : '' ) + " | " + currentChecklist?.name}</div>}
					<img src={filters} alt='filters' onClick={(e)=>{openFilters();}} className={styles.mobile} style={{cursor:'pointer'}} />
				</div>
				<div>
					<hr className={styles.hrTable} />
				</div>
				<div className={styles.table} >
					<div className={styles.tableContents}>
							<div className={styles.headerContent}>
									<div></div>
									<div></div>
									<div>מס׳</div>
									<div>תאריך המסמך</div>
									<div>שם הספק</div>
									<div>תחום</div>
									<div>סכום עסקה</div>
									<div>סטטוס חוזה</div>
									<div>סטטוס תשלום</div>
									<div>תשלום מקדמה</div>
									<div></div>
									<div></div>
							</div>
					</div>
					<Popup isOpen={isOpenPopup} isRead={isRead} setIsOpen={setIsOpenPopup} documentId={isCurrentId} update={update} />
					<PopupFilters isOpen={isOpenMobileFilters} onChange={check} setIsOpen={setisOpenMobileFilters} documents={documentStatus} currentDocument={currentDocumentStatus} setDocument={setcurrentDocumentStatus} />
					<PopupDetails isOpen={isOpenMobileDetails} setIsOpen={setisOpenMobileDetails} downloadPDF={pdfDownload} openDetails={detailsInfo} currentDocument={currentDocument} />
					{!documents.loading && documents.data.map((item, index)=>{
									return (
									<div key={index}>
									<div className={styles.tableMonth}>
											<div>{getMonth(item.month)} {item.year}</div>
											<div></div>
											<div></div>
											<div></div>
											<div></div>
											<div></div>
											<div></div>
											<div></div>
											<div></div>
											<div></div>
											<div></div>
											<div></div>
									</div>
									{ item.contracts.map((val, index2)=>{
									return (
									<div key={index2} className={styles.mainContent} style={{backgroundColor: val.advancePayment <= 0 ? '#FFFFFF' : '#E7B1AD'}} >
											<div className={styles.empty}></div>
											<div className={classNames(styles.textBlock,styles.desctop)}>
													<div className={styles.contract_services_checkbox}>
															<div className={styles.radio}>
																	<input
																			type="checkbox"
																			name={index +  '' + index2}
																			id={index + '' + index2}
																			checked={val.selected}
																			value={val.selected}
																			onChange={(e) => onChangeDocuments(index2, index, val.selected)}
																	/>
																	<label htmlFor={index + '' + index2}></label>
															</div>
													</div>
											</div>
											<div className={classNames(styles.textBlock,styles.contractId)}>{val.contractId}</div>
											<div className={classNames(styles.textBlock,styles.desctop)}>{convertDate(val.creationDate)}</div>
											<div className={styles.supplierName} onClick={(e)=>{openSupplier(val.categoryId,val.categoryName,val.supplierId);}}>{val.supplierName}</div>
											<div className={styles.textBlock}>{val.categoryName}</div>
											<div className={classNames(styles.textBlock,styles.desctop)}>{val.amount}<img src={shekel} alt='shekel' className={styles.shekel} /></div>
											{val.hasContract && val.status === 'PENDING' && <div className={classNames(styles.textBlock,styles.desctop)} style={{fontWeight:'bold',textDecoration:'underline', cursor:'pointer'}} onClick={(e)=>{openUserChat(val)}} >חתום עכשיו</div>}
											{val.hasContract && val.status === 'EXPIRED' && <div className={classNames(styles.textBlock,styles.desctop)} style={{fontWeight:'bold', color: 'red'}}>פג תוקף</div>}
											{val.hasContract && val.status === 'PAYED' && <div className={classNames(styles.textBlock,styles.desctop)} >בתשלום</div>}
											{val.hasContract && (val.status === 'SIGNED' || val.status === 'UN_PAYED') && <div className={classNames(styles.textBlock,styles.desctop)}>נחתם</div>}
											{val.status === null && <div className={classNames(styles.textBlock,styles.desctop)}>{'-'}</div>}
											<div className={styles.textBlock} style={(val.paymentStatus === 'UN_PAYED' || val.paymentStatus === null) && (val.status === 'UN_PAYED' || val.status === 'SIGNED') ? {fontWeight:'bold',textDecoration:'underline', cursor:'pointer'} : {}} 
												onClick={(val.paymentStatus === 'UN_PAYED' || val.paymentStatus === null) && (val.status === 'UN_PAYED' || val.status === 'SIGNED') ? (e)=>{getServicesInfoForPayment(val.contractId,val.supplierName)} : ''}>
												{(val.paymentStatus === 'UN_PAYED' || val.paymentStatus === null) && (val.status === 'UN_PAYED' || val.status === 'SIGNED') ? 'בצע תשלום' : val.paymentStatus === 'PAYED' && (val.status === null || val.status === 'PAYED') ? 'שולם': '-' }
											</div>
											<div className={classNames(styles.textBlock,styles.desctop)}>{val.advancePayment ? val.advancePayment : '-'}</div>
											<div className={classNames(styles.fileDownload,styles.desctop)}>
													{val.status !== 'EXPIRED' && <img src={file_active} alt='file' className={styles.download} onClick={(e)=>{openDocumentDialog(val.contractId,true);}} />}
													{val.status === 'EXPIRED' && <img src={file_inactive} alt='file' className={styles.download} />}
													<span className={styles.tooltiptext}>{"פירוט עסקה"}</span>
											</div>
											<div className={classNames(styles.fileDownload,styles.desctop)}>
													{val.hasContract && val.status !== 'EXPIRED' && <img src={download_active} onClick={(e)=>{getPDF(val.contractId);}} alt='download' className={styles.download} />}
													{(val.status === 'EXPIRED' || !val.hasContract) && <img src={download_inactive} alt='download' className={styles.download} />}
													<span className={styles.tooltiptext}>{"הורדת חוזה"}</span>
											</div>
											<div className={styles.details}> <img src={details} alt='details' onClick={(e)=>{openDetails(val)}} style={{cursor:'pointer'}} /> </div>
									</div>
									) 
									})}
									</div>
									)
							})
							}
				</div>
				</div> 
		}
		<Dialog hideBackdrop={true} sx={{
		  "& .MuiDialog-container": {
			"& .MuiPaper-root": {
			  width: "100%",
			  maxWidth: "400px",
			  boxShadow: 'none',
			  overflow: 'hidden',
			  borderRadius: '0'
			},
		  },
		}} open={statusUnPaid} fullWidth={true} className={styles.infoDialog}>
		  <div className={styles.mainPopup}>
			<div className={styles.close}>
			  <svg style={{cursor:'pointer'}}
			  xmlns="http://www.w3.org/2000/svg"
			  width="25.477"
			  height="25.47"
			  viewBox="0 0 25.477 25.47"
			  onClick={() => setstatusUnPaid(!statusUnPaid)}
			  >
			  <path
				id="Icon_ionic-ios-close"
				data-name="Icon ionic-ios-close"
				d="M27.042,24.024l9.1-9.1a2.132,2.132,0,1,0-3.015-3.015l-9.1,9.1-9.1-9.1a2.132,2.132,0,1,0-3.015,3.015l9.1,9.1-9.1,9.1a2.132,2.132,0,0,0,3.015,3.015l9.1-9.1,9.1,9.1a2.132,2.132,0,0,0,3.015-3.015Z"
				transform="translate(-11.285 -11.289)"
				fill="#242323"
			  />
			  </svg>
			</div>
			<div className={styles.blockCalendar}>
			  <div className={styles.infoDialogClose}>
				<div className={styles.infoDialogtitle}>התשלום נכשל נסה מאוחר יותר</div>
			  </div>
			</div>
		  </div>
		</Dialog>
		</>
		:
		<>
				<Payment isOpen={openPayment} value={suppName} closePopup={setopenPayment} customRedirect={setshowPayment}/>
				<div className={styles.paymentContent}>
					<div className={styles.paymentRight}>
						<div className={styles.paymentMobile}>
							<div className={styles.paymentMobileContent} onClick={() => {getServicesInfoForPayment();}}>
								<div style={{cursor:'pointer'}} onClick={() => {setIsOpenDropDown(!isOpenDropDown);}} >פירוט העסקה</div>
								<img onClick={() => {setIsOpenDropDown(!isOpenDropDown);}} alt="arrow" style={{transform: isOpenDropDown ? "" : "rotate(180deg)",cursor:'pointer'}} src={arrowMobile} />
							</div>
						</div>
						{isOpenDropDown && <div>
								<hr className={styles.paymentLineTop}></hr>
							<div>
							<div className={styles.paymentTitle}>{allInformation.supplierName} | {allInformation.categoryName}</div>
							<hr className={styles.paymentLine}></hr>
							<div className={styles.paymentSecondTitle}>הצעת מחיר עדכנית לתאריך {convertDate(allInformation.creationDate)}</div>
							<div className={styles.paymentBlock}>
							{!paymentServices.loading && allInformation.services.map((item,index)=> {
								return(
								<div className={styles.paymentserviceBlock} key={index}>{item.serviceName} - {item.price}</div>
								)
							})
							}
							</div>
							<hr className={styles.paymentLineEnd}></hr>
							<div className={styles.paymentPrice}>{'סך הכל'} - {allInformation.totalAmount} <img alt="shekel" src={shekel} /></div>
							{allInformation.discountPrice !== allInformation.totalAmount && <div className={styles.paymentPriceAfterDiscount}>{'מחיר לאחר הנחה'} - {allInformation.discountPrice} <img alt="shekel" src={shekel} /></div>}
							<div className={styles.paymentDiscountBlock}>
								<div className={styles.paymentDiscount}>- {'סך תשלום מקדמה'} {allInformation.prepayment} <img alt="shekel" src={shekel} /></div>
								<div className={styles.paymentDiscountPrice}>{allInformation.prepaymentPercentage}{'%'} מכלל העסקה</div>
							</div>
						</div>
						</div>}
					</div>
					<div className={styles.paymentLeft}>
						{!isPaySuccess && <div className={styles.absolutLoader}>
              <div className={styles.ldsDualRing}></div>
          </div>}
						<div className={styles.iframe}>
							<iframe style={{"z-index": "100"}} title="tranzila-iframe"
								id="tranzila-iframe"  width="100%"
								height="100%" 
								src={`https://direct.tranzila.com/${process.env.REACT_APP_TRANZILA}/iframenew.php?hidesum=1&sum=${allInformation.prepayment}&currency=1&cred_type=1&lang=il&userId=${userID}&contractId=${allInformation.id}&tranmode=AK&nologo=1&email=${allInformation.userEmail}&contact=${allInformation.userName}&pdesc=${allInformation.pdesc}`}  
								sandbox="allow-modals allow-forms allow-popups allow-scripts"
								frameBorder="0">
									<input type="hidden" name="email" value={allInformation.userEmail}/>
									<input type="hidden" name="contact" value={allInformation.userName}/>
									<input type="hidden" name="pdesc" value={allInformation.pdesc}/>
							</iframe>
						</div>
					</div>
				</div>
			</>}
		</>
		);
	}   
export default Documents;