import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const CallToAction = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate();
  return (
    <Box
      component={'section'}
      id="call-to-action"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: { xs: 'start', sm: 'center' },
        padding: { xs: '28px 19px 162px', md: '76px 19px 168px' },
      }}
    >
      <Typography
        sx={{
          color: '#405F6A',
          fontSize: { xs: '18px', sm: '26px', md: '32px' },
          lineHeight: { xs: '24px', md: '47px' },
          fontFamily: 'Heebo-Medium',
        }}
      >
        {!isMobile ? (
          ' איוונטדו - מסדרים לכם אירוע'
        ) : (
          <>
            איוונטדו- <br></br>באנו לעשות לכם סדר בתכנון האירוע
          </>
        )}
      </Typography>
      <Typography
        sx={{
          fontSize: { xs: '14px', sm: '18px', md: '22px' },
          textAlign: { xs: 'start', sm: 'center' },
          lineHeight: { xs: '23.75px', sm: '28px', md: '36.5px' },
          marginTop: { xs: '27px', md: '22px' },
          // height: '146px',
          maxWidth: { xs: 'unset', sm: '550px', md: '865px' },
          fontFamily: { xs: 'Heebo-Medium', sm: 'Heebo-Regular' },
        }}
      >
        {/* מזל טוב! אתם מתכננים אירוע ואנחנו בטוחים שאתם כבר מתרגשים. האמת? גם אנחנו
        <Box component={'br'} sx={{ display: { xs: 'none', md: 'block' } }} />
        עולם האירועים מרגש אותנו ברמות של כלה, וזאת בדיוק הסיבה שהחלטנו לקחת את זה צעד קדימה ולהקים
        את איוונטדו. אנחנו מאמינים שתכנון אירוע צריך להיות נינוח, רגוע ואפילו מהנה
        <Box component={'br'} sx={{ display: { xs: 'none', md: 'block' } }} />
        אין שום סיבה שתקופת התכנון והארגונים תהיה לחוצה ותוציא את כל הכיף מהאירוע עצמו */}
        <b>{"נוחות"}</b>
        <Box component={'br'}/>
        {"במקום לחפש באינספור אתרים וקבוצות, ריכזנו עבורכם את מיטב ומירב הספקים בתחום האירועים בישראל."}
        <Box component={'br'}/>
        <b>{"שירות"}</b>
        <Box component={'br'}/>
        {"שירות אישי, איכותי, מקצועי ואמינות חרוטה על דגלינו!"}
        <Box component={'br'}/>
        {"עובדים בשקיפות מלאה מולכם ומול הספקים שלנו."}
        <Box component={'br'}/>
        <b>{"טכנולוגיה "}</b>
        <Box component={'br'}/>
        {"אתם עובדים עם הממשק המתקדם בעולם, למציאה וסגירת ספקים."}
        <Box component={'br'}/>
        {"מערכת צ'ק ליסט מבוססת מחשבון פיננסי שתעזור לכם לשמור על התקציב שלכם."}
        <Box component={'br'}/>
      
      </Typography>
      <Button
        variant="contained"
        onClick={() => {
          navigate('/suppliers');
        }}
        sx={{
          marginTop: { xs: '27px', md: '50px' },
          height: { xs: '45px', md: '51px' },
          maxWidth: { xs: '130px', md: '152px' },
        }}
      >
        בואו נצא לדרך
      </Button>
    </Box>
  );
};

export default CallToAction;
