import { Controller } from 'react-hook-form';

const CustomSelectShell = (props) => {
  const { name, rules, children } = props;
  return name ? (
    <Controller name={name} rules={rules} render={({ field }) => <>{children(field)}</>} />
  ) : (
    <>{children({})}</>
  );
};
export default CustomSelectShell;
