import styles from '../../styleSuppliers.module.scss';
import classNames from 'classnames';
const CategoryFilter = (props) => {
  const { item, setValue, allItems } = props;
  const onClick = () => {
    const newMas = [...allItems, item.id];
    const filteredMas = allItems.filter((oneId) => oneId !== item.id);
    if (!isSelected()) setValue(newMas);
    else setValue(filteredMas);
  };
  const isSelected = () => {
    const findItem = allItems.find((oneId) => oneId === item.id);
    return !!findItem;
  };
  return (
    <div
      onClick={onClick}
      className={classNames(styles.category_filter, isSelected() && styles.selected)}
    >
      {item.name}
    </div>
  );
};
export default CategoryFilter;
