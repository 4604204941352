import { createSlice } from "@reduxjs/toolkit";

const returnToUrlSlice = createSlice({
  name: "returnToUrl",
  initialState: {
    pathForReturn: "",
    nextPathForReturn: "",
  },
  reducers: {
    savePath(state, action) {
      state.pathForReturn = action.payload;
      // console.log(action.payload)
    },
    saveNextPath(state, action) {
      state.nextPathForReturn = action.payload;
      // console.log(action.payload)
    },
  },
});

export const { savePath, saveNextPath, cleanPath } = returnToUrlSlice.actions;

export default returnToUrlSlice.reducer;
