import styles from "./AboutUs.module.scss";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import HeaderMobile from "../../components/headerMobile/HeaderMobile";
import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/pagination";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import he from 'dayjs/locale/he'; // load on demand
import localizedFormat from 'dayjs/plugin/localizedFormat'; 
import dayjs from "dayjs";
import ChecklistButton from "../../components/checklistButton/checklistButton";
import MetaAboutUs from "../../components/metaComponents/metaAboutUs";
import WhatsApp from "../../components/whatsApp/whatsApp";
import { useDispatch } from "react-redux";
import { setLoader } from "../../store/loaderSlice";



function AboutUs() {
  dayjs.locale(he);
  dayjs.extend(localizedFormat)
  const [aboutUs, setAppState] = useState({data:null, loading:true});
  const [benefits, setBenefits] = useState({data:[], loading:true});
  const [feedback, setFeedback] = useState({data:[], loading:true});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function checkImageFormat(value){
    if(value === 'jpeg' || value === 'png' || value === 'svg' || value === 'gif' || value === 'jpg' || value === 'webp'){
        return true
    }
    return false
  }
  
  useEffect(() => {
    dispatch(setLoader(true));
    let div = document.getElementsByClassName('samyBody');
    div[0].scrollTop = 0
    const fetchData = async () => {
      const result = await axios(`${process.env.REACT_APP_API_PATH}main/about/us`);
      setAppState({data:result.data,loading:false});
      dispatch(setLoader(false));
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAppState]);

    
  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(`${process.env.REACT_APP_API_PATH}main/about/us/benefits`);
      setBenefits({data:result.data,loading:false});
      dispatch(setLoader(false));
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setBenefits]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(`${process.env.REACT_APP_API_PATH}main/about/us/feedback`);
      setFeedback({data:result.data,loading:false});
      dispatch(setLoader(false));
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFeedback]);

  function replaceTagsP(value){
    return value.replace(/p>/gi, 'div>')
  }
  
  return (
    <>
        {/* <WhatsApp/> */}
        <MetaAboutUs/>
        <Header />
        <HeaderMobile />
        <ChecklistButton/>

        {/* desctop version */}
        {!aboutUs.loading  && aboutUs.data != null && <section className={styles.desctopVersion}>
        <h1 className={styles.title}>{aboutUs.data.header}</h1>
        <div className={styles.aboutUs}>
        <div className={styles.leftBlock} dangerouslySetInnerHTML={{ __html: replaceTagsP(aboutUs.data.description) }}></div>
        <div className={styles.rightBlock}>
            {checkImageFormat(aboutUs.data.fileType) && <img src={aboutUs.data.fileUrl} className={styles.aboutUsImg} alt='testImg' /> }
            {!checkImageFormat(aboutUs.data.fileType) && <video className={styles.aboutUsVideo} controls>
            <source src={aboutUs.data.fileUrl} type="video/mp4"/>
              Your browser does not support the video tag.
            </video> }
        </div>
        </div> 
        </section>}
        {/* ** */}

        {/* mobile version */}
        {!aboutUs.loading  && aboutUs.data != null && <section className={styles.mobileVersion}>
        <h3 className={styles.mobileTitle}>?מי אנחנו</h3>
        <div className={styles.aboutUsMobile}>
        <div className={styles.mobileFile}>
            {checkImageFormat(aboutUs.data.fileType) && <img src={aboutUs.data.fileUrl} className={styles.mobileImg} alt='testImg' /> }
            {!checkImageFormat(aboutUs.data.fileType) && <video className={styles.mobileVideo} controls>
            <source src={aboutUs.data.fileUrl} type="video/mp4"/>
              Your browser does not support the video tag.
            </video> }
        </div>
        <h3 className={styles.mobileHeader}>{aboutUs.data.header}</h3>
        </div> 
        <div className={styles.mobileDescription} dangerouslySetInnerHTML={{ __html: replaceTagsP(aboutUs.data.description) }}></div>
        </section>}
        {/* ** */}

        {!benefits.loading  && benefits.data.length !== 0 && <section className={styles.feedbackBlock}>
        <Swiper
          slidesPerView={'auto'}
          spaceBetween={41}
          centeredSlides={false}
          className={styles.desctopSlider}
        >
            {benefits.data &&
                benefits.data.map((row) => {
                    return (
                        <SwiperSlide  className={styles.slideWrapper} key={row.id}>
                          <div key={row.id} className={styles.feedbackForm}>
                          <h2 className={styles.feedbackHeader}>{row.header}</h2>
                            <div className={styles.feedbackDescription}  dangerouslySetInnerHTML={{ __html: replaceTagsP(row.description)}}></div>
                          </div>
                        </SwiperSlide>
                    )
                })
            }
            {/* <div className={styles.button}>
              <button>הספקים שלנו</button>
            </div> */}
        </Swiper>
        <Swiper
          slidesPerView={'auto'}
          spaceBetween={7}
          centeredSlides={false}
          className={styles.mobileSlider}
        >
            {benefits.data &&
                benefits.data.map((row) => {
                    return (
                        <SwiperSlide  className={styles.slideWrapper} key={row.id}>
                          <div key={row.id} className={styles.feedbackForm}>
                          <h2 className={styles.feedbackHeader}>{row.header}</h2>
                            <div className={styles.feedbackDescription}  dangerouslySetInnerHTML={{ __html: replaceTagsP(row.description)}}></div>
                          </div>
                        </SwiperSlide>
                    )
                })
            }
        </Swiper>
        </section> }

        <section className={styles.buttonBlock}>
          <button onClick={() => navigate('/suppliers')} className={styles.button_}>הספקים שלנו</button>
        </section>
        <h2 className={styles.feedbackTitle}>...מספרים עלינו</h2>
        {!feedback.loading  && feedback.data != null && <section>
        <Swiper
          slidesPerView={'auto'}
          spaceBetween={15}
          centeredSlides={false}
          className={styles.desctopSliderFeedback}
        >
            { feedback.data.map((row) => {
                    return (
                        <SwiperSlide className={styles.slidefeedback} key={row.id}>
                          <div key={row.id} className={styles.feedbackFormSlide}>
                          <h3 className={styles.feedbackHeaderSlider}>{row.header}</h3>
                            <div className={styles.feedbackDescriptionSlider}  dangerouslySetInnerHTML={{ __html: replaceTagsP(row.description)}}></div>
                            <div className={styles.clientName}>{row.clientName}</div>
                            <div className={styles.clientDate}>{row.creationDate ? dayjs(row.creationDate).format("L"): null}</div>
                          </div>
                        </SwiperSlide>
                    )
                })
            }
        </Swiper>
        <Swiper
          slidesPerView={'auto'}
          spaceBetween={10}
          centeredSlides={false}
          className={styles.mobileSliderFeedback}
        >   
        { feedback.data.map((row) => {
          return (
              <SwiperSlide className={styles.slidefeedback} key={row.id}>
                <div key={row.id} className={styles.feedbackFormSlide}>
                <h3 className={styles.feedbackHeaderSlider}>{row.header}</h3>
                  <div className={styles.feedbackDescriptionSlider} dangerouslySetInnerHTML={{ __html: replaceTagsP(row.description)}}></div>
                  <div className={styles.clientName}>{row.clientName}</div>
                  <div className={styles.clientDate}>{row.creationDate ? dayjs(row.creationDate).format("L"): null}</div>
                </div>
              </SwiperSlide>
          )
      })
  }
        </Swiper>
        </section>}

        <section className={styles.desctop}> 
          < Footer />
        </section>
        <div className={styles.forIphone}>
        </div>
    </>
    );
  }   
export default AboutUs;