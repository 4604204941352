import React from "react";
import styles from "./businessLabelDetails.module.scss";
import classNames from "classnames";

import { Controller,useFormContext } from "react-hook-form";

function BusinessLabelDetails() {
  const { control, formState } = useFormContext({});
  const { errors } = formState;

  function removeSpaces(str){
    // console.log(str)
    if(!str && str!=="")return
    let newStr = str.replace(/\s+/g, ' ').trim()
    return newStr
  }

  return (
    <form noValidate className={styles.dataInputsForm}>
      <div className={styles.dataInputs}>
        <div className={classNames(styles.inputContainer,styles.width33)} >
          <Controller
            name="businessName"
            control={control}
            render={({ field: { onChange, value } }) => (
              <input
                // {...field}
                className={classNames({
                  [styles.invalidField]: errors.businessName,
                })}
                error={!!errors.businessName}
                required
                placeholder="שם העסק"
                value={value? value : ''}
                  onBlur={(event, newValue) => {
                    onChange(removeSpaces(event.target.value,newValue));
                  }}
                  onChange={(event, newValue) => {
                    onChange(event.target.value);
                  }}
                id="businessName"
              />
            )}
          />
          { errors.businessName && 
              <p className={styles.errorMessage}>
                {errors.businessName.message}
              </p>
            }
          
        </div>
        <div className={styles.inputContainer}>

          <Controller
            name="companyRegistrationNumber"
            control={control}
            render={({ field: { onChange, value } }) => (
              <input
                type={"number"}
                // {...field}
                className={classNames({
                  [styles.invalidField]: errors.companyRegistrationNumber,
                })}
                placeholder="ח.פ \ עוסק מורשה"
                error={!!errors.companyRegistrationNumber}
                // required
                value={value? value : ''}
                  onBlur={(event, newValue) => {
                    onChange(removeSpaces(event.target.value,newValue));
                  }}
                  onChange={(event, newValue) => {
                    onChange(event.target.value);
                  }}
                id="companyRegistrationNumber"
              />
            )}
          />
          { errors.companyRegistrationNumber && 
              <p className={styles.errorMessage}>
                {errors.companyRegistrationNumber.message}
              </p>
            }
          
        </div>

      </div>
      <div className={classNames(styles.dataInputs, styles.secondBlock)}>
        
        <div className={styles.inputContainer}>

        <Controller
          name="businessAddress"
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <input
                // {...field}
                className={classNames({
                  [styles.invalidField]: errors.businessAddress,
                }, styles.desctop)}
                error={!!errors.businessAddress}
                // required
                placeholder="כתובת בית העסק (רחוב, מספר, עיר)" // adress by law
                value={value? value : ''}
                  onBlur={(event, newValue) => {
                    onChange(removeSpaces(event.target.value,newValue));
                  }}
                  onChange={(event, newValue) => {
                    onChange(event.target.value);
                  }}
                id="businessAddress"
              />
              <input
                // {...field}
                className={classNames({
                  [styles.invalidField]: errors.businessAddress,
                }, styles.mobile)}
                error={!!errors.businessAddress}
                // required
                placeholder="כתובת בית העסק" // adress by law
                value={value? value : ''}
                  onBlur={(event, newValue) => {
                    onChange(removeSpaces(event.target.value,newValue));
                  }}
                  onChange={(event, newValue) => {
                    onChange(event.target.value);
                  }}
                id="businessAddress"
              />

            </>
            
          )}
        />
        { errors.businessAddress && 
              <p className={styles.errorMessage}>
                {errors.businessAddress.message}
              </p>
            }
          
        </div>
      </div>
      
      
      
      
    </form>
  );
}

export default BusinessLabelDetails;
