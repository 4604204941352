import { Divider, Typography } from '@mui/material';
import styles from '../styleSuppliers.module.scss';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
const ActionButtons = (props) => {
  const { setOpenMobileFilters, setOpenMobileCategory, openError } = props;
  const { selectedEventTypeId } = useSelector((state) => state.suppliers);
  const { watch } = useFormContext();
  const currentCategoryId = watch('category.id');
  return (
    <section id="actions_button" className={styles.action_buttons_container}>
      {/* check */}
      {!currentCategoryId || (currentCategoryId && currentCategoryId === 1) ? (
        <div
          className={styles.action_button_container}
          onClick={() => {
            openError();
            !!selectedEventTypeId && setOpenMobileFilters(true);
          }}
        >
          <Typography
            className={styles.action_button}
            sx={{ opacity: `${!selectedEventTypeId ? '0.5' : 'unset'}` }}
          >
            {!currentCategoryId ? 'פרטי האירוע' : 'הכנס את פרטי האירוע שלך'}
          </Typography>
        </div>
      ) : (
        <>
          <div
            className={styles.action_button_container}
            onClick={() => {
              openError();
              !!selectedEventTypeId && setOpenMobileCategory(true);
            }}
          >
            <Typography
              className={styles.action_button}
              sx={{ opacity: `${!selectedEventTypeId ? '0.5' : 'unset'}` }}
            >
              מיון ספקים
            </Typography>
          </div>
          <Divider orientation="vertical" flexItem sx={{ borderColor: '#405F6A' }} />
          <div
            className={styles.action_button_container}
            onClick={() => {
              openError();
              !!selectedEventTypeId && setOpenMobileFilters(true);
            }}
          >
            <Typography
              className={styles.action_button}
              sx={{ opacity: `${!selectedEventTypeId ? '0.5' : 'unset'}` }}
            >
              פרטי האירוע
            </Typography>
          </div>
        </>
      )}
    </section>
  );
};
export default ActionButtons;
