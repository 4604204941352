import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/axios";

export const userGoogleLogin = createAsyncThunk(
  "calendar/userGoogleLogin",
  async function ({idToken}, {rejectWithValue}) {
    
    try {
      const response = await axios.post(`/calendar/google/auth`, {idToken});
      // console.log(response)
      if (response.status!==200) {
        throw new Error('Server Error');
      }
      const data = await response.data;
      // console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);


export const supplierGoogleLogin = createAsyncThunk(
  "calendar/supplierGoogleLogin",
  async function ({idToken}, {rejectWithValue}) {
    
    try {
      const response = await axios.post(`/calendar/google/auth`, {idToken});
      // console.log(response)
      if (response.status!==200) {
        throw new Error('Server Error');
      }
      const data = await response.data;
      // console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);


export const fetchAllEvents = createAsyncThunk(
  "supplierRegistration/fetchAllEvents",
  async function (_, {rejectWithValue}) {
    try {
      const response = await axios.get(`/eventType`);
      /* console.log(response) */
      if (response.status!==200) {
        throw new Error('Server Error');
      }
      const data = await response.data;
      /* console.log(data); */
      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const fetchAllCategories = createAsyncThunk(
  "supplierRegistration/fetchAllCategories",
  async function (_, {rejectWithValue}) {
    try {
      const response = await axios.get(`/eventType`);
      /* console.log(response) */
      if (response.status!==200) {
        throw new Error('Server Error');
      }
      const data = await response.data;
      /* console.log(data); */
      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getRegions = createAsyncThunk(
  'supplierRegistration/getRegions', 
  async function (_, {rejectWithValue}) {
    try {
      const response = await axios.get(`/venue/details/locations`);
      /* console.log(response) */
      if (response.status!==200) {
        throw new Error('Server Error');
      }
      const data = await response.data;
      /* console.log(data); */
      return data === undefined ? null : data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getCompanyTypes = createAsyncThunk(
  'supplierRegistration/getCompanyTypes',

  async function (_, {rejectWithValue}) {
    try {
      const response = await axios.get(`/supplier/companyType`);
      /* console.log(response) */
      if (response.status!==200) {
        throw new Error('Server Error');
      }
      const data = await response.data;
      /* console.log(data); */
      return data === undefined ? null : data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getCategories = createAsyncThunk(
  'supplierRegistration/getCategories',
  async function (_, {rejectWithValue}) {
    try {
      const response = await axios.get(`/category`);
      /* console.log(response) */
      if (response.status!==200) {
        throw new Error('Server Error');
      }
      const data = await response.data;
      /* console.log(data); */
      return data === undefined ? null : data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getServicesForCategory = createAsyncThunk(
  'supplierRegistration/getServicesForCategory',
  async function (categoryId, {rejectWithValue}) {
    try {
      const response = await axios.get(`/category/${categoryId}/serviceTypes`);
      /* console.log(response) */
      if (response.status!==200) {
        throw new Error('Server Error');
      }
      const data = await response.data;
      let newDate = {data, categoryId}
      /* console.log(data); */
      return newDate === undefined ? null : newDate;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

// jwtAuthenticationResponseDto: 
    //   {accessToken
    //   refreshToken
    //   tokenType}

  export const saveSupplierContract = createAsyncThunk(
    'changeSupplier/saveSupplierContract',
    async ({contract, axiosPrivate}, {rejectWithValue}) => {
  
      try{
        const formData = new FormData();
          formData.append("file", contract["file"]);
          if(!contract.url) return
          const response = await axiosPrivate.post(`/supplier/personal/document/upload`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });
        // const {businessName, geoLocationsId, businessAddress, contractBusinessName, companyRegistrationNumber, businessDescription, contractRequired} = data1;
      
        // const response = await axiosPrivate.put(`/supplier/personal/business`, 
        // {businessName, geoLocationsId, businessAddress, contractBusinessName, companyRegistrationNumber, businessDescription, contractRequired});
        const data = await response.data;
        return data === undefined ? null : data;
      }catch (error){
        return rejectWithValue(error)
      }
  
    }
  );

  export const saveSupplierContractRegistration = createAsyncThunk(
    'changeSupplier/saveSupplierContract',
    async ({contract, token, supId}, {rejectWithValue}) => {
  
      try{
        const formData = new FormData();
          formData.append("file", contract["file"]);
          if(!contract.url) return
          const response = await axios.post(`/supplier/join/personal/document/upload?temporaryToken=${token}&supplierId=${supId}`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });
       const data = await response.data;
        return data === undefined ? null : data;
      }catch (error){
        return rejectWithValue(error)
      }
  
    }
  );

export const addSupplier = createAsyncThunk(
  'supplierRegistration/addSupplier',

  async function (supplierData, {rejectWithValue}) {
    const {data1, setAuth,
      //  images, 
      supplierServices,
      supplierUnicServices,
      venuesServices
    } = supplierData;
    console.log(setAuth)
    // separate images
    // let imagesToSend = images?.map((image) => {
    //   let newImage = {};
    //   newImage.file = image.file;
    //   newImage.order = image.order
    //   newImage.defaultImage = image.defaultImage;
    //   return newImage;
    // })
    // console.log(venuesServices)
    try {
      let newVenuesServices = venuesServices?.map(service => {
        let newService = {
            eventTypeId: service.eventTypeId,
            priceRangeFrom: service.priceRangeFrom,
            priceRangeTo: service.priceRangeTo,
            amountOfGuestsFrom: service.amountOfGuestsFrom,
            amountOfGuestsTo: service.amountOfGuestsTo,
            weekendEventId: service.weekendEvent?.id || 0,
            venueTypeId: service.venueType?.id || 0,
            kosherId: service.kosher?.id || 0,
            parkingOptionId: service.parkingOption?.id || 0,
            flashEvent: service.flashEvent,
            discount: service.discount,
          }
        
        return newService
      })
      // console.log(data1)
      let newSupplierData = {...data1}
      newSupplierData.geoLocationsId = newSupplierData?.geoLocationsId?.map((item) => {
        return item.id
      })
      newSupplierData.supplierServices = supplierServices;
      newSupplierData.supplierVenue = newVenuesServices;
      newSupplierData.supplierUnicServices = supplierUnicServices
      newSupplierData.hasDocument = (newSupplierData.hasDocument ? true: false)
      // delete space and '-'
      if(newSupplierData.phoneNumber.startsWith('+972')){
        newSupplierData.phoneNumber = newSupplierData.phoneNumber.replace(/^(\+972)/, '0')
      }
      newSupplierData.phoneNumber = (newSupplierData.phoneNumber).replace(/[\s-]/g, '');
      const response = await axios.post(`/supplier/join`, {...newSupplierData, 'images': []});
      // if (response.status!==200) {
      //   throw new Error('Server Error');
      // }
      const data = await response.data;

      if(data.supplierDto.id){
        sendZapierSupplierReg(data.supplierDto.id);
      }
      // const accessToken = data?.jwtAuthenticationResponseDto.accessToken;
      // const refreshToken = data?.jwtAuthenticationResponseDto.refreshToken;
      // // const supplierId = data?.supplierDto.id;
      // const roles = [data?.supplierDto.supplierType];
      // const user = JSON.stringify(data?.supplierDto);
      // if (accessToken && refreshToken && roles && user) {
      //   //setAuth({accessToken, refreshToken, user, roles})//
      // }

      // imagesToSend?.forEach(async(image) => {
      //   // const supplierId = data.id;
      //   // const newImage = image.file
      //   const formData = new FormData();
      //   formData.append("file", image["file"]);
      //   await axiosPrivate.post(`/supplier/upload/image?supplierId=${supplierId}&order=${image.order}&defaultImage=${image.defaultImage}`, formData, {
      //     headers: { "Content-Type": "multipart/form-data" },
      //   });
      // })



      /* console.log(data); */
      return data === undefined ? null : data;
    } catch (error) {
      console.log(error)
      return rejectWithValue(error)
    }
  }
);


const sendZapierSupplierReg = async(supplierId) => {
  await axios.post(`${process.env.REACT_APP_API_PATH}whatsapp?key=${process.env.REACT_APP_ZAPIER_CONTROLLER_KEY}&supId=${supplierId}&triggerType=${'COMPLETE_JOIN'}`)
  .then((response) => {
    })
    .catch((error) => {
    });
}

export const sendImages = createAsyncThunk(
  'supplierRegistration/sendImages',
  async function (data1, {rejectWithValue}) {
    try {
      const {supplierImages, axiosPrivate, supplierId} = data1;

      supplierImages?.forEach(async(image) => {
        const formData = new FormData();
        formData.append("file", image["file"]);
        if(!image.url) return
        await axiosPrivate.post(`/supplier/upload/image?supplierId=${supplierId}&order=${image.order}&defaultImage=${image.defaultImage}`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      })



      // const response = await axios.get(`/category/${categoryId}/serviceTypes`);
      /* console.log(response) */
      // if (response.status!==200) {
      //   throw new Error('Server Error');
      // }
      // const data = await response.data;
      // let newDate = {data, categoryId}
      /* console.log(data); */
      // return newDate === undefined ? null : newDate;
      return true
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);
export const sendImagesRegistration = createAsyncThunk(
  'supplierRegistration/sendImages',
  async function (data1, {rejectWithValue}) {

    function timeout(delay) {
      return new Promise( res => setTimeout(res, delay) );
    }
    try {
      const {supplierImages, token, supId} = data1;



      for (const [index, image] of supplierImages.entries()){
        if(index % 10 === 0 && index !== 0){
          await timeout(80)
        }
        const formData = new FormData();
        formData.append("file", image["file"]);
        if(image.url){
          try {
            await axios.post(`/supplier/join/upload/image?supplierId=${supId}&order=${image.order}&defaultImage=${image.defaultImage}&temporaryToken=${token}`, formData, {
              headers: { "Content-Type": "multipart/form-data" },
            });
          } catch (error) {
            
          }
          
        }
      }
      // supplierImages?.forEach(async(image) => {
      //   const formData = new FormData();
      //   formData.append("file", image["file"]);
      //   if(!image.url) return
      //   await axios.post(`/supplier/join/upload/image?supplierId=${supId}&order=${image.order}&defaultImage=${image.defaultImage}&temporaryToken=${token}`, formData, {
      //     headers: { "Content-Type": "multipart/form-data" },
      //   });
      // })
      return true
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const addLabel = createAsyncThunk(
  'supplierRegistration/addLabel',
  async (supplierData, {rejectWithValue}) => {
    // delete space and '-'
    if(supplierData.data1.phoneNumber.startsWith('+972')){
      supplierData.data1.phoneNumber = supplierData.data1.phoneNumber.replace(/^(\+972)/, '0')
    }
    supplierData.data1.phoneNumber = (supplierData.data1.phoneNumber).replace(/[\s-]/g, '');
    try{
      const {data1 } = supplierData;
      const {id,images, ...restSupplierData} = data1;
    
      const response = await axios.post(`/supplier/label/join`, {...restSupplierData});
      const data = await response.data;

      if(data){
        sendZapierSupplierReg(data.supplierDto.id);
      }
      // const accessToken = data?.jwtAuthenticationResponseDto.accessToken;
      // const refreshToken = data?.jwtAuthenticationResponseDto.refreshToken;
      // const roles = [data?.supplierDto.supplierType];
      // const user = JSON.stringify(data?.supplierDto);
      // if (accessToken && refreshToken && roles && user) {
      //   setAuth({accessToken, refreshToken, user, roles})
      // }

      return data === undefined ? null : data;
    }catch (error){
      return rejectWithValue(error)
    }

  }
);
export const sendYouImages = createAsyncThunk(
  'supplierRegistration/sendYouImages',
  async ({axiosPrivate, supplierYouImages}, {rejectWithValue}) => {
    let youImages = supplierYouImages?.map(image => {
      return image.url;
    })

    try{
      const response = await axiosPrivate.post(`/supplier/video`, youImages);
      const data = await response.data;
      return data === undefined ? null : data;
    }catch (error){
      return rejectWithValue(error)
    }

  }
);


export const sendYouImagesRegistration = createAsyncThunk(//
  'supplierRegistration/sendYouImages',
  async ({token, supId, supplierYouImages}, {rejectWithValue}) => {
    let youImages = supplierYouImages?.map(image => {
      return image.url;
    })
    let resultYouImages = youImages?.filter(image => image !== '')
    if(!Array.isArray(resultYouImages) || resultYouImages.length === 0){
      // console.log('Empty!');
    } else{
      try{
        const response = await axios.post(`/supplier/join/video?temporaryToken=${token}&supplierId=${supId}`, resultYouImages);
        const data = await response.data;
        return data === undefined ? null : data;
      }catch (error){
        return rejectWithValue(error)
      }
    }
  }
);

/*
"supplierServices": [
  {
    "categoryId": 0,
    "supplierEventDto": [
      {
        "eventId": 0,
        "flashEvent": true,
        "discount": 0,
        "supplierService": [
          {
            services: cate.services,
            isSelected: false,
            "serviceId": 0,
            "price": 0,
            "priceWithTax": 0
          }
        ]
      }
    ]
  }
]
*/


const supplierRegistrationSlice = createSlice({
  name: "supplierRegistration",
  initialState: {
    userIdToken: '',
    idToken: '',
    allEvents: [],
    allRegions: [],
    allCompanyTypes: [],
    allCategories: [],
    services:{},
    supplierServices:[],
    events:{},
    error: null,
    loadingStatus: null,
    isLabel: false,
    images: [],
    supplierUnicServices: [],
    supplierImages: [],
    supplierYouImages: [],
    venuesServices:[],
    // isRegistredLabel: false,
    // isEmailAccept: false,
    isRulsAccept: false,
    categoriesWithServices:[{id: 1,category: {}, minPrice: {}, minPriceWithTax: {}, services: {}, price: {}, priceWithTax: {}}]
  },
  reducers: {
    clearUserData(state, action) {
      state.userIdToken = '';
    },
    clearData(state, action) {
      state.idToken = '';
      state.allEvents = [];
      state.venuesServices = [];
      state.allRegions = [];
      state.allCompanyTypes = [];
      state.allCategories = [];
      state.services = {};
      state.supplierServices = [];
      state.supplierUnicServices = [];
      state.events = {};
      state.supplierYouImages = [];
      // state.isLabel = false;
      state.images = [];
      state.supplierImages = [];
      state.isRulsAccept = false;
      state.categoriesWithServices = [{id: 1 ,category: {}, services: {}, minPrice: {}, minPriceWithTax: {}, price: {}, priceWithTax: {}}];
    },
    // setIsEmailAccept(state, action) {
    //   state.isEmailAccept = true;
    // },
    // setIsRulsAccept(state, action) {
    //   state.isRulsAccept = true;
    // },
    setSupplierServices(state, action) {
      // console.log(action.payload)
      state.supplierServices = action.payload;
    },
    setVenuesServices(state, action) {
      // console.log(action.payload)
      state.venuesServices = action.payload;
    },
    setSupplierUnicServices(state, action) {
      // console.log(action.payload)
      state.supplierUnicServices = action.payload;
    },
    setEvents(state, action) {
      state.allEvents = action.payload;
    },
    setIsLabel(state, action) {
      state.isLabel = action.payload;
    },
    setCategoriesWithServices(state, action) {
      state.categoriesWithServices = action.payload;
    },
    setSupplierImages(state, action) {
      state.supplierImages = action.payload;
    },
    setSupplierYouImages(state, action) {
      state.supplierYouImages = action.payload;
    },
    setUserIdToken(state, action) {
      state.userIdToken = action.payload;
    },
    setIdToken(state, action) {
      state.idToken = action.payload;
    },
/*     setCurrentEvent(state, action) {
      console.log(action.payload)
      state.currentEvent = {...action.payload.currentEvent};
    },
    setIsNeedPlace(state, action) {
      state.isNeedPlace = action.payload;
    }, */
  },
  extraReducers: {
    [userGoogleLogin.fulfilled]: (state, action) => {
      state.userIdToken = action.payload;
    },
    [supplierGoogleLogin.fulfilled]: (state, action) => {
      state.idToken = action.payload;
    },
    [fetchAllEvents.pending]: (state, action) => {
      state.loadingStatus = "loading";
      state.error = null;
    },
    [fetchAllEvents.fulfilled]: (state, action) => {
      state.loadingStatus = "resolved";
      state.allEvents = action.payload;
    },
    [getRegions.fulfilled]: (state, action) => {

      state.allRegions = action.payload;
    },
    [addLabel.fulfilled]: (state, action) => {
      state.isRegistredLabel = true;
    },
    [addSupplier.fulfilled]: (state, action) => {
      state.id = action.payload.supplierDto.id;
    },
    [getCompanyTypes.fulfilled]: (state, action) => {

      state.allCompanyTypes = action.payload;
    },
    [getCategories.fulfilled]: (state, action) => {

      state.allCategories = action.payload;
    },
    [getServicesForCategory.fulfilled]: (state, action) => {
      let id = action.payload.categoryId
      let data = action.payload.data;
      state.services = {...state.services, [id]: data};
    },
    [fetchAllEvents.rejected]: (state, action) => {
      state.loadingStatus = 'rejected'
      state.error = action.payload;
    },   
  },
});

export const { 
  setEvents,
  setIsLabel,
  setCategoriesWithServices,
  setSupplierServices,
  setSupplierUnicServices,
  setSupplierImages,
  setSupplierYouImages,
  setVenuesServices,
  // setIsEmailAccept,
  // setIsRulsAccept,
  clearData,
  clearUserData,
  setUserIdToken,
  setIdToken,
} = supplierRegistrationSlice.actions;

export default supplierRegistrationSlice.reducer;
