import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { addLabel } from "../../../store/supplierRegistrationSlice";
import styles from "./genLabel.module.scss";
import classNames from "classnames";
import useAuth from "../../../hooks/useAuth";
import { setErr } from "../../../store/forAll/errSlice";
import { setLoader } from "../../../store/loaderSlice";
const GenLabel = (props) => {
  const {changeStep} = props;
  const dispatch = useDispatch();
  const { getValues } = useFormContext({});
  const { setAuth } = useAuth();

  let {isRegistredLabel} = useSelector((state) => state.supplierRegistration)

  const getLabel = () => {
    if(isRegistredLabel) return
    dispatch(setLoader(true));
    dispatch(addLabel ({data1: getValues(), setAuth})).then((res) => {
      if(res?.error){
        dispatch(setErr({
          message: res?.payload?.response?.data?.message, 
          code: res?.payload?.response?.data?.code
        }))
      }
      if(changeStep){
        changeStep()
      }
      
    }).finally(() => {
      dispatch(setLoader(false));
    })
  }
  


  return <div className={styles.container}>
    <div className={styles.getBtnContainer}>
      <div onClick={() => getLabel()} className={styles.getButton}>
        הפק לי קוד לייבל  
      </div>
    </div>
    {
    isRegistredLabel 
    && 
      <>
        <div className={classNames(styles.descr,styles.desctop)}>
          <span className={styles.title}>.קוד הלייבל נשלח אליך ישירות למייל</span>
          <span className={styles.row}>,יש להעביר את הקוד שקבלת לכלל הספקים שנרשמים תחת הלייבל</span>
          <span className={styles.row}>על מנת שיוכלו להתקדם ולהקים משתמש אצלנו</span>
        </div>
        <div className={classNames(styles.descr,styles.mobile)}> 
          <span className={styles.title}>.קוד הלייבל נשלח אליך ישירות למייל</span>
          <span className={styles.row}>יש להעביר את הקוד שקבלת לכלל הספקים,</span>
          <span className={styles.row}>שנרשמים תחת הלייבל על מנת שיוכלו להתקדם</span>
          <span className={styles.row}>ולהקים משתמש אצלנו</span>
        </div>
        {/* <div className={styles.nextBtnContainer}>
          <div className={styles.nextButton}>
            אישור והמשך 
          </div>
        </div> */}
      </>  
    }
    

  </div>
}

export default GenLabel