import styles from "./inputWithArrows.module.scss";
import left from "../img/left.png";
import right from "../img/right.png";

const InputWithArrows = (props) => {
  const { onChange, dataStr, noArrows} = props;
  return (
    <div
    // style={{backgroundColor}}
     className={styles.wrapper}>
      {noArrows ? (
        <div></div>
      ) : (
        <div onClick={() => {dataStr && onChange(0)}} className={styles.arrow}>
          <img alt="right" src={right} />
        </div>
      )}
      <div>{dataStr}</div>
      {noArrows ? (
        <div></div>
      ) : (
        <div onClick={() => {dataStr && onChange(1)}} className={styles.arrow}>
          <img alt="left" src={left} />
        </div>
      )}
    </div>
  );
};

export default InputWithArrows