import { createSlice } from '@reduxjs/toolkit';

const featuresPopupSlice = createSlice({
  name: 'featuresPopup',
  initialState: {
    showPopup: false,
    initialVisit: true,
  },
  reducers: {
    showPopupFunc: (state) => {
      state.showPopup = true;
    },
    hidePopup: (state) => {
      state.showPopup = false;
    },
    setInitialVisit: (state, action) => {
      state.initialVisit = action.payload;
    },
    setIntervalId: (state, action) => {
      state.intervalId = action.payload;
    },
  }
});

export const { showPopupFunc, hidePopup, setInitialVisit, setIntervalId } = featuresPopupSlice.actions;
export default featuresPopupSlice.reducer;