import { createTheme } from '@mui/material';
import { heIL } from '@mui/material/locale';
import ArrowSelect from '../assets/images/arrowDown.png';
export const theme = createTheme(
  {
    components: {
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            fontSize: '18px',
            // padding: '12px 24px',
            borderRadius: '11px',
            [theme.breakpoints.down('sm')]: {
              fontSize: '14px',
              lineHeight: '21px',
            },
            ':hover': {
              scale: '1.05',
              boxShadow: '0px 0px 0px rgba(0,0,0,0.25)',
            },
            ...(ownerState.variant === 'contained' && {
              // padding: '15px 0px 14px',
              maxWidth: '131px',
              width: '100%',
              // height: '53px',
              backgroundColor: '#405F6A',
              color: '#F7F7F7 ',
              fontSize: '16px',
              lineHeight: '27px',
              transition: '0.5s',
              ':hover': {
                backgroundColor: '#405F6A',
                // backgroundColor:,
                // scale: '1.025',
                // boxShadow: '0px 0px 0px rgba(0,0,0,0.25)',
              },
              '&.Mui-disabled': {
                backgroundColor: '#405F6A',
                color: '#F7F7F7',
                opacity: '0.65',
              },
              [theme.breakpoints.down('sm')]: {
                // maxWidth: '110px',
                // height: '42px',
                fontSize: '14px',
                lineHeight: '21px',
              },
            }),
            ...(ownerState.variant === 'outlined' && {
              maxWidth: '131px',
              width: '100%',
              // height: '53px',
              // padding: '15px 0px 14px',
              backgroundColor: '#F7F7F7',
              color: 'black',
              fontSize: '16px',
              border: '1px solid #405F6A',
              lineHeight: '27px',
              transition: '0.5s',
              ':hover': {
                backgroundColor: '#F7F7F7',
                // scale: '1.025',
                // boxShadow: '0px 0px 0px rgba(0,0,0,0.25)',
                border: '1px solid #405F6A',
              },
              [theme.breakpoints.down('sm')]: {
                // maxWidth: '110px',
                // height: '42px',
                fontSize: '14px',
                lineHeight: '21px',
              },
            }),
          }),
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            maxWidth: '272px',
            width: '100%',
            borderRadius: '11px',
            backgroundColor: '#F7F7F7',
            '.MuiSelect-select': { display: 'flex', alignItems: 'center' },
            '.MuiOutlinedInput-input': {
              padding: '0px 24px !important',
              height: '100% !important',
              fontSize: '16px',
              lineHeight: '24px',
            },
            '.MuiOutlinedInput-notchedOutline': {
              border: '1px solid #405F6A',
              borderRadius: '11px',
            },
            '.MuiSelect-icon': {
              right: 'unset',
              left: '26.35px',
            },
            [theme.breakpoints.down('sm')]: {
              maxWidth: '227px',
              '.MuiOutlinedInput-input': {
                fontSize: '14px',
                lineHeight: '21px',
                padding: '0px 16px !important',
              },
            },
          }),
        },
        defaultProps: {
          IconComponent: ({ className }) => {
            return <img className={className} src={ArrowSelect} alt="arrow"></img>;
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            maxWidth: '272px',
            width: '100%',
            borderRadius: '11px',
            // height: '53px !important',

            '.MuiOutlinedInput-root': {
              position: 'relative',
              height: '100%',
              backgroundColor: '#F7F7F7',
              borderRadius: '11px',
              // overflow: 'hidden',
              // height: '53px',
            },
            '&.MuiTextField-root .MuiOutlinedInput-input': {
              fontSize: '16px',
              lineHeight: '24px',
              padding: '0px 24px ',
              height: '100%',
              // boxSizing: 'border-box',
            },
            '& input::placeholder': {
              fontSize: '16px',
            },
            '.MuiOutlinedInput-notchedOutline': {
              border: '1px solid #405F6A',
              borderRadius: '11px',
            },
            '.MuiFormHelperText-root': {
              position: 'absolute',
              left: '0px',
              bottom: '-10px',
              transform: 'translate(0,50%)',
              fontSize: '12px',
            },
            // p: {
            //   fontSize: '12px',
            // },
            [theme.breakpoints.down('sm')]: {
              // maxWidth: '227px',
              // maxHeight: '42px',
              '.MuiOutlinedInput-root': {
                // height: '42px',
                // minHeight: 'unset',
              },
              '.MuiFormHelperText-root': {
                fontSize: '10px',
              },

              '&.MuiTextField-root .MuiOutlinedInput-input': {
                fontSize: '14px',
                // height: '42px',
                padding: '0px 16px 0px 25px',

                lineHeight: '21px',
                // minHeight: 'unset',
              },
              '& input::placeholder': {
                fontSize: '14px',
              },
            },
          }),
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            '.MuiOutlinedInput-root .MuiAutocomplete-input': {
              padding: '0px !important',
              backgroundColor: 'unset !important',
            },

            '.MuiFormHelperText-root': {
              fontSize: '12px',
            },
            '&.MuiAutocomplete-root .MuiOutlinedInput-root': {
              padding: '0px 24px',

              backgroundColor: '#F7F7F7',
              borderRadius: '11px',
            },
            [theme.breakpoints.down('sm')]: {
              '&.MuiTextField-root .MuiOutlinedInput-input': {
                padding: '0px',
              },
              '&.MuiAutocomplete-root .MuiOutlinedInput-root': {
                padding: '0px 16px',
              },

              // '.MuiOutlinedInput-root': {
              //   padding: '0px',
              // },
              // '.MuiOutlinedInput-input': {
              //   padding: '0px 4px 0px 6px',
              // },
              '.MuiFormHelperText-root': {
                fontSize: '10px',
              },
            },
          }),
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            'Mui-error': {
              fontSize: '12px',
            },
            '.MuiFormHelperText-root': {
              fontSize: '12px !important',
            },
          }),
        },
      },
      MuiDrawer: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            '&.MuiDrawer-root': {
              top: '60px',
            },
            '.MuiBackdrop-root': {
              top: '60px',
            },
            '.MuiDrawer-paper': {
              top: '60px',
              width: '100%',
              maxWidth: '344px',
              height: 'calc(100vh - 60px)',
            },
          }),
        },
      },
    },
    typography: {
      fontFamily: 'Heebo-Regular',
      color: 'black',
    },
  },
  heIL
);
