import React, { useEffect, useRef, useState } from "react";
import styles from "./multiSelect.module.scss";
import arrow from "../../../assets/images/for-pages/caret-down.png"
import arrowUp from "../../../assets/images/for-pages/caret-up.png"

export default function MultiSelect(props) {
  const {fontSize, invalid, justifyContent, backgroundColor, borderRadius, selectedColor, fontFamily, paddingInlineStart, paddingInlineEnd, color, flexDirection} = props;

  const main = {
    outline: (invalid ? ".06rem solid red" : ''),
    borderRadius,
    backgroundColor: (invalid ? "#f3e8e8" : backgroundColor),
  }

  const dropDownContainer = {
    // width, height,
  }

  const headerOption = {
    'color': props?.selectedOptions?.length ? (selectedColor || "#242323") : '#9B9999',
    // 
    justifyContent,
    width:"100%",
  }

  const dropDownList = {
    // backgroundColor,
    // width,
  }

  const dropDownHeader = {
    paddingInlineStart,
    paddingInlineEnd,
    flexDirection
  }


  const dropDownListOption = {
    justifyContent,
    fontSize,
    color,
    // "&:hover": {
    //   "color": selectedColor,
    //   fontFamily,
    // }
  }

  const rootEl = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = (value) => {
    let newSelectedOptions = props?.selectedOptions?.length ? [...props?.selectedOptions] : [];
    let index = -1;
    newSelectedOptions.forEach((elem, index1) => {
      if(elem.id === value.id ){
        index = index1;
      }
    });
    if(index === -1){
      newSelectedOptions.push(value)
    }else {
      newSelectedOptions.splice(index, 1);
    }
    props?.onChangeOption(newSelectedOptions)
    // setIsOpen(false);
  };

  useEffect(() => {
    const onClick1 = e => rootEl.current.contains(e.target) || setIsOpen(false);
    document.addEventListener('click', onClick1);
    return () => document.removeEventListener('click', onClick1);
  }, []);

  useEffect(() => {
    
  }, [props]);

  return (
    <div 
      ref={rootEl}
      className={styles.main}
      style={main}
    >
      <div style={dropDownContainer} className={styles.dropDownContainer}>
        <div className={styles.dropDownHeader} onClick={toggling} style={dropDownHeader}>
          
          <span style={headerOption} className={styles.headerOption}>
            {(props?.selectedOptions?.length && props?.selectedOptions[0].name) || props.placeholder ||"בחר מתוך הרשימה"}
          </span>
          <img alt="arrow" src={isOpen ? arrowUp : arrow} className={styles.arrow} />
        </div>
        {isOpen && (
          <div className={styles.dropDownListContainer}>
            <div 
              style={dropDownList}
              className={styles.dropDownList}
            >
              {props?.options?.map(option => (
                  <div 
                    style={
                      dropDownListOption
                    }                  
                    className={styles.listItem} 
                    onClick={(e) => {onOptionClicked(option); e.stopPropagation()}} 
                    key={Math.random()}>
                  <span 
                    style={{
                      "fontFamily": (props.selectedOptions?.some((elem)=> elem.id === option?.id) ? (fontFamily||"Heebo-Bold") : ""),
                      "color": (props.selectedOptions?.some((elem)=> elem.id === option?.id) ? (selectedColor ) : ""),
                  }}
                  >
                    {option.name}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
