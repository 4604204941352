import { useDispatch, useSelector } from 'react-redux';
import axios from '../../../../../api/axios';
import useAuth from '../../../../hooks/useAuth';
import { fetchCategoriesForEvent } from '../../../../store/suppliersSlice';
import { TEMP_USER_KEY } from '../../../../consts/local_keys';
import { useFormContext } from 'react-hook-form';
import Category from './Category';
import { useEffect, useLayoutEffect } from 'react';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import styles from '../../styleSuppliers.module.scss';
import { setLoader } from '../../../../store/loaderSlice';
import { setCurrentCategory } from '../../../../store/mainSlice';

const Categories = () => {
  const { auth } = useAuth();
  const currentRole = Object.keys(auth).length !== 0 && auth?.roles?.length ? auth?.roles[0] : '';
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const { setValue,watch } = useFormContext();
  const watchCategory = watch('category')
  const { currentCategory, isNeedPlace } = useSelector((state) => state.main);
  const { categories, selectedEventTypeId, suppliersFilters } = useSelector(
    (state) => state.suppliers
  );
  const generateURL = (currentEventId, auth) => {
    let baseURL = '/eventType/suppliers/categories';
    if (currentEventId) {
      baseURL = baseURL + '?eventTypeId=' + currentEventId;
      if (!currentRole) {
        baseURL = baseURL + '&userToken=' + localStorage.getItem(TEMP_USER_KEY);
      }
    } else {
      if(!currentRole) baseURL = baseURL + '?userToken=' + localStorage.getItem(TEMP_USER_KEY);
    }
    if (!!suppliersFilters.eventDate)
      baseURL = baseURL + `&eventDate=` + suppliersFilters.eventDate;
    return baseURL;
  };
  const fetch = async () => {
    dispatch(setLoader(true));
    await (currentRole ? axiosPrivate : axios)
      .get(generateURL(selectedEventTypeId, auth))
      .then((response) => {
        if (response.status !== 200) {
          throw new Error('Server Error');
        } else {
          if (isNeedPlace) {
            setValue(
              'category',
              response.data.find((oneItem) => oneItem.id === 1)
            );
          }
          dispatch(fetchCategoriesForEvent({ response }));
        }
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };
  const checkExists = (categories,currentCategory) => {
    const cat = categories?.find(catOne => catOne?.id === currentCategory?.id)
    if(cat) return true
   return false
  }
  const footerSelect = () => {
    const category = {
      id: Number(localStorage.getItem('idCategory')),
      name: localStorage.getItem('nameCategory'),
    };
    if(checkExists(categories,category)){
      setValue('category', category);
      dispatch(
        setCurrentCategory({
          currentCategory: category,
        })
      )
    }
    setTimeout(() => {
      localStorage.removeItem('idCategory');
      localStorage.removeItem('nameCategory');
      localStorage.removeItem('guestAmount');
    }, 500);
  };
  //get data
  useEffect(() => {
    fetch();
  }, [selectedEventTypeId, suppliersFilters]);
  //init category
  useEffect(() => {
    if (!isNeedPlace && localStorage.getItem('idCategory') !== null) {
      footerSelect();
    }
    else{
        // init by current categories
      if(!!currentCategory && (!watchCategory || (currentCategory?.id !== watchCategory?.id))){
        if(checkExists(categories,currentCategory)){
          setValue('category',currentCategory)
        }
        else{
          dispatch(
            setCurrentCategory({
              currentCategory: "",
            })
          )
        }
      } 
    }
  }, [categories]);

  return (
    <section id="categories" className={styles.categories_container}>
      {categories?.map((oneCategory) => (
        <Category key={`one-category-${oneCategory.id}`} item={oneCategory} />
      ))}
    </section>
  );
};
export default Categories;
