import styles from "./Payment.module.scss";
import close from "../../../../assets/images/for-pages/icons/closeDialog.png";
import dayjs from "dayjs";
import he from 'dayjs/locale/he'; // load on demand
import localizedFormat from 'dayjs/plugin/localizedFormat'; 
import { useNavigate } from "react-router";

function Payment(props) {
  dayjs.locale(he);
  dayjs.extend(localizedFormat)
  const navigate = useNavigate();
  const {isOpen, value, closePopup, customRedirect} = props;    

const goToDocuments = async() => {
  customRedirect ? customRedirect(false) : navigate("/personal/documents");
}

const goToChecklist = async() => {
  navigate("/personal/checklist");
}

const closeForm = () => {
  customRedirect ? customRedirect(false) : closePopup();
};

  return (
    <>
      { isOpen && (
        <>
        <div className={styles.dialogWrapper2}>
            <div className={styles.closeDialog}>
              <img
                onClick={(e) => {
                  closeForm();
                }}
                alt="closeImage"
                src={close}
              />
            </div>
          <div className={styles.mainDialog}>
            <div className={styles.contentSecondDialog}>
              <div className={styles.content}>
                <div className={styles.secondTitle}>בוצע בהצלחה {value} תשלום עבור </div>
                <div className={styles.secondContent}>נתונים עודכנו בדף הצ׳קליסט</div>
                <div className={styles.buttonsContent}>
                  <button onClick={() => {goToDocuments();}} className={styles.leftBtn}>חזרה למסמכים</button>
                  <button onClick={() => {goToChecklist();}} className={styles.rightBtn}>מעבר לצ׳קליסט שלי</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      )}
    </>
  );
}
export default Payment;
