import { Box, Typography } from '@mui/material';
import React from 'react';
import oneStar from '../../../../../assets/images/homePage/feedback/oneStar.png';
import styles from '../styleFeedback.module.scss';
const Card = ({ card }) => {
  return (
    <div id={`feedback-card-${card.id}`} className={styles.one_card}>
      <Typography className={styles.card_title}>{card.title}</Typography>
      <Box sx={{ marginTop: '10px', display: 'flex', gap: '8px' }}>
        {Array(card.howMuchStars)
          .fill()
          .map((e, i) => (
            <img src={oneStar} key={`one-star-${i}-card-${card.id}`} alt="star" />
          ))}
      </Box>
      <Typography className={styles.card_message}>{card.message}</Typography>
      <Typography className={styles.card_who}>{card.who}</Typography>
      <Typography className={styles.card_date}>{card.date}</Typography>
    </div>
  );
};

export default Card;
