import { Button, CircularProgress, Typography } from '@mui/material';
import styles from '../styleLoginWithPhone.module.scss';
import CustomTelInput from '../../customTelInput/CustomTelInput';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAuthPhoneNumber,
  selectIsCodeSent,
  selectSendLoading,
  sendCode,
  setIsCodeSent,
} from '../../../store/authCodeSlice';
import { getFormattedPhone } from '../../../helperFunctions/phone.help';
import CustomCheckBox from '../../customCheckbox/CustomCheckBox';
import { useEffect, useState } from 'react';
const PhoneStep = (props) => {
  const dispatch = useDispatch();
  const isCodeSent = useSelector(selectIsCodeSent);
  const { formState, watch } = useFormContext();
  const [sendLoading, setSendLoading] = useState(false);
  const statePhone = useSelector(selectAuthPhoneNumber);
  const watchPhone = watch('phoneNumber');
  const onConfirm = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSendLoading(true);
    dispatch(sendCode(getFormattedPhone(watchPhone)))
      .then((data) => {
        if (!!data?.error) return;
        dispatch(setIsCodeSent(true));
      })
      .finally(() => setSendLoading(false));
  };
  useEffect(() => {
    const currentPhone = `+${getFormattedPhone(watchPhone)}`;
    if (statePhone !== currentPhone && isCodeSent) {
      dispatch(setIsCodeSent(false));
    }
    if (statePhone === currentPhone && !isCodeSent) {
      dispatch(setIsCodeSent(true));
    }
  }, [watchPhone]);
  const [isAgree, setIsAgree] = useState(isCodeSent ? true : false);
  return (
    <>
      <Typography className={styles.phone_title}>משתמש לא רשום</Typography>
      <Typography className={styles.description}>
        על מנת להתחבר בצורה מהירה, הכנס מספר טלפון ונשלח אליך קוד אימות
      </Typography>
      <div style={{ width: '100%', margin: '0 auto', marginTop: '9px' }}>
        <CustomTelInput
          name={'phoneNumber'}
          disabledFlag
          preferredCountries={['IL']}
          defaultCountry={'IL'}
          sx={{ maxWidth: 'unset !important', height: '54px' }}
        />
      </div>
      <div className={styles.checkBox_container}>
        <CustomCheckBox
          value={isAgree}
          onChange={() => {
            setIsAgree((prev) => !prev);
          }}
          variant="circle_agree"
          label="קראתי ואני מאשר את תנאי השימוש"
        />
      </div>
      <Button
        disabled={
          watchPhone.length < 3 || !!formState.errors?.phoneNumber || !isAgree || isCodeSent
        }
        onClick={onConfirm}
        type={isCodeSent ? 'button' : 'submit'}
        className={classNames(styles.phone_button, styles.steps_button)}
        variant="contained"
      >
        {sendLoading ? <CircularProgress size={16} /> : 'המשך'}
      </Button>
    </>
  );
};
export default PhoneStep;
