import styles from "./Contacts.module.scss";
import Header from "../../components/header/Header";
import HeaderMobile from "../../components/headerMobile/HeaderMobile";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import classNames from "classnames";
import React from 'react';
import { useState } from 'react'
import Dialog from "@mui/material/Dialog";
import { useNavigate } from "react-router";
import ChecklistButton from "../../components/checklistButton/checklistButton";
import MetaContactUs from "../../components/metaComponents/metaContactUs";
import { setErr } from "../../store/forAll/errSlice";
import { useDispatch } from "react-redux";
import { useAnalyticsEvents } from "../../hooks/useAnalyticsEvents";
import WhatsApp from "../../components/whatsApp/whatsApp";

function Contacts() {
  const navigate = useNavigate();
  const {ContactForm} = useAnalyticsEvents();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const openDialog = () => {
    setOpen(true)
  }

  const schema = yup
  .object()
  .shape({
    name: yup
    .string()
    .min(2, 'לפחות 2 אותיות')  // min char 2
    .required('הכנס שם משתמש'), //name required
    phoneNumber: yup
    .string()
    .matches(/^\d+$/, 'רק ספרות') // ONLY_NUMBERS
    .min(9, 'לפחות 9 ספרות') // 
    .max(10, 'מקסימום 10 ספרות') // MAX_CHAR_10
    .required('שדה חובה') //  FIELD_REQUIRED
    .test(
      'leading-zero',
        'מספר ראשון יכול להיות 0', // LEDAING_ZERO
      (value, context) => {
        return context.originalValue && context.originalValue.startsWith(0);
      }
    ),
    email: yup
      .string()
      .email("רשום מייל תקין") // 'Enter a valid email'
      .required("שדה חובה"), // 'You must enter a email'
    question: yup
      .string()
      .required("שדה חובה") // 'Please enter your password.'
      .min(10, 'לפחות 10 ספרות') // 
      .max(5000, 'מקסימום 5000 ספרות') // MAX_CHAR_5000
  }).required();

  const defaultValues = {
    name: "",
    phoneNumber: "",
    email: "",
    question: "",
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const submitFeedback = async (data) => {

    const {email, name, phoneNumber, question} = data
    const obj = {email, name, phoneNumber, question}
    ContactForm(obj);

    try{
    const result = await axios.post(
      `${process.env.REACT_APP_API_PATH}main/contact/us`,
      {
        email,
        customer_name: name,
        customer_phone: phoneNumber,
        question
      }
      // data
    );
    if(result){
      openDialog();
    } } catch(e){
        dispatch(
          setErr({
            message: e?.response?.data?.message,
            code: e?.response?.data?.code,
          }))
      }
  };


    return (
      <>
      {/* <WhatsApp/> */}
      <MetaContactUs/>
    <section className={styles.wr} >
      <Header />
      <HeaderMobile />
      <ChecklistButton/>
    <h2 className={styles.mobleTitle}>צרו קשר</h2>
    <h3 
      className={classNames(
        styles.desctop,styles.description
      )}>
    {`לכל שאלה הכוונה הדרכה, או סתם כי בא לכם לדבר .`} <br/>
     {` פנו אלינו באימייל ` } 
      <a
        href="mailto:admin@event-do.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        {"admin@event-do.com"}
      </a>
      {` או באמצעות ‌ ‌‌הטופס‌‌ ‌המצורף ‌`}
      <br/>
    </h3>
    <h3
      className={classNames(
        styles.mobile,styles.description
      )}
    >
      {'לכל שאלה הכוונה הדרכה,'}<br/>
      {'או סתם כי בא לכם לדבר'}<br/>
      
      {'פנו אלינו באימייל'}<br/>
        <a
        href="mailto:admin@event-do.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        {"admin@event-do.com"}
      </a><br/>
      {'או באמצעות ‌ ‌‌הטופס‌‌ ‌המצורף ‌'}{"."}
      <br/>

    </h3>
    <div className={styles.contactsBlock}>
    <h2 className={styles.mobleFormTitle}>{`רוצים לדעת יותר? הזינו פרטים ונחזור אליכם`}</h2>
    
    <form className={styles.regForm}
        onSubmit={handleSubmit(submitFeedback)}>
    <div className={styles.firstBlock}>
      <span>
      <input type="text" placeholder="שם מלא"
        {...register("name")}
        className={classNames({
          [styles.invalidField]: errors.name,
        },styles.input)} />
        {errors.name && (
          <p className={styles.errorMessage}>
            {errors.name.message}
          </p>
        )}
      </span>
      <span className={styles.inputPhone}>
      <input type="text" placeholder="מספר טלפון"
        {...register("phoneNumber")}
        className={classNames({
          [styles.invalidField]: errors.phoneNumber,
        },styles.input)} />
        {errors.phoneNumber && (
          <p className={styles.errorMessage}>
            {errors.phoneNumber.message}
          </p>
        )}
      </span>
    </div>
    <div className={styles.secondBlock}>
      <span>
      <input type="email" placeholder="כתובת מייל"
        {...register("email")}
        className={classNames({
          [styles.invalidField]: errors.email,
        },styles.input)} />
        {errors.email && (
          <p className={styles.errorMessage}>
            {errors.email.message}
          </p>
        )}
        </span>
    </div>
    <div className={styles.thirdBlock}>
      <span>
      <textarea placeholder="נושא הפנייה"
        {...register("question")}
        className={classNames({
          [styles.invalidField]: errors.question,
        },styles.input)}></textarea>
        {errors.question && (
          <p className={styles.errorMessageBlock}>
            {errors.question.message}
          </p>
        )}
      </span>
    </div>
    <div className={styles.btnBlock}>
      <button type="submit" className={styles.button}>שלח</button>
    </div>
    </form>
    </div>
    {/*  onClick={() => navigate(-1)} */}
    <Dialog hideBackdrop={true} maxWidth={'lg'} open={open} fullWidth={true} className={styles.infoDialog} 
    >
      <div className={styles.dialog}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25.477"
        height="25.47"
        viewBox="0 0 25.477 25.47"
        onClick={() => navigate(-1)}
        className={classNames(
          styles.closeIcon,
          styles.closeIconDesktop
        )}>
        <path
          id="Icon_ionic-ios-close"
          data-name="Icon ionic-ios-close"
          d="M27.042,24.024l9.1-9.1a2.132,2.132,0,1,0-3.015-3.015l-9.1,9.1-9.1-9.1a2.132,2.132,0,1,0-3.015,3.015l9.1,9.1-9.1,9.1a2.132,2.132,0,0,0,3.015,3.015l9.1-9.1,9.1,9.1a2.132,2.132,0,0,0,3.015-3.015Z"
          transform="translate(-11.285 -11.289)"
          fill="#242323"
        />
      </svg>
      <div className={styles.dialogTitle}>‌תודה על פנייתך!‌ </div>
      <div className={styles.dialogDescription}>‌פנייתך נשלחה אלינו ‌
        ‌בהצלחה,‌ 
        ‌מבטיחים ליצור איתך ‌
        ‌קשר בהקדם ‌
        ‌האפשרי 
      </div>
      </div>
    </Dialog>
    </section>
    </>
    );
}   
export default Contacts;