import {useEffect, useState} from "react";
import shekelImg from "../../../../../assets/images/icon-payment-ils.png";
import styles from "./Venue.module.scss";
import classNames from "classnames";
import {Controller, useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import Select from "../../../../components/selectComponent/Select";
import {useDispatch, useSelector} from "react-redux";
import {
  getKosher,
  getParkingOption,
  getVenueType,
  getWeekends,
} from "../../../../store/personalSupplier/changeSupplierSlice";
import {setErr} from "../../../../store/forAll/errSlice";
import {setVenuesServices} from "../../../../store/supplierRegistrationSlice";

const Venue = (props) => {
  let {venuesServices} = useSelector((state) => state.supplierRegistration);
  const dispatch = useDispatch();
  const {curentEvent,curentCategory, isMobile, setIsVenueError} = props;

  let [curentVenue, setCurentVenue] = useState({});
  let [isAdded, setIsAdded] = useState(false);

  let [weekends, setWeekends] = useState([]);
  let [venueType, setVenueType] = useState([]);
  let [kosher, setKosher] = useState([]);
  let [parkingOption, setParkingOption] = useState([]);
  let [isShowError, setIsShowError] = useState(false);
 
  const schema = yup.object().shape({


    contractBusinessName: yup.string().nullable(true),

    companyRegistrationNumber: yup.lazy((value) =>
      value === ""
        ? yup.string()
        : yup.number().typeError("רק ספרות").nullable(true)
    ),
    prePayment: yup.number().min(1, "רק ספרות").typeError("רק ספרות"),
  });
  const methods = useForm({
    mode: "all",

    resolver: yupResolver(schema),
  });

  const {control} = methods;
  // const {errors} = formState;

  const checkIsShowError = (curentVenue1 = curentVenue) => {
    let isError = false;
    console.log('curentVenue1', curentVenue1);
    if(curentVenue1?.amountOfGuestsTo<=curentVenue1?.amountOfGuestsFrom||
    curentVenue1?.amountOfGuestsTo<=curentVenue1?.amountOfGuestsFrom ||
    curentVenue1.amountOfGuestsFrom < 1 ||
    curentVenue1.amountOfGuestsTo < 1 ||
    curentVenue1.priceRangeFrom < 1 ||
    curentVenue1.priceRangeTo < 1 ||
    curentVenue1.weekendEvent?.id !== undefined ||
    curentVenue1.venueType?.id !== undefined ||
    curentVenue1.kosher?.id !== undefined ||
    curentVenue1.parkingOption?.id !== undefined ||
    curentVenue1.discount ){
    isError = true
    }
    // console.log(isError)
    setIsShowError(isError)
    if( isError && 
      (curentVenue1.weekendEvent?.id === undefined ||
      curentVenue1.venueType?.id === undefined ||
      curentVenue1.kosher?.id === undefined ||
      curentVenue1.parkingOption?.id === undefined )){
        // console.log(1)
        return true
      }else{
        // console.log(2)
        return false
      }
  }

  const setFirstVenuesArr = () => {
    checkError()
    if (!venuesServices?.length) {
      let newVenuesArr = [];
      curentCategory?.eventTypes?.forEach((event) => {
        let newItem = {
          eventTypeId: event.id,
          priceRangeFrom: 0,
          priceRangeTo: 0,
          amountOfGuestsFrom: 0,
          amountOfGuestsTo: 0,
          weekendEventId: 0,
          venueTypeId: 0,
          kosherId: 0,
          menuTypeId: 0,
          parkingOptionId: 0,
          flashEvent: true,
          discount: 0,
        };
        newVenuesArr.push(newItem);
      });
      dispatch(setVenuesServices(newVenuesArr));
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(()=>{return()=>setFirstVenuesArr()},[])
  const getFiltersData = () => {
    dispatch(getKosher()).then((res) => {
      if (res?.error) {
        dispatch(
          setErr({
            message: res?.payload?.response?.data?.message,
            code: res?.payload?.response?.data?.code,
          })
        );
      } else {
        setKosher(res.payload);
        // console.log(res);
      }
    });
    dispatch(getParkingOption()).then((res) => {
      if (res?.error) {
        dispatch(
          setErr({
            message: res?.payload?.response?.data?.message,
            code: res?.payload?.response?.data?.code,
          })
        );
      } else {
        setParkingOption(res.payload);
        // console.log(res);
      }
    });
    dispatch(getWeekends()).then((res) => {
      if (res?.error) {
        dispatch(
          setErr({
            message: res?.payload?.response?.data?.message,
            code: res?.payload?.response?.data?.code,
          })
        );
      } else {
        setWeekends(res.payload);
      }
    });
    dispatch(getVenueType()).then((res) => {
      if (res?.error) {
        dispatch(
          setErr({
            message: res?.payload?.response?.data?.message,
            code: res?.payload?.response?.data?.code,
          })
        );
      } else {
        setVenueType(res.payload);
        // console.log(res);
      }
    });
  };

  const setVenueData = (data) => {
    checkError()
    let venuesArr = venuesServices?.map((item) => {
      let newItem = item;
      if (item.eventTypeId === curentEvent.id) {
        return data;
      } else {
        return newItem;
      }
    });
    dispatch(setVenuesServices(venuesArr));
    setCurentVenue({...getOneVenue(curentEvent?.id, venuesArr)});
  };

  const getOneVenue = (id, arr) => {
    let newVenue = (arr ? arr : venuesServices)?.find((item) => {
      return item.eventTypeId === id;
    });
    // console.log(newVenue);
    return {...newVenue};
  };
  const checkError = (newVenueData) => {
    console.log('newVenueData', newVenueData);
    if(
      (!(newVenueData?.amountOfGuestsTo>=newVenueData?.amountOfGuestsFrom)) || 
      checkIsShowError(newVenueData) ||
      (!(newVenueData?.priceRangeTo>=newVenueData?.priceRangeFrom)) || 
      newVenueData?.amountOfGuestsFrom < 1 || newVenueData?.amountOfGuestsTo <1||
      newVenueData?.priceRangeFrom < 1 || newVenueData?.priceRangeTo <1
    ){
      console.log("error")
      return true
    }else{
      return false
    }
  }
  useEffect(() => {
    if(curentVenue?.amountOfGuestsFrom > 1 || curentVenue?.amountOfGuestsTo > 1||
      curentVenue?.priceRangeFrom > 1 || curentVenue?.priceRangeTo > 1 ||  curentVenue.weekendEvent?.id ||
      curentVenue.venueType?.id ||
      curentVenue.kosher?.id ||
      curentVenue.parkingOption?.id){
        checkIsShowError();
    }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [curentVenue]);
  const setField = (option, number) => {
    let newVenueData = {...curentVenue};
    switch (number) {
      case 1:
        newVenueData.priceRangeFrom = +option;
        break;
      case 11:
        newVenueData.priceRangeTo = +option;
        break;
      case 2:
        newVenueData.amountOfGuestsFrom = +option;
        break;
      case 22:
        newVenueData.amountOfGuestsTo = +option;
        break;
      case 3:
        newVenueData.weekendEvent = option;
        break;
      case 4:
        newVenueData.venueType = option;
        break;
      case 5:
        newVenueData.kosher = option;
        break;
      case 6:
        newVenueData.parkingOption = option;
        break;
      case 7:
        newVenueData.discount = +option;
        break;
      case 8:
        // console.log(option);
        newVenueData.flashEvent = option;
        break;
      default:
        
    }
    newVenueData.isError = checkError(newVenueData)
    //for parent
    setIsVenueError(newVenueData.isError)
    // console.log(newVenueData)
    setVenueData(newVenueData);
  };

  /*useEffect(() => {
    // console.log("check error")
    checkIsShowError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curentVenue])*/
  useEffect(() => {
    checkError()
    setCurentVenue({...getOneVenue(curentEvent?.id)});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curentEvent]);
  useEffect(() => {
    if(curentCategory.id === 1 && !isAdded){
      checkError()
      getFiltersData();
      setFirstVenuesArr();
      setCurentVenue({...getOneVenue(curentEvent?.id)});
      setIsAdded(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curentCategory]);

  

  return isMobile ? (
    <div className={styles.venueTable1}>
      <div className={styles.tableVenueContainer}>
        <div className={styles.mainTitle}>
          <div className={styles.mainTitle1}>
            {
              "ברשימה זו יופיעו כל תת התחומים הקשורים לתחום בחרת. מלא.י מחיר רק עבור השירותים שאת.ה מציע.ה"
            }
          </div>
          <div className={styles.mainTitle2}>{"אפשרויות מתחם"}</div>
          <div className={styles.underline1}></div>
          <div className={styles.mainTitle3}>
            {"טווח מחירים (עלות אורח באירוע)"}
          </div>
          <div className={styles.mainTitle4}>
            {"*"}
            {"עבור הלקוח יוצגו המחירים כולל מע״מ"}
          </div>
        </div>
        <div className={styles.tableVenue}>
          <div className={styles.row1}>
            <div className={styles.spacebetween}>
              <div className={styles.priceRange}>
                <div className={styles.inp}>
                  <input
                    type={"number"}
                    onChange={(e) => {
                      setField(e.target.value, 11);
                    }}
                    value={curentVenue.priceRangeTo}
                  />
                  <img src={shekelImg} alt="shekel" />
                  {((!(curentVenue?.priceRangeTo>=curentVenue?.priceRangeFrom)|| curentVenue?.priceRangeFrom <1 || curentVenue?.priceRangeTo <1) && isShowError) && (
                    <p className={styles.errorMessage}> {"מספר לא חוקי"}</p>
                  )}
                </div>

                {"-"}

                <div className={styles.inp}>
                  <input
                    type={"number"}
                    onChange={(e) => {
                      setField(e.target.value, 1);
                    }}
                    value={curentVenue.priceRangeFrom}
                  />
                  <img src={shekelImg} alt="shekel" />
                  {((!(curentVenue?.priceRangeTo>=curentVenue?.priceRangeFrom)|| curentVenue?.priceRangeFrom < 1 || curentVenue?.priceRangeTo < 1) && isShowError) && (
                    <p className={styles.errorMessage}> {"מספר לא חוקי"}</p>
                  )}
                </div>
              </div>
            </div>

            {/* <div
                                  className={styles.saveRow}
                                  onClick={() => {
                                    saveField(1);
                                  }}
                                >
                                  {"שמירת שינויים"}
                                </div> */}
          </div>
          <div className={styles.underline2}></div>

          <div className={styles.row2}>
            <div className={styles.spacebetween}>
              <div className={styles.tableVenueTitles}>{"כמות אורחים"}</div>
              <div className={styles.guestsRange}>
                <div className={styles.inp}>
                  <div className={styles.abbr}>{"מקסימום"}</div>
                  <input
                    type={"number"}
                    onChange={(e) => {
                      setField(e.target.value, 22);
                    }}
                    value={curentVenue.amountOfGuestsTo}
                  />
                  {((!(curentVenue?.amountOfGuestsTo>=curentVenue?.amountOfGuestsFrom) || curentVenue?.amountOfGuestsFrom < 1 || curentVenue?.amountOfGuestsTo < 1) && isShowError) && (
                    <p className={styles.errorMessage}> {"מספר לא חוקי"}</p>
                  )}
                </div>
                {"-"}
                <div className={styles.inp}>
                  <div className={styles.abbr}>{"מינימום"}</div>
                  <input
                    type={"number"}

                    onChange={(e) => {
                      setField(e.target.value, 2);
                    }}
                    value={curentVenue.amountOfGuestsFrom}
                  />
                  {((!(curentVenue?.amountOfGuestsTo>=curentVenue?.amountOfGuestsFrom) || curentVenue?.amountOfGuestsFrom < 1 || curentVenue?.amountOfGuestsTo < 1) && isShowError) && (
                    <p className={styles.errorMessage}> {"מספר לא חוקי"}</p>
                  )}              
                </div>
              </div>
            </div>

            {/* <div
                                  className={styles.saveRow}
                                  onClick={() => {
                                    saveField(2);
                                  }}
                                >
                                  {"שמירת שינויים"}
                                </div> */}
          </div>
          <div className={styles.underline2}></div>
          <div className={styles.row3}>
            <div className={styles.venueSelect}>
              <Controller
                name="field1"
                control={control}
                render={({field: {onChange, value}}) => (
                  <Select
                    placeholder={"אירועים בסופי שבוע"}
                    fontSize={"1rem"}
                    paddingInlineStart={"1.75rem"}
                    paddingInlineEnd={"1rem"}
                    width={"100%"}
                    borderRadius={"0.125rem"}
                    backgroundColor={"#EEE9E5"}
                    color={"#242323"}
                    options={
                      weekends
                      // [
                      //   { id: 1, name: "first" },
                      //   { id: 2, name: "second" },
                      //   { id: 3, name: "third" },
                      //   { id: 4, name: "fourth" },
                      // ]
                    }
                    onChangeOption={(option) => {
                      onChange(option?.name);
                      // console.log(errors)
                      setField(option, 3);
                    }}
                    selectedOption={curentVenue.weekendEvent}
                  />
                )}
              />
              {(!curentVenue?.weekendEvent?.name && isShowError) && (
                <p className={styles.errorMessage}> {"שדה חובה"}</p>
              )}
            </div>
            {/* <div
                                  className={styles.saveRow}
                                  onClick={() => {
                                    saveField(3);
                                  }}
                                >
                                  {"שמירת שינויים"}
                                </div> */}
          </div>
          <div className={styles.row4}>
            <div className={styles.venueSelect}>
              <Controller
                name="field2"
                control={control}
                render={({field: {onChange, value}}) => (
                  <Select
                    placeholder={"סוג מתחם"}
                    fontSize={"1rem"}
                    paddingInlineStart={"1.75rem"}
                    paddingInlineEnd={"1rem"}
                    width={"100%"}
                    borderRadius={"0.125rem"}
                    backgroundColor={"#EEE9E5"}
                    color={"#242323"}
                    options={
                      venueType
                      // [
                      //   { id: 1, name: "first" },
                      //   { id: 2, name: "second" },
                      //   { id: 3, name: "third" },
                      //   { id: 4, name: "fourth" },
                      // ]
                    }
                    onChangeOption={(option) => {
                      onChange(option?.name);
                      // console.log(errors)
                      setField(option, 4);
                    }}
                    selectedOption={curentVenue.venueType}
                  />
                )}
              />
              {(!curentVenue?.venueType?.name && isShowError) && (
                <p className={styles.errorMessage}> {"שדה חובה"}</p>
              )}
            </div>
            {/* <div
                                  className={styles.saveRow}
                                  onClick={() => {
                                    saveField(4);
                                  }}
                                >
                                  {"שמירת שינויים"}
                                </div> */}
          </div>
          <div className={styles.row5}>
            <div className={styles.venueSelect}>
              <Controller
                placeholder={"כשרות / סוג תפריט"}
                name="field3"
                control={control}
                render={({field: {onChange, value}}) => (
                  <Select
                    fontSize={"1rem"}
                    paddingInlineStart={"1.75rem"}
                    paddingInlineEnd={"1rem"}
                    width={"100%"}
                    borderRadius={"0.125rem"}
                    backgroundColor={"#EEE9E5"}
                    color={"#242323"}
                    options={
                      kosher
                      // [
                      //   { id: 1, name: "first" },
                      //   { id: 2, name: "second" },
                      //   { id: 3, name: "third" },
                      //   { id: 4, name: "fourth" },
                      // ]
                    }
                    onChangeOption={(option) => {
                      onChange(option?.name);
                      // console.log(errors)
                      setField(option, 5);
                    }}
                    selectedOption={curentVenue.kosher}
                  />
                )}
              />
              {(!curentVenue?.kosher?.name && isShowError) && (
                <p className={styles.errorMessage}> {"שדה חובה"}</p>
              )}
            </div>
            {/* <div
                                  className={styles.saveRow}
                                  onClick={() => {
                                    saveField(5);
                                  }}
                                >
                                  {"שמירת שינויים"}
                                </div> */}
          </div>
          <div className={styles.row6}>
            <div className={styles.venueSelect}>
              <Controller
                name="field4"
                control={control}
                render={({field: {onChange, value}}) => (
                  <Select
                    placeholder={"אפשרויות חניה"}
                    fontSize={"1rem"}
                    paddingInlineStart={"1.75rem"}
                    paddingInlineEnd={"1rem"}
                    width={"100%"}
                    borderRadius={"0.125rem"}
                    backgroundColor={"#EEE9E5"}
                    color={"#242323"}
                    options={
                      parkingOption
                      // [
                      //   { id: 1, name: "first" },
                      //   { id: 2, name: "second" },
                      //   { id: 3, name: "third" },
                      //   { id: 4, name: "fourth" },
                      // ]
                    }
                    onChangeOption={(option) => {
                      onChange(option?.name);
                      // console.log(errors)
                      setField(option, 6);
                    }}
                    selectedOption={curentVenue.parkingOption}
                  />
                )}
              />
              {(!curentVenue?.parkingOption?.name && isShowError) && (
                <p className={styles.errorMessage}> {"שדה חובה"}</p>
              )}
            </div>

            {/* <div
                                  className={styles.saveRow}
                                  onClick={() => {
                                    saveField(6);
                                  }}
                                >
                                  {"שמירת שינויים"}
                                </div> */}
          </div>
          <div className={styles.underline2}></div>
        </div>
        <div style={{paddingTop: "1.25rem", fontSize: "0.875rem", lineHeight: "1.3125rem"}}>
          {"אירוע בזק - הזן אחוז הנחה"}
        </div>
        <div className={styles.flashVenue}>
          <div className={styles.inputFlash}>
            <input
                    type={"number"}

              value={curentVenue.discount}
              onChange={(e) => {
                let value = e.target.value;
                setField(value ? value : "0", 7);
              }}
            />
            {"%"}
          </div>
          <div className={styles.flashTitle}>
            <div className={styles.rad}>
              <div className={classNames(styles.radio, styles.radio2)}>
                <input
                  onClick={() => setField(!curentVenue?.flashEvent, 8)}
                  // {...register("isFlash")}
                  id="radioFlash1"
                  type="radio"
                  // name="contractRequired"
                  checked={curentVenue.flashEvent}
                  // value={""}
                />
                <label htmlFor="radioFlash1">{"אירוע בזק"}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div style={{paddingBottom: "2.625rem"}}>
      <div className={styles.wrapper}>
        <div className={styles.wrapperTitle}>{"אפשרויות מתחם"}</div>
        <div className={styles.underline}></div>
        <div className={styles.tableVenueContainer}>
          <div className={styles.tableVenue}>
            <div style={{height: "3.0625rem"}} className={styles.row1}>
              <div className={styles.spacebetween}>
                <div className={styles.tableVenueTitles}>
                  {"טווח מחירים (עלות אורח באירוע)"}
                  <div>{"עבור הלקוח יוצגו המחירים כולל מע״מ"}</div>
                </div>
                <div className={styles.priceRange}>
                  <div className={styles.inp}>
                    <input
                    type={"number"}
                      onChange={(e) => {
                        setField(e.target.value, 11);
                      }}
                      value={curentVenue?.priceRangeTo}
                    />
                    <img src={shekelImg} alt="shekel" />
                    {((!(curentVenue?.priceRangeTo>=curentVenue?.priceRangeFrom)|| curentVenue?.priceRangeFrom < 1 || curentVenue?.priceRangeTo < 1) && isShowError) && (
                      <p className={styles.errorMessage}> {"מספר לא חוקי"}</p>
                    )}
                  </div>
                  {"-"}

                  <div className={styles.inp}>
                    <input
                    type={"number"}
                      onChange={(e) => {
                        setField(e.target.value, 1);
                      }}
                      value={curentVenue?.priceRangeFrom}
                    />
                    <img src={shekelImg} alt="shekel" />
                    {((!(curentVenue?.priceRangeTo>=curentVenue?.priceRangeFrom)|| curentVenue?.priceRangeFrom < 1 || curentVenue?.priceRangeTo < 1) && isShowError) && (
                      <p className={styles.errorMessage}> {"מספר לא חוקי"}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.row2}>
              <div className={styles.spacebetween}>
                <div className={styles.tableVenueTitles}>{"כמות אורחים"}</div>
                <div className={styles.guestsRange}>
                  <div className={styles.inp}>
                    <div className={styles.abbr}>{"מקסימום"}</div>
                    <input
                    type={"number"}
                      onChange={(e) => {
                        setField(e.target.value, 22);
                      }}
                      value={curentVenue?.amountOfGuestsTo}
                    />
                    {((!(curentVenue?.amountOfGuestsTo>=curentVenue?.amountOfGuestsFrom)|| curentVenue?.amountOfGuestsFrom < 1 || curentVenue?.amountOfGuestsTo < 1) && isShowError) && (
                      <p className={styles.errorMessage}> {"מספר לא חוקי"}</p>
                    )}
                  </div>
                  {"-"}
                  <div className={styles.inp}>
                    <div className={styles.abbr}>{"מינימום"}</div>
                    <input
                    type={"number"}
                      onChange={(e) => {
                        setField(e.target.value, 2);
                      }}
                      value={curentVenue?.amountOfGuestsFrom}
                    />
                    {((!(curentVenue?.amountOfGuestsTo>=curentVenue?.amountOfGuestsFrom)|| curentVenue?.amountOfGuestsFrom < 1 || curentVenue?.amountOfGuestsTo < 1) && isShowError) && (
                      <p className={styles.errorMessage}> {"מספר לא חוקי"}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.row3}>
              <div className={styles.spacebetween}>
                <div className={styles.tableVenueTitles}>
                  {"אירועים בסופי שבוע"}
                </div>
                <div className={styles.venueSelect}>
                  <Controller
                    name="field1"
                    control={control}
                    render={({field: {onChange, value}}) => (
                      <Select
                        fontSize={"1rem"}
                        paddingInlineStart={"1.75rem"}
                        paddingInlineEnd={"1.33rem"}
                        width={"100%"}
                        borderRadius={"0"}
                        backgroundColor={"white"}
                        color={"#242323"}
                        options={weekends}
                        onChangeOption={(option) => {
                          onChange(option?.name);
                          // console.log(errors)
                          setField(option, 3);
                        }}
                        selectedOption={curentVenue?.weekendEvent}
                      />
                    )}
                  />
                  {(!curentVenue?.weekendEvent?.name && isShowError) && (
                    <p className={styles.errorMessage}>
                       {"שדה חובה"}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.row4}>
              <div className={styles.spacebetween}>
                <div className={styles.tableVenueTitles}>{"סוג מתחם"}</div>
                <div className={styles.venueSelect}>
                  <Controller
                    name="field2"
                    control={control}
                    render={({field: {onChange, value}}) => (
                      <Select
                        fontSize={"1rem"}
                        paddingInlineStart={"1.75rem"}
                        paddingInlineEnd={"1.33rem"}
                        width={"100%"}
                        borderRadius={"0"}
                        backgroundColor={"white"}
                        color={"#242323"}
                        options={venueType}
                        onChangeOption={(option) => {
                          onChange(option?.name);
                          // console.log(errors)
                          setField(option, 4);
                        }}
                        selectedOption={curentVenue?.venueType}
                      />
                    )}
                  />
                  {(!curentVenue?.venueType?.name && isShowError) && (
                    <p className={styles.errorMessage}>
                       {"שדה חובה"}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.row5}>
              <div className={styles.spacebetween}>
                <div className={styles.tableVenueTitles}>
                  {"כשרות / סוג תפריט"}
                </div>
                <div className={styles.venueSelect}>
                  <Controller
                    name="field3"
                    control={control}
                    render={({field: {onChange, value}}) => (
                      <Select
                        fontSize={"1rem"}
                        paddingInlineStart={"1.75rem"}
                        paddingInlineEnd={"1.33rem"}
                        width={"100%"}
                        borderRadius={"0"}
                        backgroundColor={"white"}
                        color={"#242323"}
                        options={kosher}
                        onChangeOption={(option) => {
                          onChange(option?.name);
                          // console.log(errors)
                          setField(option, 5);
                        }}
                        selectedOption={curentVenue?.kosher}
                      />
                    )}
                  />
                  {(!curentVenue?.kosher?.name && isShowError) && (
                    <p className={styles.errorMessage}>
                       {"שדה חובה"}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.row6}>
              <div className={styles.spacebetween}>
                <div className={styles.tableVenueTitles}>{"אפשרויות חניה"}</div>
                <div className={styles.venueSelect}>
                  <Controller
                    name="field4"
                    control={control}
                    render={({field: {onChange, value}}) => (
                      <Select
                        fontSize={"1rem"}
                        paddingInlineStart={"1.75rem"}
                        paddingInlineEnd={"1.33rem"}
                        width={"100%"}
                        borderRadius={"0"}
                        backgroundColor={"white"}
                        color={"#242323"}
                        options={parkingOption}
                        onChangeOption={(option) => {
                          onChange(option?.name);
                          // console.log(errors)
                          setField(option, 6);
                        }}
                        selectedOption={curentVenue?.parkingOption}
                      />
                    )}
                  />
                  {(!curentVenue?.parkingOption?.name && isShowError) && (
                    <p className={styles.errorMessage}>
                      {"שדה חובה"}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.flashVenue}>
            <div className={styles.flashTitle}>
              {"אירוע בזק - הזן אחוז הנחה עבור אירוע מסוג זה"}
            </div>
            <div className={styles.inputFlash}>
              <input
              type={"number"}
                value={curentVenue?.discount}
                onChange={(e) => {
                  let value = e.target.value;
                  setField(value ? value : 0, 7);
                  // saveField(7, e.target.value);
                }}
              />
              {"%"}
            </div>

            <div className={styles.rad}>
              <div className={classNames(styles.radio, styles.radio2)}>
                <input
                  onClick={() => {
                    // saveField(8);
                    setField(!curentVenue?.flashEvent, 8);
                  }}
                  // {...register("isFlash")}
                  id="radioFlash"
                  type="radio"
                  // name="contractRequired"
                  checked={curentVenue?.flashEvent}
                  // value={""}
                />
                <label htmlFor="radioFlash">
                  {"איני מעוניין לתת שירות עבור אירועי בזק"}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Venue;
