import { Typography } from '@mui/material';
import styles from '../../../styleBasicForm.module.scss';
// import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentStep } from '../../../../../../store/mainSlice';
import { useState } from 'react';
import CustomTextField from '../../../../../../components/customTextField/CustomTextField';
const InputStep = (props) => {
  const { name, label, placeholder, sx, typeInput, rules, nextStep } = props;
  // const { formState, clearErrors } = useFormContext();
  const [isSkip, setIsSkip] = useState(false);
  const dispatch = useDispatch();
  const { currentStep } = useSelector((state) => state.main);
  const goToStep = (step) => {
    if (!step) return;
    dispatch(setCurrentStep(step));
  };
  // const onSkip = () => {
  //   if (formState.errors[`${name}`]) {
  //     clearErrors(name);
  //   }
  //   if (isSkip) return;
  //   setIsSkip(true);
  //   if (!nextStep || currentStep >= nextStep) return;
  //   goToStep(nextStep);
  // };

  const getRules = () => {
    return isSkip ? Object.fromEntries(Object.keys(rules).map((key) => [key, undefined])) : rules;
  };
  return (
    <div className={styles.one_step}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography className={styles.one_step_label}>{label}</Typography>
        {/* {nextStep && (
          <Typography onClick={onSkip} className={styles.miss_text}>
            דלג
          </Typography>
        )} */}
      </div>
      <div className={styles.field_container} style={{ flex: '1 0 auto' }}>
        <CustomTextField
          name={name}
          rules={getRules()}
          sx={sx}
          onChange={(e) => {
            if (isSkip) setIsSkip(false);
            if (currentStep >= nextStep) return;
            goToStep(nextStep);
          }}
          placeholder={placeholder}
          typeInput={typeInput}
        />
      </div>
    </div>
  );
};
export default InputStep;
