import { useState, useEffect } from "react"
import { Helmet } from "react-helmet-async";

const MetaFavicon = () => {
    const [ icon,setIcon ]=useState('')

    useEffect(()=>{
        setIcon(prevState=>prevState === ''?sessionStorage.getItem('url'):prevState)
    },[])

    return(
      <Helmet>
        <link rel="shortcut icon" href={icon} />
      </Helmet>
    )
}
export default MetaFavicon;
