import axios from 'axios';
import he from 'dayjs/locale/he'; // load on demand
import localizedFormat from 'dayjs/plugin/localizedFormat'; 
import dayjs from "dayjs";
import styles from "./Terms.module.scss";
import { useEffect, useState } from "react";
import MetaTerms from "../../components/metaComponents/metaTerms";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import HeaderMobile from "../../components/headerMobile/HeaderMobile";
import { useParams } from 'react-router-dom';


function Terms() {
  dayjs.locale(he);
  dayjs.extend(localizedFormat)
  const routeParams = useParams();
  let roleId = routeParams.id;
  const [terms, setAppState] = useState({data:null, loading:true});
  // const navigate = useNavigate();
  
  useEffect(() => {
    const fetchData = async () => {
      let value;
      if(roleId === '1'){
        const result = await axios.get(`/main/terms/page`);
        value = result;
      } else{
        const result = await axios.get(`/main/terms/page/suppliers`);
        value = result;
      }
      setAppState({data:value.data.text,loading:false});
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAppState]);

  // <MetaAboutUs/>
  return (
    <>
      <MetaTerms/>
      <Header />
      <HeaderMobile />
      {/* desctop version */}
      {!terms.loading  && terms.data != null && 
      <section className={styles.desctopVersion}>
            <h1 className={styles.title}>תקנון אתר</h1>
            <div className={styles.textWrapper}>
                <div className={styles.textBlock} dangerouslySetInnerHTML={{ __html: terms.data }}></div>
            </div> 
      </section>}

      {/* mobile version */}
      {!terms.loading  && terms.data != null && <section className={styles.mobileVersion}>
        <div className={styles.headerWrapperMobile}>
          <h3 className={styles.mobileTitle}>תקנון אתר</h3>
        </div> 
        <div className={styles.mobileTextBlock} dangerouslySetInnerHTML={{ __html: terms.data }}></div>
      </section>}
      <section className={styles.desctop}> 
        < Footer />
      </section>
    </>
  );
  }   
export default Terms;