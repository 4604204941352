import { Button, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import styles from '../../../styleBasicForm.module.scss';
import { useFormContext } from 'react-hook-form';
import { AUTH_EXISTS_PLACE_FIELDS, AUTH_NO_EXISTS_PLACE_FIELDS, EXISTS_PLACE_ENUM, EXISTS_PLACE_FIELDS, NO_EXISTS_PLACE_FIELDS, RELATIONSHIP_OF_FIELDS_TO_STEPS, STEPS_ENUM } from '../stepForm.config';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentStep } from '../../../../../../store/mainSlice';
import useAuth from '../../../../../../hooks/useAuth';
const findFirstEmptyField = (obj) => {
  const emptyFields = Object.keys(obj).filter(key => !obj[key].value);
  if (emptyFields.length === 0) {
    return undefined;
  }
  return obj[emptyFields.sort((a, b) => obj[a].order - obj[b].order)[0]];
};

const getValuesFromFields= (obj,masFields) =>  {
  return masFields.reduce((acc, field) => {
    if (obj.hasOwnProperty(field)) {
      acc[field] ={
        value:obj[field],
        order:RELATIONSHIP_OF_FIELDS_TO_STEPS[field]
      };
    }
    return acc;
  }, {});
}

const ExistsPlace = () => {
  const { watch, setValue } = useFormContext();
  const { auth } = useAuth();
  const { currentStep } = useSelector((state) => state.main);
  const currentRole = Object.keys(auth).length !== 0 && auth?.roles?.length ? auth?.roles[0] : '';
  const dispatch = useDispatch();
  const currentValue = watch('existsPlace');
  const allValues = watch()

  const onChangeType = (type) => {
    if(currentStep === STEPS_ENUM.EXISTS_PLACE){
      dispatch(setCurrentStep(STEPS_ENUM.DATE_SELECTION)); 
    }
    if(currentStep > STEPS_ENUM.DATE_SELECTION){
      const isExistsPlace = type === EXISTS_PLACE_ENUM.YES;
      const currentFields = isExistsPlace ? !currentRole ?  EXISTS_PLACE_FIELDS : AUTH_EXISTS_PLACE_FIELDS : !currentRole ? NO_EXISTS_PLACE_FIELDS : AUTH_NO_EXISTS_PLACE_FIELDS
      const currentValues = getValuesFromFields(allValues,currentFields)
      const emptyValue = findFirstEmptyField(currentValues)
      dispatch(setCurrentStep(emptyValue ?  emptyValue.order : STEPS_ENUM.SUBMIT)); 
    }
    if (type === currentValue) return;
    setValue('existsPlace', type);
  };
  return (
    <div className={styles.one_step}>
      <Typography className={styles.one_step_label}>יש מקום לאירוע?</Typography>
      <div className={styles.field_container} style={{ display: 'flex' }}>
        <Button
          onClick={() => onChangeType(EXISTS_PLACE_ENUM.YES)}
          variant={currentValue === EXISTS_PLACE_ENUM.YES ? 'contained' : 'outlined'}
        >
          כן!
        </Button>
        <Button
          onClick={() => onChangeType(EXISTS_PLACE_ENUM.NO)}
          sx={{ marginRight: { xs: '7px', md: '10px' } }}
          variant={currentValue === EXISTS_PLACE_ENUM.NO ? 'contained' : 'outlined'}
        >
          עדיין לא
        </Button>
      </div>
    </div>
  );
};

export default ExistsPlace;
