import styles from "./PopupDetails.module.scss";
import close from "../../../../assets/images/for-pages/icons/closeDialog.png";
import dayjs from "dayjs";
import eye from "../eye.svg"
import load from "../download_active.svg"
import he from 'dayjs/locale/he'; // load on demand
import localizedFormat from 'dayjs/plugin/localizedFormat'; 

function PopupDetails(props) {
  dayjs.locale(he);
  dayjs.extend(localizedFormat)
  const {isOpen, setIsOpen, currentDocument, openDetails, downloadPDF} = props;
 

  const closeDialog = async () => {
    setIsOpen(!isOpen);
  }

  const details = async () => {
    openDetails();
    setIsOpen(!isOpen);
  }

  
  const downloadFile = async () => {
    downloadPDF();
    setIsOpen(!isOpen);
  }

  function convertToTwoCharacter(numb) {
      return numb.toString().length === 1 ? "0" + numb : numb.toString();
    }

  function convertDate(date){
      const time = new Date(date);
      let month = time.getMonth() + 1;
      let day = time.getDate();
      let dayString = convertToTwoCharacter(day);
      let monthString = convertToTwoCharacter(month);
      let dateFull = dayString + '.' + monthString + '.' + time.getFullYear();
      return dateFull
    }


  return (
    <>
      {isOpen && (
        <div className={styles.dialogWrapper}>
          <div className={styles.mainDialog}>
            <div className={styles.closeDialog}>
              <img
                onClick={(e) => {
                  closeDialog();
                }}
                alt="closeImage"
                src={close}
              />
            </div>
            <div className={styles.content}>
              <div className={styles.block}>
                <div className={styles.blockTitle}>תאריך המסמך</div>
                <div className={styles.blockContent}>{convertDate(currentDocument.creationDate)}</div>
              </div>
              <div className={styles.block}>
                <div className={styles.blockTitle}><span onClick={(e)=>currentDocument.status !== 'EXPIRED' ? details() : ''}  style={{cursor:currentDocument.status !== 'EXPIRED' ?'pointer' :'default'}}>פירוט העסקה</span></div>
                <div className={styles.blockContent}><img onClick={(e)=>currentDocument.status !== 'EXPIRED' ? details() : ''} style={{cursor:currentDocument.status !== 'EXPIRED' ?'pointer' :'default'}} src={eye} alt='eye'/></div>
              </div>
              <div className={styles.block}>
                <div className={styles.blockTitle}><span onClick={(e)=>currentDocument.status !== 'EXPIRED' ? downloadFile(): ''} style={{cursor:currentDocument.status !== 'EXPIRED' ?'pointer' :'default'}}>הורדת חוזה</span></div>
                <div className={styles.blockContent}><img src={load} onClick={(e)=>currentDocument.status !== 'EXPIRED' ? downloadFile(): ''} style={{cursor:currentDocument.status !== 'EXPIRED' ?'pointer' :'default'}} className={styles.load} alt='load'/></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default PopupDetails;
