import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchServicesForCategory, setServices } from '../../../../store/suppliersSlice';
import { useFormContext } from 'react-hook-form';

export const useGetData = () => {
  const dispatch = useDispatch();
  let { services } = useSelector((state) => state.suppliers);
  const { watch, setValue } = useFormContext();
  const currentCategory = watch('category');
  const [categoryFiltersLoading, setCategoryFiltersLoading] = useState(false);
  useEffect(() => {
    if (!currentCategory) {
      dispatch(setServices([]))
      setValue('categoryFiltersId', []);
      return;
    }
    setCategoryFiltersLoading(true);
    dispatch(fetchServicesForCategory(currentCategory)).finally(() =>
      setCategoryFiltersLoading(false)
    );
  }, [currentCategory]);
  return { categoryFilters: services, categoryFiltersLoading: categoryFiltersLoading };
};
