import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

const CustomTextField = (props) => {
  const { name, rules, placeholder, sx, typeInput, onChange, disabled } = props;
  return (
    <Controller
      name={name}
      rules={rules}
      render={({ field, fieldState }) => (
        <TextField
          disabled={disabled}
          type={typeInput ? typeInput : 'unset'}
          placeholder={!!placeholder ? placeholder : ''}
          sx={{
            width: '100%',
            height: '100%',
            '.MuiOutlinedInput-root': { height: '100%' },
            ...sx,
          }}
          inputProps={{ height: '100%' }}
          {...field}
          error={fieldState.error ? Object.keys(fieldState.error).length !== 0 : false}
          helperText={fieldState.error ? fieldState.error.message : undefined}
          onChange={(e) => {
            field.onChange(e.target.value);
            !!onChange && onChange();
          }}
        />
      )}
    />
  );
};
export default CustomTextField;
