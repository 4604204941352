import styles from "./PopupFilters.module.scss";
import close from "../../../../assets/images/for-pages/icons/closeDialog.png";
import dayjs from "dayjs";
import filters from "../filters.svg"
import he from 'dayjs/locale/he'; // load on demand
import localizedFormat from 'dayjs/plugin/localizedFormat'; 
import {useState} from "react";

function PopupFilters(props) {
  dayjs.locale(he);
  dayjs.extend(localizedFormat)
  const {isOpen, setIsOpen, documents, setDocument, currentDocument, onChange} = props;
  const [defaultDocument, setdefaultDocument] = useState(currentDocument);
 
  const changeCurrentItem = async (option) => {
    setdefaultDocument(option);
  }

  const closeDialog = async () => {
    setIsOpen(!isOpen);
  }
  
  const send = async () => {
    setDocument(defaultDocument);
    setIsOpen(!isOpen);
    onChange(defaultDocument);
  }

  return (
    <>
      {isOpen && (
        <div className={styles.dialogWrapper}>
          <div className={styles.mainDialog}>
            <div className={styles.closeDialog}>
              <img
                onClick={(e) => {
                  closeDialog();
                }}
                alt="closeImage"
                src={close}
              />
            </div>
            <div className={styles.content}>
              <div className={styles.filters}>
                <img src={filters} alt='filters'/>
                <div className={styles.filtersTitle}>סינון מסמכים</div>
              </div>
              <hr className={styles.line}></hr>
              <div className={styles.contentBlock}>
              {documents.map((item,index)=>{
                return(
                  <div key={index}className={styles.contentTitle} style={{opacity: defaultDocument.id === item.id ? '1' :'0.57',fontFamily: defaultDocument.id === item.id ? 'Heebo-Medium' :'Heebo-Regular'}}>
                    <span onClick={(e)=>{changeCurrentItem(item);}} style={{cursor:'pointer'}}>{item.name}</span>
                  </div>
                )
              })}
              </div>
              <div className={styles.applyButton} ><span style={{cursor:'pointer'}} onClick={(e)=>{send();}}>אישור</span></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default PopupFilters;
