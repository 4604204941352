import styles from "./Popup.module.scss";
import close from "../../../../../assets/images/for-pages/icons/closeDialog.png";
import gog from "../../../../../assets/images/for-pages/icons/gog.png";

function Popup(props) {
  // const _D = "DESKTOP";
  // const _M = "MOBILE";
  const {isOpen, setIsOpen, getGoogle} = props;

  // const getWidthOfWindow = () => {
  //   return (window.innerWidth || 961)
  // }

  // const getMobileOrDesctop = () => {
  //   return ((getWidthOfWindow() > 960) ? _D : _M)
  // }

  return (
    <>
      {isOpen && (
        <div className={styles.dialogWrapper}>
          <div className={styles.mainDialog}>
            <div className={styles.closeDialog}>
              <img
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
                alt="closeImage"
                src={close}
              />
            </div>
            <div className={styles.content}>
              <span className={styles.descr}>
                {
                  "על מנת להמשיך ולעדכן את היומן שלך אצלנו, עליך לקשר אותו עם חשבון המייל האישי שלך בשביל שתוכל להיות מעודכן ומסונכרן בכל עת"
                }
              </span>
              <div className={styles.gogWrap}>
                <div onClick={() => {getGoogle();setIsOpen(!isOpen);}} className={styles.gogBtn}>
                  <img alt="go0gle" src={gog} />
                  <span>{" התחברות באמצעות"}{" GOOGLE"}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default Popup;
