import styles from "./PersonalAreaTab.module.scss";
import {useEffect, useState} from "react";
import person from "../../../assets/images/person_black.svg"
import person_active from "../../../assets/images/person_pink.svg"
import plus from "../../../assets/images/plus_black.svg"
import plus_active from "../../../assets/images/plus_pink.svg"
import menu from "../../../assets/images/menu_black.svg"
import menu_active from "../../../assets/images/menu_pink.svg"
import heart from "../../../assets/images/heart_black.svg"
import heart_active from "../../../assets/images/heart_pink.svg"
import chat from "../../../assets/images/chat_black.svg"
import line from "../../../assets/images/line.svg"
import chat_active from "../../../assets/images/chat_pink.svg"
import {useLocation} from "react-router-dom";
import {Outlet} from 'react-router-dom'
import {NavLink} from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import classNames from "classnames";
import MetaFavicon from '../../components/metaComponents/metaFavicon'
import { useDispatch } from "react-redux";
import { saveNextPath, savePath } from "../../store/returnToUrlSlice";
import { setIsOpenUserAuth } from "../../store/mainSlice";

function PersonalAreaTab() {
    const dispatch = useDispatch();
    const {auth} = useAuth();
    const currentRole = (auth?.roles?.length) ? auth?.roles[0] : '';
    let {pathname} = useLocation();
    const [tab, setAppState] = useState({
        data: [
                {name: 'person', isActive: (
                    (pathname === "/personal/change-user")
                  ) ? true : false, 
                  isVisible: true},
                {name: 'menu', isActive: pathname.indexOf('checklist') !== -1, isVisible: true},
                {name: 'heart', isActive: (
                    (pathname === "/personal/favorites")
                  ) ? true : false, isVisible: true},
                {name: 'chat', isActive: pathname.indexOf('chat') !== -1, isVisible: true},
                {name: 'plus',
                    isActive: (
                        ((pathname === "/personal/add-event") || (pathname === '/personal-guest/add-event'))
                    ) ? true : false, 
                    isVisible: true},
                {name: 'line', isActive: pathname.indexOf('/personal/documents') !== -1, isVisible: true}
            ], isLoading: false
    });

    useEffect(() => {
        if((pathname === "/personal/add-event") || (pathname === '/personal-guest/add-event')){
            changeActiveTab('plus')
        }  else if((pathname === "/personal/checklist") || (pathname === '/personal-guest/checklist')){
            changeActiveTab('menu')
        }  else if((pathname === "/personal/favorites")){
            changeActiveTab('heart')
        }  else if((pathname.indexOf('/personal/chat') !== -1) || (pathname === '/personal/chats')){
            changeActiveTab('chat')
        }  else if((pathname === "/personal/change-user") || (pathname === "/personal-guest/change-user")){
            changeActiveTab('person')
        }  else if((pathname === "/personal/documents")){
            changeActiveTab('line')
        } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[pathname])

    function changeActiveTab(tabName, isSavePath, nextPath) {
        if(isSavePath){
            dispatch(savePath(pathname));
            dispatch(saveNextPath(nextPath));
        }
        let newTab = tab.data.map((val) => {
            if (val.name === tabName) {
                val.isActive = true;
            } else {
                val.isActive = false;
            }
            return val
        })
        setAppState({data: newTab, isLoading: false})
    }

    return (
        <>
        <MetaFavicon />
            <div className={styles.tab}>
                {
                    tab.data[0].isVisible && (
                       
                            <div className={styles.block}> 
                                {tab.data[0].isActive === false && <NavLink onClick={() => {
                                    changeActiveTab('person')
                                }} to="change-user"> <img alt="person" src={person} className={styles.img}/> </NavLink> }
                                {tab.data[0].isActive === true && <NavLink onClick={() => {
                                    changeActiveTab('person')
                                }} to="change-user"> <img alt="person" src={person_active} className={styles.img}/> </NavLink>}
                                <div  className={tab.data[0].isActive === false ? styles.textBlock : styles.textBlockActive}> 
                                <span><NavLink to="change-user"onClick={() => {
                                    changeActiveTab('person')
                                }}> פרטים  אישיים </NavLink></span>
                                </div>
                            </div>
                    
                    )
                }
                {
                    tab.data[1].isVisible && (
                            <div className={styles.block}>
                                {tab.data[1].isActive === false && <NavLink onClick={() => {
                                    changeActiveTab('menu')
                                }}to={currentRole ? '/personal/checklist' : '/personal-guest/checklist'}> <img  alt="menu" src={menu} className={styles.img}/> </NavLink>}
                                {tab.data[1].isActive === true &&  <NavLink onClick={() => {
                                    changeActiveTab('menu')
                                }}to={currentRole ? '/personal/checklist' : '/personal-guest/checklist'}> <img  alt="menu" src={menu_active} className={styles.img}/> </NavLink>}
                                <div
                                    className={tab.data[1].isActive === false ? styles.textBlock : styles.textBlockActive}>
                                        <NavLink onClick={() => {
                                    changeActiveTab('menu')
                                }} to={currentRole ? '/personal/checklist' : '/personal-guest/checklist'}>  הצ'קליסט  שלי </NavLink>
                                </div>
                            </div>
                    )
                }
                {
                    tab.data[2].isVisible && (
                            <div className={styles.block}>
                                {tab.data[2].isActive === false && <NavLink onClick={() => {
                                    changeActiveTab('heart', !currentRole, '/personal/favorites')
                                    if(!currentRole){
                                        dispatch(setIsOpenUserAuth(true))
                                    }
                                }} to={currentRole ? '/personal/favorites' : ''}> <img alt="heart" src={heart} className={classNames(styles.img,styles.heart)}/> </NavLink>}
                                {tab.data[2].isActive === true &&  <NavLink onClick={() => {
                                    changeActiveTab('heart', !currentRole, '/personal/favorites')
                                    if(!currentRole){
                                        dispatch(setIsOpenUserAuth(true))
                                    }
                                }} to={currentRole ? '/personal/favorites' : ''}> <img alt="heart" src={heart_active} className={classNames(styles.img,styles.heart)}/> </NavLink>}
                                <div className={tab.data[2].isActive === false ? styles.textBlock : styles.textBlockActive}>  <NavLink onClick={() => {
                                    changeActiveTab('heart', !currentRole, '/personal/favorites')
                                    if(!currentRole){
                                        dispatch(setIsOpenUserAuth(true))
                                    }
                                }} to={currentRole ? '/personal/favorites' : ''}>ספקים
                                    שאהבתי </NavLink>
                                </div>
                            </div>
                    )
                }
                {
                    tab.data[3].isVisible && (
                            <div className={styles.block}>
                                {tab.data[3].isActive === false &&  <NavLink onClick={() => {
                                    changeActiveTab('chat', !currentRole, "/personal/chats")
                                    if(!currentRole){
                                        dispatch(setIsOpenUserAuth(true))
                                    }
                                }} to={currentRole ? '/personal/chats' : ''}><img alt="chat" src={chat} className={styles.img}/> </NavLink>}
                                {tab.data[3].isActive === true && <NavLink onClick={() => {
                                    changeActiveTab('chat', !currentRole, "/personal/chats")
                                    if(!currentRole){
                                        dispatch(setIsOpenUserAuth(true))
                                    }
                                }} to={currentRole ? '/personal/chats' : ''}> <img alt="chat" src={chat_active} className={styles.img}/></NavLink>}
                                <div className={tab.data[3].isActive === false ? styles.textBlock : styles.textBlockActive}>  <NavLink onClick={() => {
                                    changeActiveTab('chat', !currentRole, "/personal/chats")
                                    if(!currentRole){
                                        dispatch(setIsOpenUserAuth(true))
                                    }
                                }} to={currentRole ? '/personal/chats' : ''}>צ'אט </NavLink>
                                </div>
                            </div>
                    )
                }
                {
                    tab.data[4].isVisible && (
                        <div className={styles.customBlock}>
                            {tab.data[4].isActive === false && <NavLink onClick={() => {
                                changeActiveTab('plus')
                            }} to={currentRole ? '/personal/add-event' : '/personal-guest/add-event'}><img alt="plus" src={plus} className={styles.img}/></NavLink>}
                            {tab.data[4].isActive === true && <NavLink onClick={() => {
                                changeActiveTab('plus')
                            }} to={currentRole ? '/personal/add-event' : '/personal-guest/add-event'}> <img alt="plus" src={plus_active} className={styles.img}/></NavLink>}
                            <div className={tab.data[4].isActive === false ? styles.textBlock : styles.textBlockActive}> <NavLink onClick={() => {
                                changeActiveTab('plus')
                            }} to={currentRole ? '/personal/add-event' : '/personal-guest/add-event'}> הוספת
                                אירוע </NavLink>
                            </div>
                        </div>
                    )
                }
                {
                    tab.data[5].isVisible && (
                        <div className={styles.block}>
                            {tab.data[5].isActive === false &&   <NavLink onClick={() => {
                                changeActiveTab('line', !currentRole, '/personal/documents')
                                if(!currentRole){
                                    dispatch(setIsOpenUserAuth(true))
                                }
                            }} to={currentRole ? '/personal/documents' : ''}> <img alt="line" src={line} className={styles.img}/> </NavLink>}
                            {tab.data[5].isActive === true &&   <NavLink onClick={() => {
                                changeActiveTab('line', !currentRole, '/personal/documents')
                                if(!currentRole){
                                    dispatch(setIsOpenUserAuth(true))
                                }
                            }} to={currentRole ? '/personal/documents' : ''}> <img alt="line" src={line} className={styles.img}/> </NavLink>}
                            <div className={tab.data[5].isActive === false ? styles.customTextBlock : styles.customTextBlockActive}>
                               <NavLink onClick={() => {
                                changeActiveTab('line', !currentRole, '/personal/documents')
                                if(!currentRole){
                                    dispatch(setIsOpenUserAuth(true))
                                }
                            }} to={currentRole ? '/personal/documents' : ''}> מסמכים ותשלומים </NavLink>
                            </div>
                        </div>
                    )
                }

            </div>
            <hr className={styles.line}></hr>
            {tab.data[0].isActive === true && <div className={styles.content}>

            </div>}
            {tab.data[1].isActive === true && <div className={styles.content}>

            </div>}
            {tab.data[2].isActive === true && <div className={styles.content}>

            </div>}
            {tab.data[3].isActive === true && <div className={styles.content}>
                {/* {pathname.indexOf('chats') !== -1 && <Chats/> } */}
            </div>}
            {tab.data[4].isActive === true && <div className={styles.content}>

            </div>}
            {tab.data[5].isActive === true && <div className={styles.content}>

            </div>}
            <main>
                <Outlet/>
            </main>
        </>
    );
}

export default PersonalAreaTab;
