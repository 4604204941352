import React, { useState, useEffect, useRef } from 'react';

function CustomTimer({ seconds, setSeconds, onTimeIsOver }) {
  const secondsRef = useRef(seconds);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(secondsRef.current > 0 ? secondsRef.current - 1 : 0);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    secondsRef.current = seconds;
    if (seconds === 0) {
      onTimeIsOver && onTimeIsOver();
    }
  }, [seconds]);

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  return (
    <div>
      {minutes.toString().padStart(2, '0')}:{remainingSeconds.toString().padStart(2, '0')}
    </div>
  );
}

export default CustomTimer;
