import { Typography } from '@mui/material';
import React from 'react';
import Cards from './cards/Cards';
import styles from './styleFeedback.module.scss';

const Feedback = () => {
  return (
    <section className={styles.feedback_container} id="feedback">
      <Typography className={styles.feedback_title}>
        מגיעים ישר לתכלס - מוצאים, מתאהבים וסוגרים
      </Typography>
      <Cards />
    </section>
  );
};

export default Feedback;
