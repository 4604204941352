// import "@fontsource/anek-telugu";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import dayjs from "dayjs";
import calendar from "./../../../../assets/images/for-pages/icons/calendar-blank.png";
// import calendarBlack from "./../../../../assets/images/for-pages/calendar-icon.png";
import arrowLeft from "./../../../../assets/images/for-pages/icons/arrow-left.png";
import arrowRight from "./../../../../assets/images/for-pages/icons/arrow-right.png";
import menu from "./../../../../assets/images/for-pages/icons/menu.png";
import add from "./img/add.png";
import plus from "./img/plus.png";
import screpka from "./img/screpka.png";
import location1 from "./img/location.png";
import trash from "./img/trash.png";

// import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import styles from "./customCalendar.module.scss";
import * as Styles from "./styles";
import InputWithArrows from "./inputWithArrows/inputWithArrows";
import {useGoogleLogin} from "@react-oauth/google";
import he from "dayjs/locale/he"; // load on demand
import localizedFormat from "dayjs/plugin/localizedFormat";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import {useDispatch, useSelector} from "react-redux";
import {sendGoogleCodeReg, setAllInterval} from "../../../store/calendarSlice";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Popup from "./popup/Popup";
import PopUp from "../../../components/defaultPopUp/PopUp";
import {setErr} from "../../../store/forAll/errSlice";

const Calendar = (props) => {
  // dayjs.extend(updateLocale)

  // dayjs.updateLocale('en', {
  //   weekdaysMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]
  // })

  // dayjs.extend(updateLocale)

  // dayjs.updateLocale('en', {
  //   weekdaysShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
  // })
  dayjs.locale(he);
  dayjs.extend(localizedFormat);
  dayjs.extend(isSameOrBefore);
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const {width, step, changeStep, token, supId} = props;
  const [desctopStep, setDesctopStep] = useState(1);
  const rootEl = useRef(null);
  const [selectedCube, setSelectedCube] = useState(0);
  const [id, setId] = useState(0);
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  const [location, setLocation] = useState("");
  const [curentDate, setCurentDate] = useState(dayjs());
  const today = dayjs();
  const [isShowError, setIsShowError] = useState(false);
  const [isShowGoogleDialog, setIsShowGoogleDialog] = useState(true);
  const [isArrowsByWeeks, setIsArrowsByWeeks] = useState(false);
  const [clicedDayStatus, setClicedDayStatus] = useState(0);

  // 1-month 2-weeks 3-days
  const [calendarBy, setCalendarBy] = useState(1);
  // const [selectedDates, setSelectedDates] = useState([]);
  // const [allInterval, setAllIntervals] = useState([]);
  const [currentEventStartDate, setCurrentEventStartDate] = useState(
    dayjs().minute(0)
  );
  const [currentEventEndDate, setCurrentEventEndDate] = useState(
    dayjs().minute(0).add(1, "h")
  );
  // const [selectedDates, setSelectedDates] = useState([]);
  let [isShowMenu, setIsShowMenu] = useState(false);
  const [dayIntervals, setDayIntervals] = useState([]);

  // const [displayDate, setDisplayDate] = useState([])

    useEffect(()=>{
      dispatch(setAllInterval([]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

  const _A = "BLOCKED";
  const _B = "PARTIALLY_BLOCKED";
  const _C = "VIDEO_CALL";

  const currentDay = useMemo(() => dayjs().toDate(), []);

  const setAllIntervals = (intervals) => {
    dispatch(setAllInterval(intervals));
  };

  const {allInterval, email} = useSelector((state) => state.calendar);
  // const {allInterval} = useSelector(state => state.calendar)

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      // console.log(codeResponse);
      if (
        codeResponse.scope.includes(
          "https://www.googleapis.com/auth/calendar"
        ) &&
        codeResponse.scope.includes(
          "https://www.googleapis.com/auth/userinfo.email"
        )
      ) {
        dispatch(
          sendGoogleCodeReg({
            code: codeResponse.code,
            axiosPrivate,
            token,
            supId,
          })
        );
      }
    },

    scope:
      "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.email",
    flow: "auth-code",
    // prompt: "consent",
  });

  const checkIsIncrement = (e) => {
    if (e) {
      return "add";
    } else {
      return "subtract";
    }
  };

  const cahngeCurrentStartTime = (e) => {
    let f = checkIsIncrement(e);
    let newStartDate = dayjs(currentEventStartDate)[f](1, "h");
    if (dayjs(newStartDate).isBefore(today, "date")) return;
    if (dayjs(newStartDate).isBefore(currentEventEndDate, "h")) {
      setCurrentEventStartDate(newStartDate);
    }else{
      setCurrentEventStartDate(newStartDate);
      setCurrentEventEndDate(dayjs(newStartDate).add(1, "h"));
    }
  };

  const cahngeCurrentStartDate = (e) => {
    let f = checkIsIncrement(e);
    let newStartDate = dayjs(currentEventStartDate)[f](1, "day");
    if (dayjs(newStartDate).isBefore(today, "date")) return;
    if (
      !dayjs(newStartDate).isAfter(currentEventEndDate, "day") &&
      !dayjs(newStartDate).isAfter(currentEventEndDate, "h")
    ) {
      setCurrentEventStartDate(newStartDate);
    }else{
      setCurrentEventStartDate(newStartDate);
      setCurrentEventEndDate(dayjs(newStartDate).add(1, "day").add(1,"h"));
    }
  };
  const cahngeCurrentEndDate = (e) => {
    let f = checkIsIncrement(e);
    let newEndDate = dayjs(currentEventEndDate)[f](1, "day");

    if (
      !dayjs(newEndDate).isBefore(currentEventStartDate, "day") &&
      !dayjs(newEndDate).isBefore(currentEventStartDate, "h")
    ) {
      setCurrentEventEndDate(newEndDate);
    }
  };

  const deleteInterval = (intervalId) => {
    setAllIntervals(
      allInterval.filter((int) => {
        return int.id !== intervalId;
      })
    );
    setDayIntervals(
      dayIntervals.filter((int) => {
        return int.id !== intervalId;
      })
    );
    clearInterval(true);
    setDesctopStep(2);
    changeStep && changeStep(1);
  };

  const selectStep = (st) => {
    // console.log(allIntervals)

    setDesctopStep(st);
  };

  const cahngeCurrentEndTime = (e) => {
    let f = checkIsIncrement(e);
    let newEndDate = dayjs(currentEventEndDate)[f](1, "h");
    if (!dayjs(newEndDate).isSameOrBefore(currentEventStartDate, "h")) {
      setCurrentEventEndDate(newEndDate);
    }
  };

  // const setClickedDay = (day) => {
  //   setCurrentEventStartDate(dayjs(day).add(8, "h"));
  //   setCurrentEventEndDate(dayjs(day).add(9, "h"));
  // };

  const setClickedDay = (day) => {
    if (dayjs(day).isBefore(today, "date")) return;

    if (
      clicedDayStatus === 1 &&
      !(
        dayjs(day).isSameOrBefore(currentEventStartDate, "date") &&
        dayjs(day).isBefore(currentEventStartDate, "h")
      )
    ) {
      // if(dayjs(day).isSameOrBefore(currentEventStartDate, "date") && (
      //   dayjs(day).isBefore(currentEventStartDate, "h")
      // )){
      //   return
      // }
      if (dayjs(day).isBefore(today, "date")) return;
      setClicedDayStatus(0);
      setCurrentEventEndDate(dayjs(day).add(1, "h"));
      selectStep(1);
    } else {
      if (dayjs(day).isBefore(today, "date")) return;
      clearInterval();
      setClicedDayStatus(1);
      let ints = checkIntervalisInDay(day);

      setDayIntervals(ints);
      if (ints.length) {
        setCurrentEventStartDate(dayjs(day));
        setCurrentEventEndDate(dayjs(day).add(1, "h"));
        selectStep(2);
      } else {
        selectStep(1);
        setCurrentEventStartDate(dayjs(day));
        setCurrentEventEndDate(dayjs(day).add(1, "h"));
      }
    }
  };

  // endDate: "2023-03-16T05:00:00.000Z";
  // id: "569a882f";
  // link: "";
  // location: "";
  // name: "";
  // startDate: "2023-03-16T04:00:00.000Z";
  // type: "PARTIALLY_BLOCKED";

  const isPartInside = (checkedDayStart, checkedDayEnd, start, end) => {
    let isInside =
      (dayjs(checkedDayStart).isSameOrBefore(start, "date") &&
        dayjs(checkedDayStart).isSameOrBefore(start, "h") &&
        dayjs(start).isSameOrBefore(checkedDayEnd, "date") &&
        dayjs(start).isBefore(checkedDayEnd, "h")) ||
      (dayjs(checkedDayStart).isSameOrBefore(end, "date") &&
        dayjs(checkedDayStart).isBefore(end, "h") &&
        dayjs(end).isSameOrBefore(checkedDayEnd, "date") &&
        dayjs(end).isSameOrBefore(checkedDayEnd, "h"));
    return isInside;
  };
  
  const isInside = (checkedDayStart, checkedDayEnd, start, end, isByDay) => {
    if(isByDay){
      return (
        dayjs(start).isSameOrBefore(checkedDayStart, "date") &&
        // dayjs(start).isSameOrBefore(checkedDayStart, "h") &&
        dayjs(start).isSameOrBefore(checkedDayEnd, "date") &&
        // dayjs(start).isSameOrBefore(checkedDayEnd, "h") &&
        dayjs(checkedDayStart).isSameOrBefore(end, "date") &&
        // dayjs(checkedDayStart).isSameOrBefore(end, "h") &&
        dayjs(checkedDayEnd).isSameOrBefore(end, "date") )
        // dayjs(checkedDayEnd).isSameOrBefore(end, "h"))
    } else {
      return (
        dayjs(start).isSameOrBefore(checkedDayStart, "date") &&
        dayjs(start).isSameOrBefore(checkedDayStart, "h") &&
        dayjs(start).isSameOrBefore(checkedDayEnd, "date") &&
        dayjs(start).isSameOrBefore(checkedDayEnd, "h") &&
        dayjs(checkedDayStart).isSameOrBefore(end, "date") &&
        dayjs(checkedDayStart).isSameOrBefore(end, "h") &&
        dayjs(checkedDayEnd).isSameOrBefore(end, "date") &&
        dayjs(checkedDayEnd).isSameOrBefore(end, "h")
      );
    }
    
  };

  const checkInterval = (interval, allIntervals) => {
    let intervalsInside = allIntervals.filter((inter) => {
      return (
        (isInside(
          inter.startDate,
          inter.endDate,
          interval.startDate,
          interval.endDate
        ) ||
          isInside(
            interval.startDate,
            interval.endDate,
            inter.startDate,
            inter.endDate
          ) ||
          isPartInside(
            interval.startDate,
            interval.endDate,
            inter.startDate,
            inter.endDate
          )) &&
        inter.id !== interval.id
      );
    });


    if (interval.type === _A) {
      if (intervalsInside.length) {
        dispatch(
          setErr({
            message: "זמן הזה כבר תפוס",
          })
        );
        return false;
      } else {
        return true;
      }
    } else {
      if (
        intervalsInside.find((int) => {
          return int.type === _A;
        })
      ) {
        dispatch(
          setErr({
            message: "זמן הזה כבר תפוס",
          })
        );
        return false;
      } else {
        return true;
      }
    }
  };

  const checkIntervalisInDay = (day, isByDay) => {
    // console.log(day);
    let newIntervals =
      allInterval?.filter((int) => {
        // console.log(int);

        // (
        //   dayjs(day).isBefore(int.endDate, "date") &&
        //     dayjs(item.endDate).isBefore(interval.startDate, "date"),
        //   dayjs(interval.startDate).isBefore(item.startDate, "date") &&
        //     dayjs(interval.endDate).isBefore(item.startDate, "date")
        // )
        if (isByDay) {
          return (
            dayjs(day).isSameOrBefore(int.endDate, "day") &&
            dayjs(day).isBefore(int.endDate, "h") &&
            dayjs(int.startDate).isSameOrBefore(day, "day") &&
            dayjs(int.startDate).isSameOrBefore(day, "h")
          );
        } else {
          return (
            dayjs(day).isSameOrBefore(int.endDate, "day") &&
            dayjs(int.startDate).isSameOrBefore(day, "day")
          );
        }
      }) || [];

    return newIntervals;
  };

  const setInterval = (interval) => {
    setId(interval.id);
    setTitle(interval.name);
    setLink(interval.link);
    setLocation(interval.location);
    setCurrentEventEndDate(interval.endDate);
    setCurrentEventStartDate(interval.startDate);
    setSelectedCube(interval.type);
  };

  const clearInterval = (isAfterDelete) => {
    setId(0);
    setTitle("");
    setLink("");
    setLocation("");
    setSelectedCube(0);
    if (isAfterDelete) return;
    setCurrentEventStartDate(dayjs().minute(0));
    setCurrentEventEndDate(dayjs().minute(0).add(1, "h"));
  };

  const generateGUID = () => {
    function S4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return S4() + S4();
  };

  const createInterval = () => {
    let curentId = id;
    if (
      dayjs(currentEventStartDate).isBefore(currentDay, "day") ||
      dayjs(currentEventEndDate).isBefore(currentDay, "day")
    ) {
      return;
    }
    let newIntervals = allInterval?.length ? [...allInterval] : [];
    let newInterval = {
      id: curentId ? curentId : generateGUID(),
      name: title,
      type: selectedCube,
      startDate: dayjs(currentEventStartDate).toJSON(),
      endDate: dayjs(currentEventEndDate).toJSON(),
      location: location || "",
      link: link || "",
      // screpka: '',
    };

    if (curentId) {
      let isChanging = false;
      let newIntervals = allInterval.map((interval) => {
        if (
          interval.id === curentId &&
          newInterval.startDate &&
          checkInterval(
            newInterval,
            allInterval?.length ? [...allInterval] : []
          )
        ) {
          isChanging = true;
          return newInterval;
        } else {
          return interval;
        }
      });
      if (isChanging) {
        setAllIntervals(newIntervals);
        clearInterval();
        return true;
      } else {
        return false;
      }
    } else {
      if (
        newInterval.startDate &&
        checkInterval(newInterval, allInterval?.length ? [...allInterval] : [])
      ) {
        if (
          dayjs(newInterval.startDate).isSameOrBefore(today, "day") &&
          dayjs(newInterval.startDate).isSameOrBefore(today, "h")
        ) {
          setIsOpenPass(true);
          return false;
        }
        newIntervals?.push(newInterval);
        setAllIntervals(newIntervals);
        clearInterval();
        return true;
      } else {
        return false;
      }
    }
  };

  const checkCell = (day, type, isByDay = isArrowsByWeeks) => {
    let intervals = allInterval?.filter((interval) => {
      return interval.type === type;
    });
    if (!intervals?.length) return false;

    return intervals.find((item) => {
      if (isByDay) {
        return (
          dayjs(item.startDate).isSameOrBefore(day, "date") &&
          dayjs(item.startDate).isSameOrBefore(day, "h") &&
          dayjs(day).isSameOrBefore(item.endDate, "date") &&
          dayjs(day).isBefore(item.endDate, "h")
        );
      } else {
        return (
          dayjs(item.startDate).isSameOrBefore(day, "date") &&
          dayjs(day).isSameOrBefore(item.endDate, "date")
        );
      }
    });
  };

  const checkStartDefaultEndCell = (day, type, isByDay = isArrowsByWeeks) => {
    // let dayOneTypeIntervals = checkIntervalisInDay(day).filter((interval) => {
    //   return interval.type === type;
    // });
    let dayOneTypeIntervals = isByDay
      ? checkIntervalisInDay(day, isByDay).filter((interval) => {
          return interval.type === type;
        })
      : checkIntervalisInDay(day).filter((interval) => {
          return interval.type === type;
        });

    if (!dayOneTypeIntervals?.length) return false;
    if (dayOneTypeIntervals?.length && dayOneTypeIntervals?.length > 1) {
      return "default";
    }

    if (
      dayOneTypeIntervals.find((item) => {
        if (isByDay) {
          return (
            dayjs(day).isSame(item.startDate, "date") &&
            dayjs(item.startDate).isSame(day, "h") &&
            dayjs(day).isSame(item.endDate, "date") &&
            dayjs(day).add(1, "h").isSame(item.endDate, "h")
          );
        } else {
          return (
            dayjs(day).isSame(item.startDate, "date") &&
            dayjs(day).isSame(item.endDate, "date")
          );
        }
      })
    ) {
      // console.log(1);
      return "one";
    } else {
      if (
        dayOneTypeIntervals.find((item) => {
          if (isByDay) {
            return (
              dayjs(day).isSame(item.startDate, "date") &&
              dayjs(day).isSame(item.startDate, "h")
            );
          } else {
            return dayjs(day).isSame(item.startDate, "date");
          }
        })
      ) {
        // console.log(2);
        return "first";
      } else {
        if (
          dayOneTypeIntervals.find((item) => {
            if (isByDay) {
              return (
                dayjs(day).isSame(item.endDate, "date") &&
                dayjs(day).add(1, "h").isSame(item.endDate, "h")
              );
            } else {
              return dayjs(day).isSame(item.endDate, "date");
            }
          })
        ) {
          // console.log(3);
          return "last";
        } else {
          // console.log(4);
          return "default";
        }
      }
    }
  };
  // const checkStartDefaultEndCell = (day, type) => {
  //   let intervals = allInterval?.filter((interval) => {
  //     return interval.type === type;
  //   });
  //   if (!intervals?.length) return false;
  //   console.log(intervals)

  //   if(intervals.find((item) => {
  //     return (
  //       dayjs(day).isSame(item.startDate, "date")
  //       && dayjs(day).isSame(item.endDate, "date")
  //     )
  //   })){
  //     console.log(1);
  //     return "one"
  //   }else{
  //     if(
  //       intervals.find((item) => {
  //         return (
  //           dayjs(day).isSame(item.startDate, "date")
  //         );
  //       })
  //     ){
  //       console.log(2);
  //       return "first"
  //     }else{
  //       if(
  //         intervals.find((item) => {
  //           return (
  //             dayjs(day).isSame(item.endDate, "date")
  //           );
  //         })
  //       ){
  //         console.log(3);
  //         return "last"
  //       }else{
  //         console.log(4);
  //         return "default"
  //       }
  //     }

  //   }

  // }

  // const setClickedDay1 = (day) => {
  //   let isBefore = selectedDates.length
  //     ? dayjs(day).isBefore(selectedDates[0], "date")
  //     : false;
  //   console.log(isBefore, selectedDates);
  //   let newDates =
  //     selectedDates.length && selectedDates.length !== 2
  //       ? [...selectedDates]
  //       : [];
  //   if (isBefore) {
  //     newDates.unshift(day);
  //   } else {
  //     newDates.push(day);
  //   }
  //   setSelectedDates(newDates);
  // };

  // const getDates = () => {
  //   let newArr = selectedDates.length
  //     ? selectedDates.map((day) => {
  //         return dayjs(day).format("L");
  //       })
  //     : [];

  //   if (newArr.length === 1) {
  //     newArr.push(newArr[0]);
  //   }
  //   setDisplayDate(newArr);
  //   console.log(newArr);
  // };

  const firstDayOfTheMonth = useMemo(
    () => curentDate.clone().startOf("month"),
    [curentDate]
  );

  const firstDayOfFirstWeekOfMonth = useMemo(
    () => dayjs(firstDayOfTheMonth).startOf("week"),
    [firstDayOfTheMonth]
  );

  const generateFirstDayOfEachWeek = useCallback((day) => {
    const dates = [day];
    for (let i = 1; i < 6; i++) {
      const date = day.clone().add(i, "week");
      dates.push(date);
    }
    return dates;
  }, []);

  const generateDay = useCallback((day) => {
    const dates = [];
    for (let i = 0; i < 24; i++) {
      const date = day.clone().add(i, "hour").toDate();
      dates.push(date);
    }
    return dates;
  }, []);

  const generateWeek = useCallback((day) => {
    const dates = [];
    for (let i = 0; i < 7; i++) {
      const date = day.clone().add(i, "day");
      dates.push(date);
    }
    return dates;
  }, []);

  const searchCurentWeek = (day) => {
    // if(!day) return
    let curentWeekNumber = 0;
    const firstDayOfEachWeek = generateFirstDayOfEachWeek(
      firstDayOfFirstWeekOfMonth
    );
    firstDayOfEachWeek?.forEach((item, index) => {
      if (dayjs(item.toJSON()).isSameOrBefore(day)) {
        curentWeekNumber = index;
      }
    });
    return curentWeekNumber;
  };

  const generateWeeksOfTheMonth = useMemo(() => {
    const firstDayOfEachWeek = generateFirstDayOfEachWeek(
      firstDayOfFirstWeekOfMonth
    );
    return firstDayOfEachWeek.map((date) =>
      generateWeek(date).map((item) => generateDay(item))
    );
  }, [
    generateFirstDayOfEachWeek,
    firstDayOfFirstWeekOfMonth,
    generateWeek,
    generateDay,
  ]);

  useEffect(() => {
    const onClick1 = (e) =>
      rootEl.current?.contains(e.target) || setIsShowMenu(false);
    document.addEventListener("click", onClick1);
    return () => document.removeEventListener("click", onClick1);
  }, []);

  useEffect(() => {
    // getIntervalsFor3Month(curentDate);
    searchCurentWeek(curentDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curentDate]);

  useEffect(() => {
    console.log(calendarBy);
    if (calendarBy !== 1) {
      setIsArrowsByWeeks(true);
    } else {
      setIsArrowsByWeeks(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarBy]);

  const [isOpenPass, setIsOpenPass] = useState(false);

  return (
    <>
      <PopUp
        isOpen={isOpenPass}
        setIsOpen={setIsOpenPass}
        message={"לא ניתן לבחור תאריך עבר"}
      />
      <Popup
        isOpen={isShowGoogleDialog}
        setIsOpen={setIsShowGoogleDialog}
        getGoogle={login}
      />

      <div className={styles.mainWrapper}>
        <div className={styles.desctop}>
          <Styles.Wrapper ref={rootEl}>
            <Styles.MainWrapper style={{width}}>
              <Styles.CalendarHeaderWrapper>
                <Styles.CurentDateWithArrows>
                  <Styles.MenuImgWrapper>
                    {isShowMenu && (
                      <Styles.Menu>
                        {/* <Styles.MenuItem>שנה</Styles.MenuItem> */}
                        <Styles.MenuItem
                          onClick={(e) => {
                            setCalendarBy(1);
                            // e.stopPropagation();
                          }}
                          variant={calendarBy === 1 ? "selected" : "default"}
                        >
                          חודש
                        </Styles.MenuItem>
                        <Styles.MenuItem
                          onClick={(e) => {
                            setCalendarBy(2);
                            // e.stopPropagation();
                          }}
                          variant={calendarBy === 2 ? "selected" : "default"}
                        >
                          שבוע
                        </Styles.MenuItem>
                        <Styles.MenuItem
                          onClick={(e) => {
                            setCalendarBy(3);
                            // e.stopPropagation();
                          }}
                          variant={calendarBy === 3 ? "lastSelected" : "last"}
                        >
                          יום
                        </Styles.MenuItem>

                        <Styles.Underline></Styles.Underline>

                        <Styles.MenuTitle>
                          לוחות שנה (בחירה מרובה)
                        </Styles.MenuTitle>
                        {email && (
                          <Styles.MenuEmailRow>
                            <div className={styles.radio}>
                              <input
                                // {...register('isEmailAccept')}
                                // onClick={() => dispatch(setIsEmailAccept())}
                                id="isEmailAccept"
                                type="radio"
                                name="isEmailAccept"
                                checked={true}
                              />
                              <label htmlFor="isEmailAccept"></label>
                              {/* <input type="radio" value={1} /> */}
                            </div>

                            <Styles.MenuEmail>{email}</Styles.MenuEmail>
                          </Styles.MenuEmailRow>
                        )}
                        <Styles.MenuBtns>
                          <Styles.MenuBtn onClick={() => login()}>
                            הוסף חשבון
                          </Styles.MenuBtn>
                          <Styles.MenuBtn>סנכרן עכשיו</Styles.MenuBtn>
                        </Styles.MenuBtns>
                      </Styles.Menu>
                    )}

                    <Styles.MenuImg
                      onClick={(e) => {
                        setIsShowMenu(!isShowMenu);
                        e.stopPropagation();
                      }}
                      alt="menu"
                      src={menu}
                    />
                  </Styles.MenuImgWrapper>

                  <Styles.CurentDate>
                    {curentDate.clone().format("MMM YYYY")}
                  </Styles.CurentDate>

                  <Styles.Arrows>
                    <Styles.ArrowImg
                      onClick={() =>
                        setCurentDate((date) =>
                          date.subtract(1, isArrowsByWeeks ? "week" : "month")
                        )
                      }
                      alt=">"
                      src={arrowRight}
                    />
                    <Styles.ArrowImg
                      onClick={() =>
                        setCurentDate((date) =>
                          date.add(1, isArrowsByWeeks ? "week" : "month")
                        )
                      }
                      alt="<"
                      src={arrowLeft}
                    />
                  </Styles.Arrows>
                </Styles.CurentDateWithArrows>

                <Styles.CalImgWithNumber>
                  <Styles.DateNumber>
                    {dayjs(currentDay).format("D")}
                  </Styles.DateNumber>
                  <Styles.CalImg alt="cal" src={calendar} />
                </Styles.CalImgWithNumber>
              </Styles.CalendarHeaderWrapper>
              <Styles.WeekDaysWrapper>
                {generateWeeksOfTheMonth[0].map((day, index) => (
                  <Styles.WeekDayCell
                    key={`week-day-${index}`}
                    onClick={() => {
                      if (calendarBy === 3) {
                        setClickedDay(
                          generateWeeksOfTheMonth[searchCurentWeek(curentDate)][
                            index
                          ][7]
                        );
                      }
                    }}
                    variant={
                      calendarBy === 3 &&
                      dayjs(
                        generateWeeksOfTheMonth[searchCurentWeek(curentDate)][
                          index
                        ][7]
                      ).isSame(currentEventStartDate, "day")
                        ? "clicked"
                        : calendarBy === 3
                        ? "button"
                        : "default"
                    }
                  >
                    <div style={{display: "flex", flexDirection: "column"}}>
                      {dayjs(day[7]).format("ddd")}
                      {calendarBy !== 1 && (
                        <Styles.WeekDayCellNumber>
                          {dayjs(
                            generateWeeksOfTheMonth[
                              searchCurentWeek(curentDate)
                            ][index][7]
                          ).format("D")}
                        </Styles.WeekDayCellNumber>
                      )}
                    </div>
                  </Styles.WeekDayCell>
                ))}
              </Styles.WeekDaysWrapper>
              {calendarBy === 3 ? (
                <Styles.CalendarDayWrapper className={styles.customScroll}>
                  <Styles.CalendarDayRowWrapper>
                    {generateWeeksOfTheMonth[searchCurentWeek(curentDate)]
                      .find((item, ind) => {
                        return dayjs(item[0]).isSame(
                          currentEventStartDate,
                          "day"
                        );
                      })
                      ?.map((hour, houtIndex) => (
                        <Styles.CalendarDayColWrapper
                          onClick={() => {
                            setClickedDay(hour);
                            // console.log(day,currentDay)
                          }}
                        >
                          <Styles.DayCellAbsolutContainer
                          // variant={
                          // houtIndex === 0 ? "first" :"default"
                          // }
                          >
                            {checkCell(hour, _A) && (
                              <Styles.CellDay1
                                variant={checkStartDefaultEndCell(hour, _A)}
                              ></Styles.CellDay1>
                            )}
                            {checkCell(hour, _B) && (
                              <Styles.CellDay2
                                variant={checkStartDefaultEndCell(hour, _B)}
                              ></Styles.CellDay2>
                            )}
                            {checkCell(hour, _C) && (
                              <Styles.CellDay3
                                variant={checkStartDefaultEndCell(hour, _C)}
                              ></Styles.CellDay3>
                            )}
                          </Styles.DayCellAbsolutContainer>
                          <Styles.CalendarDayColAbsolut>
                            {houtIndex + 1}
                          </Styles.CalendarDayColAbsolut>
                          {isInside(
                            hour,
                            hour,
                            currentEventStartDate,
                            dayjs(currentEventEndDate).subtract(1, "h")
                          ) && (
                            <Styles.SelectedOne
                              variant={"default"}
                            ></Styles.SelectedOne>
                          )}
                          {dayjs(currentEventStartDate).isSame(hour, "h") && (
                            <Styles.SelectedOne
                              variant={"default"}
                            ></Styles.SelectedOne>
                          )}
                        </Styles.CalendarDayColWrapper>
                      ))}
                  </Styles.CalendarDayRowWrapper>
                </Styles.CalendarDayWrapper>
              ) : calendarBy === 2 ? (
                <Styles.CalendarWeekWrapper className={styles.customScroll}>
                  {/* <Styles.CalendarWeekRowWrapper>
                      {_24.map((item, index) => (
                        <Styles.CalendarWeekColWrapper>
                          {index+1}
                        </Styles.CalendarWeekColWrapper>
                      ))}
                  </Styles.CalendarWeekRowWrapper> */}

                  {calendarBy === 2 ? (
                    generateWeeksOfTheMonth[searchCurentWeek(curentDate)].map(
                      (day, dayIndex) => (
                        <Styles.CalendarWeekRowWrapper>
                          {day.map((hour, houtIndex) => (
                            <Styles.CalendarWeekColWrapper
                              onClick={() => {
                                setClickedDay(hour);
                                // console.log(day,currentDay)
                              }}
                            >
                              <Styles.DayCellAbsolutContainer
                              // variant={
                              // houtIndex === 0 ? "first" :"default"
                              // }
                              >
                                {checkCell(hour, _A) && (
                                  <Styles.CellDay1
                                    variant={checkStartDefaultEndCell(hour, _A)}
                                  ></Styles.CellDay1>
                                )}
                                {checkCell(hour, _B) && (
                                  <Styles.CellDay2
                                    variant={checkStartDefaultEndCell(hour, _B)}
                                  ></Styles.CellDay2>
                                )}
                                {checkCell(hour, _C) && (
                                  <Styles.CellDay3
                                    variant={checkStartDefaultEndCell(hour, _C)}
                                  ></Styles.CellDay3>
                                )}
                              </Styles.DayCellAbsolutContainer>
                              {!!!dayIndex && (
                                <Styles.CalendarWeekColAbsolut>
                                  {houtIndex + 1}
                                </Styles.CalendarWeekColAbsolut>
                              )}
                              {dayjs(currentEventStartDate).isSame(
                                hour,
                                "h"
                              ) && (
                                <Styles.SelectedOne
                                  variant={"default"}
                                ></Styles.SelectedOne>
                              )}

                              {isInside(
                                hour,
                                hour,
                                currentEventStartDate,
                                dayjs(currentEventEndDate).subtract(1, "h")
                              ) && (
                                <Styles.SelectedOne
                                  variant={"default"}
                                ></Styles.SelectedOne>
                              )}
                            </Styles.CalendarWeekColWrapper>
                          ))}
                        </Styles.CalendarWeekRowWrapper>
                      )
                    )
                  ) : (
                    <></>
                  )}
                </Styles.CalendarWeekWrapper>
              ) : (
                <Styles.CalendarMonthWrapper>
                  {generateWeeksOfTheMonth.map((week, weekIndex) => (
                    <Styles.CalendarContentWrapper key={`week-${weekIndex}`}>
                      {week.map((day, dayIndex) => (
                        <Styles.CalendarDayCell
                          onClick={() => {
                            setClickedDay(day[7]);
                            // console.log(day,currentDay)
                          }}
                          key={`day-${dayIndex}`}
                          variant={
                            curentDate.clone().toDate().getMonth() !==
                              day[7].getMonth() ||
                            dayjs(day[7]).isBefore(currentDay, "date")
                              ? "nextMonth"
                              : // : (selectedDates?.length === 1 &&
                              //     dayjs(day).isSame(selectedDates[0], "date")) ||
                              //   (selectedDates?.length === 2 &&
                              //     ((dayjs(day).isAfter(selectedDates[0], "date") &&
                              //       dayjs(day).isBefore(selectedDates[1], "date")) ||
                              //       dayjs(day).isSame(selectedDates[1], "date") ||
                              //       dayjs(day).isSame(selectedDates[0], "date")))
                              // ? "selected"
                              dayjs(currentDay).isSame(day[7], "date")
                              ? "today"
                              : "default"
                          }
                        >
                          {dayjs(currentDay).isSame(day[7], "date") && (
                            <Styles.Today
                              variant={
                                dayIndex === 0
                                  ? "first"
                                  : dayIndex === week?.length - 1
                                  ? "last"
                                  : "default"
                              }
                            ></Styles.Today>
                          )}
                          {isInside(
                            day[7],
                            day[7],
                            currentEventStartDate,
                            currentEventEndDate,
                            true
                          ) && (
                            <Styles.TodaySelected
                              variant={
                                dayIndex === 0
                                  ? "first"
                                  : dayIndex === week?.length - 1
                                  ? "last"
                                  : "default"
                              }
                            ></Styles.TodaySelected>
                          )}
                          {dayjs(currentEventStartDate).isSame(
                            day[7],
                            "date"
                          ) && (
                            <Styles.Selected
                              variant={
                                dayIndex === 0
                                  ? "first"
                                  : dayIndex === week?.length - 1
                                  ? "last"
                                  : "default"
                              }
                            ></Styles.Selected>
                          )}
                          {dayjs(currentEventEndDate).isSame(
                            day[7],
                            "date"
                          ) && (
                            <Styles.Selected
                              variant={
                                dayIndex === 0
                                  ? "first"
                                  : dayIndex === week?.length - 1
                                  ? "last"
                                  : "default"
                              }
                            ></Styles.Selected>
                          )}
                          {checkCell(day[7], _A) && (
                            <Styles.Cell1
                              variant={checkStartDefaultEndCell(day[7], _A)}
                              pos={
                                dayIndex === 0
                                  ? true
                                  : dayIndex === week?.length - 1
                                  ? true
                                  : false
                              }
                            ></Styles.Cell1>
                          )}
                          {checkCell(day[7], _B) && (
                            <Styles.Cell2
                              variant={checkStartDefaultEndCell(day[7], _B)}
                              pos={
                                dayIndex === 0
                                  ? true
                                  : dayIndex === week?.length - 1
                                  ? true
                                  : false
                              }
                            ></Styles.Cell2>
                          )}
                          {checkCell(day[7], _C) && (
                            <Styles.Cell3
                              variant={checkStartDefaultEndCell(day[7], _C)}
                              pos={
                                dayIndex === 0
                                  ? true
                                  : dayIndex === week?.length - 1
                                  ? true
                                  : false
                              }
                            ></Styles.Cell3>
                          )}
                          {day[7].getDate()}
                        </Styles.CalendarDayCell>
                      ))}
                    </Styles.CalendarContentWrapper>
                  ))}
                </Styles.CalendarMonthWrapper>
              )}
              {/* <Styles.Underline>

          </Styles.Underline>
          <Styles.CalendarButton>
            <Styles.Button onClick={() => {getDates(); setIsShowCalendar(!isShowCalendar)}}>
              בחר תאריך
            </Styles.Button>
            
          </Styles.CalendarButton> */}
            </Styles.MainWrapper>
          </Styles.Wrapper>
          {desctopStep === 1 ? (
            <div className={styles.secondPart}>
              <div className={styles.titleInput}>
                <input
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="הכנס כותרת"
                />
                {!!id && (
                  <div className={styles.tooltipWrapper}>
                    <img
                      onClick={() => {
                        deleteInterval(id);
                      }}
                      className={styles.trash}
                      alt="trash"
                      src={trash}
                    />
                    <span className={styles.tooltiptext}>{"מחיקת אירוע"}</span>
                  </div>
                )}
              </div>

              <Styles.Underline></Styles.Underline>
              <span className={styles.titleBusy}>תיוג אירוע</span>
              <div className={styles.cubsWrapper}>
                <div className={styles.cubeWrapper}>
                  <div
                    onClick={() => setSelectedCube(_A)}
                    style={{backgroundColor: "#E7B1AD"}}
                    className={
                      selectedCube === _A ? styles.activeCube : styles.cube
                    }
                  ></div>
                  <span>חסום</span>
                </div>
                <div className={styles.cubeWrapper}>
                  <div
                    onClick={() => setSelectedCube(_B)}
                    style={{backgroundColor: "#ADE3E7"}}
                    className={
                      selectedCube === _B ? styles.activeCube : styles.cube
                    }
                  ></div>
                  <span>חסום חלקית</span>
                </div>
                <div className={styles.cubeWrapper}>
                  <div
                    onClick={() => setSelectedCube(_C)}
                    style={{backgroundColor: "#DAADE7"}}
                    className={
                      selectedCube === _C ? styles.activeCube : styles.cube
                    }
                  ></div>
                  <span>שיחת וידאו</span>
                </div>
                {!selectedCube && isShowError && (
                  <div className={styles.error}>{"שדה חובה"}</div>
                )}
              </div>
              <Styles.Underline></Styles.Underline>
              <div style={{paddingTop: "1.8125rem"}} className={styles.timeRow}>
                <span style={{marginLeft: "0.9375rem"}}>התחלה</span>
                <div className={styles.date}>
                  <InputWithArrows
                    // noArrows={true}
                    onChange={cahngeCurrentStartDate}
                    dataStr={
                      currentEventStartDate
                        ? dayjs(currentEventStartDate).format("ddd, D MMMM")
                        : ""
                    }
                  />
                </div>
                <div className={styles.time}>
                  <InputWithArrows
                    onChange={cahngeCurrentStartTime}
                    dataStr={
                      currentEventStartDate
                        ? dayjs(currentEventStartDate).format("H:mm")
                        : ""
                    }
                  />
                </div>
              </div>
              <div
                style={{paddingBottom: "1.6875rem", paddingTop: "1.375rem"}}
                className={styles.timeRow}
              >
                <span style={{marginLeft: "2.25rem"}}>סיום</span>
                <div className={styles.date}>
                  <InputWithArrows
                    onChange={cahngeCurrentEndDate}
                    dataStr={
                      currentEventEndDate
                        ? dayjs(currentEventEndDate).format("ddd, D MMMM")
                        : ""
                    }
                  />
                </div>
                <div className={styles.time}>
                  <InputWithArrows
                    onChange={cahngeCurrentEndTime}
                    dataStr={
                      currentEventEndDate
                        ? dayjs(currentEventEndDate).format("H:mm")
                        : ""
                    }
                  />
                </div>
              </div>
              <Styles.Underline></Styles.Underline>
              <div className={styles.row} style={{paddingTop: "0.96875rem"}}>
                <img
                  className={styles.location}
                  alt="location"
                  src={location1}
                />
                <div className={styles.titleInput1}>
                  <input
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    placeholder="מיקום"
                  />
                </div>
              </div>
              <div
                className={styles.row}
                style={{paddingBottom: "1.3rem", paddingTop: "1.5rem"}}
              >
                <img className={styles.screpka} alt="screpka" src={screpka} />
                <div className={styles.titleInput1}>
                  <input
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                    placeholder="צירוף לינקים והערות לאירוע"
                  />
                </div>
              </div>
              {/* <Styles.Underline></Styles.Underline>
            <div className={styles.row} style={{paddingTop: "1rem"}}>
              <img className={styles.add} alt="add" src={add} />
              <span>הוספת אירוע</span>
            </div> */}
              <div className={styles.btnContainer}>
                <div
                  onClick={() => {
                    if (selectedCube) {
                      setIsShowError(false);
                      createInterval();
                    } else {
                      setIsShowError(true);
                    }
                  }}
                  className={styles.addBtn}
                >
                  הוספה ליומן
                </div>
              </div>
            </div>
          ) : desctopStep === 2 ? (
            <div className={styles.allEvents}>
              <div className={styles.row}>
                <img
                  onClick={() => setDesctopStep(1)}
                  style={{cursor: "pointer"}}
                  className={styles.add}
                  alt="add"
                  src={add}
                />
                <span
                  onClick={() => setDesctopStep(1)}
                  style={{cursor: "pointer"}}
                >
                  הוספת אירוע
                </span>
              </div>
              <Styles.Underline></Styles.Underline>
              <div className={styles.allEventsContainer}>
                {dayIntervals?.length
                  ? dayIntervals.map((interval, index) => {
                      return (
                        <>
                          <div className={styles.evetnConteiner}>
                            <div className={styles.head}>
                              <div className={styles.withCube}>
                                <div
                                  style={{
                                    backgroundColor:
                                      interval.type === _A
                                        ? "#E7B1AD"
                                        : interval.type === _B
                                        ? "#ADE3E7"
                                        : "#DAADE7",
                                  }}
                                  className={styles.cube}
                                ></div>
                                <div className={styles.cubeTitle}>
                                  {interval.name || ""}
                                </div>
                              </div>
                              <div
                                onClick={() => {
                                  // changeStep(2);
                                  setDesctopStep(1);
                                  setInterval(interval);
                                }}
                                className={styles.change}
                              >
                                {"עריכה"}
                              </div>
                            </div>
                            <div className={styles.times}>
                              <div className={styles.date}>
                                <InputWithArrows
                                  dataStr={dayjs(interval.startDate).format(
                                    "ddd, D MMMM"
                                  )}
                                  noArrows={true}
                                />
                              </div>
                              <div className={styles.time}>
                                <InputWithArrows
                                  dataStr={dayjs(interval.startDate).format(
                                    "H:mm"
                                  )}
                                  noArrows={true}
                                />
                              </div>
                            </div>
                            <div className={styles.times}>
                              <div className={styles.date}>
                                <InputWithArrows
                                  dataStr={dayjs(interval.endDate).format(
                                    "ddd, D MMMM"
                                  )}
                                  noArrows={true}
                                />
                              </div>
                              <div className={styles.time}>
                                <InputWithArrows
                                  dataStr={dayjs(interval.endDate).format(
                                    "H:mm"
                                  )}
                                  noArrows={true}
                                />
                              </div>
                            </div>
                            {!!interval.link && (
                              <div className={styles.linkRow}>
                                <img
                                  className={styles.screpka}
                                  alt="screpka"
                                  src={screpka}
                                />
                                <div style={{textDecoration: "underline"}}>
                                  {interval.link}
                                </div>
                              </div>
                            )}
                            {!!interval.location && (
                              <div className={styles.linkRow}>
                                <img
                                  className={styles.location}
                                  alt="location"
                                  src={location1}
                                />
                                <div>{interval.location}</div>
                              </div>
                            )}
                            {dayIntervals?.length !== index + 1 && (
                              <Styles.Underline></Styles.Underline>
                            )}
                          </div>
                        </>
                      );
                    })
                  : null}
              </div>
            </div>
          ) : (
            <div className={styles.secondPart}>
              <div className={styles.titleInput}>
                <input
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="הכנס כותרת"
                />
              </div>

              <Styles.Underline></Styles.Underline>
              <span className={styles.titleBusy}>תיוג אירוע</span>
              <div className={styles.cubsWrapper}>
                <div className={styles.cubeWrapper}>
                  <div
                    onClick={() => setSelectedCube(_A)}
                    style={{backgroundColor: "#E7B1AD"}}
                    className={
                      selectedCube === _A ? styles.activeCube : styles.cube
                    }
                  ></div>
                  <span>חסום</span>
                </div>
                <div className={styles.cubeWrapper}>
                  <div
                    onClick={() => setSelectedCube(_B)}
                    style={{backgroundColor: "#ADE3E7"}}
                    className={
                      selectedCube === _B ? styles.activeCube : styles.cube
                    }
                  ></div>
                  <span>חסום חלקית</span>
                </div>
                <div className={styles.cubeWrapper}>
                  <div
                    onClick={() => setSelectedCube(_C)}
                    style={{backgroundColor: "#DAADE7"}}
                    className={
                      selectedCube === _C ? styles.activeCube : styles.cube
                    }
                  ></div>
                  <span>שיחת וידאו</span>
                </div>
                {!selectedCube && isShowError && (
                  <div className={styles.error}>{"שדה חובה"}</div>
                )}
              </div>
              <Styles.Underline></Styles.Underline>
              <div style={{paddingTop: "1.8125rem"}} className={styles.timeRow}>
                <span style={{marginLeft: "0.9375rem"}}>התחלה</span>
                <div className={styles.date}>
                  <InputWithArrows
                    // noArrows={true}
                    onChange={cahngeCurrentStartDate}
                    dataStr={
                      currentEventStartDate
                        ? dayjs(currentEventStartDate).format("ddd, D MMMM")
                        : ""
                    }
                  />
                </div>
                <div className={styles.time}>
                  <InputWithArrows
                    onChange={cahngeCurrentStartTime}
                    dataStr={
                      currentEventStartDate
                        ? dayjs(currentEventStartDate).format("H:mm")
                        : ""
                    }
                  />
                </div>
              </div>
              <div
                style={{paddingBottom: "1.6875rem", paddingTop: "1.375rem"}}
                className={styles.timeRow}
              >
                <span style={{marginLeft: "2.25rem"}}>סיום</span>
                <div className={styles.date}>
                  <InputWithArrows
                    onChange={cahngeCurrentEndDate}
                    dataStr={
                      currentEventEndDate
                        ? dayjs(currentEventEndDate).format("ddd, D MMMM")
                        : ""
                    }
                  />
                </div>
                <div className={styles.time}>
                  <InputWithArrows
                    onChange={cahngeCurrentEndTime}
                    dataStr={
                      currentEventEndDate
                        ? dayjs(currentEventEndDate).format("H:mm")
                        : ""
                    }
                  />
                </div>
              </div>
              <Styles.Underline></Styles.Underline>
              <div className={styles.row} style={{paddingTop: "0.96875rem"}}>
                <img
                  className={styles.location}
                  alt="location"
                  src={location1}
                />
                <span>מיקום</span>
              </div>
              <div
                className={styles.row}
                style={{paddingBottom: "1.3rem", paddingTop: "1.5rem"}}
              >
                <img className={styles.screpka} alt="screpka" src={screpka} />
                <span>צירוף לינקים והערות לאירוע</span>
              </div>
              <Styles.Underline></Styles.Underline>
              <div className={styles.row} style={{paddingTop: "1rem"}}>
                <img className={styles.add} alt="add" src={add} />
                <span>הוספת אירוע</span>
              </div>
              <div className={styles.btnContainer}>
                <div
                  onClick={() => {
                    if (selectedCube) {
                      setIsShowError(false);
                      createInterval();
                    } else {
                      setIsShowError(true);
                    }
                  }}
                  className={styles.addBtn}
                >
                  הוספה ליומן
                </div>
              </div>
            </div>
          )}
          {/* <div className={styles.secondPart}>
            <div className={styles.titleInput}>
              <input
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="הכנס כותרת"
              />
            </div>

            <Styles.Underline></Styles.Underline>
            <span className={styles.titleBusy}>תיוג אירוע</span>
            <div className={styles.cubsWrapper}>
              <div className={styles.cubeWrapper}>
                <div
                  onClick={() => setSelectedCube(_A)}
                  style={{ backgroundColor: "#E7B1AD" }}
                  className={selectedCube === _A ? styles.activeCube : styles.cube}
                ></div>
                <span>חסום</span>
              </div>
              <div className={styles.cubeWrapper}>
                <div
                  onClick={() => setSelectedCube(_B)}
                  style={{ backgroundColor: "#ADE3E7" }}
                  className={selectedCube === _B ? styles.activeCube : styles.cube}
                ></div>
                <span>חסום חלקית</span>
              </div>
              <div className={styles.cubeWrapper}>
                <div
                  onClick={() => setSelectedCube(_C)}
                  style={{ backgroundColor: "#DAADE7" }}
                  className={selectedCube === _C ? styles.activeCube : styles.cube}
                ></div>
                <span>שיחת וידאו</span>
              </div>
              {(!selectedCube && isShowError) && <div className={styles.error}>
                {"שדה חובה"}
              </div>}
            </div>
            <Styles.Underline></Styles.Underline>
            <div style={{ paddingTop: "1.8125rem" }} className={styles.timeRow}>
              <span style={{ marginLeft: "0.9375rem" }}>התחלה</span>
              <div className={styles.date}>
                <InputWithArrows
                  // noArrows={true}
                  onChange={cahngeCurrentStartDate}
                  dataStr={
                    currentEventStartDate
                      ? dayjs(currentEventStartDate).format("dd D MMMM")
                      : ""
                  }
                />
              </div>
              <div className={styles.time}>
                <InputWithArrows
                  onChange={cahngeCurrentStartTime}
                  dataStr={
                    currentEventStartDate
                      ? dayjs(currentEventStartDate).format("H:mm")
                      : ""
                  }
                />
              </div>
            </div>
            <div
              style={{ paddingBottom: "1.6875rem", paddingTop: "1.375rem" }}
              className={styles.timeRow}
            >
              <span style={{ marginLeft: "2.25rem" }}>סיום</span>
              <div className={styles.date}>
                <InputWithArrows
                  onChange={cahngeCurrentEndDate}
                  dataStr={
                    currentEventEndDate
                      ? dayjs(currentEventEndDate).format("dd D MMMM")
                      : ""
                  }
                />
              </div>
              <div className={styles.time}>
                <InputWithArrows
                  onChange={cahngeCurrentEndTime}
                  dataStr={
                    currentEventEndDate
                      ? dayjs(currentEventEndDate).format("H:mm")
                      : ""
                  }
                />
              </div>
            </div>
            <Styles.Underline></Styles.Underline>
            <div className={styles.row} style={{ paddingTop: "0.96875rem" }}>
              <img className={styles.location} alt="location" src={location} />
              <span>מיקום</span>
            </div>
            <div
              className={styles.row}
              style={{ paddingBottom: "1.3rem", paddingTop: "1.5rem" }}
            >
              <img className={styles.screpka} alt="screpka" src={screpka} />
              <span>צירוף לינקים והערות לאירוע</span>
            </div>
            <Styles.Underline></Styles.Underline>
            <div className={styles.row} style={{ paddingTop: "1rem" }}>
              <img className={styles.add} alt="add" src={add} />
              <span>הוספת אירוע</span>
            </div>
            <div className={styles.btnContainer}>
              <div
                onClick={() => {
                  if(selectedCube){
                    setIsShowError(false);
                    createInterval();
                  }else{
                    setIsShowError(true);
                  }
                  
                  
                }}
                className={styles.addBtn}
              >
                הוספה ליומן
              </div>
            </div>
          </div> */}
        </div>
        <div className={styles.mobile}>
          {(step === 1 || step === 3) && (
            <Styles.Wrapper ref={rootEl}>
              <Styles.MainWrapper style={{width}}>
                <Styles.CalendarHeaderWrapper>
                  <Styles.MenuImgWrapper>
                    {isShowMenu && (
                      <Styles.Menu>
                        {/* <Styles.MenuItem>שנה</Styles.MenuItem> */}
                        <Styles.MenuItem
                          onClick={(e) => {
                            setCalendarBy(1);
                            // e.stopPropagation();
                          }}
                          variant={calendarBy === 1 ? "selected" : "default"}
                        >
                          חודש
                        </Styles.MenuItem>
                        <Styles.MenuItem
                          onClick={(e) => {
                            setCalendarBy(2);
                            // e.stopPropagation();
                          }}
                          variant={calendarBy === 2 ? "selected" : "default"}
                        >
                          שבוע
                        </Styles.MenuItem>
                        <Styles.MenuItem
                          onClick={(e) => {
                            setCalendarBy(3);
                            // e.stopPropagation();
                          }}
                          variant={calendarBy === 3 ? "lastSelected" : "last"}
                        >
                          יום
                        </Styles.MenuItem>

                        <Styles.Underline></Styles.Underline>

                        <Styles.MenuTitle>
                          לוחות שנה (בחירה מרובה)
                        </Styles.MenuTitle>
                        {email && (
                          <Styles.MenuEmailRow>
                            <Styles.MenuEmail>{email}</Styles.MenuEmail>
                          </Styles.MenuEmailRow>
                        )}
                        <Styles.MenuBtns>
                          <Styles.MenuBtn onClick={() => login()}>
                            הוסף חשבון
                          </Styles.MenuBtn>
                          <Styles.MenuBtn>סנכרן עכשיו</Styles.MenuBtn>
                        </Styles.MenuBtns>
                      </Styles.Menu>
                    )}
                    <Styles.MenuImg
                      onClick={(e) => {
                        setIsShowMenu((v) => !v);
                        e.stopPropagation();
                      }}
                      alt="menu"
                      src={menu}
                    />
                  </Styles.MenuImgWrapper>
                  <Styles.CurentDateWithArrows>
                    <Styles.Arrows>
                      <Styles.ArrowImg
                        onClick={() =>
                          setCurentDate((date) =>
                            date.subtract(1, isArrowsByWeeks ? "week" : "month")
                          )
                        }
                        alt=">"
                        src={arrowRight}
                      />

                      <Styles.CurentDate>
                        {curentDate.clone().format("MMM YYYY")}
                      </Styles.CurentDate>
                      <Styles.ArrowImg
                        onClick={() =>
                          setCurentDate((date) =>
                            date.add(1, isArrowsByWeeks ? "week" : "month")
                          )
                        }
                        alt="<"
                        src={arrowLeft}
                      />
                    </Styles.Arrows>
                  </Styles.CurentDateWithArrows>

                  <Styles.CalImgWithNumber>
                    <Styles.DateNumber>
                      {dayjs(currentDay).format("D")}
                    </Styles.DateNumber>
                    <Styles.CalImg alt="cal" src={calendar} />
                  </Styles.CalImgWithNumber>
                </Styles.CalendarHeaderWrapper>
                <Styles.WeekDaysWrapper>
                  {generateWeeksOfTheMonth[0].map((day, index) => (
                    <Styles.WeekDayCell
                      key={`week-day-${index}`}
                      onClick={() => {
                        if (calendarBy === 3) {
                          setClickedDay(
                            generateWeeksOfTheMonth[
                              searchCurentWeek(curentDate)
                            ][index][7]
                          );
                        }
                      }}
                      variant={
                        calendarBy === 3 &&
                        dayjs(
                          generateWeeksOfTheMonth[searchCurentWeek(curentDate)][
                            index
                          ][7]
                        ).isSame(currentEventStartDate, "day")
                          ? "clicked"
                          : calendarBy === 3
                          ? "button"
                          : "default"
                      }
                    >
                      <div style={{display: "flex", flexDirection: "column"}}>
                        {dayjs(day[7]).format("ddd")}
                        {calendarBy !== 1 && (
                          <Styles.WeekDayCellNumber>
                            {dayjs(
                              generateWeeksOfTheMonth[
                                searchCurentWeek(curentDate)
                              ][index][7]
                            ).format("D")}
                          </Styles.WeekDayCellNumber>
                        )}
                      </div>
                    </Styles.WeekDayCell>
                  ))}
                </Styles.WeekDaysWrapper>

                {calendarBy === 3 ? (
                  <Styles.CalendarDayWrapper className={styles.customScroll}>
                    <Styles.CalendarDayRowWrapper>
                      {generateWeeksOfTheMonth[searchCurentWeek(curentDate)]
                        .find((item, ind) => {
                          return dayjs(item[0]).isSame(
                            currentEventStartDate,
                            "day"
                          );
                        })
                        ?.map((hour, houtIndex) => (
                          <Styles.CalendarDayColWrapper
                            onClick={() => {
                              setClickedDay(hour);
                              // console.log(day,currentDay)
                            }}
                          >
                            <Styles.DayCellAbsolutContainer
                            // variant={
                            // houtIndex === 0 ? "first" :"default"
                            // }
                            >
                              {checkCell(hour, _A) && (
                                <Styles.CellDay1
                                  variant={checkStartDefaultEndCell(hour, _A)}
                                ></Styles.CellDay1>
                              )}
                              {checkCell(hour, _B) && (
                                <Styles.CellDay2
                                  variant={checkStartDefaultEndCell(hour, _B)}
                                ></Styles.CellDay2>
                              )}
                              {checkCell(hour, _C) && (
                                <Styles.CellDay3
                                  variant={checkStartDefaultEndCell(hour, _C)}
                                ></Styles.CellDay3>
                              )}
                            </Styles.DayCellAbsolutContainer>
                            <Styles.CalendarDayColAbsolut>
                              {houtIndex + 1}
                            </Styles.CalendarDayColAbsolut>
                            {dayjs(currentEventStartDate).isSame(hour, "h") && (
                              <Styles.SelectedOne
                                variant={"default"}
                              ></Styles.SelectedOne>
                            )}
                          </Styles.CalendarDayColWrapper>
                        ))}
                    </Styles.CalendarDayRowWrapper>
                  </Styles.CalendarDayWrapper>
                ) : calendarBy === 2 ? (
                  <Styles.CalendarWeekWrapper className={styles.customScroll}>
                    {/* <Styles.CalendarWeekRowWrapper>
                      {_24.map((item, index) => (
                        <Styles.CalendarWeekColWrapper>
                          {index+1}
                        </Styles.CalendarWeekColWrapper>
                      ))}
                  </Styles.CalendarWeekRowWrapper> */}

                    {calendarBy === 2 ? (
                      generateWeeksOfTheMonth[searchCurentWeek(curentDate)].map(
                        (day, dayIndex) => (
                          <Styles.CalendarWeekRowWrapper>
                            {day.map((hour, houtIndex) => (
                              <Styles.CalendarWeekColWrapper
                                onClick={() => {
                                  setClickedDay(hour);
                                  // console.log(day,currentDay)
                                }}
                              >
                                <Styles.DayCellAbsolutContainer
                                // variant={
                                // houtIndex === 0 ? "first" :"default"
                                // }
                                >
                                  {checkCell(hour, _A) && (
                                    <Styles.CellDay1
                                      variant={checkStartDefaultEndCell(
                                        hour,
                                        _A
                                      )}
                                    ></Styles.CellDay1>
                                  )}
                                  {checkCell(hour, _B) && (
                                    <Styles.CellDay2
                                      variant={checkStartDefaultEndCell(
                                        hour,
                                        _B
                                      )}
                                    ></Styles.CellDay2>
                                  )}
                                  {checkCell(hour, _C) && (
                                    <Styles.CellDay3
                                      variant={checkStartDefaultEndCell(
                                        hour,
                                        _C
                                      )}
                                    ></Styles.CellDay3>
                                  )}
                                </Styles.DayCellAbsolutContainer>
                                {!!!dayIndex && (
                                  <Styles.CalendarWeekColAbsolut>
                                    {houtIndex + 1}
                                  </Styles.CalendarWeekColAbsolut>
                                )}
                                {dayjs(currentEventStartDate).isSame(
                                  hour,
                                  "h"
                                ) && (
                                  <Styles.SelectedOne
                                    variant={"default"}
                                  ></Styles.SelectedOne>
                                )}
                              </Styles.CalendarWeekColWrapper>
                            ))}
                          </Styles.CalendarWeekRowWrapper>
                        )
                      )
                    ) : (
                      <></>
                    )}
                  </Styles.CalendarWeekWrapper>
                ) : (
                  <Styles.CalendarMonthWrapper>
                    {generateWeeksOfTheMonth.map((week, weekIndex) => (
                      <Styles.CalendarContentWrapper key={`week1-${weekIndex}`}>
                        {week.map((day, dayIndex) => (
                          <Styles.CalendarDayCell
                            onClick={() => {
                              setClickedDay(day[7]);
                              changeStep(3);
                            }}
                            key={`day-${dayIndex}`}
                            variant={
                              curentDate.clone().toDate().getMonth() !==
                                day[7].getMonth() ||
                              dayjs(day[7]).isBefore(currentDay, "date")
                                ? "nextMonth"
                                : // : (selectedDates?.length === 1 &&
                                //     dayjs(day).isSame(selectedDates[0], "date")) ||
                                //   (selectedDates?.length === 2 &&
                                //     ((dayjs(day).isAfter(selectedDates[0], "date") &&
                                //       dayjs(day).isBefore(selectedDates[1], "date")) ||
                                //       dayjs(day).isSame(selectedDates[1], "date") ||
                                //       dayjs(day).isSame(selectedDates[0], "date")))
                                // ? "selected"
                                dayjs(currentDay).isSame(day[7], "date")
                                ? "today"
                                : "default"
                            }
                          >
                            {dayjs(currentDay).isSame(day[7], "date") && (
                              <Styles.Today
                                variant={
                                  dayIndex === 0
                                    ? "first"
                                    : dayIndex === week?.length - 1
                                    ? "last"
                                    : "default"
                                }
                              ></Styles.Today>
                            )}
                            {isInside(
                              day[7],
                              day[7],
                              currentEventStartDate,
                              currentEventEndDate,
                              true
                            ) && (
                              <Styles.TodaySelected
                                variant={
                                  dayIndex === 0
                                    ? "first"
                                    : dayIndex === week?.length - 1
                                    ? "last"
                                    : "default"
                                }
                              ></Styles.TodaySelected>
                            )}
                            {dayjs(currentEventStartDate).isSame(
                              day[7],
                              "date"
                            ) && (
                              <Styles.Selected
                                variant={
                                  dayIndex === 0
                                    ? "first"
                                    : dayIndex === week?.length - 1
                                    ? "last"
                                    : "default"
                                }
                              ></Styles.Selected>
                            )}
                            {dayjs(currentEventEndDate).isSame(
                              day[7],
                              "date"
                            ) && (
                              <Styles.Selected
                                variant={
                                  dayIndex === 0
                                    ? "first"
                                    : dayIndex === week?.length - 1
                                    ? "last"
                                    : "default"
                                }
                              ></Styles.Selected>
                            )}
                            {checkCell(day[7], _A) && (
                              <Styles.Cell1
                                variant={checkStartDefaultEndCell(day[7], _A)}
                                pos={
                                  dayIndex === 0
                                    ? true
                                    : dayIndex === week?.length - 1
                                    ? true
                                    : false
                                }
                              ></Styles.Cell1>
                            )}
                            {checkCell(day[7], _B) && (
                              <Styles.Cell2
                                variant={checkStartDefaultEndCell(day[7], _B)}
                                pos={
                                  dayIndex === 0
                                    ? true
                                    : dayIndex === week?.length - 1
                                    ? true
                                    : false
                                }
                              ></Styles.Cell2>
                            )}
                            {checkCell(day[7], _C) && (
                              <Styles.Cell3
                                variant={checkStartDefaultEndCell(day[7], _C)}
                                pos={
                                  dayIndex === 0
                                    ? true
                                    : dayIndex === week?.length - 1
                                    ? true
                                    : false
                                }
                              ></Styles.Cell3>
                            )}
                            {day[7].getDate()}
                          </Styles.CalendarDayCell>
                        ))}
                      </Styles.CalendarContentWrapper>
                    ))}
                  </Styles.CalendarMonthWrapper>
                )}
                {/* <Styles.Underline>

          </Styles.Underline>
          <Styles.CalendarButton>
            <Styles.Button onClick={() => {getDates(); setIsShowCalendar(!isShowCalendar)}}>
              בחר תאריך
            </Styles.Button>
            
          </Styles.CalendarButton> */}
              </Styles.MainWrapper>
            </Styles.Wrapper>
          )}
          {step === 2 && (
            <div className={styles.secondPart}>
              <div className={styles.linkBack}>
                <span onClick={() => changeStep(1)}>{"חזרה ליומן"}</span>
              </div>
              <div className={styles.titleInput}>
                <input
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="הכנס כותרת"
                />
                {!!id && (
                  <img
                    onClick={() => {
                      deleteInterval(id);
                    }}
                    className={styles.trash}
                    alt="trash"
                    src={trash}
                  />
                )}
              </div>

              <Styles.Underline></Styles.Underline>
              <span className={styles.titleBusy}>תיוג אירוע</span>
              <div className={styles.cubsWrapper}>
                <div className={styles.cubeWrapper}>
                  <div
                    onClick={() => setSelectedCube(_A)}
                    style={{backgroundColor: "#E7B1AD"}}
                    className={
                      selectedCube === _A ? styles.activeCube : styles.cube
                    }
                  ></div>
                  <span>חסום</span>
                </div>
                <div className={styles.cubeWrapper}>
                  <div
                    onClick={() => setSelectedCube(_B)}
                    style={{backgroundColor: "#ADE3E7"}}
                    className={
                      selectedCube === _B ? styles.activeCube : styles.cube
                    }
                  ></div>
                  <span>חסום חלקית</span>
                </div>
                <div className={styles.cubeWrapper}>
                  <div
                    onClick={() => setSelectedCube(_C)}
                    style={{backgroundColor: "#DAADE7"}}
                    className={
                      selectedCube === _C ? styles.activeCube : styles.cube
                    }
                  ></div>
                  <span>שיחת וידאו</span>
                </div>
                {!selectedCube && isShowError && (
                  <div className={styles.error}>{"שדה חובה"}</div>
                )}
              </div>
              <Styles.Underline></Styles.Underline>
              <div style={{paddingTop: "1.4375rem"}} className={styles.timeRow}>
                <span
                  className={styles.marginLeft1}
                  style={{marginLeft: "0.9375rem"}}
                >
                  התחלה
                </span>
                <div className={styles.date}>
                  <InputWithArrows
                    onChange={cahngeCurrentStartDate}
                    // noArrows={true}
                    dataStr={
                      currentEventStartDate
                        ? dayjs(currentEventStartDate).format("ddd, D MMMM")
                        : ""
                    }
                  />
                </div>
                <div className={styles.time}>
                  <InputWithArrows
                    onChange={cahngeCurrentStartTime}
                    dataStr={
                      currentEventStartDate
                        ? dayjs(currentEventStartDate).format("H:mm")
                        : ""
                    }
                  />
                </div>
              </div>
              <div
                style={{paddingBottom: "1.4375rem", paddingTop: "0.625rem"}}
                className={styles.timeRow}
              >
                <span
                  className={styles.marginLeft2}
                  style={{marginLeft: "2.25rem"}}
                >
                  סיום
                </span>
                <div className={styles.date}>
                  <InputWithArrows
                    onChange={cahngeCurrentEndDate}
                    dataStr={
                      currentEventEndDate
                        ? dayjs(currentEventEndDate).format("ddd, D MMMM")
                        : ""
                    }
                  />
                </div>
                <div className={styles.time}>
                  <InputWithArrows
                    onChange={cahngeCurrentEndTime}
                    dataStr={
                      currentEventEndDate
                        ? dayjs(currentEventEndDate).format("H:mm")
                        : ""
                    }
                  />
                </div>
              </div>
              <Styles.Underline></Styles.Underline>
              <div className={styles.row} style={{paddingTop: "0.96875rem"}}>
                <img
                  className={styles.location}
                  alt="location"
                  src={location1}
                />
                <div className={styles.titleInput1}>
                  <input
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    placeholder="מיקום"
                  />
                </div>
              </div>
              <div
                className={styles.row}
                style={{paddingBottom: "1.3rem", paddingTop: "1.5rem"}}
              >
                <img className={styles.screpka} alt="screpka" src={screpka} />
                <div className={styles.titleInput1}>
                  <input
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                    placeholder="צירוף לינקים והערות לאירוע"
                  />
                </div>
              </div>
              {/* <Styles.Underline></Styles.Underline>
              <div className={styles.row} style={{ paddingTop: "1rem" }}>
                <img className={styles.add} alt="add" src={add} />
                <span>הוספת אירוע</span>
              </div> */}
              <div className={styles.btnContainer}>
                <div
                  onClick={() => {
                    if (selectedCube) {
                      setIsShowError(false);
                      let isAdded = createInterval();
                      if (isAdded) {
                        changeStep(1);
                      } else {
                      }
                    } else {
                      setIsShowError(true);
                    }
                  }}
                  className={styles.addBtn1}
                >
                  {!!id ? "שמירת שינויים" : "הוספה ליומן"}
                </div>

                <div
                  onClick={() => {
                    clearInterval();
                    changeStep(1);
                  }}
                  className={styles.cancellBtn}
                >
                  ביטול
                </div>
              </div>
            </div>
          )}
          {(step === 1 || step === 3) && (
            <div
              style={{
                paddingBottom:
                  step === 1 || !dayIntervals?.length ? "2.6875rem" : "",
              }}
              className={styles.addBtn}
            >
              <div
                onClick={() => {
                  changeStep(2);
                }}
                className={styles.imgWrap}
              >
                <img src={plus} alt="plus" />
              </div>
              {step === 1 && "הוספת אירוע ליומן"}
            </div>
          )}
          {step === 3 && !!dayIntervals?.length && (
            <div className={styles.allEvents}>
              {dayIntervals?.length
                ? dayIntervals.map((interval, index) => {
                    return (
                      <>
                        <div className={styles.evetnConteiner}>
                          <div className={styles.head}>
                            <div className={styles.withCube}>
                              <div
                                style={{
                                  backgroundColor:
                                    interval.type === _A
                                      ? "#E7B1AD"
                                      : interval.type === _B
                                      ? "#ADE3E7"
                                      : "#DAADE7",
                                }}
                                className={styles.cube}
                              ></div>
                              <div className={styles.cubeTitle}>
                                {interval.name || ""}
                              </div>
                            </div>
                            <div
                              onClick={() => {
                                changeStep(2);
                                setInterval(interval);
                              }}
                              className={styles.change}
                            >
                              {"עריכה"}
                            </div>
                          </div>
                          <div className={styles.times}>
                            <div className={styles.date}>
                              <InputWithArrows
                                dataStr={dayjs(interval.startDate).format(
                                  "ddd, D MMMM"
                                )}
                                noArrows={true}
                              />
                            </div>
                            <div className={styles.time}>
                              <InputWithArrows
                                dataStr={dayjs(interval.startDate).format(
                                  "H:mm"
                                )}
                                noArrows={true}
                              />
                            </div>
                          </div>
                          <div className={styles.times}>
                            <div className={styles.date}>
                              <InputWithArrows
                                dataStr={dayjs(interval.endDate).format(
                                  "ddd, D MMMM"
                                )}
                                noArrows={true}
                              />
                            </div>
                            <div className={styles.time}>
                              <InputWithArrows
                                dataStr={dayjs(interval.endDate).format("H:mm")}
                                noArrows={true}
                              />
                            </div>
                          </div>
                          {!!interval.link && (
                            <div className={styles.linkRow}>
                              <img
                                className={styles.screpka}
                                alt="screpka"
                                src={screpka}
                              />
                              <div style={{textDecoration: "underline"}}>
                                {interval.link}
                              </div>
                            </div>
                          )}
                          {!!interval.location && (
                            <div className={styles.linkRow}>
                              <img
                                style={{height: "23px"}}
                                className={styles.location}
                                alt="location"
                                src={location1}
                              />
                              <div>{interval.location}</div>
                            </div>
                          )}

                          {dayIntervals?.length !== index + 1 && (
                            <Styles.Underline></Styles.Underline>
                          )}
                        </div>
                      </>
                    );
                  })
                : null}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Calendar;
