import { Button, CalendarCell, CalendarGrid, Heading, I18nProvider } from 'react-aria-components';
import './style.scss';
import arrow from '../../../assets/images/datePicker/arrow.png';
import CalendarShell from './CalendarShell';
import { Controller } from 'react-hook-form';
const CustomCalendar = (props) => {
  const { range, name, rules, onChange } = props;
  return (
    <Controller
      name={name}
      rules={rules}
      render={({ field }) => (
        <I18nProvider locale="he">
          <CalendarShell
            // {...field}
            value={field.value}
            aria-label="datePicker"
            onChange={(value) => {
              field.onChange(value);
              onChange && onChange();
            }}
            range={range}
          >
            <header>
              <Button slot="next">
                <img src={arrow} alt="arrow-left" />
              </Button>
              <Heading />
              <Button slot="previous">
                <img style={{ transform: 'scale(-1,1)' }} src={arrow} alt="arrow-right" />
              </Button>
            </header>
            <CalendarGrid>{(date) => <CalendarCell date={date} />}</CalendarGrid>
            {/* {errorMessage && <Text slot="errorMessage">{errorMessage}</Text>} */}
          </CalendarShell>
        </I18nProvider>
      )}
    />
  );
};
export default CustomCalendar;
