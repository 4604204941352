import styles from "./Chats.module.scss";
import * as React from 'react';
import { useEffect, useState,useRef } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import { useDispatch, useSelector } from "react-redux";
import Select from "../../components/selectComponent/Select";
import {
  setEventOnChats
} from "../../store/chatsSlice";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import info from "./info.svg"
import MetaFavicon from '../../components/metaComponents/metaFavicon'
import { getDatabase, ref, onValue, query, limitToLast} from "firebase/database";

function Chats() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [chats, setChats] = useState({data:null,loading:true});
  const { currentEvent } = useSelector((state) => state.chats);
  let defaultEventType = {id:'', name:''};
  const [currentSupplier, setcurrentSupplier] = useState({id:0, name:''});
  const [allEvents, setEvents] = useState({data:[], loading:false});
  const [supplier, setSupplier] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  let currentRole = auth.roles[0];

  const onChangeOption = (option) => {
    dispatch(setEventOnChats({ currentEvent: option }));
    findChatsByEventType(option);
  };
  const onChangeSupplier = (option) => {
    setcurrentSupplier(option);
    onChangeChat(option.id);
    // findChatsByEventType(option);
  };

  const onChangeChat = async(id) => {
    setChats({data: [], loading: true});
    const result = await axiosPrivate.get(`${process.env.REACT_APP_API_PATH}chats?supId=${id}`);
    if(result){
      checkMessages(result.data);
    }
  };

  function navigateByChat(data) {
    if(data && currentRole === 'USER'){
      navigate(`/personal/chat/${data.supplierId}/${data.userId}/${data.eventTypeId}/${data.categoryId}/${data.checkListId}`);
    } else if(data && currentRole !== 'USER'){
      navigate(`/personal-supplier/chat/${data.supplierId}/${data.userId}/${data.eventTypeId}/${data.categoryId}/${data.checkListId}`);
    }
  }
    
  function convertToTwoCharacter(numb) {
    return numb.toString().length === 1 ? "0" + numb : numb.toString();
  }

  function convertDate(date){
    const time = new Date(date);
    let month = time.getMonth() + 1;
    let day = time.getDate();
    let dayString = convertToTwoCharacter(day);
    let monthString = convertToTwoCharacter(month);
    let dateFull = date !== null ? dayString + "-" + monthString + "-" + time.getFullYear()  : '';
    return dateFull
  }

  const ffff =  useRef([]);
  const repeat =  useRef(true);
  const loading =  useRef(true);
  const checkMessages = async (chat) => { 
    ffff.current= [];
    setChats({data: [], loading: true});
    if(chat.length > 0){
        let data = chat;
        const newItems = await data.map( async(val,index)=>{
          let item = val;
          item = await loadingMessages(item, index, chat);
          return item;
        })  
        if(newItems){

        }
    }
  };

   function loadingMessages(item, index, chat){
    const db = getDatabase();
    let count = chat.length;
    loading.current = true;
    const nextCountRef = query(ref(db,  'chats/' + item.chatFirebaseKey +'/messages'),limitToLast(1) );
    let flag = false;
    onValue(nextCountRef, (snapshot) => {
        let result = snapshot.val();
        if(result){ 
          let lastMessage = Object.values(result);
          item.message = lastMessage[0]?.message;
          item.isRead = lastMessage[0]?.isRead;
          item.timestamp = lastMessage[0]?.timestamp;
          item.fromUserType = lastMessage[0]?.fromUserType;
          item.sender = lastMessage[0]?.sender;
        }
          item.timestamp = item.timestamp || 0;
          ffff.current.push(item);
          if(index === count - 1){
            flag = true;
          }
          if(index === count - 1 && loading.current){
          //  console.log('work'); 
            loading.current = false;
            getChats(ffff.current);
          }
          if(flag){
            flag = false;
            return item
          }
      });
  }

  async function getChats(newItems){
    let sortNew = newItems;
   // setTimeout(() => {
    let t = await sortNew.sort((x,y)=>y.timestamp - x.timestamp)
   // setChats({data: t, loading: true});
    setChats({data: t, loading: false});
    ffff.current = []
  //  }, 100);
  }

  useEffect(() => {
    if(currentRole !== 'LABEL'){
      const fetchData = async () => {
          const result = await axiosPrivate.get(`${process.env.REACT_APP_API_PATH}chats`);
          if(repeat.current){
            setChats({data: [], loading: true});
            repeat.current = false;
            checkMessages(result.data);
          }
        //  checkMessages(result.data, 1);
      };
      fetchData();
    }
    if(currentRole === 'USER'){
      userEvents();
    }
    if(currentRole === 'LABEL'){
      LabelChats();
    }
    dispatch(setEventOnChats({ currentEvent: defaultEventType }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userEvents = async () => {
    const result = await axiosPrivate.get(`${process.env.REACT_APP_API_PATH}eventType/chat`);
    const value = chats.data;  
    setEvents({data: value, loading: true});
    setEvents({data: result.data, loading: false});
  };  

  
  const LabelChats = async () => {
    const result = await axiosPrivate.get(`${process.env.REACT_APP_API_PATH}supplier/inferior/label`);
    setSupplier(result.data);
  };  

  const findChatsByEventType = async (eventType) => {
    const result = await axiosPrivate.get(`${process.env.REACT_APP_API_PATH}chats?checklistId=${eventType.checkListId}`);
    // const value = chats.data;  
    // setChats({data: value, loading: true});
    // setChats({data: result.data, loading: false});
    // setChats({data: result.data, loading: false});
    checkMessages(result.data);
  };  

return (
    <>
    <MetaFavicon />
    <div className={styles.mobileTitle}>צ'אט</div>
    <div className={styles.desctop}>
    { currentRole === 'USER' && <div className={styles.eventTypeBlock}>
      <div className={styles.eventLabel}>בחר\י אירוע מתוך רשימת האירועים שלך</div>
    <div className={styles.select}>
    <Select
      fontFamilyHeader={"Heebo-Light"}
      placeholder={" "}
      backgroundColor={"#EEE9E5"}
      options={allEvents.data}
      onChangeOption={onChangeOption}
      selectedOption={currentEvent || defaultEventType}
    />
  </div>
  </div> }
    { currentRole === 'LABEL' && <div className={styles.eventTypeBlock}>
      {/* <div className={styles.eventLabel}>בחר\י אירוע מתוך רשימת האירועים שלך</div> */}
    <div className={styles.select}>
    <Select
      fontFamilyHeader={"Heebo-Light"}
      placeholder={" "}
      backgroundColor={"#EEE9E5"}
      options={supplier}
      onChangeOption={onChangeSupplier}
      selectedOption={currentSupplier}
    />
  </div>
  </div> }
    <div className={styles.menu}>
        <div>כל ההודעות</div>
    </div>
    <div className={styles.chatBlocks}>
      {/* <div className={styles.rightBlock}>

      </div> */}
      
      <div className={styles.leftBlock}>
        { !chats.loading && chats.data.map((item, index)=> { 
        return ( <div key={index} onClick={(e) =>{navigateByChat(item)}}>
        <div className={styles.row}>
            { currentRole === 'USER' && <div className={styles.category} onClick={(e) =>{navigateByChat(item)}}>{item.categoryName}</div> }
            { currentRole === 'USER' && <div className={styles.name}>{item.supName}</div>}
            { currentRole !== 'USER' && <div style={{cursor: 'pointer'}} className={styles.name} onClick={(e) =>{navigateByChat(item)}}>{item.userName}</div>}
            {item.countUnreadMessage > 0 &&<div className={styles.events}>{item.countUnreadMessage}</div>}
            {item.countUnreadMessage <= 0 &&<div className={styles.eventsOne}></div>}
            {currentRole !== 'USER' && <div className={styles.eventDate}>{item.eventName}</div>}
            {currentRole !== 'USER' && <div className={styles.eventDate}>{convertDate(item.contractDate)}</div>}
            {currentRole === 'USER' && (item.fromUserType === 'USER' || item.fromUserType === 'SUPPLIER' || item.fromUserType === 'LABEL') && <div style={{backgroundColor: (!item?.isRead && item.sender !== currentRole) ? 'rgb(169 155 155 / 48%)' : ''}} className={styles.lastMessage}>{item.message}</div> }
            {currentRole === 'USER' && (item.fromUserType === 'USER_GOING_OR_NOT_GOOGLE_MEET' || item.fromUserType === 'SYSTEM_INVITE_REQUEST_USER' || item.fromUserType === 'SYSTEM_INVITE_APPROVE_SUPPLIER') && <div className={styles.lastMessage}>Google meet</div> }
            {currentRole === 'USER' && (item.fromUserType === 'SYSTEM_USER' || item.fromUserType === 'SYSTEM_SUPPLIER') && <div className={styles.lastMessage}>הבקשה נשלחה</div> }
            {currentRole !== 'USER' && <div className={styles.eventDate}></div>}
            {/* <div className={styles.date}>{convertDate(item.lastSendTime)}</div> */}
            {currentRole !== 'USER' && item.contractStatus === 'REQUEST' && <div  style={{backgroundColor: (!item?.isRead && item.sender !== 'LABEL' && item.sender !=='SUPPLIER' ) ? 'rgb(169 155 155 / 48%)' : ''}} className={styles.document_first}>נשלח חוזה לחתימה</div>}
            {currentRole !== 'USER' && item.contractStatus === 'EXPIRED' && <div  style={{backgroundColor: (!item?.isRead && item.sender !== 'LABEL' && item.sender !=='SUPPLIER') ? 'rgb(169 155 155 / 48%)' : ''}} className={styles.document_first}><img alt="info" src={info} /> &nbsp; חוזה פג תוקף</div>}
            {currentRole !== 'USER' && item.contractStatus === 'SIGNED' && <div  style={{backgroundColor: (!item?.isRead && item.sender !== 'LABEL' && item.sender !=='SUPPLIER') ? 'rgb(169 155 155 / 48%)' : '', color:'#E7B1AD'}} className={styles.document_first}>חוזה נחתם!</div>}
            {currentRole !== 'USER' && item.contractStatus === null && <div  style={{backgroundColor: (!item?.isRead && item.sender !== 'LABEL' && item.sender !=='SUPPLIER') ? 'rgb(169 155 155 / 48%)' : ''}} className={styles.document_first}></div>}
        </div>
        <div className={styles.mobileRow}>
          <div className={styles.mobileBlock}>
            { currentRole === 'USER' &&<div className={styles.category} onClick={(e) =>{navigateByChat(item)}}>{item.categoryName}</div> }
            { currentRole === 'USER' && <div className={styles.name}>{item.supName}</div>}
            { currentRole !== 'USER' && <div style={{backgroundColor: (!item?.isRead  && item.sender !== 'LABEL' && item.sender !=='SUPPLIER') ? 'rgb(169 155 155 / 48%)' : ''}} className={styles.name}>{item.userName}</div>}
            {currentRole !== 'USER' && <div  style={{backgroundColor: (!item?.isRead && item.sender !== 'LABEL' && item.sender !=='SUPPLIER') ? 'rgb(169 155 155 / 48%)' : ''}} className={styles.eventDate}>{item.eventName}</div>} {convertDate(item.contractDate) !== '' && currentRole !== 'USER' && <span>|</span>}&nbsp;
            {currentRole !== 'USER' && <div className={styles.eventDate}>{convertDate(item.contractDate)}</div>}
            {/* <div className={styles.date}>{convertDate(item.lastSendTime)}</div> */}
          </div>
          {<div className={styles.mobileBlock}>
            {currentRole === 'USER' && (item.fromUserType === 'USER' || item.fromUserType === 'SUPPLIER' || item.fromUserType === 'LABEL') &&  <div style={{backgroundColor: (!item?.isRead && item.sender !== currentRole) ? 'rgb(169 155 155 / 48%)' : ''}} className={styles.lastMessage}>{item.message}</div> }
            {currentRole === 'USER' && (item.fromUserType === 'SYSTEM_USER' || item.fromUserType === 'SYSTEM_SUPPLIER' || item.fromUserType === 'LABEL') && <div className={styles.lastMessage}>הבקשה נשלחה</div> }
            {currentRole === 'USER' && (item.fromUserType === 'USER_GOING_OR_NOT_GOOGLE_MEET' || item.fromUserType === 'SYSTEM_INVITE_REQUEST_USER' || item.fromUserType === 'SYSTEM_INVITE_APPROVE_SUPPLIER') && <div className={styles.lastMessage}>Google meet</div> }
            {/* {item.countUnreadMessage > 0 &&<div className={styles.events}>{item.countUnreadMessage}</div>}
            {item.countUnreadMessage <= 0 &&<div className={styles.eventsOne}></div>} */}
          </div> }
        <div className={styles.mobileLine}><hr></hr></div>
        </div>
        <hr className={styles.tableLine}></hr>
        </div> )
        })  }
      </div>
    </div>


    </div>
    </>
    );
  }   
export default Chats;