import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/axios"


// {
//   "overallRating": 0,
//   "amountOfReviews": 0,
//   "feedbacks": [
//     {
//       "userName": "string",
//       "rating": 0,
//       "feedback": "string",
//       "date": "2023-02-03T15:54:06.358Z"
//     }
//   ]
// }
export const getFeedbacks = createAsyncThunk(
  'supplier/getFeedbacks', 
  async function ({supplierId}, {rejectWithValue}) {
    try {
      const response = await axios.get(`/supplier/profile/${supplierId}/feedbacks`);
      /* console.log(response) */
      if (response.status!==200) {
        throw new Error('Server Error');
      }
      const data = await response.data;
      /* console.log(data); */
      return data === undefined ? null : data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getVideos = createAsyncThunk(
  'supplier/getVideos', 
  async function ({supplierId}, {rejectWithValue}) {
    try {
      const response = await axios.get(`/supplier/profile/${supplierId}/video`);
      /* console.log(response) */
      if (response.status!==200) {
        throw new Error('Server Error');
      }
      const data = await response.data;
      /* console.log(data); */
      return data === undefined ? null : data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

// {
//   "supplierId": 0,
//   "userName": "string",
//   "rating": 0,
//   "feedback": "string"
// }
export const saveFeedback = createAsyncThunk(
  'supplier/saveFeedback',
  async ({sendObj, axiosPrivate}, {rejectWithValue}) => {
    // console.log(sendObj, axiosPrivate)
    try{
      const response = await axiosPrivate.post(`/user/feedback/supplier`, 
      sendObj);
      const data = await response.data;
      return data === undefined ? null : data;
    }catch (error){
      return rejectWithValue(error)
    }

  }
);

const supplierSlice = createSlice({
  name: "supplier",
  initialState: {
    error: null,
    loadingStatus: null,
  },
  reducers: {
    // setSelectedEvents(state,action) {
    //   state.selectedEvents = action.payload;
    // },
    // setFilterDate(state, action) {
      
    //   console.log(action.payload)
    //   state.filterDate = action.payload;
    // },
    // clearForm(state,action) {
    //   state.filterDate = '';
    //   state.selectedEvents=[];
    // },
  },
  extraReducers: {
    
    // [getAllEvents.fulfilled]: (state, action) => {
    //   console.log(action.payload)
    //   state.allEvents = action.payload;
    //   // state.email = action.payload.email;
    // },
    // [downLoadDocument.fulfilled]: (state, action) => {
    //   console.log("ok")
    // }
    
  },
});

// export const {setFilterDate, clearForm, setSelectedEvents } = changeSupplierSlice.actions;

export default supplierSlice.reducer;
