import { useState,useEffect } from "react"
import { Helmet } from "react-helmet-async";

const MetaTerms = () => {
    const [ icon,setIcon ]=useState(sessionStorage.getItem('url'))
    useEffect(()=>{
        setIcon(sessionStorage.getItem('url'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[sessionStorage.getItem('url')])
    
    return(
        <Helmet>
        <meta charSet="utf-8" />
        <title>Terms</title>
        <link rel="canonical" href="http://mysite.com/example" />
        <link rel="shortcut icon" href={icon} />
        </Helmet>
    )
}
export default MetaTerms;
