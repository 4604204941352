import styles from "./UserRegistration.module.scss";
// import facebookLogo from "../../../assets/images/facebook_logo.png";
// import googleLogo from "../../../assets/images/google_logo.png";
import userRegBackground from "../../../assets/images/userLoginBackground.png";
import iconHide from "../../../assets/images/icon-hide.png";
import iconView from "../../../assets/images/icon-view.png";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import axios from "../../../api/axios";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { setLoader } from "../../store/loaderSlice";
import useAuth from "../../hooks/useAuth";
import { GoogleLogin } from "@react-oauth/google";
import { setUserIdToken } from "../../store/supplierRegistrationSlice";
import { TEMP_USER_KEY } from "../../consts/local_keys";
import { setErr } from "../../store/forAll/errSlice";
import MetaFavicon from '../../components/metaComponents/metaFavicon'
import { setIsOpenUserAuth, setIsOpenUserReg, setIsGoogle } from "../../store/mainSlice";
import { useAnalyticsEvents } from "../../hooks/useAnalyticsEvents";

function UserRegistration() {
  const {UserSignUp} = useAnalyticsEvents();
  // const routeParams = useParams();
  const [isGoogleState,setIsGoogleState] = useState(false)
  // routeParams.id === "google"
  let { pathForReturn, nextPathForReturn } = useSelector((state) => state.returnToUrl);
  let { isGoogle } = useSelector((state) => state.main);
  let { userIdToken } = useSelector((state) => state.supplierRegistration);
  let [isShowPass, setIsShowPass] = useState(false);
  let [isShowPassMatch, setIsShowPassMatch] = useState(false);
  const [isDisableButton, setIsDisableButton] = useState(false)
  const navigate = useNavigate();
  const { setAuth } = useAuth();

  // let [isDownloaded, setIsDownloaded] = useState(false);
  const imageContainer = useRef();
  const dispatch = useDispatch();
  const backgroundImage = new Image();
  backgroundImage.src = userRegBackground;

  // const handleLoadingBackground = () => {
  //   // imageContainer.current.style.backgroundImage =
  //   //   "url(" + userRegBackground + ")";
  //   setIsDownloaded(true);
  //   dispatch(setLoader(false));
  // };
  useEffect(()=>{
    setIsGoogleState(isGoogle)
  },[isGoogle])

  const onLoginRegisterGoogle = async (res) => {
    // console.log(res);
    try {
      const response = await axios.post(`/user/login/google`, {
        token: res.credential,
      });
      const data = await response.data;

      const accessToken = data?.jwtAuthenticationResponseDto.accessToken;
      const refreshToken = data?.jwtAuthenticationResponseDto.refreshToken;
      const roles = [data?.userDto.userType];
      const user = JSON.stringify(data?.userDto);

      if (accessToken && refreshToken && roles && user) {
        setAuth({ accessToken, refreshToken, user, roles });
      }
      navigate( pathForReturn || nextPathForReturn || '/')
      dispatch(setIsOpenUserReg(false))
      // navigate("/");

      return data === undefined ? null : data;
    } catch (error) {
      dispatch(setUserIdToken(res.credential));
      dispatch(setIsGoogle(true))
      setIsGoogleState(true)
      // navigate("/user-registration/google");
      // console.log(error);
    }
  };

  // useEffect(() => {
  //   dispatch(setLoader(true));
  //   backgroundImage.addEventListener("load", handleLoadingBackground);
  //   return backgroundImage.remove("load", handleLoadingBackground);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const schema = yup.object().shape({
    terms: yup.string().required("שדה חובה"),
    name: yup
      .string()
      .min(2, "לפחות 2 אותיות") // min char 2
      .required("הכנס שם משתמש"), //name required
    phoneNumber: yup
      .string()
      .matches(/^(?:\+[\d\s-]+|[\d\s-]+)$/, "רק ספרות") // ONLY_NUMBERS
      .min(9, "לפחות 9 ספרות") //
      // .max(10, "מקסימום 10 ספרות") // MIN_CHAR_10
      .required("שדה חובה") //  FIELD_REQUIRED
      .test(
        "leading-zero",
        "מספר טלפון צריך להתחיל מ 0 או 972+", // LEDAING_ZERO
        (value, context) => {
          return context.originalValue && (context.originalValue.startsWith(0) || context.originalValue.startsWith('+'));
        }
      ),
    // 'You must enter a email'
    email: isGoogleState
      ? yup.string().nullable(true)
      : yup
          .string()
          .email("רשום מייל תקין") // 'Enter a valid email'
          .required("שדה חובה"),
    // 'Password is too short - should be 6 chars minimum.'
    password: isGoogleState
      ? yup.string().nullable(true)
      : yup
          .string()
          .required("נא לרשום סיסמא") // 'Please enter your password.'
          .min(6, "הסיסמא קצרה מדי, לפחות 6 תוים."),
    passwordConfirm: isGoogleState
      ? yup.string().nullable(true)
      : yup
          .string()
          /* .required("שדה חובה") */
          .oneOf([yup.ref("password"), null], "הסיסמאות אינן תואמות"),
  });

  const defaultValues = {
    name: "",
    phoneNumber: "",
    email: "",
    terms: '',
    password: "",
    passwordConfirm: "",
    rememberMe: false,
  };

  const {
    register,
    // handleSubmit,
    formState: { errors },
    formState,
    getValues,
    setValue,
  } = useForm({
    mode: "all",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const registrateUser = async (data1) => {
    let cleaned = (getValues().phoneNumber).replace(/[\s-]/g, '');
    if(cleaned.startsWith('+972')){
      cleaned = cleaned.replace(/^(\+972)/, '0')
    }
    if(cleaned.startsWith('+') && !cleaned.startsWith('+972')){
      dispatch(
        setErr({
          message: "מספר טלפון צריך להתחיל מ 0 או 972+",
          type: 1,
        })
      );
      return false;
    }
    if (cleaned.length >= 9 && cleaned.length <= 10) {
    } else {
      dispatch(
        setErr({
          message: "מקסימום 10 ספרות",
          type: 1,
        })
      );
      return false;
    }
   // setIsDisableButton(true)
    let newData = { ...data1 };
    newData.phoneNumber = cleaned; 
    setValue("name", getValues().name, { shouldValidate: true });
    setValue("phoneNumber", cleaned, { shouldValidate: true });
    setValue("terms", getValues().terms, { shouldValidate: true });
    if (!isGoogleState) {
      setValue("email", getValues().email, { shouldValidate: true });
      setValue("password", getValues().password, { shouldValidate: true });
      setValue("passwordConfirm", getValues().passwordConfirm, {
        shouldValidate: true,
      });
    } else {
      newData.idToken = userIdToken;
    }

    if (newData.terms === '') return;
    if (Object.keys(formState.errors).length) return;

    delete newData.passwordConfirm;
    delete newData.rememberMe;

    try {
      const response = await axios.post(
        !isGoogleState
          ? `${process.env.REACT_APP_API_PATH}user/join?userToken=${
              localStorage.getItem(TEMP_USER_KEY) || ""
            }`
          : `/user/join/google?userToken=${
              localStorage.getItem(TEMP_USER_KEY) || ""
            }`,
        newData
      );
      const data = await response.data;

      const obj = ({
        userName: newData.name,
        userPhone: newData.phoneNumber,
        // url: pathname,
      })
      UserSignUp(obj)
      if(data){
        sendZapierUserReg(data.userDto.id);
      }
      const accessToken = data?.jwtAuthenticationResponseDto.accessToken;
      const refreshToken = data?.jwtAuthenticationResponseDto.refreshToken;
      const roles = [data?.userDto.userType];
      const user = JSON.stringify(data?.userDto);

      if (accessToken && refreshToken && roles && user) {
        setAuth({ accessToken, refreshToken, user, roles });
      }
      localStorage.removeItem(TEMP_USER_KEY);
      // navigate("/");
      // navigate(pathForReturn || nextPathForReturn || '/')
      if(window.location.pathname === '/supplier-registration'){
        navigate("/");
      } else if(window.location.pathname === '/personal-guest/checklist'){
        navigate("/personal/checklist");
      } else if(window.location.pathname === '/personal-guest/change-user'){
        navigate("/personal/change-user");
      } else if(window.location.pathname === '/personal-guest/add-event'){
        navigate("/personal/add-event");
      } else if(window.location.pathname === '/personal-guest'){
        navigate("/personal/checklist");
      }
      
      dispatch(setIsOpenUserReg(false))
     // setIsDisableButton(false)
      return data === undefined ? null : data;
    } catch (error) {
      setIsDisableButton(false)
      if (error) {
        // console.log(error)
        dispatch(
          setErr({
            message: error?.response?.data?.message,
            code: error?.response?.data?.code,
          })
        );
      }
    }
  };
    const sendZapierUserReg = async(userId) => {
      await axios.post(`${process.env.REACT_APP_API_PATH}whatsapp?key=${process.env.REACT_APP_ZAPIER_CONTROLLER_KEY}&userId=${userId}&triggerType=${'COMPLETE_JOIN'}`)
      .then((response) => {
        })
        .catch((error) => {
        });
    }

    const openTerms = async() => {   
      let url = process.env.REACT_APP_PATH;
      window.open(url + 'terms/2','_blank')
    }

  return (
    <>
      <MetaFavicon />
      <div className={styles.rootWrapper} onClick={(e)=>{dispatch(setIsOpenUserReg(false))}}>
        <div className={styles.userRegContainer} onClick={(e)=>{e.stopPropagation();}}>
          <div className={styles.userRegBackgroundImage}>
            <div className={styles.adaptiveWrapperFirstBlock}>
              <div
                style={{ backgroundImage: `url(${userRegBackground})` }}
                ref={imageContainer}
                className={styles.userRegBackgroundImageContent}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25.477"
                  height="25.47"
                  viewBox="0 0 25.477 25.47"
                  onClick={() => {
                    dispatch(setIsOpenUserReg(false))
                  }}
                  className={classNames(styles.closeIcon, styles.closeIconMobile)}
                >
                  <path
                    id="Icon_ionic-ios-close"
                    data-name="Icon ionic-ios-close"
                    d="M27.042,24.024l9.1-9.1a2.132,2.132,0,1,0-3.015-3.015l-9.1,9.1-9.1-9.1a2.132,2.132,0,1,0-3.015,3.015l9.1,9.1-9.1,9.1a2.132,2.132,0,0,0,3.015,3.015l9.1-9.1,9.1,9.1a2.132,2.132,0,0,0,3.015-3.015Z"
                    transform="translate(-11.285 -11.289)"
                    fill="#242323"
                  />
                </svg>
              </div>
            </div>
          </div>

          {
            // isDownloaded &&
            <div className={styles.userRegFormContainer}>
              <div className={styles.adaptiveWrapperSecondBlock}>
                <div className={styles.userRegFormContainerContent}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25.477"
                    height="25.47"
                    viewBox="0 0 25.477 25.47"
                    onClick={() => {
                      dispatch(setIsOpenUserReg(false))
                      // navigate(pathForReturn ? pathForReturn : "/");
                    }}
                    className={classNames(
                      styles.closeIcon,
                      styles.closeIconDesktop
                    )}
                  >
                    <path
                      id="Icon_ionic-ios-close"
                      data-name="Icon ionic-ios-close"
                      d="M27.042,24.024l9.1-9.1a2.132,2.132,0,1,0-3.015-3.015l-9.1,9.1-9.1-9.1a2.132,2.132,0,1,0-3.015,3.015l9.1,9.1-9.1,9.1a2.132,2.132,0,0,0,3.015,3.015l9.1-9.1,9.1,9.1a2.132,2.132,0,0,0,3.015-3.015Z"
                      transform="translate(-11.285 -11.289)"
                      fill="#242323"
                    />
                  </svg>
                  <h2 className={styles.regHeader}>הרשמה</h2>
                  {!isGoogleState && (
                    <div className={styles.regButtonsWithOtherResContainer}>
                      {/* <button>
                      <img src={facebookLogo} alt="facebookLogo" />
                      להתחברות עם Facebook
                    </button>
                    <button>
                      <img src={googleLogo} alt="googleLogo" />
                      להתחברות עם Google
                    </button> */}
                      {/* <div> */}
                      <GoogleLogin
                        onSuccess={(credentialResponse) => {
                          onLoginRegisterGoogle(credentialResponse);
                        }}
                        onError={() => {
                          // console.log("Login Failed");
                        }}
                        locale={"he"}
                      />
                      {/* </div> */}
                    </div>
                  )}
                  <form
                    className={styles.regForm}
                    onSubmit={() => registrateUser(getValues())}
                  >
                    <div className={styles.inputContainer}>
                      <input
                        type="text"
                        placeholder="שם מלא"
                        {...register("name")}
                        className={classNames({
                          [styles.invalidField]: errors.name,
                        })}
                      />
                      {errors.name && (
                        <p className={styles.errorMessage}>
                          {errors.name.message}
                        </p>
                      )}
                    </div>
                    <div className={styles.inputContainer}>
                      <input
                        type="text"
                        placeholder="מספר טלפון"
                        {...register("phoneNumber")}
                        className={classNames({
                          [styles.invalidField]: errors.phoneNumber,
                        }, styles.phoneInput)}
                      />
                      {errors.phoneNumber && (
                        <p className={styles.errorMessage}>
                          {errors.phoneNumber.message}
                        </p>
                      )}
                    </div>
                    {!isGoogleState && (
                      <div className={styles.inputContainer}>
                        <input
                          type="email"
                          placeholder="כתובת מייל"
                          {...register("email")}
                          className={classNames({
                            [styles.invalidField]: errors.email,
                          })}
                        />
                        {errors.email && (
                          <p className={styles.errorMessage}>
                            {errors.email.message}
                          </p>
                        )}
                      </div>
                    )}

                    {!isGoogleState && (
                      <div className={styles.inputPasswordsContainer}>
                        <div
                          className={classNames(
                            styles.inputContainer,
                            styles.inputPasswordsContainerItem
                          )}
                        >
                          <input
                            type={isShowPass ? "text" : "password"}
                            placeholder="סיסמא"
                            autoComplete="new-password"
                            {...register("password", {
                              deps: ["passwordConfirm"],
                            })}
                            className={classNames({
                              [styles.invalidField]: errors.password,
                            })}
                          />
                          <img
                            src={iconHide}
                            alt="hide"
                            className={styles.hideShowIcon}
                            style={{ display: isShowPass ? "none" : "block" }}
                            onClick={() => setIsShowPass(!isShowPass)}
                          />
                          <img
                            src={iconView}
                            alt="view"
                            className={styles.hideShowIcon}
                            style={{ display: isShowPass ? "block" : "none" }}
                            onClick={() => setIsShowPass(!isShowPass)}
                          />
                          {errors.password && (
                            <p className={styles.errorMessage}>
                              {errors.password.message}
                            </p>
                          )}
                        </div>
                        <div
                          className={classNames(
                            styles.inputContainer,
                            styles.inputPasswordsContainerItem
                          )}
                        >
                          <input
                            type={isShowPassMatch ? "text" : "password"}
                            placeholder="אישור סיסמא"
                            autoComplete="new-password"
                            {...register("passwordConfirm", {
                              deps: ["password"],
                            })}
                            className={classNames({
                              [styles.invalidField]: errors.passwordConfirm,
                            })}
                          />

                          <img
                            src={iconHide}
                            alt="hide"
                            className={styles.hideShowIcon}
                            style={{
                              display: isShowPassMatch ? "none" : "block",
                            }}
                            onClick={() => setIsShowPassMatch(!isShowPassMatch)}
                          />
                          <img
                            src={iconView}
                            alt="view"
                            className={styles.hideShowIcon}
                            style={{
                              display: isShowPassMatch ? "block" : "none",
                            }}
                            onClick={() => setIsShowPassMatch(!isShowPassMatch)}
                          />

                          {errors.passwordConfirm && (
                            <p className={styles.errorMessage}>
                              {errors.passwordConfirm.message}
                            </p>
                          )}
                        </div>
                      </div>
                    )}
                    <input
                      type="checkbox"
                      id="rememberMe"
                      {...register("rememberMe")}
                      className={styles.checkbox}
                    />
                    <label htmlFor="rememberMe" className={styles.checkboxLabel}>
                      זכור אותי
                    </label>
                                
                    <div style={{minHeight: '2.5rem', position: 'relative', marginBottom: 'auto', display: 'flex', gap:'10px'}}>
                      <input 
                      className={styles.checkbox}
                        value={'2'}
                        {...register('terms')}
                        id="terms" type="radio" name="terms"  />
                      <label htmlFor="terms" className={styles.checkboxLabel}>קראתי ואני מאשר את </label>{" "}
                      {/* AgreeWithRules */}
                        <div onClick={()=>{openTerms()}} style={{textDecoration: 'underline', color: '#7b7777', cursor: 'pointer'}}>תקנון האתר</div>
                      { errors.terms && 
                          <p className={styles.errorMessage}>
                            שדה חובה
                          </p>
                        }
                    </div>

                    <button
                      className={styles.submitButton}
                      type="button"
                      onClick={() => registrateUser(getValues())}
                      disabled={isDisableButton}
                    >
                      אפשר להמשיך
                    </button>
                  </form>
                  <div className={styles.goToLoginContainer}>
                    <p>יש לי כבר חשבון אצלכם</p>
                    <Link onClick={()=>{dispatch(setIsOpenUserReg(false));dispatch(setIsOpenUserAuth(true))}}>התחבר</Link>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </>
  );
}

export default UserRegistration;
