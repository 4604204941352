import { Calendar, RangeCalendar } from 'react-aria-components';

const CalendarShell = (props) => {
  const { range, children, ...anotherProps } = props;
  return range ? (
    <RangeCalendar {...anotherProps}>{children}</RangeCalendar>
  ) : (
    <Calendar {...anotherProps}>{children}</Calendar>
  );
};
export default CalendarShell;
